import React, { Suspense, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import adminRoutes from 'routes/routesByRole/adminRoutes';
import superAdminRoutes from 'routes/routesByRole/superAdminRoutes';
import coordinatorRoutes from 'routes/routesByRole/coordinatorRoutes';
import pmRoutes from 'routes/routesByRole/pmRoutes';
import apmRoutes from 'routes/routesByRole/apmRoutes';
import procurementRoutes from 'routes/routesByRole/procurementRoutes';
import technicianRoutes from 'routes/routesByRole/technicianRoutes';
import beneficiaryRoutes from 'routes/routesByRole/beneficiaryRoutes';
import tenantRoutes from 'routes/routesByRole/tenantRoutes';
import Loader from './components/ui/Loader';
import AppRoute from './components/routes/AppRoute';
import { setMe as setMeAction } from './store/auth/actions';
import {
  authUserHasOtpSelector,
  authUserRoleSelector,
  isAuthenticatedSelector,
} from './store/auth/selectors';
import PageLayout from './components/layout/PageLayout';
import HeaderContext from './contexts/headerContext';
import PATH, { CHAT_PATH } from './routes/paths';
import { useDidMount } from './hooks/lifecycleHooks';
import RoleModel from './api/models/RoleModel';
import useStandalonePage from './hooks/useStandalonePage';
import RealTimeProviderContext from './contexts/realTimeProviderContext';
import useRealTimeHandler from './hooks/realTime/useRealTimeHandler';
import Alert from './alerts/Alert';
import useNetworkChecker from './hooks/useNetworkChecker';
import PrintableContainer from './components/print/PrintableContainer';
import PrintContext from './contexts/printContext';
import { APP_CONTAINER_ID } from './components/print/constants';

const App = ({ isAuthenticated, authUserHasOtp, authUserRole, setMe }) => {
  const [loading, setLoading] = useState(true);
  const projectPageMatch = useRouteMatch(PATH.PROJECT.ONE);
  const { isStandalonePage } = useStandalonePage();
  const [authUserRoutes, setAuthUserRoutes] = useState(adminRoutes);
  const [showSidebar, setShowSidebar] = useState(true);
  const { pathname } = useLocation();

  const realTimeProviderData = useRealTimeHandler();
  const chatPageOpened = pathname.includes(CHAT_PATH);

  useNetworkChecker();

  useEffect(() => {
    setShowSidebar(
      RoleModel.roleIsAtLeastAssistantPM(authUserRole.name) &&
        Boolean(projectPageMatch) &&
        !chatPageOpened // can't use useStandalonePage cause it depends on HeaderContext
    );
  }, [projectPageMatch, authUserRole, chatPageOpened]);

  useDidMount(() => {
    setMe()
      .then(
        ({ payload }) =>
          !payload.organization?.id &&
          Alert.showError(
            'Parent organization is not set for authenticated user!'
          )
      )
      .finally(() => setLoading(false));
  });

  useEffect(() => {
    switch (authUserRole.name) {
      case RoleModel.ROLES.SUPER_ADMIN:
        setAuthUserRoutes(superAdminRoutes);
        break;
      case RoleModel.ROLES.ADMIN:
        setAuthUserRoutes(adminRoutes);
        break;
      case RoleModel.ROLES.COORDINATOR:
        setAuthUserRoutes(coordinatorRoutes);
        break;
      case RoleModel.ROLES.PROJECT_MANAGER:
        setAuthUserRoutes(pmRoutes);
        break;
      case RoleModel.ROLES.ASSISTANT_PROJECT_MANAGER:
        setAuthUserRoutes(apmRoutes);
        break;
      case RoleModel.ROLES.PROCUREMENT:
        setAuthUserRoutes(procurementRoutes);
        break;
      case RoleModel.ROLES.TECHNICIAN:
        setAuthUserRoutes(technicianRoutes);
        break;
      case RoleModel.ROLES.BUILDING_MANAGER:
        setAuthUserRoutes(beneficiaryRoutes);
        break;
      case RoleModel.ROLES.OFFICE_MANAGER:
        setAuthUserRoutes(tenantRoutes);
        break;
      default:
        setAuthUserRoutes(adminRoutes);
    }
  }, [authUserRole]);
  const routerContent = (
    <Suspense fallback={<Loader size={64} />}>
      <Switch>
        {RoleModel.roleCanAccessApp(authUserRole.name) &&
          !RoleModel.roleIsAtLeastCoordinator(authUserRole.name) && (
            <Redirect exact from="/" to={PATH.PROJECT.LIST} />
          )}
        {authUserRoutes.map((route) => {
          // You can pass customKey if you expect a redirect to the same page and avoid extra mount/unmount
          return (
            <AppRoute
              key={route.customKey || route.path}
              path={route.path}
              exact={route.exact}
              component={route.component}
              isProtected={route.isProtected}
              isAuthenticated={isAuthenticated}
              authUserHasOtp={authUserHasOtp}
            />
          );
        })}
        <Route
          path="/"
          render={() => {
            if (!authUserHasOtp) {
              return <Redirect to={PATH.DASHBOARD} />;
            }
            return (
              <Redirect
                to={{
                  pathname: PATH.AUTH.CREATE_PASSWORD,
                }}
              />
            );
          }}
        />
      </Switch>
    </Suspense>
  );

  const [headerMountNode, setHeaderMountNode] = useState(null);
  const [printableContextNode, setPrintableContextNode] = useState(null);

  const getAppContent = () => {
    const content = (
      <HeaderContext.Provider value={{ headerMountNode, setHeaderMountNode }}>
        <ToastContainer />
        <PageLayout mobileMenuIsActive showSidebar={showSidebar}>
          {routerContent}
        </PageLayout>
      </HeaderContext.Provider>
    );

    if (loading) {
      return <Loader size={64} />;
    }

    if (!isAuthenticated || isStandalonePage) {
      return (
        <>
          <ToastContainer />
          {routerContent}
        </>
      );
    }

    return content;
  };

  return (
    <RealTimeProviderContext.Provider value={realTimeProviderData}>
      <PrintContext.Provider value={printableContextNode}>
        <div id={APP_CONTAINER_ID}>{getAppContent()}</div>

        <PrintableContainer handleContainerNode={setPrintableContextNode} />
      </PrintContext.Provider>
    </RealTimeProviderContext.Provider>
  );
};

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  authUserHasOtp: PropTypes.bool.isRequired,
  authUserRole: PropTypes.objectOf(PropTypes.any).isRequired,
  setMe: PropTypes.func.isRequired,
};

App.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  authUserParentOrg: null,
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  authUserHasOtp: authUserHasOtpSelector(state),
  authUserRole: authUserRoleSelector(state),
});

const mapDispatchToProps = {
  setMe: setMeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
