import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import HeaderContext from 'contexts/headerContext';
import { useSelector } from 'react-redux';
import { useDidMount } from 'hooks/lifecycleHooks';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import UserProfileHeader from 'components/layout/Header/UserProfileHeader';
import PATH, { CHAT_PATH } from 'routes/paths';
import { authUserSelector } from 'store/auth/selectors';
import RoleModel from 'api/models/RoleModel';
import PropTypes from 'prop-types';
import { ReactComponent as CafmMainLogo } from 'assets/coralLogo40x40.svg';
import { numericRegex } from '../../../utils/helpers';
import useMobile from '../../../stories/hooks/devices/useMobile';
import NavigationDropdown from '../../ui/NavigationDropdown';

const StyledHeader = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  border-bottom: 1px solid ${({ theme }) => theme.palette.themeCommon.separator};
  display: flex;
  align-items: center;
`;

const StyledUserProfileHeader = styled(UserProfileHeader)`
  margin-left: auto;
`;

const PortalContainer = styled.div`
  flex: 1 0 0;
  display: flex;
  height: 100%;
`;

const StyledCafmMainLogo = styled(CafmMainLogo)`
  margin-left: 24px;
`;

const NavigationDropdownWrapper = styled.div`
  margin-left: 15px;
`;

export default function Header({ showLoggedInUserInfo }) {
  const portalContainer = useRef(null);
  const { setHeaderMountNode } = useContext(HeaderContext);
  const history = useHistory();
  const onDashboardRoute = useRouteMatch(PATH.DASHBOARD).isExact;
  const excludedRoutesForPrevBtn = useRouteMatch([
    PATH.PROJECT.ONE,
    PATH.ORGANIZATION.ONE,
  ]);
  const authUser = useSelector(authUserSelector);
  const isChatOpened = history.location.pathname.includes(CHAT_PATH);
  const { pathname } = useLocation();
  const { isLessThanMobileL } = useMobile();
  const isInProject = new RegExp(`/projects/${numericRegex}`).test(pathname);

  const shouldShowPrevBtn =
    !onDashboardRoute &&
    !excludedRoutesForPrevBtn &&
    !isChatOpened &&
    RoleModel.authUserIsAtLeastCoordinator(authUser);

  useDidMount(() => {
    if (!portalContainer.current) {
      throw new Error("Portal container hasn't been mounted");
    }
    setHeaderMountNode(portalContainer.current);
  });

  return (
    <StyledHeader>
      {!isLessThanMobileL && !isInProject && (
        <StyledCafmMainLogo
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/')}
        />
      )}

      {shouldShowPrevBtn && (
        <NavigationDropdownWrapper>
          <NavigationDropdown />
        </NavigationDropdownWrapper>
      )}
      <PortalContainer className="portal-container" ref={portalContainer} />
      {showLoggedInUserInfo && <StyledUserProfileHeader />}
    </StyledHeader>
  );
}

Header.propTypes = {
  showLoggedInUserInfo: PropTypes.bool.isRequired,
};
