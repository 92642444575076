import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function ChatIconWithTextLinesInside({ size, ...rest }) {
  const computedSize = size || 24;

  return (
    <svg
      {...rest}
      width={computedSize}
      height={computedSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 12H17M7 15H12M7 9H17M21 12C21 16.9706 16.9706 21 12 21C10.5124 21 9.10904 20.6391 7.8728 20L3 21L4 16.1272C3.36093 14.891 3 13.4876 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke="#383C42"
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(ChatIconWithTextLinesInside);
