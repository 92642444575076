import AbstractRequest from './AbstractRequest';
import {
  COLON_SYMBOL,
  HTTP_STATUS_CODE,
  SEMI_SYMBOL,
  SEMICOLON_SYMBOL,
} from '../../utils/constants';
import PATH from '../../routes/paths';
import TypesHelper from '../../utils/types/TypesHelper';
import AbstractSortClientStore from '../../clientStore/AbstractSortClientStore';
import Pager from '../helpers/Pager';
import ListingResponse from './ListingResponse';
import { RequestMiddleware } from './RequestMiddleware';

export const API_METHODS = Object.freeze({
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
});

/**
 * Api resource request class
 */
class ApiResourceRequest extends AbstractRequest {
  get routes() {
    return {
      ...super.routes,
      GET: `${this.apiRoute}`,
      FIND: `${this.apiRoute}/`,
      CREATE: `${this.apiRoute}`,
      UPDATE: `${this.apiRoute}/`,
      DELETE: `${this.apiRoute}/`,
    };
  }

  async request(method, url, data = {}, extraConfig = {}, requestType) {
    let result;
    try {
      result = await super.request(method, url, data, extraConfig, requestType);
    } catch (e) {
      if (e?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
        window.location.href = window.location.origin + PATH.AUTH.LOGIN;
      }
      throw e;
    }

    return result;
  }

  async get(
    pager,
    query = {},
    newQueryFilterFormat = false,
    requestType = RequestMiddleware.REQUEST_TYPES.ALL_MATTER
  ) {
    pager && Pager.check(pager);
    const queryString = this.getQueryString(pager, query, newQueryFilterFormat);

    const response = await this.request(
      API_METHODS.GET,
      `${this.routes.GET}${queryString}`,
      {},
      {},
      requestType
    );

    return new ListingResponse(response?.data);
  }

  async lookupGet(
    pager,
    query = {},
    requestType = RequestMiddleware.REQUEST_TYPES.ALL_MATTER
  ) {
    return this.get(pager, query, requestType);
  }

  async find(
    id,
    query = {},
    requestType = RequestMiddleware.REQUEST_TYPES.ALL_MATTER
  ) {
    if (!id) {
      throw new Error('Id is required to find a resource!');
    }

    const queryString = this.buildQueryString(
      this.getRelationParams(query.relations),
      this.getExtraQueryParams(query.extra)
    );
    const response = await this.request(
      API_METHODS.GET,
      this.routes.FIND + id + queryString,
      {},
      {},
      requestType
    );

    return response?.data;
  }

  async create(
    attributes,
    requestType = RequestMiddleware.REQUEST_TYPES.ALL_MATTER
  ) {
    const response = await this.request(
      API_METHODS.POST,
      this.routes.CREATE,
      attributes,
      {},
      requestType
    );

    return response.data;
  }

  async update(
    id,
    attributes,
    method = API_METHODS.PATCH,
    requestType = RequestMiddleware.REQUEST_TYPES.ALL_MATTER
  ) {
    if (!id) {
      throw new Error('Id is required to update a resource!');
    }

    const response = await this.request(
      method,
      this.routes.UPDATE + id,
      attributes,
      {},
      requestType
    );

    return response?.data;
  }

  async partialUpdate(
    id,
    attributes,
    requestType = RequestMiddleware.REQUEST_TYPES.ALL_MATTER
  ) {
    return this.update(id, attributes, API_METHODS.PATCH, requestType);
  }

  async delete(id, requestType = RequestMiddleware.REQUEST_TYPES.ALL_MATTER) {
    if (!id) {
      throw new Error('Id is required to delete a resource!');
    }

    const response = await this.request(
      API_METHODS.DELETE,
      this.routes.DELETE + id,
      {},
      {},
      requestType
    );

    return response?.data;
  }

  buildQueryString(...queryParams) {
    let queryString = '';
    let firstQueryParamSet = false;
    queryParams
      .filter((queryParam) => queryParam)
      .forEach((queryParam) => {
        if (firstQueryParamSet) {
          queryString += queryParam;
        } else {
          queryString += `?${queryParam.slice(1)}`;
          firstQueryParamSet = true;
        }
      });

    return queryString;
  }

  /**
   *
   * @param pager
   * @returns {string}
   */
  getPaginationParams(pager) {
    if (!pager) {
      return '';
    }
    if (pager?.pageSize < 0) {
      return '';
    }
    return `&page=${pager?.pageNumber}&limit=${pager?.pageSize}`;
  }

  /**
   *
   * @param order
   * @returns {string}
   */
  getOrderParams(order) {
    if (!order) {
      return '';
    }

    const orderParams = '&order=';

    return `${orderParams}${AbstractSortClientStore.getSieveQueryAsStrFromObj(
      order
    )}`;
  }

  /**
   *
   * @param relations
   * @returns {string}
   */
  getRelationParams(relations) {
    if (!Array.isArray(relations)) {
      return '';
    }

    let relationParams = '&withRelations=';
    relationParams += relations.join(SEMI_SYMBOL);

    return relationParams;
  }

  /**
   *
   * @param {object} filter
   * @param {(string|string[])} filter.param
   * @param {boolean} newQueryFilterFormat
   * @returns {string}
   */
  getFilterParams(filter, newQueryFilterFormat) {
    if (!filter) {
      return '';
    }
    const filterParams = '&filter=';
    const params = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [param, value] of Object.entries(filter)) {
      if (TypesHelper.isArray(value)) {
        if (newQueryFilterFormat) {
          let values = '';
          value.forEach((item, index, array) => {
            if (index === array.length - 1) {
              values += item;
            } else {
              values += `${item}${SEMI_SYMBOL}`;
            }
          });
          values.length && params.push(`${param}${COLON_SYMBOL}${values}`);
        } else {
          value.forEach((item) =>
            params.push(`${param}${COLON_SYMBOL}${item}`)
          );
        }
      } else {
        params.push(`${param}${COLON_SYMBOL}${value}`);
      }
    }

    return (
      filterParams +
      params.join(newQueryFilterFormat ? SEMICOLON_SYMBOL : SEMI_SYMBOL)
    );
  }

  /**
   *
   * @param params
   * @returns {string}
   */
  getExtraQueryParams(params) {
    if (!params) {
      return '';
    }
    let extraParams = '';
    // eslint-disable-next-line no-restricted-syntax
    for (const [param, value] of Object.entries(params)) {
      extraParams += `&${param}=${value}`;
    }

    return extraParams;
  }

  getQueryString(pager, query, newQueryFilterFormat) {
    return this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query?.relations),
      this.getFilterParams(query?.filter, newQueryFilterFormat),
      this.getOrderParams(query?.order),
      this.getExtraQueryParams(query?.extra)
    );
  }
}

export default ApiResourceRequest;
