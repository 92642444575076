export default {
  layout: {
    itself: 'Layout',
    sidebar: {
      title: 'Files',
      noFilesYet: 'No files yet',
    },
    exportLayoutData: 'Export Layout Data',
  },
};
