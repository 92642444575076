import { useSelector } from 'react-redux';
import {
  authUserProjectCountSelector,
  authUserSelector,
} from '../../../store/auth/selectors';
import RoleModel from '../../../api/models/RoleModel';

export default () => {
  const authUserProjectCount = useSelector(authUserProjectCountSelector);
  const authUser = useSelector(authUserSelector);

  return (
    (RoleModel.authUserIs(authUser, RoleModel.ROLES.PROJECT_MANAGER) ||
      RoleModel.authUserIs(
        authUser,
        RoleModel.ROLES.ASSISTANT_PROJECT_MANAGER
      )) &&
    authUserProjectCount === 1
  );
};
