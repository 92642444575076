import AbstractModel from '../AbstractModel';
import { STATUS } from '../../../utils/constants';
import LevelModel from './LevelModel';

class BuildingModel extends AbstractModel {
  constructor(...params) {
    super(...params);
    if (this.status !== STATUS.LOADING && Array.isArray(this.levels)) {
      this.buildingLevels = this.levels.map(
        (level) => new LevelModel({ ...level })
      );
    }
  }
}

export default BuildingModel;
