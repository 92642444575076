import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function DailyCheckIcon({ size, color, ...rest }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.5 21H10L13 18C13.3333 17.6667 14 17 14 16C14 15 13.3181 14 12 14C10.5 14 10 15.5 10 16M21 21V18M21 14V18M21 18H17V14M7 19.4845C4.58803 17.8699 3 15.1204 3 12C3 11.4889 3.0426 10.9878 3.12444 10.5M20.9451 11C20.4476 6.50005 16.6326 3 12 3C8.87958 3 6 5 4.51555 7M4.5 3L4.51555 7M8.5 7H4.51555"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(DailyCheckIcon);
