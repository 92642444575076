export default {
  subcontractor: {
    itself: 'Subcontractant',
    plural: {
      itself: 'Subantreprenori',
    },
    attributes: {
      businessCategory: {
        itself: 'Categorie business',
        '*': 'Categorie business *',
        required: 'Categoria business e obligatorie',
      },
      category: {
        itself: 'Categorie',
        '*': 'Categorie *',
        required: 'Categoria e obligatorie',
      },
      company: {
        itself: 'Companie',
        '*': 'Companie *',
        required: 'Compania e obligatorie',
      },
      website: {
        itself: 'Website',
        '*': 'Website *',
        required: 'Website-ul e obligatoriu',
      },
      contact: {
        itself: 'Contact',
        '*': 'Contact *',
        required: 'Contact-ul e obligatoriu',
      },
      contacts: {
        itself: 'Contact(uri)',
        '*': 'Contact(uri) *',
        required: 'Contact(uri) e obligatoriu',
        add: 'Adaugă contact nou',
        name: {
          itself: 'Nume',
          '*': 'Nume *',
          required: 'Nume e obligatoriu',
        },
        department: {
          itself: 'Dept.',
          '*': 'Dept. *',
          required: 'Dept. e obligatoriu',
        },
        email: {
          itself: 'E-mail',
          '*': 'E-mail *',
          required: 'E-mail-ul e obligatoriu',
        },
        phoneNumber: {
          itself: 'Telefon',
          '*': 'Telefon *',
          required: 'Telefon-ul e obligatoriu',
        },
      },
      contactPerson: {
        itself: 'Persoana de contact',
        '*': 'Persoana de contact *',
        required: 'Persoana de contact e obligatorie',
      },
      description: {
        itself: 'Descriere',
        '*': 'Descriere *',
        required: 'Descrierea e obligatorie',
      },
    },
    selectOrAdd: 'Selectează/adaugă subcontractor',
    createSubcontractor: 'Creează subcontractor',
    addSubcontractor: 'Adaugă subcontractor',
    addNewSubcontractor: 'Adaugă subcontractor nou',
    saveNewSubcontractor: 'Salvează subcontractor nou',
    category: 'Categorie',
    companyName: 'Nume companie',
    businessWebsite: 'Business website',
    contactPerson: 'Persoană de contact',
    contactEmailAddress: 'Adresa de email de contact',
    contactPhoneNumber: 'Număr telefon de contact',
    addDescription: 'Adaugă descriere',
    editInformation: 'Modifică datele',
    subcontractorDetails: '‘Detalii subcontractor',
    startAddingSubcontractors:
      'Apăsați butonul albastru plus și începeți să adăugați subcontractanți',
  },
};
