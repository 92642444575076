export class EventBus {
  constructor() {
    this.bus = {};
  }

  $off(id, callback) {
    this.validateFields(id, callback);
    if (!Array.isArray(this.bus[id])) {
      return;
    }
    this.bus[id] = this.bus[id].filter(
      (busCallback) => busCallback !== callback
    );
  }

  $on(id, callback) {
    this.validateFields(id, callback);
    if (!Array.isArray(this.bus[id])) {
      this.bus[id] = [];
    }
    if (this.bus[id].some((busCallback) => busCallback === callback)) {
      return;
    }
    this.bus[id].push(callback);
  }

  $emit(id, ...params) {
    this.validateId(id);
    if (!Array.isArray(this.bus[id])) {
      return;
    }
    this.bus[id].forEach((busCallback) => busCallback(...params));
  }

  validateFields(id, callback) {
    this.validateId(id);
    this.validateCallback(callback);
  }

  validateId(id) {
    if (typeof id !== 'string') {
      throw new Error('id must be a string!');
    }
  }

  validateCallback(callback) {
    if (typeof callback !== 'function') {
      throw new Error('callback must be a function!');
    }
  }
}

const eventBus = new EventBus();

export default eventBus;

export const EVENT_LIST = Object.freeze({
  COMPONENTS: {
    SIMPLE_TABLE: {
      SCROLL_INTO_ROW_VIEW: 'GenericTable:SCROLL_INTO_ROW_VIEW',
    },
    USER_FORM: {
      USER_PROFILE_PHOTO_UPDATED: 'UserForm:USER_PROFILE_PHOTO_UPDATED',
    },
    SUBCONTRACTOR_FORM: {
      SUBCONTRACTOR_CREATED: 'SubcontractorForm:SUBCONTRACTOR_CREATED',
    },
    BUILDING_BOX: {
      BULK_BUILDINGS_IN_PROGRESS: 'BuildingBox:BULK_BUILDINGS_IN_PROGRESS',
      BULK_BUILDINGS_SUCCESS: 'BuildingBox:BULK_BUILDINGS_SUCCESS',
    },
    EQUIPMENT_BOX: {
      BULK_PROCEDURES_IN_PROGRESS: 'EquipmentBox:BULK_PROCEDURES_IN_PROGRESS',
      BULK_PROCEDURES_SUCCESS: 'EquipmentBox:BULK_PROCEDURES_SUCCESS',

      EQUIPMENT: {
        ADD_IDS_TO_PROCEDURES: 'EquipmentBox/Equipment:ADD_IDS_TO_PROCEDURES',
        ENABLE_OR_DISABLE_PROCEDURE:
          'EquipmentBox/Equipment:ENABLE_OR_DISABLE_PROCEDURE',
        MAINTENANCE_PLAN: {
          FILTER_CHANGED:
            'EquipmentBox/Equipment/BeneficiaryMaintenancePlan:FILTER_CHANGED',
        },
        STATUS_CHANGED: 'EquipmentBox/Equipment:STATUS_CHANGED',
      },
      PROCEDURE: {
        PRESELECT_CREATED_SUBCONTRACTOR:
          'EquipmentBox/Procedure:PRESELECT_CREATED_SUBCONTRACTOR',
        OPEN_SUBCONTRACTOR_MODAL:
          'EquipmentBox/Procedure:OPEN_SUBCONTRACTOR_MODAL',
        ADD_IDS_TO_OPERATIONS: 'EquipmentBox/Procedure:ADD_IDS_TO_OPERATIONS',
        OPEN_ENABLE_DISABLE_PROCEDURE_MODAL:
          'EquipmentBox/Procedure:OPEN_ENABLE_DISABLE_PROCEDURE_MODAL',
        CANCEL_ENABLE: 'EquipmentBox/Procedure:CANCEL_ENABLE',
        CANCEL_DISABLE: 'EquipmentBox/Procedure:CANCEL_DISABLE',
      },
      OPERATION: {
        ADD_IDS_TO_PARAMETERS: 'EquipmentBox/Operation:ADD_IDS_TO_PARAMETERS',
        ADD_IDS_TO_LABELS: 'EquipmentBox/Operation:ADD_IDS_TO_LABELS',
      },
    },
    DAILY_CHECK_BOX: {
      BULK_PROCEDURES_IN_PROGRESS: 'DailyCheckBox:BULK_PROCEDURES_IN_PROGRESS',
      BULK_PROCEDURES_SUCCESS: 'DailyCheckBox:BULK_PROCEDURES_SUCCESS',
    },
    DAILY_PROCEDURE_GROUP_FORM_MODAL: {
      GROUP_UPDATED: 'DailyProcedureGroupFormModal:GROUP_UPDATED',
    },
    DRAFT_PROJECT_PAGE: {
      SAVE_CHANGES_ALERT_BAR: {
        SAVE: 'DraftProjectPage/SaveChangesAlertBar:SAVE',
        DISCARD: 'DraftProjectPage/SaveChangesAlertBar:DISCARD',
        SHOW_ALERT_BAR: 'DraftProjectPage/SaveChangesAlertBar:SHOW_ALERT_BAR',
        HIDE_ALERT_BAR: 'DraftProjectPage/SaveChangesAlertBar:HIDE_ALERT_BAR',
        SHOW_LOADING_SPINNER:
          'DraftProjectPage/SaveChangesAlertBar:SHOW_LOADING_SPINNER',
        HIDE_LOADING_SPINNER:
          'DraftProjectPage/SaveChangesAlertBar:HIDE_LOADING_SPINNER',
      },
    },
    TICKET_FORM_MODAL: {
      TICKET_CREATED: 'TicketFormModal:TICKET_CREATED',
    },
    PROCUREMENT_FORM_MODAL: {
      PROCUREMENT_CREATED: 'ProcurementFromModal:PROCUREMENT_CREATED',
    },
    REAL_TIME: {
      TICKET: {
        CREATED: 'RealTime:TICKET_CREATED',
        UPDATED: 'RealTime:TICKET_UPDATED',
        STATUS_CHANGED: 'RealTime:TICKET_STATUS_CHANGED',
        PROCUREMENT_LINKED: 'RealTime:TICKET_PROCUREMENT_LINKED',
        PROCUREMENT_UNLINKED: 'RealTime:TICKET_PROCUREMENT_UNLINKED',
      },
      PROCUREMENT: {
        STATUS_CHANGED: 'RealTime:PROCUREMENT_STATUS_CHANGED',
        TICKET_LINKED: 'RealTime:PROCUREMENT_TICKET_LINKED',
        TICKET_UNLINKED: 'RealTime:PROCUREMENT_TICKET_UNLINKED',
      },
    },
  },
});

/*
 * IMPORTANT NOTE
 * if using useDidMount and useWillUnMount hooks
 * there is no need to wrap component callbacks in useCallback with no dependencies
 * because actually the callback used in useDidMount has the same reference as the one used in useWillUnmount
 * ^ this does not apply if you use closure functions because a new reference is created when using closures
 */
