import { useRouteMatch } from 'react-router-dom';
import WarrantyIcon from '@material-ui/icons/PolicyOutlined';
import ActivityReportIcon from '@material-ui/icons/LocalActivityOutlined';
import { useTranslation } from 'react-i18next';
import DashboardIcon from 'stories/ui/Icons/DashboardIcon';
import PATH from 'routes/paths';
import MaintenancePlanIcon from 'stories/ui/Icons/MaintenancePlanIcon';
import TicketsIcon from 'stories/ui/Icons/TicketsIcon';
import ProcurementIcon from 'stories/ui/Icons/ProcurementIcon';
import CategoryIcon from 'stories/ui/Icons/CategoryIcon';
import UserTeamIcon from 'stories/ui/Icons/UserTeamIcon';
import LayersIcon from 'stories/ui/Icons/LayersIcon';
import SubcontractIcon from 'stories/ui/Icons/SubcontractorIcon';
import SettingsIcon from 'stories/ui/Icons/SettingsIcon';
import { projectIdPattern } from 'routes/replacePatterns';
import ChatIconWithTextLinesInside from 'stories/ui/Icons/ChatIconWithTextLinesInside';
import InventoryIcon from 'stories/ui/Icons/InventoryIcon';
import DailyCheckIcon from 'stories/ui/Icons/DailyCheckIcon';
import CloseAltIcon from 'stories/ui/Icons/CloseAltIcon';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import useIsCurrentPMHasOneAssignedProject from 'containers/project/hooks/useIsCurrentPMHasOneAssignedProject';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import OrganizationIcon from 'stories/ui/Icons/OrganizationIcon';
import AnnouncementIcon from '@material-ui/icons/AnnouncementOutlined';
import ProcedureIcon from '@material-ui/icons/FormatListNumberedRtlOutlined';
import InfoIcon from 'stories/ui/Icons/InfoIcon';
import { useSelector } from 'react-redux';
import { authUserSelector } from 'store/auth/selectors';
import RoleModel from 'api/models/RoleModel';
import UnitsIcon from '@material-ui/icons/HdrStrong';

const PROJECT_MENU_ITEMS = [
  {
    icon: <DashboardIcon />,
    text: 'project.dashboard.itself',
    path: PATH.PROJECT.ONE,
  },
  {
    icon: <MaintenancePlanIcon />,
    text: 'equipment.maintenancePlan',
    path: PATH.PROJECT.MAINTENANCE_PLAN.LIST,
  },
  {
    icon: <DailyCheckIcon />,
    text: 'dailyCheck.itself',
    path: PATH.PROJECT.DAILY_CHECK.TOUR.LIST,
  },
  {
    icon: <TicketsIcon />,
    text: 'ticket.plural.itself',
    path: PATH.PROJECT.TICKET.LIST,
  },
  {
    icon: <ProcurementIcon />,
    text: 'procurement.itself',
    path: PATH.PROJECT.PROCUREMENT.LIST,
  },
  {
    icon: <WarrantyIcon />,
    text: 'project.generalContractorForm.warranty',
    path: PATH.PROJECT.WARRANTY_TRACKER.LIST,
  },
  {
    icon: <ActivityReportIcon />,
    text: 'activityReport.itself',
    path: PATH.PROJECT.ACTIVITY_REPORT.LIST,
  },
  {
    icon: <ReportOutlinedIcon />,
    text: 'report.plural.itself',
    path: PATH.PROJECT.REPORT.LIST,
  },
  {
    icon: <CategoryIcon />,
    text: 'equipment.itself',
    path: PATH.PROJECT.EQUIPMENT.LIST,
  },
  {
    icon: <LayersIcon />,
    text: 'layout.itself',
    path: PATH.PROJECT.LAYOUT.SHOW,
  },
  {
    icon: <InventoryIcon />,
    text: 'inventory.itself',
    path: PATH.PROJECT.INVENTORY.LIST,
  },
  {
    icon: <UserTeamIcon />,
    text: 'user.itself',
    path: PATH.PROJECT.USER.TEAM_MEMBER.LIST,
  },
  {
    icon: <SubcontractIcon />,
    text: 'subcontractor.plural.itself',
    path: PATH.PROJECT.SUBCONTRACTOR.LIST,
  },
  {
    icon: <ChatIconWithTextLinesInside />,
    text: 'chat.itself',
    path: PATH.PROJECT.CHAT.CHANNEL.LIST,
  },
  {
    icon: <SettingsIcon />,
    text: 'general.settings',
    path: PATH.PROJECT.SETTINGS.SHOW,
    alignBottom: true,
  },
  {
    icon: <CloseAltIcon />,
    text: 'project.exitProject',
    path: PATH.PROJECT.LIST,
  },
];

const ADMIN_MENU_ITEMS = [
  {
    icon: <DashboardIcon />,
    text: 'project.dashboard.itself',
    path: PATH.DASHBOARD,
  },
  {
    icon: <PersonIcon />,
    text: 'user.plural.itself',
    path: PATH.USER.LIST,
  },
  {
    icon: <OrganizationIcon />,
    text: 'realm.plural.itself',
    path: PATH.ORGANIZATION.LIST,
  },
  {
    icon: <SubcontractIcon />,
    text: 'subcontractor.plural.itself',
    path: PATH.SUBCONTRACTOR.LIST,
  },
  {
    icon: <AnnouncementIcon />,
    text: 'announcement.plural.itself',
    path: PATH.ANNOUNCEMENT.LIST,
  },
  {
    icon: <ProcedureIcon />,
    text: 'project.standardProcedures',
    path: PATH.STANDARD.PROCEDURE.LIST,
  },
  {
    icon: <DashboardIcon />,
    text: 'project.projects',
    path: PATH.PROJECT.LIST,
  },
  {
    icon: <UnitsIcon />,
    text: 'units.itself',
    path: PATH.UNITS.LIST,
  },
  {
    icon: <ChatIconWithTextLinesInside />,
    text: 'chat.itself',
    path: PATH.CHAT.PROJECT_CHANNEL.LIST,
  },
];

const BENEFICIARY_PROJECT_MENU_ITEMS = [
  {
    icon: <DashboardIcon />,
    text: 'project.dashboard.itself',
    path: PATH.PROJECT.ONE,
    isBeneficiaryOnly: true,
  },
  {
    icon: <WarrantyIcon />,
    text: 'project.generalContractorForm.warranty',
    path: PATH.PROJECT.WARRANTY_TRACKER.LIST,
    isBeneficiaryOnly: true,
  },
  {
    icon: <InfoIcon />,
    text: 'project.projectInfo',
    path: PATH.PROJECT.INFO.ONE,
  },
  {
    icon: <TicketsIcon />,
    text: 'ticket.plural.itself',
    path: PATH.PROJECT.TICKET.LIST,
  },
  {
    icon: <ProcurementIcon />,
    text: 'procurement.itself',
    path: PATH.PROJECT.PROCUREMENT.LIST,
  },
];

const getProjectMenuItems = (
  projectPageMatch,
  isCurrentPMHasOneAssignedProject,
  t
) => {
  const menuItems = PROJECT_MENU_ITEMS.map((menuItem) => ({
    ...menuItem,
    text: t(menuItem.text),
    path: menuItem.path.replace(
      projectIdPattern,
      projectPageMatch?.params?.projectId
    ),
  }));
  if (isCurrentPMHasOneAssignedProject) {
    menuItems.pop();
    menuItems[menuItems.length - 1].alignBottomSoloStyling = true;
  }
  return menuItems;
};

const getAdminMenuItems = (t) => {
  return ADMIN_MENU_ITEMS.map((menuItem) => ({
    ...menuItem,
    text: t(menuItem.text),
    path: menuItem.path,
  }));
};

const getBeneficiaryProjectMenuItems = (projectPageMatch, t, isBeneficiary) => {
  return BENEFICIARY_PROJECT_MENU_ITEMS.map((menuItem) => ({
    ...menuItem,
    text: t(menuItem.text),
    path: menuItem.path.replace(
      projectIdPattern,
      projectPageMatch?.params?.projectId
    ),
  })).filter((item) => (isBeneficiary ? true : !item.isBeneficiaryOnly));
};

export function useProjectMenuItems() {
  const projectPageMatch = useRouteMatch(PATH.PROJECT.ONE);
  const isCurrentPMHasOneAssignedProject = useIsCurrentPMHasOneAssignedProject();
  const { t } = useTranslation();

  return {
    menuItems: getProjectMenuItems(
      projectPageMatch,
      isCurrentPMHasOneAssignedProject,
      t
    ),
  };
}

export function useAdminMenuItems() {
  const { t } = useTranslation();

  return {
    menuItems: getAdminMenuItems(t),
  };
}

export function useBeneficiaryProjectMenuItems() {
  const projectPageMatch = useRouteMatch(PATH.PROJECT.ONE);
  const loggedInUser = useSelector(authUserSelector);
  const isLoggedInUserBeneficiary = RoleModel.authUserIsBeneficiary(
    loggedInUser
  );
  const { t } = useTranslation();

  return {
    menuItems: getBeneficiaryProjectMenuItems(
      projectPageMatch,
      t,
      isLoggedInUserBeneficiary
    ),
  };
}
