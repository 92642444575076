import TRAIL_ACTION from './constants';

const initialState = {
  initialized: false,
  current: null,
  trail: [],
};
export default function trailReducer(state = initialState, action) {
  let trail;
  let index;
  switch (action.type) {
    case TRAIL_ACTION.PUSH:
      trail = [...state.trail];
      index = trail.findIndex((item) => item.path === action.payload.path);

      if (index !== -1) {
        trail.splice(index + 1, trail.length - index);
      } else {
        trail.push(action.payload);
      }
      return {
        initialized: true,
        current: action.payload,
        trail,
      };
    case TRAIL_ACTION.GO_BACK:
      trail = [...state.trail];
      trail.pop();
      return {
        initialized: true,
        current: trail[trail.length - 1],
        trail,
      };
    default:
      return state;
  }
}
