export default {
  realm: {
    plural: {
      itself: 'Organizations',
    },
    attributes: {
      name: {
        itself: 'Name',
        '*': 'Name *',
        required: 'Name is required',
      },
      type: {
        itself: 'Type',
        '*': 'Type *',
        required: 'Type is required',
      },
      website: {
        itself: 'Website',
        '*': 'Website *',
        required: 'Website is required',
      },
      status: {
        itself: 'Status',
      },
    },
    addNewOrganization: 'Add new organization',
    editOrganization: 'Edit organization',
    saveOrganization: 'Save organization',
    saveNewOrganization: 'Save new organization',
    enterOrganizationName: 'Enter the name of the organization',
    generalManager: 'General Manager',
    generalManagerInfo: 'General manager info',
    buildingManagerInfo: 'Building Manager Info',
    officeManagerInfo: 'Office Manager Info',
    companyWebsite: 'Company website',
    list: {
      filter: {
        showSuspended: 'Show suspended organizations',
      },
    },
    types: {
      beneficiary: 'Beneficiary',
      tenant: 'Tenant',
      provider: 'Provider',
    },
    companyInformation: 'Company information',
  },
};
