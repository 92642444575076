import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { PRINTABLE_CONTAINER_ID } from './constants';

const PrintableContainer = ({ handleContainerNode }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    handleContainerNode(containerRef.current);
  }, [handleContainerNode]);

  return <div ref={containerRef} id={PRINTABLE_CONTAINER_ID} />;
};

PrintableContainer.propTypes = {
  handleContainerNode: PropTypes.func,
};

PrintableContainer.defaultProps = {
  handleContainerNode: () => {},
};

export default PrintableContainer;
