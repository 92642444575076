import {
  CAFM_WS_ENTRY_ROUTE,
  CAFM_WS_HOST,
  CAFM_WS_PORT,
  CAFM_WS_PROTOCOL,
} from '../../config';

class AbstractRealTimeProvider {
  static STATUS = Object.freeze({
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
  });

  static CLOSE_CODES = Object.freeze({
    NORMAL: 1000,
  });

  get wsProtocol() {
    return CAFM_WS_PROTOCOL;
  }

  get wsHost() {
    return CAFM_WS_HOST;
  }

  get wsPort() {
    return CAFM_WS_PORT;
  }

  get wsEntryRoute() {
    return CAFM_WS_ENTRY_ROUTE;
  }

  get wsConnectionPath() {
    const port = this.wsPort ? `:${this.wsPort}` : '';

    return `${this.wsProtocol}://${this.wsHost}${port}${this.wsEntryRoute}`;
  }
}

export default AbstractRealTimeProvider;
