import { addNotificationAction } from 'store/notifications/actions';
import AbstractEventCatcher from '../AbstractEventCatcher';

class ProjectBoardNotificationsEventCatcher extends AbstractEventCatcher {
  static PROJECT_BOARD_TYPES = Object.freeze({
    ITEM_CREATED: 'projectBoardCreated',
    REMIND_DATE: 'projectBoardReminderDate',
  });

  static PROJECT_BOARD_TYPES_AS_ARRAY = Object.freeze(
    Object.values(this.PROJECT_BOARD_TYPES)
  );

  static process({ notification, dispatch }) {
    dispatch(addNotificationAction(notification));
  }
}

export default ProjectBoardNotificationsEventCatcher;
