import AbstractEventCatcher from '../../AbstractEventCatcher';
import ChatPager from '../../../helpers/ChatPager';
import { generalChatProjectsConversationActionsCreators } from '../../../../store/chat/general/actions';

class ProjectMessagesBeneficiaryHistoryEventCatcher extends AbstractEventCatcher {
  static process(historyResponse, dispatch) {
    const pagerFromApi = new ChatPager(
      ChatPager.calculatePage(historyResponse.offset, historyResponse.limit),
      historyResponse.limit
    );

    if (!historyResponse.projectId || !historyResponse.chat.id) {
      throw new Error('projectId and chatId are required');
    }

    dispatch(
      generalChatProjectsConversationActionsCreators.updateHistory(
        historyResponse.projectId,
        historyResponse.chat.id,
        historyResponse.messages.messages,
        historyResponse.messages.total,
        pagerFromApi
      )
    );
  }
}

export default ProjectMessagesBeneficiaryHistoryEventCatcher;
