export const LOGIN = Object.freeze({
  REQUEST: 'auth/LOGIN_REQUEST',
  SUCCESS: 'auth/LOGIN_SUCCESS',
  ERROR: 'auth/LOGIN_ERROR',
});

export const ME = Object.freeze({
  REQUEST: 'auth/ME_REQUEST',
  SUCCESS: 'auth/ME_SUCCESS',
  ERROR: 'auth/ME_ERROR',
});

export const LOGOUT = Object.freeze({
  REQUEST: 'auth/LOGOUT_REQUEST',
  SUCCESS: 'auth/LOGOUT_SUCCESS',
  ERROR: 'auth/LOGOUT_ERROR',
});

export const SET_PASSWORD = Object.freeze({
  REQUEST: 'auth/SET_PASSWORD_REQUEST',
  SUCCESS: 'auth/SET_PASSWORD_SUCCESS',
  ERROR: 'auth/SET_PASSWORD_ERROR',
});

export const LOGIN_ACTIONS = Object.freeze({
  REQUEST: () => ({
    type: LOGIN.REQUEST,
  }),
  SUCCESS: (payload) => ({
    type: LOGIN.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: LOGIN.ERROR,
    payload,
    error: true,
  }),
});

export const ME_ACTIONS = Object.freeze({
  REQUEST: () => ({
    type: ME.REQUEST,
  }),
  SUCCESS: (payload) => ({
    type: ME.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: ME.ERROR,
    payload,
    error: true,
  }),
});

export const LOGOUT_ACTIONS = Object.freeze({
  REQUEST: () => ({
    type: LOGOUT.REQUEST,
  }),
  SUCCESS: (payload) => ({
    type: LOGOUT.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: LOGOUT.ERROR,
    payload,
    error: true,
  }),
});

export const SET_PASSWORD_ACTION = Object.freeze({
  REQUEST: () => ({
    type: SET_PASSWORD.REQUEST,
  }),
  SUCCESS: (payload) => ({
    type: SET_PASSWORD.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: SET_PASSWORD.ERROR,
    payload,
    error: true,
  }),
});
