import { createContext } from 'react';

const RealTimeProviderContext = createContext({
  sendMessage: () => {
    // eslint-disable-next-line no-console
    console.warn('empty sendMessage method from RealTimeProviderContext');
  },
});

export default RealTimeProviderContext;
