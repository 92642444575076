import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function ProcurementIcon({ size, color, ...rest }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7 15H11M13 12H7M7 9H13M16 15H20V18C20 19.1046 19.1046 20 18 20H16M16 15V20M16 15V4H4V18C4 19.1046 4.89543 20 6 20H16"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(ProcurementIcon);
