import { SEMI_SYMBOL } from '../utils/constants';
import { QUERY_KEYS } from '../routes/paths/query.constants';
import AbstractSieveClientStore from './AbstractSieveClientStore';

class AbstractSortClientStore extends AbstractSieveClientStore {
  static get queryKey() {
    return QUERY_KEYS.ORDER;
  }

  static get sieveType() {
    return 'sorting';
  }

  static getSieveQueryAsObj = (str) => {
    const orderQueryStr =
      new URLSearchParams(str).get(QUERY_KEYS.ORDER) || str || '';

    return orderQueryStr
      .split(SEMI_SYMBOL)
      .map((subOrderString) => {
        const splittedSubOrder = subOrderString.split(':');

        return splittedSubOrder?.length
          ? Object.fromEntries([splittedSubOrder])
          : false;
      })
      .filter((item) => !!item)
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});
  };

  static getSieveQueryAsStrFromObj = (obj) => {
    let subQueryResult = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const [param, value] of Object.entries(obj)) {
      subQueryResult += `${param}:${value},`;
    }
    subQueryResult = subQueryResult.slice(0, -1);

    return subQueryResult;
  };
}

export default AbstractSortClientStore;
