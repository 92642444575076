import { required } from 'utils/helpers';
import DateFormats from 'utils/dates';
import ApiResourceRequest, { API_METHODS } from './ApiResourceRequest';
import ListingResponse from './ListingResponse';
import Pager from '../helpers/Pager';
import { FileRequest } from './FileRequest';
import { DOCUMENT_FILE_EXTENSIONS } from '../../utils/constants';

export class PMPEventRequest extends ApiResourceRequest {
  get apiResource() {
    return 'pmp-event';
  }

  get dynamicIds() {
    return {
      PROJECT_ID: '{projectId}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_PROJECT_ID: `${this.apiRoute}/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      POST_MEASUREMENT: `${this.apiRoute}/measurement/{eventId}`,
      POST_OPERATION: `${this.apiRoute}/operation-data/{eventId}`,
      GET_IN_PROGRESS_BY_PROJECT_ID: `${this.apiRoute}/in-progress/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_RESOLVED_BY_PROJECT_ID: `${this.apiRoute}/resolved/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_BY_PROJECT_ID_YEARLY: `${this.apiRoute}/yearly/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_BY_PROJECT_ID_WEEK_EVENTS: `${this.apiRoute}/simplified/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_REPORT_FILE: `${this.apiRoute}/by-project-id/${this.dynamicIds.PROJECT_ID}/export`,
    };
  }

  getDateParams(startDate, endDate) {
    if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
      throw new Error('startDate and endDate must be instance of Date!');
    }
    const formattedStartDate = DateFormats.toISODate(startDate);
    const formattedEndDate = DateFormats.toISODate(endDate);

    return `&start=${formattedStartDate}&stop=${formattedEndDate}`;
  }

  async get(startDate, endDate, query = {}) {
    required({ startDate, endDate });
    const queryString = this.buildQueryString(
      this.getDateParams(startDate, endDate),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request('GET', this.routes.GET + queryString);

    return new ListingResponse(response?.data);
  }

  async getByProjectIdMonthly(projectId, startDate, endDate, query = {}) {
    required({ projectId, startDate, endDate });
    const queryString = this.buildQueryString(
      this.getDateParams(startDate, endDate),
      this.getRelationParams(query.relations),
      this.getPaginationParams(new Pager(1, -1)),
      this.getFilterParams(query.filter),
      this.getExtraQueryParams(query.search)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_BY_PROJECT_ID.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async getByProjectIdYearly(projectId, startDate, endDate, query = {}) {
    required({ projectId, startDate, endDate });
    const queryString = this.buildQueryString(
      this.getDateParams(startDate, endDate),
      this.getRelationParams(query.relations),
      this.getPaginationParams(new Pager(1, -1)),
      this.getFilterParams(query.filter),
      this.getExtraQueryParams(query.search)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_BY_PROJECT_ID_YEARLY.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async getByProjectIdWeekEvents(projectId, startDate, endDate, query = {}) {
    required({ projectId, startDate, endDate });
    const queryString = this.buildQueryString(
      this.getDateParams(startDate, endDate),
      this.getRelationParams(query.relations),
      this.getPaginationParams(new Pager(1, -1)),
      this.getFilterParams(query.filter),
      this.getExtraQueryParams(query.search)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_BY_PROJECT_ID_WEEK_EVENTS.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async getInProgressByProjectId(projectId) {
    required({ projectId });

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_IN_PROGRESS_BY_PROJECT_ID.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      )
    );

    return new ListingResponse(response?.data);
  }

  async getResolvedByProjectId(projectId) {
    required({ projectId });

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_RESOLVED_BY_PROJECT_ID.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      )
    );

    return new ListingResponse(response?.data);
  }

  async editMeasurement(eventId, data) {
    required({ eventId, data });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.POST_MEASUREMENT.replace('{eventId}', eventId),
      data
    );

    return response?.data;
  }

  async createEventOperationData(eventId, data) {
    required({ eventId, data });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.POST_OPERATION.replace('{eventId}', eventId),
      data
    );

    return response?.data;
  }

  async getReportFile(projectId, startDate, endDate, query = {}) {
    required({
      projectId,
      startDate,
      endDate,
    });

    const queryString = this.buildQueryString(
      this.getExtraQueryParams({
        start: DateFormats.formatToApiDate(startDate),
        stop: DateFormats.formatToApiDate(endDate),
        ...(query.extra || {}),
      }),
      this.getFilterParams(query.filter, true)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_REPORT_FILE.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      ) + queryString,
      {},
      {
        responseType: 'blob',
      }
    );

    return response.data;
  }

  async downloadReportFile(
    projectId,
    startDate,
    endDate,
    fileName,
    query = {}
  ) {
    required({ projectId, startDate, endDate, fileName });

    const reportBlob = await this.getReportFile(
      projectId,
      startDate,
      endDate,
      query
    );

    return FileRequest.downloadFileViaLink(reportBlob, {
      fileName,
      fileExtension: DOCUMENT_FILE_EXTENSIONS.excel,
    });
  }
}

export const pmpEventRequest = new PMPEventRequest();
