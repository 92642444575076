import AbstractEventCatcher from '../AbstractEventCatcher';
import { addNotificationAction } from '../../../store/notifications/actions';
import eventBus, { EVENT_LIST } from '../../../utils/eventBus/eventBus';

class TicketNotificationsEventCatcher extends AbstractEventCatcher {
  static TICKET_UPDATE_TYPES = Object.freeze({
    TICKET_CREATED: 'ticketCreated',
    STATUS_UPDATED: 'ticketStatusChanged',
    PRIORITY_UPDATED: 'ticketPriorityChanged',
  });

  static TICKET_UPDATE_TYPES_AS_ARRAY = Object.freeze(
    Object.values(this.TICKET_UPDATE_TYPES)
  );

  static emitEventBus(updateType) {
    switch (updateType) {
      case TicketNotificationsEventCatcher.TICKET_UPDATE_TYPES.TICKET_CREATED: {
        eventBus.$emit(EVENT_LIST.COMPONENTS.REAL_TIME.TICKET.CREATED);
        break;
      }
      default:
        break;
    }
  }

  static process(notificationInfo, dispatch) {
    this.emitEventBus(notificationInfo.type);

    dispatch(addNotificationAction(notificationInfo));
  }
}

export default TicketNotificationsEventCatcher;
