import { createActions, createActionTypesConfig } from '../helpers';

export const PROJECT_BOARD_LABEL = 'projectBoard';

/** Action Types */

export const GET_PERSONAL_PROJECT_BOARD_ITEMS_TYPES = createActionTypesConfig(
  PROJECT_BOARD_LABEL,
  'GET_PERSONAL_PROJECT_BOARD_ITEMS'
);

export const GET_TEAM_PROJECT_BOARD_ITEMS_TYPES = createActionTypesConfig(
  PROJECT_BOARD_LABEL,
  'GET_TEAM_PROJECT_BOARD_ITEMS'
);

export const CREATE_PROJECT_BOARD_TYPES = createActionTypesConfig(
  PROJECT_BOARD_LABEL,
  'CREATE_PROJECT_BOARD'
);
export const UPDATE_PROJECT_BOARD_TYPES = createActionTypesConfig(
  PROJECT_BOARD_LABEL,
  'UPDATE_PROJECT_BOARD'
);
export const FIND_PROJECT_BOARD_ITEM_TYPES = createActionTypesConfig(
  PROJECT_BOARD_LABEL,
  'GET_PROJECT_BOARD_ITEM'
);
export const DELETE_PROJECT_BOARD_ITEM_TYPES = createActionTypesConfig(
  PROJECT_BOARD_LABEL,
  'DELETE_PROJECT_BOARD_ITEM'
);
export const GET_PROJECT_BOARD_ITEM_LOGS_TYPES = createActionTypesConfig(
  PROJECT_BOARD_LABEL,
  'GET_PROJECT_BOARD_ITEM_LOGS'
);

export const GET_PERSONAL_PROJECT_BOARD_ITEMS_ACTION = createActions(
  GET_PERSONAL_PROJECT_BOARD_ITEMS_TYPES
);

export const GET_TEAM_PROJECT_BOARD_ITEMS_ACTION = createActions(
  GET_TEAM_PROJECT_BOARD_ITEMS_TYPES
);

export const CREATE_PROJECT_BOARD_ACTION = createActions(
  CREATE_PROJECT_BOARD_TYPES
);
export const UPDATE_PROJECT_BOARD_ACTION = createActions(
  UPDATE_PROJECT_BOARD_TYPES
);
export const FIND_PROJECT_BOARD_ITEM_ACTION = createActions(
  FIND_PROJECT_BOARD_ITEM_TYPES
);
export const DELETE_PROJECT_BOARD_ITEM_ACTION = createActions(
  DELETE_PROJECT_BOARD_ITEM_TYPES
);
export const GET_PROJECT_BOARD_ITEM_LOGS_ACTION = createActions(
  GET_PROJECT_BOARD_ITEM_LOGS_TYPES
);
