export default {
  dailyCheckProcedure: {
    itself: 'Procedure',
    plural: {
      itself: 'Procedures',
    },
    selectEquipmentOrGroup: 'Select equipment or group',
    equipmentOrGroup: 'Equipment or group',
    operation: {
      itself: 'Operation',
      plural: {
        itself: 'Operations',
      },
    },
    description: 'Description',
    saveProcedure: 'Save procedure',
    saveNewProcedure: 'Save new procedure',
  },
};
