import { createActions, createActionTypesConfig } from '../helpers';

export const GET = Object.freeze({
  REQUEST: 'pmpEvent/GET_REQUEST',
  SUCCESS: 'pmpEvent/GET_SUCCESS',
  ERROR: 'pmpEvent/GET_ERROR',
});

export const GET_BY_PROJECT_ID = Object.freeze({
  REQUEST: 'pmpEvent/GET_BY_PROJECT_ID_REQUEST',
  SUCCESS: 'pmpEvent/GET_BY_PROJECT_ID_SUCCESS',
  ERROR: 'pmpEvent/GET_BY_PROJECT_ID_ERROR',
});

export const GET_BY_PROJECT_ID_YEARLY = Object.freeze({
  REQUEST: 'pmpEvent/GET_BY_PROJECT_ID_YEARLY_REQUEST',
  SUCCESS: 'pmpEvent/GET_BY_PROJECT_ID_YEARLY_SUCCESS',
  ERROR: 'pmpEvent/GET_BY_PROJECT_ID_YEARLY_ERROR',
});

export const FIND_YEARLY_PMP_EVENT = Object.freeze({
  REQUEST: 'pmpEvent/FIND_YEARLY_PMP_EVENT_REQUEST',
  SUCCESS: 'pmpEvent/FIND_YEARLY_PMP_EVENT_SUCCESS',
  ERROR: 'pmpEvent/FIND_YEARLY_PMP_EVENT_ERROR',
});

export const GET_BY_PROJECT_ID_WEEK_EVENTS = Object.freeze({
  REQUEST: 'pmpEvent/GET_BY_PROJECT_ID_WEEK_EVENTS_REQUEST',
  SUCCESS: 'pmpEvent/GET_BY_PROJECT_ID_WEEK_EVENTS_SUCCESS',
  ERROR: 'pmpEvent/GET_BY_PROJECT_ID_WEEK_EVENTS_ERROR',
});

export const WEEK_EVENTS_LOADING = Object.freeze({
  UPDATE: 'pmpEvent/WEEK_EVENTS_LOADING_UPDATE',
});

export const FIND = Object.freeze({
  REQUEST: 'pmpEvent/FIND_REQUEST',
  SUCCESS: 'pmpEvent/FIND_SUCCESS',
  ERROR: 'pmpEvent/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'pmpEvent/CREATE_REQUEST',
  SUCCESS: 'pmpEvent/CREATE_SUCCESS',
  ERROR: 'pmpEvent/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'pmpEvent/UPDATE_REQUEST',
  SUCCESS: 'pmpEvent/UPDATE_SUCCESS',
  ERROR: 'pmpEvent/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'pmpEvent/DELETE_REQUEST',
  SUCCESS: 'pmpEvent/DELETE_SUCCESS',
  ERROR: 'pmpEvent/DELETE_ERROR',
});

export const GET_IN_PROGRESS_PMP_EVENTS_BY_PROJECT_ID = createActionTypesConfig(
  'pmpEvent',
  'GET_IN_PROGRESS_PMP_EVENTS_BY_PROJECT_ID'
);

export const GET_RESOLVED_PMP_EVENTS_BY_PROJECT_ID = createActionTypesConfig(
  'pmpEvent',
  'GET_RESOLVED_PMP_EVENTS_BY_PROJECT_ID'
);
export const GET_PMP_EVENTS_BY_PROJECT_ID_INFINITE_SCROLL = createActionTypesConfig(
  'pmpEvent',
  'GET_PMP_EVENTS_BY_PROJECT_ID_INFINITE_SCROLL'
);

export const GET_PMP_EVENTS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});
export const GET_PMP_EVENTS_BY_PROJECT_ID_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_PROJECT_ID.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_PROJECT_ID.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_PROJECT_ID.ERROR,
    payload,
    error: true,
  }),
});

export const GET_BY_PROJECT_ID_YEARLY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_PROJECT_ID_YEARLY.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_PROJECT_ID_YEARLY.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_PROJECT_ID_YEARLY.ERROR,
    payload,
    error: true,
  }),
});

export const GET_BY_PROJECT_ID_WEEK_EVENTS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_PROJECT_ID_WEEK_EVENTS.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_PROJECT_ID_WEEK_EVENTS.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_PROJECT_ID_WEEK_EVENTS.ERROR,
    payload,
    error: true,
  }),
});
export const WEEK_EVENTS_LOADING_ACTION = Object.freeze({
  UPDATE: (payload) => ({
    type: WEEK_EVENTS_LOADING.UPDATE,
    payload,
  }),
});

export const FIND_PMP_EVENT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_YEARLY_PMP_EVENT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND_YEARLY_PMP_EVENT.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND_YEARLY_PMP_EVENT.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND_YEARLY_PMP_EVENT.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_PMP_EVENT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_PMP_EVENT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_PMP_EVENT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});

export const GET_IN_PROGRESS_PMP_EVENTS_BY_PROJECT_ID_ACTION = createActions(
  GET_IN_PROGRESS_PMP_EVENTS_BY_PROJECT_ID
);

export const GET_RESOLVED_PMP_EVENTS_BY_PROJECT_ID_ACTION = createActions(
  GET_RESOLVED_PMP_EVENTS_BY_PROJECT_ID
);
export const GET_PMP_EVENTS_BY_PROJECT_ID_INFINITE_SCROLL_ACTION = createActions(
  GET_PMP_EVENTS_BY_PROJECT_ID_INFINITE_SCROLL
);
