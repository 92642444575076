import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GraySpan } from '../../../css/common';
import UserTag from '../UserTag/UserTag';
import Dropdown from '../Dropdown/Dropdown';
import EditIcon from '../Icons/EditIcon';
import BuildingIcon from '../Icons/BuildingIcon';
import CloseIcon from '../Icons/CloseIcon';

const Container = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.themeCommon.white.main};
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 4px;
    right: 4px;
    border-bottom: 1px solid
      ${({ theme }) => theme.palette.themeCommon.black.light};
  }
`;

const Content = styled.div`
  padding: 8px 16px 16px 16px;
  display: flex;
  height: 100%;
  font-weight: 500;
`;

const CountResources = styled.div`
  font-size: ${({ theme }) =>
    theme.typography.pxToRem(theme.typography.fontSize - 2)};
`;

const UsersContainer = styled.div`
  flex: 0 0 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  ::after {
    content: '';
    position: absolute;
    right: 0;
    top: -4px;
    bottom: -12px;
    border-right: 1px solid
      ${({ theme }) => theme.palette.themeCommon.black.light};
  }
`;

const List = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const UserList = styled(List)``;

const LocationsContainers = styled.div`
  flex: 1 0 0;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const LocationList = styled(List)``;

export const Location = styled.div`
  border-radius: 6px;
  padding: 7px 12px;
  display: flex;
  flex-direction: column;
  background-color: ${({ color }) => color};
  line-height: 1.125rem;
  font-size: ${({ theme }) =>
    theme.typography.pxToRem(theme.typography.fontSize - 2)};
`;

const displayPlural = (length) => {
  return length === 1 ? '' : 's';
};

export class LocationColor {
  colors = ['#F5ECFE', '#FDD9DB', '#D9F0FD', '#D9FDF6', '#EBEAE8', '#E5F0EC'];

  generateRandomLightColor() {
    const random = Math.random();
    const red = 253 + Math.floor((217 - 253) * random);
    const green = 217 + Math.floor((253 - 217) * random);
    const blue = 219 + Math.floor((246 - 219) * random);

    return `rgb(${red}, ${green}, ${blue})`;
  }

  getBuildingColorsFromLS() {
    try {
      const localStorageBuildingColors = localStorage.getItem(
        'building-colors'
      );
      return localStorageBuildingColors
        ? JSON.parse(localStorageBuildingColors)
        : {};
    } catch (e) {
      console.log(e);
      return {};
    }
  }

  setBuildingColorsToLS({ index, currentRandomColor }) {
    const buildingColors = this.getBuildingColorsFromLS();
    localStorage.setItem(
      'building-colors',
      JSON.stringify({
        ...buildingColors,
        [index]: currentRandomColor,
      })
    );
  }

  pickColor(index) {
    if (index < this.colors.length) {
      return this.colors[index];
    }

    const buildingColors = this.getBuildingColorsFromLS();
    const existColor = buildingColors[index];
    if (existColor) {
      return existColor;
    }

    const currentRandomColor = this.generateRandomLightColor();
    this.setBuildingColorsToLS({ index, currentRandomColor });
    return currentRandomColor;
  }
}

export default function TenantOrganization({
  tenantName,
  onEditTenant,
  onRemoveTenant,
  onClickOrganization,
  users,
  locations,
  onUserTagClick,
  buildingColors,
  showDropdown,
  ...rest
}) {
  const { t } = useTranslation();
  return (
    <Container {...rest}>
      <TitleContainer>
        <h3>{tenantName}</h3>
        {showDropdown ? (
          <Dropdown
            options={[
              {
                option: t('general.edit'),
                icon: <EditIcon />,
                onClick: onEditTenant,
              },
              {
                option: t('realm.plural.itself'),
                icon: <BuildingIcon />,
                onClick: onClickOrganization,
              },
              {
                option: t('project.tenant.removeTenant'),
                icon: <CloseIcon />,
                onClick: onRemoveTenant,
              },
            ]}
          />
        ) : null}
      </TitleContainer>
      <Content>
        <UsersContainer>
          <CountResources>
            <GraySpan>{users.length}</GraySpan> {t('user.itself')}
            {displayPlural(users.length)}
          </CountResources>
          <UserList>
            {users.map((user) => (
              <UserTag
                onClick={() => {
                  onUserTagClick(user.id);
                }}
                key={user.id}
                name={user.name}
                avatarImgUrl={user.avatarImgUrl}
              />
            ))}
          </UserList>
        </UsersContainer>
        <LocationsContainers>
          <CountResources>
            <GraySpan>{locations.length}</GraySpan> {t('general.location')}
            {displayPlural(locations.length)}
          </CountResources>
          <LocationList>
            {locations.map((location) => (
              <Location
                key={location.id}
                color={buildingColors[location?.buildingId]}
              >
                <span>{location.building}</span>
                <span>{location.level}</span>
                <span>{location.room || 'All rooms'}</span>
              </Location>
            ))}
          </LocationList>
        </LocationsContainers>
      </Content>
    </Container>
  );
}

TenantOrganization.propTypes = {
  tenantName: PropTypes.string.isRequired,
  onEditTenant: PropTypes.func.isRequired,
  onRemoveTenant: PropTypes.func.isRequired,
  onClickOrganization: PropTypes.func.isRequired,
  onUserTagClick: PropTypes.func,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatarImgUrl: PropTypes.string,
    })
  ),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      buildingId: PropTypes.number.isRequired,
      building: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
      room: PropTypes.string,
    })
  ),
  showDropdown: PropTypes.bool,
  buildingColors: PropTypes.objectOf(PropTypes.string),
};

TenantOrganization.defaultProps = {
  onUserTagClick: () => {},
  users: [],
  locations: [],
  buildingColors: {},
  showDropdown: true,
};
