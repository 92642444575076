import general from './general/ro';
import realm from './realm/ro';
import user from './user/ro';
import building from './buildingBox/building/ro';
import level from './buildingBox/building/level/ro';
import surface from './buildingBox/building/level/surface/ro';
import room from './buildingBox/building/level/room/ro';
import project from './project/ro';
import subcontractor from './subcontractor/ro';
import role from './role/ro';

import dailyCheck from './dailyCheckBox/ro';
import dailyCheckProcedure from './dailyCheckBox/procedure/ro';
import dailyCheckOperation from './dailyCheckBox/procedure/operation/ro';

import group from './equipmentBox/category/group/ro';
import equipment from './equipmentBox/category/group/equipment/ro';
import procedure from './equipmentBox/category/group/equipment/procedure/ro';
import operation from './equipmentBox/category/group/equipment/procedure/operation/ro';
import parameter from './equipmentBox/category/group/equipment/procedure/operation/parameter/ro';

import ticket from './ticket/ro';
import procurement from './procurement/ro';

import metaTitles from './metaTitles/ro';

import pmp from './pmp/ro';
import inventory from './inventory/ro';

import chat from './chat/ro';
import error from './apiErrors/ro';

import standardProcedure from './standard/procedure/ro';

import warrantyTracker from './warrantyTracker/ro';

import dailyProcedure from './dailyProcedure/ro';
import dailyCheckTour from './dailyCheck/tour/ro';
import dailyCheckTourEvent from './dailyCheck/tour/event/ro';

import layout from './layout/ro';

import announcement from './announcement/ro';
import activityReport from './activityReport/ro';
import report from './report/ro';
import units from './units/ro';

const ro = {
  translations: {
    ...general,
    ...user,
    ...realm,
    ...building,
    ...level,
    ...surface,
    ...room,
    ...project,
    ...subcontractor,
    ...role,
    ...units,

    ...dailyCheck,
    ...dailyCheckProcedure,
    ...dailyCheckOperation,

    ...group,
    ...equipment,
    ...procedure,
    ...operation,
    ...parameter,

    ...ticket,

    ...procurement,

    ...metaTitles,

    ...pmp,

    ...inventory,

    ...chat,

    ...error,

    ...standardProcedure,

    ...warrantyTracker,

    ...dailyProcedure,
    ...dailyCheckTour,
    ...dailyCheckTourEvent,

    ...layout,

    ...announcement,
    ...activityReport,
    ...report,

    cancel: 'Renunță',
    submit: 'Trimite',
    welcome: 'Salut {{name}} aceasta este o pagină protejată!',
    login: 'Autentificare',
    logout: 'Deconectare',
    back: 'Înapoi',
    next: 'Următorul',
    generate: 'Genera',
    save: 'Salvează',
    saveAndContinue: 'Salvează & continue',
    skip: 'Skip',
    signIn: 'Autentificare',
    enterCredentials: 'Introdu credențialele tale',
    createStrongPassword: 'Creează o parolă puternică',
    profilePic: 'Poză de profil',
    yesterday: 'Ieri',
    today: 'Azi',
    tomorrow: 'Mâine',
    outdated: 'Învechit',
    nextWeeks: 'Săptămânile viitoare',
    week: 'Săptămână',
    reminder: 'Aducere aminte',

    newPassword: 'Parolă nouă',
    repeatNewPassword: 'Repetă parola nouă',

    invalidEmail: 'Adresă de email nevalidă',
    enterEmail: 'Introdu adresa de email',
    enterUsername: 'Introdu numele de utilizator',
    enterPassword: 'Introdu parola',
    enterPasswordOrPin: 'Introduceți parola sau codul pin',

    browseOrDragDrop: 'Răsfoiți sau glisați fișier',
    dropFile: 'Glisați fișierul',

    username: 'Nume utilizator',
    usernameRequired: 'Numele de utilizator e obligatoriu',

    emailAddress: 'Adresa de email',
    emailRequired: 'Adresa de email e obligatorie',

    password: 'Parola',
    passwordRequired: 'Parola e obligatorie',

    pin: 'Pin',
    pinRequired: 'Pin e obligatorie',

    name: 'Nume',
    nameRequired: 'Numele este obligatoriu',
    firstNameRequired: 'Prenume este obligatoriu',
    lastNameRequired: 'Numele este obligatoriu',

    resourceType: 'Tip de resursă',
    resourceTypeRequired: 'Tipul de resursă e obligatoriu',

    selectPermission: 'Selectează o permisiune',
    permissionRequired: 'Permisiunea e obligatorie',

    selectUser: 'Selectează un utilizator',
    userRequired: 'Utilizatorul e obligatoriu',

    selectPermissionContext: 'Selectează un context de permisiune',
    permissionContextRequired: 'Contextul de permisiune e obligatoriu',

    assign: 'Asignare',
    unassign: 'Ștergere asignare',

    assignmentType: 'Tip asignare',
    assignmentTypeRequired: 'Tipul asignării e obligatoriu',

    accessType: 'Tip acces',
    accessTypeRequired: 'Tipul de acces e obligatoriu',

    grantAccess: 'Permite acces',
    denyAccess: 'Refuză acces',

    selectResource: 'Selectează o resursă',
    resourceRequired: 'Resursa e obligatorie',

    assignPermissionPermissionContext:
      'Asignează permisiune la context permisiune',
    assignPermissionRole: 'Asignează permisiune la rolul',
    assignUserRole: 'Asignează utilizator la rol',
    assignAccessResource: 'Asignează acces la o resursă',
    assignAccessResourceRole: 'Asignează acces resursă la rolul',

    allowedActions: 'Acțiuni permise',
    action: 'Acțiune',

    users: 'Utilizatori',
    goToUserList: 'Mergi la lista de utilizatori',
    backToUserList: 'Înapoi la lista de utilizatori',
    addNewUser: 'Adaugă un utilizator',
    createUpdateUser: 'Creează sau actualizează utilizator',

    userGroups: 'Grupuri de utilizatori',
    goToUserGroupList: 'Mergeți la lista de grupuri de utilizatori',
    backToUserGroupList: 'Înapoi la lista de grupuri utilizatori',
    addNewUserGroup: 'Adaugă un grup de utilizatori nou',
    createUpdateUserGroup: 'Creează sau actualizează grup de utilizatori',

    roles: 'Roluri',
    goToRoleList: 'Mergeți la lista de roluri',
    backToRoleList: 'Înapoi la lista de roluri',
    addNewRole: 'Adaugă un rol nou',
    createUpdateRole: 'Creează sau actualizează rol',

    permissions: 'Permisiuni',
    goToPermissionList: 'Mergeți la lista de permisiuni',
    backToPermissionList: 'Înapoi la lista de permisiuni',
    addNewPermission: 'Adaugă o permisiune nouă',
    createUpdatePermission: 'Creează sau actualizează permisiune',

    permissionContexts: 'Contexte de permisiuni',
    goToPermissionContextList: 'Mergeți la lista de contexte de permisiuni',
    backToPermissionContextList: 'Înapoi la lista de contexte de permisiuni',
    addNewPermissionContext: 'Adaugă un context de permisiune nou',
    createUpdatePermissionContext:
      'Creează sau actualizează context de permisiune',

    projects: 'Proiecte',
    goToProjectList: 'Mergeți la lista de proiecte',
    backToProjectList: 'Înapoi la lista de proiecte',
    addNewProject: 'Adaugă un proiect nou',
    createUpdateProject: 'Creează sau actualizează proiect',

    hiddenItems: 'Articole {{count}} ascunse',
    hide: 'Ascunde',
    more: 'încă {{count}}',

    changeDueDate: 'Schimbați data scadentă',
    addNote: 'Adăugați notă',

    view: 'Vizualizează',

    language: 'Limba',
    ro: 'Română',
    en: 'Engleză',
  },
};

export default ro;
