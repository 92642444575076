import { lazy } from 'react';
import PATH from 'routes/paths';

const Ticket = lazy(() =>
  import('components/layout/mainPages/tenantPages/ticket/Ticket')
);
const TicketList = lazy(() =>
  import('components/layout/mainPages/tenantPages/ticket/TicketList')
);

const TICKET = {
  component: Ticket,
  path: PATH.PROJECT.TICKET.ONE,
  exact: true,
  isProtected: true,
};

const TICKET_LIST = {
  component: TicketList,
  path: PATH.PROJECT.TICKET.LIST,
  exact: false,
  isProtected: true,
};

/**
 * It's very important to put TICKET before TICKET_LIST because we need Switch statement to see TICKET first
 * as TICKET_LIST exact is false and we don't want TicketModalController from inside TicketList
 * to catch TICKET which is a separate page in the app
 */
export default [TICKET, TICKET_LIST];
