import {
  UPDATE_TICKET_CHAT_UNREAD_COUNTS,
  UPDATE_TICKET_CHAT_LAST_READ_MESSAGE_ID,
  UPDATE_PROCUREMENT_CHAT_UNREAD_COUNTS,
  UPDATE_PROCUREMENT_CHAT_LAST_READ_MESSAGE_ID,
  RESET_RESOURCE_CHAT_STATE,
  RESOURCE_TICKET_CHAT_ACTIONS_TYPES,
  RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES,
} from '../constants';
import ChatModel from '../../../realTimeApi/models/ChatModel';
import setResource from './helpers/setResource';
import setResourceChat from './helpers/setResourceChat';
import setChatHistory from './helpers/setChatHistory';
import setAddMessage from './helpers/setAddMessage';
import setRemoveMessage from './helpers/setRemoveMessage';
import setUpdateUnreadMessages from './helpers/setUpdateUnreadMessages';
import setAddParticipant from './helpers/setAddParticipant';
import setRemoveParticipant from './helpers/setRemoveParticipant';
import setUpdateLastReadMessageUsingHistory from './helpers/setUpdateLastReadMessageUsingHistory';
import setHistoryLastReadMessage from './helpers/setHistoryLastReadMessage';
import setUpdateLastReadMessage from './helpers/setUpdateLastReadMessage';
import setAddLastReadMessageToEachHistory from './helpers/setAddLastReadMessageToEachHistory';
import {
  createTicketChatMessageModel,
  createProcurementChatMessageModel,
} from '../helpers/createResourceChatMessageModel';

const TICKET_CONFIG = {
  propName: 'ticket',
  messageModelCreator: createTicketChatMessageModel,
};

const PROCUREMENT_CONFIG = {
  propName: 'procurement',
  messageModelCreator: createProcurementChatMessageModel,
};

const initialState = {
  [TICKET_CONFIG.propName]: {
    /**
     * [ticketId]: {
     *    resourceInfo: {},
     *    chats: {
     *        [chatId]: {
     *              chatInfo: null,
     *              lastReadMessageId: null,
     *              history: [],
     *              nextPager: new ChatPager(),
     *              pager: new ChatPager(),
     *              total: null,
     *              type: 'internal',
     *              participants: []
     *        }
     *    }
     * }
     * */

    chatType: {
      [ChatModel.RESOURCE_CHAT_TYPE.EXTERNAL]: {
        unreadCounts: 0,
        lastReadMessageId: null,
      },
      [ChatModel.RESOURCE_CHAT_TYPE.INTERNAL]: {
        unreadCounts: 0,
        lastReadMessageId: null,
      },
    },
  },
  [PROCUREMENT_CONFIG.propName]: {
    /**
     * [ticketId]: {
     *    resourceInfo: {},
     *    chats: {
     *        [chatId]: {
     *              chatInfo: null,
     *              lastReadMessageId: null,
     *              history: [],
     *              nextPager: new ChatPager(),
     *              pager: new ChatPager(),
     *              total: null,
     *              type: 'internal',
     *              participants: []
     *        }
     *    }
     * }
     * */

    chatType: {
      [ChatModel.RESOURCE_CHAT_TYPE.EXTERNAL]: {
        unreadCounts: 0,
        lastReadMessageId: null,
      },
      [ChatModel.RESOURCE_CHAT_TYPE.INTERNAL]: {
        unreadCounts: 0,
        lastReadMessageId: null,
      },
    },
  },
};

export default function resourceChatReducer(state = initialState, action) {
  switch (action.type) {
    /** @Tickets */

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.SET_RESOURCE: {
      return setResource(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.SET_CHAT: {
      return setResourceChat(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.SET_CHAT_HISTORY: {
      return setChatHistory(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.ADD_MESSAGE: {
      return setAddMessage(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.REMOVE_MESSAGE: {
      return setRemoveMessage(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.UPDATE_UNREAD_MESSAGES: {
      return setUpdateUnreadMessages(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.ADD_PARTICIPANT: {
      return setAddParticipant(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.REMOVE_PARTICIPANT: {
      return setRemoveParticipant(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.UPDATE_LAST_READ_MESSAGE_USING_HISTORY: {
      return setUpdateLastReadMessageUsingHistory(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.SET_HISTORY_LAST_READ_MESSAGE: {
      return setHistoryLastReadMessage(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.UPDATE_LAST_READ_MESSAGE: {
      return setUpdateLastReadMessage(state, action, TICKET_CONFIG);
    }

    case RESOURCE_TICKET_CHAT_ACTIONS_TYPES.ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY: {
      return setAddLastReadMessageToEachHistory(state, action, TICKET_CONFIG);
    }

    /** @Procurements */

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.SET_RESOURCE: {
      return setResource(state, action, PROCUREMENT_CONFIG);
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.SET_CHAT: {
      return setResourceChat(state, action, PROCUREMENT_CONFIG);
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.SET_CHAT_HISTORY: {
      return setChatHistory(state, action, PROCUREMENT_CONFIG);
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.ADD_MESSAGE: {
      return setAddMessage(state, action, PROCUREMENT_CONFIG);
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.REMOVE_MESSAGE: {
      return setRemoveMessage(state, action, PROCUREMENT_CONFIG);
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.UPDATE_UNREAD_MESSAGES: {
      return setUpdateUnreadMessages(state, action, PROCUREMENT_CONFIG);
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.ADD_PARTICIPANT: {
      return setAddParticipant(state, action, PROCUREMENT_CONFIG);
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.REMOVE_PARTICIPANT: {
      return setRemoveParticipant(state, action, PROCUREMENT_CONFIG);
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.UPDATE_LAST_READ_MESSAGE_USING_HISTORY: {
      return setUpdateLastReadMessageUsingHistory(
        state,
        action,
        PROCUREMENT_CONFIG
      );
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.SET_HISTORY_LAST_READ_MESSAGE: {
      return setHistoryLastReadMessage(state, action, PROCUREMENT_CONFIG);
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.UPDATE_LAST_READ_MESSAGE: {
      return setUpdateLastReadMessage(state, action, PROCUREMENT_CONFIG);
    }

    case RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES.ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY: {
      return setAddLastReadMessageToEachHistory(
        state,
        action,
        PROCUREMENT_CONFIG
      );
    }

    case UPDATE_TICKET_CHAT_UNREAD_COUNTS:
      return {
        ...state,
        ticket: {
          ...state.ticket,
          chatType: {
            ...state.ticket.chatType,
            [action.payload.chatType]: {
              ...state.ticket.chatType[action.payload.chatType],
              unreadCounts: Number(action.payload.unreadCounts),
            },
          },
        },
      };

    case UPDATE_TICKET_CHAT_LAST_READ_MESSAGE_ID:
      return {
        ...state,
        ticket: {
          ...state.ticket,
          chatType: {
            ...state.ticket.chatType,
            [action.payload.chatType]: {
              ...state.ticket.chatType[action.payload.chatType],
              lastReadMessageId: Number(action.payload.lastReadMessageId),
            },
          },
        },
      };

    case UPDATE_PROCUREMENT_CHAT_UNREAD_COUNTS:
      return {
        ...state,
        procurement: {
          ...state.procurement,
          chatType: {
            ...state.procurement.chatType,
            [action.payload.chatType]: {
              ...state.procurement.chatType[action.payload.chatType],
              unreadCounts: Number(action.payload.unreadCounts),
            },
          },
        },
      };

    case UPDATE_PROCUREMENT_CHAT_LAST_READ_MESSAGE_ID:
      return {
        ...state,
        procurement: {
          ...state.procurement,
          chatType: {
            ...state.procurement.chatType,
            [action.payload.chatType]: {
              ...state.procurement.chatType[action.payload.chatType],
              lastReadMessageId: Number(action.payload.lastReadMessageId),
            },
          },
        },
      };

    case RESET_RESOURCE_CHAT_STATE:
      return { ...initialState };

    default:
      return state;
  }
}
