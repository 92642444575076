import ApiResourceRequest from './ApiResourceRequest';
import { required } from '../../utils/helpers';
import ListingResponse from './ListingResponse';
import Pager from '../helpers/Pager';
import ChatModel from '../../realTimeApi/models/ChatModel';

export class TicketRequest extends ApiResourceRequest {
  get apiResource() {
    return 'tickets';
  }

  get dynamicIds() {
    return {
      PROJECT_ID: '{projectId}',
      MESSAGE_ID: '{messageId}',
      TICKET_ID: '{ticketId}',
      CHAT_ID: '{chatId}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_PROJECT_ID: `${this.apiRoute}/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_TICKET_STATISTICS_BY_PROJECT_ID: `${this.apiRoute}/statistics/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      ADD_PARTICIPANT: `${this.apiRoute}/${this.dynamicIds.TICKET_ID}/chats/${this.dynamicIds.CHAT_ID}/participants`,
      GET_MESSAGES: `${this.apiRoute}/${this.dynamicIds.TICKET_ID}/chats/${this.dynamicIds.CHAT_ID}/messages`,
      DELETE_MESSAGE: `${this.apiRoute}/${this.dynamicIds.TICKET_ID}/chats/${this.dynamicIds.CHAT_ID}/messages/${this.dynamicIds.MESSAGE_ID}`,
      GET_AVAILABLE_PARTICIPANTS_BY_TICKET_ID: `${this.apiRoute}/available-participants/${this.dynamicIds.TICKET_ID}/by-chat-type/{chatType}`,
      MARK_RESOLVED: `${this.apiRoute}/${this.dynamicIds.TICKET_ID}/resolved`,
    };
  }

  async getByProjectId(projectId, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ projectId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order),
      this.getExtraQueryParams(query.extra)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_PROJECT_ID.replace('{projectId}', projectId) +
        queryString
    );

    return new ListingResponse(response?.data);
  }

  async getStatisticsByProjectId(projectId, query = {}) {
    required({ projectId });
    const queryString = this.buildQueryString(
      this.getExtraQueryParams(query.extra),
      this.getFilterParams(query.filter)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_TICKET_STATISTICS_BY_PROJECT_ID.replace(
        '{projectId}',
        projectId
      ) + queryString
    );

    return {
      resources: response?.data,
    };
  }

  async addParticipant(ticketId, chatId, userId, method = 'POST') {
    required({ ticketId, chatId, userId });

    const response = await this.request(
      method,
      this.routes.ADD_PARTICIPANT.replace('{ticketId}', ticketId).replace(
        '{chatId}',
        chatId
      ),
      { userId }
    );

    return response?.data;
  }

  removeParticipant(ticketId, chatId, userId) {
    return this.addParticipant(ticketId, chatId, userId, 'DELETE');
  }

  async getMessages(ticketId, chatId, lastMessageId = 0) {
    required({ ticketId, chatId });

    const response = await this.request(
      'GET',
      `${this.routes.GET_MESSAGES.replace('{ticketId}', ticketId).replace(
        '{chatId}',
        chatId
      )}?lastMessageId=${lastMessageId}`
    );

    return {
      resources: response?.data?.data,
      total: response?.data?.total,
      lastReadMessageId: response?.data?.lastReadMessageId,
    };
  }

  async addMessage(ticketId, chatId, message, fileUuids = []) {
    required({ ticketId, chatId });
    if (!fileUuids.length) {
      required({ message });
    }

    const response = await this.request(
      'POST',
      this.routes.GET_MESSAGES.replace('{ticketId}', ticketId).replace(
        '{chatId}',
        chatId
      ),
      { message, files: fileUuids }
    );

    return new ListingResponse(response?.data);
  }

  async deleteMessage(ticketId, chatId, messageId) {
    required({ ticketId, chatId, messageId });

    const response = await this.request(
      'DELETE',
      this.routes.DELETE_MESSAGE.replace('{ticketId}', ticketId)
        .replace('{chatId}', chatId)
        .replace('{messageId}', messageId)
    );

    return response?.data;
  }

  async getAvailableParticipantsByTicketId(ticketId, chatType) {
    required({ ticketId });
    if (!Object.values(ChatModel.RESOURCE_CHAT_TYPE).includes(chatType)) {
      throw new Error(
        `chatType must be one of ${Object.values(
          ChatModel.RESOURCE_CHAT_TYPE
        ).toString()}`
      );
    }

    const response = await this.request(
      'GET',
      this.routes.GET_AVAILABLE_PARTICIPANTS_BY_TICKET_ID.replace(
        '{ticketId}',
        ticketId
      ).replace('{chatType}', chatType)
    );

    return new ListingResponse(response?.data);
  }

  async markResolved(ticketId, { accept, reason, rating }) {
    required({ ticketId });

    const response = await this.request(
      'POST',
      this.routes.MARK_RESOLVED.replace('{ticketId}', ticketId),
      {
        resolved: Boolean(accept),
        ...(reason ? { reason } : { reason: '' }),
        ...(rating ? { rating } : {}),
      }
    );

    return response?.data;
  }
}

export const ticketRequest = new TicketRequest();
