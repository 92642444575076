import { STATUS } from 'utils/constants';
import AbstractModel from './AbstractModel';
import SubcontractorCategoryModel from './SubcontractorCategoryModel';

class SubcontractorModel extends AbstractModel {
  constructor(...params) {
    super(...params);
    if (this.status !== STATUS.LOADING && this.subcontractorCategory) {
      this.subcontractorCategory = new SubcontractorCategoryModel({
        ...this.subcontractorCategory,
      });
    }
  }

  get businessCategoryName() {
    return this.subcontractorCategory?.name ?? '---';
  }

  get fullAddress() {
    let address = '';
    if (this.addressOne) {
      address += `${this.addressOne} `;
    }
    if (this.addressTwo) {
      address += `, ${this.addressTwo}`;
    }

    return address || '---';
  }
}

export default SubcontractorModel;
