import createGeneralChatMessageModel from './createGeneralChatMessageModel';
import { getUniqueArrayOfObjects } from '../../../utils/helpers';
import sortChatHistory from './sortChatHistory';
import { setChatHistoryLastReadMessage } from './updateChatHistoryLastReadMessage.helpers';
import { getShouldProjectBeMissed } from './toProjectConversationsStructure';
import ChatModel from '../../../realTimeApi/models/ChatModel';

const getUpdatedConversation = (
  currentProject,
  newChannel,
  conversationType
) => {
  const currentConversations = currentProject?.conversations || {};
  const currentConversation =
    currentConversations[newChannel[conversationType].id] || {};

  const lastReadMessageHistoryItem = createGeneralChatMessageModel(
    newChannel[conversationType].lastReadMessage,
    currentConversation.conversationInfo
  );

  lastReadMessageHistoryItem.showLastMessageLineForce = true;

  return {
    [newChannel[conversationType].id]: {
      ...currentConversation,
      lastReadMessageId: newChannel[conversationType].lastReadMessage?.id,
      history: setChatHistoryLastReadMessage(
        sortChatHistory(
          getUniqueArrayOfObjects(
            [
              ...(currentConversation?.history || []),
              lastReadMessageHistoryItem,
            ],
            'id'
          )
        ),
        newChannel[conversationType].lastReadMessage?.id
      ),
    },
  };
};

const shouldAddNewLastMessage = (channel, conversationType) => {
  return (
    channel[conversationType].lastReadMessage &&
    channel[conversationType].unreadMessagesIds?.length
  );
};

const addLastReadMessageToEachProjectConversationHistory = (
  prevProjects,
  channels
) => {
  const result = {};

  Object.entries(channels).forEach(([projectId, channel]) => {
    if (getShouldProjectBeMissed(channel)) {
      return;
    }

    const currentProject = prevProjects?.[projectId] || {};
    const currentConversations = currentProject?.conversations || {};

    result[projectId] = {
      ...currentProject,
      conversations: {
        ...currentConversations,
        ...(shouldAddNewLastMessage(
          channel,
          ChatModel.CHAT_TYPE.BENEFICIARY_CHAT
        )
          ? getUpdatedConversation(
              currentProject,
              channel,
              ChatModel.CHAT_TYPE.BENEFICIARY_CHAT
            )
          : {}),
        ...(shouldAddNewLastMessage(
          channel,
          ChatModel.CHAT_TYPE.MANAGEMENT_CHAT
        )
          ? getUpdatedConversation(
              currentProject,
              channel,
              ChatModel.CHAT_TYPE.MANAGEMENT_CHAT
            )
          : {}),
        ...(shouldAddNewLastMessage(channel, ChatModel.CHAT_TYPE.STAFF_CHAT)
          ? getUpdatedConversation(
              currentProject,
              channel,
              ChatModel.CHAT_TYPE.STAFF_CHAT
            )
          : {}),
      },
    };
  });

  return result;
};

export default addLastReadMessageToEachProjectConversationHistory;
