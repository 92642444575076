import { required } from '../../utils/helpers';
import AbstractRequest from './AbstractRequest';
import {
  DOCUMENT_FILE_EXTENSIONS,
  HTTP_STATUS_CODE,
} from '../../utils/constants';
import PATH from '../../routes/paths';
import { authRequest } from './AuthRequest';

export class FileRequest extends AbstractRequest {
  get apiResource() {
    return 'files';
  }

  get routes() {
    return {
      ...super.routes,
      GET_FILE: `${this.apiRoute}/{uuid}`,
      UPLOAD_FILE: `${this.apiRoute}/upload`,
    };
  }

  async request(method, url, data = {}, extraConfig) {
    let result;
    try {
      result = await super.request(method, url, data, extraConfig);
    } catch (e) {
      if (e?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
        window.location.href = window.location.origin + PATH.AUTH.LOGIN;
      }
      throw e;
    }

    return result;
  }

  static THUMBNAIL_SIZES = {
    HEIGHT_300_PX: 'w_0_h_320',
    SQUARE_176_PX: 'w_176_h_176',
    WIDTH_400_PX: 'w_400_h_0',
  };

  async uploadFile(file, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);

    const response = await this.request(
      'POST',
      this.routes.UPLOAD_FILE,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${authRequest.token}`,
        },
        onUploadProgress,
      }
    );

    return response?.data;
  }

  getFilePath(uuid, thumbnailSize) {
    required({ uuid });
    let queryParams = '';
    if (thumbnailSize) {
      if (
        !Object.values(this.constructor.THUMBNAIL_SIZES).includes(thumbnailSize)
      ) {
        throw new Error(
          `${thumbnailSize} must be one of ${this.constructor.THUMBNAIL_SIZES.toString()}`
        );
      }
      queryParams = `?size=${thumbnailSize}`;
    }

    return this.routes.GET_FILE.replace('{uuid}', uuid) + queryParams;
  }

  downloadFile(uuid, thumbnailSize) {
    FileRequest.downloadFile(this.getFilePath(uuid, thumbnailSize));
  }

  static downloadFile(url) {
    window.location.href = url;
  }

  static downloadFileViaLink(blob, fileOptions) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.style.display = 'none';
    link.href = url;
    link.download = `${fileOptions.fileName || 'file'}.${
      fileOptions.fileExtension || DOCUMENT_FILE_EXTENSIONS.word
    }`;

    document.body.appendChild(link);

    link.click();

    window.URL.revokeObjectURL(url);
    link.remove();
  }
}

export const fileRequest = new FileRequest();
