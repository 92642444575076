import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import eventBus, { EVENT_LIST } from 'utils/eventBus/eventBus';
import { useDidMount, useWillUnmount } from 'hooks/lifecycleHooks';

const StyledMainSection = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  overflow: auto;
  padding: 24px;
  position: relative;
  transition: padding-top 0.114s linear;
  ${({ saveChangesAlertBarOpened }) =>
    saveChangesAlertBarOpened && `padding-top: 96px;`}
`;

export default function MainSection({ children }) {
  const [saveChangesAlertBarOpened, setSaveChangesAlertBarOpened] = useState(
    false
  );
  const handleOnShowAlertBar = () => {
    setSaveChangesAlertBarOpened(true);
  };
  const handleOnHideAlertBar = () => {
    setSaveChangesAlertBarOpened(false);
  };

  useDidMount(() => {
    eventBus.$on(
      EVENT_LIST.COMPONENTS.DRAFT_PROJECT_PAGE.SAVE_CHANGES_ALERT_BAR
        .SHOW_ALERT_BAR,
      handleOnShowAlertBar
    );
    eventBus.$on(
      EVENT_LIST.COMPONENTS.DRAFT_PROJECT_PAGE.SAVE_CHANGES_ALERT_BAR
        .HIDE_ALERT_BAR,
      handleOnHideAlertBar
    );
  });

  useWillUnmount(() => {
    eventBus.$off(
      EVENT_LIST.COMPONENTS.DRAFT_PROJECT_PAGE.SAVE_CHANGES_ALERT_BAR
        .SHOW_ALERT_BAR,
      handleOnShowAlertBar
    );
    eventBus.$off(
      EVENT_LIST.COMPONENTS.DRAFT_PROJECT_PAGE.SAVE_CHANGES_ALERT_BAR
        .HIDE_ALERT_BAR,
      handleOnHideAlertBar
    );
  });

  return (
    <StyledMainSection saveChangesAlertBarOpened={saveChangesAlertBarOpened}>
      {children}
    </StyledMainSection>
  );
}

MainSection.propTypes = {
  children: PropTypes.node.isRequired,
};
