import { buildQueryKey } from 'utils/helpers';
import { STATUS } from 'utils/constants';
import { FIND_REPORT_TYPE, GET_REPORTS_TYPE } from './constants';
import Pager from '../../api/helpers/Pager';
import {
  addToDictionary,
  changeListStatus,
  resetState,
  updateList,
} from '../helpers';
import DEFAULT_REQUEST from '../constants';

const initialState = {
  dictionary: {},
  list: {
    meta: {
      currentKey: DEFAULT_REQUEST,
    },
    pagination: {
      pageNumber: null,
      pageSize: null,
      total: 0,
    },
    [DEFAULT_REQUEST]: {},
  },
};

export default function reportsReducer(state = initialState, action) {
  let queryKey;
  let pager;
  let listStatus;
  let resourceModel;
  let resetToDefault;
  switch (action.type) {
    case GET_REPORTS_TYPE.REQUEST: {
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;
      resetToDefault = action.payload.resetToDefault;

      if (
        Pager.hasPageSizeChanged(
          state.list.pagination.pageSize,
          pager?.pageSize
        ) ||
        resetToDefault
      ) {
        return resetState(state, initialState, {
          queryKey,
          pager,
        });
      }

      if (
        [STATUS.RELOADING, STATUS.SUCCESS, STATUS.CACHED].includes(listStatus)
      ) {
        return changeListStatus(state, { pager, queryKey }, STATUS.RELOADING);
      }
      return changeListStatus(state, { pager, queryKey }, STATUS.LOADING);
    }
    case GET_REPORTS_TYPE.SUCCESS: {
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
        list: updateList(state.list, action.payload),
      };
    }

    case FIND_REPORT_TYPE.REQUEST: {
      const { id } = action.payload;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    }
    case FIND_REPORT_TYPE.SUCCESS: {
      const { resource } = action.payload;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [resource.id]: new resourceModel({
            ...state.dictionary[resource.id],
            ...resource,
            findFetched: true,
          }),
        },
      };
    }
    case FIND_REPORT_TYPE.ERROR: {
      const { id } = action.payload;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };
    }
    default:
      return state;
  }
}
