import ApiResourceRequest from './ApiResourceRequest';
import Pager from '../helpers/Pager';

export class CountryRequest extends ApiResourceRequest {
  get apiResource() {
    return 'geolocation';
  }

  get routes() {
    return {
      ...super.routes,
      GET: `${this.apiRoute}/countries`,
      GET_COUNTRY_STATES: `${this.apiRoute}/states/by-country-id/{country_id}`,
      GET_COUNTRY_CITIES_BY_STATE: `${this.apiRoute}/cities/by-country-id/{country_id}/by-state/{state_id}`,
    };
  }

  async get(pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    const paginationParams = this.getPaginationParams(pager);
    const orderParams = this.getOrderParams(query.order);

    const response = await this.request(
      'GET',
      this.routes.GET + paginationParams + orderParams
    );

    return {
      resources: response?.data,
      total: response?.data?.length ?? 0,
    };
  }

  async getCountryStates(countryId, pager = new Pager(1, -1), query = {}) {
    pager && Pager.check(pager);
    if (!countryId) {
      throw new Error('countryId is required to fetch states!');
    }
    const paginationParams = this.getPaginationParams(pager);
    const orderParams = this.getOrderParams(query.order);

    const response = await this.request(
      'GET',
      this.routes.GET_COUNTRY_STATES.replace('{country_id}', countryId) +
        paginationParams +
        orderParams
    );

    return {
      resources: response?.data,
      total: response?.data?.length ?? 0,
    };
  }

  async getCountryCitiesByState(
    countryId,
    stateId,
    pager = new Pager(1, -1),
    query = {}
  ) {
    pager && Pager.check(pager);
    if (!countryId || !stateId) {
      throw new Error('countryId and stateId are required to fetch cities!');
    }
    const paginationParams = this.getPaginationParams(pager);
    const orderParams = this.getOrderParams(query.order);

    const response = await this.request(
      'GET',
      this.routes.GET_COUNTRY_CITIES_BY_STATE.replace(
        '{country_id}',
        countryId
      ).replace('{state_id}', stateId) +
        paginationParams +
        orderParams
    );

    return {
      resources: response?.data,
      total: response?.data?.length ?? 0,
    };
  }
}

export const countryRequest = new CountryRequest();
