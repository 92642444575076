import React from 'react';
import MaterialUiTooltip from '@material-ui/core/Tooltip';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { BUTTON_COLORS, getBgColor, getColor } from '../Button/Button';

function getTooltipColor(theme, color, background = false) {
  return background ? getBgColor(theme, color) : getColor(theme, color);
}

const StyledTooltip = styled(({ className, color, ...props }) => (
  <MaterialUiTooltip {...props} classes={{ tooltip: className }} />
))`
  background-color: ${({ theme, color }) =>
    getTooltipColor(theme, color, true)};
  color: ${({ theme, color }) => getTooltipColor(theme, color)};
  border: 1.4px solid ${({ theme, color }) => getTooltipColor(theme, color)};

  .MuiTooltip-arrow {
    left: 50% !important;
    transform: translateX(-50%) !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: -8px !important;

    :before {
      background-color: ${({ theme, color }) => getTooltipColor(theme, color)};
    }
  }
`;

export default function Tooltip({
  title,
  placement,
  enterDelay,
  color,
  children,
  ...rest
}) {
  return (
    <StyledTooltip
      title={title}
      placement={placement}
      enterDelay={enterDelay}
      color={color}
      arrow
      {...rest}
    >
      {children}
    </StyledTooltip>
  );
}

Tooltip.propTypes = {
  title: PropTypes.node,
  placement: PropTypes.string,
  enterDelay: PropTypes.number,
  children: PropTypes.node.isRequired,
  color(props, propName) {
    const propValue = props[propName];
    const btnColors = Object.values(BUTTON_COLORS);
    if (!btnColors.includes(propValue)) {
      throw new Error(`${propName} must be one of ${btnColors.toString()}`);
    }
  },
};

Tooltip.defaultProps = {
  title: 'Remove',
  placement: 'top',
  enterDelay: 0,
  color: 'primary',
};
