const PROCUREMENT_EVENTS = Object.freeze({
  CHAT: {
    INTERNAL: {
      GET_CHAT: 'getInternalProcurementChat',
      GET_CHAT_HISTORY: 'getInternalProcurementChatHistory',
      SEND_MESSAGE: 'sendInternalProcurementChatMessage',
      REMOVE_MESSAGE: 'removeInternalProcurementChatMessage',
      ADD_PARTICIPANT: 'addInternalProcurementChatParticipant',
      REMOVE_PARTICIPANT: 'removeInternalProcurementChatParticipant',
      UNREAD_MESSAGES_UPDATED:
        'internalProcurementChatUnreadMessagesIdsUpdated',
    },
    EXTERNAL: {
      GET_CHAT: 'getExternalProcurementChat',
      GET_CHAT_HISTORY: 'getExternalProcurementChatHistory',
      SEND_MESSAGE: 'sendExternalProcurementChatMessage',
      REMOVE_MESSAGE: 'removeExternalProcurementChatMessage',
      ADD_PARTICIPANT: 'addExternalProcurementChatParticipant',
      REMOVE_PARTICIPANT: 'removeExternalProcurementChatParticipant',
      UNREAD_MESSAGES_UPDATED:
        'externalProcurementChatUnreadMessagesIdsUpdated',
    },
  },
});

export default PROCUREMENT_EVENTS;
