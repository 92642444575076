import TypesHelper from '../../utils/types/TypesHelper';

class RealTimeMessageModel {
  constructor(eventName, data = {}) {
    if (!TypesHelper.isString(eventName)) {
      throw new Error('String is expected');
    }

    if (!TypesHelper.isObject(data)) {
      throw new Error('Object is expected');
    }

    this.event = eventName;
    this.data = data;
  }

  get JSON() {
    return JSON.stringify({
      event: this.event,
      data: this.data,
    });
  }

  static checkInstance = (realTimeMessageModel) => {
    if (!(realTimeMessageModel instanceof RealTimeMessageModel)) {
      throw new Error('incorrect instance type');
    }
  };
}

export default RealTimeMessageModel;
