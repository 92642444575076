import { setChatHistoryLastReadMessage } from './updateChatHistoryLastReadMessage.helpers';
import sortChatHistory from './sortChatHistory';
import { getUniqueArrayOfObjects } from '../../../utils/helpers';

const addLastReadMessageToEachResourceChatHistory = (
  prevChats,
  newChats,
  messageModelCreator
) => {
  const result = {};

  Object.entries(newChats).forEach(([ticketId, chats]) => {
    const currentTicket = prevChats?.[ticketId] || {};
    const currentChats = currentTicket?.chats || {};

    result[ticketId] = {
      ...currentTicket,
      chats: {
        ...currentChats,
      },
    };

    Object.entries(chats).forEach(([chatId, chat]) => {
      const currentChat = result[ticketId].chats[chatId] || {};

      const areValuesValid = [
        chat.lastReadMessage,
        chat.unreadMessagesIds?.length,
      ].every((value) => !!value);

      if (!areValuesValid) {
        return;
      }

      const lastReadMessageHistoryMessage = messageModelCreator(
        chat.lastReadMessage,
        currentChat.chatInfo
      );

      lastReadMessageHistoryMessage.showLastMessageLineForce = true;

      result[ticketId].chats = {
        ...result[ticketId].chats,
        [chatId]: {
          ...currentChat,
          lastReadMessageId: lastReadMessageHistoryMessage.id,
          history: setChatHistoryLastReadMessage(
            sortChatHistory(
              getUniqueArrayOfObjects(
                [...(currentChat.history || []), lastReadMessageHistoryMessage],
                'id'
              )
            ),
            lastReadMessageHistoryMessage.id
          ),
        },
      };
    });
  });

  return result;
};

export default addLastReadMessageToEachResourceChatHistory;
