import AbstractModel from '../AbstractModel';
import { STATUS } from '../../../utils/constants';
import RoomModel from './RoomModel';

class LevelModel extends AbstractModel {
  constructor(...params) {
    super(...params);
    if (this.status !== STATUS.LOADING && Array.isArray(this.rooms)) {
      this.buildingLevelRooms = this.rooms.map(
        (room) => new RoomModel({ ...room })
      );
    }
    if (this.status !== STATUS.LOADING && Array.isArray(this.buildings)) {
      this.buildingIds = this.buildings.map((building) => building.id);
    }
  }
}

export default LevelModel;
