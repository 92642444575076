import { useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from '@material-ui/core';

export default (originValue, originOnChange, debounceMsTime, isDirectValue) => {
  const [value, setValue] = useState(originValue);
  const valueRef = useRef(value);

  useEffect(() => {
    if (originValue !== valueRef.current) {
      setValue(originValue);
    }
  }, [originValue]);

  const onChangeWithDebounce = useMemo(
    () =>
      debounce(
        (valueParameter, callback) => callback(valueParameter),
        debounceMsTime
      ),
    [debounceMsTime]
  );

  const onChange = (e) => {
    const newTextValue = isDirectValue ? e : e?.target?.value;

    setValue(newTextValue);
    valueRef.current = newTextValue;

    onChangeWithDebounce(e, originOnChange);
  };

  return [value, onChange];
};
