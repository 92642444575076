import authRoutes from 'routes/globalRoutes/auth';
import myProfileRoutes from 'routes/globalRoutes/myProfile';
import PATH from 'routes/paths';
import { lazy } from 'react';

const ProjectsPanel = lazy(() =>
  import('components/layout/mainPages/adminPages/AdminProjectsView')
);

export const DASHBOARD = {
  component: ProjectsPanel,
  path: PATH.DASHBOARD,
  exact: false,
  isProtected: true,
};

let routes = [];
const routeFiles = require.context('./', true, /^(?!.\/index|.\/paths).*\.js$/);

routeFiles.keys().forEach((routeFile) => {
  routes = [...routes, ...routeFiles(routeFile).default];
});

const adminRoutes = [...authRoutes, ...myProfileRoutes, ...routes, DASHBOARD];

export default adminRoutes;
