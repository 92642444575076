import ApiResourceRequest from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import Pager from '../../helpers/Pager';

export class EquipmentInputRequest extends ApiResourceRequest {
  get apiResource() {
    return 'equipment_inputs';
  }

  get apiUrl() {
    return this.projectApiUrl;
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_EQUIPMENT_ID: `${this.apiRoute}/by_equipment_id/{equipment_id}`,
    };
  }

  async getByEquipmentId(
    equipmentId,
    pager = new Pager(),
    query = {},
    resourcesDataKey = this.apiResource
  ) {
    pager && Pager.check(pager);
    required({ equipmentId });
    const paginationParams = this.getPaginationParams(pager);
    const orderParams = this.getOrderParams(query.order);

    const response = await this.request(
      'GET',
      this.routes.GET_BY_EQUIPMENT_ID.replace('{equipment_id}', equipmentId) +
        paginationParams +
        orderParams
    );

    return {
      resources: response?.data?.[resourcesDataKey],
      total: response?.data?.total_item_count,
    };
  }
}

export const equipmentInputRequest = new EquipmentInputRequest();
