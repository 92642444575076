import AbstractDataAdapter from './AbstractDataAdapter';

class UnitDataAdapter extends AbstractDataAdapter {
  static get createAllowedFields() {
    return ['name', 'symbol'];
  }

  static get createRequiredFields() {
    return ['name', 'symbol'];
  }

  static get updateAllowedFields() {
    return ['name', 'symbol'];
  }

  static get updateRequiredFields() {
    return [];
  }
}

export default UnitDataAdapter;
