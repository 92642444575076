import TypesHelper from '../../../utils/types/TypesHelper';

const removeConversation = (conversations, conversationId) => {
  if (!TypesHelper.isObject(conversations)) {
    throw new Error('conversations should be object');
  }
  const result = {};

  Object.entries(conversations).forEach(([id, conversation]) => {
    if (+id !== +conversationId) {
      result[id] = conversation;
    }
  });

  return result;
};

export default removeConversation;
