export default {
  report: {
    itself: 'Report',
    plural: {
      itself: 'Reports',
    },
    metadata: {
      activityRegistryFullInfo: 'Activity Registry Full Info',
      dailyProceduresFullInfo: 'Daily Procedures Full Info',
      procurementsFullInfo: 'Procurements Full Info',
      includeProcurementsFinanceInfo: 'Include Procurements Finance Info',
      ticketsFullInfo: 'Tickets Full Info',
      warrantyTrackerFullInfo: 'Warranty Tracker Full Info',
      preventiveMaintenanceEventsFullInfo:
        'Preventive Maintenance Events Full Info',
    },
    attributes: {
      name: {
        itself: 'Name',
      },
      format: {
        itself: 'Format',
      },
      data: {
        itself: 'Data',
      },
      created: {
        itself: 'Created',
      },
    },
    note: {
      reportContentSection: {
        preventiveMaintenanceEvent: 'Preventive maintenance event',
        dailyCheckEvent: 'Daily check event',
        ticket: 'Ticket',
        procurement: 'Procurement',
        activityRegistry: 'Activity registry',
        warrantyTracker: 'Warranty tracker',
      },
    },
    preventiveMaintenance: {
      itself: 'Preventive Maintenance',
    },
    procurementOrder: {
      itself: 'Procurement Order',
      plural: {
        itself: 'Procurement Orders',
      },
    },
    category: 'Category',
    group: 'Group',
    equipment: 'Equipment',
    recipient: 'Recipient',
    generateReport: 'Generate report',
    form: {
      name: {
        itself: 'Name',
        '*': 'Name *',
        required: 'Name is required',
      },
      startDate: {
        itself: 'Start date',
        '*': 'Start date *',
        required: 'Start date is required',
      },
      endDate: {
        itself: 'End date',
        '*': 'End date *',
        required: 'End date is required',
      },
      format: {
        itself: 'Format',
        '*': 'Format *',
        required: 'Format is required',
      },
      category: {
        itself: 'Category',
        '*': 'Category *',
        required: 'Category is required',
      },
      group: {
        itself: 'Group',
        '*': 'Group *',
        required: 'Group is required',
      },
      equipment: {
        itself: 'Equipment',
        '*': 'Equipment *',
        required: 'Equipment is required',
      },
      state: {
        itself: 'State',
        '*': 'State *',
        required: 'State is required',
      },
      recipient: {
        itself: 'Recipient',
        '*': 'Recipient *',
        required: 'Recipient is required',
      },
      location: {
        itself: 'Location',
        '*': 'Location *',
        required: 'Location is required',
      },
      metadata: {
        itself: 'Metadata',
        '*': 'Metadata *',
        required: 'Metadata is required',
      },
      exportDetailedReport: 'Export detailed report',
      includeFinancialData: 'Include financial data',
      preventiveMaintenance: 'Preventive maintenance',
      preventiveMaintenanceEvents: 'Preventive maintenance events',
      dailyCheckEvents: 'Daily check events',
      correctiveMaintenance: 'Corrective maintenance',
    },
    downloadReport: 'Download report',
    reportDownloadingError:
      'An error occurred while a report generating. Try later, please',
  },
};
