import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.themeCommon.black.light};
  margin-bottom: 12px;
  width: 100%;

  .title {
    font-weight: 500;
    font-size: ${({ theme }) =>
      theme.typography.pxToRem(theme.typography.fontSize) + 2};
    line-height: 24px;
    color: ${({ theme }) => theme.palette.grey[1900]};
  }

  .dismiss-button {
    background: transparent !important;
    border: none !important;
    padding: 5px;
    cursor: ${({ isDismissButtonDisabled }) =>
      isDismissButtonDisabled ? 'default' : 'pointer'};
  }
`;

const Count = styled.span`
  color: ${({ theme }) => theme.palette.gray[500]};
  margin-left: 8px;
`;

const NotificationsHeader = ({
  onDismissAllClick,
  count,
  isDismissButtonDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper isDismissButtonDisabled={isDismissButtonDisabled}>
      <Typography className="title">
        {t('general.notifications')} {count > 0 && <Count>{count}</Count>}
      </Typography>
      <Button
        as="div"
        disabled={isDismissButtonDisabled}
        className="dismiss-button"
        onClick={() => {
          !isDismissButtonDisabled && onDismissAllClick();
        }}
      >
        {t('general.dismissAll')}
      </Button>
    </Wrapper>
  );
};

NotificationsHeader.propTypes = {
  onDismissAllClick: PropTypes.func,
  count: PropTypes.number,
  isDismissButtonDisabled: PropTypes.bool,
};
NotificationsHeader.defaultProps = {
  onDismissAllClick: () => {},
  count: 0,
  isDismissButtonDisabled: false,
};

export default NotificationsHeader;
