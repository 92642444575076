import Alert from '../../alerts/Alert';

export const PROMISE_STATUSES = Object.freeze({
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
});

/**
 *
 * @param {Promise[]} uploadPromises
 * @param {{}} config
 */
const getUploadedFilesUuids = (
  uploadPromises,
  config = {
    errorUploadingFilesMessage: 'An error occurred while uploading files!',
    errorUploadingSomeFilesMessage:
      'An error occurred while uploading some files!',
  }
) => {
  return Promise.allSettled(uploadPromises).then((results) => {
    const fileUuids = [];
    const response = { fileUuids };
    if (!uploadPromises.length) {
      return response;
    }

    const uploadedFilesResponses = results.filter(
      (result) => result.status === PROMISE_STATUSES.FULFILLED
    );

    if (!uploadedFilesResponses.length) {
      console.error('Upload files error', results);
      Alert.showError(config.errorUploadingFilesMessage);

      return response;
    }

    uploadedFilesResponses.forEach(({ value: { fileUuid } }) =>
      fileUuids.push(fileUuid)
    );

    if (uploadedFilesResponses.length !== results.length) {
      console.error('Upload some files error', results);
      Alert.showError(config.errorUploadingSomeFilesMessage);

      return response;
    }

    return response;
  });
};

export default getUploadedFilesUuids;
