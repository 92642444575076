import RoleModel from 'api/models/RoleModel';
import AbstractModel from 'api/models/AbstractModel';
import { STATUS } from 'utils/constants';
import MaintenanceProcedureModel from 'api/models/MaintenanceProcedure/MaintenanceProcedureModel';

class EquipmentModel extends AbstractModel {
  static EDIT_ROLES = [RoleModel.ROLES.SUPER_ADMIN, RoleModel.ROLES.ADMIN];

  constructor(...params) {
    super(...params);
    if (
      this.status !== STATUS.LOADING &&
      Array.isArray(this.maintenanceProcedures)
    ) {
      this.maintenanceProcedures = this.maintenanceProcedures.map(
        (procedure) => new MaintenanceProcedureModel({ ...procedure })
      );
    }
  }

  static FILTER = Object.freeze({
    BUILDING: 'buildingId',
    LEVEL: 'levelId',
    ROOM: 'roomId',
    STATUS: 'status',
  });

  static STATUSES = Object.freeze({
    DRAFT: 'draft',
    ACTIVE: 'active',
    DISABLED: 'disabled',
    ARCHIVED: 'archived',
  });

  static READABLE_I18N_STATUSES = Object.freeze({
    [EquipmentModel.STATUSES.DRAFT]: 'equipment.statuses.draft',
    [EquipmentModel.STATUSES.ACTIVE]: 'equipment.statuses.active',
    [EquipmentModel.STATUSES.DISABLED]: 'equipment.statuses.disabled',
    [EquipmentModel.STATUSES.ARCHIVED]: 'equipment.statuses.archived',
  });

  static CATEGORIES = Object.freeze({
    HEATING: 'Heating',
    COOLING: 'Cooling',
    VENTILATION: 'Ventilation',
    EMERGENCY_SYSTEM: 'Emergency system',
    SANITARY: 'Sanitary',
    PLUMBING_SYSTEM: 'Plumbing system',
    LOW_VOLTAGE: 'Low voltage',
    EXTRA_LOW_VOLTAGE: 'Extra low voltage',
    OTHER: 'Other',
  });

  static READABLE_I18N_CATEGORIES = Object.freeze({
    [EquipmentModel.CATEGORIES.HEATING]: 'equipment.categories.heating',
    [EquipmentModel.CATEGORIES.COOLING]: 'equipment.categories.cooling',
    [EquipmentModel.CATEGORIES.VENTILATION]: 'equipment.categories.ventilation',
    [EquipmentModel.CATEGORIES.EMERGENCY_SYSTEM]:
      'equipment.categories.emergencySystem',
    [EquipmentModel.CATEGORIES.SANITARY]: 'equipment.categories.sanitary',
    [EquipmentModel.CATEGORIES.PLUMBING_SYSTEM]:
      'equipment.categories.plumbingSystem',
    [EquipmentModel.CATEGORIES.LOW_VOLTAGE]: 'equipment.categories.lowVoltage',
    [EquipmentModel.CATEGORIES.EXTRA_LOW_VOLTAGE]:
      'equipment.categories.extraLowVoltage',
    [EquipmentModel.CATEGORIES.OTHER]: 'equipment.categories.other',
  });

  get equipmentStatus() {
    return this.state;
  }
}

export default EquipmentModel;
