import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { cafmTheme } from './themes/cafm';
import store from './store';
import * as serviceWorker from './serviceWorker';
// translations config must be before App to have translated meta titles
import './translations/config';
import App from './App';
import './utils/jsPrototypeEnhancements/String/capitalize';
import './utils/jsPrototypeEnhancements/String/reverse';
import GlobalStyles from './globalStyles';

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={cafmTheme}>
        <SCThemeProvider theme={cafmTheme}>
          <GlobalStyles />
          <BrowserRouter>
            <CssBaseline />
            <App />
          </BrowserRouter>
        </SCThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
