import React from 'react';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PATH from 'routes/paths';
import { compose } from 'redux';
import i18n from 'i18next';
import { ReactComponent as CafmMainLogo } from 'assets/coralLogo40x40.svg';
import { ReactComponent as CafmTitle } from 'assets/coralTitle.svg';
import TextField from '../../stories/ui/TextField/TextField';
import Button from '../../stories/ui/Button/Button';
import {
  login as loginAction,
  setMe as setMeAction,
} from '../../store/auth/actions';
import {
  authErrorSelector,
  isAuthenticatedSelector,
} from '../../store/auth/selectors';
import useTextFieldErrorClasses from '../../hooks/styles/useTextFieldErrorClasses';
import InputErrorIcon from '../../components/ui/InputErrorIcon';
import withHelmet from '../../hocs/withHelmet';
import Alert from '../../alerts/Alert';

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.gray[100]};
`;

const LoginContainer = styled.div`
  width: 340px;
  height: 320px;
  background-color: ${({ theme }) => theme.palette.themeCommon.white.main};
  border-radius: 0.5rem;
  padding: 0 1.5rem;
`;

const LoginHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 72px;
  font-size: 1rem;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 3rem);
    bottom: 0;
    left: -1.5rem;
    right: -1.5rem;
    border-bottom: 1px solid
      ${({ theme }) => theme.palette.themeCommon.black.light};
  }
`;

const StyledForm = styled(Form)`
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const PasswordTextField = styled(StyledTextField)`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.themeCommon.black.light};
`;

const StyledSubmitButton = styled(Button)`
  font-size: 1rem;
  width: 100%;
`;

const LoginLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  svg:first-child {
    width: 80px;
    height: 80px;
  }
`;

export const LoginLogo = () => {
  return (
    <LoginLogoWrapper>
      <CafmMainLogo />
      <CafmTitle />
    </LoginLogoWrapper>
  );
};

function Login({
  isAuthenticated,
  authError,
  login,
  setMe,
  location: { state },
}) {
  const { t } = useTranslation();
  const { from } =
    state?.from?.pathname && state?.from?.pathname !== '/'
      ? state
      : { from: { pathname: PATH.DASHBOARD } };
  const textFieldErrorClasses = useTextFieldErrorClasses();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('invalidEmail')).required(t('enterEmail')),
    password: Yup.string().required(t('enterPasswordOrPin')),
  });

  const loginContent = (
    <PageContainer>
      <LoginLogo />
      <LoginContainer>
        <LoginHeader>
          <Trans i18nKey="enterCredentials" />
        </LoginHeader>

        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, { setSubmitting }) => {
            login({
              username: values.email,
              password: values.password,
            }).then(() =>
              setMe().then(({ payload }) => {
                !payload.organization?.id &&
                  Alert.showError(
                    'Parent organization is not set for authenticated user!'
                  );
              })
            );
            setSubmitting(false);
          }}
        >
          {() => (
            <StyledForm>
              <Field name="email">
                {({ field, meta }) => (
                  <>
                    <StyledTextField
                      type="text"
                      id="email-text-field"
                      placeholder={t('emailAddress')}
                      {...field}
                      InputProps={
                        meta.touched && meta.error
                          ? {
                              endAdornment: (
                                <InputErrorIcon
                                  title={meta.error}
                                  permanentOpen
                                />
                              ),
                              classes: textFieldErrorClasses,
                            }
                          : undefined
                      }
                    />
                  </>
                )}
              </Field>
              <Field name="password">
                {({ field, meta }) => (
                  <>
                    <PasswordTextField
                      type="password"
                      id="password-text-field"
                      placeholder={t('enterPasswordOrPin')}
                      {...field}
                      InputProps={
                        meta.touched && meta.error
                          ? {
                              endAdornment: (
                                <InputErrorIcon
                                  title={meta.error}
                                  permanentOpen
                                />
                              ),
                              classes: textFieldErrorClasses,
                            }
                          : undefined
                      }
                    />
                  </>
                )}
              </Field>
              {authError && (
                <div className="auth-errors">{authError.message}</div>
              )}

              <StyledSubmitButton color="primary" size="large" type="submit">
                <Trans i18nKey="login" />
              </StyledSubmitButton>
            </StyledForm>
          )}
        </Formik>
      </LoginContainer>
    </PageContainer>
  );

  return isAuthenticated ? <Redirect to={from} /> : loginContent;
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  authError: authErrorSelector(state),
});

const mapDispatchToProps = {
  login: loginAction,
  setMe: setMeAction,
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  authError: PropTypes.shape({ message: PropTypes.string.isRequired }),
  login: PropTypes.func.isRequired,
  setMe: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
  }).isRequired,
};

Login.defaultProps = {
  authError: null,
};
const wrappers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHelmet(i18n.t('metaTitles.login'))
);

export default wrappers(Login);
