import AbstractEventCatcher from '../AbstractEventCatcher';
import { ticketChatActionCreators } from '../../../store/chat/resource/actions';
import Alert from '../../../alerts/Alert';

export default class TicketChatRemoveParticipantEventCatcher extends AbstractEventCatcher {
  static process({ participantInfo, dispatch, loggedInUser }) {
    dispatch(
      ticketChatActionCreators.removeParticipant(
        participantInfo.ticketId,
        participantInfo.chatId,
        participantInfo.removedParticipant.id
      )
    );

    /** @dima todo temp solution */
    if (participantInfo.removedParticipant.userId === loggedInUser?.id) {
      Alert.showWarn(
        `Sorry, you were removed from the ticket chat with id ${participantInfo.ticketId}`
      );
    }
  }
}
