import AbstractEventCatcher from '../../AbstractEventCatcher';
import ChatModel from '../../../models/ChatModel';
import { generalChatProjectsConversationActionsCreators } from '../../../../store/chat/general/actions';

export default class ProjectMessagesBeneficiaryChannelEventCather extends AbstractEventCatcher {
  static process(chat, dispatch, loggedInUser) {
    if (!chat.projectId || !chat.id) {
      throw new Error('projectId and chatId are required');
    }

    const channels = {
      [chat.projectId]: {
        [ChatModel.PROJECT_CHAT_TYPE.BENEFICIARY_CHAT]: chat,
        [ChatModel.PROJECT_CHAT_TYPE.MANAGEMENT_CHAT]: {},
        [ChatModel.PROJECT_CHAT_TYPE.STAFF_CHAT]: {},
      },
    };

    dispatch(
      generalChatProjectsConversationActionsCreators.setConversations(
        channels,
        loggedInUser
      )
    );

    dispatch(
      generalChatProjectsConversationActionsCreators.addLastReadMessageToEachConversationHistory(
        channels
      )
    );
  }
}
