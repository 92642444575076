import Login from '../../containers/auth/Login';
import CreatePassword from '../../containers/auth/CreatePassword';
import PATH from '../paths';

export const LOGIN = {
  component: Login,
  path: PATH.AUTH.LOGIN,
  exact: true,
  isProtected: false,
};

export const CREATE_PASSWORD = {
  component: CreatePassword,
  path: PATH.AUTH.CREATE_PASSWORD,
  exact: true,
  isProtected: true,
};

export default [LOGIN, CREATE_PASSWORD];
