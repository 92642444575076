export default {
  user: {
    itself: 'Utilizator',
    plural: {
      itself: 'Utilizatori',
    },
    list: {
      showSuspended: 'Afișați utilizatorii suspendați',
    },
    addNewUser: 'Adaugă utilizator nou',
    editUser: 'Modifică utilizator',
    saveUser: 'Salvează utilizator',
    saveNewUser: 'Salvează utilizator nou',
    emptyUserList:
      'Apăsați butonul albastru plus și începeți să adăugați utilizatori',
    attributes: {
      phone: {
        itself: 'Telefon',
        '*': 'Telefon *',
        required: 'Telefonul e obligatoriu',
      },
      lastSeen: {
        itself: 'Ultima autentificare',
      },
      status: {
        itself: 'Stare',
      },
    },
    role: {
      superAdmin: 'Super admin',
      admin: 'Headquarter Team',
      coordinator: 'Senior Facility Manager',
      projectManager: 'Facility Manager',
      assistantProjectManager: 'Junior Facility Manager',
      technician: 'Tehnician',
      procurement: 'Specialist Achiziții',
      beneficiary: 'Beneficiar',
      tenant: 'Chiriaş',
    },
    activateUser: 'Activați utilizatorul',
    suspendUser: 'Suspendați utilizatorul',
    userProfile: 'Profil',
  },
};
