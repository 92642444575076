import AbstractModel from '../../api/models/AbstractModel';
import getMessageAttachments from '../../hooks/chat/useResourceChat/messageAttachmentHelpers';
import ChatParticipantModel from './ChatParticipantModel';
import UserModel from '../../api/models/UserModel';

export default class ChatHistoryMessageModel extends AbstractModel {
  get lastReadMessage() {
    return this._lastReadMessage;
  }

  set lastReadMessage(isRead) {
    this._lastReadMessage = isRead;
  }

  get showLastMessageLineForce() {
    return this._showLastMessageLineForce;
  }

  get participantAffectedUser() {
    if (this.participant?.user?.firstName) {
      return new UserModel({ ...this.participant.user });
    }

    if (this.metadata?.user?.firstName) {
      return new UserModel({ ...this.metadata?.user });
    }

    /** @dima todo temp */
    if (this.metadata?.firstName) {
      return new UserModel({ ...this.metadata, id: this.metadata.userId });
    }

    const candidate = this.conversationInfo?.chatParticipants?.find(
      (participant) => +participant?.user?.id === +this.metadata?.userId
    );

    const affectedParticipant = candidate || new ChatParticipantModel();

    return affectedParticipant.user;
  }

  get participantUser() {
    return new UserModel({ ...this.participant.user });
  }

  set showLastMessageLineForce(showLastMessageLineForce) {
    this._showLastMessageLineForce = showLastMessageLineForce;
  }

  constructor(...params) {
    super(...params);

    this.timestamp = new Date(this.createdAt);

    this.attachments = getMessageAttachments(this.files || []);

    this._type = this.messageType;

    this.participant = new ChatParticipantModel({ ...this.user });
  }

  convertAttribute(attribute) {
    switch (attribute) {
      case 'type': {
        return 'messageType';
      }

      default:
        return super.convertAttribute(attribute);
    }
  }
}
