import React from 'react';
import PropTypes from 'prop-types';
import withRef from '../../hocs/withRef';
import RedQuestionIcon from '../../stories/ui/Icons/RedQuestionIcon';
import Tooltip from '../../stories/ui/Tooltip/Tooltip';
import TypesHelper from '../../utils/types/TypesHelper';

const RedQuestionIconWithRef = withRef(RedQuestionIcon);

export default function InputErrorIcon({
  title,
  permanentOpen,
  open,
  ...rest
}) {
  return (
    <Tooltip
      title={title}
      color="danger"
      {...(TypesHelper.isBoolean(open) ? { open } : {})}
      {...(permanentOpen ? { open: true } : {})}
      {...rest}
    >
      <RedQuestionIconWithRef />
    </Tooltip>
  );
}

InputErrorIcon.propTypes = {
  title: PropTypes.string.isRequired,
  permanentOpen: PropTypes.bool,
  open: PropTypes.bool,
};

InputErrorIcon.defaultProps = {
  permanentOpen: false,
  open: undefined,
};
