export default {
  announcement: {
    plural: {
      itself: 'Anunturi',
    },
    createAnnouncement: 'Creați un anunț nou',
    editAnnouncement: 'Editați anunțul',
    saveNewAnnouncement: 'Salvați noul anunț',
    form: {
      title: {
        itself: 'Titlu',
        '*': 'Titlu *',
        required: 'Titlul este obligatoriu',
      },
      summary: {
        itself: 'Rezumat',
        '*': 'Rezumat *',
        required: 'Rezumatul este necesar',
      },
      body: {
        itself: 'Corp',
        '*': 'Corp *',
        required: 'Este necesar corpul',
      },
      coverImage: {
        itself: 'Imagine de copertă',
        '*': 'Imagine de copertă *',
        required: 'Imaginea de copertă este necesară',
        uploadImage: 'Încărcați o imagine',
      },
      documentation: {
        itself: 'Documentație',
        '*': 'Documentație *',
        required: 'Documentația e obligatorie',
        uploadDocuments: 'Încarcă documente',
      },
      media: {
        itself: 'Media',
        '*': 'Media *',
        required: 'Media este obligatorie',
        uploadImagesVideos: 'Încarcă imagini & video',
      },
      startDate: {
        itself: 'Data de început',
        '*': 'Data de început *',
        required: 'Data de începere este obligatorie',
      },
      endDate: {
        itself: 'Data de încheiere',
        '*': 'Data de încheiere *',
        required: 'Data de încheiere este obligatorie',
      },
    },
    noAnnouncements: 'Niciun anunt inca',
  },
};
