import createGeneralChatMessageModel from './createGeneralChatMessageModel';
import ChatHistoryMessageModel from '../../../realTimeApi/models/ChatHistoryMessageModel';

export const resetChatHistoryLastReadMessage = (history) => {
  return history.map((messageObj) => {
    let historyItem;

    if (messageObj instanceof ChatHistoryMessageModel) {
      historyItem = new messageObj.constructor(messageObj);
    } else {
      historyItem = createGeneralChatMessageModel(messageObj);
    }

    historyItem.lastReadMessage = false;

    return historyItem;
  });
};

export const setChatHistoryLastReadMessage = (history, lastReadMessageId) => {
  const resetHistory = resetChatHistoryLastReadMessage(history);

  if (!lastReadMessageId) {
    return resetHistory;
  }

  return resetHistory.map((messageObj, ind, arr) => {
    const lastItemCondition =
      messageObj.showLastMessageLineForce || ind !== arr.length - 1;

    if (messageObj.id === lastReadMessageId && lastItemCondition) {
      let historyItem;

      if (messageObj instanceof ChatHistoryMessageModel) {
        historyItem = new messageObj.constructor(messageObj);
      } else {
        historyItem = createGeneralChatMessageModel(messageObj);
      }

      historyItem.lastReadMessage = true;

      return historyItem;
    }
    return messageObj;
  });
};
