export const GET = Object.freeze({
  REQUEST: 'building/GET_REQUEST',
  SUCCESS: 'building/GET_SUCCESS',
  ERROR: 'building/GET_ERROR',
});

export const GET_FULL_INFO = Object.freeze({
  REQUEST: 'building/GET_FULL_INFO_REQUEST',
  SUCCESS: 'building/GET_FULL_INFO_SUCCESS',
  ERROR: 'building/GET_FULL_INFO_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'building/FIND_REQUEST',
  SUCCESS: 'building/FIND_SUCCESS',
  ERROR: 'building/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'building/CREATE_REQUEST',
  SUCCESS: 'building/CREATE_SUCCESS',
  ERROR: 'building/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'building/UPDATE_REQUEST',
  SUCCESS: 'building/UPDATE_SUCCESS',
  ERROR: 'building/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'building/DELETE_REQUEST',
  SUCCESS: 'building/DELETE_SUCCESS',
  ERROR: 'building/DELETE_ERROR',
});

export const GET_BUILDINGS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const GET_BUILDINGS_FULL_INFO_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_FULL_INFO.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_FULL_INFO.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_FULL_INFO.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_BUILDING_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_BUILDING_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_BUILDING_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_BUILDING_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});
