import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en';
import ro from './ro';
import { I18N_DEBUG_MODE } from '../config';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en,
      ro,
    },
    fallbackLng: 'en',
    debug: I18N_DEBUG_MODE,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: '.', // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export const LANGUAGES = Object.freeze({
  EN: 'en',
  RO: 'ro',
});

export default i18n;
