export default {
  project: {
    status: 'Status',
    alerts: 'Alerts',
    projectInfo: 'Project Info',
    lastUpdate: 'Last Update',
    activeProjects: 'Active projects',
    projects: 'Projects',
    noProjectsFound: 'No projects found',
    drafts: 'Drafts',
    archived: 'Archived',
    buildings: 'Buildings',
    equipment: 'Equipment',
    procedures: 'Procedures',
    standardProcedures: 'Standard procedures',
    additionalProcedures: 'Additional procedures',
    activateProject: 'Activate project',
    conversations: {
      beneficiary: 'Beneficiary',
      management: 'Management',
      staff: 'Staff',
    },
    generalProjectForm: {
      generalProjectInformation: 'General project information',
      projectName: 'Project name',
      mediaGallery: 'Media gallery',
      startedDate: 'Started date',
      endDate: 'End date',
      address: 'Address',
      country: 'Country',
      state: 'State',
      city: 'City',
      postalCode: 'Post code',
      addressOne: 'Address line 1',
      addressTwo: 'Address line 2',
      projectDescription: 'Project description',
      projectImage: 'Project image',
    },
    generalContractorForm: {
      name: 'Name',
      address: 'Address',
      city: 'City',
      country: 'Country',
      addressLineOne: 'Address line 1',
      addressLineTwo: 'Address line 2(optional)',
      contact: 'Contact',
      companyRepresentative: 'Company representative',
      email: 'Email',
      emailAddress: 'Email address',
      phone: 'Phone',
      warranty: 'Warranty',
      uploadWarrantyDocuments: 'Upload Warranty Documents',
      uploadProjectImage: 'Upload project image',
      contractorName: 'Contractor name',
      contactInformation: 'Contact information',
      warrantyDocuments: 'Warranty documents',
    },
    generalInformationForm: {
      name: 'Name',
      address: 'Address',
      city: 'City',
      country: 'Country',
      addressLineOne: 'Address line 1',
      addressLineTwo: 'Address line 2(optional)',
      selectOrAddBeneficiary: 'Select or add beneficiary',
      selectBeneficiary: 'Select beneficiary',
      beneficiary: {
        types: {
          beneficiary: 'Beneficiary',
          provider: 'Provider',
          tenant: 'Tenant',
        },
      },
      manager: 'Manager',
      selectOrAddBuildingManager: 'Select or add building manager',
      selectBuildingManager: 'Select building manager',
    },
    tenant: {
      addTenantInformation: 'Add tenant information',
      locations: 'Location(s)',
      officeManagers: 'Office manager(s)',
      addAnotherLocation: 'Add another location',
      saveTenant: 'Save tenant',
      saveNewTenant: 'Save new tenant',
      selectOfficeManager: 'Select an office manager',
      removeTenant: 'Remove tenant',
      noDataText: 'Hit the blue plus button and start adding tenants',
      noTenants: 'No Tenants',
    },
    activateDraftModal: {
      cancel: 'No, cancel',
      confirm: 'Yes, activate!',
      message: 'Are you sure you want to activate this project?',
    },
    deleteDraftModal: {
      cancel: 'No, cancel',
      confirm: 'Yes, delete!',
      message:
        'Are you sure you want to delete this project and all its information? You cannot undo this action.',
    },
    assignedProjects: 'Assigned projects',
    noAssignedProjects: "You haven't been assigned to any project",
    noActiveProjects: "You don't have any active projects",
    notFound: 'Project with given ID not found',
    teamMembers: 'Team members',
    tenants: 'Tenants',
    beneficiary: 'Beneficiary',
    projectName: {
      itself: 'Project Name',
      '*': 'Project Name *',
    },
    giveProjectAName: 'Give project a name',
    startDate: {
      itself: 'Start date',
      '*': 'Start date *',
    },
    endDate: {
      itself: 'End date',
      '*': 'End date *',
    },
    projectDurationError:
      'Project duration should be at least 6 months and not greater than 2 years',
    summary: 'Summary',
    projectDescription: 'Project description',
    media: 'Media',
    generalContractor: 'General contractor',
    dangerZone: 'Danger zone',
    archiveModal: {
      cancel: 'No, cancel',
      confirm: 'Yes, archive!',
      message: 'Are you sure you want to archive this project?',
    },
    projectActivation: {
      itself: 'Project activation',
      button: 'Activate project',
      message:
        'Activating your project will set all its components into motion. Once activated, the project will be fully operational and visible to all relevant parties. Please ensure all project details are accurate and complete before activation.',
    },
    deletingProject: {
      itself: 'Deleting the project',
      button: 'Delete project',
      message:
        'Deleting this project will permanently remove all associated data. This action is irreversible. Please ensure you have backed up any necessary information before proceeding with deletion.',
    },
    archiveProject: {
      itself: 'Archive project',
      button: 'Archive project',
      message:
        'Archiving this project will preserve its history and data, including equipment, maintenance procedures, while removing it from active operations. The project will no longer be editable or visible in the active projects list, but you can access its complete history for reference or audit purposes. Archiving is a suitable option for completed projects that you wish to retain for record-keeping.',
    },
    extendProjectModal: {
      itself: 'Extend project end date',
      confirm: 'Yes, extend!',
      message:
        'Are you sure you want to extend the project? This action is irreversible.',
    },
    extendProject: {
      itself: 'Extend project end date',
      button: 'Extend project',
      message:
        'Extending the project will prolong all maintenance procedure events that do not have a set end date. This action is irreversible.',
    },
    dashboard: {
      itself: 'Dashboard',
      dailyCheck: 'Daily check',
      preventiveMaintenance: 'Preventive maintenance',
      tickets: 'Tickets',
      createTicket: 'Create Ticket',
      maintenancePlan: 'Maintenance plan',
      warrantyNotes: 'Warranty notes',
      createWarrantyNotes: 'Create Warranty Note',
      projectBoard: {
        noItems: 'No tasks yet',
        blockTitle: 'Project board',
        modalTitleCreate: 'Create a task',
        modalTitleEdit: 'Edit the task',
        infoModal: {
          markTodo: 'Mark as todo',
          markCompleted: 'Mark as completed',
        },
        types: {
          personal: 'Personal task',
          team: 'Team task',
        },
        statuses: {
          toDo: 'Todo',
          completed: 'Completed',
        },
        tabs: {
          team: 'Team',
          personal: 'Personal',
        },
        form: {
          timestampReminder: {
            itself: 'Remind date',
            error: 'The remind date must be less than date.',
          },
        },
        saveTask: 'Save Task',
      },
      procurementOrders: 'Procurement orders',
      createProcurementOrders: 'Create Procurement Order',
      createActivityRegistry: 'Create Activity Registry',
      announcements: 'Announcements',
      weather: 'Weather',
      next7Days: 'Next 7 days',
    },
    exitProject: 'Exit project',
  },
};
