import { STATUS } from '../../utils/constants';

const rootSelector = (state) => state.notification;
const listSelector = (state) => rootSelector(state).list;
const lastRemovedNotificationSelector = (state) =>
  rootSelector(state).lastRemovedNotification;
const resetListSelector = (state) => rootSelector(state).resetList;

export const notificationItemsSelector = (state) => listSelector(state).items;

export const notificationTotalSelector = (state) => listSelector(state).total;

export const notificationItemsInPendingSelector = (state) =>
  listSelector(state).status === STATUS.LOADING;

export const lastRemovedNotificationInPendingSelector = (state) =>
  lastRemovedNotificationSelector(state).status === STATUS.LOADING;

export const notificationResetListInPendingSelector = (state) =>
  resetListSelector(state).status === STATUS.LOADING;
