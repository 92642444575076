export const GET = Object.freeze({
  REQUEST: 'maintenanceProcedure/GET_REQUEST',
  SUCCESS: 'maintenanceProcedure/GET_SUCCESS',
  ERROR: 'maintenanceProcedure/GET_ERROR',
});

export const GET_BY_EQUIPMENT_ID = Object.freeze({
  REQUEST: 'maintenanceProcedure/GET_BY_EQUIPMENT_ID_REQUEST',
  SUCCESS: 'maintenanceProcedure/GET_BY_EQUIPMENT_ID_SUCCESS',
  ERROR: 'maintenanceProcedure/GET_BY_EQUIPMENT_ID_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'maintenanceProcedure/FIND_REQUEST',
  SUCCESS: 'maintenanceProcedure/FIND_SUCCESS',
  ERROR: 'maintenanceProcedure/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'maintenanceProcedure/CREATE_REQUEST',
  SUCCESS: 'maintenanceProcedure/CREATE_SUCCESS',
  ERROR: 'maintenanceProcedure/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'maintenanceProcedure/UPDATE_REQUEST',
  SUCCESS: 'maintenanceProcedure/UPDATE_SUCCESS',
  ERROR: 'maintenanceProcedure/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'maintenanceProcedure/DELETE_REQUEST',
  SUCCESS: 'maintenanceProcedure/DELETE_SUCCESS',
  ERROR: 'maintenanceProcedure/DELETE_ERROR',
});

export const RESET_STATE = 'maintenanceProcedure/RESET_STATE';

export const GET_MAINTENANCE_PROCEDURES_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const GET_MAINTENANCE_PROCEDURES_BY_EQUIPMENT_ID_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_EQUIPMENT_ID.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_EQUIPMENT_ID.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_EQUIPMENT_ID.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_MAINTENANCE_PROCEDURE_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_MAINTENANCE_PROCEDURE_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_MAINTENANCE_PROCEDURE_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_MAINTENANCE_PROCEDURE_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});
