export default {
  ticket: {
    itself: 'Tichet',
    plural: {
      itself: 'Tichete',
    },
    createNewTicket: 'Creaază un tichet nou',
    createTicket: 'Creează tichet',
    participants: 'Participanți',
    information: 'Informații',
    priority: {
      itself: 'Prioritate',
      critical: 'Critică',
      high: 'Mare',
      medium: 'Medie',
      low: 'Mică',
      noPriority: 'Nicio prioritate',
    },
    openedOn: 'Deschis pe',
    dueDate: 'Dată expirare',
    lastUpdate: 'Ultima modificare',
    uploadFiles: 'Încarcă document, video sau poză',
    customDueDate: 'Dată expirare personalizată',
    confirmModals: {
      cancel: 'Nu, anulează',
      confirmClose: 'Da, închide!',
      confirmResolve: 'Da, rezolvă!',
      confirmCloseMessage: 'Ești sigur că vrei să închizi acest tichet?',
      confirmResolveMessage: 'Ești sigur că vrei să rezolvi acest tichet?',
      submitReasonClose: 'Scrie un motiv pentru închidere ..',
      submitReasonResolve: 'Scrie un motiv pentru rezolvare ..',
      reasonRequired: 'Motivul e obligatoriu',
      acceptTicket: 'Acceptă tichet',
      rejectTicket: 'Respinge tichet',
    },
    attributes: {
      subject: {
        itself: 'Subiect',
        '*': 'Subiect *',
        required: 'Subiectul e obligatoriu',
        placeholder: 'Nume tichet',
      },
      description: {
        itself: 'Descriere',
        '*': 'Descriere *',
        required: 'Descrierea e obligatorie',
        placeholder: 'Descrie problema',
      },
      contact: {
        itself: 'Contact',
        '*': 'Contact *',
        required: 'Contactul e obligatoriu',
        placeholder: 'Modalitate de contact alternativă',
      },
      location: {
        itself: 'Locație',
        '*': 'Locație *',
        required: 'Locația e obligatorie',
      },
      building: {
        itself: 'Clădire',
        '*': 'Clădire *',
        required: 'Clădirea e obligatorie',
        select: 'Selectează clădirea',
      },
      level: {
        itself: 'Nivel',
        '*': 'Nivel *',
        required: 'Nivelul e obligatoriu',
        select: 'Selectează nivelul',
      },
      room: {
        itself: 'Cameră',
        '*': 'Cameră *',
        required: 'Camera e obligatorie',
        select: 'Selectează camera',
      },
      recipient: {
        itself: 'Destinatar',
        '*': 'Destinatar *',
        required: 'Destinatarul e obligatoriu',
      },
      attachments: {
        itself: 'Atașamente',
        '*': 'Atașamente *',
        required: 'Atașamentele sunt obligatorii',
      },
      organization: {
        itself: 'Organizație',
        '*': 'Organizație *',
        required: 'Organizația e obligatorie',
      },
      user: {
        itself: 'Utilizator',
        '*': 'Utilizator *',
        required: 'Utilizatorul e obligatoriu',
      },
      status: {
        new: 'Nou',
        opened: 'Deschis',
        onHold: 'In asteptare',
        resolved: 'Rezolvat',
        closed: 'Închis',
        overdue: 'Depasit',
        all: 'Toate',
      },
    },
    selector: {
      myTickets: 'Biletele mele',
      allTickets: 'Toate biletele',
      tenantTickets: 'Bilete de chiriaș',
      beneficiaryTickets: 'Bilete de beneficiar',
    },
    progressOverview: 'Prezentare generală a progresului',
    chat: {
      ticketOpened: 'Biletul deschis',
      ticketResolved: 'Biletul rezolvat. Astept raspunsul chiriasului...',
      closingWasRejected: 'Închiderea a fost respinsă, cu următoarea notă:',
      closingWasAccepted: 'Închiderea a fost acceptată, cu a',
      starRating: 'rating cu stele',
      feedback: 'Părere:',
      closedByManager: 'Biletul a fost închis de managerul de proiect',
      closedDueToInactivity: 'Biletul a fost închis din cauza inactivității',
      ticketOnHold: 'Biletul în așteptare',
      ticketReopened: 'Biletul a fost redeschis',
      hasBeenAddedAsParticipant: 'a fost adăugat ca participant',
      hasBeenRemovedFromParticipants: 'a fost eliminat de la participanți',
      procurementOrderWithId: 'O comandă de achiziție cu ID {{id}}',
      ticketWithId: 'Un ticket cu ID {{id}}',
      linkedToThisTicket: 'a fost legat de acest bilet.',
      unlinkedFromThisTicket: 'a fost deconectat de la acest bilet.',
      hasBeenCreated: 'a fost creat.',
      hasBeenClosed: 'a fost inchis.',
      procurementOrderOpened: 'Ordin de achiziție deschis',
      procurementOrderProposalSubmitted: 'Procurement order proposal submitted',
      priceProposal: 'propunere de pret: {{price}}',
      unlinkedFromThisProcurementOrder:
        'a fost deconectat de la acest ordin de achiziție.',
      linkedToThisProcurementOrder: 'a fost legat de ordinul de achiziție.',
      procurementOrderClosedDueToInactivity:
        'Comanda de achiziție a fost închisă din cauza inactivității',
      procurementOrderClosedByProjectManager:
        'Comanda de achiziție a fost închisă de managerul de proiect',
      procurementOrderWasClosedWithA:
        'Ordinul de achiziție a fost închis, cu a',
      procurementOrderWorkWasRejected:
        'S-a respins lucrarea de comandă de achiziție, cu următoarea mențiune: comanda a fost închisă, cu a',
      procurementOrderMarkedWorkFinished:
        'Comanda de achiziție a marcat lucrarea terminată',
      procurementOrderMarkedWorkInProgress:
        'Comanda de achiziție a marcat lucru în curs',
      procurementOrderAccepted: 'Ordin de achiziție acceptat',
      procurementOrderRejected: 'Ordinul de achiziție a fost respins',
      procurementOrderProposalCanceled:
        'Propunerea de comandă de achiziție a fost anulată.',
    },
    closeTicket: 'Închideți biletul',
  },
};
