import AbstractDirectMessagesChannelEventCatcher from './AbstractDirectMessagesChannelEventCatcher';
import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';
import createChatChannelItemModel from '../../../../store/chat/helpers/createChatChannelItemModel';

export default class DirectMessagesChannelAlreadyExistEventCatcher extends AbstractDirectMessagesChannelEventCatcher {
  static process({ chat }, loggedInUser, dispatch, history) {
    dispatch(
      generalChatMessagesConversationActionCreators.updateConversationInfo(
        chat.id,
        createChatChannelItemModel(chat, loggedInUser.id)
      )
    );

    this.redirectToConversation(chat, history);
  }
}
