import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatProjectsConversationActionsCreators } from '../../../../store/chat/general/actions';

export default class ProjectMessagesRemoveMessageEventCatcher extends AbstractEventCatcher {
  static process(removedMessage, dispatch) {
    dispatch(
      generalChatProjectsConversationActionsCreators.removeMessage(
        removedMessage.projectId,
        removedMessage.chat.id,
        removedMessage.id
      )
    );
  }
}
