import TypesHelper from '../../utils/types/TypesHelper';
import parseMessage from './parseMessage';
import GENERAL_CHAT_EVENTS from '../events/generalChat.events';
import processDirectMessagesChatEvents from './processDirectMessagesChatEvents';
import processProjectMessagesChatEvents from './processProjectMessagesChatEvents';
import TICKET_EVENTS from '../events/ticket.events';
import processTicketEvents from './processTicketEvents';
import PROCUREMENT_EVENTS from '../events/procurement.events';
import processProcurementEvents from './processProcurementEvents';
import PROJECT_BOARD_EVENTS from '../events/projectBoard.events';
import processProjectBoardEvents from './processProjectBoardEvents';

const checkIfEventDataValid = (event, data) => {
  if (!TypesHelper.isString(event) || !data) {
    // eslint-disable-next-line no-console
    console.error('There are no event or data');

    return false;
  }

  return true;
};

/**
 *
 * @param {{data: string}} message
 * @param {Dispatch} dispatch
 * @param {Object} history
 * @param {Object} loggedInUser
 */
const processEvent = (message, dispatch, history, loggedInUser) => {
  const { event, data } = parseMessage(message);

  if (!checkIfEventDataValid(event, data)) {
    return;
  }

  if (data.error) {
    // eslint-disable-next-line no-console
    console.error(data.error);

    return;
  }

  if (Object.values(GENERAL_CHAT_EVENTS.DIRECT_MESSAGES).includes(event)) {
    processDirectMessagesChatEvents({
      event,
      data,
      dispatch,
      history,
      loggedInUser,
    });

    return;
  }

  if (Object.values(GENERAL_CHAT_EVENTS.PROJECT_MESSAGES).includes(event)) {
    processProjectMessagesChatEvents({
      event,
      data,
      dispatch,
      history,
      loggedInUser,
    });

    return;
  }

  if (
    [
      TICKET_EVENTS.NOTIFICATION,
      ...Object.values(TICKET_EVENTS.CHAT.INTERNAL),
      ...Object.values(TICKET_EVENTS.CHAT.EXTERNAL),
    ].includes(event)
  ) {
    processTicketEvents({
      event,
      data,
      dispatch,
      history,
      loggedInUser,
    });

    return;
  }

  if (
    [
      ...Object.values(PROCUREMENT_EVENTS.CHAT.INTERNAL),
      ...Object.values(PROCUREMENT_EVENTS.CHAT.EXTERNAL),
    ].includes(event)
  ) {
    processProcurementEvents({
      event,
      data,
      dispatch,
      history,
      loggedInUser,
    });

    return;
  }

  if ([PROJECT_BOARD_EVENTS.NOTIFICATION].includes(event)) {
    processProjectBoardEvents({ event, data, dispatch });
  }
};

export default processEvent;
