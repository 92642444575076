import { createActions, createActionTypesConfig } from '../helpers';

export const GET = Object.freeze({
  REQUEST: 'user/GET_REQUEST',
  SUCCESS: 'user/GET_SUCCESS',
  ERROR: 'user/GET_ERROR',
});

export const GET_BY_ORGANIZATION_ID = Object.freeze({
  REQUEST: 'user/GET_BY_ORGANIZATION_ID_REQUEST',
  SUCCESS: 'user/GET_BY_ORGANIZATION_ID_SUCCESS',
  ERROR: 'user/GET_BY_ORGANIZATION_ID_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'user/FIND_REQUEST',
  SUCCESS: 'user/FIND_SUCCESS',
  ERROR: 'user/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'user/CREATE_REQUEST',
  SUCCESS: 'user/CREATE_SUCCESS',
  ERROR: 'user/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'user/UPDATE_REQUEST',
  SUCCESS: 'user/UPDATE_SUCCESS',
  ERROR: 'user/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'user/DELETE_REQUEST',
  SUCCESS: 'user/DELETE_SUCCESS',
  ERROR: 'user/DELETE_ERROR',
});

export const ACTIVATE_USER_ACTION_TYPES = createActionTypesConfig(
  'user',
  'ACTIVATE'
);

export const GET_PROJECT_TEAM_MEMBER_USERS = createActionTypesConfig(
  'user',
  'GET_PROJECT_TEAM_MEMBER_USERS'
);

export const GET_PROJECT_TENANT_USERS = createActionTypesConfig(
  'user',
  'GET_PROJECT_TENANT_USERS'
);

export const GET_PROJECT_BENEFICIARY_USERS = createActionTypesConfig(
  'user',
  'GET_PROJECT_BENEFICIARY_USERS'
);

export const GET_USERS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const GET_USERS_BY_ORGANIZATION_ID_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_ORGANIZATION_ID.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_ORGANIZATION_ID.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_ORGANIZATION_ID.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_USER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_USER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_USER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DEACTIVATE_USER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});

export const ACTIVATE_USER_ACTIONS = createActions(ACTIVATE_USER_ACTION_TYPES);

export const GET_PROJECT_TEAM_MEMBER_USERS_ACTION = createActions(
  GET_PROJECT_TEAM_MEMBER_USERS
);

export const GET_PROJECT_TENANT_USERS_ACTION = createActions(
  GET_PROJECT_TENANT_USERS
);

export const GET_PROJECT_BENEFICIARY_USERS_ACTION = createActions(
  GET_PROJECT_BENEFICIARY_USERS
);
