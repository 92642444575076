import ApiResourceRequest from './ApiResourceRequest';
import { required } from '../../utils/helpers';
import Pager from '../helpers/Pager';
import ListingResponse from './ListingResponse';

export class SubcontractorRequest extends ApiResourceRequest {
  get apiResource() {
    return 'subcontractors';
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_PROJECT_ID: `${this.apiRoute}/by-project-id/{projectId}`,
      GET_CATEGORIES: `${this.apiUrl}/${this.apiResource}/subcontractor-categories`,
    };
  }

  async getByProjectId(
    projectId,
    pager = new Pager(),
    query = {},
    newFilterFormat = false
  ) {
    pager && Pager.check(pager);
    required({ projectId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter, newFilterFormat),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_PROJECT_ID.replace('{projectId}', projectId) +
        queryString
    );

    return new ListingResponse(response?.data);
  }

  async getCategories() {
    const response = await this.request('GET', this.routes.GET_CATEGORIES);

    return response?.data?.data;
  }
}

export const subcontractorRequest = new SubcontractorRequest();
