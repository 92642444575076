import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import Header from './Header/Header';
import MainSection from './MainSection';
import MobileMenu from '../../stories/ui/mobile/MobileMenu/MobileMenu';
import { useShowMobileMenu } from '../../hooks/utilHooks';

const StyledPageLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 72px auto;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.gray[100]};
`;

export default function PageLayout({
  children,
  showSidebar,
  mobileMenuIsActive,
}) {
  const showMobileMenu = useShowMobileMenu();

  return (
    <StyledPageLayout>
      {!showMobileMenu && showSidebar && <Sidebar />}
      <Header showLoggedInUserInfo={!showMobileMenu} />

      {showMobileMenu && <MobileMenu mobileMenuIsActive={mobileMenuIsActive} />}
      <MainSection showMobileMenu={showMobileMenu}>{children}</MainSection>
    </StyledPageLayout>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showSidebar: PropTypes.bool,
  mobileMenuIsActive: PropTypes.bool,
};

PageLayout.defaultProps = {
  showSidebar: true,
  mobileMenuIsActive: false,
};
