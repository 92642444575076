import ApiResourceRequest, { API_METHODS } from './ApiResourceRequest';
import { required } from '../../utils/helpers';
import ListingResponse from './ListingResponse';
import Pager from '../helpers/Pager';
import ChatModel from '../../realTimeApi/models/ChatModel';

export class ProcurementRequest extends ApiResourceRequest {
  get apiResource() {
    return 'procurements';
  }

  get dynamicIds() {
    return {
      PROPOSAL_ID: '{proposalId}',
      PROJECT_ID: '{projectId}',
      PROCUREMENT_ID: '{procurementId}',
      CHAT_ID: '{chatId}',
      MESSAGE_ID: '{messageId}',
      CHAT_TYPE_ID: '{chatType}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      SUBMIT_PROPOSAL: `${this.apiRoute}/submit-proposal/${this.dynamicIds.PROPOSAL_ID}`,
      GET: `${this.apiRoute}/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_STATISTICS: `${this.apiRoute}/statistics/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_AVAILABLE_PARTICIPANTS_BY_PROCUREMENT_ID: `${this.apiRoute}/available-participants/${this.dynamicIds.PROCUREMENT_ID}/by-chat-type/${this.dynamicIds.CHAT_TYPE_ID}`,
      ADD_PARTICIPANT: `${this.apiRoute}/${this.dynamicIds.PROCUREMENT_ID}/chats/${this.dynamicIds.CHAT_ID}/participants`,
      GET_MESSAGES: `${this.apiRoute}/${this.dynamicIds.PROCUREMENT_ID}/chats/${this.dynamicIds.CHAT_ID}/messages`,
      DELETE_MESSAGE: `${this.apiRoute}/${this.dynamicIds.PROCUREMENT_ID}/chats/{chatId}/messages/${this.dynamicIds.MESSAGE_ID}`,
      MARK_WORK_FINISHED: `${this.apiRoute}/${this.dynamicIds.PROCUREMENT_ID}/workFinished`,
    };
  }

  async submitProposal(proposalId, body) {
    const response = await this.request(
      API_METHODS.POST,
      this.routes.SUBMIT_PROPOSAL.replace(
        this.dynamicIds.PROPOSAL_ID,
        proposalId
      ),
      body
    );
    return response?.data;
  }

  async get(projectId, pager, query = {}) {
    pager && Pager.check(pager);
    const queryString = super.getQueryString(pager, query);

    const response = await this.request(
      API_METHODS.GET,
      `${this.routes.GET.replace(
        this.dynamicIds.PROJECT_ID,
        projectId.toString()
      )}${queryString}`
    );

    return new ListingResponse(response?.data);
  }

  async getStatistics(projectId) {
    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_STATISTICS.replace(this.dynamicIds.PROJECT_ID, projectId)
    );

    return response.data;
  }

  async addParticipant(procurementId, chatId, userId, method = 'POST') {
    required({ procurementId, chatId, userId });

    const response = await this.request(
      method,
      this.routes.ADD_PARTICIPANT.replace(
        this.dynamicIds.PROCUREMENT_ID,
        procurementId
      ).replace(this.dynamicIds.CHAT_ID, chatId),
      { userId }
    );

    return response?.data;
  }

  removeParticipant(procurementId, chatId, userId) {
    return this.addParticipant(procurementId, chatId, userId, 'DELETE');
  }

  async getMessages(procurementId, chatId, lastMessageId = 0) {
    required({ procurementId, chatId });

    const response = await this.request(
      'GET',
      `${this.routes.GET_MESSAGES.replace(
        this.dynamicIds.PROCUREMENT_ID,
        procurementId
      ).replace(
        this.dynamicIds.CHAT_ID,
        chatId
      )}?lastMessageId=${lastMessageId}`
    );

    return {
      resources: response?.data?.data,
      total: response?.data?.total,
      lastReadMessageId: response?.data?.lastReadMessageId,
    };
  }

  async addMessage(procurementId, chatId, message, fileUuids = []) {
    required({ procurementId, chatId });
    if (!fileUuids.length) {
      required({ message });
    }

    const response = await this.request(
      'POST',
      this.routes.GET_MESSAGES.replace(
        this.dynamicIds.PROCUREMENT_ID,
        procurementId
      ).replace(this.dynamicIds.CHAT_ID, chatId),
      { message, files: fileUuids }
    );

    return new ListingResponse(response?.data);
  }

  async deleteMessage(procurementId, chatId, messageId) {
    required({ procurementId, chatId, messageId });

    const response = await this.request(
      'DELETE',
      this.routes.DELETE_MESSAGE.replace(
        this.dynamicIds.PROCUREMENT_ID,
        procurementId
      )
        .replace(this.dynamicIds.CHAT_ID, chatId)
        .replace(this.dynamicIds.MESSAGE_ID, messageId)
    );

    return response?.data;
  }

  async getAvailableParticipantsByProcurementId(procurementId, chatType) {
    required({ procurementId });
    if (!Object.values(ChatModel.RESOURCE_CHAT_TYPE).includes(chatType)) {
      throw new Error(
        `chatType must be one of ${Object.values(ChatModel.TYPES).toString()}`
      );
    }

    const response = await this.request(
      'GET',
      this.routes.GET_AVAILABLE_PARTICIPANTS_BY_PROCUREMENT_ID.replace(
        this.dynamicIds.PROCUREMENT_ID,
        procurementId
      ).replace(this.dynamicIds.CHAT_TYPE_ID, chatType)
    );

    return new ListingResponse(response?.data);
  }

  async markWorkFinished(procurementId, { accept, reason, rating }) {
    required({ procurementId });

    const response = await this.request(
      'POST',
      this.routes.MARK_WORK_FINISHED.replace(
        this.dynamicIds.PROCUREMENT_ID,
        procurementId
      ),
      {
        resolved: Boolean(accept),
        ...(reason ? { reason } : { reason: '' }),
        ...(rating ? { rating } : {}),
      }
    );

    return response?.data;
  }
}

export const procurementRequest = new ProcurementRequest();
