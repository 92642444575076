import GENERAL_CHAT_EVENTS from '../events/generalChat.events';
import DirectMessagesChannelsEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesChannelsEventCatcher';
import DirectMessagesHistoryEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesHistoryEventCatcher';
import DirectMessagesNewChannelEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesNewChannelEventCatcher';
import DirectMessagesChannelAlreadyExistEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesChannelAlreadyExistEventCatcher';
import DirectMessagesUpdateChannelEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesUpdateChannelEventCatcher';
import DirectMessagesNewMessageEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesNewMessageEventCatcher';
import DirectMessagesRemoveMessageEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesRemoveMessageEventCatcher';
import DirectMessagesChannelUnreadMessagesUpdatedEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesChannelUnreadMessagesUpdatedEventCatcher';
import DirectMessagesRemoveChannelEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesRemoveChannelEventCatcher';
import DirectMessagesAddParticipantToChannelEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesAddParticipantToChannelEventCatcher';
import DirectMessagesRemoveParticipantFromChannelEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesRemoveParticipantFromChannelEventCatcher';
import DirectMessagesNotificationEventCatcher from '../eventCatchers/chat/directMessages/DirectMessagesNotificationEventCatcher';

const processDirectMessagesChatEvents = ({
  event,
  data,
  dispatch,
  history,
  loggedInUser,
}) => {
  switch (event) {
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES.NOTIFICATION: {
      DirectMessagesNotificationEventCatcher.process(data, dispatch);
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES.GET_CHAT_CHANNELS: {
      DirectMessagesChannelsEventCatcher.process(data, dispatch, loggedInUser);
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES.GET_CHAT_HISTORY: {
      DirectMessagesHistoryEventCatcher.process(data, dispatch);
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES.CREATE_CONVERSATION: {
      DirectMessagesNewChannelEventCatcher.process(
        data,
        loggedInUser,
        dispatch,
        history
      );
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES.CONVERSATION_ALREADY_EXIST: {
      DirectMessagesChannelAlreadyExistEventCatcher.process(
        data,
        loggedInUser,
        dispatch,
        history
      );
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES.UPDATE_CONVERSATION: {
      DirectMessagesUpdateChannelEventCatcher.process(
        data,
        dispatch,
        loggedInUser
      );
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES.SEND_MESSAGE: {
      DirectMessagesNewMessageEventCatcher.process(data, dispatch);
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES.REMOVE_MESSAGE: {
      DirectMessagesRemoveMessageEventCatcher.process(data, dispatch);
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES
      .CONVERSATION_UNREAD_MESSAGES_IDS_UPDATED: {
      DirectMessagesChannelUnreadMessagesUpdatedEventCatcher.process(
        data,
        dispatch
      );
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES.REMOVE_CONVERSATION: {
      DirectMessagesRemoveChannelEventCatcher.process(data, dispatch, history);
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES
      .ADD_GROUP_CONVERSATION_PARTICIPANT: {
      DirectMessagesAddParticipantToChannelEventCatcher.process(
        data,
        dispatch,
        loggedInUser
      );
      break;
    }
    case GENERAL_CHAT_EVENTS.DIRECT_MESSAGES
      .REMOVE_GROUP_CONVERSATION_PARTICIPANT: {
      DirectMessagesRemoveParticipantFromChannelEventCatcher.process(
        data,
        loggedInUser,
        dispatch,
        history
      );
      break;
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn('There are no cases for this event', { event, data });
    }
  }
};

export default processDirectMessagesChatEvents;
