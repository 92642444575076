import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';
import AbstractDirectMessagesChannelEventCatcher from './AbstractDirectMessagesChannelEventCatcher';
import PATH from '../../../../routes/paths';

export default class DirectMessagesRemoveParticipantFromChannelEventCatcher extends AbstractEventCatcher {
  static process(
    { chatId, removedParticipant },
    loggedInUser,
    dispatch,
    history
  ) {
    dispatch(
      generalChatMessagesConversationActionCreators.removeParticipantFromConversation(
        chatId,
        removedParticipant.user.id
      )
    );

    if (removedParticipant.user.id === loggedInUser?.id) {
      const currentUrl = history.location.pathname;
      const projectId = AbstractDirectMessagesChannelEventCatcher.getProjectIdFromUrl(
        currentUrl
      );
      const channelListUrl = AbstractDirectMessagesChannelEventCatcher.getDirectMessagesChannelListUrl(
        projectId
      );

      currentUrl.includes(PATH.CHAT.CONVERSATION.LIST) &&
        history.replace(channelListUrl);
    }
  }
}
