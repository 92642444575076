export default {
  dailyCheckProcedure: {
    itself: 'Procedură',
    plural: {
      itself: 'Proceduri',
    },
    selectEquipmentOrGroup: 'Selectează echipament sau grup',
    equipmentOrGroup: 'Echipament sau grup',
    operation: {
      itself: 'Operațiune',
      plural: {
        itself: 'Operațiuni',
      },
    },
    description: 'Descriere',
    saveProcedure: 'Salvează procedura',
    saveNewProcedure: 'Salvează procedură nouă',
  },
};
