import AbstractEventCatcher from './AbstractEventCatcher';

export default class AbstractChatNotificationEventCatcher extends AbstractEventCatcher {
  static CHAT_NOTIFICATION_TYPES = Object.freeze({
    CHAT_SEND_MESSAGE: 'chatSendMessage',
  });

  static CHAT_NOTIFICATION_TYPES_AS_ARRAY = Object.values(
    this.CHAT_NOTIFICATION_TYPES
  );
}
