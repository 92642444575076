import AbstractModel from './AbstractModel';
import { STATUS } from '../../utils/constants';

export default class PMPEventModel extends AbstractModel {
  static STATUSES = Object.freeze({
    PLANNED: 'planned',
    OPENED: 'open',
    IN_PROGRESS: 'inProgress',
    ON_HOLD: 'onHold',
    RESOLVED: 'resolved',
    CLOSED: 'closed',
    OVERDUE: 'overdue',
    DRAFT: 'draft',
  });

  static READABLE_I18N_STATUSES = Object.freeze({
    [PMPEventModel.STATUSES.PLANNED]: 'pmp.eventStates.planned',
    [PMPEventModel.STATUSES.OPENED]: 'pmp.eventStates.opened',
    [PMPEventModel.STATUSES.IN_PROGRESS]: 'pmp.eventStates.inProgress',
    [PMPEventModel.STATUSES.ON_HOLD]: 'pmp.eventStates.onHold',
    [PMPEventModel.STATUSES.RESOLVED]: 'pmp.eventStates.resolved',
    [PMPEventModel.STATUSES.CLOSED]: 'pmp.eventStates.closed',
    [PMPEventModel.STATUSES.OVERDUE]: 'pmp.eventStates.overdue',
    [PMPEventModel.STATUSES.DRAFT]: 'pmp.eventStates.draft',
  });

  static FILTER = Object.freeze({
    PARAM: 'filter',
    BUILDING: 'buildingId',
    LEVEL: 'levelId',
    ROOM: 'roomId',
    INCLUDE_DISABLED: 'includeDisabled',
    INCLUDE_DRAFT: 'includeDraft',
    CATEGORY: 'projectCategoryId',
    GROUP: 'categoryGroupId',
    EQUIPMENT: 'equipmentId',
    FREQUENCY: 'frequency',
    TYPE: 'type',
    VALUE_DELIMITER: '.',
    KEY_DELIMITER: ',',
    KEY_VALUE_DELIMITER: ':',
  });

  constructor(...params) {
    super(...params);

    if (this.status !== STATUS.LOADING) {
      this.overwriteProperty('date', {
        get() {
          return this._date ? new Date(this._date) : null;
        },
      });
    }
  }

  convertAttribute(attribute) {
    if (attribute === 'status') {
      return 'eventStatus';
    }
    return super.convertAttribute(attribute);
  }
}
