import React from 'react';
import iconWrapper from 'hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function TicketsIcon({ size, color, ...rest }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 16.5V14.5M12 3L20 7.5V16.5L12 21L4 16.5V7.5L12 3ZM11.5 8L12 13L12.5 8H11.5Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(TicketsIcon);
