import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import {
  buildQueryKey,
  getUniqueArrayOfObjects,
  required,
} from '../utils/helpers';
import { STATUS } from '../utils/constants';
import TypesHelper from '../utils/types/TypesHelper';
import Pager from '../api/helpers/Pager';

/**
 * if we create a list it means the status will always be successful
 * because only successful responses build resource lists
 * @param resources
 * @returns {{error: null, items: *, status: string}}
 */
function createList(resources) {
  required({
    resources,
  });
  return {
    status: STATUS.SUCCESS,
    error: null,
    items: resources.map((resource) => resource.id),
  };
}

export function addToDictionary(
  dictionary,
  resources,
  resourceModel,
  config = { mergeRelations: [] }
) {
  required({
    dictionary,
    resources,
    resourceModel,
  });
  const newDictionary = { ...dictionary };

  resources.forEach((resource) => {
    const mergeRelations = config.mergeRelations.reduce(
      (accumulator, relation) => {
        return {
          ...accumulator,
          [relation]: getUniqueArrayOfObjects([
            ...(Array.isArray([resource.id]?.[relation])
              ? [resource.id]?.[relation]
              : []),
            ...resource?.[relation],
          ]),
        };
      },
      {}
    );
    newDictionary[resource.id] = new resourceModel({
      ...newDictionary[resource.id],
      ...resource,
      ...mergeRelations,
    });
  });

  return newDictionary;
}

/* !!! Old method (use updateList instead) */
export function addToList(list, { resources, pager, total, query }) {
  pager && Pager.check(pager);
  required({
    list,
    resources,
    pager,
    total,
    query,
  });
  if (
    !(
      TypesHelper.isNumber(pager?.pageNumber) &&
      TypesHelper.isNumber(pager?.pageSize)
    )
  ) {
    throw new Error('pageNumber and pageSize must be numbers!');
  }
  const newList = { ...list };
  const queryKey = buildQueryKey(query);
  if (
    list.pagination.pageNumber &&
    list.pagination.pageNumber !== pager?.pageNumber
  ) {
    return newList;
  }

  newList.pagination = {
    ...pager,
    total,
  };
  newList.meta = {
    currentKey: queryKey,
  };
  newList[queryKey] = { ...list[queryKey] };
  newList[queryKey][pager?.pageNumber] = createList(resources);

  return newList;
}

export const hasPageSizeChanged = (currentPageSize, nextPageSize) =>
  currentPageSize && currentPageSize !== nextPageSize;

export const resetState = (state, initialState, { queryKey, pager }) => {
  pager && Pager.check(pager);
  required({
    state,
    initialState,
    queryKey,
    pager,
  });
  // eslint-disable-next-line no-shadow
  const resetState = { ...initialState };
  resetState.list = {
    ...resetState.list,
    meta: {
      ...state.list.meta,
      currentKey: queryKey,
    },
    pagination: {
      ...state.list.pagination,
      ...pager,
    },
    [queryKey]: {
      [pager?.pageNumber]: {
        status: STATUS.LOADING,
      },
    },
  };

  return resetState;
};

/* New method (the old one is addToList) */
export const updateList = (list, { pager, ...restData }) => {
  return addToList(list, {
    ...restData,
    pager,
  });
};

export const changeListStatus = (
  state,
  { pager, queryKey, error },
  newStatus
) => {
  pager && Pager.check(pager);
  required({
    queryKey,
    pager,
    newStatus,
  });
  return {
    ...state,
    list: {
      ...state.list,
      meta: {
        ...state.list.meta,
        currentKey: queryKey,
      },
      pagination: {
        ...state.list.pagination,
        ...pager,
      },
      [queryKey]: {
        ...state.list?.[queryKey],
        [pager?.pageNumber]: {
          ...state.list?.[queryKey]?.[pager?.pageNumber],
          status: newStatus,
          error,
        },
      },
    },
  };
};

export const setPageNumber = (
  state,
  currentPage,
  lastPageSelector,
  resourcesSelector
) => {
  const lastPage = lastPageSelector(state);
  const pageCount = resourcesSelector(state).length;

  if (currentPage !== 1 && currentPage === lastPage && pageCount === 1) {
    return currentPage - 1;
  }

  return currentPage;
};

export const DEFAULT_UPDATE_META_CONFIG = Object.freeze({
  shouldFetchResources: false,
  partialUpdate: false,
});

export const createActionTypesConfig = (entityName, type) =>
  Object.freeze({
    REQUEST: `${entityName}/${type}_REQUEST`,
    SUCCESS: `${entityName}/${type}_SUCCESS`,
    ERROR: `${entityName}/${type}_ERROR`,
  });

export const createActions = (actionTypesConfig) =>
  Object.freeze({
    REQUEST: (payload) => ({
      type: actionTypesConfig.REQUEST,
      payload,
    }),
    SUCCESS: (payload) => ({
      type: actionTypesConfig.SUCCESS,
      payload,
      error: false,
    }),
    ERROR: (payload) => ({
      type: actionTypesConfig.ERROR,
      payload,
      error: true,
    }),
  });

/**
 * attributes should exclude reserved words
 * @param {Function} selectorCreator
 * @param {Array} selectorCreatorParams
 * @returns {Function}
 */
export const useSelectorCreator = (
  selectorCreator,
  selectorCreatorParams = []
) => {
  const deps = [...selectorCreatorParams];

  const selector = useMemo(
    () => {
      return selectorCreator(...selectorCreatorParams);
    },
    // eslint-disable-next-line
    deps
  );

  return useSelector(selector);
};

export const handleSortLocations = (l1, l2) => {
  if (l1?.building?.id === l2?.building?.id) {
    return l1.buildingLevel.positionIndex > l2.buildingLevel.positionIndex
      ? 1
      : -1;
  }
  return l1?.building?.id > l2?.building?.id ? 1 : -1;
};
