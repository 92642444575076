import ChatModel from '../../../realTimeApi/models/ChatModel';
import GeneralChatHistoryMessageModel from '../../../realTimeApi/models/GeneralChatHistoryMessageModel';

const createGeneralChatMessageModel = (
  historyItem,
  conversationInfo = null
) => {
  return new GeneralChatHistoryMessageModel({
    ...historyItem,
    ...(conversationInfo instanceof ChatModel ? { conversationInfo } : {}),
  });
};

export default createGeneralChatMessageModel;
