import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import {
  Link as RouterLink,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import CrossIcon from 'stories/ui/Icons/CrossIcon';
import clsx from 'clsx';
import { ReactComponent as CafmMainLogo } from 'assets/coralLogo40x40.svg';
import Typography from '@material-ui/core/Typography';
import { useProjectMenuItems } from '../../hooks/menu/useMenuItems';
import PATH from '../../routes/paths';
import { projectIdPattern } from '../../routes/replacePatterns';
import TypesHelper from '../../utils/types/TypesHelper';

const STATE = Object.freeze({
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded',
});

const StyledSidebar = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  transition: all 0.3s;
  border-right: 1px solid ${({ theme }) => theme.palette.themeCommon.separator};
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;

  &.${STATE.COLLAPSED} {
    width: 72px;
  }

  &.${STATE.EXPANDED} {
    width: 216px;
    padding: 0 27.5px;

    @media only screen and (max-width: 768px) {
      width: 100vw;
    }

    .sidebar-state-btn-container,
    .menu-item-container {
      justify-content: left;
    }

    .menu-item-container {
      position: relative;
      margin-left: -12px;
      font-size: ${({ theme }) =>
        theme.typography.pxToRem(theme.typography.fontSize)};

      .menu-item-text {
        opacity: 1;
      }

      &:not(.last-child):after {
        content: '';
        position: absolute;
        width: calc(100% - 12px);
        bottom: 0;
        left: 12px;
        border-bottom: 1px solid
          ${({ theme }) => theme.palette.themeCommon.separator};
      }

      &:last-child {
        :after {
          content: none !important;
        }
      }

      &.bottom-aligned:before {
          content: '';
          position: absolute;
          width: calc(100% - 12px);
          top: 0;
          left: 12px;
          border-top: 1px solid
            ${({ theme }) => theme.palette.themeCommon.separator};
        }
      }
    }
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 69px;
  right: -3px;
`;

const SidebarStateBtnContainer = styled.div`
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  border-bottom: 1px dashed
    ${({ theme }) => theme.palette.themeCommon.separator};
  margin-bottom: 1rem;
  cursor: pointer;
`;

const MenuItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  white-space: nowrap;

  @media only screen and (max-height: 1200px) {
    height: 50px;
  }

  @media only screen and (max-height: 1000px) {
    height: 45px;
  }

  @media only screen and (max-height: 840px) {
    height: 40px;
  }

  .menu-item-text {
    transition: opacity 0.7s ease-out;
    opacity: 0;
  }

  ${({ menuItemPath, currentPath, ignoredPaths, theme }) => {
    const doesCurrentPathIncludesItemPath = ignoredPaths.includes(menuItemPath)
      ? false
      : currentPath.includes(menuItemPath);

    if (
      TypesHelper.isString(currentPath) &&
      (currentPath === menuItemPath || doesCurrentPathIncludesItemPath)
    ) {
      return `
        color: ${theme.palette.primary.main};
        .MuiIconButton-label svg path {
          stroke: ${theme.palette.primary.main};
      `;
    }
    return null;
  }}
`;

const BottomAlignedItemContainer = styled(MenuItemContainer)`
  margin-top: auto;

  ${({ alignBottomSoloStyling }) =>
    alignBottomSoloStyling &&
    `
  margin-bottom: 1rem;
  :before {
    content: none !important;
  } 
  `}
`;

const ProjectTitle = styled(Typography)`
  font-size: ${({ theme }) =>
    theme.typography.pxToRem(theme.typography.fontSize + 5)};
  font-weight: 500;
`;

function Sidebar() {
  const [state, setState] = useState(STATE.COLLAPSED);
  const isCollapsed = () => state === STATE.COLLAPSED;
  const isExpanded = () => state === STATE.EXPANDED;
  const { menuItems } = useProjectMenuItems();
  const location = useLocation();
  const currentPath = location.pathname;
  const match = useRouteMatch([PATH.PROJECT.ONE, PATH.PROJECT.LIST]);
  const projectId = match?.params?.projectId;

  const ignoredPaths = [
    PATH.PROJECT.ONE.replace(projectIdPattern, projectId),
    PATH.PROJECT.LIST,
  ];

  return (
    <StyledSidebar className={state}>
      {isCollapsed() && <StyledCrossIcon />}
      <SidebarStateBtnContainer
        className="sidebar-state-btn-container"
        onClick={() =>
          setState(isCollapsed() ? STATE.EXPANDED : STATE.COLLAPSED)
        }
      >
        <CafmMainLogo />

        {state === STATE.EXPANDED && <ProjectTitle>CAFM</ProjectTitle>}
      </SidebarStateBtnContainer>
      {menuItems.map((menuItem) => {
        const ItemContainer = menuItem.alignBottom
          ? BottomAlignedItemContainer
          : MenuItemContainer;

        return (
          <ItemContainer
            key={menuItem.text}
            className={clsx('menu-item-container', {
              'bottom-aligned': menuItem.alignBottom,
            })}
            currentPath={currentPath}
            menuItemPath={menuItem.path}
            ignoredPaths={ignoredPaths}
            alignBottomSoloStyling={menuItem.alignBottomSoloStyling}
          >
            <Link component={RouterLink} to={menuItem.path}>
              <IconButton>{menuItem.icon}</IconButton>
              <span className="menu-item-text">
                {isExpanded() && menuItem.text}
              </span>
            </Link>
          </ItemContainer>
        );
      })}
    </StyledSidebar>
  );
}

export default Sidebar;
