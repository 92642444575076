export default {
  dailyCheckOperation: {
    itself: 'Operațiune',
    description: 'Descriere',
    label: 'Introdu o nouă etichetă',
    plural: {
      itself: '$ Operations',
    },
    selectEquipment: 'Selectează echipament',
    selectParameter: 'Selectează parametru',
    selectType: 'Selectează tipul',
    visualCheck: '$ Visual check',
    parameterCheck: '$ Parameter check',
    pressure: {
      itself: '$ Pressure',
      withUnit: '$ Pressure (bar)',
    },
    temperature: {
      itself: '$ Temperature',
      withUnit: '$ Temperature (Degree Celsius)',
    },
    parameter: {
      min: '$ min',
      max: '$ max',
    },
  },
};
