export default {
  room: {
    itself: 'Room',
    plural: {
      itself: 'Rooms',
    },
    modalDeleteMessage:
      'Are you sure you want to delete this room and all its information?',
  },
};
