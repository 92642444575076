export default {
  procedure: {
    itself: 'Procedură',
    plural: {
      itself: 'Proceduri',
    },
    selectFrequency: 'Selectează frecvență',
    startDate: 'Dată start',
    endDate: 'Dată sfârșit',
    deleteProcedure: 'Șterge procedura',
    enableProcedure: 'Activează procedura',
    disableProcedure: 'Dezactivează procedura',
    procedureWillBeDisabled: 'Această procedură va fi dezactivată pe {{date}}',
    procedureIsDisabled: 'Această procedură este dezactivată',
    procedureWillBeEnabledOn: 'Această procedură va fi activată pe {{date}}',
    enable: 'Activează',
    cancelEnable: 'Anulează activare',
    disable: 'Dezactivează',
    cancelDisable: 'Anulează dezactivare',
    frequency: 'Frecvență',
    type: 'Tip',
    subcontractor: 'Subcontractant',
    operations: 'Operațiuni',
    createNewProcedure: 'Creați o nouă procedură',
    updateProcedure: 'Editați procedura',
    saveNewProcedure: 'Salvați o nouă procedură',
    saveUpdatedProcedure: 'Salvați procedura editată',
    form: {
      description: 'Este necesară descrierea',
      operationType: 'Tipul este obligatoriu',
      parameters: {
        name: 'Numele este obligatoriu',
        parameter: 'Parametrul este necesar',
      },
      labels: {
        name: 'Numele este obligatoriu',
      },
    },
    categories: {
      heating: 'Incălzire',
      cooling: 'Răcire',
      ventilation: 'Ventilare',
      emergencySystem: 'Sistem de urgență',
      sanitary: 'Sanitar',
      plumbingSystem: 'Instalații sanitare',
      lowVoltage: 'Voltaj scazut',
      extraLowVoltage: 'Tensiune foarte joasă',
      others: 'Alte',
      fireFightingSystem: 'Sistem de stingere a incendiilor',
    },
    errors: {
      startDateShouldBeGreaterThanOrEqualToProjectStartDate:
        'Data de start trebuie să fie mai mare sau egală cu data de start a proiectului ({{date}})',
    },
    modal: {
      confirmDeleteMessage: `Ești sigur? <br /> <br /> Această acțiune este ireversibilă și va avea ca rezultat ștergerea evenimentelor de întreținere preventivă. Ai dorit să dezactivezi procedura de fapt?`,
    },
  },
};
