import AbstractDataAdapter from './AbstractDataAdapter';

class RealmDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return [
      'name',
      'websiteUrl',
      'organizationType',
      'addressOne',
      'addressTwo',
      'postalCode',
      'countryId',
      'cityId',
      'managerId',
    ];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return ['name', 'organizationType'];
  }

  static sendableAttributeRule(attribute) {
    if (attribute === 'organizationType') {
      return 'type';
    }
    return super.sendableAttributeRule(attribute);
  }
}

export default RealmDataAdapter;
