import * as Yup from 'yup';
import { HTTP_STATUS_CODE, ORDERS } from './constants';
import { QUERY_KEYS } from '../routes/paths/query.constants';
import TypesHelper from './types/TypesHelper';
import { LocationColor } from '../stories/ui/TenantOrganization/TenantOrganization';

/**
 * sleep the program execution for x milliseconds
 * @param milliseconds
 * @returns {Promise<unknown>}
 */
export const sleep = (milliseconds) =>
  new Promise((r) => setTimeout(r, milliseconds));

/**
 *
 * @param milliseconds
 * @param throwError
 * @returns {Promise<void>}
 */
export const simulateRequest = async (
  milliseconds = 1500,
  throwError = false
) => {
  await sleep(milliseconds);
  if (throwError) {
    throw new Error('Error fetching data!');
  }
};
/**
 *
 * @param ms
 * @param multiplier
 * @param requestPromiseCallback
 * @returns {Promise<*|{status: number}|{status: number}>}
 */
export const tryRequest = async (ms, multiplier, requestPromiseCallback) => {
  if (multiplier <= 1) {
    return {
      status: HTTP_STATUS_CODE.TIMEOUT,
    };
  }

  await sleep(ms);
  const response = await requestPromiseCallback();
  if (
    [HTTP_STATUS_CODE.NOT_FOUND, HTTP_STATUS_CODE.ACCEPTED].includes(
      response.status
    )
  ) {
    // eslint-disable-next-line no-param-reassign
    ms *= multiplier;
    // eslint-disable-next-line no-param-reassign
    multiplier -= 0.25;
    return tryRequest(ms, multiplier, requestPromiseCallback);
  }

  return response;
};

/**
 * checks if an object has no properties
 * @param object
 * @returns {boolean}
 */
export const isEmptyObject = (object = {}) => Object.keys(object).length === 0;

/**
 * builds a query key used as object index of a listing from redux store
 * @param query
 * @returns {string}
 */
export const buildQueryKey = (query = {}) => {
  if (isEmptyObject(query)) {
    return 'default';
  }

  let queryKey = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const [queryParam, paramValue] of Object.entries(query)) {
    if (!paramValue) {
      // eslint-disable-next-line no-continue
      continue;
    }
    queryKey += `${queryParam}=`;
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(paramValue)) {
      queryKey += `${key}:${value},`;
    }
    queryKey = `${queryKey.slice(0, -1)}&`;
  }
  return queryKey.slice(0, -1);
};

/**
 * checks if every key of the object given as param is not falsy (except 0)
 * @param data
 */
export const required = (data) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(data)) {
    if (!value && value !== 0) {
      throw new Error(`${key} is required! ${value} received!`);
    }
  }
};

/**
 * PropType helper to allow null values for network requested props that should actually be required
 * @param wrappedPropTypes
 * @returns {function(*=, *=, ...[*]): (null|*)}
 */
export const allowNull = (wrappedPropTypes) => (props, propName, ...rest) => {
  if (props[propName] === null) return null;
  return wrappedPropTypes(props, propName, ...rest);
};

/**
 *
 * @param str
 * @returns {*}
 */
export const toSnakeCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((letter) => letter.toLowerCase())
    .join('_');

/**
 *
 * @param url
 * @returns {boolean}
 */
export function hasQueryParams(url = '') {
  return url.includes('?');
}

/**
 *
 * @param url
 * @param {URLSearchParams | undefined} queryOriginal
 * @returns {{path: string, pageSize: number, page: number}}
 */
export const createCurrentPath = (url, queryOriginal) => {
  let query;

  const hasUrlQuery = hasQueryParams(url);

  if (queryOriginal) {
    query = queryOriginal;
  } else if (hasUrlQuery) {
    query = new URLSearchParams(url);
  } else {
    query = new URLSearchParams('');
  }

  const page = +query?.get(QUERY_KEYS.LISTING.PAGE);
  const pageSize = +query?.get(QUERY_KEYS.LISTING.PAGE_SIZE);

  return {
    path: hasUrlQuery
      ? url
      : `${url}${query.toString() ? `?${query.toString()}` : ''}`,
    page: page || 1,
    pageSize: pageSize || 10,
  };
};

/**
 *
 * @type {string}
 */
export const uuidRegex =
  '[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}';

/**
 *
 * @type {string}
 */
export const numericRegex = '\\d+';

/**
 *
 * @type {RegExp}
 */
export const pinCodeRegex = new RegExp(/^(\d{4}|\d{6})$/);

/**
 *
 * @type {RegExp}
 */
export const numberRangeRegexp = new RegExp(/[^\d]/, 'g');

/**
 *
 * @type {RegExp}
 */
export const zeroSequenceRegex = new RegExp(/^0+/, 'g');

/**
 *
 * @type {RegExp}
 */
export const htmlTagRegex = new RegExp(/<[^>]*>/, 'g');

/**
 *
 * @param {Element} element
 * @param {Element | Window} container
 * @returns {boolean}
 */
export const isInViewport = (element, container = window) => {
  if (!(element instanceof Element)) {
    throw new Error('element must be an Element type!');
  }

  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (container.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (container.innerWidth || document.documentElement.clientWidth)
  );
};

const validEmailSchema = Yup.string().email();
/**
 *
 * @param email
 * @returns {boolean|*}
 */
export const emailIsValid = (email) => validEmailSchema.isValidSync(email);

/**
 *
 * @param roles
 * @returns {[{name: string, notFromApi: null, title: string}]|[undefined]|[{name: string, notFromApi: boolean, title: string}]}
 */
export const getHumanReadableRoles = (roles) => {
  if (!roles || !roles?.length) {
    return [
      {
        name: 'No role',
        title: 'No role',
        notFromApi: true,
      },
    ];
  }

  const humanReadableRole = roles[0];

  if (humanReadableRole) {
    return [humanReadableRole];
  }

  return [
    {
      name: 'Unknown role',
      title: 'Unknown role',
      notFromApi: true,
    },
  ];
};

/**
 * @deprecated
 * @param obj
 * @returns {{}}
 */
export const getExistingValues = (obj) => {
  const existingValuesObj = {};
  Object.keys(obj).forEach((key) => {
    if ((obj[key] || TypesHelper.isBoolean(obj[key])) && obj[key] !== 0) {
      existingValuesObj[key] = obj[key];
    }
  });

  return existingValuesObj;
};

export const getExistingValuesNew = (obj) => {
  const existingValuesObj = {};
  Object.keys(obj).forEach((key) => {
    if (![undefined, null, ''].includes(key)) {
      existingValuesObj[key] = obj[key];
    }
  });

  return existingValuesObj;
};

/**
 *
 * @param date
 * @returns {number}
 */
export const getTimeOfDate = (date) => {
  if (date instanceof Date) {
    return date.getTime() / 1000;
  }

  if (typeof date === 'string') {
    return new Date(date).getTime() / 1000;
  }

  throw new Error('date must be a Date object or a string!');
};

/**
 *
 * @param arrayWithDuplicates
 * @param uniqueProperty
 * @returns {*}
 */
export const getUniqueArrayOfObjects = (
  arrayWithDuplicates,
  uniqueProperty = 'id'
) => {
  return arrayWithDuplicates.filter(
    (value, index, array) =>
      array.findIndex(
        (element) => element[uniqueProperty] === value[uniqueProperty]
      ) === index
  );
};

/**
 *
 * @param url
 */
export const downloadLocalFile = (url) => {
  const link = document.createElement('a');
  link.download = '';
  link.href = url;
  link.click();
};

/**
 *
 * @param url
 */
export const openLinkInNewTab = (url) => {
  window.open(url, '_blank');
};

/**
 *
 * @param file
 * @returns {*}
 */
export const getFileExtension = (file) => {
  const filePath = file.path ?? file.name;
  return filePath.reverse().split('.')[0].reverse();
};

/**
 *
 * @returns {URLSearchParams}
 */
export const getQueryParams = () => {
  return new URLSearchParams(window.location.search);
};

/**
 *
 * @param count
 * @param total
 * @param options
 * @returns {string|number}
 */
export const getPercentage = (
  count,
  total,
  options = { rounded: true, digits: true, noDigits: 1 }
) => {
  if (total <= 0) {
    return 0;
  }
  const { rounded, digits, noDigits } = options;
  const result = (count / total) * 100;

  if (!rounded) {
    return result;
  }
  if (digits) {
    const roundedResult = result.toFixed(noDigits);
    const decimals = roundedResult.split('.')[1];

    let pure = true;
    for (let i = 0; i < decimals?.length; i += 1) {
      if (decimals[i] !== '0') {
        pure = false;
      }
    }
    if (pure) {
      return roundedResult.split('.')[0];
    }
    return roundedResult;
  }

  return Math.round(result);
};

/**
 *
 * @param count
 * @param total
 * @param options
 * @returns {string}
 */
export const displayPercentage = (count, total, options) => {
  return `${getPercentage(count, total, options)}%`;
};

/**
 *
 * @param {Array<Object>} array
 * @param {string} prop
 * @param {'asc' | 'desc'} order
 * @returns {Array<Object>}
 */
export const sortArrayByObjectDateProp = (
  array,
  prop = 'date',
  order = ORDERS.ASC
) => {
  if (!Object.values(ORDERS).includes(order)) {
    throw new Error('Invalid order');
  }

  const isASC = order === ORDERS.ASC;
  const arrayCopy = [...array];

  return arrayCopy.sort((a, b) => {
    return isASC
      ? new Date(a[prop]) - new Date(b[prop])
      : new Date(b[prop]) - new Date(a[prop]);
  });
};

/**
 *
 * @param {Array<Object>} array
 * @param {string} prop
 * @param {'asc' | 'desc'} order
 * @returns {Array<Object>}
 */
export const sortArrayByObjectProp = (
  array = [],
  prop = 'id',
  order = ORDERS.ASC
) => {
  if (!Object.values(ORDERS).includes(order)) {
    throw new Error('Invalid order');
  }

  const isASC = order === ORDERS.ASC;
  const arrayCopy = [...array];

  return arrayCopy.sort((a, b) => {
    return isASC ? a[prop] - b[prop] : b[prop] - a[prop];
  });
};

/**
 *
 * @param {Element} element
 * @returns {boolean}
 */
export const hasScrollbarVisible = (element) => {
  return element.scrollHeight > element.clientHeight;
};

/**
 *
 * @param {Array<RealmModel>} tenantUsers
 * @returns {object} updatedBuildingColors
 */
export const getTenantsBuildingColors = (tenantUsers) => {
  const locationColor = new LocationColor();
  const buildingIds = [];
  const updatedBuildingColors = {};

  tenantUsers.forEach((tenantUser) => {
    tenantUser.locations
      .sort((l1, l2) => l1.building?.id - l2.building?.id)
      .forEach((location) => {
        if (location?.building?.id) {
          buildingIds.push(location.building.id);
        }
      });
  });
  const uniqueBuildingIds = [...new Set(buildingIds)];
  uniqueBuildingIds.forEach((buildingId, index) => {
    updatedBuildingColors[buildingId] = locationColor.pickColor(index);
  });
  return updatedBuildingColors;
};

export const cutStringByMaxLength = (
  string,
  maxLength = 50,
  endSymbol = '...'
) => {
  if (!TypesHelper.isString(string)) {
    throw new Error('string param should have string type');
  }

  return string?.length > maxLength
    ? `${string.slice(0, maxLength)}${endSymbol}`
    : string;
};

export const groupBy = (
  list,
  keyGetter,
  options = { convertResultToArray: true }
) => {
  const map = new Map();

  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return options.convertResultToArray ? Array.from(map) : map;
};

export const getCompletedPercent = (resolved, total) => {
  return total ? Number(((resolved / total) * 100).toFixed()) : 0;
};

export const getUniqueArrayOfObjectsWithCheck = (
  arrayWithDuplicates,
  uniqueProperty = 'id',
  checker
) => {
  const resultArray = [];
  const arrayWithDuplicatesCopy = [...arrayWithDuplicates];
  const alreadyCheckedValues = [];

  arrayWithDuplicatesCopy.forEach((item, index, array) => {
    if (alreadyCheckedValues.includes(item?.[uniqueProperty])) {
      return;
    }

    let selectedObject = item;

    array.forEach((subItem, subIndex) => {
      if (
        subItem?.[uniqueProperty] === item?.[uniqueProperty] &&
        subIndex !== index
      ) {
        selectedObject = checker(selectedObject, subItem);
      }
    });

    alreadyCheckedValues.push(item?.[uniqueProperty]);
    resultArray.push(selectedObject);
  });

  return resultArray;
};

export function getElementOffset(element) {
  if (!element) {
    return {
      left: 0,
      top: 0,
    };
  }

  const rect = element.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
}

export function removeHtmlTags(string) {
  return string.replaceAll(htmlTagRegex, ' ');
}

export const insertValueBetweenElements = (arr, value = '/') => {
  const formattedArr = arr.reduce((r, a) => r.concat(a, value), []);

  formattedArr.pop();

  return formattedArr;
};

export const doesArrayIncludeSomeValues = (basicArray, arrayToCompare) => {
  return basicArray.some((value) => arrayToCompare.includes(value));
};
