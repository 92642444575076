export const GET = Object.freeze({
  REQUEST: 'city/GET_REQUEST',
  SUCCESS: 'city/GET_SUCCESS',
  ERROR: 'city/GET_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'city/FIND_REQUEST',
  SUCCESS: 'city/FIND_SUCCESS',
  ERROR: 'city/FIND_ERROR',
});

export const GET_STATE_CITIES = Object.freeze({
  REQUEST: 'city/GET_STATE_CITIES_REQUEST',
  SUCCESS: 'city/GET_STATE_CITIES_SUCCESS',
  ERROR: 'city/GET_STATE_CITIES_ERROR',
});

export const GET_CITIES_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const GET_STATE_CITIES_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_STATE_CITIES.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_STATE_CITIES.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_STATE_CITIES.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_CITY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});
