import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? `${props.height}px;` : '100vh;')};
`;

const Loader = ({ height, size, ...rest }) => (
  <Wrapper height={height} {...rest}>
    <CircularProgress color="primary" size={size} />
  </Wrapper>
);

Loader.propTypes = {
  height: PropTypes.number,
  size: PropTypes.number.isRequired,
};

Loader.defaultProps = {
  height: 0,
};

export default Loader;
