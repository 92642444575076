import { lazy } from 'react';
import PATH from 'routes/paths';

const StandardProcedureList = lazy(() =>
  import('containers/standard/procedure/StandardProcedureList')
);

const STANDARD_PROCEDURE_LIST = {
  component: StandardProcedureList,
  path: PATH.STANDARD.PROCEDURE.LIST,
  exact: false,
  isProtected: true,
};

export default [STANDARD_PROCEDURE_LIST];
