import { createSelector } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/constants';
import { authUserSelector } from '../auth/selectors';
import PATH from '../../routes/paths';

export const dictionarySelector = (state) => state.user.dictionary;
const listDataSelector = (state) => state.user.list;
const currentKeySelector = (state) => state.user.list.meta.currentKey;
const pageNumberSelector = (state) => state.user.list.pagination.pageNumber;
const pageSizeSelector = (state) => state.user.list.pagination.pageSize;

const listSelector = createSelector(
  [listDataSelector, currentKeySelector],
  (listData, currentKey) => {
    return listData[currentKey];
  }
);

export const usersSelector = createSelector(
  [listSelector, pageNumberSelector, dictionarySelector],
  (list, pageNumber, dictionary) => {
    if (!list?.[pageNumber]?.items) {
      return [];
    }

    const users = list[pageNumber].items.map((userId) => dictionary[userId]);

    return users;
  }
);

export const totalUsersSelector = createSelector(
  [listDataSelector],
  (listData) => listData.pagination.total
);

export const lastPageSelector = createSelector(
  [totalUsersSelector, pageSizeSelector],
  (total, pageSize) => Math.ceil(total / pageSize)
);

export const usersLoadingSelector = createSelector(
  [listSelector, pageNumberSelector],
  (list, pageNumber) => list?.[pageNumber]?.status === STATUS.LOADING
);

export const findUserSelector = (state, { match }) => {
  let userId = match.params.id || match.params.userId;
  const onProfilePaths = [PATH.MY_PROFILE.SHOW, PATH.MY_PROFILE.EDIT].includes(
    match.path
  );

  if (onProfilePaths) {
    userId = authUserSelector(state).id;
  }

  if (!userId) {
    return null;
  }
  const { dictionary } = state.user;

  if (!dictionary[userId]) {
    return null;
  }

  return dictionary[userId];
};
