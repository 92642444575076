import AbstractEventCatcher from '../AbstractEventCatcher';
import { procurementChatActionCreators } from '../../../store/chat/resource/actions';

export default class ProcurementChatAddParticipantEventCatcher extends AbstractEventCatcher {
  static process({ participantInfo, dispatch }) {
    dispatch(
      procurementChatActionCreators.addParticipant(
        participantInfo.procurementId,
        participantInfo.chatId,
        participantInfo.newParticipant
      )
    );
  }
}
