import { lazy } from 'react';
import PATH from 'routes/paths';

const AnnouncementList = lazy(() =>
  import('containers/announcement/AnnouncementList')
);

export const ANNOUNCEMENT_LIST = {
  component: AnnouncementList,
  path: PATH.ANNOUNCEMENT.LIST,
  exact: false,
  isProtected: true,
};

export default [ANNOUNCEMENT_LIST];
