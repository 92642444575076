import ApiResourceRequest, { API_METHODS } from '../ApiResourceRequest';
import Pager from '../../helpers/Pager';
import ListingResponse from '../ListingResponse';
import { required } from '../../../utils/helpers';

export class DailyProcedureGroupRequest extends ApiResourceRequest {
  get apiResource() {
    return 'dailycheck-procedures/group';
  }

  get dynamicIds() {
    return {
      PROJECT_ID: '{projectId}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      GET: `${this.apiUrl}/dailycheck-procedures/by-project-id/${this.dynamicIds.PROJECT_ID}/by-group`,
    };
  }

  async get(projectId, pager, query = {}) {
    required({ projectId });
    pager && Pager.check(pager);
    const queryString = this.getQueryString(pager, query, true);

    const response = await this.request(
      API_METHODS.GET,
      `${this.routes.GET.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      )}${queryString}`
    );

    return new ListingResponse(response?.data);
  }
}

export const dailyProcedureGroupRequest = new DailyProcedureGroupRequest();
