import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import PATH from '../../routes/paths';

const AppRoute = ({
  component: Component,
  isAuthenticated,
  isProtected,
  authUserHasOtp,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isAuthenticated && isProtected) {
        return (
          <Redirect
            to={{
              pathname: PATH.AUTH.LOGIN,
            }}
          />
        );
      }

      // eslint-disable-next-line react/prop-types
      if (!authUserHasOtp && props.match.path === PATH.AUTH.CREATE_PASSWORD) {
        return (
          <Redirect
            to={{
              pathname: PATH.DASHBOARD,
            }}
          />
        );
      }

      /* eslint-disable-next-line react/prop-types */
      if (authUserHasOtp && !(props.match.path === PATH.AUTH.CREATE_PASSWORD)) {
        return (
          <Redirect
            to={{
              pathname: PATH.AUTH.CREATE_PASSWORD,
            }}
          />
        );
      }

      return <Component {...props} />;
    }}
  />
);

AppRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  authUserHasOtp: PropTypes.bool.isRequired,
  isProtected: PropTypes.bool,
};

AppRoute.defaultProps = {
  isProtected: true,
};

export default AppRoute;
