export default {
  warrantyTracker: {
    itself: 'Warranty Tracker',
    plural: {
      itself: 'Warranty Trackers',
    },
    statuses: {
      inProgress: 'In Progress',
      onHold: 'On Hold',
      underObservation: 'Under Observation',
      closed: 'Closed',
    },
    createNewWarrantyTrackerItem: 'Create a new warranty tracker',
    createNewWarrantyNote: 'Create new warranty note',
    editWarrantyNote: 'Edit warranty note',
    editWarrantyTrackerItem: 'Edit warranty tracker',
    createItem: 'Create item',
    editItem: 'Edit item',
    actions: {
      addAdditionalNote: 'Add additional note',
      updateDueDate: 'Update due date',
    },
    lastNote: 'Last Note',
    attributes: {
      subject: {
        itself: 'Subject',
        '*': 'Subject *',
        required: 'Subject is required',
      },
      description: {
        itself: 'Description',
        '*': 'Description *',
        required: 'Description is required',
        placeholder: 'Describe the item',
      },
      location: {
        itself: 'Location',
        '*': 'Location *',
        required: 'Location is required',
      },
      building: {
        itself: 'Building',
        '*': 'Building *',
        required: 'Building is required',
        select: 'Select building',
      },
      level: {
        itself: 'Level',
        '*': 'Level *',
        required: 'Level is required',
        select: 'Select level',
      },
      room: {
        itself: 'Room',
        '*': 'Room *',
        required: 'Room is required',
        select: 'Select room',
      },
      category: {
        itself: 'Category',
        '*': 'Category *',
        required: 'Category is required',
        options: {
          mep: 'MEP',
          civil: 'Civil',
        },
        types: {
          consumables: 'Consumables',
          tools: 'Tools',
        },
      },
      equipment: {
        itself: 'Equipment',
        '*': 'Equipment *',
        required: 'Equipment is required',
        placeholder: 'Select equipment',
      },
      subcontractor: {
        itself: 'Subcontractor',
        '*': 'Subcontractor *',
        required: 'Subcontractor is required',
      },
      assignedTo: {
        itself: 'Assigned to',
        '*': 'Assigned to *',
        required: 'Assigned to is required',
      },
    },
    status: {
      opened: 'Opened',
      submitted: 'Submitted',
      inProgress: 'In progress',
      underObservation: 'Under observation',
      onHold: 'On hold',
      closed: 'Closed',
    },
    note: {
      status: {
        created: 'Created',
        updated: 'Updated',
        subject: 'Subject',
        closed: 'Closed',
      },
    },
    exportWarrantyTracker: 'Export Warranty Tracker Data',
    showOverdue: 'Show overdue',

    conclusion: {
      itself: 'Conclusion',
      '*': 'Conclusion *',
      required: 'Conclusion is required',
    },
    addConclusion: 'Add conclusion',
  },
};
