import { createActions, createActionTypesConfig } from 'store/helpers';

const DAILY_PROCEDURE_GROUP_LABEL = 'dailyProcedureGroup';

export const GET = createActionTypesConfig(DAILY_PROCEDURE_GROUP_LABEL, 'GET');
export const FIND = createActionTypesConfig(
  DAILY_PROCEDURE_GROUP_LABEL,
  'FIND'
);
export const CREATE = createActionTypesConfig(
  DAILY_PROCEDURE_GROUP_LABEL,
  'CREATE'
);
export const UPDATE = createActionTypesConfig(
  DAILY_PROCEDURE_GROUP_LABEL,
  'UPDATE'
);
export const DELETE = createActionTypesConfig(
  DAILY_PROCEDURE_GROUP_LABEL,
  'DELETE'
);

export const GET_DAILY_PROCEDURE_GROUPS_ACTION = createActions(GET);
export const FIND_DAILY_PROCEDURE_GROUP_ACTION = createActions(FIND);
export const CREATE_DAILY_PROCEDURE_GROUP_ACTION = createActions(CREATE);
export const UPDATE_DAILY_PROCEDURE_GROUP_ACTION = createActions(UPDATE);
export const DELETE_DAILY_PROCEDURE_GROUP_ACTION = createActions(DELETE);
