import AbstractEventCatcher from '../AbstractEventCatcher';
import { ticketChatActionCreators } from '../../../store/chat/resource/actions';

export default class TicketChatEventCatcher extends AbstractEventCatcher {
  static process({ chat, loggedInUser, dispatch }) {
    dispatch(
      ticketChatActionCreators.setChat(
        chat.ticketId,
        chat.id,
        chat,
        loggedInUser
      )
    );

    dispatch(
      ticketChatActionCreators.addLastReadMessageToEachHistory({
        [chat.ticketId]: {
          [chat.id]: {
            lastReadMessage: chat.lastReadMessage,
            unreadMessagesIds: chat.unreadMessagesIds,
          },
        },
      })
    );
  }
}
