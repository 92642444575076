import jwtDecode from 'jwt-decode';
import AbstractRequest from './AbstractRequest';
import AuthClientStore from '../../clientStore/AuthClientStore';
import { API_METHODS } from './ApiResourceRequest';

export class AuthRequest extends AbstractRequest {
  constructor(params) {
    super(params);
    this._token = AuthClientStore.getToken();
    this._requestInterceptorId = null;
  }

  get apiResource() {
    return 'auth';
  }

  get routes() {
    return {
      AUTHENTICATE: `${this.apiRoute}/login`,
      AUTHENTICATE_TECHNICIAN: `${this.apiRoute}/login-technician`,
      LOGOUT: `${this.apiRoute}/logout`,
      ME: `${this.apiUrl}/users/me`,
      CHANGE_PIN: `${this.apiUrl}/users/change-pincode`,
      CHANGE_PASSWORD: `${this.apiUrl}/users/change-password`,
    };
  }

  get token() {
    return this._token;
  }

  set token(token) {
    if (token) {
      AuthClientStore.setToken(token);
    } else {
      AuthClientStore.removeToken();
    }

    this._token = token;
  }

  removeTokenFromLocalStorage() {
    AuthClientStore.removeToken();
  }

  refreshToken() {
    // if token is set in memory, also set it in local storage
    // eslint-disable-next-line no-self-assign
    this.token = this.token;
  }

  async changePassword(email, oldPassword, newPassword) {
    const response = await this.request(
      API_METHODS.POST,
      this.routes.CHANGE_PASSWORD,
      {
        email,
        oldPassword,
        newPassword,
      }
    );

    return response.data;
  }

  async changePin(email, newPinCode) {
    const response = await this.request(
      API_METHODS.POST,
      this.routes.CHANGE_PIN,
      {
        email,
        newPinCode,
      }
    );

    return response.data;
  }

  setAuthorizationHeader() {
    if (!this.token) {
      return;
    }
    this._requestInterceptorId = this.addRequestInterceptor((config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${this.token}`;

      return config;
    });
  }

  unsetAuthorizationHeader() {
    this.removeRequestInterceptor(this._requestInterceptorId);
    this._requestInterceptorId = null;
  }

  async authenticate(userLoginData) {
    await this.login(userLoginData, this.routes.AUTHENTICATE);

    return {
      decodedToken: this.getDecodedJwt(),
      userPassword: userLoginData.password,
    };
  }

  async login(userLoginData, url) {
    const response = await this.request(API_METHODS.POST, url, userLoginData);

    this.token = response.data.access_token;

    this.setAuthorizationHeader();

    return response;
  }

  async logout() {
    if (!this.token) {
      return null;
    }

    // const response = await this.request('POST', this.routes.LOGOUT, {
    //   token: this.token,
    // });
    this.token = null;
    this.unsetAuthorizationHeader();

    return null;
  }

  async me() {
    this.setAuthorizationHeader();
    try {
      const result = await this.request('GET', this.routes.ME);

      return result.data;
    } catch (e) {
      this.unsetAuthorizationHeader();
      throw e;
    }
  }

  getDecodedJwt() {
    return jwtDecode(this.token);
  }
}

export const authRequest = new AuthRequest();
