export default {
  report: {
    itself: 'Raport',
    plural: {
      itself: 'Rapoarte',
    },
    metadata: {
      activityRegistryFullInfo: 'Registrul de activități Informații complete',
      dailyProceduresFullInfo: 'Proceduri zilnice Informații complete',
      procurementsFullInfo: 'Achiziții Informații complete',
      includeProcurementsFinanceInfo:
        'Includeți informații privind finanțarea achizițiilor',
      ticketsFullInfo: 'Bilete Informații complete',
      warrantyTrackerFullInfo: 'Garanție Tracker Informații complete',
      preventiveMaintenanceEventsFullInfo:
        'Evenimente de întreținere preventivă Informații complete',
    },
    attributes: {
      name: {
        itself: 'Nume',
      },
      format: {
        itself: 'Format',
      },
      data: {
        itself: 'Date',
      },
      created: {
        itself: 'Creată',
      },
    },
    note: {
      reportContentSection: {
        preventiveMaintenanceEvent: 'Eveniment de întreținere preventivă',
        dailyCheckEvent: 'Eveniment de verificare zilnică',
        ticket: 'Bilet',
        procurement: 'Achiziții',
        activityRegistry: 'Registrul de activitate',
        warrantyTracker: 'Tracker garanție',
      },
    },
    preventiveMaintenance: {
      itself: 'Întreținerea preventivă',
    },
    procurementOrder: {
      itself: 'Ordin de achiziție',
      plural: {
        itself: 'Ordine de achiziții',
      },
    },
    category: 'Categorie',
    group: 'Grup',
    equipment: 'Echipamente',
    recipient: 'Destinatar',
    generateReport: 'Generează raport',
    form: {
      name: {
        itself: 'Nume',
        '*': 'Nume *',
        required: 'Numele este obligatoriu',
      },
      startDate: {
        itself: 'Data de început',
        '*': 'Data de început *',
        required: 'Data de începere este obligatorie',
      },
      endDate: {
        itself: 'Data de încheiere',
        '*': 'Data de încheiere *',
        required: 'Data de încheiere este obligatorie',
      },
      format: {
        itself: 'Format',
        '*': 'Format *',
        required: 'Formatul este necesar',
      },
      category: {
        itself: 'Categorie',
        '*': 'Categorie *',
        required: 'Categoria este obligatorie',
      },
      group: {
        itself: 'Grup',
        '*': 'Grup *',
        required: 'Grupul este necesar',
      },
      equipment: {
        itself: 'Echipamente',
        '*': 'Echipamente *',
        required: 'Este necesar un echipament',
      },
      state: {
        itself: 'Stat',
        '*': 'Stat *',
        required: 'Statul este necesar',
      },
      recipient: {
        itself: 'Destinatar',
        '*': 'Destinatar *',
        required: 'Destinatarul este necesar',
      },
      location: {
        itself: 'Locație',
        '*': 'Locație *',
        required: 'Este necesară locația',
      },
      metadata: {
        itself: 'Metadate',
        '*': 'Metadate *',
        required: 'Sunt necesare metadatele',
      },
      exportDetailedReport: 'Exportați raportul detaliat',
      includeFinancialData: 'Includeți date financiare',
      preventiveMaintenance: 'Întreținerea preventivă',
      preventiveMaintenanceEvents: 'Evenimente de întreținere preventivă',
      dailyCheckEvents: 'Evenimente de verificare zilnică',
      correctiveMaintenance: 'Mentenanta corectiva',
    },
    downloadReport: 'Descărcați raportul',
    reportDownloadingError:
      'A apărut o eroare la generarea unui raport. Încercați mai târziu, vă rog',
  },
};
