import AbstractEventCatcher from '../AbstractEventCatcher';
import { procurementChatActionCreators } from '../../../store/chat/resource/actions';
import Alert from '../../../alerts/Alert';

export default class ProcurementChatRemoveParticipantEventCatcher extends AbstractEventCatcher {
  static process({ participantInfo, dispatch, loggedInUser }) {
    dispatch(
      procurementChatActionCreators.removeParticipant(
        participantInfo.procurementId,
        participantInfo.chatId,
        participantInfo.removedParticipant.id
      )
    );

    /** @dima todo temp solution */
    if (participantInfo.removedParticipant.userId === loggedInUser?.id) {
      Alert.showWarn(
        `Sorry, you were removed from the procurement chat with id ${participantInfo.procurementId}`
      );
    }
  }
}
