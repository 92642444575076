import {
  CREATE,
  DELETE,
  FIND,
  GET_BY_STD_CATEGORY_ID,
  UPDATE,
} from 'store/standard/procedure/constants';
import { STATUS } from 'utils/constants';
import { buildQueryKey, sortArrayByObjectProp } from 'utils/helpers';
import {
  addToDictionary,
  addToList,
  changeListStatus,
  hasPageSizeChanged,
  resetState,
} from 'store/helpers';

/**
 *
 * @type {{list: {default: {}, pagination: {total: number, pageNumber: null, pageSize: null}, meta: {currentKey: string}}, dictionary: {}}}
 */
const initialState = {
  dictionary: {},
  list: {
    meta: {
      currentKey: 'default',
    },
    pagination: {
      pageNumber: null,
      pageSize: null,
      total: 0,
    },
    default: {},
  },
};

export default function standardProcedureReducer(state = initialState, action) {
  let queryKey;
  let listStatus;
  let error;
  let id;
  let resourceModel;
  let resetToDefault;
  let pager;

  switch (action.type) {
    case GET_BY_STD_CATEGORY_ID.REQUEST:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;
      resetToDefault = action.payload.resetToDefault;

      if (
        hasPageSizeChanged(state.list.pagination.pageSize, pager?.pageSize) ||
        resetToDefault
      ) {
        return resetState(state, initialState, {
          queryKey,
          pager,
        });
      }

      if (
        [STATUS.RELOADING, STATUS.SUCCESS, STATUS.CACHED].includes(listStatus)
      ) {
        return changeListStatus(state, { pager, queryKey }, STATUS.RELOADING);
      }
      return changeListStatus(state, { pager, queryKey }, STATUS.LOADING);
    case GET_BY_STD_CATEGORY_ID.SUCCESS:
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
        list: addToList(state.list, action.payload),
      };
    case GET_BY_STD_CATEGORY_ID.ERROR:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      error = action.payload.error;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;

      return changeListStatus(
        state,
        { pager, queryKey, error },
        listStatus === STATUS.RELOADING ? STATUS.CACHED : STATUS.ERROR
      );

    case FIND.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case FIND.SUCCESS:
      const { resource } = action.payload;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [resource.id]: new resourceModel({
            ...state.dictionary[resource.id],
            ...resource,
            operations: sortArrayByObjectProp(
              resource.operations,
              'positionIndex'
            ),
            findFetched: true,
          }),
        },
      };
    case FIND.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case CREATE.REQUEST:
      return state;
    case CREATE.SUCCESS:
      return state;
    case CREATE.ERROR:
      return state;

    case UPDATE.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case UPDATE.SUCCESS:
      return state;
    case UPDATE.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case DELETE.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case DELETE.SUCCESS:
      id = action.payload.id;

      const newDictionary = { ...state.dictionary };
      delete newDictionary[id];
      return {
        ...state,
        dictionary: newDictionary,
      };
    case DELETE.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    default:
      return state;
  }
}
