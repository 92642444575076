import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function CrossIcon({ size, color, useSize = false, ...rest }) {
  return (
    <svg
      width={useSize ? size : 5}
      height={useSize ? size : 5}
      viewBox="0 0 5 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M2.5 2.5H5M2.5 2.5H0M2.5 2.5V0M2.5 2.5V5" stroke={color} />
    </svg>
  );
}

export default iconWrapper(CrossIcon);
