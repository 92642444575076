import { combineReducers } from 'redux';
import authReducer from './auth';
import userReducer from './user';
import roleReducer from './role';
import projectReducer from './project';
import trailReducer from './trail';
import realmReducer from './realm';
import countryReducer from './country';
import cityReducer from './city';
import manufacturerReducer from './manufacturer';
import unitReducer from './unit';
import standardEquipmentCategoryReducer from './equipment/standardEquipmentCategory';
import equipmentGroupReducer from './equipment/equipmentGroup';
import equipmentModelReducer from './equipment/equipmentModel';
import equipmentProjectCategoryReducer from './equipment/equipmentProjectCategory';
import equipmentReducer from './equipment/equipment';
import equipmentInputReducer from './equipment/equipmentInput';
import maintenanceProcedureReducer from './maintenanceProcedure/maintenanceProcedure';
import maintenanceOperationReducer from './maintenanceProcedure/maintenanceOperation';
import maintenanceParameterReducer from './maintenanceProcedure/maintenanceParameter';
import buildingReducer from './building/building';
import levelReducer from './building/level';
import roomReducer from './building/room';
import subcontractorReducer from './subcontractor';
import procedureReducer from './dailyCheck/dailyCheckProcedure';
import operationReducer from './dailyCheck/dailyCheckOperation';
import parameterReducer from './dailyCheck/dailyCheckParameter';
import subcontractorCategoryReducer from './subcontractorCategory';
import pmpEventReducer from './pmpEvent';
import { LOGOUT } from './auth/constants';
import ticketReducer from './ticket';
import procurementReducer from './procurement';
import generalChatReducer from './chat/general/index';
import resourceChatReducer from './chat/resource/index';
import notificationsReducer from './notifications';
import inventoryReducer from './inventory';
import standardProcedureReducer from './standard/procedure';
import dailyProcedureGroupReducer from './dailyProcedure/dailyProcedureGroup';
import pmpDcTourReducer from './pmpDc/tour';
import warrantyTrackerReducer from './warrantyTracker';
import announcementReducer from './announcement';
import projectBoardReducer from './projectBoard';
import activityReportReducer from './activityReport';
import reportsReducer from './reports';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  project: projectReducer,
  trail: trailReducer,
  realm: realmReducer,
  country: countryReducer,
  city: cityReducer,

  subcontractorCategory: subcontractorCategoryReducer,
  subcontractor: subcontractorReducer,

  unit: unitReducer,
  manufacturer: manufacturerReducer,
  equipment: equipmentReducer,
  standardEquipmentCategory: standardEquipmentCategoryReducer,
  equipmentProjectCategory: equipmentProjectCategoryReducer,
  equipmentGroup: equipmentGroupReducer,
  equipmentModel: equipmentModelReducer,
  equipmentInput: equipmentInputReducer,

  maintenanceProcedure: maintenanceProcedureReducer,
  maintenanceOperation: maintenanceOperationReducer,
  maintenanceParameter: maintenanceParameterReducer,

  building: buildingReducer,
  level: levelReducer,
  room: roomReducer,

  dailyCheckProcedure: procedureReducer,
  dailyCheckOperation: operationReducer,
  dailyCheckParameter: parameterReducer,

  pmpEvent: pmpEventReducer,
  pmpDcTour: pmpDcTourReducer,

  ticket: ticketReducer,
  procurement: procurementReducer,
  inventory: inventoryReducer,

  resourceChat: resourceChatReducer,
  generalChat: generalChatReducer,

  projectBoard: projectBoardReducer,

  notification: notificationsReducer,

  standardProcedure: standardProcedureReducer,

  dailyProcedureGroup: dailyProcedureGroupReducer,

  warrantyTracker: warrantyTrackerReducer,
  activityReport: activityReportReducer,

  announcement: announcementReducer,
  report: reportsReducer,
});

export default (state, action) =>
  rootReducer(action.type === LOGOUT.SUCCESS ? undefined : state, action);
