const PALETTE_COLORS = Object.freeze({
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
});

export const DEVICE_SIZES = Object.freeze({
  MOBILE_S: '320px',
  MOBILE_M: '375px',
  MOBILE_L: '425px',
  TABLET: '768px',
  LAPTOP: '1024px',
  LAPTOP_L: '1440px',
  DESKTOP: '2560px',
});

export const DEVICE_MEDIA = Object.freeze({
  MOBILE_S: `(max-width: ${DEVICE_SIZES.MOBILE_S})`,
  MOBILE_M: `(max-width: ${DEVICE_SIZES.MOBILE_M})`,
  MOBILE_L: `(max-width: ${DEVICE_SIZES.MOBILE_L})`,
  TABLET: `(max-width: ${DEVICE_SIZES.TABLET})`,
  LAPTOP: `(max-width: ${DEVICE_SIZES.LAPTOP})`,
  LAPTOP_L: `(max-width: ${DEVICE_SIZES.LAPTOP_L})`,
  DESKTOP: `(max-width: ${DEVICE_SIZES.DESKTOP})`,
});

export default PALETTE_COLORS;
