export const GET = Object.freeze({
  REQUEST: 'manufacturer/GET_REQUEST',
  SUCCESS: 'manufacturer/GET_SUCCESS',
  ERROR: 'manufacturer/GET_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'manufacturer/FIND_REQUEST',
  SUCCESS: 'manufacturer/FIND_SUCCESS',
  ERROR: 'manufacturer/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'manufacturer/CREATE_REQUEST',
  SUCCESS: 'manufacturer/CREATE_SUCCESS',
  ERROR: 'manufacturer/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'manufacturer/UPDATE_REQUEST',
  SUCCESS: 'manufacturer/UPDATE_SUCCESS',
  ERROR: 'manufacturer/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'manufacturer/DELETE_REQUEST',
  SUCCESS: 'manufacturer/DELETE_SUCCESS',
  ERROR: 'manufacturer/DELETE_ERROR',
});

export const GET_MANUFACTURERS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_MANUFACTURER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_MANUFACTURER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_MANUFACTURER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_MANUFACTURER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});
