import AbstractModel from '../../api/models/AbstractModel';
import ChatParticipantModel from './ChatParticipantModel';
import ChatRoleModel from './ChatRoleModel';
import GeneralChatHistoryMessageModel from './GeneralChatHistoryMessageModel';

class ChatModel extends AbstractModel {
  get isGroupChat() {
    return this.chatType === ChatModel.CHAT_TYPE.GROUP_CHAT;
  }

  get userNames() {
    return this._userNames;
  }

  get lastMessageParticipant() {
    return new ChatParticipantModel(this.lastMessage?.user);
  }

  get lastMessageString() {
    if (this.lastMessage?.message) {
      return this.lastMessage?.message;
    }

    if (this.lastMessage?.files?.length) {
      return this.getMessageByFiles(this.lastMessage.files);
    }

    return '';
  }

  get isLastMessageSystem() {
    if (this.lastMessage?.type) {
      return [
        ...Object.values(GeneralChatHistoryMessageModel.SYSTEM_MESSAGE_TYPES),
      ].includes(this.lastMessage.type);
    }

    return false;
  }

  get lastMessageDate() {
    return this.lastMessage?.createdAt || this.createdAt || null;
  }

  constructor(...params) {
    super(...params);

    this._filterChatParticipants();

    this._setUserNames();

    this._setMultipleChannelFields();

    this.unreadMessagesCount = this.unreadMessagesIds?.length;
  }

  convertAttribute(attribute) {
    switch (attribute) {
      case 'id': {
        return 'channelId';
      }

      case 'type': {
        return 'chatType';
      }

      default:
        return super.convertAttribute(attribute);
    }
  }

  _setMultipleChannelFields() {
    if (!this.isGroupChat) {
      const conversationParticipant = this.conversationUsers?.[0];

      this.communicatorAvatarUrl = conversationParticipant?.user?.avatarImgUrl;
      this.communicatorRole = conversationParticipant?.user?.role;
      this.communicatorChatRole = conversationParticipant?.role;
    }
  }

  _setUserNames() {
    const userNames = this.conversationUsers.map((participant) =>
      participant.user.isSuspended
        ? `${participant.user.fullName} (Deactivated)`
        : participant.user.fullName
    );

    this._userNames = this.isGroupChat ? ['You', ...userNames] : userNames;
  }

  _filterChatParticipants() {
    this._chatParticipants = (this._chatParticipants || []).map(
      (participant) => new ChatParticipantModel(participant)
    );

    this.chatOwner =
      this._chatParticipants.find((chatParticipant) => {
        return chatParticipant.role === ChatRoleModel.ROLES.OWNER;
      }) || null;

    this.conversationUsers = this._chatParticipants.filter(
      (participant) => participant.user.id !== this.loggedInUserId
    );
  }

  getMessageByFiles = (files) => {
    return files.map((file) => file.originalName).join(', ');
  };

  static PROJECT_CHAT_TYPE = Object.freeze({
    BENEFICIARY_CHAT: 'beneficiaryChannel',
    MANAGEMENT_CHAT: 'managementChannel',
    STAFF_CHAT: 'staffChannel',
  });

  static CHAT_TYPE = Object.freeze({
    GROUP_CHAT: 'groupChat',
    PRIVATE_CHAT: 'privateChat',
    ...ChatModel.PROJECT_CHAT_TYPE,
    ...ChatModel.RESOURCE_CHAT_TYPE,
  });

  static RESOURCE_CHAT_TYPE = Object.freeze({
    EXTERNAL: 'external',
    INTERNAL: 'internal',
  });
}

export default ChatModel;
