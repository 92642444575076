import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';
import createChatChannelItemModel from '../../../../store/chat/helpers/createChatChannelItemModel';

export default class DirectMessagesAddParticipantToChannelEventCatcher extends AbstractEventCatcher {
  static process({ chatId, newParticipant, chat }, dispatch, loggedInUser) {
    dispatch(
      generalChatMessagesConversationActionCreators.addParticipantToConversation(
        chatId,
        newParticipant
      )
    );

    if (newParticipant.user.id === loggedInUser?.id && chat) {
      dispatch(
        generalChatMessagesConversationActionCreators.updateConversationInfo(
          chat.id,
          createChatChannelItemModel(chat, loggedInUser.id)
        )
      );
    }
  }
}
