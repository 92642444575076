import AbstractDataAdapter from './AbstractDataAdapter';

class UserDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get createAllowedFields() {
    return [
      'email',
      'firstName',
      'lastName',
      'roleNames',
      'password',
      'pinCode',
      'addressOne',
      'addressTwo',
      'postalCode',
      'phoneNumber',
      'avatarImageId',
      'startDate',
      'countryId',
      'cityId',
      'organizationId',
      'sendEmails',
    ];
  }

  /**
   *
   * @returns {string[]}
   */
  static get createRequiredFields() {
    return ['email', 'firstName', 'lastName', 'roleNames', 'organizationId'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get updateAllowedFields() {
    return [
      'email',
      'firstName',
      'lastName',
      'roleNames',
      'password',
      'addressOne',
      'addressTwo',
      'postalCode',
      'phoneNumber',
      'avatarImageId',
      'startDate',
      'countryId',
      'cityId',
      'organizationId',
      'sendEmails',
    ];
  }

  /**
   *
   * @returns {string[]}
   */
  static get updateRequiredFields() {
    return [];
  }
}

export default UserDataAdapter;
