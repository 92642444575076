export default {
  chat: {
    itself: 'Conversație',
    plural: {
      itself: 'Chaturi',
    },
    noMessagesYet: 'Niciun mesaj încă',
    newMessages: 'Mesaje noi',
    selectConversation: 'Selectați mai întâi o conversație',
    channels: 'Canale',
    groupChatWith: 'Chat de grup cu',
    latestMessages: 'Ultimele mesaje',
    startMessaging: 'Începeți să trimiteți mesaje',
    messages: 'Mesaje',
    projectChat: 'Chat de proiect',
    typeSomething: 'Scrie ceva ...',
    backToChannels: 'Înapoi la canale',
    viewChat: 'Vizualiza chat',
  },
};
