import AbstractEventCatcher from '../AbstractEventCatcher';
import { procurementChatActionCreators } from '../../../store/chat/resource/actions';

export default class ProcurementChatUnreadMessagesUpdated extends AbstractEventCatcher {
  static process({ unreadMessageInfo, dispatch }) {
    dispatch(
      procurementChatActionCreators.updateUnreadMessages(
        unreadMessageInfo.procurementId,
        unreadMessageInfo.chatId,
        unreadMessageInfo.unreadMessagesIds
      )
    );

    dispatch(
      procurementChatActionCreators.updateLastReadMessageId(
        unreadMessageInfo.procurementId,
        unreadMessageInfo.chatId,
        unreadMessageInfo.lastReadMessage?.id
      )
    );

    if (unreadMessageInfo.lastReadMessage) {
      dispatch(
        procurementChatActionCreators.addMessage(
          unreadMessageInfo.procurementId,
          unreadMessageInfo.chatId,
          unreadMessageInfo.lastReadMessage
        )
      );
    }

    dispatch(
      procurementChatActionCreators.setHistoryLastReadMessage(
        unreadMessageInfo.procurementId,
        unreadMessageInfo.chatId
      )
    );
  }
}
