import styled from 'styled-components';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  background: ${({ theme }) => theme.palette.gray[100]};
  border-radius: 6px;
  padding: 4px 8px 4px 4px;
  font-weight: 500;
  cursor: pointer;
`;

const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

export default function UserTag({ avatarImgUrl, name, onClick }) {
  return (
    <Wrapper onClick={onClick}>
      <StyledAvatar alt={name} src={avatarImgUrl} />
      {name}
    </Wrapper>
  );
}

UserTag.propTypes = {
  avatarImgUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

UserTag.defaultProps = {
  onClick: () => {},
  avatarImgUrl: '',
};
