import AbstractModel from './AbstractModel';
import UserModel from './UserModel';

class ProjectBoardItemModel extends AbstractModel {
  constructor(...params) {
    super(...params);

    this.creatorModel = new UserModel(this.creator || {});

    const item = params.at(0) || {};

    this.taskStatus = item.status;
    this.taskType = item.type;
  }

  static STATUSES = Object.freeze({
    TODO: 'toDo',
    COMPLETED: 'completed',
  });

  static TYPES = Object.freeze({
    PERSONAL: 'personal',
    TEAM: 'team',
  });
}

export default ProjectBoardItemModel;
