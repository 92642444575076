import ApiResourceRequest from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import ListingResponse from '../ListingResponse';
import Pager from '../../helpers/Pager';

export class StandardEquipmentCategoryRequest extends ApiResourceRequest {
  get apiResource() {
    return 'standard/categories';
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_ORGANIZATION_ID: `${this.apiRoute}/by-organization-id/{organizationId}`,
    };
  }

  async getByOrganizationId(organizationId, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ organizationId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_ORGANIZATION_ID.replace(
        '{organizationId}',
        organizationId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }
}

export const standardEquipmentCategoryRequest = new StandardEquipmentCategoryRequest();
