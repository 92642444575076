export default {
  general: {
    mobilePhoneNumber: 'Mobile phone number',
    uploadImage: 'Upload image',
    cancel: 'Cancel',
    save: 'Save',
    download: 'Download',
    delete: 'Delete',
    remove: 'Remove',
    edit: 'Edit',
    viewDetails: 'View Details',
    no: 'No',
    yes: 'Yes',
    by: 'By {{closedBy}}',
    accept: 'Accept',
    reject: 'Reject',
    disable: 'Disable',
    enable: 'Enable',
    duplicate: 'Duplicate',

    created: 'Created',
    updated: 'Updated',

    online: 'Connection is restored.',
    offline:
      'You are disconnected. You will not be able to save changes until your connection is restored.',

    youHaveUnsavedChanges: 'You have unsaved changes',
    discard: 'Discard',
    saveChanges: 'Save changes',
    savingIsInProgress: 'Saving is in progress',
    myProfile: 'My profile',
    editMyProfile: 'Edit my profile',
    viewAll: 'View all',
    uploadFile: 'Upload file',
    submitFeedback: 'Submit feedback',
    rateExperience: 'Please rate your experience',
    rejectionReason: 'Please submit a reason for the rejection',

    summary: 'Summary',
    viewQr: 'View QR',

    itemCanNotBeSet: `{{item}} can't be set`,

    backToList: 'Back to list',

    errors: {
      endDateShouldBeGreaterThanStart:
        'End date should be greater than start date',
      startDateShouldBeGreaterThanToday:
        'Start date should be greater than today',
      startDateRequiredIfEndDate:
        'Start date is required if end date is present',
    },

    subject: 'Subject',
    date: 'Date',
    startDate: 'Start date',
    endDate: 'End date',
    dueDate: 'Due date',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    creator: 'Creator',
    user: 'User',
    status: 'Status',

    form: {
      address: {
        itself: 'Address',
        '*': 'Address *',
        required: 'Address is required',
      },
      city: {
        itself: 'City',
        '*': 'City *',
        required: 'City is required',
      },
      state: {
        itself: 'State',
        '*': 'State *',
        required: 'State is required',
      },
      addressLineOne: {
        itself: 'Address line 1',
        '*': 'Address line 1 *',
        required: 'Address line 1 is required',
      },
      addressLineTwo: {
        itself: 'Address line 2 (optional)',
      },
      emailAddress: {
        itself: 'Email address',
        '*': 'Email address *',
        required: 'Email address is required',
      },
      phone: {
        itself: 'Phone',
        '*': 'Phone *',
        required: 'Phone is required',
      },
      profilePhoto: {
        itself: 'Profile photo',
        '*': 'Profile photo *',
        required: 'Profile photo is required',
      },
      postCode: {
        itself: 'Post code',
        '*': 'Post code *',
        required: 'Post code is required',
      },
      country: {
        itself: 'Country',
        '*': 'Country *',
        required: 'Country is required',
      },
      name: {
        itself: 'Name',
        '*': 'Name *',
        required: 'Name is required',
      },
      firstName: {
        itself: 'First',
        '*': 'First *',
        required: 'First is required',
      },
      lastName: {
        itself: 'Last',
        '*': 'Last *',
        required: 'Last is required',
      },
      email: {
        itself: 'Email',
        '*': 'Email *',
        required: 'Email is required',
        plural: {
          itself: 'Emails',
          '*': 'Emails *',
          required: 'Emails are required',
        },
        addAnother: 'Add another email',
      },
      summary: {
        itself: 'Summary',
        '*': 'Summary *',
        required: 'Summary is required',
      },
      subject: {
        itself: 'Subject',
        '*': 'Subject *',
        required: 'Subject is required',
      },
      user: {
        itself: 'User',
        '*': 'User *',
        required: 'User is required',
      },
      date: {
        itself: 'Date',
        '*': 'Date *',
        required: 'Date is required',
      },
      description: {
        '*': 'Description *',
        itself: 'Description',
        required: 'Description is required',
      },
      type: {
        '*': 'Type *',
        itself: 'Type',
        required: 'Type is required',
        plural: 'Types',
      },
      dueDate: {
        '*': 'Due date *',
        itself: 'Due date',
        required: 'Due date is required',
      },
      startDate: 'Start date',
      endDate: 'End date',
    },
    settings: 'Settings',
    started: 'Started',
    statusChangedTo: 'Status was changed to {{status}}',
    all: 'All',
    viewLog: 'View logs',
    noItemsFound: 'No items found',
    location: 'Location',
    addNew: 'Add new',
    description: 'Description',

    export: 'Export',
    search: 'Search',

    active: 'Active',
    inactive: 'Inactive',

    loadMore: 'Load More',

    filters: {
      locations: 'Locations',
      frequency: 'Frequency',
      equipmentCategory: 'Equipment category',
      inactiveEvents: 'Inactive events',
      disabledEvents: 'Disabled events',
      type: 'Type',
    },
    of: ' of {{total}}',
    printQr: 'Print QR',
    never: 'Never',
    rows: 'Rows',
    noLocationSet: 'No location set',
    conversations: 'Conversations',
    external: 'External',
    internal: 'Internal',
    option: 'option',
    opened: 'Opened',
    organizations: 'Organizations',
    today: 'Today',
    yesterday: 'Yesterday',
    last7Days: 'Last 7 days',
    due: 'Due',
    tomorrow: 'Tomorrow',
    interval: 'Interval',
    equipments: 'Equipment',
    frequency: {
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      semiannually: 'Semiannually',
      annually: 'Annually',
      weekly: 'Weekly',
    },
    editDetails: 'Edit details',
    email: 'E-mail',
    address: 'Address',
    entries: '{{count}} entries',
    log: 'Log',
    recent: 'Recent',
    selectParticipants: 'Select participants',
    uploadImages: 'Upload images',
    notifications: 'Notifications',
    logout: 'Logout',
    dismissAll: 'Dismiss all',
    noNotifications: 'There are no notifications yet',
    type: {
      hard: 'Hard',
      soft: 'Soft',
    },
    openedBy: 'Opened by',
    notSet: 'Not set',
    addTeamMember: 'Add team member',
    first: 'First',
    last: 'Last',
    receiveEmailNotifications: 'Receive e-mail notifications',

    filter: 'Filter',
    showArchivedEvents: 'Show archived events',
    showDraftEvents: 'Show draft events',

    primaryImage: 'Primary image',
    weatherData: 'Weather data',
  },
};
