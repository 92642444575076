const getLastReadMessageId = (lastReadMessage, action) => {
  let lastReadMessageId;

  if (!action.payload.reset) {
    lastReadMessageId = lastReadMessage.id;
  }

  return lastReadMessageId;
};

export default getLastReadMessageId;
