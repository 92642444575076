import NotificationModel from 'api/models/NotificationModel';
import {
  projectBoardItemIdPattern,
  projectIdPattern,
} from 'routes/replacePatterns';
import PATH from 'routes/paths';
import ProjectBoardNotificationsEventCatcher from 'realTimeApi/eventCatchers/projectBoard/ProjectBoardNotificationsEventCatcher';
import ProjectBoardItemModel from 'api/models/ProjectBoardItemModel';
import DateFormats from 'utils/dates';

/* eslint-disable no-param-reassign */

const setProjectBoardNotificationMissingFields = (notificationModel) => {
  const {
    projectBoardSubject,
    type,
    projectId,
    projectBoardId,
    statusNotification,
    remindDate,
  } = notificationModel.metadata;

  if (
    ![projectBoardSubject, projectId, projectBoardId, statusNotification].every(
      (value) => !!value
    )
  ) {
    return notificationModel;
  }

  // eslint-disable-next-line default-case
  switch (statusNotification) {
    case ProjectBoardNotificationsEventCatcher.PROJECT_BOARD_TYPES
      .ITEM_CREATED: {
      notificationModel.notificationUiType =
        NotificationModel.NOTIFICATION_TYPES.CREATE;

      notificationModel.description = projectBoardSubject;
      notificationModel.name = {
        text: `project.dashboard.projectBoard.types.${
          type || ProjectBoardItemModel.TYPES.PERSONAL
        }`,
        withTranslate: true,
      };

      break;
    }

    case ProjectBoardNotificationsEventCatcher.PROJECT_BOARD_TYPES
      .REMIND_DATE: {
      notificationModel.notificationUiType =
        NotificationModel.NOTIFICATION_TYPES.CUSTOM;
      notificationModel.customTypeTitle = {
        text: DateFormats.validateAndFormat(
          new Date(remindDate),
          DateFormats.FORMATS.HOURS_AND_MINUTES_24
        ),
      };

      notificationModel.description = projectBoardSubject;
      notificationModel.name = {
        text: 'today',
        withTranslate: true,
      };

      break;
    }
  }

  notificationModel.resourceMetaData = {
    resourceId: projectBoardId,
    resourceType: 'projectBoard',
    path: PATH.PROJECT.PROJECT_BOARD.ONE.replace(
      projectIdPattern,
      projectId
    ).replace(projectBoardItemIdPattern, projectBoardId),
  };

  return notificationModel;
};

export default setProjectBoardNotificationMissingFields;
