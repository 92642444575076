import {
  channelIdPattern,
  conversationIdPattern,
  equipmentIdPattern,
  maintenancePlanEventIdPattern,
  organizationIdPattern,
  procurementIdPattern,
  projectIdPattern,
  subcontractorIdPattern,
  ticketIdPattern,
  userIdPattern,
  inventoryIdPattern,
  standardProcedureIdPattern,
  dailyProcedureGroupIdPattern,
  dailyCheckTourIdPattern,
  dailyCheckTourEventIdPattern,
  warrantyTrackerIdPattern,
  procedureIdPattern,
  announcementIdPattern,
  projectBoardItemIdPattern,
  activityReportIdPattern,
  reportIdPattern,
  unitIdPattern,
  dailyCheckProcedureIdPattern,
  buildingIdPattern,
  levelIdPattern,
} from '../replacePatterns';

export const CHAT_PATH = '/chat';
export const CHAT_CONVERSATION_TAB = `${CHAT_PATH}/conversations`;
export const EQUIPMENT_SETTINGS_ENDPOINT = '/settings/procedures';
export const MAINTENANCE_PLAN_ENDPOINT = '/maintenance-plan';

const PATH = Object.freeze({
  DASHBOARD: '/',
  AUTH: {
    LOGIN: '/login',
    CREATE_PASSWORD: '/create-password',
  },
  PROJECT: {
    ONE: `/projects/${projectIdPattern}`,
    LIST: '/projects',
    DRAFTS: `/projects/drafts`,
    ARCHIVED: '/projects/archived',
    CREATE: '/projects/new',
    INFO: {
      ONE: `/projects/${projectIdPattern}/info`,
      TENANT: `/projects/${projectIdPattern}/info/tenants/${userIdPattern}`,
    },
    UPDATE: `/projects/${projectIdPattern}/edit`,
    PROJECT_BOARD: {
      CREATE: `/projects/${projectIdPattern}/project-board/new`,
      UPDATE: `/projects/${projectIdPattern}/project-board/${projectBoardItemIdPattern}/edit`,
      LIST: `/projects/${projectIdPattern}/project-board`,
      ONE: `/projects/${projectIdPattern}/project-board/${projectBoardItemIdPattern}`,
    },
    EQUIPMENT: {
      LIST: `/projects/${projectIdPattern}/equipments`,
      EXPORT: `/projects/${projectIdPattern}/equipments/export`,
      ONE: `/projects/${projectIdPattern}/equipments/${equipmentIdPattern}`,
      CREATE: `/projects/${projectIdPattern}/equipments/new`,
      UPDATE: `/projects/${projectIdPattern}/equipments/${equipmentIdPattern}/edit`,
      PROCEDURES: {
        LIST: `/projects/${projectIdPattern}/equipments/${equipmentIdPattern}${EQUIPMENT_SETTINGS_ENDPOINT}`,
        UPDATE: `/projects/${projectIdPattern}/equipments/${equipmentIdPattern}${EQUIPMENT_SETTINGS_ENDPOINT}/${procedureIdPattern}/edit`,
        CREATE: `/projects/${projectIdPattern}/equipments/${equipmentIdPattern}${EQUIPMENT_SETTINGS_ENDPOINT}/new`,
        UPDATE_EQUIPMENT: `/projects/${projectIdPattern}/equipments/${equipmentIdPattern}${EQUIPMENT_SETTINGS_ENDPOINT}/edit-equipment`,
      },
      MAINTENANCE_PLAN: {
        ONE: `/projects/${projectIdPattern}/equipments/${equipmentIdPattern}${MAINTENANCE_PLAN_ENDPOINT}/${maintenancePlanEventIdPattern}`,
        UPDATE_EQUIPMENT: `/projects/${projectIdPattern}/equipments/${equipmentIdPattern}${MAINTENANCE_PLAN_ENDPOINT}/edit-equipment`,
      },
    },
    MAINTENANCE_PLAN: {
      ONE: `/projects/${projectIdPattern}${MAINTENANCE_PLAN_ENDPOINT}/${maintenancePlanEventIdPattern}`,
      INFO: `/projects/${projectIdPattern}/maintenance-plan-events/${maintenancePlanEventIdPattern}`,
      LIST: `/projects/${projectIdPattern}${MAINTENANCE_PLAN_ENDPOINT}`,
      EXPORT: `/projects/${projectIdPattern}${MAINTENANCE_PLAN_ENDPOINT}/export`,
    },
    TICKET: {
      ONE: `/projects/${projectIdPattern}/tickets/${ticketIdPattern}`,
      LIST: `/projects/${projectIdPattern}/tickets`,
      CREATE: `/projects/${projectIdPattern}/tickets/new`,
    },
    PROCUREMENT: {
      ONE: `/projects/${projectIdPattern}/procurements/${procurementIdPattern}`,
      LIST: `/projects/${projectIdPattern}/procurements`,
      CREATE: `/projects/${projectIdPattern}/procurements/new`,
      USER_VIEW: `/projects/${projectIdPattern}/procurements/${procurementIdPattern}/user/${userIdPattern}`,
    },
    SUBCONTRACTOR: {
      ONE: `/projects/${projectIdPattern}/subcontractors/${subcontractorIdPattern}`,
      LIST: `/projects/${projectIdPattern}/subcontractors`,
    },
    USER: {
      TEAM_MEMBER: {
        LIST: `/projects/${projectIdPattern}/team-members`,
        ONE: `/projects/${projectIdPattern}/team-members/${userIdPattern}`,
      },
      TENANT: {
        LIST: `/projects/${projectIdPattern}/tenants`,
        ONE: `/projects/${projectIdPattern}/tenants/${userIdPattern}`,
        ORGANISATION: {
          ONE: `/projects/${projectIdPattern}/tenants/organization/${organizationIdPattern}`,
        },
      },
      BENEFICIARY: {
        LIST: `/projects/${projectIdPattern}/beneficiary`,
        ONE: `/projects/${projectIdPattern}/beneficiary/${userIdPattern}`,
      },
    },
    LAYOUT: {
      SHOW: `/projects/${projectIdPattern}/layout`,
      BUILDING: {
        CREATE: `/projects/${projectIdPattern}/layout/buildings/new`,
        UPDATE: `/projects/${projectIdPattern}/layout/buildings/${buildingIdPattern}/edit`,
        LEVEL: {
          CREATE: `/projects/${projectIdPattern}/layout/buildings/${buildingIdPattern}/levels/new`,
          UPDATE: `/projects/${projectIdPattern}/layout/buildings/${buildingIdPattern}/levels/${levelIdPattern}/edit`,
        },
      },
    },
    SETTINGS: {
      SHOW: `/projects/${projectIdPattern}/settings`,
    },
    CHAT: {
      CONVERSATION: {
        LIST: `/projects/${projectIdPattern}/chat/conversations`,
        ONE: `/projects/${projectIdPattern}/chat/conversations/${conversationIdPattern}`,
      },
      CHANNEL: {
        LIST: `/projects/${projectIdPattern}/chat/channels`,
        ONE: `/projects/${projectIdPattern}/chat/channels/${channelIdPattern}`,
      },
    },
    INVENTORY: {
      ONE: `/projects/${projectIdPattern}/inventory/${inventoryIdPattern}`,
      LIST: `/projects/${projectIdPattern}/inventory`,
      CREATE: `/projects/${projectIdPattern}/inventory/new`,
      UPDATE: `/projects/${projectIdPattern}/inventory/${inventoryIdPattern}/edit`,
    },
    WARRANTY_TRACKER: {
      ONE: `/projects/${projectIdPattern}/warranty/${warrantyTrackerIdPattern}`,
      LIST: `/projects/${projectIdPattern}/warranty`,
      EXPORT: `/projects/${projectIdPattern}/warranty/export`,
      CREATE: `/projects/${projectIdPattern}/warranty/new`,
      UPDATE: `/projects/${projectIdPattern}/warranty/${warrantyTrackerIdPattern}/edit`,
    },
    DAILY_CHECK: {
      TOUR: {
        LIST: `/projects/${projectIdPattern}/daily-check`,
        ONE: `/projects/${projectIdPattern}/daily-check/${dailyCheckTourIdPattern}`,
        GROUP: {
          ONE: `/projects/${projectIdPattern}/daily-check/${dailyCheckTourIdPattern}/group/${dailyProcedureGroupIdPattern}`,
          EVENT: {
            ONE: `/projects/${projectIdPattern}/daily-check/${dailyCheckTourIdPattern}/group/${dailyProcedureGroupIdPattern}/event/${dailyCheckTourEventIdPattern}`,
          },
        },
      },
      SETTINGS: {
        GROUP: {
          CREATE: `/projects/${projectIdPattern}/daily-check/settings/procedure-groups/new`,
          ONE: `/projects/${projectIdPattern}/daily-check/settings/procedure-groups/${dailyProcedureGroupIdPattern}`,
          LIST: `/projects/${projectIdPattern}/daily-check/settings/procedure-groups`,
          UPDATE: `/projects/${projectIdPattern}/daily-check/settings/procedure-groups/${dailyProcedureGroupIdPattern}/edit`,
          PROCEDURE: {
            CREATE: `/projects/${projectIdPattern}/daily-check/settings/procedure-groups/${dailyProcedureGroupIdPattern}/procedure/new`,
            UPDATE: `/projects/${projectIdPattern}/daily-check/settings/procedure-groups/${dailyProcedureGroupIdPattern}/procedure/${dailyCheckProcedureIdPattern}/edit`,
          },
        },
      },
    },
    ANNOUNCEMENTS: {
      LIST: `/projects/${projectIdPattern}/announcements`,
      ONE: `/projects/${projectIdPattern}/announcements/${announcementIdPattern}`,
    },
    ACTIVITY_REPORT: {
      LIST: `/projects/${projectIdPattern}/activity-registry`,
      ONE: `/projects/${projectIdPattern}/activity-registry/${activityReportIdPattern}`,
      UPDATE: `/projects/${projectIdPattern}/activity-registry/${activityReportIdPattern}/edit`,
      CREATE: `/projects/${projectIdPattern}/activity-registry/new`,
    },
    REPORT: {
      LIST: `/projects/${projectIdPattern}/reports`,
      ONE: `/projects/${projectIdPattern}/reports/${reportIdPattern}`,
      CREATE: `/projects/${projectIdPattern}/reports/new`,
    },
  },
  USER: {
    ONE: `/users/${userIdPattern}`,
    LIST: '/users',
    CREATE: '/users/new',
    UPDATE: `/users/${userIdPattern}/edit`,
  },
  UNITS: {
    UPDATE: `/units/${unitIdPattern}/edit`,
    LIST: `/units`,
    CREATE: `/units/new`,
  },
  ORGANIZATION: {
    ONE: `/organization-profile/${organizationIdPattern}`,
    LIST: '/organizations',
    CREATE: '/organizations/new',
    UPDATE: `/organization-profile/${organizationIdPattern}/edit`,
    USER: {
      ONE: `/organization-profile/${organizationIdPattern}/user/${userIdPattern}`,
      CREATE: `/organization-profile/${organizationIdPattern}/user/new`,
      UPDATE: `/organization-profile/${organizationIdPattern}/user/${userIdPattern}/edit`,
    },
  },
  SUBCONTRACTOR: {
    ONE: `/subcontractors/${subcontractorIdPattern}`,
    LIST: '/subcontractors',
    CREATE: '/subcontractors/new',
    UPDATE: `/subcontractors/${subcontractorIdPattern}/edit`,
  },
  MY_PROFILE: {
    SHOW: '/my-profile',
    EDIT: '/edit-my-profile',
  },
  CHAT: {
    CONVERSATION: {
      LIST: `/chat/conversations`,
      ONE: `/chat/conversations/${conversationIdPattern}`,
    },
    PROJECT_CHANNEL: {
      LIST: `/chat/project-channels`,
      ONE: `/chat/project-channels/${channelIdPattern}`,
    },
  },
  STANDARD: {
    PROCEDURE: {
      LIST: '/standard/procedures',
      CREATE: '/standard/procedures/new',
      UPDATE: `/standard/procedures/${standardProcedureIdPattern}/edit`,
    },
  },
  ANNOUNCEMENT: {
    LIST: '/announcements',
    CREATE: '/announcements/new',
    ONE: `/announcements/${announcementIdPattern}`,
    UPDATE: `/announcements/${announcementIdPattern}/edit`,
  },
});
export default PATH;
