import ChatHistoryMessageModel from './ChatHistoryMessageModel';
import ProcurementModel from '../../api/models/ProcurementModel';

export default class ProcurementChatHistoryMessageModel extends ChatHistoryMessageModel {
  static TYPES = Object.freeze({
    USER_NEW_TICKET: 'userNewTicket',
    USER: 'user',
    PRIORITY_CHANGED: 'priorityChanged',

    PROCUREMENT_OPENED: 'procurementOpened',
    PROCUREMENT_PROPOSAL_SUBMITTED: 'procurementProposalSubmitted',
    PROCUREMENT_PROPOSAL_CANCELED: 'procurementProposalCanceled',
    PROCUREMENT_PROPOSAL_ACCEPTED: 'procurementProposalAccepted',
    PROCUREMENT_PROPOSAL_REJECTED: 'procurementProposalRejected',
    PROCUREMENT_WORK_IN_PROGRESS: 'procurementWorkStarted',
    PROCUREMENT_WORK_FINISHED: 'procurementWorkFinished',
    PROCUREMENT_WORK_ACCEPTED: 'procurementWorkAccepted',
    PROCUREMENT_WORK_REJECTED: 'procurementWorkRejected',
    PROCUREMENT_CLOSED: 'procurementClosed',
    SYSTEM_PROCUREMENT_CLOSED: 'systemProcurementClosed',
    SYSTEM_PROCUREMENT_LINKED_TO_TICKED_ID: 'procurementLinkedToTicketId',
    SYSTEM_PROCUREMENT_UNLINKED_FROM_TICKET_ID:
      'procurementUnlinkedFromTicketId',

    SYSTEM_TICKET_CLOSED: 'systemTicketClosed',
    SYSTEM_USER_JOINED_CHAT: 'systemUserJoinedChat',
    SYSTEM_USER_LEFT_CHAT: 'systemUserLeftChat',
    SYSTEM_MESSAGE_DELETED: 'systemMessageDeleted',
  });

  static getIsSystemMessage = (message) => {
    return [
      this.TYPES.SYSTEM_TICKET_CLOSED,
      this.TYPES.SYSTEM_USER_JOINED_CHAT,
      this.TYPES.SYSTEM_USER_LEFT_CHAT,
      this.TYPES.SYSTEM_PROCUREMENT_LINKED_TO_TICKED_ID,
      this.TYPES.SYSTEM_PROCUREMENT_UNLINKED_FROM_TICKET_ID,
    ].includes(message.type);
  };

  static getIsStatusChangeMessage = (message) => {
    return [
      this.TYPES.PROCUREMENT_OPENED,
      this.TYPES.PROCUREMENT_PROPOSAL_SUBMITTED,
      this.TYPES.PROCUREMENT_PROPOSAL_CANCELED,
      this.TYPES.PROCUREMENT_PROPOSAL_ACCEPTED,
      this.TYPES.PROCUREMENT_PROPOSAL_REJECTED,
      this.TYPES.PROCUREMENT_WORK_IN_PROGRESS,
      this.TYPES.PROCUREMENT_WORK_FINISHED,
      this.TYPES.PROCUREMENT_WORK_ACCEPTED,
      this.TYPES.PROCUREMENT_WORK_REJECTED,
      this.TYPES.PROCUREMENT_CLOSED,
    ].includes(message.type);
  };

  static getIsTicketLinkingMessage = (message) => {
    return [
      this.TYPES.SYSTEM_PROCUREMENT_LINKED_TO_TICKED_ID,
      this.TYPES.SYSTEM_PROCUREMENT_UNLINKED_FROM_TICKET_ID,
    ].includes(message.type);
  };

  static TYPES_JOINED_WITH_STATUSES = Object.freeze({
    [this.TYPES.PROCUREMENT_OPENED]: ProcurementModel.STATUSES.OPENED,
    [this.TYPES.PROCUREMENT_PROPOSAL_SUBMITTED]:
      ProcurementModel.STATUSES.PROPOSAL_SUBMITTED,
    [this.TYPES.PROCUREMENT_PROPOSAL_CANCELED]:
      ProcurementModel.STATUSES.OPENED,
    [this.TYPES.PROCUREMENT_PROPOSAL_ACCEPTED]:
      ProcurementModel.STATUSES.ACCEPTED,
    [this.TYPES.PROCUREMENT_PROPOSAL_REJECTED]:
      ProcurementModel.STATUSES.REJECTED,
    [this.TYPES.PROCUREMENT_WORK_IN_PROGRESS]:
      ProcurementModel.STATUSES.WORK_IN_PROGRESS,
    [this.TYPES.PROCUREMENT_WORK_FINISHED]:
      ProcurementModel.STATUSES.WORK_FINISHED,
    [this.TYPES.PROCUREMENT_WORK_ACCEPTED]: ProcurementModel.STATUSES.CLOSED,
    [this.TYPES.PROCUREMENT_WORK_REJECTED]: ProcurementModel.STATUSES.REJECTED,
    [this.TYPES.PROCUREMENT_CLOSED]: ProcurementModel.STATUSES.CLOSED,
  });

  constructor(...params) {
    super(...params);

    this.isSystemMessage = ProcurementChatHistoryMessageModel.getIsSystemMessage(
      {
        type: this._type,
      }
    );
  }
}
