import { lazy } from 'react';
import PATH from 'routes/paths';

const InventoryList = lazy(() =>
  import('containers/project/inventory/InventoryList')
);

const INVENTORY_LIST = {
  component: InventoryList,
  path: PATH.PROJECT.INVENTORY.LIST,
  exact: false,
  isProtected: true,
};

export default [INVENTORY_LIST];
