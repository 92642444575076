import createGeneralChatMessageModel from './createGeneralChatMessageModel';
import ChatPager from '../../../realTimeApi/helpers/ChatPager';
import TypesHelper from '../../../utils/types/TypesHelper';
import sortChatHistory from './sortChatHistory';
import { getUniqueArrayOfObjects } from '../../../utils/helpers';
import { setChatHistoryLastReadMessage } from './updateChatHistoryLastReadMessage.helpers';

const convertToHistoryModels = (
  plainHistory,
  conversationInfo,
  messageCreator = createGeneralChatMessageModel
) => {
  return plainHistory.map((item) => messageCreator(item, conversationInfo));
};

const computePager = (pagerFromApi, nextPager) => {
  /** Compute the new nextPager relatively to current nextPager and pagerFromApi */

  if (pagerFromApi && nextPager) {
    if (pagerFromApi.page === 1 && nextPager.page > 1) {
      return nextPager;
    }
  }

  return nextPager
    ? new ChatPager(nextPager.page + 1, nextPager.limit)
    : new ChatPager(2);
};

export const updateChatHistory = (
  conversationInfo,
  updateHistoryFields = {},
  currentHistory,
  nextPagerOrigin,
  lastReadMessageId,
  messageCreator = createGeneralChatMessageModel
) => {
  if (nextPagerOrigin) {
    ChatPager.checkInstance(nextPagerOrigin);
  }

  let updateConversationFieldsCopy = {
    ...updateHistoryFields,
  };

  const { pagerFromApi } = updateHistoryFields;

  const isHistoryExisting = TypesHelper.isArray(
    updateConversationFieldsCopy.history
  );

  if (isHistoryExisting && pagerFromApi) {
    ChatPager.checkInstance(pagerFromApi);
  }

  if (isHistoryExisting) {
    const newHistory = [
      ...(currentHistory || []),
      ...convertToHistoryModels(
        updateConversationFieldsCopy.history,
        conversationInfo,
        messageCreator
      ),
    ];

    const computedPager = computePager(pagerFromApi, nextPagerOrigin);

    updateConversationFieldsCopy = {
      ...updateConversationFieldsCopy,
      history: sortChatHistory(getUniqueArrayOfObjects(newHistory, 'id')),
      nextPager: computedPager,
    };

    if (lastReadMessageId) {
      updateConversationFieldsCopy = {
        ...updateConversationFieldsCopy,
        history: setChatHistoryLastReadMessage(
          updateConversationFieldsCopy.history,
          lastReadMessageId
        ),
      };
    }
  }

  return updateConversationFieldsCopy;
};

export default updateChatHistory;
