import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';

class DirectMessagesChannelsEventCatcher extends AbstractEventCatcher {
  static process({ chats }, dispatch, loggedInUser) {
    dispatch(
      generalChatMessagesConversationActionCreators.setConversations(
        chats,
        loggedInUser.id
      )
    );

    dispatch(
      generalChatMessagesConversationActionCreators.addLastReadMessageToEachConversationHistory(
        chats
      )
    );
  }
}

export default DirectMessagesChannelsEventCatcher;
