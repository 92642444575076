import {
  GENERAL_CHAT_MESSAGES_ACTION_TYPES,
  GENERAL_CHAT_PROJECTS_ACTION_TYPES,
} from '../constants';
import GENERAL_CHAT_TABS from '../../../stories/ui/GeneralChatHeader/constants';
import { updateGeneralChatConversation } from '../helpers/updateGeneralChatConversation.helpers';
import { setChatHistoryLastReadMessage } from '../helpers/updateChatHistoryLastReadMessage.helpers';
import toConversationsStructure from '../helpers/toConversationsStructure';
import TypesHelper from '../../../utils/types/TypesHelper';
import toProjectConversationsStructure from '../helpers/toProjectConversationsStructure';
import getLastReadMessageId from '../helpers/getLastReadMessageId';
import createGeneralChatMessageModel from '../helpers/createGeneralChatMessageModel';
import createChatChannelItemModel from '../helpers/createChatChannelItemModel';
import ChatModel from '../../../realTimeApi/models/ChatModel';
import sortChatHistory from '../helpers/sortChatHistory';
import { getUniqueArrayOfObjects } from '../../../utils/helpers';
import removeConversation from '../helpers/removeConversation';
import ChatParticipantModel from '../../../realTimeApi/models/ChatParticipantModel';
import addLastReadMessageToEachConversationHistory from '../helpers/addLastReadMessageToEachConversationHistory';
import addLastReadMessageToEachProjectConversationHistory from '../helpers/addLastReadMessageToEachProjectConversationHistory';
import updateChatHistory from '../helpers/updateChatHistory';

const initialState = {
  [GENERAL_CHAT_TABS.MESSAGES]: {
    /**
     * [conversationId]: {
     *    conversationInfo: null,
     *    lastReadMessageId: null,
     *    history: [],
     *    nextPager: new ChatPager(),
     *    pager: new ChatPager(),
     *    total: null
     * }
     * */
  },
  [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: {
    /**
     * [projectId]: {
     *    projectInfo: null,
     *    conversations: {
     *        [conversationId]: {
     *          conversationInfo: null,
     *          lastReadMessageId: null,
     *          history: [],
     *          nextPager: new ChatPager(),
     *          pager: new ChatPager(),
     *          total: null
     *        }
     *    }
     * }
     * */
  },
};

export default function generalChatReducer(state = initialState, action) {
  switch (action.type) {
    /** @Messages_Tab */

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.SET_CONVERSATIONS: {
      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: {
          ...state[GENERAL_CHAT_TABS.MESSAGES],
          ...toConversationsStructure(
            action.payload.conversations,
            state[GENERAL_CHAT_TABS.MESSAGES],
            action.payload.loggedInUserId
          ),
        },
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.UPDATE_CONVERSATION_HISTORY: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];
      const currentConversation =
        conversations?.[action.payload.conversationId] || {};

      const newHistoryFields = updateChatHistory(
        currentConversation.conversationInfo,
        {
          history: action.payload.history,
          pagerFromApi: action.payload.pagerFromApi,
          total: action.payload.total,
        },
        currentConversation?.history || [],
        currentConversation.nextPager,
        currentConversation.lastReadMessageId
      );

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: {
          ...conversations,
          [action.payload.conversationId]: {
            ...currentConversation,
            ...newHistoryFields,
          },
        },
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.UPDATE_SPECIFIED_CONVERSATION: {
      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: updateGeneralChatConversation(
          action.payload.conversationId,
          state[GENERAL_CHAT_TABS.MESSAGES],
          action.payload.updateObj
        ),
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.ADD_NEW_HISTORY_MESSAGE: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];
      const currentConversation =
        conversations?.[action.payload.conversationId] || {};

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: {
          ...conversations,
          [action.payload.conversationId]: {
            ...currentConversation,
            history: sortChatHistory(
              getUniqueArrayOfObjects(
                [
                  ...(currentConversation?.history || []),
                  createGeneralChatMessageModel(
                    action.payload.newMessageObj,
                    currentConversation.conversationInfo
                  ),
                ],
                'id'
              )
            ),
          },
        },
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.REMOVE_HISTORY_MESSAGE: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];
      const currentConversation =
        conversations?.[action.payload.conversationId] || {};
      const currentHistory = currentConversation.history || [];
      const currentTotal = currentConversation.total;

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: {
          ...conversations,
          [action.payload.conversationId]: {
            ...currentConversation,
            history: currentHistory.filter(
              (message) => message.id !== +action.payload.messageId
            ),
            total: TypesHelper.isNumber(currentTotal) ? currentTotal - 1 : 0,
          },
        },
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.UPDATE_LAST_READ_MESSAGE_USING_HISTORY: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];
      const currentConversation =
        conversations?.[action.payload.conversationId] || {};
      const currentHistory = currentConversation?.history || [];
      const lastReadMessage = currentHistory.length
        ? currentHistory[currentHistory.length - 1]
        : {};

      const lastReadMessageId = getLastReadMessageId(lastReadMessage, action);

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: {
          ...conversations,
          [action.payload.conversationId]: {
            ...currentConversation,
            lastReadMessageId,
            history: setChatHistoryLastReadMessage(
              currentHistory,
              lastReadMessageId
            ),
          },
        },
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.UPDATE_LAST_READ_MESSAGE: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];
      const currentConversation =
        conversations?.[action.payload.conversationId] || {};

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: {
          ...conversations,
          [action.payload.conversationId]: {
            ...currentConversation,
            lastReadMessageId: action.payload.lastReadMessageId,
          },
        },
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.UPDATE_CONVERSATION_UNREAD_MESSAGES_IDS: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];
      const currentConversation =
        conversations?.[action.payload.conversationId] || {};
      const currentConversationInfo =
        currentConversation?.conversationInfo || {};

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: {
          ...conversations,
          [action.payload.conversationId]: {
            ...currentConversation,
            conversationInfo: new ChatModel({
              ...currentConversationInfo,
              unreadMessagesIds: action.payload.unreadMessagesIds || [],
            }),
          },
        },
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.SET_HISTORY_LAST_READ_MESSAGE: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];
      const currentConversation =
        conversations?.[action.payload.conversationId] || {};
      const currentHistory = currentConversation?.history || [];

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: {
          ...conversations,
          [action.payload.conversationId]: {
            ...currentConversation,
            history: setChatHistoryLastReadMessage(
              currentHistory,
              currentConversation.lastReadMessageId
            ),
          },
        },
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.REMOVE_CONVERSATION: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: removeConversation(
          conversations,
          action.payload.conversationId
        ),
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.REMOVE_PARTICIPANT_FROM_CONVERSATION: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];
      const currentConversation =
        conversations?.[action.payload.conversationId] || {};
      const currentConversationInfo = currentConversation.conversationInfo;
      const currentConversationInfoParticipants =
        currentConversationInfo?.chatParticipants || [];

      const newConversationInfo = TypesHelper.isObject(currentConversationInfo)
        ? new ChatModel({
            ...currentConversationInfo,
            chatParticipants: currentConversationInfoParticipants.filter(
              (participant) =>
                +participant.userId !== +action.payload.participantId
            ),
          })
        : undefined;

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: {
          ...conversations,
          [action.payload.conversationId]: {
            ...currentConversation,
            ...(newConversationInfo
              ? { conversationInfo: newConversationInfo }
              : {}),
          },
        },
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.ADD_PARTICIPANT_TO_CONVERSATION: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];
      const currentConversation =
        conversations?.[action.payload.conversationId] || {};
      const currentConversationInfo = currentConversation.conversationInfo;
      const currentConversationInfoParticipants =
        currentConversationInfo?.chatParticipants || [];

      const newConversationInfo = TypesHelper.isObject(currentConversationInfo)
        ? new ChatModel({
            ...currentConversationInfo,
            chatParticipants: [
              ...currentConversationInfoParticipants,
              new ChatParticipantModel(action.payload.participant),
            ],
          })
        : undefined;

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: {
          ...conversations,
          [action.payload.conversationId]: {
            ...currentConversation,
            ...(newConversationInfo
              ? { conversationInfo: newConversationInfo }
              : {}),
          },
        },
      };
    }

    case GENERAL_CHAT_MESSAGES_ACTION_TYPES.ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY: {
      const conversations = state[GENERAL_CHAT_TABS.MESSAGES];

      return {
        ...state,
        [GENERAL_CHAT_TABS.MESSAGES]: addLastReadMessageToEachConversationHistory(
          conversations,
          action.payload.chats
        ),
      };
    }

    /** @Projects_Tab */

    case GENERAL_CHAT_PROJECTS_ACTION_TYPES.SET_CONVERSATIONS: {
      const newConversations = toProjectConversationsStructure(
        action.payload.conversations,
        state[GENERAL_CHAT_TABS.PROJECT_CHANNELS],
        action.payload.loggedInUserId
      );

      return {
        ...state,
        [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: {
          ...state[GENERAL_CHAT_TABS.PROJECT_CHANNELS],
          ...newConversations,
        },
      };
    }

    case GENERAL_CHAT_PROJECTS_ACTION_TYPES.UPDATE_CONVERSATION_HISTORY: {
      const chatProjects = state[GENERAL_CHAT_TABS.PROJECT_CHANNELS];
      const currentChatProject = chatProjects?.[action.payload.projectId] || {};
      const currentConversation =
        currentChatProject?.conversations?.[action.payload.conversationId] ||
        {};

      const newHistoryFields = updateChatHistory(
        currentConversation.conversationInfo,
        {
          history: action.payload.history,
          pagerFromApi: action.payload.pagerFromApi,
          total: action.payload.total,
        },
        currentConversation?.history || [],
        currentConversation.nextPager,
        currentConversation.lastReadMessageId
      );

      return {
        ...state,
        [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: {
          ...chatProjects,
          [action.payload.projectId]: {
            ...currentChatProject,
            conversations: {
              ...currentChatProject.conversations,
              [action.payload.conversationId]: {
                ...currentConversation,
                ...newHistoryFields,
              },
            },
          },
        },
      };
    }

    case GENERAL_CHAT_PROJECTS_ACTION_TYPES.ADD_NEW_HISTORY_MESSAGE: {
      const chatProjects = state[GENERAL_CHAT_TABS.PROJECT_CHANNELS];
      const currentChatProject = chatProjects?.[action.payload.projectId] || {};
      const currentConversation =
        currentChatProject?.conversations?.[action.payload.conversationId] ||
        {};

      return {
        ...state,
        [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: {
          ...chatProjects,
          [action.payload.projectId]: {
            ...currentChatProject,
            conversations: {
              ...currentChatProject.conversations,
              [action.payload.conversationId]: {
                ...currentConversation,
                history: sortChatHistory(
                  getUniqueArrayOfObjects(
                    [
                      ...(currentConversation?.history || []),
                      createGeneralChatMessageModel(
                        action.payload.newMessageObj,
                        currentConversation.conversationInfo
                      ),
                    ],
                    'id'
                  )
                ),
              },
            },
          },
        },
      };
    }

    case GENERAL_CHAT_PROJECTS_ACTION_TYPES.UPDATE_LAST_READ_MESSAGE_USING_HISTORY: {
      const chatProjects = state[GENERAL_CHAT_TABS.PROJECT_CHANNELS];
      const currentChatProject = chatProjects?.[action.payload.projectId] || {};
      const currentConversation =
        currentChatProject?.conversations?.[action.payload.conversationId] ||
        {};
      const currentHistory = currentConversation?.history || [];
      const lastReadMessage = currentHistory.length
        ? currentHistory[currentHistory.length - 1]
        : {};

      const lastReadMessageId = getLastReadMessageId(lastReadMessage, action);

      return {
        ...state,
        [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: {
          ...chatProjects,
          [action.payload.projectId]: {
            ...currentChatProject,
            conversations: {
              ...currentChatProject.conversations,
              [action.payload.conversationId]: {
                ...currentConversation,
                lastReadMessageId,
                history: setChatHistoryLastReadMessage(
                  currentHistory,
                  lastReadMessageId
                ),
              },
            },
          },
        },
      };
    }

    case GENERAL_CHAT_PROJECTS_ACTION_TYPES.UPDATE_LAST_READ_MESSAGE: {
      const chatProjects = state[GENERAL_CHAT_TABS.PROJECT_CHANNELS];
      const currentChatProject = chatProjects?.[action.payload.projectId] || {};
      const currentConversation =
        currentChatProject?.conversations?.[action.payload.conversationId] ||
        {};

      return {
        ...state,
        [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: {
          ...chatProjects,
          [action.payload.projectId]: {
            ...currentChatProject,
            conversations: {
              ...currentChatProject.conversations,
              [action.payload.conversationId]: {
                ...currentConversation,
                lastReadMessageId: action.payload.lastReadMessageId,
              },
            },
          },
        },
      };
    }

    case GENERAL_CHAT_PROJECTS_ACTION_TYPES.REMOVE_HISTORY_MESSAGE: {
      const chatProjects = state[GENERAL_CHAT_TABS.PROJECT_CHANNELS];
      const currentChatProject = chatProjects?.[action.payload.projectId] || {};
      const currentConversation =
        currentChatProject?.conversations?.[action.payload.conversationId] ||
        {};
      const currentHistory = currentConversation?.history || [];
      const currentTotal = currentConversation.total;

      return {
        ...state,
        [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: {
          ...chatProjects,
          [action.payload.projectId]: {
            ...currentChatProject,
            conversations: {
              ...currentChatProject.conversations,
              [action.payload.conversationId]: {
                ...currentConversation,
                history: currentHistory.filter(
                  (message) => message.id !== +action.payload.messageId
                ),
                total: TypesHelper.isNumber(currentTotal)
                  ? currentTotal - 1
                  : 0,
              },
            },
          },
        },
      };
    }

    case GENERAL_CHAT_PROJECTS_ACTION_TYPES.UPDATE_CONVERSATION_INFO: {
      const chatProjects = state[GENERAL_CHAT_TABS.PROJECT_CHANNELS];
      const currentChatProject = chatProjects?.[action.payload.projectId] || {};
      const currentConversation =
        currentChatProject?.conversations?.[action.payload.conversationId] ||
        {};

      const newConversationInfo = createChatChannelItemModel(
        {
          ...currentConversation.conversationInfo,
          ...action.payload.newConversationInfo,
        },
        action.payload.newConversationInfo.loggedInUserId
      );

      return {
        ...state,
        [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: {
          ...chatProjects,
          [action.payload.projectId]: {
            ...currentChatProject,
            conversations: {
              ...currentChatProject.conversations,
              [action.payload.conversationId]: {
                ...currentConversation,
                conversationInfo: newConversationInfo,
              },
            },
          },
        },
      };
    }

    case GENERAL_CHAT_PROJECTS_ACTION_TYPES.SET_HISTORY_LAST_READ_MESSAGE: {
      const chatProjects = state[GENERAL_CHAT_TABS.PROJECT_CHANNELS];
      const currentChatProject = chatProjects?.[action.payload.projectId] || {};
      const currentConversation =
        currentChatProject?.conversations?.[action.payload.conversationId] ||
        {};
      const currentHistory = currentConversation?.history || [];

      return {
        ...state,
        [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: {
          ...chatProjects,
          [action.payload.projectId]: {
            ...currentChatProject,
            conversations: {
              ...currentChatProject.conversations,
              [action.payload.conversationId]: {
                ...currentConversation,
                history: setChatHistoryLastReadMessage(
                  currentHistory,
                  currentConversation?.lastReadMessageId
                ),
              },
            },
          },
        },
      };
    }

    case GENERAL_CHAT_PROJECTS_ACTION_TYPES.UPDATE_CONVERSATION_UNREAD_MESSAGES_IDS: {
      const chatProjects = state[GENERAL_CHAT_TABS.PROJECT_CHANNELS];
      const currentChatProject = chatProjects?.[action.payload.projectId] || {};
      const currentConversation =
        currentChatProject?.conversations?.[action.payload.conversationId] ||
        {};
      const currentConversationInfo =
        currentConversation?.conversationInfo || {};

      return {
        ...state,
        [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: {
          ...chatProjects,
          [action.payload.projectId]: {
            ...currentChatProject,
            conversations: {
              ...currentChatProject.conversations,
              [action.payload.conversationId]: {
                ...currentConversation,
                conversationInfo: new ChatModel({
                  ...currentConversationInfo,
                  unreadMessagesIds: action.payload.unreadMessagesIds || [],
                }),
              },
            },
          },
        },
      };
    }

    case GENERAL_CHAT_PROJECTS_ACTION_TYPES.ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY: {
      const projectConversations = state[GENERAL_CHAT_TABS.PROJECT_CHANNELS];

      return {
        ...state,
        [GENERAL_CHAT_TABS.PROJECT_CHANNELS]: addLastReadMessageToEachProjectConversationHistory(
          projectConversations,
          action.payload.projects
        ),
      };
    }

    default:
      return state;
  }
}
