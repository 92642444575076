import RealTimeProvider from './RealTimeProvider';

/**
 * @param {Object} actions
 * @param {Function} actions.onMessage
 * @param {Function} actions.onError
 * @param {Function} actions.onClose
 * @param {Function} actions.onOpen
 */
const createRealTimeProvider = (actions) =>
  new RealTimeProvider(WebSocket, actions);

export default createRealTimeProvider;
