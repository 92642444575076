import ApiResourceRequest, { API_METHODS } from './ApiResourceRequest';
import { required } from '../../utils/helpers';
import ProjectModel from '../models/Project/ProjectModel';
import ListingResponse from './ListingResponse';
import Pager from '../helpers/Pager';

export class ProjectRequest extends ApiResourceRequest {
  get apiResource() {
    return 'projects';
  }

  get dynamicIds() {
    return {
      PROJECT_ID: '{projectId}',
      FILE_ID: '{fileId}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      GET_STATISTICS: `${this.apiRoute}/statistics`,
      GET_DASHBOARD_STATS_BY_PROJECT_ID: `${this.apiRoute}/dashboard-stats/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_USER_STATS_BY_PROJECT_ID: `${this.apiRoute}/users-stats/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_ASSIGNED_PROJECTS: `${this.apiRoute}/statistics-for-associated-projects`,
      ACTIVATE_PROJECT: `${this.apiRoute}/activate/${this.dynamicIds.PROJECT_ID}`,
      ARCHIVE_PROJECT: `${this.apiRoute}/archive/${this.dynamicIds.PROJECT_ID}`,
      UNARCHIVE_PROJECT: `${this.apiRoute}/unarchive/${this.dynamicIds.PROJECT_ID}`,
      EXTEND_PROJECT: `${this.apiRoute}/extend/${this.dynamicIds.PROJECT_ID}`,
      ASSIGN_USER: `${this.apiRoute}/assign-user-to-project`,
      DEASSIGN_USER: `${this.apiRoute}/remove-user-from-project`,
      GET_PUBLIC_DATA: `${this.apiRoute}/public-info/${this.dynamicIds.PROJECT_ID}`,
      GET_STATUSES_STATS: `${this.apiRoute}/statistics/statuses`,
    };
  }

  async getPublicData(id, query = {}) {
    if (!id) {
      throw new Error('Id is required to find project public data!');
    }

    const queryString = this.buildQueryString(
      this.getRelationParams(query.relations)
    );
    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_PUBLIC_DATA.replace(this.dynamicIds.PROJECT_ID, id) +
        queryString
    );

    return response?.data;
  }

  async getStatistics(status, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    if (!Object.values(ProjectModel.STATES).includes(status)) {
      throw new Error(
        `status must be one of ${Object.values(ProjectModel.STATES).toString()}`
      );
    }
    const queryString = this.getQueryString(pager, query, true);

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_STATISTICS + queryString
    );

    return new ListingResponse(response?.data);
  }

  async getDashboardStatsByProjectId(projectId) {
    required({ projectId });
    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_DASHBOARD_STATS_BY_PROJECT_ID.replace(
        `${this.dynamicIds.PROJECT_ID}`,
        projectId
      )
    );

    return response?.data;
  }

  async getUserStatsByProjectId(projectId) {
    required({ projectId });
    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_USER_STATS_BY_PROJECT_ID.replace(
        `${this.dynamicIds.PROJECT_ID}`,
        projectId
      )
    );

    return response?.data;
  }

  async getAssignedProjects(pager = new Pager(), query = {}) {
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_ASSIGNED_PROJECTS + queryString
    );

    return new ListingResponse(response?.data);
  }

  async assignUser(userId, projectId) {
    required({ userId, projectId });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.ASSIGN_USER,
      {
        userId: Number(userId),
        projectId: Number(projectId),
      }
    );

    return response?.data;
  }

  async deassignUser(userId, projectId) {
    required({ userId, projectId });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.DEASSIGN_USER,
      {
        userId: Number(userId),
        projectId: Number(projectId),
      }
    );

    return response?.data;
  }

  async activateProject(projectId) {
    required({ projectId });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.ACTIVATE_PROJECT.replace(
        `${this.dynamicIds.PROJECT_ID}`,
        projectId
      )
    );

    return response?.data;
  }

  async archiveProject(projectId) {
    required({ projectId });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.ARCHIVE_PROJECT.replace(
        `${this.dynamicIds.PROJECT_ID}`,
        projectId
      )
    );

    return response?.data;
  }

  async unarchiveProject(projectId) {
    required({ projectId });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.UNARCHIVE_PROJECT.replace(
        `${this.dynamicIds.PROJECT_ID}`,
        projectId
      )
    );

    return response?.data;
  }

  async extendProject(projectId, endDate) {
    required({ projectId, endDate });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.EXTEND_PROJECT.replace(
        `${this.dynamicIds.PROJECT_ID}`,
        projectId
      ),
      {
        endDate,
      }
    );

    return response?.data;
  }

  async getStatusesStats() {
    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_STATUSES_STATS
    );

    return response?.data;
  }
}

export const projectRequest = new ProjectRequest();
