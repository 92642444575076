export default {
  realm: {
    plural: {
      itself: 'Organizare',
    },
    attributes: {
      name: {
        itself: 'Nume',
        '*': 'Nume *',
        required: 'Numele este obligatoriu',
      },
      type: {
        itself: 'Tip',
        '*': 'Tip *',
        required: 'Tipul este obligatoriu',
      },
      website: {
        itself: 'Website',
        '*': 'Website *',
        required: 'Website-ul este obligatoriu',
      },
      status: {
        itself: 'Stare',
      },
    },
    list: {
      filter: {
        showSuspended: 'Afișați organizațiile suspendate',
      },
    },
    addNewOrganization: 'Adaugă organizație nouă',
    editOrganization: 'Modifică organizație',
    saveOrganization: 'Salvează organizația',
    saveNewOrganization: 'Salvează organizația nouă',
    enterOrganizationName: 'Introdu numele organizației',
    generalManager: 'General Manager',
    generalManagerInfo: 'Informații manager general',
    buildingManagerInfo: 'Informații administrator clădire',
    officeManagerInfo: 'Informații administrator de birou',
    companyWebsite: 'Website-ul companiei',
    types: {
      beneficiary: 'Beneficiar',
      tenant: 'Chiriaș',
      provider: '$ Provider',
    },
    companyInformation: '$ Company information',
  },
};
