import { createActions, createActionTypesConfig } from '../helpers';

export const GET = Object.freeze({
  REQUEST: 'realm/GET_REQUEST',
  SUCCESS: 'realm/GET_SUCCESS',
  ERROR: 'realm/GET_ERROR',
});

export const GET_BY_TYPE = Object.freeze({
  REQUEST: 'realm/GET_BY_TYPE_REQUEST',
  SUCCESS: 'realm/GET_BY_TYPE_SUCCESS',
  ERROR: 'realm/GET_BY_TYPE_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'realm/FIND_REQUEST',
  SUCCESS: 'realm/FIND_SUCCESS',
  ERROR: 'realm/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'realm/CREATE_REQUEST',
  SUCCESS: 'realm/CREATE_SUCCESS',
  ERROR: 'realm/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'realm/UPDATE_REQUEST',
  SUCCESS: 'realm/UPDATE_SUCCESS',
  ERROR: 'realm/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'realm/DELETE_REQUEST',
  SUCCESS: 'realm/DELETE_SUCCESS',
  ERROR: 'realm/DELETE_ERROR',
});

export const ACTIVATE_ORGANIZATION_ACTION_TYPES = createActionTypesConfig(
  'realm',
  'ACTIVATE'
);

export const DEACTIVATE_ORGANIZATION_ACTION_TYPES = createActionTypesConfig(
  'realm',
  'DEACTIVATE'
);

export const GET_REALMS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const GET_REALMS_BY_TYPE_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_TYPE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_TYPE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_TYPE.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_REALM_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_REALM_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_REALM_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_REALM_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});

export const ACTIVATE_ORGANIZATION_ACTIONS = createActions(
  ACTIVATE_ORGANIZATION_ACTION_TYPES
);

export const DEACTIVATE_ORGANIZATION_ACTIONS = createActions(
  DEACTIVATE_ORGANIZATION_ACTION_TYPES
);
