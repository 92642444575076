import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function SubcontractIcon({ size, color, ...rest }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 12V6H8M3 12V20H21V12M3 12H10M10 12V15H14V12M10 12V10H14V12M14 12H21M21 12V6H16M8 6V2H16V6M8 6H16"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(SubcontractIcon);
