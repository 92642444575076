import { numericRegex } from '../../utils/helpers';

/**
 *
 * @type {string}
 */
export const userIdPattern = `:userId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const unitIdPattern = `:unitId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const organizationIdPattern = `:organizationId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const projectIdPattern = `:projectId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const channelIdPattern = `:channelId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const conversationIdPattern = `:conversationId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const maintenancePlanEventIdPattern = `:eventId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const equipmentIdPattern = `:equipmentId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const subcontractorIdPattern = `:subcontractorId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const ticketIdPattern = `:ticketId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const procurementIdPattern = `:procurementId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const inventoryIdPattern = `:inventoryId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const warrantyTrackerIdPattern = `:warrantyTrackerId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const standardProcedureIdPattern = `:standardProcedureId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const dailyProcedureGroupIdPattern = `:dailyProcedureGroupId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const dailyCheckTourIdPattern = `:dailyCheckTourId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const dailyCheckTourEventIdPattern = `:dailyCheckTourEventId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const dailyCheckProcedureIdPattern = `:dailyCheckProcedureId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const announcementIdPattern = `:announcementId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const procedureIdPattern = `:procedureId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const projectBoardItemIdPattern = `:projectBoardItemId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const activityReportIdPattern = `:activityReportId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const reportIdPattern = `:reportId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const buildingIdPattern = `:buildingId(${numericRegex})`;
/**
 *
 * @type {string}
 */
export const levelIdPattern = `:levelId(${numericRegex})`;
