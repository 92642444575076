import React from 'react';
import PropTypes from 'prop-types';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { cafmTheme } from 'themes/cafm';
import NotificationModel from 'api/models/NotificationModel';
import NotificationItemMapper from 'store/notifications/helpers/NotificationItemMapper';
import { cutStringByMaxLength } from 'utils/helpers';
import TypesHelper from 'utils/types/TypesHelper';

const { NOTIFICATION_TYPES } = NotificationModel;

const typeColors = {
  [NOTIFICATION_TYPES.CREATE]: {
    color: cafmTheme.palette.secondary.main,
    background: cafmTheme.palette.secondary.light,
  },
  [NOTIFICATION_TYPES.UPDATE]: {
    color: cafmTheme.palette.themeCommon.yellow.main,
    background: cafmTheme.palette.themeCommon.yellow.light,
  },
  [NOTIFICATION_TYPES.INCOMING_MESSAGE]: {
    color: cafmTheme.palette.primary.main,
    background: cafmTheme.palette.primary.light,
  },
  [NOTIFICATION_TYPES.CUSTOM]: {
    color: cafmTheme.palette.themeCommon.purple.main,
    background: cafmTheme.palette.themeCommon.purple.light,
  },
  default: {
    color: cafmTheme.palette.gray[1900],
    background: cafmTheme.palette.gray[200],
  },
};

const Notification = styled.div`
  padding: 12px;
  margin: 0 4px;
  white-space: normal !important;
  border-radius: 8px;
  cursor: ${({ withHover }) => withHover && 'pointer'};
  position: relative;
  overflow: hidden;
  
  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 98%;
    height: 1px;
    background:  ${({ theme }) => theme.palette.themeCommon.black.light};
    content: "";
  }
  
  &:hover {
    ${({ withHover }) =>
      withHover &&
      css`
        &:hover {
          background: ${({ theme }) => theme.palette.gray[100]};
          border-bottom: none;

          &::before {
            display: none;
          }
        }
      `}
`;

const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
`;

const headerTitleTextCss = css`
  color: ${({ type }) => typeColors[type]?.color || typeColors.default.color};
  font-weight: 500;
  font-size: ${({ theme }) =>
    theme.typography.pxToRem(theme.typography.fontSize - 2)};
  line-height: 16px;
  letter-spacing: 0.04em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Name = styled.div`
  ${headerTitleTextCss}
`;

const NotificationType = styled.span`
  ${headerTitleTextCss}
`;

const NotificationDate = styled.span`
  margin-left: auto;
  font-weight: 500;
  font-size: ${({ theme }) =>
    theme.typography.pxToRem(theme.typography.fontSize - 2)};
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.palette.gray[500]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NotificationAuthor = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) =>
    theme.typography.pxToRem(theme.typography.fontSize - 2)};
  line-height: 16px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.palette.gray[1900]};
`;

const NotificationDescription = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) =>
    theme.typography.pxToRem(theme.typography.fontSize - 2)};
  line-height: 16px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.palette.gray[500]};
`;

const Circle = styled.div`
  background: ${({ type }) =>
    typeColors[type]?.color || typeColors.default.color};
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 5px;
`;

const NotificationsItem = ({
  item: { type, author, description, date, name, customTypeTitle },
  withHover,
}) => {
  const { t } = useTranslation();

  const getText = (text) => {
    if (TypesHelper.isObject(text)) {
      if (text.withTranslate) {
        return t(text.text);
      }

      return text.text;
    }

    return text;
  };

  const formattedDate = date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '-';

  return (
    <Notification withHover={withHover}>
      <NotificationHeader>
        <Name type={type}>{getText(name)}</Name>
        <Circle type={type} />
        <NotificationType type={type}>
          {cutStringByMaxLength(
            NotificationItemMapper.getTypeName(type, getText(customTypeTitle)),
            20
          )}
        </NotificationType>
        <NotificationDate>{formattedDate}</NotificationDate>
      </NotificationHeader>
      <NotificationAuthor>{author}</NotificationAuthor>
      <NotificationDescription>
        {cutStringByMaxLength(getText(description) || '', 70)}
      </NotificationDescription>
    </Notification>
  );
};

const TextPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({ text: PropTypes.string, withTranslate: PropTypes.bool }),
]);

NotificationsItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOfType([
      PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)),
      PropTypes.string,
    ]),
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    author: PropTypes.string,
    description: TextPropType,
    onClick: PropTypes.func,
    name: TextPropType,
    customTypeTitle: TextPropType,
  }).isRequired,
  withHover: PropTypes.bool,
};
NotificationsItem.defaultProps = {
  withHover: false,
};

export default NotificationsItem;
