import AbstractModel from '../AbstractModel';

class StandardEquipmentCategoryModel extends AbstractModel {
  static CATEGORIES = Object.freeze({
    HEATING: 'Heating',
    COOLING: 'Cooling',
    VENTILATION: 'Ventilation',
    SANITARY: 'Sanitary',
    PLUMBING_SYSTEM: 'Plumbing system',
    LOW_VOLTAGE: 'Low voltage',
    EXTRA_LOW_VOLTAGE: 'Extra low voltage',
    FIRE_FIGHTING_SYSTEM: 'Fire fighting system',
    EMERGENCY_SYSTEM: 'Emergency system',
    OTHER: 'Others',
  });

  static READABLE_I18N_CATEGORIES = Object.freeze({
    [StandardEquipmentCategoryModel.CATEGORIES.HEATING]:
      'procedure.categories.heating',
    [StandardEquipmentCategoryModel.CATEGORIES.COOLING]:
      'procedure.categories.cooling',
    [StandardEquipmentCategoryModel.CATEGORIES.VENTILATION]:
      'procedure.categories.ventilation',
    [StandardEquipmentCategoryModel.CATEGORIES.SANITARY]:
      'procedure.categories.sanitary',
    [StandardEquipmentCategoryModel.CATEGORIES.PLUMBING_SYSTEM]:
      'procedure.categories.plumbingSystem',
    [StandardEquipmentCategoryModel.CATEGORIES.LOW_VOLTAGE]:
      'procedure.categories.lowVoltage',
    [StandardEquipmentCategoryModel.CATEGORIES.EXTRA_LOW_VOLTAGE]:
      'procedure.categories.extraLowVoltage',
    [StandardEquipmentCategoryModel.CATEGORIES.FIRE_FIGHTING_SYSTEM]:
      'procedure.categories.fireFightingSystem',
    [StandardEquipmentCategoryModel.CATEGORIES.EMERGENCY_SYSTEM]:
      'procedure.categories.emergencySystem',
    [StandardEquipmentCategoryModel.CATEGORIES.OTHER]:
      'procedure.categories.others',
  });
}

export default StandardEquipmentCategoryModel;
