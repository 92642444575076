import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';
import ChatParticipantModel from '../../../models/ChatParticipantModel';
import GeneralChatHistoryMessageModel from '../../../models/GeneralChatHistoryMessageModel';

export default class DirectMessagesNewMessageEventCatcher extends AbstractEventCatcher {
  static process(historyItem, dispatch) {
    if (
      Object.values(GeneralChatHistoryMessageModel.TYPES).includes(
        historyItem.type
      )
    ) {
      dispatch(
        generalChatMessagesConversationActionCreators.addNewMessage(
          historyItem.chat.id,
          {
            chatId: historyItem.chat.id,
            ...historyItem,
          }
        )
      );

      return;
    }

    dispatch(
      generalChatMessagesConversationActionCreators.addNewMessage(
        historyItem.chatId,
        {
          chatId: historyItem.chatId,
          ...historyItem.message,
          user: new ChatParticipantModel(historyItem.user),
        }
      )
    );
  }
}
