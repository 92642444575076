import { ORDERS, STATUS } from 'utils/constants';
import {
  buildQueryKey,
  getUniqueArrayOfObjectsWithCheck,
  sortArrayByObjectDateProp,
} from 'utils/helpers';
import DateFormats from 'utils/dates';
import {
  addToDictionary,
  addToList,
  changeListStatus,
  hasPageSizeChanged,
  resetState,
} from '../helpers';
import {
  FIND,
  FIND_YEARLY_PMP_EVENT,
  GET,
  GET_BY_PROJECT_ID,
  GET_BY_PROJECT_ID_WEEK_EVENTS,
  GET_BY_PROJECT_ID_YEARLY,
  GET_IN_PROGRESS_PMP_EVENTS_BY_PROJECT_ID,
  GET_PMP_EVENTS_BY_PROJECT_ID_INFINITE_SCROLL,
  GET_RESOLVED_PMP_EVENTS_BY_PROJECT_ID,
  WEEK_EVENTS_LOADING,
} from './constants';
import comparePmpEventQueryObject from './helpers/comparePmpEventQueryObject';

/**
 *
 * @type {{list: {default: {}, pagination: {total: number, pageNumber: null, pageSize: null}, meta: {currentKey: string}}, dictionary: {}}}
 */
const initialState = {
  infiniteScrollList: {},
  dictionary: {},
  list: {
    meta: {
      currentKey: 'default',
    },
    pagination: {
      pageNumber: null,
      pageSize: null,
      total: 0,
    },
    default: {},
  },
  yearWeeksDictionary: {
    // [DateFormats.toISODate(startDate)]: {
    //   count: number, // total
    //   events: [{equipmentName: string, procedureFrequency: string, status: string, eventId: number}],
    // loading: bool
    // },
  },
  yearWeeksDictionaryLoading: null,
  yearWeeksDictionaryDataKey: Math.random(),
};

export default function pmpEventReducer(state = initialState, action) {
  let queryKey;
  let listStatus;
  let error;
  let id;
  let resourceModel;
  let resetToDefault;
  let pager;

  switch (action.type) {
    case GET.REQUEST:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;
      resetToDefault = action.payload.resetToDefault;

      if (
        hasPageSizeChanged(state.list.pagination.pageSize, pager?.pageSize) ||
        resetToDefault
      ) {
        return resetState(state, initialState, {
          queryKey,
          pager,
        });
      }

      if (
        [STATUS.RELOADING, STATUS.SUCCESS, STATUS.CACHED].includes(listStatus)
      ) {
        return changeListStatus(state, { pager, queryKey }, STATUS.RELOADING);
      }
      return changeListStatus(state, { pager, queryKey }, STATUS.LOADING);
    case GET.SUCCESS:
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
        list: addToList(state.list, action.payload),
      };
    case GET.ERROR:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      error = action.payload.error;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;

      return changeListStatus(
        state,
        { pager, queryKey, error },
        listStatus === STATUS.RELOADING ? STATUS.CACHED : STATUS.ERROR
      );

    case GET_BY_PROJECT_ID.REQUEST:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;
      resetToDefault = action.payload.resetToDefault;

      if (
        hasPageSizeChanged(state.list.pagination.pageSize, pager?.pageSize) ||
        resetToDefault
      ) {
        return resetState(state, initialState, {
          queryKey,
          pager,
        });
      }

      if (
        [STATUS.RELOADING, STATUS.SUCCESS, STATUS.CACHED].includes(listStatus)
      ) {
        return changeListStatus(state, { pager, queryKey }, STATUS.RELOADING);
      }
      return changeListStatus(state, { pager, queryKey }, STATUS.LOADING);
    case GET_BY_PROJECT_ID.SUCCESS:
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
        list: addToList(state.list, action.payload),
      };
    case GET_PMP_EVENTS_BY_PROJECT_ID_INFINITE_SCROLL.REQUEST: {
      const { projectId } = action.payload;
      return {
        ...state,
        infiniteScrollList: {
          ...state.infiniteScrollList,
          [projectId]: {
            ...(state.infiniteScrollList?.[projectId] || {}),
            list: {
              ...state.infiniteScrollList?.[projectId]?.list,
              status: STATUS.LOADING,
            },
          },
        },
      };
    }
    case GET_PMP_EVENTS_BY_PROJECT_ID_INFINITE_SCROLL.SUCCESS: {
      const { projectId } = action.payload;
      const currentInfiniteScrollList =
        state.infiniteScrollList[projectId] || {};
      const currentDictionary = currentInfiniteScrollList?.dictionary || [];
      const newResources = action.payload.resources.map(
        (resourceParameter) =>
          new action.payload.resourceModel(resourceParameter)
      );

      const prevQuery = currentInfiniteScrollList?.list?.query || {};
      const currentQuery = action.payload.query || {};
      const isQueryEqual = comparePmpEventQueryObject(prevQuery, currentQuery);

      return {
        ...state,
        infiniteScrollList: {
          ...state.infiniteScrollList,
          [projectId]: {
            ...currentInfiniteScrollList,
            list: {
              ...(state.infiniteScrollList?.[projectId].list || {}),
              status: STATUS.SUCCESS,
              pagination: {
                ...(state.infiniteScrollList?.[projectId].list.pagination ||
                  {}),
                total: action.payload.total,
              },
              query: currentQuery,
            },
            dictionary: sortArrayByObjectDateProp(
              isQueryEqual
                ? getUniqueArrayOfObjectsWithCheck(
                    [...currentDictionary, ...newResources],
                    'id',
                    (item1, item2) => item2
                  )
                : [...newResources],
              'date',
              ORDERS.ASC
            ),
          },
        },
      };
    }
    case GET_BY_PROJECT_ID_YEARLY.REQUEST:
      return {
        ...state,
        yearWeeksDictionaryLoading: true,
      };
    case GET_BY_PROJECT_ID_YEARLY.SUCCESS:
      return {
        ...state,
        yearWeeksDictionary: {
          ...(action.payload.preserveOldState ? state.yearWeeksDictionary : {}),
          ...action.payload.resources.reduce(
            (
              acc,
              {
                startDate,
                stopDate,
                isWeekMonthStart,
                isWeekMonthEnd,
                isWeekYearStart,
                isWeekYearEnd,
                events = [],
                count = 0,
              }
            ) => {
              const key = `${DateFormats.toISODate(
                startDate
              )}_${DateFormats.toISODate(stopDate)}`;

              acc[key] = {
                events,
                count,
                isWeekMonthStart,
                isWeekMonthEnd,
                isWeekYearStart,
                isWeekYearEnd,
              };
              return acc;
            },
            {}
          ),
        },
        yearWeeksDictionaryLoading: false,
        yearWeeksDictionaryDataKey: Math.random(),
      };
    case GET_BY_PROJECT_ID_YEARLY.ERROR:
      return {
        ...state,
        yearWeeksDictionaryLoading: false,
      };

    case FIND_YEARLY_PMP_EVENT.SUCCESS: {
      const { resource } = action.payload;

      const key = Object.keys(state.yearWeeksDictionary || {}).find(
        (datesKey) => {
          const [startDate, endDate] = datesKey.split('_');

          return (
            (DateFormats.isDateBefore(
              DateFormats.setTime(new Date(startDate)),
              resource.date
            ) ||
              DateFormats.areDatesEqual(
                DateFormats.setTime(new Date(startDate)),
                resource.date
              )) &&
            (DateFormats.isDateBefore(
              resource.date,
              DateFormats.setTime(new Date(endDate))
            ) ||
              DateFormats.areDatesEqual(
                resource.date,
                DateFormats.setTime(new Date(endDate))
              ))
          );
        }
      );

      if (!key) {
        return state;
      }

      return {
        ...state,
        yearWeeksDictionary: {
          ...state.yearWeeksDictionary,
          [key]: {
            ...state.yearWeeksDictionary[key],
            events: state.yearWeeksDictionary[key].events.map((event) => {
              if (+(event.id || event.eventId) === +resource.id) {
                return {
                  equipmentName: resource.procedure.equipment.name,
                  eventId: resource.id,
                  isDisabled: resource.isDisabled,
                  procedureFrequency: resource.procedure.frequency,
                  status: resource.eventStatus,
                  type: resource.procedure.type,
                };
              }

              return event;
            }),
          },
        },
      };
    }

    case GET_BY_PROJECT_ID_WEEK_EVENTS.REQUEST:
      const key = `${DateFormats.toISOUTCDate(
        action.payload.startDate
      )}_${DateFormats.toISOUTCDate(action.payload.endDate)}`;

      return {
        ...state,
        yearWeeksDictionary: {
          ...state.yearWeeksDictionary,
          [key]: {
            ...(state.yearWeeksDictionary[key] || {}),
            loading: true,
          },
        },
        yearWeeksDictionaryDataKey: Math.random(),
      };
    case GET_BY_PROJECT_ID_WEEK_EVENTS.SUCCESS:
      const key2 = `${DateFormats.toISOUTCDate(
        action.payload.startDate
      )}_${DateFormats.toISOUTCDate(action.payload.endDate)}`;

      return {
        ...state,
        yearWeeksDictionary: {
          ...state.yearWeeksDictionary,
          [key2]: {
            ...(state.yearWeeksDictionary[key2] || {}),
            events: action.payload.resources,
            count: action.payload.resources.length,
            loading: false,
          },
        },
        yearWeeksDictionaryDataKey: Math.random(),
      };

    case WEEK_EVENTS_LOADING.UPDATE:
      const newYearWeeksDictionary = state.yearWeeksDictionary;
      action.payload.eventKeys.forEach((eventKey) => {
        newYearWeeksDictionary[eventKey].loading = action.payload.loading;
      });
      return {
        ...state,
        yearWeeksDictionary: newYearWeeksDictionary,
        yearWeeksDictionaryDataKey: Math.random(),
      };

    case GET_BY_PROJECT_ID.ERROR:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      error = action.payload.error;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;

      return changeListStatus(
        state,
        { pager, queryKey, error },
        listStatus === STATUS.RELOADING ? STATUS.CACHED : STATUS.ERROR
      );

    case GET_IN_PROGRESS_PMP_EVENTS_BY_PROJECT_ID.REQUEST:
      return state;
    case GET_IN_PROGRESS_PMP_EVENTS_BY_PROJECT_ID.SUCCESS:
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
      };
    case GET_IN_PROGRESS_PMP_EVENTS_BY_PROJECT_ID.ERROR:
      return state;

    case GET_RESOLVED_PMP_EVENTS_BY_PROJECT_ID.REQUEST:
      return state;
    case GET_RESOLVED_PMP_EVENTS_BY_PROJECT_ID.SUCCESS:
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
      };
    case GET_RESOLVED_PMP_EVENTS_BY_PROJECT_ID.ERROR:
      return state;

    case FIND.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case FIND.SUCCESS:
      const { resource } = action.payload;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [resource.id]: resource,
        },
      };
    case FIND.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    default:
      return state;
  }
}
