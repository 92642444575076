import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatProjectsConversationActionsCreators } from '../../../../store/chat/general/actions';

export default class ProjectMessagesUpdateChannelEventCatcher extends AbstractEventCatcher {
  static process(channel, dispatch, loggedInUser) {
    dispatch(
      generalChatProjectsConversationActionsCreators.updateConversationInfo(
        channel.projectId,
        channel.id,
        { ...channel, loggedInUserId: loggedInUser.id }
      )
    );
  }
}
