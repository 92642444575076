import { lazy } from 'react';
import PATH from 'routes/paths';

const WarrantyTrackerList = lazy(() =>
  import('containers/project/warrantyTracker/WarrantyTrackerList')
);

const WARRANTY_TRACKER_LIST = {
  component: WarrantyTrackerList,
  path: PATH.PROJECT.WARRANTY_TRACKER.LIST,
  exact: false,
  isProtected: true,
};

export default [WARRANTY_TRACKER_LIST];
