import { createActions, createActionTypesConfig } from '../helpers';

export const GET = Object.freeze({
  REQUEST: 'project/GET_REQUEST',
  SUCCESS: 'project/GET_SUCCESS',
  ERROR: 'project/GET_ERROR',
});

export const GET_STATISTICS = Object.freeze({
  REQUEST: 'project/GET_STATISTICS_REQUEST',
  SUCCESS: 'project/GET_STATISTICS_SUCCESS',
  ERROR: 'project/GET_STATISTICS_ERROR',
});

export const GET_ASSIGNED = Object.freeze({
  REQUEST: 'project/GET_ASSIGNED_REQUEST',
  SUCCESS: 'project/GET_ASSIGNED_SUCCESS',
  ERROR: 'project/GET_ASSIGNED_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'project/FIND_REQUEST',
  SUCCESS: 'project/FIND_SUCCESS',
  ERROR: 'project/FIND_ERROR',
});

export const GET_PUBLIC_DATA_TYPES = createActionTypesConfig(
  'project',
  'GET_PUBLIC_DATA'
);

export const CREATE = Object.freeze({
  REQUEST: 'project/CREATE_REQUEST',
  SUCCESS: 'project/CREATE_SUCCESS',
  ERROR: 'project/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'project/UPDATE_REQUEST',
  SUCCESS: 'project/UPDATE_SUCCESS',
  ERROR: 'project/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'project/DELETE_REQUEST',
  SUCCESS: 'project/DELETE_SUCCESS',
  ERROR: 'project/DELETE_ERROR',
});

export const ACTIVATE = Object.freeze({
  REQUEST: 'project/ACTIVATE_REQUEST',
  SUCCESS: 'project/ACTIVATE_SUCCESS',
  ERROR: 'project/ACTIVATE_ERROR',
});

export const ARCHIVE = Object.freeze({
  REQUEST: 'project/ARCHIVE_REQUEST',
  SUCCESS: 'project/ARCHIVE_SUCCESS',
  ERROR: 'project/ARCHIVE_ERROR',
});

export const EXTEND = Object.freeze({
  REQUEST: 'project/EXTEND_REQUEST',
  SUCCESS: 'project/EXTEND_SUCCESS',
  ERROR: 'project/EXTEND_ERROR',
});

export const UNARCHIVE = createActionTypesConfig('project', 'UNARCHIVE');

export const GET_PROJECT_USERS_STATISTICS = createActionTypesConfig(
  'project',
  'GET_PROJECT_USERS_STATISTICS'
);
export const GET_PROJECT_LAYOUT_FILES = createActionTypesConfig(
  'project',
  'GET_PROJECT_LAYOUT_FILES'
);

export const DELETE_PROJECT_LAYOUT_FILES = createActionTypesConfig(
  'project',
  'DELETE_PROJECT_LAYOUT_FILES'
);
export const CREATE_PROJECT_LAYOUT_FILES = createActionTypesConfig(
  'project',
  'CREATE_PROJECT_LAYOUT_FILES'
);

export const PROJECT_LIST_TABS_SET_STATE_COUNTS =
  'project/PROJECT_LIST_TABS_SET_STATE_COUNTS';

export const ASSIGN_TEAM_MEMBER_USER = createActionTypesConfig(
  'project',
  'ASSIGN_TEAM_MEMBER_USER'
);

export const DEASSIGN_TEAM_MEMBER_USER = createActionTypesConfig(
  'project',
  'DEASSIGN_TEAM_MEMBER_USER'
);

export const ASSIGN_TENANT_USER = createActionTypesConfig(
  'project',
  'ASSIGN_TENANT_USER'
);

export const DEASSIGN_TENANT_USER = createActionTypesConfig(
  'project',
  'DEASSIGN_TENANT_USER'
);

export const ASSIGN_BENEFICIARY_USER = createActionTypesConfig(
  'project',
  'ASSIGN_BENEFICIARY_USER'
);

export const DEASSIGN_BENEFICIARY_USER = createActionTypesConfig(
  'project',
  'DEASSIGN_BENEFICIARY_USER'
);

export const ADD_TENANT_LOCATION = createActionTypesConfig(
  'project',
  'ADD_TENANT_LOCATION'
);

export const REMOVE_TENANT_LOCATION = createActionTypesConfig(
  'project',
  'REMOVE_TENANT_LOCATION'
);

export const GET_PROJECTS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const GET_PROJECT_STATISTICS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_STATISTICS.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_STATISTICS.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_STATISTICS.ERROR,
    payload,
    error: true,
  }),
});

export const GET_ASSIGNED_PROJECTS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_ASSIGNED.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_ASSIGNED.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_ASSIGNED.ERROR,
    payload,
    error: true,
  }),
});

export const GET_PROJECT_PUBLIC_INF0_ACTION = createActions(
  GET_PUBLIC_DATA_TYPES
);

export const FIND_PROJECT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_PROJECT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_PROJECT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_PROJECT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});

export const ACTIVATE_PROJECT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: ACTIVATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: ACTIVATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: ACTIVATE.ERROR,
    payload,
    error: true,
  }),
});

export const ARCHIVE_PROJECT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: ARCHIVE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: ARCHIVE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: ARCHIVE.ERROR,
    payload,
    error: true,
  }),
});

export const EXTEND_PROJECT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: EXTEND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: EXTEND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: EXTEND.ERROR,
    payload,
    error: true,
  }),
});

export const UNARCHIVE_PROJECT_ACTION = createActions(UNARCHIVE);

export const GET_PROJECT_USERS_STATISTICS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_PROJECT_USERS_STATISTICS.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_PROJECT_USERS_STATISTICS.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_PROJECT_USERS_STATISTICS.ERROR,
    payload,
    error: true,
  }),
});

export const GET_PROJECT_LAYOUT_FILES_ACTION = createActions(
  GET_PROJECT_LAYOUT_FILES
);

export const DELETE_PROJECT_LAYOUT_FILES_ACTION = createActions(
  DELETE_PROJECT_LAYOUT_FILES
);

export const CREATE_PROJECT_LAYOUT_FILES_ACTION = createActions(
  CREATE_PROJECT_LAYOUT_FILES
);

export const ASSIGN_TEAM_MEMBER_USER_ACTION = createActions(
  ASSIGN_TEAM_MEMBER_USER
);
export const DEASSIGN_TEAM_MEMBER_USER_ACTION = createActions(
  DEASSIGN_TEAM_MEMBER_USER
);

export const ASSIGN_TENANT_USER_ACTION = createActions(ASSIGN_TENANT_USER);
export const DEASSIGN_TENANT_USER_ACTION = createActions(DEASSIGN_TENANT_USER);

export const ASSIGN_BENEFICIARY_USER_ACTION = createActions(
  ASSIGN_BENEFICIARY_USER
);
export const DEASSIGN_BENEFICIARY_USER_ACTION = createActions(
  DEASSIGN_BENEFICIARY_USER
);

export const ADD_TENANT_LOCATION_ACTION = createActions(ADD_TENANT_LOCATION);
export const REMOVE_TENANT_LOCATION_ACTION = createActions(
  REMOVE_TENANT_LOCATION
);
