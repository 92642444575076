export default {
  procurement: {
    itself: 'Procurement',
    plural: {
      itself: 'Procurements',
    },
    attributes: {
      subject: {
        itself: 'Subject',
        '*': 'Subject *',
        required: 'Subject is required',
      },
      description: {
        itself: 'Description',
        '*': 'Description *',
        required: 'Description is required',
        placeholder: 'Describe the issue',
      },
      organization: {
        itself: 'Organization',
        '*': 'Organization *',
        required: 'Organization is required',
      },
      user: {
        itself: 'User',
        '*': 'User *',
        required: 'User is required',
      },
      recipient: {
        itself: 'Recipient',
        '*': 'Recipient *',
        required: 'Recipient is required',
      },
      equipment: {
        itself: 'Equipment',
        '*': 'Equipment *',
        required: 'Equipment is required',
        placeholder: 'Select equipment',
      },
      ticket: {
        itself: 'Ticket',
        '*': 'Ticket *',
        required: 'Ticket is required',
        placeholder: 'Select ticket ID',
      },
      status: {
        new: 'New',
        open: 'Open',
        proposalSubmitted: 'Proposal submitted',
        accepted: 'Accepted',
        rejected: 'Rejected',
        workInProgress: 'Work in progress',
        workFinished: 'Work finished',
        closed: 'Closed',
        all: 'All',
      },
    },
    submitProposal: {
      attributes: {
        proposalFile: {
          itself: 'Proposal file',
          '*': 'Proposal file *',
          required: 'Proposal file is required',
        },
        currency: {
          itself: 'Currency',
          '*': 'Currency *',
          required: 'Currency is required',
        },
        amount: {
          itself: 'Amount',
          '*': 'Amount *',
          required: 'Please enter a  number',
          greaterThanZero: 'Amount must be greater than 0',
        },
      },
    },
    actions: {
      acknowledge: 'Acknowledge',
      markInProgress: 'Mark in progress',
      submitProposal: 'Submit proposal',
      cancelProposal: 'Cancel proposal',
      startWork: 'Start work',
      finish: 'Finish',
    },
    createProcurement: 'Create a procurement',
    closeProcurementOrder: 'Close procurement order',
    confirmModals: {
      cancel: 'No, cancel',
      confirmClose: 'Yes, close!',
      confirmCancel: 'Yes, cancel!',
      confirmCloseMessage:
        'Are you sure you want to close this procurement order?',
      confirmCancelMessage:
        'Are you sure you want to cancel this procurement order?',
      submitReasonClose: 'Please submit a reason for closing ..',
      submitReasonCancel: 'Please submit a reason for canceling ..',
      reasonRequired: 'Reason is required',
      confirmProcurementOrder:
        'Are you sure you want to accept the procurement order?',
      acceptProcurementOrder: 'Accept procurement order',
      rejectProcurementOrder: 'Reject procurement order',
      acceptWork: 'Accept work',
      rejectWork: 'Reject work',
    },
    submittedProposal: 'Submitted Proposal',
  },
};
