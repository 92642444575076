import { useRouteMatch } from 'react-router-dom';
import PATH from '../routes/paths';

const useStandalonePage = () => {
  const draftProjectPageMatch = useRouteMatch(PATH.PROJECT.UPDATE);
  const createPasswordPageMatch = useRouteMatch(PATH.AUTH.CREATE_PASSWORD);
  const ticketPageMatch = useRouteMatch(PATH.PROJECT.TICKET.ONE);
  const procurementPageMatch = useRouteMatch(PATH.PROJECT.PROCUREMENT.ONE);

  return {
    isStandalonePage:
      createPasswordPageMatch?.url ||
      draftProjectPageMatch?.url ||
      ticketPageMatch?.url ||
      procurementPageMatch?.url,
  };
};

export default useStandalonePage;
