import AbstractDataAdapter from '../AbstractDataAdapter';

class DailyProcedureGroupDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return ['name', 'description', 'projectId', 'locationDtos'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return ['name', 'projectId', 'locationDtos'];
  }
}

export default DailyProcedureGroupDataAdapter;
