import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatProjectsConversationActionsCreators } from '../../../../store/chat/general/actions';

class ProjectMessagesChannelsEventCatcher extends AbstractEventCatcher {
  static process({ channels }, dispatch, loggedInUser) {
    dispatch(
      generalChatProjectsConversationActionsCreators.setConversations(
        channels,
        loggedInUser
      )
    );

    dispatch(
      generalChatProjectsConversationActionsCreators.addLastReadMessageToEachConversationHistory(
        channels
      )
    );
  }
}

export default ProjectMessagesChannelsEventCatcher;
