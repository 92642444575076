import { addDays, addHours } from 'date-fns';
import AbstractModel from './AbstractModel';
import { STATUS } from '../../utils/constants';
import UserModel from './UserModel';
import ProjectModel from './Project/ProjectModel';
import BuildingModel from './Building/BuildingModel';
import LevelModel from './Building/LevelModel';
import RoomModel from './Building/RoomModel';
import RoleModel from './RoleModel';
// eslint-disable-next-line import/no-cycle
import ProcurementModel from './ProcurementModel';

export default class TicketModel extends AbstractModel {
  static STATUSES = Object.freeze({
    NEW: 'new',
    OPENED: 'open',
    ON_HOLD: 'onHold',
    RESOLVED: 'resolved',
    CLOSED: 'closed',
    OVERDUE: 'overdue',
    ALL: 'all',
  });

  static READABLE_I18N_STATUSES = Object.freeze({
    [this.STATUSES.NEW]: 'ticket.attributes.status.new',
    [this.STATUSES.OPENED]: 'ticket.attributes.status.opened',
    [this.STATUSES.ON_HOLD]: 'ticket.attributes.status.onHold',
    [this.STATUSES.RESOLVED]: 'ticket.attributes.status.resolved',
    [this.STATUSES.CLOSED]: 'ticket.attributes.status.closed',
    [this.STATUSES.OVERDUE]: 'ticket.attributes.status.overdue',
    [this.STATUSES.ALL]: 'ticket.attributes.status.all',
  });

  static PRIORITIES = Object.freeze({
    NO_PRIORITY: 0,
    LOW: 10,
    MEDIUM: 20,
    HIGH: 30,
    CRITICAL: 40,
  });

  static DUE_DATES_BY_PRIORITY = Object.freeze({
    10: () => addDays(new Date(), 7),
    20: () => addDays(new Date(), 1),
    30: () => addHours(new Date(), 12),
    40: () => addHours(new Date(), 2),
  });

  static authUserCanEditTickets = (authUser) => {
    return RoleModel.authUserIsAtLeastAssistantPM(authUser);
  };

  constructor(...params) {
    super(...params);
    if (this.status !== STATUS.LOADING) {
      this.createdAt &&
        this.overwriteProperty('createdAt', {
          get() {
            return this._createdAt ? new Date(this._createdAt) : null;
          },
        });
      this.updatedAt &&
        this.overwriteProperty('updatedAt', {
          get() {
            return this._updatedAt ? new Date(this._updatedAt) : null;
          },
        });
      this.openedAt &&
        this.overwriteProperty('openedAt', {
          get() {
            return this._openedAt ? new Date(this._openedAt) : null;
          },
        });
      this.dueDate &&
        this.overwriteProperty('dueDate', {
          get() {
            return this._dueDate ? new Date(this._dueDate) : null;
          },
        });
      this.creator = new UserModel(this.creator);
      this.recipient = new UserModel(this.recipient);
      this.project = new ProjectModel(this.project);
      if (this.building) {
        this.building = new BuildingModel(this.building);
      }
      if (this.buildingLevel) {
        this.buildingLevel = new LevelModel(this.buildingLevel);
      }
      if (this.buildingRooms) {
        this.buildingRooms = this.buildingRooms.map(
          (buildingRoom) => new RoomModel(buildingRoom)
        );
      }
      if (this.procurements) {
        this.procurements = this.procurements.map(
          (procurement) => new ProcurementModel(procurement)
        );
      }

      if (Array.isArray(this.externalChat?.chatParticipants)) {
        this.externalChat = {
          ...this.externalChat,
          chatParticipants: this.externalChat.chatParticipants.map(
            (participant) => ({
              ...participant,
              user: new UserModel(participant.user),
            })
          ),
        };
      }
      if (Array.isArray(this.internalChat?.chatParticipants)) {
        this.internalChat = {
          ...this.externalChat,
          chatParticipants: this.internalChat.chatParticipants.map(
            (participant) => ({
              ...participant,
              user: new UserModel(participant.user),
            })
          ),
        };
      }
    }
  }

  convertAttribute(attribute) {
    if (attribute === 'status') {
      return 'ticketStatus';
    }
    return super.convertAttribute(attribute);
  }
}
