import { getI18n } from 'react-i18next';
import i18n from 'i18next';
import { LANGUAGES } from './config';

const translate = (key) => {
  return getI18n().translator.translate(key);
};

export const changeLanguage = (language) => {
  if (!Object.values(LANGUAGES).includes(language)) {
    throw new Error('pass correct language!');
  }

  return i18n.changeLanguage(language);
};

export default translate;
