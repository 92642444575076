import AbstractDataAdapter from './AbstractDataAdapter';

class SubcontractorDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return [
      'companyName',
      'description',
      'websiteUrl',
      'addressOne',
      'addressTwo',
      'postalCode',
      'countryId',
      'cityId',
      'subcontractorCategoriesIds',
      'organizationId',
      'contacts',
    ];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return [
      'companyName',
      'websiteUrl',
      'addressOne',
      'postalCode',
      'countryId',
      'cityId',
      'subcontractorCategoriesIds',
      'organizationId',
      'contacts',
    ];
  }
}

export default SubcontractorDataAdapter;
