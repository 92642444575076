export default {
  layout: {
    itself: 'Plan arhitectură',
    sidebar: {
      title: 'Fișiere',
      noFilesYet: 'No files yet',
    },
    exportLayoutData: 'Exportați plan cladire',
  },
};
