export default {
  standardProcedure: {
    plural: {
      itself: 'Proceduri standard',
    },
    createNewProcedure: 'Creează o procedură nouă',
    editProcedure: 'Modifică procedura',
    saveProcedure: 'Salvează procedura',
    saveNewProcedure: 'Salvează procedură nouă',
    emptyStandardProcedureList:
      'Apăsați butonul albastru plus și începeți să adăugați proceduri',
    modal: {
      confirmDeleteMessage: `Ești sigur? <br /> <br /> Vă rugăm să fiți conștient că această acțiune nu poate fi anulată. Continuarea va șterge permanent procedura standard, precum și toate operațiunile asociate.`,
    },
    attributes: {
      category: {
        itself: 'Categorie',
        '*': 'Categorie *',
        required: 'Categoria e obligatorie',
        select: 'Selectează categorie',
      },
      group: {
        itself: 'Grup',
        '*': 'Grup *',
        required: 'Grupul e obligatoriu',
        select: 'Selectează grup',
      },
      frequency: {
        itself: 'Frecvență',
        '*': 'Frecvență *',
        required: 'Frecvență is required',
      },
      type: {
        itself: 'Tip',
        '*': 'Tip *',
        required: 'Tip is required',
      },
      subtype: {
        itself: 'Subtip',
        '*': 'Subtip *',
        required: 'Subtip is required',
      },
    },
  },
};
