import { toast } from 'react-toastify';
import i18n from 'i18next';

class Alert {
  static alertTextFormatter = (text) => i18n.t(text);

  static alertService;

  static DEFAULT_ALERT_CONFIG = Object.freeze({
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  static setAlertService(alertService) {
    this.alertService = alertService;
  }

  static showAlert(showAlertFunc, text, customConfig = {}) {
    showAlertFunc(this.alertTextFormatter(text), {
      toastId: text,
      ...this.DEFAULT_ALERT_CONFIG,
      ...customConfig,
    });
  }

  static showInfo(text, customConfig = {}) {
    this.showAlert(
      this.alertService.info.bind(this.alertService),
      text,
      customConfig
    );
  }

  static showError(text, customConfig = {}) {
    this.showAlert(
      this.alertService.error.bind(this.alertService),
      text,
      customConfig
    );
  }

  static showWarn(text, customConfig = {}) {
    this.showAlert(
      this.alertService.warn.bind(this.alertService),
      text,
      customConfig
    );
  }

  static showSuccess(text, customConfig = {}) {
    this.showAlert(
      this.alertService.success.bind(this.alertService),
      text,
      customConfig
    );
  }
}

Alert.setAlertService(toast);

export default Alert;
