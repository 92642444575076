export default {
  building: {
    itself: 'Clădire',
    plural: {
      itself: 'Clădiri',
    },
    buildingName: {
      itself: 'Nume clădire',
      required: 'Trebuie să puneți un nume clădirii înainte!',
    },
    modalDeleteMessage:
      'Ești sigur că vrei să ștergi aceasta clădire și toate informațiile asociate?',
    allBuildings: 'Toate clădirile',
    allLevels: 'Toate nivelurile',
    allRooms: 'Toate camerele',
    addBuilding: 'Adaugă o clădire',
    editBuilding: 'Modifică clădire',
  },
};
