import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialUiTextField from '@material-ui/core/TextField';
import IdIcon from '../Icons/IdIcon';

const StyledTextField = styled(MaterialUiTextField)`
  .MuiInputLabel-root {
    transform: translate(15px, 13px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }

  .MuiOutlinedInput-root {
    & input::placeholder {
      color: ${({ theme }) => theme.palette.themeCommon.gray};
      opacity: 1;
    }
    &.Mui-disabled input::placeholder {
      opacity: 0.5;
    }
  }
`;

const StyledIcon = styled(IdIcon)`
  margin-left: 3px;
  margin-right: 19px;
`;

const StyledTextFieldWrapper = styled('div')`
  align-items: center;
  display: flex;
`;

const StyledInputLabel = styled('p')`
  color: ${({ theme }) => theme.palette.gray[1900]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.6rem;
  margin: 0 1.5rem 0 0;
`;

const LabelContent = styled.div`
  display: flex;
  ${({ width }) => `width: ${width || 'auto'}`};
`;

export default function TextField({
  loading,
  id,
  label,
  placeholder,
  showLabel,
  labelWidth,
  InputProps,
  InputLabelProps,
  wrapperStyles,
  ...props
}) {
  return (
    <StyledTextFieldWrapper style={wrapperStyles}>
      {showLabel ? (
        <LabelContent width={labelWidth}>
          <StyledIcon size="regular" color="gray" />
          <StyledInputLabel {...InputLabelProps}> {label}</StyledInputLabel>
        </LabelContent>
      ) : undefined}
      <StyledTextField
        {...props}
        id={id}
        placeholder={placeholder}
        variant="outlined"
        InputProps={{
          ...InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {InputProps?.endAdornment}
            </>
          ),
        }}
      />
    </StyledTextFieldWrapper>
  );
}

TextField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  id: PropTypes.string,
  showLabel: PropTypes.bool,
  InputProps: PropTypes.objectOf(PropTypes.any),
  InputLabelProps: PropTypes.objectOf(PropTypes.any),
  labelWidth: PropTypes.string,
  wrapperStyles: PropTypes.objectOf(PropTypes.any),
};

TextField.defaultProps = {
  disabled: false,
  id: `text-field-${Math.random()}`,
  label: 'label',
  loading: false,
  placeholder: '',
  showLabel: false,
  InputProps: undefined,
  InputLabelProps: undefined,
  labelWidth: '',
  wrapperStyles: undefined,
};
