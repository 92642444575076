import { useEffect, useRef } from 'react';

export const useWillMount = (callback) => {
  const willMount = useRef(true);
  if (willMount.current) {
    callback();
  }
  willMount.current = false;
};

export const useDidMount = (callback) => useEffect(callback, []);

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useWillUnmount = (callback) => useEffect(() => callback, []);

export const useDidUpdate = (callback) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      callback();
    }
  });
};

export const useHasUnmounted = () => {
  const hasUnmounted = useRef(false);
  useWillUnmount(() => {
    hasUnmounted.current = true;
  });
  return hasUnmounted;
};

export const useHasMounted = () => {
  const hasMounted = useRef(false);
  useDidMount(() => {
    hasMounted.current = true;
  });
  return hasMounted;
};

export const useSkipFirstRun = (callback, dependencies) => {
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
