const GENERAL_CHAT_EVENTS = Object.freeze({
  DIRECT_MESSAGES: {
    GET_CHAT_CHANNELS: 'getChats',
    GET_CHAT_HISTORY: 'getChat',
    CREATE_CONVERSATION: 'createChat',
    UPDATE_CONVERSATION: 'chatUpdated',
    SEND_MESSAGE: 'sendMessage',
    REMOVE_MESSAGE: 'removeMessage',
    CONVERSATION_ALREADY_EXIST: 'chatAlreadyExist',
    CONVERSATION_UNREAD_MESSAGES_IDS_UPDATED: 'chatUnreadMessagesIdsUpdated',
    REMOVE_CONVERSATION: 'deleteChat',
    REMOVE_GROUP_CONVERSATION_PARTICIPANT: 'removeParticipant',
    ADD_GROUP_CONVERSATION_PARTICIPANT: 'addParticipantToChat',
    NOTIFICATION: 'chatNotification',
  },
  PROJECT_MESSAGES: {
    GET_CHAT_CHANNELS: 'getProjectChats',
    GET_CHAT_HISTORY: 'getProjectChat',
    UPDATE_CONVERSATION: 'projectChatUpdated',
    SEND_MESSAGE: 'sendProjectChatMessage',
    REMOVE_MESSAGE: 'removeProjectChatMessage',
    CONVERSATION_UNREAD_MESSAGES_IDS_UPDATED: 'projectUnreadMessagesIdsUpdated',
    GET_BENEFICIARY_CHAT_HISTORY: 'getProjectBeneficiaryHistoryChat',
    GET_BENEFICIARY_CONVERSATION: 'getProjectBeneficiaryChat',
    NOTIFICATION: 'projectChatNotification',
  },
});

export default GENERAL_CHAT_EVENTS;
