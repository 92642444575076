import React, { useState } from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function EditIcon({ size, color, hoverDisabled, hoverBgColor, ...rest }) {
  const [fillColor, setFillColor] = useState(undefined);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      onMouseEnter={() => {
        setFillColor(hoverBgColor);
      }}
      onMouseLeave={() => {
        setFillColor(undefined);
      }}
    >
      {hoverDisabled ? undefined : (
        <path d="M4 17V14L12 6L15 3L18 6L15 9L7 17H4Z" fill={fillColor} />
      )}
      <path
        d="M4 21H18M12 6L4 14V17H7L15 9M12 6L15 3L18 6L15 9M12 6L15 9"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(EditIcon);
