import { createActions, createActionTypesConfig } from '../helpers';

const SUBCONTRACTOR_LABEL = 'subcontractor';

export const GET = createActionTypesConfig(SUBCONTRACTOR_LABEL, 'GET');
export const GET_BY_PROJECT_ID = createActionTypesConfig(
  SUBCONTRACTOR_LABEL,
  'GET_BY_PROJECT'
);
export const FIND = createActionTypesConfig(SUBCONTRACTOR_LABEL, 'FIND');
export const CREATE = createActionTypesConfig(SUBCONTRACTOR_LABEL, 'CREATE');
export const UPDATE = createActionTypesConfig(SUBCONTRACTOR_LABEL, 'UPDATE');
export const DELETE = createActionTypesConfig(SUBCONTRACTOR_LABEL, 'DELETE');

export const GET_SUBCONTRACTORS_ACTION = createActions(GET);
export const GET_SUBCONTRACTORS_BY_PROJECT_ID_ACTION = createActions(
  GET_BY_PROJECT_ID
);
export const FIND_SUBCONTRACTOR_ACTION = createActions(FIND);
export const CREATE_SUBCONTRACTOR_ACTION = createActions(CREATE);
export const UPDATE_SUBCONTRACTOR_ACTION = createActions(UPDATE);
export const DELETE_SUBCONTRACTOR_ACTION = createActions(DELETE);
