import { useCallback, useState } from 'react';
import {
  FileRequest,
  fileRequest,
} from '../../api/requestWrappers/FileRequest';
import { useDidMount, useWillUnmount } from '../lifecycleHooks';
import eventBus, { EVENT_LIST } from '../../utils/eventBus/eventBus';

const useUserProfilePhotoUpdate = (observedUserId) => {
  const [updatedProfilePhoto, setUpdatedProfilePhoto] = useState({
    filePath: null,
    updated: false,
  });

  const handleUserProfilePhotoUpdated = useCallback((userId, fileUuid) => {
    if (userId === observedUserId) {
      setUpdatedProfilePhoto({
        filePath: fileUuid
          ? fileRequest.getFilePath(
              fileUuid,
              FileRequest.THUMBNAIL_SIZES.SQUARE_176_PX
            )
          : undefined,
        updated: true,
      });
    }
  }, []);

  useDidMount(() => {
    eventBus.$on(
      EVENT_LIST.COMPONENTS.USER_FORM.USER_PROFILE_PHOTO_UPDATED,
      handleUserProfilePhotoUpdated
    );
  });
  useWillUnmount(() => {
    eventBus.$off(
      EVENT_LIST.COMPONENTS.USER_FORM.USER_PROFILE_PHOTO_UPDATED,
      handleUserProfilePhotoUpdated
    );
  });

  return { updatedProfilePhoto };
};

export default useUserProfilePhotoUpdate;
