export const GET = Object.freeze({
  REQUEST: 'maintenanceParameter/GET_REQUEST',
  SUCCESS: 'maintenanceParameter/GET_SUCCESS',
  ERROR: 'maintenanceParameter/GET_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'maintenanceParameter/FIND_REQUEST',
  SUCCESS: 'maintenanceParameter/FIND_SUCCESS',
  ERROR: 'maintenanceParameter/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'maintenanceParameter/CREATE_REQUEST',
  SUCCESS: 'maintenanceParameter/CREATE_SUCCESS',
  ERROR: 'maintenanceParameter/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'maintenanceParameter/UPDATE_REQUEST',
  SUCCESS: 'maintenanceParameter/UPDATE_SUCCESS',
  ERROR: 'maintenanceParameter/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'maintenanceParameter/DELETE_REQUEST',
  SUCCESS: 'maintenanceParameter/DELETE_SUCCESS',
  ERROR: 'maintenanceParameter/DELETE_ERROR',
});

export const RESET_STATE = 'maintenanceParameter/RESET_STATE';

export const GET_MAINTENANCE_PARAMETERS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_MAINTENANCE_PARAMETER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_MAINTENANCE_PARAMETER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_MAINTENANCE_PARAMETER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_MAINTENANCE_PARAMETER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});
