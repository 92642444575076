import AbstractRealTimeProvider from './AbstractRealTimeProvider';
import RealTimeMessageModel from '../models/RealTimeMessageModel';
import AuthClientStore from '../../clientStore/AuthClientStore';

const RECONNECT_TIME_MS = 1500;

class RealTimeProvider extends AbstractRealTimeProvider {
  _Client;

  _client;

  _actions;

  static CLOSE_CASES = Object.freeze({
    CONNECTED_COMPONENT_DESTROYED: 'connectedComponentDestroyed',
    CLOSE_TO_RECONNECT: 'closeToReconnect',
  });

  static ALLOW_RECONNECT_BY_CLOSE_CASE = Object.freeze({
    [RealTimeProvider.CLOSE_CASES.CONNECTED_COMPONENT_DESTROYED]: false,
    [RealTimeProvider.CLOSE_CASES.CLOSE_TO_RECONNECT]: true,
  });

  get status() {
    return this._client?.readyState;
  }

  get isConnectionExisting() {
    return !!this._client;
  }

  get isConnectionOpened() {
    if (!this.isConnectionExisting) {
      return false;
    }

    return this.status === AbstractRealTimeProvider.STATUS.OPEN;
  }

  get isConnectionClosed() {
    if (!this.isConnectionExisting) {
      return false;
    }

    return [
      AbstractRealTimeProvider.STATUS.CLOSED,
      AbstractRealTimeProvider.STATUS.CLOSING,
    ].includes(this.status);
  }

  /**
   *
   * @param {Object} Client
   * @param {Object} actions
   * @param {Function} actions.onMessage
   * @param {Function} actions.onError
   * @param {Function} actions.onClose
   * @param {Function} actions.onOpen
   */
  constructor(Client, actions) {
    super();

    this._Client = Client;
    this._actions = actions;
  }

  _subscribeToOpening() {
    if (!this.isConnectionExisting) {
      return;
    }

    this._client.onopen = (event) => {
      // eslint-disable-next-line no-console
      console.log('Socket is opened', event);

      this._actions?.onOpen?.(event);
    };
  }

  _subscribeToMessages() {
    if (!this.isConnectionExisting) {
      return;
    }

    this._client.onmessage = (event) => this._actions?.onMessage?.(event);
  }

  _subscribeToClosing() {
    if (!this.isConnectionExisting) {
      return;
    }

    this._client.onclose = (event) => {
      const reconnectOnClose =
        !event.wasClean ||
        RealTimeProvider.ALLOW_RECONNECT_BY_CLOSE_CASE[event.reason];

      // eslint-disable-next-line no-console
      console.warn(
        `Socket is closed. Reconnecting ${
          reconnectOnClose ? 'enabled' : 'disabled'
        }`,
        event
      );

      this._actions?.onClose?.(event);

      if (reconnectOnClose) {
        setTimeout(() => {
          this.connect(`Connection is restarting.`);
        }, RECONNECT_TIME_MS);
      }
    };
  }

  _subscribeToErrors() {
    if (!this.isConnectionExisting) {
      return;
    }

    this._client.onerror = (error) => {
      // eslint-disable-next-line no-console
      console.error('Socket encountered error: ', error, 'Closing socket');

      this._actions?.onError?.(error);

      this.close();
    };
  }

  connect(message) {
    // eslint-disable-next-line no-console
    console.log(message || 'Connection is starting');

    this._client = new this._Client(
      this.wsConnectionPath,
      AuthClientStore.getToken()
    );

    this._subscribeToOpening();

    this._subscribeToMessages();

    this._subscribeToClosing();

    this._subscribeToErrors();
  }

  send(data) {
    if (!this.isConnectionExisting) {
      return;
    }

    if (!this.isConnectionOpened) {
      return;
    }

    RealTimeMessageModel.checkInstance(data);

    this._client.send(data.JSON);
  }

  close(
    closeCase = RealTimeProvider.CLOSE_CASES.CONNECTED_COMPONENT_DESTROYED
  ) {
    if (!this.isConnectionExisting) {
      return;
    }

    if (!Object.values(RealTimeProvider.CLOSE_CASES).includes(closeCase)) {
      throw new Error('Invalid closeCase');
    }

    this._client.close(RealTimeProvider.CLOSE_CODES.NORMAL, closeCase);
  }

  waitForOpenedStatus() {
    const DELAY_MS = 10;
    const DELAY_MAX_MS = 1000 * 60;

    let delaySum = DELAY_MS;

    return new Promise((resolve, reject) => {
      const intervalId = setInterval(() => {
        if (this.isConnectionOpened) {
          resolve();

          clearInterval(intervalId);
        } else if (this.isConnectionClosed) {
          reject();

          clearInterval(intervalId);
        } else {
          if (delaySum === DELAY_MAX_MS) {
            reject();

            // eslint-disable-next-line no-console
            console.warn(
              'It is not possible to connect to the real-time client'
            );

            clearInterval(intervalId);
          }

          delaySum += DELAY_MS;
        }
      }, DELAY_MS);
    });
  }
}

export default RealTimeProvider;
