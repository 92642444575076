import sortChatHistory from '../../helpers/sortChatHistory';
import { getUniqueArrayOfObjects } from '../../../../utils/helpers';

export default (state, action, config) => {
  const currentResource =
    state[config.propName][action.payload.resourceId] || {};
  const currentResourceChats = currentResource.chats || {};
  const currentChat = currentResourceChats[action.payload.chatId] || {};
  const currentHistory = currentChat.history || [];

  return {
    ...state,
    [config.propName]: {
      ...state[config.propName],
      [action.payload.resourceId]: {
        ...currentResource,
        chats: {
          ...currentResourceChats,
          [action.payload.chatId]: {
            ...currentChat,
            history: sortChatHistory(
              getUniqueArrayOfObjects(
                [
                  ...currentHistory,
                  config.messageModelCreator(
                    action.payload.message,
                    currentChat.chatInfo
                  ),
                ],
                'id'
              )
            ),
          },
        },
      },
    },
  };
};
