import createChatChannelItemModel from '../../helpers/createChatChannelItemModel';
import ChatParticipantModel from '../../../../realTimeApi/models/ChatParticipantModel';

export default (state, action, config) => {
  const currentResource =
    state[config.propName][action.payload.resourceId] || {};
  const currentResourceChats = currentResource.chats || {};
  const currentChat = currentResourceChats[action.payload.chatId] || {};

  return {
    ...state,
    [config.propName]: {
      ...state[config.propName],
      [action.payload.resourceId]: {
        ...currentResource,
        chats: {
          ...currentResourceChats,
          [action.payload.chatId]: {
            ...currentChat,
            chatInfo: createChatChannelItemModel(
              action.payload.chatInfo,
              action.payload.loggedInUser
            ),
            participants: action.payload.chatInfo?.chatParticipants?.map(
              (participant) => new ChatParticipantModel(participant)
            ),
          },
        },
      },
    },
  };
};
