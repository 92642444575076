import AbstractDataAdapter from '../AbstractDataAdapter';

class MaintenanceParameterDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get createAllowedFields() {
    return ['name', 'unitId', 'minValue', 'maxValue'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get createRequiredFields() {
    return ['name', 'unitId'];
  }

  /**
   * @todo remove operationId if bulk will disappear or it will no longer use it
   * @returns {string[]}
   */
  static get updateAllowedFields() {
    return ['id', 'name', 'unitId', 'minValue', 'maxValue', 'operationId'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get updateRequiredFields() {
    return ['id', 'name', 'unitId'];
  }
}

export default MaintenanceParameterDataAdapter;
