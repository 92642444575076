import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import i18n from 'i18next';
import TextField from '../../stories/ui/TextField/TextField';
import Button from '../../stories/ui/Button/Button';
import Tooltip from '../../stories/ui/Tooltip/Tooltip';
import withRef from '../../hocs/withRef';
import RedQuestionIcon from '../../stories/ui/Icons/RedQuestionIcon';
import {
  authUserHasOtpSelector,
  authUserOtpPasswordSelector,
} from '../../store/auth/selectors';
import { setPassword as setPasswordAction } from '../../store/auth/actions';
import PATH from '../../routes/paths';
import withHelmet from '../../hocs/withHelmet';
import { LoginLogo } from './Login';

const ErrorIconWithRef = withRef(RedQuestionIcon);

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.gray[100]};
`;

const LoginContainer = styled.div`
  width: 340px;
  height: 320px;
  background-color: ${({ theme }) => theme.palette.themeCommon.white.main};
  border-radius: 0.5rem;
  padding: 0 1.5rem;
`;

const LoginHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 72px;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 3rem);
    bottom: 0;
    left: -1.5rem;
    right: -1.5rem;
    border-bottom: 1px solid
      ${({ theme }) => theme.palette.themeCommon.black.light};
  }
`;

const StyledForm = styled(Form)`
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const PasswordTextField = styled(StyledTextField)`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.themeCommon.black.light};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  flex: 0 0 47%;
`;

const StyledSubmitButton = styled(StyledButton)`
  padding: 0.5rem 2.5rem;
`;

const useTextFieldErrorClasses = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.themeCommon.red.light,
  },
}));

function CreatePassword({ authUserHasOtp, authUserOtpPassword, setPassword }) {
  const { t } = useTranslation();
  const textFieldErrorClasses = useTextFieldErrorClasses();
  const history = useHistory();
  const [createPasswordIsLoading, setCreatePasswordIsLoading] = useState(false);

  const CreatePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
      .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
      .matches(/\d/, 'Password must have a number')
      .matches(
        /[!@#$%^&*()\-_"=+{}; :,<.>]/,
        'Password must have a special character'
      )
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required(t('passwordRequired')),
    repeatPassword: Yup.string()
      .when('password', {
        is: (val) => val && val.length > 0,
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          'Passwords must be the same'
        ),
      })
      .required(t('passwordRequired')),
  });

  return (
    <PageContainer>
      <LoginLogo />
      <LoginContainer>
        <LoginHeader>
          <Trans i18nKey="createStrongPassword" />
        </LoginHeader>

        <Formik
          initialValues={{
            password: '',
            repeatPassword: '',
          }}
          validationSchema={CreatePasswordSchema}
          onSubmit={(values) => {
            setCreatePasswordIsLoading(true);
            setPassword(authUserOtpPassword, values.password)
              .then(() => history.push(PATH.DASHBOARD))
              .catch(() => setCreatePasswordIsLoading(false));
          }}
        >
          {() => (
            <StyledForm>
              <Field name="password">
                {({ field, meta }) => (
                  <>
                    <StyledTextField
                      type="password"
                      id="password-text-field"
                      placeholder={t('newPassword')}
                      {...field}
                      InputProps={
                        meta.touched && meta.error
                          ? {
                              endAdornment: (
                                <Tooltip title={meta.error} color="danger" open>
                                  <ErrorIconWithRef />
                                </Tooltip>
                              ),
                              classes: textFieldErrorClasses,
                            }
                          : undefined
                      }
                    />
                  </>
                )}
              </Field>
              <Field name="repeatPassword">
                {({ field, meta }) => (
                  <>
                    <PasswordTextField
                      type="password"
                      id="repeat-password-text-field"
                      placeholder={t('repeatNewPassword')}
                      {...field}
                      InputProps={
                        meta.touched && meta.error
                          ? {
                              endAdornment: (
                                <Tooltip title={meta.error} color="danger" open>
                                  <ErrorIconWithRef />
                                </Tooltip>
                              ),
                              classes: textFieldErrorClasses,
                            }
                          : undefined
                      }
                    />
                  </>
                )}
              </Field>
              <ButtonsContainer>
                <StyledButton
                  color="primary"
                  size="regular"
                  onClick={() => history.push(PATH.DASHBOARD)}
                  disabled={authUserHasOtp}
                >
                  <Trans i18nKey="back" />
                </StyledButton>
                <StyledSubmitButton
                  color="primary"
                  size="large"
                  type="submit"
                  isLoading={createPasswordIsLoading}
                >
                  <Trans i18nKey="save" />
                </StyledSubmitButton>
              </ButtonsContainer>
            </StyledForm>
          )}
        </Formik>
      </LoginContainer>
    </PageContainer>
  );
}

const mapStateToProps = (state) => ({
  authUserHasOtp: authUserHasOtpSelector(state),
  authUserOtpPassword: authUserOtpPasswordSelector(state),
});

const mapDispatchToProps = {
  setPassword: setPasswordAction,
};

CreatePassword.propTypes = {
  authUserHasOtp: PropTypes.bool.isRequired,
  authUserOtpPassword: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
};

const wrappers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHelmet(i18n.t('metaTitles.createPassword'))
);

export default wrappers(CreatePassword);
