import NotificationModel from '../../../../api/models/NotificationModel';
import ChatParticipantModel from '../../../../realTimeApi/models/ChatParticipantModel';
import ChatModel from '../../../../realTimeApi/models/ChatModel';
import AbstractDirectMessagesChannelEventCatcher from '../../../../realTimeApi/eventCatchers/chat/directMessages/AbstractDirectMessagesChannelEventCatcher';
import PATH from '../../../../routes/paths';
import {
  channelIdPattern,
  projectIdPattern,
} from '../../../../routes/replacePatterns';

/* eslint default-case: 0 */
/* eslint no-param-reassign: 0 */

const getDescription = (message, files, prefix = '') => {
  if (message && files?.length) {
    return `${prefix} ${message} | Files (${files.length})`;
  }

  if (message) {
    return `${prefix} ${message}`;
  }

  if (files?.length) {
    return `${prefix} Files (${files.length})`;
  }

  return `${prefix} --`;
};

const getFullDescription = (message, files, chatType) => {
  switch (chatType) {
    case ChatModel.PROJECT_CHAT_TYPE.STAFF_CHAT: {
      return getDescription(message, files, 'Staff:');
    }
    case ChatModel.PROJECT_CHAT_TYPE.BENEFICIARY_CHAT: {
      return getDescription(message, files, 'Beneficiary:');
    }
    case ChatModel.PROJECT_CHAT_TYPE.MANAGEMENT_CHAT: {
      return getDescription(message, files, 'Management:');
    }
    default:
      return getDescription(message, files);
  }
};

const getPath = (chatId, chatType, projectIdOrigin) => {
  const projectId =
    AbstractDirectMessagesChannelEventCatcher.getProjectIdFromUrl(
      window.location.pathname
    ) || projectIdOrigin;

  if (Object.values(ChatModel.PROJECT_CHAT_TYPE).includes(chatType)) {
    return projectId
      ? PATH.PROJECT.CHAT.CHANNEL.ONE.replace(
          projectIdPattern,
          projectId
        ).replace(channelIdPattern, chatId)
      : PATH.CHAT.PROJECT_CHANNEL.ONE.replace(channelIdPattern, chatId);
  }

  return AbstractDirectMessagesChannelEventCatcher.getMessagesOneViewPath(
    projectId,
    chatId
  );
};

/**
 *
 * @param {NotificationModel} notificationModel
 */
const setChatNotificationMissingFields = (notificationModel) => {
  const {
    chatId,
    message,
    user,
    projectName,
    projectId,
  } = notificationModel.metadata;

  if (![chatId, message, user].every((value) => !!value)) {
    return notificationModel;
  }

  const sender = new ChatParticipantModel(user);

  notificationModel.notificationUiType =
    NotificationModel.NOTIFICATION_TYPES.INCOMING_MESSAGE;

  if (
    Object.values(ChatModel.PROJECT_CHAT_TYPE).includes(message?.chat?.type)
  ) {
    notificationModel.notificationUiType =
      NotificationModel.NOTIFICATION_TYPES.CUSTOM;
    notificationModel.customTypeTitle = projectName;
  }

  notificationModel.description = getFullDescription(
    message.message,
    message.files,
    message.chat.type
  );

  notificationModel.name = sender.user?.fullName;

  notificationModel.resourceMetaData = {
    resourceId: chatId,
    resourceType: 'generalChat',
    path: getPath(chatId, message.chat.type, projectId),
  };

  return notificationModel;
};

export default setChatNotificationMissingFields;
