import ApiResourceRequest, { API_METHODS } from './ApiResourceRequest';
import { required } from '../../utils/helpers';
import { FileRequest } from './FileRequest';
import { DOCUMENT_FILE_EXTENSIONS } from '../../utils/constants';

export class ProjectLayoutRequest extends ApiResourceRequest {
  get apiResource() {
    return `projects/${this.dynamicIds.PROJECT_ID}/layout`;
  }

  get dynamicIds() {
    return {
      PROJECT_ID: '{projectId}',
      FILE_ID: '{fileId}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      GET_LAYOUT_FILES: `${this.apiRoute}/files`,
      DELETE_LAYOUT_FILES: `${this.apiRoute}/${this.dynamicIds.FILE_ID}`,
      UPLOAD_LAYOUT_FILES: `${this.apiRoute}/files`,
      GET_REPORT_FILE: `${this.apiRoute}/generate`,
    };
  }

  async getLayoutFiles(projectId) {
    required({ projectId });

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_LAYOUT_FILES.replace(
        `${this.dynamicIds.PROJECT_ID}`,
        projectId
      )
    );

    return response?.data;
  }

  async createLayoutFiles(projectId, filesIds) {
    required({ projectId, filesIds });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.UPLOAD_LAYOUT_FILES.replace(
        `${this.dynamicIds.PROJECT_ID}`,
        projectId
      ),
      {
        filesIds,
      }
    );

    return response?.data;
  }

  async deleteLayoutFiles(projectId, fileId) {
    required({ projectId, fileId });

    const response = await this.request(
      API_METHODS.DELETE,
      this.routes.DELETE_LAYOUT_FILES.replace(
        `${this.dynamicIds.PROJECT_ID}`,
        projectId
      ).replace(`${this.dynamicIds.FILE_ID}`, fileId)
    );

    return response?.data;
  }

  async getReportFile(projectId, query = {}) {
    required({
      projectId,
    });
    const queryString = this.buildQueryString(
      this.getExtraQueryParams({
        ...(query.extra || {}),
      }),
      this.getFilterParams(query.filter, true)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_REPORT_FILE.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      ) + queryString,
      {},
      {
        responseType: 'blob',
      }
    );

    return response.data;
  }

  async downloadReportFile(projectId, fileName, query = {}) {
    required({ projectId, fileName });

    const reportBlob = await this.getReportFile(projectId, query);

    return FileRequest.downloadFileViaLink(reportBlob, {
      fileName,
      fileExtension: DOCUMENT_FILE_EXTENSIONS.excel,
    });
  }
}

export const projectLayoutRequest = new ProjectLayoutRequest();
