export default {
  level: {
    itself: 'Nivel',
    plural: {
      itself: 'Niveluri',
    },
    levelName: {
      itself: 'Nume nivel',
      required: 'Trebuie să puneți un nume nivelului înainte!',
    },
    sublevel: {
      itself: 'Subnivel',
    },
    sharedLevel: {
      itself: 'Nivel comun',
    },
    duplicateLevel: {
      itself: 'Duplică nivel',
    },
    modalDeleteMessage:
      'Ești sigur că vrei să ștergi acest nivel și toate informațiile asociate?',
    addLevel: 'Adaugă un nivel',
    editLevel: 'Modifică nivel',
  },
};
