class AbstractEventCatcher {
  static process(data) {
    // eslint-disable-next-line no-console
    console.log(`default process method`, data);

    /**
     * this.doSomething1()
     * this.doSomething2()
     * this.doSomething3()
     * */
  }
}

export default AbstractEventCatcher;
