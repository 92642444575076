import AbstractModel from '../AbstractModel';
import { STATUS } from '../../../utils/constants';
import MaintenanceOperationModel from './MaintenanceOperationModel';

class MaintenanceProcedureModel extends AbstractModel {
  constructor(...params) {
    super(...params);
    if (this.status !== STATUS.LOADING) {
      if (Array.isArray(this.operations)) {
        this.maintenanceProcedureOperations = this.operations.map(
          (operation) => new MaintenanceOperationModel({ ...operation })
        );
      }

      this.overwriteProperty('startDate', {
        get() {
          return this._startDate ? new Date(this._startDate) : null;
        },
      });
    }
  }

  convertAttribute(attribute) {
    if (attribute === 'type') {
      return 'procedureType';
    }
    return super.convertAttribute(attribute);
  }
}

export default MaintenanceProcedureModel;
