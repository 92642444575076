export default {
  dailyCheckTour: {
    dailyCheck: 'Daily check',
    startDailyCheck: 'Start daily check',
    closeDailyCheck: 'Close daily check',
    emptyDailyCheckTourList: 'No daily check has been defined for the project',
    dailyCheckInProgressWarning:
      'There is a daily check in progress, please close it in order to start a new one',
    dailyCheckNoGroupsWarning:
      'You must define daily check groups and procedures',
    options: {
      startDailyCheck: 'Start daily check',
    },
    emptyDailyCheckTourGroupList:
      'No daily check group has been found for this tour.',
    dailyCheckHasNoEvents: 'Daily check started has no events',
    tableColumns: {
      date: 'Date',
      status: 'Status',
      startTime: 'Start time',
      endTime: 'End time',
      procedures: 'Procedures',
      completed: 'Completed',
      alerts: 'Alerts',
    },
    close: 'Close',
    attributes: {
      comment: {
        '*': 'Comment *',
        itself: 'Comment',
        required: 'Comment is required',
        placeholder: 'Write a comment',
      },
      file: {
        plural: {
          itself: 'Files',
          '*': 'Files *',
          required: 'Files are required',
        },
      },
    },
  },
};
