import MaintenanceProcedureModel from 'api/models/MaintenanceProcedure/MaintenanceProcedureModel';
import {
  buildQueryKey,
  getUniqueArrayOfObjectsWithCheck,
  sortArrayByObjectProp,
} from 'utils/helpers';
import {
  CREATE,
  DELETE,
  FIND,
  UPDATE,
  GET_BY_PROJECT_ID,
  RESET_STATE,
  GET_EQUIPMENT_PMP_EVENTS_ACTION_TYPES_CONFIG,
  GET_ADDITIONAL_PROCEDURES,
  GET_STANDARD_PROCEDURES,
  FIND_EQUIPMENT_PROCEDURE_ACTION_TYPES_CONFIG,
  GET_EQUIPMENT_PROCEDURE_OPERATIONS_ACTION_TYPES_CONFIG,
} from './constants';
import { sortArrayByObjectDateProp } from '../../../utils/helpers';
import {
  addToDictionary,
  addToList,
  changeListStatus,
  hasPageSizeChanged,
  resetState,
} from '../../helpers';
import compareEquipmentPmpEventsQueryObject from './helpers/compareEquipmentPmpEventsQueryObject';
import { ORDERS, STATUS } from '../../../utils/constants';

const PROCEDURE_PROPERTIES = Object.freeze({
  STANDARD: 'standardProcedures',
  ADDITIONAL: 'additionalProcedures',
});

/**
 *
 * @type {{list: {default: {}, pagination: {total: number, pageNumber: null, pageSize: null}, meta: {currentKey: string}}, dictionary: {}}}
 */
const initialState = {
  dictionary: {},
  list: {
    meta: {
      currentKey: 'default',
    },
    pagination: {
      pageNumber: null,
      pageSize: null,
      total: 0,
    },
    default: {},
  },
  [PROCEDURE_PROPERTIES.STANDARD]: {
    dictionary: {},
    list: {
      meta: {
        currentKey: 'default',
      },
      pagination: {
        pageNumber: null,
        pageSize: null,
        total: 0,
      },
      default: {},
    },
  },
  [PROCEDURE_PROPERTIES.ADDITIONAL]: {
    dictionary: {},
    list: {
      meta: {
        currentKey: 'default',
      },
      pagination: {
        pageNumber: null,
        pageSize: null,
        total: 0,
      },
      default: {},
    },
  },
  pmp: {
    // [equipmentId]: {
    //   dictionary: [],
    //   list: {
    //     status: null,
    //     error: null,
    //     query: null,
    //     pagination: {
    //       total: 0,
    //     },
    //   },
    // },
  },
};

export default function equipmentReducer(state = initialState, action) {
  let queryKey;
  let listStatus;
  let error;
  let id;
  let resourceModel;
  let resetToDefault;
  let pager;
  let maintenanceProcedures;

  switch (action.type) {
    case GET_BY_PROJECT_ID.REQUEST:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;
      resetToDefault = action.payload.resetToDefault;

      if (
        hasPageSizeChanged(state.list.pagination.pageSize, pager?.pageSize) ||
        resetToDefault
      ) {
        return resetState(state, initialState, {
          queryKey,
          pager,
        });
      }

      if (
        [STATUS.RELOADING, STATUS.SUCCESS, STATUS.CACHED].includes(listStatus)
      ) {
        return changeListStatus(state, { pager, queryKey }, STATUS.RELOADING);
      }
      return changeListStatus(state, { pager, queryKey }, STATUS.LOADING);
    case GET_BY_PROJECT_ID.SUCCESS:
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
        list: addToList(state.list, action.payload),
      };
    case GET_BY_PROJECT_ID.ERROR:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      error = action.payload.error;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;

      return changeListStatus(
        state,
        { pager, queryKey, error },
        listStatus === STATUS.RELOADING ? STATUS.CACHED : STATUS.ERROR
      );

    case FIND.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case FIND.SUCCESS:
      const { resource } = action.payload;
      resourceModel = action.payload.resourceModel;

      maintenanceProcedures = sortArrayByObjectProp(
        (resource.maintenanceProcedures || []).map(
          (maintenanceProcedure) =>
            new MaintenanceProcedureModel({
              ...maintenanceProcedure,
              operations: sortArrayByObjectProp(
                maintenanceProcedure.operations,
                'positionIndex'
              ),
            })
        ),
        'positionIndex'
      );

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [resource.id]: new resourceModel({
            ...state.dictionary[resource.id],
            ...resource,
            allRelations: action.payload.allRelations,
            maintenanceProcedures,
          }),
        },
      };
    case FIND.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case CREATE.REQUEST:
      return state;
    case CREATE.SUCCESS:
      return state;
    case CREATE.ERROR:
      return state;

    case UPDATE.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case UPDATE.SUCCESS:
      return state;
    case UPDATE.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case DELETE.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case DELETE.SUCCESS:
      return state;
    case DELETE.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };
    case GET_ADDITIONAL_PROCEDURES.SUCCESS:
      resourceModel = action.payload.resourceModel;
      return {
        ...state,
        [PROCEDURE_PROPERTIES.ADDITIONAL]: {
          ...state[PROCEDURE_PROPERTIES.ADDITIONAL],
          dictionary: addToDictionary(
            state[PROCEDURE_PROPERTIES.ADDITIONAL].dictionary,
            action.payload.resources.map((resourceParameter) => ({
              ...resourceParameter,
              equipmentId: action.payload.equipmentId,
            })),
            resourceModel
          ),
          list: addToList(
            state[PROCEDURE_PROPERTIES.ADDITIONAL].list,
            action.payload
          ),
        },
      };

    case GET_STANDARD_PROCEDURES.SUCCESS:
      resourceModel = action.payload.resourceModel;
      return {
        ...state,
        [PROCEDURE_PROPERTIES.STANDARD]: {
          ...state[PROCEDURE_PROPERTIES.STANDARD],
          dictionary: addToDictionary(
            state[PROCEDURE_PROPERTIES.STANDARD].dictionary,
            action.payload.resources.map((resourceParameter) => ({
              ...resourceParameter,
              equipmentId: action.payload.equipmentId,
            })),
            resourceModel
          ),
          list: addToList(
            state[PROCEDURE_PROPERTIES.STANDARD].list,
            action.payload
          ),
        },
      };

    case GET_EQUIPMENT_PMP_EVENTS_ACTION_TYPES_CONFIG.REQUEST: {
      const currentPmp = state.pmp?.[action.payload.equipmentId] || {};
      const currentList = currentPmp.list || {};

      return {
        ...state,
        pmp: {
          [action.payload.equipmentId]: {
            ...currentPmp,
            list: {
              ...currentList,
              status: STATUS.LOADING,
            },
          },
        },
      };
    }
    case GET_EQUIPMENT_PMP_EVENTS_ACTION_TYPES_CONFIG.SUCCESS: {
      const currentPmp = state.pmp[action.payload.equipmentId] || {};
      const currentList = currentPmp.list || {};
      const currentDictionary = currentPmp.dictionary || [];
      const prevQuery = currentPmp.list.query || {};
      const currentQuery = action.payload.query || {};

      const isQueryEqual = compareEquipmentPmpEventsQueryObject(
        prevQuery,
        currentQuery
      );

      const newResources = action.payload.resources.map(
        (resourceParameter) =>
          new action.payload.resourceModel(resourceParameter)
      );

      return {
        ...state,
        pmp: {
          [action.payload.equipmentId]: {
            ...currentPmp,
            list: {
              ...currentList,
              status: STATUS.SUCCESS,
              pagination: {
                ...currentList.pagination,
                total: action.payload.total,
              },
              query: currentQuery,
            },
            dictionary: sortArrayByObjectDateProp(
              isQueryEqual
                ? getUniqueArrayOfObjectsWithCheck(
                    [...currentDictionary, ...newResources],
                    'id',
                    (item1, item2) => item2
                  )
                : newResources,
              'date',
              ORDERS.ASC
            ),
          },
        },
      };
    }
    case GET_EQUIPMENT_PMP_EVENTS_ACTION_TYPES_CONFIG.ERROR: {
      const currentPmp = state.pmp?.[action.payload.equipmentId] || {};
      const currentList = currentPmp.list || {};

      return {
        ...state,
        pmp: {
          [action.payload.equipmentId]: {
            ...currentPmp,
            list: {
              ...currentList,
              status: STATUS.ERROR,
              error: action.payload.error,
            },
          },
        },
      };
    }

    case FIND_EQUIPMENT_PROCEDURE_ACTION_TYPES_CONFIG.SUCCESS: {
      const procedureResource = action.payload.resource;
      const isMaintenanceProcedure = procedureResource.isFromStandard;
      const proceduresProperty = isMaintenanceProcedure
        ? PROCEDURE_PROPERTIES.STANDARD
        : PROCEDURE_PROPERTIES.ADDITIONAL;

      return {
        ...state,
        [proceduresProperty]: {
          ...state[proceduresProperty],
          dictionary: {
            ...state[proceduresProperty].dictionary,
            [procedureResource.id]: new action.payload.resourceModel({
              ...(state[proceduresProperty].dictionary?.[
                procedureResource.id
              ] || {}),
              ...procedureResource,
            }),
          },
        },
      };
    }

    case GET_EQUIPMENT_PROCEDURE_OPERATIONS_ACTION_TYPES_CONFIG.SUCCESS: {
      const isMaintenanceProcedure =
        action.payload.isEquipmentProcedureFromStandard;
      const proceduresProperty = isMaintenanceProcedure
        ? PROCEDURE_PROPERTIES.STANDARD
        : PROCEDURE_PROPERTIES.ADDITIONAL;

      return {
        ...state,
        [proceduresProperty]: {
          ...state[proceduresProperty],
          dictionary: {
            ...state[proceduresProperty].dictionary,
            [action.payload.procedureId]: new action.payload.procedureModel({
              ...(state[proceduresProperty].dictionary?.[
                action.payload.procedureId
              ] || {}),
              operations: (action.payload.resources || []).map(
                (resourceParameter) =>
                  new action.payload.resourceModel({
                    ...resourceParameter,
                  })
              ),
            }),
          },
        },
      };
    }

    case RESET_STATE:
      return { ...initialState };

    default:
      return state;
  }
}
