export default {
  equipment: {
    itself: 'Echipament',
    plural: {
      itself: 'Echipamente',
    },
    addEquipment: 'Adaugă echipament',
    editEquipment: 'Modifică echipamentul',
    enableEquipment: 'Activați echipamentul',
    disableEquipment: 'Dezactivați echipamentul',
    linkToOther: 'Legătură cu alt echipament',
    deleteEquipment: 'Șterge echipamentul',
    saveNewEquipment: 'Salvează echipament nou',
    saveEquipment: 'Salvați echipamentul',
    addEquipmentIn: 'Adaugă echipament în',
    editEquipmentIn: 'Modifică echipament în',
    equipmentDetails: 'Detalii echipament',
    technicalDetails: 'Detalii tehnice',
    standardProcedures: 'Proceduri standard',
    statuses: {
      draft: 'Proiect',
      active: 'Activ',
      disabled: 'Dezactivat',
      archived: 'Arhivat',
    },
    actions: {
      activate: 'Activează',
      archive: 'Arhivează ',
      disable: 'Dezactivează',
      enable: 'Activează',
    },
    form: {
      projectCategory: {
        itself: 'Categorie proiect',
        '*': 'Categorie proiect *',
        required: 'Categoria proiectului e obligatorie',
      },
      category: {
        itself: 'Categorie',
        '*': 'Categorie *',
        required: 'Categoria e obligatorie',
        select: 'Selectează categorie',
      },
      group: {
        itself: 'Grup',
        '*': 'Grup *',
        required: 'Grupul e obligatoriu',
        select: 'Selectează grup',
      },
      equipmentGroup: {
        itself: 'Grup echipament',
        '*': 'Grup echipament *',
        required: 'Grupul echipamentului e obligatoriu',
        select: 'Selectează grup echipament',
        selectOrAdd: 'Selectează sau adaugă grup echipament',
        addNew: 'Adaugă grup echipament nou',
      },
      name: {
        itself: 'Nume',
        '*': 'Nume *',
        required: 'Numele e obligatoriu',
      },
      manufacturer: {
        itself: 'Producător',
        '*': 'Producător *',
        required: 'Producătorul e obligatoriu',
        selectOrAdd: 'Selectează sau adaugă producător',
        addNew: 'Adaugă producător nou',
      },
      model: {
        itself: 'Model',
        '*': 'Model *',
        required: 'Modelul e obligatoriu',
        selectOrAdd: 'Selectează sau adaugă model',
        addNew: 'Adaugă model nou',
      },
      quantity: {
        itself: 'Cantitate',
        '*': 'Cantitate *',
        required: 'Introdu un număr',
        enterQuantity: 'Introdu cantitatea',
      },
      documentation: {
        itself: 'Documentație',
        '*': 'Documentație *',
        required: 'Documentația e obligatorie',
        uploadDocuments: 'Încarcă documente',
      },
      media: {
        itself: 'Media',
        '*': 'Media *',
        required: 'Media este obligatorie',
        uploadImagesVideos: 'Încarcă imagini & video',
      },
      location: {
        itself: 'Locație',
        '*': 'Locație *',
        required: 'Locația e obligatorie',
      },
      building: {
        itself: 'Clădire',
        '*': 'Clădire *',
        required: 'Clădirea e obligatorie',
        select: 'Selectează clădirea',
      },
      locations: {
        required: 'Locațiile sunt necesare',
      },
      level: {
        itself: 'Nivel',
        '*': 'Nivel *',
        required: 'Nivelul e obligatoriu',
        select: 'Selectează nivelul',
      },
      room: {
        itself: 'Cameră',
        '*': 'Cameră *',
        required: 'Camera e obligatorie',
        select: 'Selectează camera',
      },
      input: {
        plural: {
          itself: 'Parametrii',
        },
        name: {
          itself: 'Nume',
          '*': 'Nume *',
          required: 'Numele e obligatoriu',
        },
        value: {
          itself: 'Valoare',
          '*': 'Valoare *',
          required: 'Valoarea e obligatorie',
        },
        unit: {
          itself: 'Unitate',
          '*': 'Unitate *',
          required: 'Unitatea e obligatorie',
        },
      },
      status: {
        itself: 'Stat',
        '*': 'Stat *',
      },
    },
    maintenanceProcedures: 'Proceduri de întreținere',
    additionalProcedures: 'Proceduri suplimentare',
    maintenancePlan: 'Plan de întreținere',
    enablingPlannedOn: 'Activarea este planificată pe {{date}}',
    disablingPlannedOn: 'Dezactivarea este planificată pentru {{date}}',
    logs: {
      statusWillBeChangedOn: `Starea va fi schimbată în {{status}} pe {{date}}`,
      statusWasChangedOn: `Starea a fost schimbată în {{status}} pe {{date}}`,
      equipmentCreated: 'Echipamentul este creat',
      equipmentLinkedToProcedure:
        'Echipamentul {{equipmentName}} este conectat la procedura {{procedureName}}',
      equipmentUnlinkedFromProcedure:
        'Echipamentul {{equipmentName}} este deconectat de la procedura {{procedureName}}',
      procedureCreated: 'Procedura {{procedureName}} este creată',
      procedureStatusChangedTo:
        'Starea procedurii {{procedureName}} este schimbată în {{status}}',
      equipmentIsLinkedToProcurement:
        'Echipamentul {{equipmentName}} este conectat la achiziția {{procurementName}}',
      equipmentIsUnlinkedFromProcurement:
        'Echipamentul {{equipmentName}} este deconectat de la achiziția {{procurementName}}',
      procurementStatusChangedTo:
        'Starea achiziției {{procurementName}} este schimbată în {{status}}',
      equipmentIsLinkedToWarrantyTracker:
        'Echipamentul {{equipmentName}} este conectat la instrumentul de urmărire a garanției {{warrantyTrackerName}}',
      equipmentIsUnlinkedFromWarrantyTracker:
        'Echipamentul {{equipmentName}} este deconectat de la instrumentul de urmărire a garanției {{warrantyTrackerName}}',
      warrantyTrackerStatusChangedTo:
        'Starea de urmărire a garanției {{warrantyTrackerName}} este schimbată în {{status}}',
      pmpEventStatusChangedTo:
        'Starea evenimentului {{eventName}} este schimbată în {{status}}',
      pmpDcEventStatusChangedTo:
        'Starea evenimentului de verificare zilnică a {{eventName}} este schimbată în {{status}}',
      pmpDcEventsResolved: '{{dailyCheckGroupName}} evenimente sunt rezolvate',
    },
    categories: {
      heating: 'Incălzire',
      cooling: 'Răcire',
      ventilation: 'Ventilare',
      emergencySystem: 'Sistem De Urgență',
      plumbingSystem: 'Instalații sanitare',
      sanitary: 'Sanitar',
      lowVoltage: 'Voltaj scazut',
      extraLowVoltage: 'Tensiune foarte joasă',
      other: 'Alte',
    },
    table: {
      columns: {
        equipmentName: 'Numele Echipamentului',
      },
    },
    exportEquipmentData: 'Exportați datele echipamentelor',
    exportEquipmentIncludeProcedures:
      'Include detalii despre proceduri de mentenanta in export.',
    modalDeleteMessage:
      'Ești sigur că vrei să ștergi acest echipament și toate informațiile asociate?',
    modalArchiveMessage: 'Ești sigur că vrei să arhivezi acest echipament?',
    modalActivateMessage: 'Ești sigur că vrei să activezi acest echipament?',
  },
};
