export default {
  building: {
    itself: 'Building',
    plural: {
      itself: 'Buildings',
    },
    buildingName: {
      itself: 'Building name',
      required: 'You need to set a building name first!',
    },
    modalDeleteMessage:
      'Are you sure you want to delete this building and all its information?',
    allBuildings: 'All buildings',
    allLevels: 'All levels',
    allRooms: 'All rooms',
    addBuilding: 'Add building',
    editBuilding: 'Edit building',
  },
};
