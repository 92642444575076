import AbstractEventCatcher from '../AbstractEventCatcher';
import { procurementChatActionCreators } from '../../../store/chat/resource/actions';

export default class ProcurementChatEventCatcher extends AbstractEventCatcher {
  static process({ chat, loggedInUser, dispatch }) {
    dispatch(
      procurementChatActionCreators.setChat(
        chat.procurementId,
        chat.id,
        chat,
        loggedInUser
      )
    );

    dispatch(
      procurementChatActionCreators.addLastReadMessageToEachHistory({
        [chat.procurementId]: {
          [chat.id]: {
            lastReadMessage: chat.lastReadMessage,
            unreadMessagesIds: chat.unreadMessagesIds,
          },
        },
      })
    );
  }
}
