export default {
  dailyProcedure: {
    dailyProcedureGroup: 'Grupuri de proceduri zilnice',
    itself: 'Procedura zilnică',
    plural: {
      itself: 'Proceduri zilnice',
    },
    procedures: 'Proceduri',
    createNewGroup: 'Creează un grup nou',
    editGroup: 'Modifică grup',
    createGroup: 'Creează grup',
    saveGroup: 'Salvează grup',
    emptyDailyCheckGroupList:
      'Apăsați butonul albastru plus și începeți să adăugați grupuri',
    group: {
      attributes: {
        name: {
          itself: 'Nume',
          '*': 'Nume *',
          required: 'Numele e obligatoriu',
        },
      },
    },
    confirmDeleteModal: {
      cancel: 'Nu, anulează',
      confirm: 'Da, șterge!',
      deleteMessage:
        'Ești sigur că vrei să ștergi acest grup și toate informațiile asociate? Acțiunea este ireversibilă.',
    },
    completed: 'Efectuat',
    resolvedProcedures: 'Proceduri rezolvate',
    alerts: 'Alerte',
  },
};
