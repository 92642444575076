import { capitalize } from '@material-ui/core';
import NotificationModel from '../../../api/models/NotificationModel';

export default class NotificationItemMapper {
  static getTypeName = (type, customTypeTitle) => {
    if (!type) return '--';

    if (
      type === NotificationModel.NOTIFICATION_TYPES.CUSTOM &&
      customTypeTitle
    ) {
      return customTypeTitle;
    }

    switch (type) {
      case NotificationModel.NOTIFICATION_TYPES.CREATE:
        return 'Create';
      case NotificationModel.NOTIFICATION_TYPES.UPDATE:
        return 'Update';
      case NotificationModel.NOTIFICATION_TYPES.INCOMING_MESSAGE:
        return 'New Message';
      case NotificationModel.NOTIFICATION_TYPES.CUSTOM:
        return 'Notification';
      default:
        return capitalize(type);
    }
  };

  constructor(notificationModel) {
    if (!(notificationModel instanceof NotificationModel)) {
      throw new Error('Should be instance of NotificationModel');
    }

    this.id = notificationModel.id;
    this.name = notificationModel.name;
    this.type = notificationModel.notificationUiType;
    this.customTypeTitle = notificationModel.customTypeTitle;
    this.date = notificationModel.date;
    this.author = notificationModel.authorFullName;
    this.description = notificationModel.description;
    this.resourceMetaData = notificationModel.resourceMetaData;

    this.navigateToResource = (callback, history) => {
      callback(this.resourceMetaData);

      history.push(this.resourceMetaData.path);
    };
  }
}
