import AbstractDataAdapter from './AbstractDataAdapter';

class CountryDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return [
      'id',
      'localeCode',
      'continentCode',
      'continentName',
      'countryIsoCode',
      'countryName',
      'isInEuropeanUnion',
    ];
  }
}

export default CountryDataAdapter;
