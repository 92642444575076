import AbstractDataAdapter from './AbstractDataAdapter';

class TicketDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return [
      'subject',
      'description',
      'contact',
      'projectId',
      'recipientId',
      'buildingId',
      'buildingLevelId',
      'buildingRoomIds',
      'fileIds',
      'procurementIds',
    ];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return [
      'subject',
      'description',
      'projectId',
      'buildingId',
      'buildingLevelId',
      'buildingRoomIds',
    ];
  }

  static sendableAttributeRule(attribute) {
    if (attribute === 'ticketStatus') {
      return 'status';
    }
    return super.sendableAttributeRule(attribute);
  }
}

export default TicketDataAdapter;
