import ApiResourceRequest, { API_METHODS } from './ApiResourceRequest';
import TypesHelper from '../../utils/types/TypesHelper';
import Pager from '../helpers/Pager';

export class NotificationRequest extends ApiResourceRequest {
  get apiResource() {
    return 'notifications';
  }

  get dynamicIds() {
    return {
      NOTIFICATION_ID: '{notificationId}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      MARK_ONE_AS_ACKNOWLEDGED: `${this.apiRoute}/${this.dynamicIds.NOTIFICATION_ID}/ack`,
      MARK_ALL_AS_ACKNOWLEDGED: `${this.apiRoute}/read-all`,
    };
  }

  async get(pager, query) {
    if (pager) {
      Pager.check(pager);
    }

    const queryString = this.buildQueryString(
      this.getExtraQueryParams(query.extra),
      this.getPaginationParams(pager)
    );
    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET + queryString
    );

    return {
      resources: response?.data?.resources,
      total: response?.data?.total,
    };
  }

  async markOneAsAcknowledged(notificationId) {
    if (!TypesHelper.isNumber(notificationId)) {
      throw new Error('notificationId should be number');
    }

    const response = await this.request(
      API_METHODS.POST,
      this.routes.MARK_ONE_AS_ACKNOWLEDGED.replace(
        this.dynamicIds.NOTIFICATION_ID,
        notificationId
      ),
      {}
    );

    return response.data;
  }

  async markAllAsAcknowledged() {
    const response = await this.request(
      API_METHODS.POST,
      this.routes.MARK_ALL_AS_ACKNOWLEDGED,
      {}
    );

    return response.data;
  }
}

export const notificationRequest = new NotificationRequest();
