export const GET_BY_PROJECT_ID = Object.freeze({
  REQUEST: 'inventory/GET_BY_PROJECT_ID_REQUEST',
  SUCCESS: 'inventory/GET_BY_PROJECT_ID_SUCCESS',
  ERROR: 'inventory/GET_BY_PROJECT_ID_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'inventory/FIND_REQUEST',
  SUCCESS: 'inventory/FIND_SUCCESS',
  ERROR: 'inventory/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'inventory/CREATE_REQUEST',
  SUCCESS: 'inventory/CREATE_SUCCESS',
  ERROR: 'inventory/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'inventory/UPDATE_REQUEST',
  SUCCESS: 'inventory/UPDATE_SUCCESS',
  ERROR: 'inventory/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'inventory/DELETE_REQUEST',
  SUCCESS: 'inventory/DELETE_SUCCESS',
  ERROR: 'inventory/DELETE_ERROR',
});

export const GET_INVENTORY_STATISTICS = Object.freeze({
  REQUEST: 'project/GET_INVENTORY_STATISTICS_REQUEST',
  SUCCESS: 'project/GET_INVENTORY_STATISTICS_SUCCESS',
  ERROR: 'project/GET_INVENTORY_STATISTICS_ERROR',
});

export const GET_INVENTORIES_BY_PROJECT_ID_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_PROJECT_ID.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_PROJECT_ID.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_PROJECT_ID.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_INVENTORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_INVENTORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_INVENTORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_INVENTORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});

export const GET_INVENTORY_STATISTICS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_INVENTORY_STATISTICS.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_INVENTORY_STATISTICS.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_INVENTORY_STATISTICS.ERROR,
    payload,
    error: true,
  }),
});
