import React from 'react';

/**
 * Adds a span wrapper as the ref of the wrapped component
 */
export default function withRef(WrappedComponent) {
  function withRefWrapper({ ...props }, ref) {
    return (
      <span className="ref-wrapper" ref={ref}>
        <WrappedComponent {...props} />
      </span>
    );
  }

  return React.forwardRef(withRefWrapper);
}
