import { required } from '../../../utils/helpers';
import createChatChannelItemModel from './createChatChannelItemModel';

const toConversationsStructure = (array, state = {}, loggedInUserId) => {
  required(array);

  const result = {};

  array.forEach((item) => {
    result[item.id] = {
      ...(state?.[item.id] || {}),
      conversationInfo: createChatChannelItemModel(item, loggedInUserId),
      ...(item.unreadMessagesIds?.length
        ? { lastReadMessageId: item.unreadMessagesIds[0] }
        : {}),
    };
  });

  return result;
};

export default toConversationsStructure;
