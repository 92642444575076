import { lazy } from 'react';
import PATH from 'routes/paths';

const UnitsList = lazy(() => import('containers/units/UnitsList'));

export const USER_LIST = {
  component: UnitsList,
  path: PATH.UNITS.LIST,
  exact: false,
  isProtected: true,
};

export default [USER_LIST];
