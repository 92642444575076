export default {
  activityReport: {
    itself: 'Registrul de activitate',
    plural: {
      itself: 'Registre de activitate',
    },
    hoursSpent: 'Ore petrecute',
    saveItem: 'Salvați raportul de activitate',
    createNew: 'Creați un nou raport de activitate',
    updateNew: 'Actualizați noul raport de activitate',
    form: {
      hoursSpent: {
        itself: 'Ore petrecute',
        '*': 'Ore petrecute *',
        required: 'Sunt necesare orele petrecute',
      },
    },
    types: {
      technicalMeeting: 'Sedinta Tehnica',
      technicalAssistance: 'Asistenta Tehnica',
      additionalReporting: 'Realizare rapoarte suplimentare',
      tickets: 'Tichete',
      others: 'Altele',
    },
  },
};
