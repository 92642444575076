import UserModel from '../../api/models/UserModel';
import AbstractModel from '../../api/models/AbstractModel';

export default class ChatParticipantModel extends AbstractModel {
  constructor(params) {
    super(params);

    this.user = new UserModel(this.user);
    this.rawData = params;
  }
}
