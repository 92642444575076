import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function NotificationIcon({ size, color, ...rest }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 3C8.56356 3 5.77778 5.93813 5.77778 9.5625V15.2222C5.77778 16.2041 4.98184 17 4 17H20C19.0182 17 18.2222 16.2041 18.2222 15.2222V9.5625C18.2222 5.93813 15.4364 3 12 3Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M12 21.5C13.2106 21.5 14.2202 20.6395 14.4506 19.4968C14.5052 19.2261 14.2761 19 14 19H10C9.72386 19 9.49478 19.2261 9.54936 19.4968C9.77976 20.6395 10.7894 21.5 12 21.5Z"
        fill={color}
      />
    </svg>
  );
}

export default iconWrapper(NotificationIcon);
