import ApiResourceRequest, {
  API_METHODS,
} from 'api/requestWrappers/ApiResourceRequest';
import { required } from 'utils/helpers';
import ListingResponse from '../ListingResponse';
import Pager from '../../helpers/Pager';

export class RoomRequest extends ApiResourceRequest {
  get apiResource() {
    return 'rooms';
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_LEVEL_ID: `${this.apiUrl}/buildings/rooms/by-level-id/{levelId}`,
      UPDATE_POSITIONS: `${this.apiUrl}/buildings/update-room-positions`,
    };
  }

  async get(levelId, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ levelId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_LEVEL_ID.replace('{levelId}', levelId) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async updatePositions(items) {
    required({ items });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.UPDATE_POSITIONS,
      items
    );

    return response?.data;
  }
}

export const roomRequest = new RoomRequest();
