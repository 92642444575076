import { createActions, createActionTypesConfig } from '../helpers';

export const PROCUREMENT_LABEL = 'procurement';

/** Action Types */

export const CREATE_PROCUREMENT_ORDER_TYPES = createActionTypesConfig(
  PROCUREMENT_LABEL,
  'CREATE_PROCUREMENT_ORDER'
);

export const SUBMIT_PROPOSAL_TYPES = createActionTypesConfig(
  PROCUREMENT_LABEL,
  'SUBMIT_PROPOSAL'
);

export const UPDATE_PROCUREMENT_TYPES = createActionTypesConfig(
  PROCUREMENT_LABEL,
  'UPDATE_PROCUREMENT'
);

export const GET_PROCUREMENTS_TYPES = createActionTypesConfig(
  PROCUREMENT_LABEL,
  'GET_PROCUREMENTS'
);

export const FIND_PROCUREMENT_TYPES = createActionTypesConfig(
  PROCUREMENT_LABEL,
  'FIND_PROCUREMENT'
);

export const DELETE_PROCUREMENT_TYPES = createActionTypesConfig(
  PROCUREMENT_LABEL,
  'DELETE_PROCUREMENT'
);
export const GET_PROCUREMENT_STATISTICS_TYPES = createActionTypesConfig(
  PROCUREMENT_LABEL,
  'GET_PROCUREMENT_STATISTICS'
);

/** Actions */

export const CREATE_PROCUREMENT_ORDER_ACTION = createActions(
  CREATE_PROCUREMENT_ORDER_TYPES
);
export const GET_PROCUREMENT_STATISTICS_ACTION = createActions(
  GET_PROCUREMENT_STATISTICS_TYPES
);
export const SUBMIT_PROPOSAL_ACTION = createActions(SUBMIT_PROPOSAL_TYPES);
export const UPDATE_PROCUREMENT_ACTION = createActions(
  UPDATE_PROCUREMENT_TYPES
);
export const GET_PROCUREMENTS_ACTION = createActions(GET_PROCUREMENTS_TYPES);
export const FIND_PROCUREMENT_ACTION = createActions(FIND_PROCUREMENT_TYPES);
export const DELETE_PROCUREMENT_ACTION = createActions(
  DELETE_PROCUREMENT_TYPES
);
