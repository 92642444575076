import ApiResourceRequest from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import ListingResponse from '../ListingResponse';
import Pager from '../../helpers/Pager';

export class EquipmentProjectCategoryRequest extends ApiResourceRequest {
  get apiResource() {
    return 'equipments/project-categories';
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_PROJECT_ID: `${this.apiRoute}/by-project-id/{projectId}`,
      GET_WITH_GROUPS_BY_PROJECT_ID: `${this.apiRoute}-with-category-groups/{projectId}`,
      GET_WITH_GROUPS_NO_COUNTS_BY_PROJECT_ID: `${this.apiRoute}-with-category-groups-no-counts/{projectId}`,
    };
  }

  async getByProjectId(projectId, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ projectId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_PROJECT_ID.replace('{projectId}', projectId) +
        queryString
    );

    return new ListingResponse(response?.data);
  }

  async getWithGroupsByProjectId(
    projectId,
    withCounts,
    pager = new Pager(),
    query,
    newQueryFilterFormat
  ) {
    pager && Pager.check(pager);
    required({ projectId });
    const queryString = this.getQueryString(pager, query, newQueryFilterFormat);

    const url = withCounts
      ? this.routes.GET_WITH_GROUPS_BY_PROJECT_ID
      : this.routes.GET_WITH_GROUPS_NO_COUNTS_BY_PROJECT_ID;

    const response = await this.request(
      'GET',
      url.replace('{projectId}', projectId) + queryString
    );

    return {
      resources: response?.data?.equipmentProjectCategories,
      total: response?.data?.count,
    };
  }
}

export const equipmentProjectCategoryRequest = new EquipmentProjectCategoryRequest();
