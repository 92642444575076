import ApiResourceRequest, { API_METHODS } from './ApiResourceRequest';
import { required } from '../../utils/helpers';
import RealmModel from '../models/RealmModel';
import ListingResponse from './ListingResponse';
import Pager from '../helpers/Pager';

export class UserRequest extends ApiResourceRequest {
  get apiResource() {
    return 'users';
  }

  get dynamicIds() {
    return {
      PROJECT_ID: '{projectId}',
      USER_ID: '{userId}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_ORGANIZATION_ID: `${this.apiRoute}/by-organization-id/{organizationId}`,
      SET_PASSWORD: `${this.apiRoute}/change-password`,
      GET_ASSOCIATED_USERS: `${this.apiUrl}/projects/{projectId}/associated-users/by-organization-id/{organizationId}`,
      GET_ASSOCIATED_USERS_BY_ORG_TYPE: `${this.apiUrl}/projects/{projectId}/associated-users/by-organization-type/{orgType}`,
      GET_PROJECT_STATISTICS: `${this.apiRoute}/statistics/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      ACTIVATE_USER: `${this.apiRoute}/activate/${this.dynamicIds.USER_ID}`,
    };
  }

  async getProjectStatistics(projectId) {
    required({ projectId });

    return this.request(
      API_METHODS.GET,
      this.routes.GET_PROJECT_STATISTICS.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      )
    ).then(({ data }) => data);
  }

  async getByOrganizationId(organizationId, pager = new Pager(), query = {}) {
    required({ organizationId });
    const queryString = this.getQueryString(pager, query);

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_BY_ORGANIZATION_ID.replace(
        '{organizationId}',
        organizationId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async setPassword(oldPassword, newPassword) {
    required({ oldPassword, newPassword });

    const response = await this.request('POST', this.routes.SET_PASSWORD, {
      oldPassword,
      newPassword,
    });

    return response?.data;
  }

  async getAssociatedUsers(
    projectId,
    organizationId,
    pager = new Pager(),
    query = {}
  ) {
    required({ projectId, organizationId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_ASSOCIATED_USERS.replace(
        '{projectId}',
        projectId
      ).replace('{organizationId}', organizationId) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async getAssociatedUsersByOrgType(
    projectId,
    orgType,
    pager = new Pager(1, -1),
    query = {}
  ) {
    required({ projectId });
    if (!Object.values(RealmModel.TYPES).includes(orgType)) {
      throw new Error(
        `orgType must be one of ${Object.values(RealmModel.TYPES).toString()}`
      );
    }
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter, true),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_ASSOCIATED_USERS_BY_ORG_TYPE.replace(
        '{projectId}',
        projectId
      ).replace('{orgType}', orgType) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async activateUser(userId) {
    required({ userId });

    const response = await this.request(
      API_METHODS.PATCH,
      this.routes.ACTIVATE_USER.replace(`${this.dynamicIds.USER_ID}`, userId)
    );

    return response?.data;
  }
}

export const userRequest = new UserRequest();
