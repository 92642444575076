import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function JointBottomIcon({ size, color, useSize = false, ...rest }) {
  return (
    <svg
      width={useSize ? size : 5}
      height={useSize ? size : 3}
      viewBox="0 0 5 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M2.5 2.5H5M2.5 2.5H0M2.5 2.5V0" stroke={color} />
    </svg>
  );
}

export default iconWrapper(JointBottomIcon);
