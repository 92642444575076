import React, { forwardRef } from 'react';
import MaterialUiButton from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

export const BUTTON_SIZES = {
  REGULAR: 'regular',
  LARGE: 'large',
  SPECIAL: 'special',
};
export const BUTTON_COLORS = {
  PRIMARY: 'primary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  LINK: 'link',
  DEFAULT: 'default',
};

export function getColor(theme, color, size, disabled, hover = false) {
  if (disabled) {
    return theme.palette.themeCommon.gray;
  }
  if (
    (size === BUTTON_SIZES.LARGE ||
      (size === BUTTON_SIZES.SPECIAL && color !== BUTTON_COLORS.DANGER)) &&
    !hover
  ) {
    return theme.palette.themeCommon.white.main;
  }
  switch (color) {
    case BUTTON_COLORS.PRIMARY:
      return theme.palette.primary.main;
    case BUTTON_COLORS.SUCCESS:
      return theme.palette.secondary.main;
    case BUTTON_COLORS.DANGER:
      return theme.palette.themeCommon.red.main;
    case BUTTON_COLORS.WARNING:
      return theme.palette.themeCommon.yellow.main;
    case BUTTON_COLORS.LINK:
      return theme.palette.themeCommon.purple.main;
    case BUTTON_COLORS.DEFAULT:
      return theme.palette.gray[1900];
    default:
      return theme.palette.primary.main;
  }
}

export function getBgColor(theme, color, size, disabled, hover = false) {
  if (disabled) {
    return theme.palette.themeCommon.black.light;
  }
  const key =
    (size === BUTTON_SIZES.LARGE ||
      (size === BUTTON_SIZES.SPECIAL && color !== BUTTON_COLORS.DANGER)) &&
    !hover
      ? 'main'
      : 'light';
  switch (color) {
    case BUTTON_COLORS.PRIMARY:
      return theme.palette.primary[key];
    case BUTTON_COLORS.SUCCESS:
      return theme.palette.secondary[key];
    case BUTTON_COLORS.DANGER:
      return theme.palette.themeCommon.red[key];
    case BUTTON_COLORS.WARNING:
      return theme.palette.themeCommon.yellow[key];
    case BUTTON_COLORS.LINK:
      return theme.palette.themeCommon.purple[key];
    case BUTTON_COLORS.DEFAULT:
      return theme.palette.gray[100];
    default:
      return theme.palette.primary[key];
  }
}

function applyPadding(size) {
  switch (size) {
    case BUTTON_SIZES.SPECIAL:
      return `
      padding: 0.25rem 0.5rem; 
      min-width: unset; 
      .MuiSvgIcon-root { 
        width: 1rem; 
      }`;
    case BUTTON_SIZES.REGULAR:
      return 'padding: 0.5rem 2.5rem;';
    case BUTTON_SIZES.LARGE:
      return 'padding: 0.5rem 4.5rem;';
    default:
      return '';
  }
}

const StyledButton = styled(MaterialUiButton)`
  background-color: ${({ theme, btnsize: size, btncolor: color, disabled }) =>
    getBgColor(theme, color, size, disabled)} !important;
  color: ${({ theme, btnsize: size, btncolor: color, disabled }) =>
    getColor(theme, color, size, disabled)} !important;
  border: 2px solid
    ${({ theme, btnsize: size, btncolor: color, disabled }) =>
      getBgColor(theme, color, size, disabled)};
  text-transform: initial;
  box-shadow: none !important;
  &:hover {
    background-color: ${({ theme, btnsize: size, btncolor: color, disabled }) =>
      getBgColor(theme, color, size, disabled, true)} !important;
    color: ${({ theme, btnsize: size, btncolor: color, disabled }) =>
      getColor(theme, color, size, disabled, true)} !important;
    border: 2px solid
      ${({ theme, btnsize: size, btncolor: color, disabled }) =>
        getColor(theme, color, size, disabled, true)};
  }

  ${({ btnsize: size }) => applyPadding(size)}

  ${({ btnsize: size, btncolor: color }) =>
    size === BUTTON_SIZES.SPECIAL &&
    color !== BUTTON_COLORS.DANGER &&
    'border-radius: 50%'}

  ${({ endIcon, btnsize: size }) =>
    endIcon &&
    size === BUTTON_SIZES.REGULAR &&
    `
    padding-left: 1rem;
    padding-right: 1rem;
    .MuiButton-label {
      min-width: 4rem;
      justify-content: space-between;
    }
  `}
`;

const Button = forwardRef(
  (
    {
      children,
      color,
      size,
      endIcon,
      specialIcon,
      disabled,
      isLoading,
      ...rest
    },
    ref
  ) => {
    const isSpecial = size === 'special';
    if (!isSpecial) {
      // eslint-disable-next-line no-param-reassign
      rest = { ...rest, endIcon };
    }
    return (
      <StyledButton
        ref={ref}
        variant="contained"
        btncolor={color}
        btnsize={size}
        disabled={disabled || isLoading}
        {...rest}
      >
        {(isSpecial && (specialIcon || <CloseIcon />)) || children}
        {isLoading && (
          <CircularProgress
            style={{ position: 'relative', left: '8px' }}
            size={20}
          />
        )}
      </StyledButton>
    );
  }
);

Button.propTypes = {
  children: PropTypes.node,
  endIcon: PropTypes.node,
  specialIcon: PropTypes.node,
  color(props, propName) {
    const propValue = props[propName];
    const btnColors = Object.values(BUTTON_COLORS);
    if (!btnColors.includes(propValue)) {
      throw new Error(`${propName} must be one of ${btnColors.toString()}`);
    }
  },
  size(props, propName) {
    const propValue = props[propName];
    const btnSizes = Object.values(BUTTON_SIZES);
    if (!btnSizes.includes(propValue)) {
      throw new Error(`${propName} must be one of ${btnSizes.toString()}`);
    }
  },
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  endIcon: null,
  specialIcon: null,
  color: 'primary',
  size: 'regular',
  isLoading: false,
  disabled: false,
};

export default Button;
