import LocalClientStore from './LocalClientStore';
import { LOCAL_STORAGE_TOKEN_KEY } from '../utils/constants';

class AuthClientStore extends LocalClientStore {
  static getToken() {
    return this.storage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  }

  static setToken(token) {
    this.storage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  }

  static removeToken() {
    this.storage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  }
}

export default AuthClientStore;
