export const UPDATE_TICKET_CHAT_UNREAD_COUNTS =
  'chat/UPDATE_TICKET_CHAT_UNREAD_COUNTS';

export const UPDATE_TICKET_CHAT_LAST_READ_MESSAGE_ID =
  'chat/UPDATE_TICKET_CHAT_LAST_READ_MESSAGE_ID';

export const UPDATE_PROCUREMENT_CHAT_UNREAD_COUNTS =
  'chat/UPDATE_PROCUREMENT_CHAT_UNREAD_COUNTS';

export const UPDATE_PROCUREMENT_CHAT_LAST_READ_MESSAGE_ID =
  'chat/UPDATE_PROCUREMENT_CHAT_LAST_READ_MESSAGE_ID';

const RESOURCE_TICKET_CHAT_BASE = 'resource-chat/TICKETS_';
export const RESOURCE_TICKET_CHAT_ACTIONS_TYPES = Object.freeze({
  SET_RESOURCE: `${RESOURCE_TICKET_CHAT_BASE}SET_RESOURCE`,
  SET_CHAT: `${RESOURCE_TICKET_CHAT_BASE}SET_CHAT`,
  SET_CHAT_HISTORY: `${RESOURCE_TICKET_CHAT_BASE}SET_CHAT_HISTORY`,
  ADD_MESSAGE: `${RESOURCE_TICKET_CHAT_BASE}ADD_MESSAGE`,
  REMOVE_MESSAGE: `${RESOURCE_TICKET_CHAT_BASE}REMOVE_MESSAGE`,
  UPDATE_UNREAD_MESSAGES: `${RESOURCE_TICKET_CHAT_BASE}UPDATE_UNREAD_MESSAGES`,
  ADD_PARTICIPANT: `${RESOURCE_TICKET_CHAT_BASE}ADD_PARTICIPANT`,
  REMOVE_PARTICIPANT: `${RESOURCE_TICKET_CHAT_BASE}REMOVE_PARTICIPANT`,
  UPDATE_LAST_READ_MESSAGE: `${RESOURCE_TICKET_CHAT_BASE}UPDATE_LAST_READ_MESSAGE`,
  UPDATE_LAST_READ_MESSAGE_USING_HISTORY: `${RESOURCE_TICKET_CHAT_BASE}UPDATE_LAST_READ_MESSAGE_USING_HISTORY`,
  SET_HISTORY_LAST_READ_MESSAGE: `${RESOURCE_TICKET_CHAT_BASE}SET_HISTORY_LAST_READ_MESSAGE`,
  ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY: `${RESOURCE_TICKET_CHAT_BASE}ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY`,
});

const RESOURCE_PROCUREMENT_CHAT_BASE = 'resource-chat/PROCUREMENTS_';
export const RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES = Object.freeze({
  SET_RESOURCE: `${RESOURCE_PROCUREMENT_CHAT_BASE}SET_RESOURCE`,
  SET_CHAT: `${RESOURCE_PROCUREMENT_CHAT_BASE}SET_CHAT`,
  SET_CHAT_HISTORY: `${RESOURCE_PROCUREMENT_CHAT_BASE}SET_CHAT_HISTORY`,
  ADD_MESSAGE: `${RESOURCE_PROCUREMENT_CHAT_BASE}ADD_MESSAGE`,
  REMOVE_MESSAGE: `${RESOURCE_PROCUREMENT_CHAT_BASE}REMOVE_MESSAGE`,
  UPDATE_UNREAD_MESSAGES: `${RESOURCE_PROCUREMENT_CHAT_BASE}UPDATE_UNREAD_MESSAGES`,
  ADD_PARTICIPANT: `${RESOURCE_PROCUREMENT_CHAT_BASE}ADD_PARTICIPANT`,
  REMOVE_PARTICIPANT: `${RESOURCE_PROCUREMENT_CHAT_BASE}REMOVE_PARTICIPANT`,
  UPDATE_LAST_READ_MESSAGE: `${RESOURCE_PROCUREMENT_CHAT_BASE}UPDATE_LAST_READ_MESSAGE`,
  UPDATE_LAST_READ_MESSAGE_USING_HISTORY: `${RESOURCE_PROCUREMENT_CHAT_BASE}UPDATE_LAST_READ_MESSAGE_USING_HISTORY`,
  SET_HISTORY_LAST_READ_MESSAGE: `${RESOURCE_PROCUREMENT_CHAT_BASE}SET_HISTORY_LAST_READ_MESSAGE`,
  ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY: `${RESOURCE_PROCUREMENT_CHAT_BASE}ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY`,
});

const GENERAL_CHAT_MESSAGES_BASE = 'chat/MESSAGES_TAB_';
export const GENERAL_CHAT_MESSAGES_ACTION_TYPES = Object.freeze({
  SET_CONVERSATIONS: `${GENERAL_CHAT_MESSAGES_BASE}SET_CONVERSATIONS`,
  UPDATE_SPECIFIED_CONVERSATION: `${GENERAL_CHAT_MESSAGES_BASE}UPDATE_SPECIFIED_CONVERSATION`,
  ADD_NEW_HISTORY_MESSAGE: `${GENERAL_CHAT_MESSAGES_BASE}ADD_NEW_HISTORY_MESSAGE`,
  REMOVE_HISTORY_MESSAGE: `${GENERAL_CHAT_MESSAGES_BASE}REMOVE_HISTORY_MESSAGE`,
  UPDATE_LAST_READ_MESSAGE: `${GENERAL_CHAT_MESSAGES_BASE}UPDATE_LAST_READ_MESSAGE`,
  UPDATE_LAST_READ_MESSAGE_USING_HISTORY: `${GENERAL_CHAT_MESSAGES_BASE}UPDATE_LAST_READ_MESSAGE_USING_HISTORY`,
  UPDATE_CONVERSATION_HISTORY: `${GENERAL_CHAT_MESSAGES_BASE}UPDATE_CONVERSATION_HISTORY`,
  UPDATE_CONVERSATION_UNREAD_MESSAGES_IDS: `${GENERAL_CHAT_MESSAGES_BASE}UPDATE_CONVERSATION_UNREAD_MESSAGES_IDS`,
  SET_HISTORY_LAST_READ_MESSAGE: `${GENERAL_CHAT_MESSAGES_BASE}SET_HISTORY_LAST_READ_MESSAGE`,
  REMOVE_CONVERSATION: `${GENERAL_CHAT_MESSAGES_BASE}REMOVE_CONVERSATION`,
  ADD_PARTICIPANT_TO_CONVERSATION: `${GENERAL_CHAT_MESSAGES_BASE}ADD_PARTICIPANT_TO_CONVERSATION`,
  REMOVE_PARTICIPANT_FROM_CONVERSATION: `${GENERAL_CHAT_MESSAGES_BASE}REMOVE_PARTICIPANT_FROM_CONVERSATION`,
  ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY: `${GENERAL_CHAT_MESSAGES_BASE}ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY`,
});

const GENERAL_CHAT_PROJECTS_BASE = 'chat/CHAT_PROJECTS_TAB_';
export const GENERAL_CHAT_PROJECTS_ACTION_TYPES = Object.freeze({
  SET_CONVERSATIONS: `${GENERAL_CHAT_PROJECTS_BASE}SET_CONVERSATIONS`,
  UPDATE_CONVERSATION_INFO: `${GENERAL_CHAT_PROJECTS_BASE}/UPDATE_CONVERSATION_INFO`,
  ADD_NEW_HISTORY_MESSAGE: `${GENERAL_CHAT_PROJECTS_BASE}ADD_NEW_HISTORY_MESSAGE`,
  REMOVE_HISTORY_MESSAGE: `${GENERAL_CHAT_PROJECTS_BASE}/REMOVE_HISTORY_MESSAGE`,
  UPDATE_LAST_READ_MESSAGE: `${GENERAL_CHAT_PROJECTS_BASE}UPDATE_LAST_READ_MESSAGE`,
  UPDATE_LAST_READ_MESSAGE_USING_HISTORY: `${GENERAL_CHAT_PROJECTS_BASE}UPDATE_LAST_READ_MESSAGE_USING_HISTORY`,
  UPDATE_CONVERSATION_HISTORY: `${GENERAL_CHAT_PROJECTS_BASE}UPDATE_CONVERSATION_HISTORY`,
  UPDATE_CONVERSATION_UNREAD_MESSAGES_IDS: `${GENERAL_CHAT_PROJECTS_BASE}UPDATE_CONVERSATION_UNREAD_MESSAGES_IDS`,
  SET_HISTORY_LAST_READ_MESSAGE: `${GENERAL_CHAT_PROJECTS_BASE}SET_HISTORY_LAST_READ_MESSAGE`,
  ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY: `${GENERAL_CHAT_PROJECTS_BASE}ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY`,
});

export const RESET_RESOURCE_CHAT_STATE =
  'resourceChat/RESET_RESOURCE_CHAT_STATE';
