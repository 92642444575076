import ApiErrorModel from 'api/models/ApiErrorModel';

export default {
  codes: {
    default: 'Ceva n-a mers bine...',
    [ApiErrorModel.ERROR_CODES.BUILDING_NOT_FOUND]: 'Clădirea nu este găsită',
    [ApiErrorModel.ERROR_CODES.BUILDING_LEVEL_NOT_FOUND]:
      'Nivelul clădirii nu este găsit',
    [ApiErrorModel.ERROR_CODES.BUILDING_ROOM_NOT_FOUND]:
      'Camera clădirii nu este găsită',
    [ApiErrorModel.ERROR_CODES.PROJECT_NOT_FOUND]: 'Proiectul nu este găsit',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_NOT_FOUND]:
      'Echipamentul nu este găsit',
    [ApiErrorModel.ERROR_CODES.ORGANIZATION_NOT_FOUND]:
      'Organizația nu este găsită',
    [ApiErrorModel.ERROR_CODES.USER_NOT_FOUND]: 'Utilizatorul nu este găsit',
    [ApiErrorModel.ERROR_CODES.DAILYCHECK_OPERATION_NOT_FOUND]:
      'Operația de verificare zilnică nu este găsită',
    [ApiErrorModel.ERROR_CODES.DAILYCHECK_PROCEDURE_NOT_FOUND]:
      'Procedura de verificare zilnică nu este găsită',
    [ApiErrorModel.ERROR_CODES.UNIT_NOT_FOUND]: 'Unitatea nu este găsită',
    [ApiErrorModel.ERROR_CODES.MAINTENANCE_OPERATION_NOT_FOUND]:
      'Operația de întreținere nu este găsită',
    [ApiErrorModel.ERROR_CODES.MAINTENANCE_PROCEDURE_NOT_FOUND]:
      'Procedura de întreținere nu este găsită',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_CATEGORY_GROUP_NOT_FOUND]:
      'Grupul de categorii de echipamente nu este găsit',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_MODEL_NOT_FOUND]:
      'Modelul echipamentului nu este găsit',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_MANUFACTURER_NOT_FOUND]:
      'Producătorul echipamentului nu este găsit',
    [ApiErrorModel.ERROR_CODES.SUBCONTRACTOR_NOT_FOUND]:
      'Subcontractantul nu este găsit',
    [ApiErrorModel.ERROR_CODES.COUNTRY_NOT_FOUND]: 'Țara nu este găsită',
    [ApiErrorModel.ERROR_CODES.SOURCE_EQUIPMENT_NOT_FOUND]:
      'Echipamentul sursă nu este găsit',
    [ApiErrorModel.ERROR_CODES.CHAT_NOT_FOUND]: 'Chatul nu este găsit',
    [ApiErrorModel.ERROR_CODES.PROJECT_CATEGORY_NOT_FOUND]:
      'Categoria de proiect nu este găsită',
    [ApiErrorModel.ERROR_CODES.STANDARD_CATEGORY_GROUP_NOT_FOUND]:
      'Grupul de categorii standard nu este găsit',
    [ApiErrorModel.ERROR_CODES.BUILDING_IS_NOT_PART_OF_PROJECT]:
      'Clădirea nu face parte din proiect',
    [ApiErrorModel.ERROR_CODES.BUILDING_LEVEL_IS_NOT_PART_OF_BUILDING]:
      'Nivelul clădirii nu face parte din clădire',
    [ApiErrorModel.ERROR_CODES.BUILDING_ROOM_IS_NOT_PART_OF_BUILDING]:
      'Camera clădirii nu face parte din clădire',
    [ApiErrorModel.ERROR_CODES.PROJECT_ID_NOT_SET]:
      'ID-ul proiectului nu este setat',
    [ApiErrorModel.ERROR_CODES.INVALID_FILE_SIZE]:
      'Dimensiunea fișierului nevalidă',
    [ApiErrorModel.ERROR_CODES.THUMBNAIL_NOT_SUPPORTED_FOR_FILE_TYPE]:
      'Miniatura nu este acceptată pentru tipul de fișier',
    [ApiErrorModel.ERROR_CODES.PROJECT_NEEDS_START_DATE_SET]:
      'Proiectul ar trebui să aibă data de începere stabilită',
    [ApiErrorModel.ERROR_CODES.INVALID_MAINTENANCE_PROCEDURE_ID]:
      'Id-ul procedurii de întreținere nevalid',
    [ApiErrorModel.ERROR_CODES.INVALID_EVENT_ID]: 'ID eveniment nevalid',
    [ApiErrorModel.ERROR_CODES.INVALID_OPERATION_ID]: 'Id operațiune nevalid',
    [ApiErrorModel.ERROR_CODES.INVALID_LABEL_ID]: 'ID de etichetă nevalid',
    [ApiErrorModel.ERROR_CODES.INVALID_PARAMETER_ID]: 'ID parametru nevalid',
    [ApiErrorModel.ERROR_CODES.INVALID_DATES]: 'Date nevalide',
    [ApiErrorModel.ERROR_CODES.INVALID_PROJECT_ID]: 'ID proiect nevalid',
    [ApiErrorModel.ERROR_CODES.USER_MUST_BE_PART_OF_ORGANIZATION]:
      'Utilizatorul ar trebui să facă parte din organizație',
    [ApiErrorModel.ERROR_CODES.INVALID_ORGANIZATION_TYPE]:
      'Tip de organizație nevalid',
    [ApiErrorModel.ERROR_CODES.USER_DOES_NOT_HAVE_A_VALID_ROLE]:
      'Utilizatorul nu are un rol valid',
    [ApiErrorModel.ERROR_CODES.USER_TO_ASSIGN_NOT_FOUND]:
      'Atribuirea a eșuat. Utilizatorul nu este găsit',
    [ApiErrorModel.ERROR_CODES
      .CHAT_DOES_NOT_EXIST_OR_USER_IS_NOT_A_PARTICIPANT]:
      'Chatul nu există sau utilizatorul nu este participant',
    [ApiErrorModel.ERROR_CODES.YOU_CAN_ADD_LABELS_ONLY_TO_VISUAL_OPERATION]:
      'Puteți adăuga etichete numai la operarea vizuală',
    [ApiErrorModel.ERROR_CODES
      .YOU_CAN_ADD_PARAMETERS_ONLY_TO_PARAMETER_OPERATION]:
      'Puteți adăuga parametri numai la funcționarea parametrilor',
    [ApiErrorModel.ERROR_CODES
      .YOU_NEED_TO_SET_EITHER_EQUIPMENT_OR_EQUIPMENT_CATEGORY_GROUP_FOR_DAILYCHECK_PROCEDURE]:
      'Trebuie să setați echipament sau grup de categorie de echipamente pentru procedura de verificare zilnică',
    [ApiErrorModel.ERROR_CODES.PARENT_ORGANIZATION_TYPE_MUST_BE_PROVIDER]:
      'Ar trebui furnizat un părinte de tip organizație',
    [ApiErrorModel.ERROR_CODES.YOU_NEDD_TO_SET_EITHER_A_LABEL_OR_A_PARAMETER]:
      'Trebuie să setați fie o etichetă, fie un parametru',
    [ApiErrorModel.ERROR_CODES.USER_TO_ASIGN_IS_NOT_PART_OF_AN_ORGANIZATION]:
      'Atribuirea a eșuat. Utilizatorul nu face parte din organizație',
    [ApiErrorModel.ERROR_CODES.YOU_CAN_ACTIVATE_ONLY_PROJECTS_IN_DRAFT_STATE]:
      'Pot fi activate doar proiectele de stat proiecte',
    [ApiErrorModel.ERROR_CODES.INITIATOR_USER_NOT_FOUND]:
      'Utilizatorul inițiator nu este găsit',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING]:
      'Nu poate șterge clădirea',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING_FK_CONSTRAINT]:
      'Nu se poate șterge clădirea FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING_LEVEL]:
      'Nu se poate șterge nivelul clădirii',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING_LEVEL_FK_CONSTRAINT]:
      'Nu se poate șterge nivelul clădirii FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING_ROOM]:
      'Nu se poate șterge camera clădirii',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING_ROOM_FK_CONSTRAINT]:
      'Nu se poate șterge camera clădirii FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_ORGANIZATION]:
      'Nu se poate șterge organizația',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_ORGANIZATION_FK_CONSTRAINT]:
      'Nu se poate șterge organizația FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_SUBCONTRACTOR]:
      'Nu se poate șterge subcontractantul',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_SUBCONTRACTOR_FK_CONSTRAINT]:
      'Nu se poate șterge subcontractantul FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_USER]:
      'Nu se poate șterge utilizatorul',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_USER_FK_CONSTRAINT]:
      'Nu se poate șterge utilizatorul FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_EQUIPMENT]:
      'Nu se poate șterge echipamentul',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_EQUIPMENT_FK_CONSTRAINT]:
      'Imposibil de șters echipamentul FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_EQUIPMENT_NOT_DELETABLE]:
      'Nu se poate șterge. Echipamentul nu poate fi șters',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_EQUIPMENT_READ_ONLY]:
      'Nu se poate șterge. Echipamentul este doar citit',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_IS_READ_ONLY]:
      'Echipamentul este doar citit',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_PROJECT]:
      'Imposibil de șters proiectul',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_PROJECT_FK_CONSTRAINT]:
      'Imposibil de șters proiectul FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.USER_NOT_FOUND_IN_PROJECT]:
      'Utilizatorul nu este găsit în proiect',
    [ApiErrorModel.ERROR_CODES
      .TICKET_UPDATE_NEED_TO_SPECIFY_DUE_DATE_IF_CHANGING_PRIORITY]:
      'Actualizarea biletului a eșuat. Este necesar să se furnizeze o dată scadentă pentru a schimba prioritatea',
    [ApiErrorModel.ERROR_CODES.TICKET_NOT_FOUND]: 'Biletul nu este găsit',
    [ApiErrorModel.ERROR_CODES.TICKET_RECIPIENT_MUST_BE_SET]:
      'Este necesar destinatarul biletului',
    [ApiErrorModel.ERROR_CODES.INTERNAL_CHAT_IS_ONY_FOR_PROVIDER_USERS]:
      'Chatul intern este doar pentru furnizori',
    [ApiErrorModel.ERROR_CODES.USER_ALREADY_ACTIVE_IN_CHAT]:
      'Utilizatorul este deja activ în chat',
    [ApiErrorModel.ERROR_CODES.USER_NOT_ACTIVE_IN_CHAT]:
      'Utilizatorul nu este activ în chat',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_CATEGORY_NOT_FOUND]:
      'Categoria de echipamente nu este găsită',
    [ApiErrorModel.ERROR_CODES.PROCUREMENT_NOT_FOUND]:
      'Achizitia nu este gasita',
    [ApiErrorModel.ERROR_CODES.FILE_NOT_FOUND]: 'Fișierul nu este găsit',
    [ApiErrorModel.ERROR_CODES.EMAIL_IN_USE]: 'E-mailul este deja utilizat',
    [ApiErrorModel.ERROR_CODES.DAILYCHECK_GROUP_NOT_FOUND]:
      'Grupul de verificare zilnică nu este găsit',
    [ApiErrorModel.ERROR_CODES.DAILYCHECK_GROUP_EXISTS]:
      'Grupul de verificare zilnică există',
    [ApiErrorModel.ERROR_CODES.WORK_ORDER_NOT_FOUND]:
      'Comanda de lucru nu este găsită',
    [ApiErrorModel.ERROR_CODES.TICKET_INVALID_ORGANIZATION_TYPE]:
      'Tichetul are un tip de organizare nevalid',
    [ApiErrorModel.ERROR_CODES.RESTRICTED_FROM_EXTERNAL_CHAT]:
      'A fost restricționat de la chatul extern',
    [ApiErrorModel.ERROR_CODES
      .YOU_NEED_TO_HAVE_AT_LEAST_ONE_USER_FROM_THE_RECIPIENT_ORG_IN_CHAT]:
      'Trebuie să aveți cel puțin un utilizator din organizația destinatară în chat',
    [ApiErrorModel.ERROR_CODES.TICKET_STATUS_CHANGE_INVALID]:
      'Noul statut al biletului nu este valid',
    [ApiErrorModel.ERROR_CODES.USER_NOT_IN_ORGANIZATION]:
      'Utilizatorul nu este în organizație',
    [ApiErrorModel.ERROR_CODES.DC_EVENT_NOT_FOUND]:
      'Evenimentul DC nu este găsit',
    [ApiErrorModel.ERROR_CODES.PERSONAL_AGENDA_DOES_NOT_EXIST]:
      'Agenda personală nu există',
    [ApiErrorModel.ERROR_CODES
      .PERSONAL_AGENDA_REMINDER_DATE_MUST_BE_BEFORE_START_DATE]:
      'Data de reamintire a agendei personale trebuie să fie înaintea datei de începere',
    [ApiErrorModel.ERROR_CODES
      .PERSONAL_AGENDA_MUST_RECEIVE_BOTH_START_AND_END_DATES]:
      'Agenda personală ar trebui să primească atât date de început, cât și date de încheiere',
    [ApiErrorModel.ERROR_CODES
      .PERSONAL_AGENDA_START_DATE_MUST_BE_BEFORE_END_DATE]:
      'Data de începere a agendei personale ar trebui să fie anterioară datei de încheiere',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_PERSONAL_AGENDA]:
      'Nu se poate șterge agenda personală',
    [ApiErrorModel.ERROR_CODES.YOU_CAN_ARCHIVE_ONLY_PROJECTS_IN_ACTIVE_STATE]:
      'Puteți arhiva numai proiectele active',
    [ApiErrorModel.ERROR_CODES.STANDARD_PROJECT_CATEGORY_EXISTS]:
      'Categorii standard de proiecte există deja',
    [ApiErrorModel.ERROR_CODES.USER_CANNOT_BE_ACTIVATED]:
      'Utilizatorul nu poate fi activat',
    [ApiErrorModel.ERROR_CODES.USER_TO_UPDATE_HAS_HIGHER_CLEARANCE]:
      'Utilizatorul are un spațiu liber mai mare',
    [ApiErrorModel.ERROR_CODES.USER_TO_DEACTIVATE_HAS_OPEN_TICKETS]:
      'Utilizatorul a deschis bilete',
    [ApiErrorModel.ERROR_CODES.USER_TO_DEACTIVATE_HAS_OPEN_PROCUREMENTS]:
      'Utilizatorul a deschis achiziții',
    [ApiErrorModel.ERROR_CODES.USER_TO_DEACTIVATE_HAS_UNIQUE_ROLE_IN_PROJECT]:
      'Utilizatorul are un rol unic în proiect',
    [ApiErrorModel.ERROR_CODES.BOTH_EQUIPMENTS_MUST_BE_IN_SAME_PROJECT]:
      'Ambele echipamente ar trebui să fie în același proiect',
    [ApiErrorModel.ERROR_CODES.DAILYCHECK_NOT_FOUND]:
      'Dailycheck nu este găsit',
    [ApiErrorModel.ERROR_CODES.PMP_EVENT_NOT_FOUND]:
      'Evenimentul Pmp nu a fost găsit',
    [ApiErrorModel.ERROR_CODES
      .YOU_CAN_DISABLE_MAINTENANCE_PROCEDURES_ONLY_IN_ACTIVE_PRJ]:
      'Puteți dezactiva procedurile de întreținere numai în proiectul activ',
    [ApiErrorModel.ERROR_CODES
      .YOU_CAN_ENABLE_MAINTENANCE_PROCEDURES_ONLY_IN_ACTIVE_PRJ]:
      'Puteți activa procedurile de întreținere numai în proiectul activ',
    [ApiErrorModel.ERROR_CODES.CHAT_MESSAGE_NOT_FOUND]: 'Message is not found',
    [ApiErrorModel.ERROR_CODES.CHAT_MESSAGE_AUTH_USER_NOT_OWNER_OF_MESSAGE]:
      'Utilizatorul nu este proprietarul mesajului',
    [ApiErrorModel.ERROR_CODES.CHAT_IS_READ_ONLY]:
      'Chatul este numai pentru citire',
    [ApiErrorModel.ERROR_CODES.COULD_NOT_SEND_EMAIL]:
      'Nu s-a putut trimite e-mailul',
    [ApiErrorModel.ERROR_CODES.PROCUREMENT_INVALID_ORGANIZATION_TYPE]:
      'Achiziția are un tip de organizație nevalid',
    [ApiErrorModel.ERROR_CODES.INVENTORY_ITEM_NOT_FOUND]:
      'Articolul din inventar nu a fost găsit',
    [ApiErrorModel.ERROR_CODES.INVENTORY_ITEM_DELETE_ERROR]:
      'Nu se poate șterge un articol de inventar',
    [ApiErrorModel.ERROR_CODES.INVENTORY_INVALID_QUANTITY]:
      'Inventarul are o cantitate nevalidă',
    [ApiErrorModel.ERROR_CODES.INVENTORY_USER_UNAUTHORIZED_TO_MANAGE_TOOLS]:
      'Utilizator neautorizat să gestioneze instrumentele',
    [ApiErrorModel.ERROR_CODES.MUST_DEFINE_DAILYCHECK_GROUP]:
      'Trebuie să definiți grupuri și proceduri zilnice de verificare',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_UNIT]:
      'Această unitate este în uz',
  },
};
