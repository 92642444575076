export default {
  user: {
    itself: 'User',
    plural: {
      itself: 'Users',
    },
    list: {
      showSuspended: 'Show suspended users',
    },
    addNewUser: 'Add new user',
    editUser: 'Edit user',
    saveUser: 'Save user',
    saveNewUser: 'Save new user',
    emptyUserList: 'Hit the blue plus button and start adding users',
    attributes: {
      phone: {
        itself: 'Phone',
        '*': 'Phone *',
        required: 'Phone is required',
      },
      lastSeen: {
        itself: 'Last seen',
      },
      status: {
        itself: 'Status',
      },
    },
    role: {
      superAdmin: 'Super admin',
      admin: 'Headquarter Team',
      coordinator: 'Senior Facility Manager',
      projectManager: 'Facility Manager',
      assistantProjectManager: 'Junior Facility Manager',
      technician: 'Technician',
      procurement: 'Procurement Specialist',
      beneficiary: 'Beneficiary',
      tenant: 'Tenant',
    },
    activateUser: 'Activate user',
    suspendUser: 'Suspend user',
    userProfile: 'User profile',
  },
};
