export const GET = Object.freeze({
  REQUEST: 'dailyCheckParameter/GET_REQUEST',
  SUCCESS: 'dailyCheckParameter/GET_SUCCESS',
  ERROR: 'dailyCheckParameter/GET_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'dailyCheckParameter/FIND_REQUEST',
  SUCCESS: 'dailyCheckParameter/FIND_SUCCESS',
  ERROR: 'dailyCheckParameter/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'dailyCheckParameter/CREATE_REQUEST',
  SUCCESS: 'dailyCheckParameter/CREATE_SUCCESS',
  ERROR: 'dailyCheckParameter/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'dailyCheckParameter/UPDATE_REQUEST',
  SUCCESS: 'dailyCheckParameter/UPDATE_SUCCESS',
  ERROR: 'dailyCheckParameter/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'dailyCheckParameter/DELETE_REQUEST',
  SUCCESS: 'dailyCheckParameter/DELETE_SUCCESS',
  ERROR: 'dailyCheckParameter/DELETE_ERROR',
});

export const RESET_STATE = 'dailyCheckParameter/RESET_STATE';

export const GET_PARAMETERS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_PARAMETER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_PARAMETER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_PARAMETER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_PARAMETER_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});
