import ApiResourceRequest from './ApiResourceRequest';
import RealmModel from '../models/RealmModel';
import ListingResponse from './ListingResponse';
import Pager from '../helpers/Pager';

export class RoleRequest extends ApiResourceRequest {
  get apiResource() {
    return 'roles';
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_ORGANIZATION_TYPE: `${this.apiRoute}/by-organization-type/{organization_type}`,
    };
  }

  async getByOrganizationType(
    organizationType,
    pager = new Pager(),
    query = {}
  ) {
    if (!Object.values(RealmModel.TYPES).includes(organizationType)) {
      throw new Error(
        `organizationType must be one of ${Object.values(RealmModel.TYPES)}`
      );
    }
    const paginationParams = this.getPaginationParams(pager);
    const orderParams = this.getOrderParams(query.order);
    const response = await this.request(
      'GET',
      this.routes.GET_BY_ORGANIZATION_TYPE.replace(
        '{organization_type}',
        organizationType
      ) +
        paginationParams +
        orderParams
    );

    return new ListingResponse(response?.data);
  }
}

export const roleRequest = new RoleRequest();
