import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NotificationIcon from '../Icons/NotificationIcon';

const CountWrapper = styled.div`
  border: 1px solid
    ${({ theme, active, hasNewNotifications }) => {
      if (!hasNewNotifications || !active) {
        return 'transparent';
      }

      return theme.palette.primary.main;
    }};
  background: ${({ theme, active, hasNewNotifications }) => {
    if (!hasNewNotifications) {
      return 'transparent';
    }
    return active
      ? theme.palette.primary.light
      : theme.palette.themeCommon.red.main;
  }};
  display: flex;
  align-items: center;
  gap: 2px;
  width: fit-content;
  padding: 4px ${({ isBiggerThanMax }) => (isBiggerThanMax ? '14px' : '6px')}
    4px 4px;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;

  .icon-wrap {
    svg {
      path {
        stroke: ${({ theme, active, hasNewNotifications }) => {
          if (!hasNewNotifications) {
            return theme.palette.grey[1900];
          }

          return active
            ? theme.palette.primary.main
            : theme.palette.themeCommon.red.light;
        }};

        fill: ${({ theme, active, hasNewNotifications }) => {
          if (!hasNewNotifications || active) {
            return theme.palette.grey[1900];
          }

          return theme.palette.themeCommon.red.light;
        }};
      }
    }
  }

  .count-wrap {
    .count {
      font-size: ${({ theme }) =>
        theme.typography.pxToRem(theme.typography.fontSize + 1)};
      color: ${({ theme, active }) =>
        active
          ? theme.palette.primary.main
          : theme.palette.themeCommon.red.light};
      font-weight: ${({ theme }) => theme.typography.fontWeight + 100};
      line-height: 24px;
      position: relative;

      &::after {
        content: '+';
        position: absolute;
        right: -8px;
        top: -7px;
        display: ${({ isBiggerThanMax }) =>
          isBiggerThanMax ? 'block' : 'none'};
      }
    }
  }
`;

const NotificationsCount = ({
  count,
  wrapperClassName,
  icon,
  active,
  withIcon,
}) => {
  const countMaxValue = 999;
  const isBiggerThanMax = count > countMaxValue;
  const hasNewNotifications = count > 0;
  const { t } = useTranslation();

  const stringCount = isBiggerThanMax ? `${countMaxValue}` : `${count}`;

  return (
    <>
      {withIcon ? (
        <CountWrapper
          isBiggerThanMax={isBiggerThanMax}
          hasNewNotifications={hasNewNotifications}
          active={active}
          className={wrapperClassName}
        >
          <div className="icon-wrap">{icon}</div>

          {hasNewNotifications && (
            <div className="count-wrap">
              <span className="count">{stringCount}</span>
            </div>
          )}
        </CountWrapper>
      ) : (
        <>{t('general.notifications')}</>
      )}
    </>
  );
};

NotificationsCount.propTypes = {
  count: PropTypes.number,
  wrapperClassName: PropTypes.string,
  icon: PropTypes.node,
  active: PropTypes.bool,
  withIcon: PropTypes.bool,
};
NotificationsCount.defaultProps = {
  count: 0,
  wrapperClassName: 'wrapperClassName',
  icon: <NotificationIcon />,
  active: false,
  withIcon: true,
};

export default NotificationsCount;
