import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { hasScrollbarVisible } from '../../../utils/helpers';

const FULL_INTERSECTION = 1.0;
const INTERSECTION_DEBOUNCE_TIME = 250;

const SCROLL_DIRECTION = Object.freeze({
  UP: 'up',
  DOWN: 'down',
});

const Target = styled.div`
  opacity: 0;
`;

const InfiniteScroll = ({
  intersectionHandler,
  listItems,
  scrollDirection,
  scrollableContainerRef,
  firstMessageRef,
  isAllowedToScroll,
  noListItemsElement,
}) => {
  const [targetNode, setTargetNode] = useState(null);
  const scrollableContainerNode = scrollableContainerRef.current;
  const [intersectionConfig, setIntersectionConfig] = useState(null);
  const isScrollDown = scrollDirection === SCROLL_DIRECTION.DOWN;

  useEffect(() => {
    if (!isAllowedToScroll) {
      return undefined;
    }

    const options = {
      root: scrollableContainerNode,
      rootMargin: '0px',
      threshold: FULL_INTERSECTION,
    };

    const observer = new IntersectionObserver((entry) => {
      setIntersectionConfig(entry);
    }, options);

    if (targetNode) {
      observer.observe(targetNode);
    }

    return () => {
      if (targetNode) {
        observer.unobserve(targetNode);
      }
    };
  }, [scrollableContainerNode, targetNode, isAllowedToScroll]);

  useEffect(() => {
    const handleObserver = (entities) => {
      const target = entities[0];

      if (target.isIntersecting) {
        const hasWrapperScrollbarVisible = hasScrollbarVisible(
          scrollableContainerNode
        );

        if (!scrollableContainerNode || !hasWrapperScrollbarVisible) {
          return;
        }

        if (!isScrollDown) {
          if (!firstMessageRef) {
            return;
          }

          firstMessageRef?.current?.scrollIntoView();
        }

        intersectionHandler();
      }
    };

    const debounceId = setTimeout(() => {
      intersectionConfig && handleObserver(intersectionConfig);
    }, INTERSECTION_DEBOUNCE_TIME);

    return () => {
      clearTimeout(debounceId);
    };
  }, [
    firstMessageRef,
    intersectionConfig,
    intersectionHandler,
    isScrollDown,
    scrollableContainerNode,
  ]);

  const targetElement = (
    <Target className="target" ref={(ref) => setTargetNode(ref)}>
      border
    </Target>
  );

  return (
    <div className="infinity-scroll-container">
      {!isScrollDown && targetElement}

      {!!listItems.length && (
        <div className="infinity-scroll-list">{listItems}</div>
      )}
      {!listItems.length && noListItemsElement}

      {isScrollDown && targetElement}
    </div>
  );
};

InfiniteScroll.propTypes = {
  intersectionHandler: PropTypes.func,
  listItems: PropTypes.arrayOf(PropTypes.node),
  scrollDirection: PropTypes.oneOf(Object.values(SCROLL_DIRECTION)),
  scrollableContainerRef: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.object,
  }).isRequired,
  firstMessageRef: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.object,
  }),
  isAllowedToScroll: PropTypes.bool,
  noListItemsElement: PropTypes.node,
};
InfiniteScroll.defaultProps = {
  intersectionHandler: () => {
    // eslint-disable-next-line no-console
    console.log('default intersectionHandler');
  },
  listItems: [],
  scrollDirection: SCROLL_DIRECTION.DOWN,
  firstMessageRef: null,
  isAllowedToScroll: true,
  noListItemsElement: <></>,
};

export default memo(InfiniteScroll);
