export default {
  general: {
    mobilePhoneNumber: 'Telefonul mobil',
    uploadImage: 'Încarcă imaginea',
    cancel: 'Renunță',
    save: 'Salvează',
    download: 'Descarca',
    delete: 'Șterge',
    remove: 'Șterge',
    edit: 'Modifică',
    viewDetails: 'Vezi detalii',
    no: 'Nu',
    yes: 'Da',
    by: 'De {{closedBy}}',
    accept: 'Acceptă',
    reject: 'Respinge',
    disable: 'Dezactivați',
    enable: 'Permite',
    duplicate: 'Duplică',

    created: 'Creat la',
    updated: 'Actualizat la',

    online: 'Connection is restored.',
    offline:
      'You are disconnected. You will not be able to save changes until your connection is restored.',

    youHaveUnsavedChanges: 'Există modificări nesalvate',
    discard: 'Renunță',
    saveChanges: 'Salvează modificări',
    savingIsInProgress: 'Salvarea este în curs',
    myProfile: 'Profilul meu',
    editMyProfile: 'Modifică profilul meu',
    viewAll: 'Vizualizează tot',
    uploadFile: 'Încarcă fișier',
    submitFeedback: 'Trimite feedback',
    rateExperience: 'Te rugăm să evaluezi experiența ta',
    rejectionReason: 'Te rugăm să trimiți un motiv pentru respingere',

    summary: 'Rezumat',
    viewQr: 'Vizualizați QR',

    itemCanNotBeSet: `{{item}} nu poate fi setat`,

    backToList: 'Înapoi la listă',

    errors: {
      endDateShouldBeGreaterThanStart:
        'Data de încheiere ar trebui să fie mai mare decât data de început',
      startDateShouldBeGreaterThanToday:
        'Date de start trebuie să fie mai mare ca ziua curentă',
      startDateRequiredIfEndDate:
        'Date de start este obligatorie dacă data de încheiere există',
    },

    subject: 'Subiect',
    date: 'Data',
    startDate: 'Data de început',
    endDate: 'Data de încheiere',
    dueDate: 'Data scadenței',
    createdAt: 'Creat la',
    updatedAt: 'Actualizat la',
    creator: 'Creator',
    user: 'Utilizator',
    status: 'Stare',

    form: {
      address: {
        itself: 'Adresă',
        '*': 'Adresă *',
        required: 'Adresa e obligatorie',
      },
      city: {
        itself: 'Oraș',
        '*': 'oraș *',
        required: 'Orașul e obligatoriu',
      },
      state: {
        itself: 'Județ',
        '*': 'Județ *',
        required: 'Județul e obligatoriu',
      },
      addressLineOne: {
        itself: 'Adresa 1',
        '*': 'Adresă linia 1 *',
        required: 'Adresă linia 1 e obligatorie',
      },
      addressLineTwo: {
        itself: 'Adresa 2 (opțional)',
      },
      emailAddress: {
        itself: 'Adresă de email',
        '*': 'Adresă de emails *',
        required: 'Adresa de email e obligatorie',
      },
      phone: {
        itself: 'Telefon',
        '*': 'Telefon *',
        required: 'Telefonul e obligatoriu',
      },
      profilePhoto: {
        itself: 'Poză de profil',
        '*': 'Poză de profil *',
        required: 'Poza de profil e obligatorie',
      },
      postCode: {
        itself: 'Cod poștal',
        '*': 'Cod poștal *',
        required: 'Codul poștal e obligatoriu',
      },
      country: {
        itself: 'Țară',
        '*': 'Țară *',
        required: 'Țara e obligatorie',
      },
      name: {
        itself: 'Nume',
        '*': 'Nume *',
        required: 'Numele e obligatoriu',
      },
      firstName: {
        itself: 'Prenume',
        '*': 'Prenume *',
        required: 'Prenumele e obligatoriu',
      },
      lastName: {
        itself: 'Nume',
        '*': 'Nume *',
        required: 'Numele e obligatoriu',
      },
      email: {
        itself: 'Email',
        '*': 'Email *',
        required: 'Email-ul e obligatoriu',
        plural: {
          itself: 'Email-uri',
          '*': 'Email-uri *',
          required: 'Email-urile sunt obligatorii',
        },
        addAnother: 'Adaugă un alt email',
      },
      summary: {
        itself: 'Rezumat',
        '*': 'Rezumat *',
        required: 'Rezumatul este necesar',
      },
      subject: {
        itself: 'Subiect',
        '*': 'Subiect *',
        required: 'Subiectul este obligatoriu',
      },
      user: {
        itself: 'Utilizator',
        '*': 'Utilizator *',
        required: 'Utilizatorul este necesar',
      },
      date: {
        itself: 'Data',
        '*': 'Data *',
        required: 'Data este obligatorie',
      },
      description: {
        '*': 'Descriere *',
        itself: 'Descriere',
        required: 'Este necesară descrierea',
      },
      type: {
        '*': 'Tip *',
        itself: 'Tip',
        required: 'Tipul este obligatoriu',
        plural: 'Tipuri',
      },
      dueDate: {
        '*': 'Data scadenței *',
        itself: 'Data scadenței',
        required: 'Data scadentă este necesară',
      },
      startDate: 'Data de început',
      endDate: 'Data de încheiere',
    },
    settings: 'Setări',
    started: 'A început',
    statusChangedTo: 'Starea a fost schimbată în {{status}}',
    all: 'Toate',
    viewLog: 'Vizualizați jurnalele',
    noItemsFound: 'Nu au fost gasite articolele',
    location: 'Locație',
    addNew: 'Adăuga nou',
    description: 'Descriere',

    export: 'Export',
    search: 'Căutare',

    active: 'Activ',
    inactive: 'Inactiv',

    loadMore: 'Incarca mai mult',
    filters: {
      locations: 'Locații',
      frequency: 'Frecvență',
      equipmentCategory: 'Categoria echipamentelor',
      inactiveEvents: 'Evenimente inactive',
      disabledEvents: 'Evenimente dezactivate',
      type: 'Tip',
    },
    of: ' din {{total}}',
    printQr: 'Tipăriți QR',
    never: 'Nu',
    rows: 'Rânduri',
    noLocationSet: 'Nicio locație setată',
    conversations: 'Conversații',
    external: 'Extern',
    internal: 'Intern',
    option: 'opțiune',
    opened: 'Deschis',
    organizations: 'Organizații',
    today: 'Astăzi',
    yesterday: 'Ieri',
    last7Days: 'Ultimele 7 zile',
    due: 'Datorată',
    tomorrow: 'Mâine',
    interval: 'Interval',
    equipments: 'Echipamente',
    frequency: {
      monthly: 'Lunar',
      quarterly: 'Trimestrial',
      semiannually: 'Semi anual',
      annually: 'Anual',
      weekly: 'Săptămânal',
    },
    editDetails: 'Editează detaliile',
    email: 'E-mail',
    address: 'Adresa',
    entries: '{{count}} intrări',
    log: 'Log',
    recent: 'Recent',
    selectParticipants: 'Selectați participanții',
    uploadImages: 'Incarca Imagini',
    notifications: 'Notificări',
    logout: 'Deconectare',
    dismissAll: 'Șterge notificari',
    noNotifications: 'Nu există încă notificări',
    type: {
      hard: 'Hard',
      soft: 'Soft',
    },
    openedBy: 'Deschis de',
    notSet: 'Nu setat',
    addTeamMember: 'Adăugați un membru al echipei',
    first: 'Primul',
    last: 'Ultimul',
    receiveEmailNotifications: 'Primiți notificări prin e-mail',

    filter: 'Filtru',
    showArchivedEvents: 'Afișați evenimentele arhivate',
    showDraftEvents: 'Afișați evenimentele nefinalizate',

    primaryImage: 'Imagine principală',
    weatherData: 'Date vreme',
  },
};
