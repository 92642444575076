export default {
  chat: {
    itself: 'Chat',
    plural: {
      itself: 'Chats',
    },
    noMessagesYet: 'No messages yet',
    newMessages: 'New messages',
    selectConversation: 'Select a conversation first',
    channels: 'Channels',
    groupChatWith: 'Group chat with',
    latestMessages: 'Latest messages',
    startMessaging: 'Start messaging',
    messages: 'Messages',
    projectChat: 'Project chat',
    typeSomething: 'Type something ...',
    backToChannels: 'Back To Channels',
    viewChat: 'View chat',
  },
};
