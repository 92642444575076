import { lazy } from 'react';
import PATH from 'routes/paths';

const ProjectMaintenancePlan = lazy(() =>
  import('containers/project/maintenancePlan/ProjectMaintenancePlan')
);
const DraftProjectPage = lazy(() =>
  import('components/layout/mainPages/adminPages/DraftProjectPage')
);
const ProjectList = lazy(() => import('containers/project/ProjectList'));

const ProjectSettings = lazy(() =>
  import('stories/ui/ProjectSettings/ProjectSettings')
);

const DraftProjectList = lazy(() =>
  import('containers/project/DraftProjectList')
);

const ArchivedProjectList = lazy(() =>
  import('containers/project/ArchivedProjectList')
);

const CreateProjectModal = lazy(() =>
  import('containers/project/CreateProjectModal')
);
const LayoutSection = lazy(() =>
  import('containers/project/layout/LayoutSectionWrapper')
);
const EquipmentList = lazy(() =>
  import('containers/project/equipment/list/EquipmentList')
);
const Equipment = lazy(() =>
  import('containers/project/equipment/one/Equipment')
);
const SubcontractorSection = lazy(() =>
  import('containers/project/subcontractor/SubcontractorSection')
);
const DashboardSection = lazy(() =>
  import('containers/project/dashboard/ProjectDashboard')
);
const TeamMemberList = lazy(() =>
  import('containers/project/user/teamMember/TeamMemberList')
);
const TenantList = lazy(() =>
  import('containers/project/user/tenant/TenantList')
);
const BeneficiaryList = lazy(() =>
  import('containers/project/user/beneficiary/BeneficiaryList')
);
const DailyCheckTourList = lazy(() =>
  import('containers/project/dailyCheck/tour/DailyCheckTourList')
);
const DailyCheckTour = lazy(() =>
  import('containers/project/dailyCheck/tour/DailyCheckTour')
);
const DailyCheckTourGroup = lazy(() =>
  import('containers/project/dailyCheck/tour/group/DailyCheckTourGroup')
);
const DailyProcedureGroupList = lazy(() =>
  import(
    'containers/project/dailyCheck/dailyProcedures/DailyProcedureGroupList'
  )
);
const DailyProcedureGroup = lazy(() =>
  import('containers/project/dailyCheck/dailyProcedures/DailyProcedureGroup')
);
const Ticket = lazy(() =>
  import('components/layout/mainPages/pmPages/ticket/Ticket')
);
const TicketList = lazy(() =>
  import('components/layout/mainPages/pmPages/ticket/TicketList')
);
const Procurement = lazy(() =>
  import('components/layout/mainPages/pmPages/procurement/Procurement')
);
const ProcurementList = lazy(() =>
  import('components/layout/mainPages/pmPages/procurement/ProcurementList')
);
const InventoryList = lazy(() =>
  import('containers/project/inventory/InventoryList')
);
const WarrantyTrackerList = lazy(() =>
  import('containers/project/warrantyTracker/WarrantyTrackerList')
);
const ActivityReportList = lazy(() =>
  import('containers/project/activityReport/ActivityReportList')
);
const ReportsList = lazy(() =>
  import('containers/project/reports/ReportsList')
);
const Report = lazy(() => import('containers/project/reports/Report'));

export const PROJECT_DASHBOARD = {
  component: DashboardSection,
  path: PATH.PROJECT.ONE,
  exact: false,
  isProtected: true,
};

export const PROJECT_SETTINGS = {
  component: ProjectSettings,
  path: PATH.PROJECT.SETTINGS.SHOW,
  exact: true,
  isProtected: true,
};

export const PROJECT_MAINTENANCE_PLAN_LIST = {
  component: ProjectMaintenancePlan,
  path: PATH.PROJECT.MAINTENANCE_PLAN.LIST,
  exact: false,
  isProtected: true,
};

export const PROJECT_LAYOUT = {
  component: LayoutSection,
  path: PATH.PROJECT.LAYOUT.SHOW,
  exact: false,
  isProtected: true,
};

export const PROJECT_EQUIPMENT_LIST = {
  component: EquipmentList,
  path: PATH.PROJECT.EQUIPMENT.LIST,
  exact: false,
  isProtected: true,
};

export const PROJECT_EQUIPMENT = {
  component: Equipment,
  path: PATH.PROJECT.EQUIPMENT.ONE,
  exact: false,
  isProtected: true,
};

export const PROJECT_EQUIPMENT_UPDATE = {
  component: EquipmentList,
  path: PATH.PROJECT.EQUIPMENT.UPDATE,
  exact: false,
  isProtected: true,
};

export const PROJECT_SUBCONTRACTOR = {
  component: SubcontractorSection,
  path: PATH.PROJECT.SUBCONTRACTOR.LIST,
  exact: false,
  isProtected: true,
};

export const PROJECT_TEAM_MEMBER_LIST = {
  component: TeamMemberList,
  path: PATH.PROJECT.USER.TEAM_MEMBER.LIST,
  exact: false,
  isProtected: true,
};

export const PROJECT_TENANT_LIST = {
  component: TenantList,
  path: PATH.PROJECT.USER.TENANT.LIST,
  exact: false,
  isProtected: true,
};

export const PROJECT_BENEFICIARY_LIST = {
  component: BeneficiaryList,
  path: PATH.PROJECT.USER.BENEFICIARY.LIST,
  exact: false,
  isProtected: true,
};

export const PROJECT_LIST = {
  component: ProjectList,
  path: PATH.PROJECT.LIST,
  exact: true,
  isProtected: true,
};

export const PROJECT_DRAFTS = {
  component: DraftProjectList,
  path: PATH.PROJECT.DRAFTS,
  exact: true,
  isProtected: true,
};

export const PROJECT_ARCHIVED = {
  component: ArchivedProjectList,
  path: PATH.PROJECT.ARCHIVED,
  exact: true,
  isProtected: true,
};

export const PROJECT_CREATE = {
  component: CreateProjectModal,
  path: PATH.PROJECT.CREATE,
  exact: true,
  isProtected: true,
};

export const PROJECT_UPDATE = {
  component: DraftProjectPage,
  path: PATH.PROJECT.UPDATE,
  exact: false,
  isProtected: true,
};

export const PROJECT_DAILY_CHECK_TOUR_LIST = {
  component: DailyCheckTourList,
  path: PATH.PROJECT.DAILY_CHECK.TOUR.LIST,
  exact: true,
  isProtected: true,
};

export const PROJECT_DAILY_CHECK_TOUR_ONE = {
  component: DailyCheckTour,
  path: PATH.PROJECT.DAILY_CHECK.TOUR.ONE,
  exact: true,
  isProtected: true,
};

export const PROJECT_DAILY_CHECK_TOUR_GROUP_ONE = {
  component: DailyCheckTourGroup,
  path: PATH.PROJECT.DAILY_CHECK.TOUR.GROUP.ONE,
  exact: true,
  isProtected: true,
};

export const PROJECT_DAILY_CHECK_TOUR_GROUP_EVENT_ONE = {
  component: DailyCheckTourGroup,
  path: PATH.PROJECT.DAILY_CHECK.TOUR.GROUP.EVENT.ONE,
  exact: true,
  isProtected: true,
};

export const PROJECT_DAILY_PROCEDURES_ONE = {
  component: DailyProcedureGroup,
  path: PATH.PROJECT.DAILY_CHECK.SETTINGS.GROUP.ONE,
  exact: false,
  isProtected: true,
};

export const PROJECT_DAILY_PROCEDURES_LIST = {
  component: DailyProcedureGroupList,
  path: PATH.PROJECT.DAILY_CHECK.SETTINGS.GROUP.LIST,
  exact: false,
  isProtected: true,
};

const TICKET = {
  component: Ticket,
  path: PATH.PROJECT.TICKET.ONE,
  exact: true,
  isProtected: true,
};

const TICKET_LIST = {
  component: TicketList,
  path: PATH.PROJECT.TICKET.LIST,
  exact: false,
  isProtected: true,
};
const PROCUREMENT = {
  component: Procurement,
  path: PATH.PROJECT.PROCUREMENT.ONE,
  exact: false,
  isProtected: true,
};

const PROCUREMENT_LIST = {
  component: ProcurementList,
  path: PATH.PROJECT.PROCUREMENT.LIST,
  exact: false,
  isProtected: true,
};

const INVENTORY_LIST = {
  component: InventoryList,
  path: PATH.PROJECT.INVENTORY.LIST,
  exact: false,
  isProtected: true,
};

const WARRANTY_TRACKER_LIST = {
  component: WarrantyTrackerList,
  path: PATH.PROJECT.WARRANTY_TRACKER.LIST,
  exact: false,
  isProtected: true,
};

const ACTIVITY_REPORT_LIST = {
  component: ActivityReportList,
  path: PATH.PROJECT.ACTIVITY_REPORT.LIST,
  exact: false,
  isProtected: true,
};

const REPORT_LIST = {
  component: ReportsList,
  path: PATH.PROJECT.REPORT.LIST,
  exact: false,
  isProtected: true,
};

const REPORT = {
  component: Report,
  path: PATH.PROJECT.REPORT.ONE,
  exact: true,
  isProtected: true,
};

export default [
  PROJECT_SETTINGS,
  PROJECT_MAINTENANCE_PLAN_LIST,
  PROJECT_LAYOUT,
  PROJECT_EQUIPMENT_UPDATE,
  PROJECT_EQUIPMENT,
  PROJECT_EQUIPMENT_LIST,
  PROJECT_SUBCONTRACTOR,
  PROJECT_TEAM_MEMBER_LIST,
  PROJECT_TENANT_LIST,
  PROJECT_BENEFICIARY_LIST,
  PROJECT_LIST,
  PROJECT_DRAFTS,
  PROJECT_ARCHIVED,
  PROJECT_CREATE,
  PROJECT_UPDATE,
  PROJECT_DAILY_CHECK_TOUR_LIST,
  PROJECT_DAILY_CHECK_TOUR_ONE,
  PROJECT_DAILY_CHECK_TOUR_GROUP_ONE,
  PROJECT_DAILY_CHECK_TOUR_GROUP_EVENT_ONE,
  PROJECT_DAILY_PROCEDURES_ONE,
  PROJECT_DAILY_PROCEDURES_LIST,
  TICKET,
  TICKET_LIST,
  PROCUREMENT,
  PROCUREMENT_LIST,
  INVENTORY_LIST,
  WARRANTY_TRACKER_LIST,
  ACTIVITY_REPORT_LIST,
  REPORT,
  REPORT_LIST,
  PROJECT_DASHBOARD,
];
