import ApiResourceRequest from './ApiResourceRequest';

export class SubcontractorCategoryRequest extends ApiResourceRequest {
  get apiResource() {
    return 'subcontractors';
  }

  get routes() {
    return {
      ...super.routes,
      GET: `${this.apiRoute}/subcontractor-categories`,
    };
  }
}

export const subcontractorCategoryRequest = new SubcontractorCategoryRequest();
