export default {
  metaTitles: {
    login: 'Autentificare',
    createPassword: 'Creare parolă',
    draftProject: 'Proiect draft',
    createProject: 'Creare proiect',
    project: 'Proiect',
    projects: 'Proiecte',
    projectList: 'Listă proiecte',
    draftProjectList: 'Listă proiecte draft',
    archivedProjectList: 'Listă proiecte arhivate',
    userList: 'Listă utilizatori',
    subcontractorList: 'Listă subcontractori',
    organizationList: 'Listă organizații',
    organizationProfile: 'Profilul organizației',
    ticketList: 'Listă tichete',
    ticket: 'Tichet',
    inventoryList: 'Listă inventar',
    standardProcedureList: 'Listă proceduri standard',
    dailyProcedureList: 'Listă proceduri zilnice',
    dailyCheckTourList: 'Listă tururi',
    dashboard: 'Dashboard',
  },
};
