import AbstractEventCatcher from '../AbstractEventCatcher';
import { procurementChatActionCreators } from '../../../store/chat/resource/actions';
import ChatPager from '../../helpers/ChatPager';

export default class ProcurementChatHistoryEventCatcher extends AbstractEventCatcher {
  static process({ historyInfo, dispatch }) {
    const pagerFromApi = new ChatPager(
      ChatPager.calculatePage(historyInfo.offset, historyInfo.limit),
      historyInfo.limit
    );

    dispatch(
      procurementChatActionCreators.setChatHistory(
        historyInfo.procurementId,
        historyInfo.chatId,
        historyInfo.messages.messages,
        pagerFromApi,
        historyInfo.messages.total
      )
    );
  }
}
