export const GET = Object.freeze({
  REQUEST: 'equipmentProjectCategory/GET_REQUEST',
  SUCCESS: 'equipmentProjectCategory/GET_SUCCESS',
  ERROR: 'equipmentProjectCategory/GET_ERROR',
});

export const GET_BY_PROJECT_ID = Object.freeze({
  REQUEST: 'equipmentProjectCategory/GET_BY_PROJECT_ID_REQUEST',
  SUCCESS: 'equipmentProjectCategory/GET_BY_PROJECT_ID_SUCCESS',
  ERROR: 'equipmentProjectCategory/GET_BY_PROJECT_ID_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'equipmentProjectCategory/FIND_REQUEST',
  SUCCESS: 'equipmentProjectCategory/FIND_SUCCESS',
  ERROR: 'equipmentProjectCategory/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'equipmentProjectCategory/CREATE_REQUEST',
  SUCCESS: 'equipmentProjectCategory/CREATE_SUCCESS',
  ERROR: 'equipmentProjectCategory/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'equipmentProjectCategory/UPDATE_REQUEST',
  SUCCESS: 'equipmentProjectCategory/UPDATE_SUCCESS',
  ERROR: 'equipmentProjectCategory/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'equipmentProjectCategory/DELETE_REQUEST',
  SUCCESS: 'equipmentProjectCategory/DELETE_SUCCESS',
  ERROR: 'equipmentProjectCategory/DELETE_ERROR',
});

export const GET_EQUIPMENT_PROJECT_CATEGORIES_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const GET_EQUIPMENT_PROJECT_CATEGORIES_BY_PROJECT_ID_ACTION = Object.freeze(
  {
    REQUEST: (payload) => ({
      type: GET_BY_PROJECT_ID.REQUEST,
      payload,
    }),
    SUCCESS: (payload) => ({
      type: GET_BY_PROJECT_ID.SUCCESS,
      payload,
      error: false,
    }),
    ERROR: (payload) => ({
      type: GET_BY_PROJECT_ID.ERROR,
      payload,
      error: true,
    }),
  }
);

export const GET_EQUIPMENT_PROJECT_CATEGORIES_WITH_GROUPS_BY_PROJECT_ID_ACTION = Object.freeze(
  {
    REQUEST: (payload) => ({
      type: GET_BY_PROJECT_ID.REQUEST,
      payload,
    }),
    SUCCESS: (payload) => ({
      type: GET_BY_PROJECT_ID.SUCCESS,
      payload,
      error: false,
    }),
    ERROR: (payload) => ({
      type: GET_BY_PROJECT_ID.ERROR,
      payload,
      error: true,
    }),
  }
);

export const FIND_EQUIPMENT_PROJECT_CATEGORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_EQUIPMENT_PROJECT_CATEGORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_EQUIPMENT_PROJECT_CATEGORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_EQUIPMENT_PROJECT_CATEGORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});
