import { lazy } from 'react';
import PATH from 'routes/paths';

const DirectMessagesTab = lazy(() =>
  import('containers/generalChat/directMessages/DirectMessagesTab')
);

const ProjectsMessagesTab = lazy(() =>
  import('containers/generalChat/projects/ProjectsMessagesTab')
);

/** Chat only admin paths */

const CHAT_CONVERSATION_LIST = {
  component: DirectMessagesTab,
  path: PATH.CHAT.CONVERSATION.LIST,
  exact: true,
  isProtected: true,
};

const CHAT_CONVERSATION_ONE = {
  component: DirectMessagesTab,
  path: PATH.CHAT.CONVERSATION.ONE,
  exact: true,
  isProtected: true,
};

const CHAT_PROJECT_CHANNELS_LIST = {
  component: ProjectsMessagesTab,
  path: PATH.CHAT.PROJECT_CHANNEL.LIST,
  exact: true,
  isProtected: true,
};

const CHAT_PROJECT_CHANNELS_ONE = {
  component: ProjectsMessagesTab,
  path: PATH.CHAT.PROJECT_CHANNEL.ONE,
  exact: true,
  isProtected: true,
};

/** Chat pm & apm paths */

const PROJECT_BASED_CHAT_CONVERSATION_LIST = {
  component: DirectMessagesTab,
  path: PATH.PROJECT.CHAT.CONVERSATION.LIST,
  exact: true,
  isProtected: true,
};

const PROJECT_BASED_CHAT_CONVERSATION_ONE = {
  component: DirectMessagesTab,
  path: PATH.PROJECT.CHAT.CONVERSATION.ONE,
  exact: true,
  isProtected: true,
};

const PROJECT_BASED_CHAT_CHANNELS_LIST = {
  component: ProjectsMessagesTab,
  path: PATH.PROJECT.CHAT.CHANNEL.LIST,
  exact: true,
  isProtected: true,
};

const PROJECT_BASED_CHAT_CHANNELS_ONE = {
  component: ProjectsMessagesTab,
  path: PATH.PROJECT.CHAT.CHANNEL.ONE,
  exact: true,
  isProtected: true,
};

export default [
  /** Chat only admin paths */
  CHAT_CONVERSATION_LIST,
  CHAT_CONVERSATION_ONE,

  CHAT_PROJECT_CHANNELS_LIST,
  CHAT_PROJECT_CHANNELS_ONE,

  /** Chat pm & apm paths */
  PROJECT_BASED_CHAT_CONVERSATION_LIST,
  PROJECT_BASED_CHAT_CONVERSATION_ONE,

  PROJECT_BASED_CHAT_CHANNELS_LIST,
  PROJECT_BASED_CHAT_CHANNELS_ONE,
];
