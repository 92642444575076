export default {
  ticket: {
    itself: 'Ticket',
    plural: {
      itself: 'Tickets',
    },
    createNewTicket: 'Create a new ticket',
    createTicket: 'Create ticket',
    participants: 'Participants',
    information: 'Information',
    priority: {
      itself: 'Priority',
      critical: 'Critical',
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      noPriority: 'No priority',
    },
    openedOn: 'Opened on',
    dueDate: 'Due date',
    lastUpdate: 'Last update',
    uploadFiles: 'Upload document, image or video',
    customDueDate: 'Custom due date',
    confirmModals: {
      cancel: 'No, cancel',
      confirmClose: 'Yes, close!',
      confirmResolve: 'Yes, resolve!',
      confirmCloseMessage: 'Are you sure you want to close this ticket?',
      confirmResolveMessage: 'Are you sure you want to resolve this ticket?',
      submitReasonClose: 'Please submit a reason for closing ..',
      submitReasonResolve: 'Please submit a reason for resolving ..',
      reasonRequired: 'Reason is required',
      acceptTicket: 'Accept ticket',
      rejectTicket: 'Reject ticket',
    },
    attributes: {
      subject: {
        itself: 'Subject',
        '*': 'Subject *',
        required: 'Subject is required',
        placeholder: 'Name your ticket',
      },
      description: {
        itself: 'Description',
        '*': 'Description *',
        required: 'Description is required',
        placeholder: 'Describe the issue',
      },
      contact: {
        itself: 'Contact',
        '*': 'Contact *',
        required: 'Contact is required',
        placeholder: 'Alternative contact information',
      },
      location: {
        itself: 'Location',
        '*': 'Location *',
        required: 'Location is required',
      },
      building: {
        itself: 'Building',
        '*': 'Building *',
        required: 'Building is required',
        select: 'Select building',
      },
      level: {
        itself: 'Level',
        '*': 'Level *',
        required: 'Level is required',
        select: 'Select level',
      },
      room: {
        itself: 'Room',
        '*': 'Room *',
        required: 'Room is required',
        select: 'Select room',
      },
      recipient: {
        itself: 'Recipient',
        '*': 'Recipient *',
        required: 'Recipient is required',
      },
      attachments: {
        itself: 'Attachments',
        '*': 'Attachments *',
        required: 'Attachments are required',
      },
      organization: {
        itself: 'Organization',
        '*': 'Organization *',
        required: 'Organization is required',
      },
      user: {
        itself: 'User',
        '*': 'User *',
        required: 'User is required',
      },
      status: {
        new: 'New',
        opened: 'Open',
        onHold: 'On hold',
        resolved: 'Resolved',
        closed: 'Closed',
        overdue: 'Overdue',
        all: 'All',
      },
    },
    selector: {
      myTickets: 'My tickets',
      allTickets: 'All tickets',
      tenantTickets: 'Tenant tickets',
      beneficiaryTickets: 'Beneficiary tickets',
    },
    progressOverview: 'Progress overview',
    chat: {
      ticketOpened: 'Ticket opened',
      ticketResolved: 'Ticket resolved. Waiting for tenant’s response ...',
      closingWasRejected: 'Closing was rejected, with the following note:',
      closingWasAccepted: 'Closing was accepted, with a',
      starRating: 'star rating',
      feedback: 'Feedback:',
      closedByManager: 'Ticket has been closed by project manager',
      closedDueToInactivity: 'Ticket has been closed due to inactivity',
      ticketOnHold: 'Ticket on hold',
      ticketReopened: 'Ticket reopened',
      hasBeenAddedAsParticipant: 'has been added as participant',
      hasBeenRemovedFromParticipants: 'has been removed from participants',
      procurementOrderWithId: 'A procurement order with ID {{id}}',
      ticketWithId: 'A ticket with ID {{id}}',
      linkedToThisTicket: 'has been linked to this ticket.',
      unlinkedFromThisTicket: 'has been unlinked from this ticket.',
      hasBeenCreated: 'has been created.',
      hasBeenClosed: 'has been closed.',
      procurementOrderOpened: 'Procurement order opened',
      procurementOrderProposalSubmitted: 'Procurement order proposal submitted',
      priceProposal: 'Price proposal: {{price}}',
      unlinkedFromThisProcurementOrder:
        'has been unlinked from this procurement order.',
      linkedToThisProcurementOrder: 'has been linked to procurement order.',
      procurementOrderClosedDueToInactivity:
        'Procurement order has been closed due to inactivity',
      procurementOrderClosedByProjectManager:
        'Procurement order has been closed by project manager',
      procurementOrderWasClosedWithA: 'Procurement order was closed, with a',
      procurementOrderWorkWasRejected:
        'Procurement order work was rejected, with the following note: order was closed, with a',
      procurementOrderMarkedWorkFinished:
        'Procurement order marked work finished',
      procurementOrderMarkedWorkInProgress:
        'Procurement order marked work in progress',
      procurementOrderAccepted: 'Procurement order accepted',
      procurementOrderRejected: 'Procurement order rejected',
      procurementOrderProposalCanceled:
        'Procurement order proposal has been canceled.',
    },
    closeTicket: 'Close ticket',
  },
};
