import {
  buildQueryKey,
  getUniqueArrayOfObjectsWithCheck,
  sortArrayByObjectDateProp,
} from 'utils/helpers';
import Pager from 'api/helpers/Pager';
import { ORDERS, STATUS } from 'utils/constants';
import DEFAULT_REQUEST from '../constants';
import {
  CREATE_ANNOUNCEMENT_TYPES,
  DELETE_ANNOUNCEMENT_TYPES,
  FIND_ANNOUNCEMENT_TYPES,
  GET_ANNOUNCEMENTS_TYPES,
  GET_PROJECT_ANNOUNCEMENTS_TYPES,
  UPDATE_ANNOUNCEMENT_TYPES,
} from './constants';
import {
  addToDictionary,
  changeListStatus,
  resetState,
  updateList,
} from '../helpers';

const initialState = {
  dictionary: {},
  list: {
    meta: {
      currentKey: DEFAULT_REQUEST,
    },
    pagination: {
      pageNumber: null,
      pageSize: null,
      total: 0,
    },
    [DEFAULT_REQUEST]: {},
  },
  projectAnnouncements: {
    dictionary: [],
    list: {
      status: null,
      error: null,
      query: null,
      pagination: {
        total: 0,
      },
    },
  },
};

export default function announcementReducer(state = initialState, action) {
  let queryKey;
  let pager;
  let listStatus;
  let error;
  let resourceModel;
  let resetToDefault;
  let id;

  switch (action.type) {
    case GET_ANNOUNCEMENTS_TYPES.REQUEST:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;
      resetToDefault = action.payload.resetToDefault;

      if (
        Pager.hasPageSizeChanged(
          state.list.pagination.pageSize,
          pager?.pageSize
        ) ||
        resetToDefault
      ) {
        return resetState(state, initialState, {
          queryKey,
          pager,
        });
      }

      if (
        [STATUS.RELOADING, STATUS.SUCCESS, STATUS.CACHED].includes(listStatus)
      ) {
        return changeListStatus(state, { pager, queryKey }, STATUS.RELOADING);
      }
      return changeListStatus(state, { pager, queryKey }, STATUS.LOADING);

    case GET_ANNOUNCEMENTS_TYPES.SUCCESS:
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
        list: updateList(state.list, action.payload),
      };

    case GET_ANNOUNCEMENTS_TYPES.ERROR:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      error = action.payload.error;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;

      return changeListStatus(
        state,
        { pager, queryKey, error },
        listStatus === STATUS.RELOADING ? STATUS.CACHED : STATUS.ERROR
      );

    case FIND_ANNOUNCEMENT_TYPES.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case FIND_ANNOUNCEMENT_TYPES.SUCCESS:
      const { resource } = action.payload;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [resource.id]: new resourceModel({
            ...state.dictionary[resource.id],
            ...resource,
            findFetched: true,
          }),
        },
      };
    case FIND_ANNOUNCEMENT_TYPES.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case UPDATE_ANNOUNCEMENT_TYPES.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case UPDATE_ANNOUNCEMENT_TYPES.SUCCESS:
      return state;
    case UPDATE_ANNOUNCEMENT_TYPES.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case CREATE_ANNOUNCEMENT_TYPES.REQUEST:
      return state;
    case CREATE_ANNOUNCEMENT_TYPES.SUCCESS:
      return state;
    case CREATE_ANNOUNCEMENT_TYPES.ERROR:
      return state;

    case DELETE_ANNOUNCEMENT_TYPES.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case DELETE_ANNOUNCEMENT_TYPES.SUCCESS:
      id = action.payload.id;

      const newDictionary = { ...state.dictionary };
      delete newDictionary[id];
      return {
        ...state,
        dictionary: newDictionary,
      };
    case DELETE_ANNOUNCEMENT_TYPES.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case GET_PROJECT_ANNOUNCEMENTS_TYPES.REQUEST: {
      return {
        ...state,
        projectAnnouncements: {
          ...state.projectAnnouncements,
          list: {
            ...state.projectAnnouncements.list,
            status: STATUS.LOADING,
          },
        },
      };
    }
    case GET_PROJECT_ANNOUNCEMENTS_TYPES.SUCCESS: {
      const currentDictionary = state.projectAnnouncements.dictionary || [];

      const newResources = action.payload.resources.map(
        (resourceParameter) =>
          new action.payload.resourceModel(resourceParameter)
      );

      return {
        ...state,
        projectAnnouncements: {
          list: {
            ...state.projectAnnouncements.list,
            status: STATUS.SUCCESS,
            pagination: {
              ...state.projectAnnouncements.list.pagination,
              total: action.payload.total,
            },
            query: {},
          },
          dictionary: sortArrayByObjectDateProp(
            getUniqueArrayOfObjectsWithCheck(
              [...currentDictionary, ...newResources],
              'id',
              (item1, item2) => item2
            ),
            'startDate',
            ORDERS.DESC
          ),
        },
      };
    }
    case GET_PROJECT_ANNOUNCEMENTS_TYPES.ERROR: {
      return {
        ...state,
        projectAnnouncements: {
          ...state.projectAnnouncements,
          list: {
            ...state.projectAnnouncements.list,
            status: STATUS.ERROR,
            error: action.payload.error,
          },
        },
      };
    }

    default:
      return state;
  }
}
