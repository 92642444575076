export default {
  metaTitles: {
    login: 'Login',
    createPassword: 'Create Password',
    draftProject: 'Draft Project',
    createProject: 'Create Project',
    project: 'Project',
    projects: 'Projects',
    projectList: 'Project List',
    draftProjectList: 'Draft Project List',
    archivedProjectList: 'Archived Project List',
    userList: 'User List',
    subcontractorList: 'Subcontractor List',
    organizationList: 'Organization List',
    organizationProfile: 'Organization Profile',
    ticketList: 'Ticket List',
    ticket: 'Ticket',
    inventoryList: 'Inventory List',
    standardProcedureList: 'Standard Procedure List',
    dailyProcedureList: 'Daily Procedure List',
    dailyCheckTourList: 'Daily Check Tour List',
    dashboard: 'Dashboard',
  },
};
