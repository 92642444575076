import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getProjectPublicData } from '../../../store/project/actions';
import {
  findProjectSelectorClosure,
  projectsPublicDataDictionaryItemErrorSelectorCreator,
  projectsPublicDataDictionaryItemInPendingSelectorCreator,
  projectsPublicDataDictionaryItemSelectorCreator,
} from '../../../store/project/selectors';
import { HTTP_STATUS_CODE } from '../../../utils/constants';
import ApiErrorModel from '../../../api/models/ApiErrorModel';
import PATH from '../../../routes/paths';
import { useSelectorCreator } from '../../../store/helpers';

export default (
  projectId,
  disableProjectsPageRedirect = false,
  fetchProjectPublicDataFlag = true
) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const project = useSelectorCreator(findProjectSelectorClosure, [projectId]);
  const projectPublicData = useSelectorCreator(
    projectsPublicDataDictionaryItemSelectorCreator,
    [projectId]
  );

  const isInPending = useSelectorCreator(
    projectsPublicDataDictionaryItemInPendingSelectorCreator,
    [projectId]
  );
  const hasError = useSelectorCreator(
    projectsPublicDataDictionaryItemErrorSelectorCreator,
    [projectId]
  );

  const projectName = project?.name;

  useEffect(() => {
    if (!fetchProjectPublicDataFlag) {
      return;
    }

    if (projectName) {
      return;
    }

    if (hasError || isInPending) {
      return;
    }

    const fetchProjectPublicData = () => {
      if (!projectPublicData?.name && projectId) {
        const redirectToProjectsPage = () => {
          history.replace(PATH.PROJECT.LIST);
        };

        dispatch(getProjectPublicData(projectId)).catch((err) => {
          // todo temp project existence checking
          if (err?.payload?.statusCode === HTTP_STATUS_CODE.NOT_FOUND) {
            ApiErrorModel.showError(
              ApiErrorModel.ERROR_CODES.PROJECT_NOT_FOUND
            );
          }

          !disableProjectsPageRedirect && redirectToProjectsPage();
        });
      }
    };

    fetchProjectPublicData();
  }, [
    dispatch,
    projectId,
    projectPublicData,
    isInPending,
    hasError,
    disableProjectsPageRedirect,
    history,
    projectName,
    fetchProjectPublicDataFlag,
  ]);

  return projectName ? project : projectPublicData;
};
