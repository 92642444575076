import AbstractModel from './AbstractModel';

export const DEFAULT_UNIT_DICTIONARY = Object.freeze({
  tensiune_v: {
    name: 'Voltage',
    measureUnit: 'V',
    symbol: 'V',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'tensiune_v',
  },
  intensitate_curent_a: {
    name: 'Current',
    measureUnit: 'A',
    symbol: 'A',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'intensitate_curent_a',
  },
  putere_kw: {
    name: 'Power',
    measureUnit: 'kW',
    symbol: 'kW',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'putere_kw',
  },
  capacitate_frigorifica_btu_h: {
    name: 'Cooling capacity',
    measureUnit: 'BTU/h',
    symbol: 'BTU/h',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'capacitate_frigorifica_btu_h',
  },
  debit_aer_m3_h: {
    name: 'Air flow',
    measureUnit: 'm3/h',
    symbol: 'm3/h',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'debit_aer_m3_h',
  },
  debit_apa_m3_h: {
    name: 'Water flow',
    measureUnit: 'm3/h',
    symbol: 'm3/h',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'debit_apa_m3_h',
  },
  temperatura_c: {
    name: 'Temperature',
    measureUnit: '°C',
    symbol: '°C',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'temperatura_c',
  },
  presiune_bar: {
    name: 'Pressure',
    measureUnit: 'bar',
    symbol: 'bar',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'presiune_bar',
  },
  presiune_pa: {
    name: 'Pressure',
    measureUnit: 'Pa',
    symbol: 'Pa',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'presiune_pa',
  },
  putere_aparenta_kwa: {
    name: 'Apparent Power',
    measureUnit: 'kVA',
    symbol: 'kVA',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'putere_aparenta_kwa',
  },
  inaltime_pompare_mca: {
    name: 'Pump Head',
    measureUnit: 'mCA',
    symbol: 'mCA',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'inaltime_pompare_mca',
  },
  indicator_nivel_procent: {
    name: 'Level check',
    measureUnit: '%',
    symbol: '%',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'indicator_nivel_procent',
  },
  factor_putere_cos_phi: {
    name: 'Power factor',
    measureUnit: 'cosϕ',
    symbol: 'cosϕ',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'factor_putere_cos_phi',
  },
  frecventa_hz: {
    name: 'Frequency',
    measureUnit: 'Hz',
    symbol: 'Hz',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'frecventa_hz',
  },
  capacitate_termica_kw: {
    name: 'Thermal Power',
    measureUnit: 'kW',
    symbol: 'kW',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'capacitate_termica_kw',
  },
  debit_fluid_m3_h: {
    name: 'Fluid flow',
    measureUnit: 'm3/h',
    symbol: 'm3/h',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'debit_fluid_m3_h',
  },
  debit_fluid_L_h: {
    name: 'Fluid flow',
    measureUnit: 'L/h',
    symbol: 'L/m',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'debit_fluid_L_h',
  },
  volum_m3: {
    name: 'Volume',
    measureUnit: 'm3',
    symbol: 'm3',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'volum_m3',
  },
  capacitate_L: {
    name: 'Capacity ',
    measureUnit: 'L',
    symbol: 'L',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'capacitate_L',
  },
  presiune_mbar: {
    name: 'Gas pressure ',
    measureUnit: 'mbar',
    symbol: 'mbar',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'presiune_mbar',
  },
  putere_instalata_W: {
    name: 'Electric Power',
    measureUnit: 'W',
    symbol: 'W',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'putere_instalata_W',
  },
  putere_instalata_kW: {
    name: 'Electric Power',
    measureUnit: 'kW',
    symbol: 'kW',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'putere_instalata_kW',
  },
  putere_condensatoare_kVAr: {
    name: 'Condensator power',
    measureUnit: 'kVAr',
    symbol: 'kVAr',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'putere_condensatoare_kVAr',
  },
  diametru_phi: {
    name: 'Diameter',
    measureUnit: 'Ø',
    symbol: 'Ø',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'diametru_phi',
  },
  lungime_m: {
    name: 'Lenght',
    measureUnit: 'm',
    symbol: 'm',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'lungime_m',
  },
  duritate_apa_dH: {
    name: 'Water hardness',
    measureUnit: '°dH',
    symbol: '°dH',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'duritate_apa_dH',
  },
  duritate_apa_fH: {
    name: 'Water hardness',
    measureUnit: '°fH ',
    symbol: '°fH ',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'duritate_apa_fH',
  },
  concentratie_CO2_ppm: {
    name: 'CO2 concetration',
    measureUnit: 'ppm',
    symbol: 'ppm',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'concentratie_CO2_ppm',
  },
  nivel_iluminare_lx: {
    name: 'Lighting level',
    measureUnit: 'lx',
    symbol: 'lx',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'nivel_iluminare_lx',
  },
  ore_operare_h: {
    name: 'Operating hours',
    measureUnit: 'h',
    symbol: 'h',
    displayName() {
      return `${this.name} (${this.measureUnit})`;
    },
    dbName: 'ore_operare_h',
  },
});

class UnitModel extends AbstractModel {
  get isFromDefaultDictionary() {
    return Object.keys(DEFAULT_UNIT_DICTIONARY).includes(this.name);
  }

  get displaySymbol() {
    return this.getDefaultUnitField('symbol');
  }

  get displayName() {
    const getField = () => {
      if (this.isFromDefaultDictionary) {
        return Object.entries(DEFAULT_UNIT_DICTIONARY)
          .filter(([key]) => key === this.name)
          .map(([, unit]) => unit.name)
          .at(0);
      }

      return this.name;
    };

    return getField();
  }

  get displayFullName() {
    return `${this.displayName}${
      this.displaySymbol ? ` (${this.displaySymbol})` : ''
    }`;
  }

  getDefaultUnitField(fieldName) {
    const getField = () => {
      if (!this[fieldName]) {
        if (this.isFromDefaultDictionary) {
          return Object.entries(DEFAULT_UNIT_DICTIONARY)
            .filter(([key]) => key === this.name)
            .map(([, unit]) => unit[fieldName])
            .at(0);
        }
        return '';
      }

      return this[fieldName];
    };

    return getField();
  }
}

export default UnitModel;
