import { STATUS } from '../../utils/constants';
import { buildQueryKey } from '../../utils/helpers';
import {
  addToDictionary,
  updateList,
  changeListStatus,
  resetState,
} from '../helpers';
import DEFAULT_REQUEST from '../constants';
import {
  CREATE_PROCUREMENT_ORDER_TYPES,
  DELETE_PROCUREMENT_TYPES,
  FIND_PROCUREMENT_TYPES,
  GET_PROCUREMENT_STATISTICS_TYPES,
  GET_PROCUREMENTS_TYPES,
  UPDATE_PROCUREMENT_TYPES,
} from './constants';
import Pager from '../../api/helpers/Pager';

/**
 *
 * @type {{list: {default: {}, pagination: {total: number, pageNumber: null, pageSize: null}, meta: {currentKey: string}}, dictionary: {}}}
 */
const initialState = {
  statistics: {},
  dictionary: {},
  list: {
    meta: {
      currentKey: DEFAULT_REQUEST,
    },
    pagination: {
      pageNumber: null,
      pageSize: null,
      total: 0,
    },
    [DEFAULT_REQUEST]: {},
  },
};

export default function procurementReducer(state = initialState, action) {
  let queryKey;
  let pager;
  let listStatus;
  let error;
  let id;
  let resourceModel;
  let resetToDefault;

  switch (action.type) {
    case GET_PROCUREMENTS_TYPES.REQUEST:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;
      resetToDefault = action.payload.resetToDefault;

      if (
        Pager.hasPageSizeChanged(
          state.list.pagination.pageSize,
          pager?.pageSize
        ) ||
        resetToDefault
      ) {
        return resetState(state, initialState, {
          queryKey,
          pager,
        });
      }

      if (
        [STATUS.RELOADING, STATUS.SUCCESS, STATUS.CACHED].includes(listStatus)
      ) {
        return changeListStatus(state, { pager, queryKey }, STATUS.RELOADING);
      }
      return changeListStatus(state, { pager, queryKey }, STATUS.LOADING);
    case GET_PROCUREMENTS_TYPES.SUCCESS:
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
        list: updateList(state.list, action.payload),
      };
    case GET_PROCUREMENTS_TYPES.ERROR:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      error = action.payload.error;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;

      return changeListStatus(
        state,
        { pager, queryKey, error },
        listStatus === STATUS.RELOADING ? STATUS.CACHED : STATUS.ERROR
      );

    case FIND_PROCUREMENT_TYPES.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case FIND_PROCUREMENT_TYPES.SUCCESS:
      const { resource } = action.payload;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [resource.id]: new resourceModel({
            ...state.dictionary[resource.id],
            ...resource,
            findFetched: true,
          }),
        },
      };
    case FIND_PROCUREMENT_TYPES.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case CREATE_PROCUREMENT_ORDER_TYPES.REQUEST:
      return state;
    case CREATE_PROCUREMENT_ORDER_TYPES.SUCCESS:
      return state;
    case CREATE_PROCUREMENT_ORDER_TYPES.ERROR:
      return state;

    case UPDATE_PROCUREMENT_TYPES.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case UPDATE_PROCUREMENT_TYPES.SUCCESS:
      return state;
    case UPDATE_PROCUREMENT_TYPES.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case DELETE_PROCUREMENT_TYPES.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case DELETE_PROCUREMENT_TYPES.SUCCESS:
      id = action.payload.id;

      const newDictionary = { ...state.dictionary };
      delete newDictionary[id];
      return {
        ...state,
        dictionary: newDictionary,
      };
    case DELETE_PROCUREMENT_TYPES.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };
    case GET_PROCUREMENT_STATISTICS_TYPES.SUCCESS:
      id = action.payload.id;
      return {
        ...state,
        statistics: {
          ...state.statistics,
          [id]: action.payload.resource,
        },
      };

    default:
      return state;
  }
}
