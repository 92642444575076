import authRoutes from 'routes/globalRoutes/auth';

let routes = [];
const routeFiles = require.context('./', true, /^(?!.\/index|.\/paths).*\.js$/);

routeFiles.keys().forEach((routeFile) => {
  routes = [...routes, ...routeFiles(routeFile).default];
});

const technicianRoutes = [...authRoutes];

export default technicianRoutes;
