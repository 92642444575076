import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatProjectsConversationActionsCreators } from '../../../../store/chat/general/actions';
import ChatParticipantModel from '../../../models/ChatParticipantModel';

export default class ProjectMessagesNewMessageEventCatcher extends AbstractEventCatcher {
  static process({ message, chatId, user, projectId }, dispatch) {
    dispatch(
      generalChatProjectsConversationActionsCreators.addNewMessage(
        projectId,
        chatId,
        {
          chatId,
          ...message,
          user: new ChatParticipantModel(user),
        }
      )
    );
  }
}
