import TicketNotificationsEventCatcher from '../../../../realTimeApi/eventCatchers/ticket/TicketNotificationsEventCatcher';
import TicketNotificationDescriptionUtils from './TicketNotificationDescriptionUtils';
import { TICKET_TYPE } from '../../../../api/resourceTypeMapping';
import PATH from '../../../../routes/paths';
import {
  projectIdPattern,
  ticketIdPattern,
} from '../../../../routes/replacePatterns';
import NotificationModel from '../../../../api/models/NotificationModel';

/* eslint default-case: 0 */
/* eslint no-param-reassign: 0 */

const replaceProjectId = (path, projectId) => {
  return path.replace(projectIdPattern, projectId);
};

const replaceTicketId = (path, ticketId) => {
  return path.replace(ticketIdPattern, ticketId);
};

/**
 *
 * @param {NotificationModel} notificationModel
 */
const setTicketNotificationMissingFields = (notificationModel) => {
  /** FYI: this function applies model's setters  */

  const getDescriptionFunc =
    TicketNotificationDescriptionUtils.TICKET_MESSAGE_GETTERS_BY_UPDATE_TYPE[
      notificationModel.notificationType
    ];

  const { ticketSubject, ticketId, projectId } = notificationModel.metadata;

  const path = replaceTicketId(
    replaceProjectId(PATH.PROJECT.TICKET.ONE, projectId),
    ticketId
  );

  const setType = (type) => {
    notificationModel.notificationUiType = type;
  };

  const setResourceMetaData = () => {
    notificationModel.resourceMetaData = {
      resourceType: TICKET_TYPE,
      path,
      projectId,
      ticketId,
    };
  };

  const setDescription = (description) => {
    notificationModel.description = description;
  };

  const setName = (name) => {
    notificationModel.name = name;
  };

  const NOTIFICATIONS_CONFIG = {
    [TicketNotificationsEventCatcher.TICKET_UPDATE_TYPES
      .TICKET_CREATED]: () => {
      setType(NotificationModel.NOTIFICATION_TYPES.CREATE);
      setResourceMetaData();
      setDescription(getDescriptionFunc(ticketSubject));
      setName(ticketSubject);
    },
    [TicketNotificationsEventCatcher.TICKET_UPDATE_TYPES
      .STATUS_UPDATED]: () => {
      setType(NotificationModel.NOTIFICATION_TYPES.UPDATE);
      setResourceMetaData();
      setDescription(
        getDescriptionFunc(
          ticketSubject,
          notificationModel.metadata.previousStatus,
          notificationModel.metadata.status
        )
      );
      setName(ticketSubject);
    },
    [TicketNotificationsEventCatcher.TICKET_UPDATE_TYPES
      .PRIORITY_UPDATED]: () => {
      setType(NotificationModel.NOTIFICATION_TYPES.UPDATE);
      setResourceMetaData();
      setDescription(
        getDescriptionFunc(
          ticketSubject,
          notificationModel.metadata.previousPriority,
          notificationModel.metadata.priority
        )
      );
      setName(ticketSubject);
    },
  };

  const action = NOTIFICATIONS_CONFIG[notificationModel.notificationType];

  action();

  return notificationModel;
};

export default setTicketNotificationMissingFields;
