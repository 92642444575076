const GRAY = Object.freeze({
  100: '#F4F5F6',
  200: '#E9EAEC',
  300: '#DEE0E3',
  400: '#D3D5DA',
  500: '#C8CBD0',
  600: '#BDC0C7',
  700: '#B2B6BD',
  800: '#A7ABB4',
  900: '#9CA1AB',
  1000: '#9196A1',
  1100: '#868C98',
  1200: '#7B818E',
  1300: '#717784',
  1400: '#676D79',
  1500: '#5E636E',
  1600: '#545963',
  1700: '#4B4F58',
  1800: '#42454D',
  1900: '#383C42',
});

export default GRAY;
