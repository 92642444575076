import { lazy } from 'react';
import PATH from 'routes/paths';

const RealmProfile = lazy(() => import('containers/realm/RealmProfile'));
const RealmList = lazy(() => import('containers/realm/RealmList'));

export const REALM_PROFILE = {
  component: RealmProfile,
  path: PATH.ORGANIZATION.ONE,
  exact: false,
  isProtected: true,
};
export const REALM_LIST = {
  component: RealmList,
  path: PATH.ORGANIZATION.LIST,
  exact: false,
  isProtected: true,
};

export default [REALM_PROFILE, REALM_LIST];
