import ApiResourceRequest from './ApiResourceRequest';
import Pager from '../helpers/Pager';

export class CityRequest extends ApiResourceRequest {
  get apiResource() {
    return 'cities';
  }

  get routes() {
    return {
      ...super.routes,
      GET_STATE_CITIES: `${this.apiRoute}/{state_id}/cities`,
    };
  }

  async getStateCities(stateId, pager = new Pager(1, -1), query = {}) {
    pager && Pager.check(pager);
    if (!stateId) {
      throw new Error('stateId is required to fetch cities!');
    }
    const paginationParams = this.getPaginationParams(pager);
    const orderParams = this.getOrderParams(query.order);

    const response = await this.request(
      'GET',
      this.routes.GET_STATE_CITIES.replace('{state_id}', stateId) +
        paginationParams +
        orderParams
    );

    return {
      total: response?.data?.item_count,
      resources: response?.data?.cities,
    };
  }
}

export const cityRequest = new CityRequest();
