import { required } from '../../../utils/helpers';
import createChatChannelItemModel from './createChatChannelItemModel';
import ChatModel from '../../../realTimeApi/models/ChatModel';

const getSpecificConversationId = (project, conversationType) => {
  return project[conversationType]?.id;
};

const getProjectConversation = (
  chatId,
  project,
  prevProjectConversations,
  loggedInUserId,
  conversationType
) => {
  return chatId
    ? {
        [chatId]: {
          ...(prevProjectConversations[chatId] || {}),
          conversationInfo: createChatChannelItemModel(
            {
              ...project[conversationType],
              conversationType,
            },
            loggedInUserId
          ),
        },
      }
    : {};
};

export const getShouldProjectBeMissed = (project) => {
  let missProject = false;

  // eslint-disable-next-line no-restricted-syntax
  for (const channelTypeField of Object.values(ChatModel.PROJECT_CHAT_TYPE)) {
    // eslint-disable-next-line no-prototype-builtins
    if (!project.hasOwnProperty(channelTypeField)) {
      missProject = true;
      break;
    }
  }

  return missProject;
};

const toProjectConversationsStructure = (
  object,
  state = {},
  loggedInUserId
) => {
  required(object);

  const result = {};

  Object.entries(object).forEach(([projectId, project]) => {
    if (getShouldProjectBeMissed(project)) {
      return;
    }

    const projectBeneficiaryChatId = getSpecificConversationId(
      project,
      ChatModel.CHAT_TYPE.BENEFICIARY_CHAT
    );
    const projectManagementChatId = getSpecificConversationId(
      project,
      ChatModel.CHAT_TYPE.MANAGEMENT_CHAT
    );
    const projectStaffChatId = getSpecificConversationId(
      project,
      ChatModel.CHAT_TYPE.STAFF_CHAT
    );

    const prevProject = state?.[projectId] || {};
    const prevProjectConversations = prevProject.conversations || {};

    result[projectId] = {
      ...prevProject,
      projectInfo: {
        ...(prevProject.projectInfo || {}),
        name: project.name,
        id: projectId,
      },
      conversations: {
        ...prevProjectConversations,

        ...getProjectConversation(
          projectBeneficiaryChatId,
          project,
          prevProjectConversations,
          loggedInUserId,
          ChatModel.CHAT_TYPE.BENEFICIARY_CHAT
        ),
        ...getProjectConversation(
          projectManagementChatId,
          project,
          prevProjectConversations,
          loggedInUserId,
          ChatModel.CHAT_TYPE.MANAGEMENT_CHAT
        ),
        ...getProjectConversation(
          projectStaffChatId,
          project,
          prevProjectConversations,
          loggedInUserId,
          ChatModel.CHAT_TYPE.STAFF_CHAT
        ),
      },
    };
  });

  return result;
};

export default toProjectConversationsStructure;
