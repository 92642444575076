import React, { useState } from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function InfoIcon({ size, color, hoverDisabled, hoverBgColor, ...rest }) {
  const [fillColor, setFillColor] = useState(undefined);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      onMouseEnter={() => {
        setFillColor(hoverBgColor);
      }}
      onMouseLeave={() => {
        setFillColor(undefined);
      }}
    >
      {hoverDisabled ? undefined : (
        <path
          d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
          fill={fillColor}
        />
      )}
      <path
        d="M12 17V11M12 9V7M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(InfoIcon);
