import { createActions, createActionTypesConfig } from '../helpers';

export const ACTIVITY_REPORT_LABEL = 'activity-report';

/** Action Types */

export const CREATE_ACTIVITY_REPORT_TYPES = createActionTypesConfig(
  ACTIVITY_REPORT_LABEL,
  'CREATE_ACTIVITY_REPORT'
);
export const UPDATE_ACTIVITY_REPORT_TYPES = createActionTypesConfig(
  ACTIVITY_REPORT_LABEL,
  'UPDATE_ACTIVITY_REPORT'
);

export const GET_PROJECT_ACTIVITY_REPORTS_TYPES = createActionTypesConfig(
  ACTIVITY_REPORT_LABEL,
  'GET_PROJECT_ACTIVITY_REPORTS'
);

export const FIND_ACTIVITY_REPORT_TYPES = createActionTypesConfig(
  ACTIVITY_REPORT_LABEL,
  'FIND_ACTIVITY_REPORT'
);

export const DELETE_ACTIVITY_REPORT_TYPES = createActionTypesConfig(
  ACTIVITY_REPORT_LABEL,
  'DELETE_ACTIVITY_REPORT'
);

/** Actions */

export const CREATE_ACTIVITY_REPORT_ACTION = createActions(
  CREATE_ACTIVITY_REPORT_TYPES
);
export const UPDATE_ACTIVITY_REPORT_ACTION = createActions(
  UPDATE_ACTIVITY_REPORT_TYPES
);
export const GET_PROJECT_ACTIVITY_REPORTS_ACTION = createActions(
  GET_PROJECT_ACTIVITY_REPORTS_TYPES
);
export const FIND_ACTIVITY_REPORT_ACTION = createActions(
  FIND_ACTIVITY_REPORT_TYPES
);
export const DELETE_ACTIVITY_REPORT_ACTION = createActions(
  DELETE_ACTIVITY_REPORT_TYPES
);
