export default {
  inventory: {
    itself: 'Inventory',
    createNewInventoryItem: 'Create a new inventory item',
    editInventoryItem: 'Edit inventory item',
    createItem: 'Create item',
    editItem: 'Edit item',
    emptyInventoryList:
      'Hit the blue plus button and start adding inventory items',
    attributes: {
      name: {
        itself: 'Name',
        '*': 'Name *',
        required: 'Name is required',
      },
      description: {
        itself: 'Description',
        '*': 'Description *',
        required: 'Description is required',
        placeholder: 'Describe the item',
      },
      quantity: {
        itself: 'Quantity',
        '*': 'Quantity *',
        required: 'Please enter a number',
        placeholder: 'Enter quantity',
      },
      owner: {
        itself: 'Owner',
        '*': 'Owner *',
        required: 'Owner is required',
        types: {
          project: 'Project',
          provider: 'Provider',
        },
      },
      category: {
        itself: 'Category',
        '*': 'Category *',
        required: 'Category is required',
        types: {
          consumables: 'Consumables',
          tools: 'Tools',
        },
      },
    },
    log: {
      title: 'Log • {{count}} entries',
      add: 'Added {{count}} items',
      extract: 'Extracted {{count}} items',
      create: 'Inventory created',
      delete: 'Inventory deleted',
      fieldsUpdate: 'Fields update',
    },
    addTitle: 'Add',
    extractTitle: 'Extract',
    addInputTitle: 'Number of additional items *',
    extractInputTitle: 'Number of extracted items *',
    extractInputTitle2: 'of {{total}}',
    addButton: 'Add',
    extractButton: 'Extract',
    description: 'Description',
    quantity: 'Quantity',
    owner: 'Owner',
    availability: 'Availability',
    inStock: 'In stock',
    outOfStock: 'Out of stock',
    reasonInputTitle: {
      itself: 'Reason',
      '*': 'Reason *',
    },
    addNotesInputTitle: {
      itself: 'Additional notes',
      '*': 'Additional notes *',
    },
  },
};
