import React, { useEffect as effectHook, useState as stateHook } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

export const ICON_SIZES = {
  regular: 24,
  large: 48,
  special: 12,
};
export const ICON_COLORS = {
  PRIMARY: 'primary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  LINK: 'link',
  BLACK: 'black',
  GRAY: 'gray',
  WHITE: 'white',
};

function getColor(color, customColor, theme, shade) {
  if (customColor) {
    return customColor;
  }
  switch (color) {
    case ICON_COLORS.PRIMARY:
      return theme.palette.primary[shade];
    case ICON_COLORS.SUCCESS:
      return theme.palette.secondary[shade];
    case ICON_COLORS.DANGER:
      return theme.palette.themeCommon.red[shade];
    case ICON_COLORS.WARNING:
      return theme.palette.themeCommon.yellow[shade];
    case ICON_COLORS.LINK:
      return theme.palette.themeCommon.purple[shade];
    case ICON_COLORS.BLACK:
      return theme.palette.themeCommon.black[shade];
    case ICON_COLORS.GRAY:
      return theme.palette.themeCommon.gray;
    case ICON_COLORS.WHITE:
      return theme.palette.themeCommon.white.main;
    default:
      return theme.palette.themeCommon.black[shade];
  }
}

function getIconColor(color, customColor, theme) {
  return getColor(color, customColor, theme, 'main');
}

function getHoverCircleColor(color, customColor, theme) {
  const hoverColor = ['black', 'gray'].includes(color) ? 'primary' : color;
  return getColor(hoverColor, customColor, theme, 'light');
}

function getHoverCircleBorderColor(color, customColor, theme) {
  const hoverColor = ['black', 'gray'].includes(color) ? 'primary' : color;
  return getColor(hoverColor, customColor, theme, 'main');
}

export default function iconWrapper(SvgComponent) {
  const StyledSvgComponent = styled(SvgComponent)`
    border: 1px solid transparent;
    ${({ $cursorPointer }) => $cursorPointer && `cursor: pointer;`}

    ${({ $hoverCircle, $hoverCircleColor, $hoverCircleBorderColor }) =>
      $hoverCircle &&
      `
    :hover {
      background-color: ${$hoverCircleColor};
      border-radius: 50%;
    }

    :active {
      border: 1px solid ${$hoverCircleBorderColor};
    }
    `}
  `;

  function svgComponent({
    cursorPointer,
    size,
    color,
    customSize,
    customColor,
    theme,
    hoverCircle,
    hoverCircleCustomColor,
    hoverCircleCustomBorderColor,
    hoverSvgStrokeColor,
    ...rest
  }) {
    const staticStrokeColor = getIconColor(color, customColor, theme);
    const [strokeColor, setStrokeColor] = stateHook(staticStrokeColor);
    /**
     * if staticStrokeColor changes, we make sure the actual strokeColor is changed as well
     */
    effectHook(() => {
      setStrokeColor(staticStrokeColor);
    }, [staticStrokeColor]);

    return (
      <StyledSvgComponent
        $cursorPointer={cursorPointer}
        size={customSize || ICON_SIZES[size]}
        color={strokeColor}
        theme={theme}
        $hoverCircle={hoverCircle}
        $hoverCircleColor={getHoverCircleColor(
          color,
          hoverCircleCustomColor,
          theme
        )}
        $hoverCircleBorderColor={getHoverCircleBorderColor(
          color,
          hoverCircleCustomBorderColor,
          theme
        )}
        onMouseEnter={() => {
          if (
            hoverCircle &&
            !hoverSvgStrokeColor &&
            ['black', 'gray'].includes(color)
          ) {
            setStrokeColor(theme.palette.primary.main);
          }
          hoverSvgStrokeColor && setStrokeColor(hoverSvgStrokeColor);
        }}
        onMouseLeave={() => {
          setStrokeColor(staticStrokeColor);
        }}
        {...rest}
      />
    );
  }

  svgComponent.propTypes = {
    cursorPointer: PropTypes.bool,
    size(props, propName) {
      const propValue = props[propName];
      const iconSizes = Object.keys(ICON_SIZES);
      if (!iconSizes.includes(propValue)) {
        throw new Error(`${propName} must be one of ${iconSizes.toString()}`);
      }
    },
    color(props, propName) {
      const propValue = props[propName];
      const iconColors = Object.values(ICON_COLORS);
      if (!iconColors.includes(propValue)) {
        throw new Error(`${propName} must be one of ${iconColors.toString()}`);
      }
    },
    customSize: PropTypes.number,
    customColor: PropTypes.string,
    hoverCircle: PropTypes.bool,
    hoverCircleCustomColor: PropTypes.string,
    hoverCircleCustomBorderColor: PropTypes.string,
    hoverSvgStrokeColor: PropTypes.string,
  };
  svgComponent.defaultProps = {
    cursorPointer: false,
    size: 'regular',
    color: 'black',
    customSize: 0,
    customColor: '',
    hoverCircle: false,
    hoverCircleCustomColor: '',
    hoverCircleCustomBorderColor: '',
    hoverSvgStrokeColor: '',
  };

  return withTheme(svgComponent);
}
