import AbstractDataAdapter from './AbstractDataAdapter';

class SubcontractorDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return ['name'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return ['name'];
  }
}

export default SubcontractorDataAdapter;
