export const STATUS = Object.freeze({
  LOADING: 'LOADING',
  RELOADING: 'RELOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  CACHED: 'CACHED',
});

export const HTTP_STATUS_CODE = Object.freeze({
  OK: 200,
  ACCEPTED: 202,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TIMEOUT: 408,
  INTERNAL_SERVER_ERROR: 500,
});

export const POPPER_ROOT = document.getElementById('popper') || document.body;

export const RETRY_REQUEST_MS = 75;
export const RETRY_REQUEST_MULTIPLIER = 3;

export const LOCAL_STORAGE_TOKEN_KEY = 'token';

export const RESOURCE_INSTANCE_TYPE = 'resource_instance';

export const MEGA_BYTE = 1048576;
export const MAX_FILE_SIZE_MB = 25;
export const MAX_FILE_SIZE_BYTE = MEGA_BYTE * MAX_FILE_SIZE_MB;

export const ACCEPTED_MEDIA_FILE_TYPES = [
  '.jpg',
  '.jpeg',
  '.png',
  '.mp4',
  '.mov',
  '.wmv',
  '.avi',
  '.mkv',
];

export const SEMI_SYMBOL = ',';
export const SEMICOLON_SYMBOL = ';';
export const COLON_SYMBOL = ':';

export const ORDERS = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

export const DOCUMENT_FILE_TYPES = Object.freeze({
  EXCEL: 'excel',
  WORD: 'word',
});

export const DOCUMENT_FILE_EXTENSIONS = Object.freeze({
  [DOCUMENT_FILE_TYPES.WORD]: 'docx',
  [DOCUMENT_FILE_TYPES.EXCEL]: 'xlsx',
});
