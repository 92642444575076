import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

/* eslint-disable react/prop-types */
function DirectionRightIcon({
  size,
  color,
  hoverDisabled,
  hoverBgColor,
  ...rest
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.5 7.5L15 12L10.5 16.5"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(DirectionRightIcon);
