import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useRealTimeProvider from './useRealTimeProvider';
import processEvent from '../../realTimeApi/helpers/processEvent';
import { authUserSelector } from '../../store/auth/selectors';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = useSelector(authUserSelector);

  return useRealTimeProvider(
    {
      onMessage: (event) =>
        processEvent(event, dispatch, history, loggedInUser),
    },
    loggedInUser
  );
};
