import { createActions, createActionTypesConfig } from '../helpers';

const REPORT_LABEL = 'report';

export const GET_REPORTS_TYPE = createActionTypesConfig(
  REPORT_LABEL,
  'GET_REPORTS'
);

export const FIND_REPORT_TYPE = createActionTypesConfig(
  REPORT_LABEL,
  'FIND_REPORT'
);

export const CREATE_REPORT_TYPE = createActionTypesConfig(
  REPORT_LABEL,
  'CREATE_REPORT'
);

export const DELETE_REPORT_TYPE = createActionTypesConfig(
  REPORT_LABEL,
  'DELETE_REPORT'
);

export const GET_REPORTS_ACTION = createActions(GET_REPORTS_TYPE);
export const FIND_REPORT_ACTION = createActions(FIND_REPORT_TYPE);
export const CREATE_REPORT_ACTION = createActions(CREATE_REPORT_TYPE);
export const DELETE_REPORT_ACTION = createActions(DELETE_REPORT_TYPE);
