import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function LayersIcon({ size, color, hoverBgColor, hoverDisabled, ...rest }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.4444 10L20 12L16.4444 14M16.4444 10L12 12.5L7.55556 10M16.4444 10L20 8L12 3.5L4 8L7.55556 10M7.55556 10C6.48011 10.6049 5.37251 11.228 4 12L7.55556 14M16.4444 14C17.5199 14.6049 18.6275 15.228 20 16L12 20.5L4 16C5.37251 15.228 6.48011 14.6049 7.55556 14M16.4444 14L12 16.5L7.55556 14"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(LayersIcon);
