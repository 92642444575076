import AbstractDataAdapter from './AbstractDataAdapter';

class CityDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return [
      'id',
      'localeCode',
      'continentCode',
      'continentName',
      'countryIsoCode',
      'countryName',
      'subdivision1IsoCode',
      'subdivision1Name',
      'cityName',
      'timeZone',
      'isInEuropeanUnion',
    ];
  }
}

export default CityDataAdapter;
