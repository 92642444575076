import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';
import AbstractDirectMessagesChannelEventCatcher from './AbstractDirectMessagesChannelEventCatcher';
import PATH from '../../../../routes/paths';

export default class DirectMessagesRemoveChannelEventCatcher extends AbstractEventCatcher {
  static process({ removedChatId }, dispatch, history) {
    dispatch(
      generalChatMessagesConversationActionCreators.removeConversation(
        removedChatId
      )
    );

    const currentUrl = history.location.pathname;
    const projectId = AbstractDirectMessagesChannelEventCatcher.getProjectIdFromUrl(
      currentUrl
    );
    const channelListUrl = AbstractDirectMessagesChannelEventCatcher.getDirectMessagesChannelListUrl(
      projectId
    );

    currentUrl.includes(PATH.CHAT.CONVERSATION.LIST) &&
      history.replace(channelListUrl);
  }
}
