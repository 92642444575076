import createGeneralChatMessageModel from './createGeneralChatMessageModel';
import { getUniqueArrayOfObjects } from '../../../utils/helpers';
import sortChatHistory from './sortChatHistory';
import { setChatHistoryLastReadMessage } from './updateChatHistoryLastReadMessage.helpers';

const addLastReadMessageToEachConversationHistory = (
  prevConversations,
  chats
) => {
  const result = {};

  chats.forEach((chat) => {
    const currentConversation = prevConversations?.[chat.id] || {};

    if (!chat.lastReadMessage || !chat.unreadMessagesIds.length) {
      result[chat.id] = {
        ...currentConversation,
      };

      return;
    }

    const lastReadMessageHistoryItem = createGeneralChatMessageModel(
      chat.lastReadMessage,
      prevConversations[chat.id].conversationInfo
    );

    lastReadMessageHistoryItem.showLastMessageLineForce = true;

    result[chat.id] = {
      ...currentConversation,
      lastReadMessageId: chat.lastReadMessage?.id,
      history: setChatHistoryLastReadMessage(
        sortChatHistory(
          getUniqueArrayOfObjects(
            [
              ...(currentConversation.history || []),
              lastReadMessageHistoryItem,
            ],
            'id'
          )
        ),
        chat.lastReadMessage?.id
      ),
    };
  });

  return result;
};

export default addLastReadMessageToEachConversationHistory;
