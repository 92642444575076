import ChatHistoryMessageModel from './ChatHistoryMessageModel';

export default class GeneralChatHistoryMessageModel extends ChatHistoryMessageModel {
  static SYSTEM_MESSAGE_TYPES = Object.freeze({
    SYSTEM_USER_JOINED_CHAT: 'systemUserJoinedChat',
    SYSTEM_USER_LEFT_CHAT: 'systemUserLeftChat',
  });

  static TYPES = {
    USER: 'user',
    ...GeneralChatHistoryMessageModel.SYSTEM_MESSAGE_TYPES,
  };

  constructor(...params) {
    super(...params);

    this.isSystemMessage = Object.values(
      GeneralChatHistoryMessageModel.SYSTEM_MESSAGE_TYPES
    ).includes(this._type);
  }
}
