import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';
import ChatPager from '../../../helpers/ChatPager';

class DirectMessagesHistoryEventCatcher extends AbstractEventCatcher {
  static process(historyResponse, dispatch) {
    const pagerFromApi = new ChatPager(
      ChatPager.calculatePage(historyResponse.offset, historyResponse.limit),
      historyResponse.limit
    );

    dispatch(
      generalChatMessagesConversationActionCreators.updateHistory(
        historyResponse.chat.id,
        historyResponse.messages.messages,
        historyResponse.messages.total,
        pagerFromApi
      )
    );
  }
}

export default DirectMessagesHistoryEventCatcher;
