import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatProjectsConversationActionsCreators } from '../../../../store/chat/general/actions';

export default class ProjectChatChannelUnreadMessagesUpdatedEventCatcher extends AbstractEventCatcher {
  static process(
    {
      unreadMessagesIds,
      lastReadMessageId,
      projectId,
      chatId,
      lastReadMessage,
    },
    dispatch
  ) {
    dispatch(
      generalChatProjectsConversationActionsCreators.updateUnreadMessagesIds(
        projectId,
        chatId,
        unreadMessagesIds
      )
    );

    const firstUnreadMessageId = lastReadMessageId || unreadMessagesIds[0];

    if (firstUnreadMessageId) {
      dispatch(
        generalChatProjectsConversationActionsCreators.updateLastReadMessageId(
          projectId,
          chatId,
          firstUnreadMessageId
        )
      );

      if (lastReadMessage) {
        dispatch(
          generalChatProjectsConversationActionsCreators.addNewMessage(
            projectId,
            chatId,
            lastReadMessage
          )
        );

        dispatch(
          generalChatProjectsConversationActionsCreators.setHistoryLastReadMessage(
            projectId,
            chatId
          )
        );
      }
    }
  }
}
