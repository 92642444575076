import TypesHelper from '../../../../utils/types/TypesHelper';
import ChatModel from '../../../../realTimeApi/models/ChatModel';

export default (state, action, config) => {
  const currentResource =
    state[config.propName][action.payload.resourceId] || {};
  const currentResourceChats = currentResource.chats || {};
  const currentChat = currentResourceChats[action.payload.chatId] || {};
  const currentChatInfo = currentChat.chatInfo;
  const currentParticipants = currentChatInfo?.chatParticipants || [];

  const newParticipants = currentParticipants.filter(
    /** @dima todo temp */
    (participant) => +participant.id !== +action.payload.participantId
  );

  const newChatInfo = TypesHelper.isObject(currentChatInfo)
    ? new ChatModel({
        ...currentChatInfo,
        chatParticipants: newParticipants,
      })
    : undefined;

  return {
    ...state,
    [config.propName]: {
      ...state[config.propName],
      [action.payload.resourceId]: {
        ...currentResource,
        chats: {
          ...currentResourceChats,
          [action.payload.chatId]: {
            ...currentChat,
            ...(newChatInfo ? { chatInfo: newChatInfo } : {}),
            participants: newParticipants,
          },
        },
      },
    },
  };
};
