import { useWindowSize } from 'hooks/utilHooks';
import { DEVICE_SIZES } from 'stories/constants';
/* eslint-disable radix */

const useMobile = () => {
  const { width: windowWidth } = useWindowSize();
  const mobileLWidth = parseInt(DEVICE_SIZES.MOBILE_L);
  const mobileMWidth = parseInt(DEVICE_SIZES.MOBILE_M);
  const mobileSWidth = parseInt(DEVICE_SIZES.MOBILE_S);
  const tabletWidth = parseInt(DEVICE_SIZES.TABLET);

  const isLessThanMobileL = windowWidth <= mobileLWidth;
  const isMobileL = windowWidth > mobileMWidth && isLessThanMobileL;

  const isLessThanMobileM = windowWidth <= mobileMWidth;
  const isMobileM = windowWidth > mobileSWidth && isLessThanMobileM;

  const isLessThanMobileS = windowWidth <= mobileSWidth;
  const isMobileS = windowWidth > 0 && isLessThanMobileS;

  const isLessThanTablet = windowWidth <= tabletWidth;

  return {
    isMobileL,
    isLessThanMobileL,
    isMobileM,
    isLessThanMobileM,
    isMobileS,
    isLessThanMobileS,
    isLessThanTablet,
  };
};

export default useMobile;
