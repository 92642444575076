import TICKET_EVENTS from '../events/ticket.events';
import TicketNotificationsEventCatcher from '../eventCatchers/ticket/TicketNotificationsEventCatcher';
import TicketChatEventCatcher from '../eventCatchers/ticket/TicketChatEventCatcher';
import TicketChatHistoryEventCatcher from '../eventCatchers/ticket/TicketChatHistoryEventCatcher';
import TicketChatRemoveMessageEventCatcher from '../eventCatchers/ticket/TicketChatRemoveMessageEventCatcher';
import TicketChatSendMessageEventCatcher from '../eventCatchers/ticket/TicketChatSendMessageEventCatcher';
import TicketChatAddParticipantEventCatcher from '../eventCatchers/ticket/TicketChatAddParticipantEventCatcher';
import TicketChatRemoveParticipantEventCatcher from '../eventCatchers/ticket/TicketChatRemoveParticipantEventCatcher';
import TicketChatUnreadMessagesUpdated from '../eventCatchers/ticket/TicketChatUnreadMessagesUpdated';

const processTicketEvents = ({ event, data, dispatch, loggedInUser }) => {
  switch (event) {
    case TICKET_EVENTS.NOTIFICATION: {
      TicketNotificationsEventCatcher.process(data, dispatch);
      break;
    }

    case TICKET_EVENTS.CHAT.INTERNAL.GET_CHAT: {
      TicketChatEventCatcher.process({ chat: data, dispatch, loggedInUser });
      break;
    }
    case TICKET_EVENTS.CHAT.INTERNAL.GET_CHAT_HISTORY: {
      TicketChatHistoryEventCatcher.process({ historyInfo: data, dispatch });
      break;
    }
    case TICKET_EVENTS.CHAT.INTERNAL.REMOVE_MESSAGE: {
      TicketChatRemoveMessageEventCatcher.process({
        messageInfo: data,
        dispatch,
      });
      break;
    }
    case TICKET_EVENTS.CHAT.INTERNAL.SEND_MESSAGE: {
      TicketChatSendMessageEventCatcher.process({
        messageInfo: data,
        dispatch,
        loggedInUser,
      });
      break;
    }
    case TICKET_EVENTS.CHAT.INTERNAL.ADD_PARTICIPANT: {
      TicketChatAddParticipantEventCatcher.process({
        participantInfo: data,
        dispatch,
      });
      break;
    }
    case TICKET_EVENTS.CHAT.INTERNAL.REMOVE_PARTICIPANT: {
      TicketChatRemoveParticipantEventCatcher.process({
        participantInfo: data,
        dispatch,
        loggedInUser,
      });
      break;
    }
    case TICKET_EVENTS.CHAT.INTERNAL.UNREAD_MESSAGES_UPDATED: {
      TicketChatUnreadMessagesUpdated.process({
        unreadMessageInfo: data,
        dispatch,
      });
      break;
    }

    case TICKET_EVENTS.CHAT.EXTERNAL.GET_CHAT: {
      TicketChatEventCatcher.process({ chat: data, dispatch, loggedInUser });
      break;
    }
    case TICKET_EVENTS.CHAT.EXTERNAL.GET_CHAT_HISTORY: {
      TicketChatHistoryEventCatcher.process({ historyInfo: data, dispatch });
      break;
    }
    case TICKET_EVENTS.CHAT.EXTERNAL.REMOVE_MESSAGE: {
      TicketChatRemoveMessageEventCatcher.process({
        messageInfo: data,
        dispatch,
      });
      break;
    }
    case TICKET_EVENTS.CHAT.EXTERNAL.SEND_MESSAGE: {
      TicketChatSendMessageEventCatcher.process({
        messageInfo: data,
        dispatch,
        loggedInUser,
      });
      break;
    }
    case TICKET_EVENTS.CHAT.EXTERNAL.ADD_PARTICIPANT: {
      TicketChatAddParticipantEventCatcher.process({
        participantInfo: data,
        dispatch,
      });
      break;
    }
    case TICKET_EVENTS.CHAT.EXTERNAL.REMOVE_PARTICIPANT: {
      TicketChatRemoveParticipantEventCatcher.process({
        participantInfo: data,
        dispatch,
        loggedInUser,
      });
      break;
    }
    case TICKET_EVENTS.CHAT.EXTERNAL.UNREAD_MESSAGES_UPDATED: {
      TicketChatUnreadMessagesUpdated.process({
        unreadMessageInfo: data,
        dispatch,
      });
      break;
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn('There are no cases for this event', { event, data });
    }
  }
};

export default processTicketEvents;
