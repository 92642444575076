import { required } from '../../../utils/helpers';

export const updateGeneralChatItem = (itemId, state = {}, updateObj = {}) => {
  required(itemId, state, updateObj);

  const selectedItem = state?.[itemId];

  return {
    ...state,
    [itemId]: {
      ...(selectedItem || {}),
      ...updateObj,
    },
  };
};

export const updateGeneralChatConversation = (
  conversationId,
  state = {},
  originUpdateConversationFields = {}
) => {
  required(conversationId, state, originUpdateConversationFields);

  const currentConversation = state?.[conversationId] || {};

  return {
    ...state,
    [conversationId]: {
      ...currentConversation,
      ...originUpdateConversationFields,
    },
  };
};
