import GENERAL_CHAT_EVENTS from '../events/generalChat.events';
import ProjectMessagesChannelsEventCatcher from '../eventCatchers/chat/projectMessages/ProjectMessagesChannelsEventCatcher';
import ProjectMessagesHistoryEventCatcher from '../eventCatchers/chat/projectMessages/ProjectMessagesHistoryEventCatcher';
import ProjectMessagesNewMessageEventCatcher from '../eventCatchers/chat/projectMessages/ProjectMessagesNewMessageEventCatcher';
import ProjectMessagesRemoveMessageEventCatcher from '../eventCatchers/chat/projectMessages/ProjectMessagesRemoveMessageEventCatcher';
import ProjectMessagesUpdateChannelEventCatcher from '../eventCatchers/chat/projectMessages/ProjectMessagesUpdateChannelEventCatcher';
import ProjectMessagesChannelUnreadMessagesUpdatedEventCatcher from '../eventCatchers/chat/projectMessages/ProjectMessagesChannelUnreadMessagesUpdatedEventCatcher';
import ProjectMessagesBeneficiaryHistoryEventCatcher from '../eventCatchers/chat/projectMessages/ProjectMessagesBeneficiaryHistoryEventCatcher';
import ProjectMessagesBeneficiaryChannelEventCather from '../eventCatchers/chat/projectMessages/ProjectMessagesBeneficiaryChannelEventCather';
import ProjectMessagesNotificationEventCatcher from '../eventCatchers/chat/projectMessages/ProjectMessagesNotificationEventCatcher';

const processProjectMessagesChatEvents = ({
  event,
  data,
  dispatch,
  loggedInUser,
}) => {
  switch (event) {
    case GENERAL_CHAT_EVENTS.PROJECT_MESSAGES.NOTIFICATION: {
      ProjectMessagesNotificationEventCatcher.process(data, dispatch);
      break;
    }

    case GENERAL_CHAT_EVENTS.PROJECT_MESSAGES.GET_CHAT_CHANNELS: {
      ProjectMessagesChannelsEventCatcher.process(data, dispatch, loggedInUser);
      break;
    }
    case GENERAL_CHAT_EVENTS.PROJECT_MESSAGES.GET_CHAT_HISTORY: {
      ProjectMessagesHistoryEventCatcher.process(data, dispatch);
      break;
    }
    case GENERAL_CHAT_EVENTS.PROJECT_MESSAGES.SEND_MESSAGE: {
      ProjectMessagesNewMessageEventCatcher.process(data, dispatch);
      break;
    }
    case GENERAL_CHAT_EVENTS.PROJECT_MESSAGES.REMOVE_MESSAGE: {
      ProjectMessagesRemoveMessageEventCatcher.process(data, dispatch);
      break;
    }
    case GENERAL_CHAT_EVENTS.PROJECT_MESSAGES.UPDATE_CONVERSATION: {
      ProjectMessagesUpdateChannelEventCatcher.process(
        data,
        dispatch,
        loggedInUser
      );
      break;
    }
    case GENERAL_CHAT_EVENTS.PROJECT_MESSAGES
      .CONVERSATION_UNREAD_MESSAGES_IDS_UPDATED: {
      ProjectMessagesChannelUnreadMessagesUpdatedEventCatcher.process(
        data,
        dispatch
      );
      break;
    }
    case GENERAL_CHAT_EVENTS.PROJECT_MESSAGES.GET_BENEFICIARY_CHAT_HISTORY: {
      ProjectMessagesBeneficiaryHistoryEventCatcher.process(data, dispatch);
      break;
    }
    case GENERAL_CHAT_EVENTS.PROJECT_MESSAGES.GET_BENEFICIARY_CONVERSATION: {
      ProjectMessagesBeneficiaryChannelEventCather.process(
        data,
        dispatch,
        loggedInUser
      );
      break;
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn('There are no cases for this event', { event, data });
    }
  }
};

export default processProjectMessagesChatEvents;
