class TypesHelper {
  static isString = (obj) => typeof obj === 'string';

  static isNumber = (obj) => typeof obj === 'number';

  static isUndefined = (obj) => typeof obj === 'undefined';

  static isBoolean = (obj) => typeof obj === 'boolean';

  static isArray = (obj) => obj instanceof Array;

  static isFunction = (obj) => typeof obj === 'function';

  static isObject = (obj) =>
    typeof obj === 'object' && !(obj instanceof Array) && obj !== null;
}

export default TypesHelper;
