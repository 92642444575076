export default {
  room: {
    itself: 'Cameră',
    plural: {
      itself: 'Camere',
    },
    modalDeleteMessage:
      'Ești sigur că vrei să ștergi această cameră și toate informațiile asociate?',
  },
};
