import ApiResourceRequest from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import ListingResponse from '../ListingResponse';
import Pager from '../../helpers/Pager';

export class BuildingRequest extends ApiResourceRequest {
  get apiResource() {
    return 'buildings';
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_PROJECT_ID: `${this.apiRoute}/by-project-id/{projectId}`,
      GET_FULL_INFO_BY_PROJECT_ID: `${this.apiRoute}/full-info/by-project-id/{projectId}`,
      ADD_TENANT_LOCATION: `${this.apiRoute}/add-tenant-location`,
      REMOVE_TENANT_LOCATION: `${this.apiRoute}/remove-tenant-location`,
    };
  }

  async addTenantLocation(
    buildingId,
    levelId,
    roomIds,
    projectId,
    organizationId
  ) {
    required(buildingId, levelId, roomIds, projectId, organizationId);

    const response = await this.request(
      'POST',
      this.routes.ADD_TENANT_LOCATION,
      {
        buildingId: Number(buildingId),
        buildingLevelId: Number(levelId),
        buildingRoomIds: roomIds,
        projectId: Number(projectId),
        organizationId: Number(organizationId),
      }
    );

    return response?.data;
  }

  async removeTenantLocation(buildingId, levelId, projectId, organizationId) {
    required(buildingId, levelId, projectId, organizationId);

    const response = await this.request(
      'DELETE',
      this.routes.REMOVE_TENANT_LOCATION,
      {
        buildingId: Number(buildingId),
        buildingLevelId: Number(levelId),
        projectId: Number(projectId),
        organizationId: Number(organizationId),
      }
    );

    return response?.data;
  }

  async get(projectId, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ projectId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order),
      this.getExtraQueryParams(query.extra)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_PROJECT_ID.replace('{projectId}', projectId) +
        queryString
    );

    return new ListingResponse(response?.data);
  }

  async getFullInfo(projectId, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ projectId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_FULL_INFO_BY_PROJECT_ID.replace(
        '{projectId}',
        projectId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async find(projectId, buildingId, include = []) {
    required({ projectId, buildingId });

    let includeParams = '';
    if (Array.isArray(include) && include.length) {
      include.forEach((param, index) => {
        includeParams += `${index === 0 ? '?' : '&'}include=${param}`;
      });
    } else {
      includeParams = '?include=';
    }

    const response = await this.request(
      'GET',
      `${
        this.routes.FIND.replace('{project_id}', projectId) +
        buildingId +
        includeParams
      }`
    );

    return response?.data;
  }

  async create(projectId, attributes) {
    required({ projectId });

    const response = await this.queuedRequest(
      'POST',
      this.routes.CREATE.replace('{project_id}', projectId),
      attributes
    );

    return response?.data?.result.id;
  }

  async update(projectId, buildingId, attributes, method = 'PUT') {
    required({ projectId, buildingId });

    const response = await this.queuedRequest(
      method,
      this.routes.UPDATE.replace('{project_id}', projectId) + buildingId,
      attributes
    );

    return response?.data?.result;
  }

  async partialUpdate(projectId, buildingId, attributes) {
    return this.update(projectId, buildingId, attributes, 'PATCH');
  }

  async delete(projectId, buildingId) {
    required({ projectId, buildingId });

    const response = await this.queuedRequest(
      'DELETE',
      this.routes.DELETE.replace('{project_id}', projectId) + buildingId
    );

    return response?.data?.result;
  }
}

export const buildingRequest = new BuildingRequest();
