export default {
  subcontractor: {
    itself: 'Subcontractor',
    plural: {
      itself: 'Subcontractors',
    },
    attributes: {
      businessCategory: {
        itself: 'Business category',
        '*': 'Business category *',
        required: 'Business category is required',
      },
      category: {
        itself: 'Category',
        '*': 'Category *',
        required: 'Category is required',
      },
      company: {
        itself: 'Company',
        '*': 'Company *',
        required: 'Company is required',
      },
      website: {
        itself: 'Website',
        '*': 'Website *',
        required: 'Website is required',
      },
      contact: {
        itself: 'Contact',
        '*': 'Contact *',
        required: 'Contact is required',
      },
      contacts: {
        itself: 'Contact(s)',
        '*': 'Contact(s) *',
        required: 'Contact(s) is required',
        add: 'Add another contact',
        name: {
          itself: 'Name',
          '*': 'Nume *',
          required: 'Name is required',
        },
        department: {
          itself: 'Dept.',
          '*': 'Dept. *',
          required: 'Dept. is required',
        },
        email: {
          itself: 'E-mail',
          '*': 'E-mail *',
          required: 'E-mail is required',
        },
        phoneNumber: {
          itself: 'Phone',
          '*': 'Phone *',
          required: 'Phone is required',
        },
      },
      contactPerson: {
        itself: 'Contact person',
        '*': 'Contact person *',
        required: 'Contact person is required',
      },
      description: {
        itself: 'Description',
        '*': 'Description *',
        required: 'Description is required',
      },
    },
    selectOrAdd: 'Select or add subcontractor',
    createSubcontractor: 'Create subcontractor',
    addSubcontractor: 'Add subcontractor',
    addNewSubcontractor: 'Add new subcontractor',
    saveNewSubcontractor: 'Save new subcontractor',
    category: 'Category',
    companyName: 'Company name',
    businessWebsite: 'Business website',
    contactPerson: 'Contact person',
    contactEmailAddress: 'Contact email address',
    contactPhoneNumber: 'Contact phone number',
    addDescription: 'Add description',
    editInformation: 'Edit information',
    subcontractorDetails: 'Subcontractor details',
    startAddingSubcontractors:
      'Hit the blue plus button and start adding subcontractors',
  },
};
