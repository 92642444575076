import { ORDERS, STATUS } from 'utils/constants';
import {
  getUniqueArrayOfObjectsWithCheck,
  sortArrayByObjectDateProp,
} from 'utils/helpers';
import {
  FIND_PROJECT_BOARD_ITEM_TYPES,
  GET_TEAM_PROJECT_BOARD_ITEMS_TYPES,
  GET_PERSONAL_PROJECT_BOARD_ITEMS_TYPES,
  GET_PROJECT_BOARD_ITEM_LOGS_TYPES,
  DELETE_PROJECT_BOARD_ITEM_TYPES,
} from './constants';
import compareProjectBoardQueryObjects from './helpers/compareProjectBoardQueryObjects';

const PROJECT_BOARD_ITEM_PROPS = Object.freeze({
  PERSONAL: 'personalProjectBoardItems',
  TEAM: 'teamProjectBoardItems',
});

const initialState = {
  [PROJECT_BOARD_ITEM_PROPS.PERSONAL]: {},
  [PROJECT_BOARD_ITEM_PROPS.TEAM]: {},
  currentProjectBoardItem: {},
};

export default function projectBoardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_PROJECT_BOARD_ITEMS_TYPES.REQUEST: {
      const { projectId } = action.payload;
      return {
        ...state,
        [PROJECT_BOARD_ITEM_PROPS.TEAM]: {
          ...state[PROJECT_BOARD_ITEM_PROPS.TEAM],
          [projectId]: {
            ...(state[PROJECT_BOARD_ITEM_PROPS.TEAM]?.[projectId] || {}),
            list: {
              ...state[PROJECT_BOARD_ITEM_PROPS.TEAM][projectId]?.list,
              status: STATUS.LOADING,
            },
          },
        },
      };
    }
    case GET_TEAM_PROJECT_BOARD_ITEMS_TYPES.SUCCESS: {
      const { projectId } = action.payload;
      const currentDictionary =
        state[PROJECT_BOARD_ITEM_PROPS.TEAM][projectId].dictionary || [];
      const newResources = action.payload.resources.map(
        (resourceParameter) =>
          new action.payload.resourceModel(resourceParameter)
      );

      const prevQuery =
        state[PROJECT_BOARD_ITEM_PROPS.TEAM][projectId]?.list?.query || {};
      const currentQuery = action.payload.query?.extra || {};
      const isQueryEqual = compareProjectBoardQueryObjects(
        prevQuery,
        currentQuery
      );

      return {
        ...state,
        [PROJECT_BOARD_ITEM_PROPS.TEAM]: {
          ...state[PROJECT_BOARD_ITEM_PROPS.TEAM],
          [projectId]: {
            list: {
              ...(state[PROJECT_BOARD_ITEM_PROPS.TEAM]?.[projectId].list || {}),
              status: STATUS.SUCCESS,
              pagination: {
                ...(state[PROJECT_BOARD_ITEM_PROPS.TEAM]?.[projectId].list
                  .pagination || {}),
                total: action.payload.total,
              },
              query: currentQuery,
            },
            dictionary: sortArrayByObjectDateProp(
              isQueryEqual
                ? getUniqueArrayOfObjectsWithCheck(
                    [...currentDictionary, ...newResources],
                    'id',
                    (item1, item2) => item2
                  )
                : [...newResources],
              'date',
              ORDERS.DESC
            ),
          },
        },
      };
    }

    case GET_PERSONAL_PROJECT_BOARD_ITEMS_TYPES.REQUEST: {
      const { projectId } = action.payload;
      return {
        ...state,
        [PROJECT_BOARD_ITEM_PROPS.PERSONAL]: {
          ...state[PROJECT_BOARD_ITEM_PROPS.PERSONAL],
          [projectId]: {
            ...(state[PROJECT_BOARD_ITEM_PROPS.PERSONAL]?.[projectId] || {}),
            list: {
              ...state[PROJECT_BOARD_ITEM_PROPS.PERSONAL][projectId]?.list,
              status: STATUS.LOADING,
            },
          },
        },
      };
    }
    case GET_PERSONAL_PROJECT_BOARD_ITEMS_TYPES.SUCCESS: {
      const { projectId } = action.payload;
      const currentDictionary =
        state[PROJECT_BOARD_ITEM_PROPS.PERSONAL][projectId].dictionary || [];
      const newResources = action.payload.resources.map(
        (resourceParameter) =>
          new action.payload.resourceModel(resourceParameter)
      );

      const prevQuery =
        state[PROJECT_BOARD_ITEM_PROPS.PERSONAL][projectId]?.list?.query || {};
      const currentQuery = action.payload.query?.extra || {};

      const isQueryEqual = compareProjectBoardQueryObjects(
        prevQuery,
        currentQuery
      );

      return {
        ...state,
        [PROJECT_BOARD_ITEM_PROPS.PERSONAL]: {
          ...state[PROJECT_BOARD_ITEM_PROPS.PERSONAL],
          [projectId]: {
            list: {
              ...(state[PROJECT_BOARD_ITEM_PROPS.PERSONAL]?.[projectId].list ||
                {}),
              status: STATUS.SUCCESS,
              pagination: {
                ...(state[PROJECT_BOARD_ITEM_PROPS.PERSONAL]?.[projectId].list
                  .pagination || {}),
                total: action.payload.total,
              },
              query: currentQuery,
            },
            dictionary: sortArrayByObjectDateProp(
              isQueryEqual
                ? getUniqueArrayOfObjectsWithCheck(
                    [...currentDictionary, ...newResources],
                    'id',
                    (item1, item2) => item2
                  )
                : [...newResources],
              'date',
              ORDERS.DESC
            ),
          },
        },
      };
    }

    case DELETE_PROJECT_BOARD_ITEM_TYPES.SUCCESS: {
      const isPersonal = action.payload.isPersonalType;
      const projectBoardItemId = action.payload.id;
      const projectBoardItemProp = isPersonal
        ? PROJECT_BOARD_ITEM_PROPS.PERSONAL
        : PROJECT_BOARD_ITEM_PROPS.TEAM;
      const { projectId } = action.payload;
      const currentDictionary =
        state[projectBoardItemProp]?.[projectId]?.dictionary || [];

      return {
        ...state,
        [projectBoardItemProp]: {
          ...state[projectBoardItemProp],
          [projectId]: {
            list: {
              ...(state[projectBoardItemProp]?.[projectId]?.list || {}),
              pagination: {
                ...state[projectBoardItemProp]?.[projectId]?.list?.pagination,
                total:
                  (state[projectBoardItemProp]?.[projectId]?.list?.pagination
                    ?.total || 0) === 0
                    ? 0
                    : action.payload.total - 1,
              },
            },
            dictionary: sortArrayByObjectDateProp(
              currentDictionary.filter(
                (item) => +item.id !== +projectBoardItemId
              ),
              'date',
              ORDERS.DESC
            ),
          },
        },
      };
    }

    case FIND_PROJECT_BOARD_ITEM_TYPES.SUCCESS:
      return {
        ...state,
        currentProjectBoardItem: {
          ...state.currentProjectBoardItem,
          ...action.payload.resource,
        },
      };

    case GET_PROJECT_BOARD_ITEM_LOGS_TYPES.SUCCESS: {
      return {
        ...state,
        currentProjectBoardItem: {
          ...state.currentProjectBoardItem,
          logs: action.payload.resources.map(
            (item) => new action.payload.resourceModel(item)
          ),
        },
      };
    }

    default:
      return state;
  }
}
