import { createActions, createActionTypesConfig } from '../helpers';

export const ANNOUNCEMENT_LABEL = 'announcement';

/** Action Types */

export const CREATE_ANNOUNCEMENT_TYPES = createActionTypesConfig(
  ANNOUNCEMENT_LABEL,
  'CREATE_ANNOUNCEMENT'
);

export const UPDATE_ANNOUNCEMENT_TYPES = createActionTypesConfig(
  ANNOUNCEMENT_LABEL,
  'UPDATE_ANNOUNCEMENT'
);

export const GET_ANNOUNCEMENTS_TYPES = createActionTypesConfig(
  ANNOUNCEMENT_LABEL,
  'GET_ANNOUNCEMENTS'
);

export const GET_PROJECT_ANNOUNCEMENTS_TYPES = createActionTypesConfig(
  ANNOUNCEMENT_LABEL,
  'GET_PROJECT_ANNOUNCEMENTS'
);

export const FIND_ANNOUNCEMENT_TYPES = createActionTypesConfig(
  ANNOUNCEMENT_LABEL,
  'FIND_ANNOUNCEMENT'
);

export const DELETE_ANNOUNCEMENT_TYPES = createActionTypesConfig(
  ANNOUNCEMENT_LABEL,
  'DELETE_ANNOUNCEMENT'
);

/** Actions */

export const CREATE_ANNOUNCEMENT_ACTION = createActions(
  CREATE_ANNOUNCEMENT_TYPES
);

export const UPDATE_ANNOUNCEMENT_ACTION = createActions(
  UPDATE_ANNOUNCEMENT_TYPES
);

export const GET_ANNOUNCEMENTS_ACTION = createActions(GET_ANNOUNCEMENTS_TYPES);

export const GET_PROJECT_ANNOUNCEMENTS_ACTION = createActions(
  GET_PROJECT_ANNOUNCEMENTS_TYPES
);

export const FIND_ANNOUNCEMENT_ACTION = createActions(FIND_ANNOUNCEMENT_TYPES);

export const DELETE_ANNOUNCEMENT_ACTION = createActions(
  DELETE_ANNOUNCEMENT_TYPES
);
