import { createActions, createActionTypesConfig } from '../helpers';

export const TICKET_LABEL = 'ticket';

export const GET_BY_PROJECT_ID = Object.freeze({
  REQUEST: 'ticket/GET_BY_PROJECT_ID_REQUEST',
  SUCCESS: 'ticket/GET_BY_PROJECT_ID_SUCCESS',
  ERROR: 'ticket/GET_BY_PROJECT_ID_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'ticket/FIND_REQUEST',
  SUCCESS: 'ticket/FIND_SUCCESS',
  ERROR: 'ticket/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'ticket/CREATE_REQUEST',
  SUCCESS: 'ticket/CREATE_SUCCESS',
  ERROR: 'ticket/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'ticket/UPDATE_REQUEST',
  SUCCESS: 'ticket/UPDATE_SUCCESS',
  ERROR: 'ticket/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'ticket/DELETE_REQUEST',
  SUCCESS: 'ticket/DELETE_SUCCESS',
  ERROR: 'ticket/DELETE_ERROR',
});

export const GET_TICKET_STATISTICS_TYPES = createActionTypesConfig(
  TICKET_LABEL,
  'GET_TICKET_STATISTICS'
);

export const GET_TICKETS_BY_PROJECT_ID_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_PROJECT_ID.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_PROJECT_ID.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_PROJECT_ID.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_TICKET_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_TICKET_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_TICKET_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_TICKET_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});

export const GET_TICKET_STATISTICS_ACTION = createActions(
  GET_TICKET_STATISTICS_TYPES
);
