import { createSelector } from '@reduxjs/toolkit';
import { LOGIN } from './constants';
import { getHumanReadableRoles } from '../../utils/helpers';

export const authUserSelector = (state) => state.auth.object.user;

export const isAuthenticatedSelector = (state) =>
  state.auth.object.meta.isAuthenticated;

export const authStatusSelector = (state) => state.auth.status;

export const authErrorSelector = (state) =>
  state.auth.lastAction === LOGIN.ERROR ? state.auth.error : null;

export const authUserRoleSelector = createSelector(
  [authUserSelector],
  (authUser) => {
    return getHumanReadableRoles(authUser.roles)[0];
  }
);

export const authUserHasOtpSelector = (state) => state.auth.object.meta.hasOtp;

export const authUserParentOrgSelector = (state) =>
  state.auth.object.meta.parentOrg;

export const authUserOtpPasswordSelector = (state) =>
  state.auth.object.meta.otpPassword;

export const authUserProjectCountSelector = (state) =>
  state.auth.object.meta.projectCount;
