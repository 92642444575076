import ChatHistoryMessageModel from './ChatHistoryMessageModel';
import TicketModel from '../../api/models/TicketModel';

export default class TicketChatHistoryMessageModel extends ChatHistoryMessageModel {
  static TYPES = Object.freeze({
    USER_NEW_TICKET: 'userNewTicket',
    USER: 'user',
    PRIORITY_CHANGED: 'priorityChanged',

    TICKET_OPENED: 'ticketOpened',
    TICKET_REOPENED: 'ticketReopened',
    TICKET_ON_HOLD: 'ticketOnHold',
    TICKET_RESOLVED: 'ticketResolved',
    TICKET_CLOSED: 'ticketClosed',
    TICKET_ACCEPTED: 'ticketAccepted',
    TICKET_REJECTED: 'ticketRejected',
    SYSTEM_TICKET_LINKED_TO_PROCUREMENT_ID: 'ticketLinkedToProcurementId',
    SYSTEM_TICKET_UNLINKED_FROM_PROCUREMENT_ID:
      'ticketUnlinkedFromProcurementId',
    SYSTEM_TICKET_LINKED_PROCUREMENT_CREATED: 'procurementCreatedFromTicket',
    SYSTEM_TICKET_LINKED_PROCUREMENT_CLOSED: 'linkedProcurementClosed',

    SYSTEM_TICKET_CLOSED: 'systemTicketClosed',
    SYSTEM_USER_JOINED_CHAT: 'systemUserJoinedChat',
    SYSTEM_USER_LEFT_CHAT: 'systemUserLeftChat',
    SYSTEM_MESSAGE_DELETED: 'systemMessageDeleted',
  });

  static getIsSystemMessage = (message) => {
    return [
      this.TYPES.SYSTEM_TICKET_CLOSED,
      this.TYPES.SYSTEM_USER_JOINED_CHAT,
      this.TYPES.SYSTEM_USER_LEFT_CHAT,
      this.TYPES.SYSTEM_TICKET_LINKED_TO_PROCUREMENT_ID,
      this.TYPES.SYSTEM_TICKET_UNLINKED_FROM_PROCUREMENT_ID,
      this.TYPES.SYSTEM_TICKET_LINKED_PROCUREMENT_CREATED,
      this.TYPES.SYSTEM_TICKET_LINKED_PROCUREMENT_CLOSED,
    ].includes(message.type);
  };

  static getIsStatusChangeMessage = (message) => {
    return [
      this.TYPES.TICKET_ACCEPTED,
      this.TYPES.TICKET_OPENED,
      this.TYPES.TICKET_ON_HOLD,
      this.TYPES.TICKET_REJECTED,
      this.TYPES.TICKET_REOPENED,
      this.TYPES.TICKET_RESOLVED,
      this.TYPES.TICKET_CLOSED,
    ].includes(message.type);
  };

  static getIsProcurementLinkingMessage = (message) => {
    return [
      this.TYPES.SYSTEM_TICKET_LINKED_TO_PROCUREMENT_ID,
      this.TYPES.SYSTEM_TICKET_UNLINKED_FROM_PROCUREMENT_ID,
      this.TYPES.SYSTEM_TICKET_LINKED_PROCUREMENT_CREATED,
      this.TYPES.SYSTEM_TICKET_LINKED_PROCUREMENT_CLOSED,
    ].includes(message.type);
  };

  static TYPES_JOINED_WITH_STATUSES = Object.freeze({
    [this.TYPES.TICKET_ACCEPTED]: TicketModel.STATUSES.CLOSED,
    [this.TYPES.TICKET_OPENED]: TicketModel.STATUSES.OPENED,
    [this.TYPES.TICKET_ON_HOLD]: TicketModel.STATUSES.ON_HOLD,
    [this.TYPES.TICKET_REJECTED]: TicketModel.STATUSES.CLOSED,
    [this.TYPES.TICKET_REOPENED]: TicketModel.STATUSES.OPENED,
    [this.TYPES.TICKET_RESOLVED]: TicketModel.STATUSES.RESOLVED,
    [this.TYPES.TICKET_CLOSED]: TicketModel.STATUSES.CLOSED,
  });

  constructor(...params) {
    super(...params);

    this.isSystemMessage = TicketChatHistoryMessageModel.getIsSystemMessage({
      type: this._type,
    });
  }
}
