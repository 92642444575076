export const ATTACHMENT_TYPES = {
  IMAGE: 'image',
  DOCUMENT: 'document',
  ARCHIVE: 'ARCHIVE',
};

export const ATTACHMENT_EXTENSIONS = {
  IMAGE: ['jpg', 'jpeg', 'png'],
  DOCUMENT: ['*'],
  ARCHIVE: ['zip', 'zipx', 'rar', '7z', 's7z'],
};
