import AbstractDataAdapter from '../AbstractDataAdapter';

class MaintenanceOperationDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get createAllowedFields() {
    return ['description', 'operationType'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get createRequiredFields() {
    return ['description', 'operationType'];
  }

  /**
   * @todo remove procedureId if bulk will disappear or it will no longer use it
   * @returns {string[]}
   */
  static get updateAllowedFields() {
    return ['id', 'description', 'operationType', 'procedureId'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get updateRequiredFields() {
    return ['id', 'description', 'operationType'];
  }

  static sendableAttributeRule(attribute) {
    if (attribute === 'operationType') {
      return 'type';
    }
    return super.sendableAttributeRule(attribute);
  }
}

export default MaintenanceOperationDataAdapter;
