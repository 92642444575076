import {
  LOGIN_ACTIONS,
  LOGOUT_ACTIONS,
  ME_ACTIONS,
  SET_PASSWORD_ACTION,
} from './constants';
import { authRequest } from '../../api/requestWrappers/AuthRequest';
import { required } from '../../utils/helpers';
import { userRequest } from '../../api/requestWrappers/UserRequest';

export function login(loginData) {
  return async (dispatch) => {
    dispatch(LOGIN_ACTIONS.REQUEST());

    try {
      const loginResponse = await authRequest.authenticate(loginData);

      return dispatch(LOGIN_ACTIONS.SUCCESS(loginResponse));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw dispatch(LOGIN_ACTIONS.ERROR(e));
    }
  };
}

export function setMe() {
  return async (dispatch) => {
    dispatch(ME_ACTIONS.REQUEST());

    try {
      const user = await authRequest.me();

      return dispatch(ME_ACTIONS.SUCCESS(user));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw dispatch(ME_ACTIONS.ERROR(e));
    }
  };
}

export function logout() {
  return async (dispatch) => {
    dispatch(LOGOUT_ACTIONS.REQUEST());

    try {
      await authRequest.logout();

      return dispatch(LOGOUT_ACTIONS.SUCCESS());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw dispatch(LOGOUT_ACTIONS.ERROR(e));
    }
  };
}

export function setPassword(oldPassword, newPassword) {
  required({ oldPassword, newPassword });
  return async (dispatch) => {
    dispatch(SET_PASSWORD_ACTION.REQUEST());

    try {
      const response = await userRequest.setPassword(oldPassword, newPassword);

      return dispatch(SET_PASSWORD_ACTION.SUCCESS({ response }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw dispatch(SET_PASSWORD_ACTION.ERROR({ error: e.message }));
    }
  };
}
