import MaintenancePlanFilterClientStore from 'clientStore/MaintenancePlanFilterClientStore';

const comparePmpEventQueryObject = (prevQueryObject, currentQueryObject) => {
  const prevFilter = prevQueryObject?.filter || {};
  const currentFilter = currentQueryObject?.filter || {};
  const prevSearch = prevQueryObject?.search?.search || '';
  const currentSearch = currentQueryObject?.search?.search || '';
  return (
    MaintenancePlanFilterClientStore.getSieveQueryAsStrFromObj(prevFilter) ===
      MaintenancePlanFilterClientStore.getSieveQueryAsStrFromObj(
        currentFilter
      ) && prevSearch === currentSearch
  );
};
export default comparePmpEventQueryObject;
