import AbstractModel from '../AbstractModel';
import { STATUS } from '../../../utils/constants';

class DailyCheckProceduresOperationsParametersModel extends AbstractModel {
  constructor(...params) {
    super(...params);

    if (this.status !== STATUS.LOADING) {
      this.overwriteProperty('minValue', {
        get() {
          if ([undefined, null, ''].includes(this._minValue)) {
            return null;
          }
          return Number(this._minValue);
        },
      });
      this.overwriteProperty('maxValue', {
        get() {
          if ([undefined, null, ''].includes(this._maxValue)) {
            return null;
          }
          return Number(this._maxValue);
        },
      });
    }
  }
}

export default DailyCheckProceduresOperationsParametersModel;
