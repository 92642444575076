import AbstractDataAdapter from '../AbstractDataAdapter';

class RoomDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return ['name', 'description', 'positionIndex', 'surface'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return ['name', 'description'];
  }
}

export default RoomDataAdapter;
