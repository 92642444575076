import ApiResourceRequest from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import Pager from '../../helpers/Pager';

export class MaintenanceOperationRequest extends ApiResourceRequest {
  get apiResource() {
    return 'maintenance_procedures/{maintenance_procedure_id}/operations';
  }

  get apiUrl() {
    return this.projectApiUrl;
  }

  async get(
    maintenanceProcedureId,
    pager = new Pager(),
    query = {},
    resourcesDataKey = 'maintenance_procedure_operations'
  ) {
    pager && Pager.check(pager);
    required({ maintenanceProcedureId });
    const paginationParams = this.getPaginationParams(pager);
    const orderParams = this.getOrderParams(query.order);

    const response = await this.request(
      'GET',
      this.routes.GET.replace(
        '{maintenance_procedure_id}',
        maintenanceProcedureId
      ) +
        paginationParams +
        orderParams
    );

    return {
      resources: response?.data?.[resourcesDataKey],
      total: response?.data?.total_item_count,
    };
  }

  async find(maintenanceProcedureId, maintenanceOperationId) {
    required({ maintenanceProcedureId, maintenanceOperationId });
    const response = await this.request(
      'GET',
      `${
        this.routes.FIND.replace(
          '{maintenance_procedure_id}',
          maintenanceProcedureId
        ) + maintenanceOperationId
      }`
    );

    return response?.data;
  }

  async create(maintenanceProcedureId, attributes) {
    required({ maintenanceProcedureId });
    const response = await this.queuedRequest(
      'POST',
      this.routes.CREATE.replace(
        '{maintenance_procedure_id}',
        maintenanceProcedureId
      ),
      attributes
    );

    return response?.data?.result.maintenance_procedure_operation_id;
  }

  async update(
    maintenanceProcedureId,
    maintenanceOperationId,
    attributes,
    method = 'PUT'
  ) {
    required({ maintenanceProcedureId, maintenanceOperationId });
    const response = await this.queuedRequest(
      method,
      this.routes.UPDATE.replace(
        '{maintenance_procedure_id}',
        maintenanceProcedureId
      ) + maintenanceOperationId,
      attributes
    );

    return response?.data?.result;
  }

  async partialUpdate(
    maintenanceProcedureId,
    maintenanceOperationId,
    attributes
  ) {
    return this.update(
      maintenanceProcedureId,
      maintenanceOperationId,
      attributes,
      'PATCH'
    );
  }

  async delete(maintenanceProcedureId, maintenanceOperationId) {
    required({
      maintenanceProcedureId,
      maintenanceOperationId,
    });
    const response = await this.queuedRequest(
      'DELETE',
      this.routes.DELETE.replace(
        '{maintenance_procedure_id}',
        maintenanceProcedureId
      ) + maintenanceOperationId
    );

    return response?.data?.result;
  }
}

export const maintenanceOperationRequest = new MaintenanceOperationRequest();
