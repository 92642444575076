import TypesHelper from 'utils/types/TypesHelper';
import NotificationModel from 'api/models/NotificationModel';
import TicketNotificationsEventCatcher from 'realTimeApi/eventCatchers/ticket/TicketNotificationsEventCatcher';
import AbstractChatNotificationEventCatcher from 'realTimeApi/eventCatchers/AbstractChatNotificationEventCatcher';
import ProjectBoardNotificationsEventCatcher from 'realTimeApi/eventCatchers/projectBoard/ProjectBoardNotificationsEventCatcher';
import setChatNotificationMissingFields from './chat/setChatNotificationMissingFields';
import setTicketNotificationMissingFields from './ticket/setTicketNotificationMissingFields';
import setProjectBoardNotificationMissingFields from './projectBoard/setProjectBoardNotificationMissingFields';

/**
 *
 * @param {(typeof NotificationModel)[]} notificationsModels
 */
const checkParams = (notificationsModels) => {
  const isFirstElementNotExpectedInstance =
    notificationsModels?.length &&
    !(notificationsModels[0] instanceof NotificationModel);

  if (
    !TypesHelper.isArray(notificationsModels) ||
    isFirstElementNotExpectedInstance
  ) {
    throw new Error('incorrect notificationsModels param');
  }
};

/**
 *
 * @param {(typeof NotificationModel)[]} notificationsModels
 */
const setNotificationModelsMissingFields = (notificationsModels) => {
  /** FYI: this function applies model's setters  */
  checkParams(notificationsModels);

  notificationsModels.forEach((item) => {
    /* Ticket notifications block */

    if (
      TicketNotificationsEventCatcher.TICKET_UPDATE_TYPES_AS_ARRAY.includes(
        item.notificationType
      )
    ) {
      setTicketNotificationMissingFields(item);
    }

    /* Chat notifications block */
    if (
      AbstractChatNotificationEventCatcher.CHAT_NOTIFICATION_TYPES_AS_ARRAY.includes(
        item.notificationType
      )
    ) {
      setChatNotificationMissingFields(item);
    }

    /* Project board notifications block */
    if (
      ProjectBoardNotificationsEventCatcher.PROJECT_BOARD_TYPES_AS_ARRAY.includes(
        item.notificationType
      )
    ) {
      setProjectBoardNotificationMissingFields(item);
    }
  });

  return notificationsModels;
};

export default setNotificationModelsMissingFields;
