import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled, { css, useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import TypesHelper from 'utils/types/TypesHelper';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Z_INDEX_VALUES from 'css/constants';
import EllipsisIcon from '../Icons/EllipsisIcon';
import AddIcon from '../Icons/AddIcon';
import DirectionRightIcon from '../Icons/DirectionRightIcon';
import Tooltip from '../Tooltip/Tooltip';

const usePopoverClasses = makeStyles({
  root: {
    zIndex: (props) => `${props.zIndex} !important`,
  },
});

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    overflow: unset;
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  }
`;

const OptionContainer = styled.div`
  position: relative;

  /**
    applying background color hover to whole option container so the first level option is colored when hovering the nested levels container
    this won't color the nested levels container because it is overflowing it's parent
  */
  &:hover {
    background-color: ${({ theme, $hoverColor }) =>
      $hoverColor ?? theme.palette.gray[100]};

    .nested-levels-container {
      display: flex;
    }
  }
`;

const NestedLevelsContainer = styled.div`
  position: absolute;
  padding: 8px 0;
  ${({ $hoverDirection }) => $hoverDirection}: calc(100% - 8px);
  top: -8px;
  min-width: 150px;
  display: none;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.themeCommon.white.main};
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
`;

const OptionWrapper = styled.div`
  ${({ optionHasIcon }) => optionHasIcon && 'margin-left: 13px;'}
  color: ${(props) => props.optionColor};
  font-size: ${({ theme }) =>
    theme.typography.pxToRem(theme.typography.fontSize)};
`;

const StyledIconButton = styled(IconButton)`
  ${({ $withPlusIcon, theme }) =>
    $withPlusIcon
      ? css`
          width: 24px;
          height: 24px;
          background-color: ${theme.palette.primary.main};
        `
      : css`
          background-color: transparent;
        `}

  :hover {
    ${({ $withPlusIcon, theme }) =>
      $withPlusIcon
        ? css`
            background-color: ${theme.palette.primary.light};
            color: ${theme.palette.primary.main};
            border: 2px solid ${theme.palette.primary.main};
          `
        : css`
            background-color: transparent;
          `}
  }
  padding: 0;

  &[disabled] {
    cursor: default;
  }
`;

const StyledDirectionRightIcon = styled(DirectionRightIcon)`
  margin-left: auto;
  position: relative;
  left: 10px;
  border: 0;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  :hover {
    background-color: ${({ theme, $hoverColor }) =>
      $hoverColor ?? theme.palette.gray[100]};
  }
  background-color: ${({ $active, theme }) =>
    $active ? theme.palette.gray[300] : 'unset'};
`;

export default function Dropdown({
  triggerContent: TriggerContent,
  options,
  onIconClick,
  icon: Icon,
  iconColor,
  nestedLevelsContainerHoverDirection,
  disabled,
  className,
  popoverProps,
  onMenuClose,
  customColor,
  zIndex,
  onMenuItemSelected,
  withPlusIcon,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const popoverClasses = usePopoverClasses({ zIndex });

  const handleClick = (event) => {
    const result = onIconClick(event);
    if (result?.stopPropagation) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    onMenuClose(e);
    setAnchorEl(null);
  };

  const renderOption = (item) => {
    const hasNested = Boolean(
      item.nestedLevelOptions && item.nestedLevelOptions.length > 0
    );

    const menuItem = (
      <div>
        <StyledMenuItem
          $hoverColor={item.hoverColor}
          disabled={item.isDisabled}
          $active={item.isActive}
          onClick={(e) => {
            if (item.isDisabled) {
              return;
            }

            TypesHelper.isFunction(item.onClick) && item.onClick(e, item);
            !hasNested && !item.preventDropdownClose && handleClose();
            item.dontTriggerItemSelected || onMenuItemSelected?.(e, item);
          }}
        >
          {item.icon ? item.icon : undefined}

          {TypesHelper.isObject(item.option) ? (
            <OptionWrapper
              optionHasIcon={Boolean(item.icon)}
              optionColor={item.optionColor}
            >
              {item.option}
            </OptionWrapper>
          ) : (
            <OptionWrapper
              optionHasIcon={Boolean(item.icon)}
              optionColor={item.optionColor}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item.option),
              }}
            />
          )}

          {hasNested && <StyledDirectionRightIcon customSize={18} />}
        </StyledMenuItem>
      </div>
    );

    const tooltipText = item?.tooltip?.();

    return (
      <OptionContainer
        className="option-container"
        $hoverColor={item.hoverColor}
        key={`item.option-${Math.random()}`}
      >
        {tooltipText ? (
          <Tooltip
            title={
              <Box
                fontSize={theme.typography.pxToRem(
                  theme.typography.fontSize - 2
                )}
                component="span"
              >
                {tooltipText}
              </Box>
            }
          >
            {menuItem}
          </Tooltip>
        ) : (
          menuItem
        )}

        {hasNested && (
          <NestedLevelsContainer
            className="nested-levels-container"
            $hoverDirection={
              nestedLevelsContainerHoverDirection === 'left' ? 'right' : 'left'
            }
          >
            {item.nestedLevelOptions.map((nestedOption) =>
              renderOption(nestedOption)
            )}
          </NestedLevelsContainer>
        )}
      </OptionContainer>
    );
  };

  return (
    <div className={`dropdown ${className}`}>
      {TriggerContent ? (
        <TriggerContent
          onClick={disabled ? undefined : handleClick}
          dropdownContentIsShown={open}
        />
      ) : (
        <StyledIconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={disabled ? undefined : handleClick}
          disabled={disabled}
          $withPlusIcon={withPlusIcon}
        >
          {withPlusIcon ? (
            <AddIcon
              customColor={theme.palette.themeCommon.white.main}
              hoverSvgStrokeColor={theme.palette.primary.main}
            />
          ) : (
            <Icon
              color={iconColor}
              customColor={customColor}
              hoverCircle={!disabled}
            />
          )}
        </StyledIconButton>
      )}
      <StyledMenu
        elevation={0}
        getContentAnchorEl={null}
        {...popoverProps}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PopoverClasses={popoverClasses}
      >
        {options.map((item) => renderOption(item))}
      </StyledMenu>
    </div>
  );
}

Dropdown.propTypes = {
  triggerContent: PropTypes.func,
  /**
   * options should be an array of icon component and onClick function for that option
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      icon: PropTypes.element,
      onClick: PropTypes.func,
      preventDropdownClose: PropTypes.bool,
      optionColor: PropTypes.func,
      hoverColor: PropTypes.func,
      nestedLevelOptions: PropTypes.arrayOf(PropTypes.any),
      isDisabled: PropTypes.bool,
      tooltip: PropTypes.func,
      isActive: PropTypes.bool,
    })
  ).isRequired,
  onIconClick: PropTypes.func,
  iconColor: PropTypes.string,
  nestedLevelsContainerHoverDirection: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.elementType,
  popoverProps: PropTypes.objectOf(PropTypes.any),
  onMenuClose: PropTypes.func,
  customColor: PropTypes.string,
  zIndex: PropTypes.number,
  onMenuItemSelected: PropTypes.func,
  withPlusIcon: PropTypes.bool,
};

Dropdown.defaultProps = {
  icon: EllipsisIcon,
  triggerContent: undefined,
  onIconClick: () => null,
  iconColor: 'black',
  nestedLevelsContainerHoverDirection: 'right',
  disabled: false,
  className: '',
  popoverProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  onMenuClose: () => null,
  customColor: '',
  zIndex: Z_INDEX_VALUES.POPPER,
  onMenuItemSelected: () => {},
  withPlusIcon: false,
};
