import AbstractEventCatcher from '../AbstractEventCatcher';
import { ticketChatActionCreators } from '../../../store/chat/resource/actions';

export default class TicketChatRemoveMessageEventCatcher extends AbstractEventCatcher {
  static process({ messageInfo, dispatch }) {
    dispatch(
      ticketChatActionCreators.removeMessage(
        messageInfo.ticketId,
        messageInfo.chat.id,
        messageInfo.id
      )
    );
  }
}
