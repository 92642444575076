import authRoutes from 'routes/globalRoutes/auth';
import { lazy } from 'react';
import PATH from '../../paths';
import myProfileRoutes from '../../globalRoutes/myProfile';

const Projects = lazy(() => import('components/layout/mainPages/Projects'));

export const ASSIGNED_PROJECTS = {
  component: Projects,
  path: PATH.PROJECT.LIST,
  exact: true,
  isProtected: true,
};

let routes = [];
const routeFiles = require.context('./', true, /^(?!.\/index|.\/paths).*\.js$/);

routeFiles.keys().forEach((routeFile) => {
  routes = [...routes, ...routeFiles(routeFile).default];
});

const procurementRoutes = [
  ...authRoutes,
  ...myProfileRoutes,
  ...routes,
  ASSIGNED_PROJECTS,
];

export default procurementRoutes;
