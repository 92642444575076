import toCamelCase from 'lodash.camelcase';
import { STATUS } from '../../utils/constants';
import { toSnakeCase } from '../../utils/helpers';

class AbstractModel {
  constructor(attributes = {}, status = null, error = null) {
    const adaptedAttributes = this.getAdaptedAttributes(attributes);
    // eslint-disable-next-line no-restricted-syntax
    for (const [attr, attrValue] of Object.entries(adaptedAttributes)) {
      this[`_${attr}`] = attrValue;
      Object.defineProperty(this, attr, {
        enumerable: true,
        configurable: true,
        get() {
          return this[`_${attr}`];
        },
        set(value) {
          this[`_${attr}`] = value;
        },
      });
    }
    this._status = status ? this.statusValidation(status) : STATUS.SUCCESS;
    this._error = error;
    this._type = toSnakeCase(
      Object.getPrototypeOf(this).constructor.name.split('Model')[0]
    );
  }

  static getType() {
    return toSnakeCase(this.name.split('Model')[0]);
  }

  convertAttribute(attribute) {
    return toCamelCase(attribute);
  }

  statusValidation(status) {
    if (![STATUS.LOADING, STATUS.SUCCESS, STATUS.ERROR].includes(status)) {
      throw new Error('Invalid status!');
    }

    return status;
  }

  get status() {
    return this._status;
  }

  set status(value) {
    return this.statusValidation(value);
  }

  get error() {
    return this._error;
  }

  get type() {
    return this._type;
  }

  /**
   * attributes should exclude reserved words
   * @param attributes
   * @returns {*}
   */
  sanitizeAttributes(attributes) {
    const reservedWords = [
      '_status',
      'status',
      '_error',
      'error',
      'type',
      '_type',
    ];
    const sanitizedAttributes = { ...attributes };
    reservedWords.forEach((reservedWord) => {
      delete sanitizedAttributes[reservedWord];
    });

    return sanitizedAttributes;
  }

  /**
   * adapt backend attributes to frontend format
   * @param attributes
   * @returns {*}
   */
  getAdaptedAttributes(attributes) {
    const adaptedAttributes = {};
    Object.entries(attributes).forEach(([attr, attrValue]) => {
      adaptedAttributes[this.convertAttribute(attr, attrValue)] = attrValue;
    });

    return this.sanitizeAttributes(adaptedAttributes);
  }

  overwriteProperty(property, attributes) {
    if (!(`_${property}` in this)) {
      throw new Error(
        `_${property} is not defined on ${
          Object.getPrototypeOf(this).constructor.name
        }`
      );
    }

    Object.defineProperty(this, property, {
      ...attributes,
      enumerable: true,
      configurable: true,
    });
  }

  static convertToModel(resource) {
    const ModelClass = this;
    return new ModelClass(resource);
  }

  static convertArrayToModels(resourceArr) {
    return resourceArr.map((resource) => this.convertToModel(resource));
  }
}

export default AbstractModel;
