const parseMessage = (message) => {
  const { data: messageData } = message;

  const parsedData = JSON.parse(messageData.toString());

  return {
    event: parsedData.event,
    data: parsedData.data,
  };
};

export default parseMessage;
