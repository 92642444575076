import PROCUREMENT_EVENTS from '../events/procurement.events';
import ProcurementChatEventCatcher from '../eventCatchers/procurement/ProcurementChatEventCatcher';
import ProcurementChatHistoryEventCatcher from '../eventCatchers/procurement/ProcurementChatHistoryEventCatcher';
import ProcurementChatRemoveMessageEventCatcher from '../eventCatchers/procurement/ProcurementChatRemoveMessageEventCatcher';
import ProcurementChatSendMessageEventCatcher from '../eventCatchers/procurement/ProcurementChatSendMessageEventCatcher';
import ProcurementChatAddParticipantEventCatcher from '../eventCatchers/procurement/ProcurementChatAddParticipantEventCatcher';
import ProcurementChatRemoveParticipantEventCatcher from '../eventCatchers/procurement/ProcurementChatRemoveParticipantEventCatcher';
import ProcurementChatUnreadMessagesUpdated from '../eventCatchers/procurement/ProcurementChatUnreadMessagesUpdated';

const processProcurementEvents = ({ event, data, dispatch, loggedInUser }) => {
  switch (event) {
    case PROCUREMENT_EVENTS.CHAT.INTERNAL.GET_CHAT: {
      ProcurementChatEventCatcher.process({
        chat: data,
        dispatch,
        loggedInUser,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.INTERNAL.GET_CHAT_HISTORY: {
      ProcurementChatHistoryEventCatcher.process({
        historyInfo: data,
        dispatch,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.INTERNAL.REMOVE_MESSAGE: {
      ProcurementChatRemoveMessageEventCatcher.process({
        messageInfo: data,
        dispatch,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.INTERNAL.SEND_MESSAGE: {
      ProcurementChatSendMessageEventCatcher.process({
        messageInfo: data,
        dispatch,
        loggedInUser,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.INTERNAL.ADD_PARTICIPANT: {
      ProcurementChatAddParticipantEventCatcher.process({
        participantInfo: data,
        dispatch,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.INTERNAL.REMOVE_PARTICIPANT: {
      ProcurementChatRemoveParticipantEventCatcher.process({
        participantInfo: data,
        dispatch,
        loggedInUser,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.INTERNAL.UNREAD_MESSAGES_UPDATED: {
      ProcurementChatUnreadMessagesUpdated.process({
        unreadMessageInfo: data,
        dispatch,
      });
      break;
    }

    case PROCUREMENT_EVENTS.CHAT.EXTERNAL.GET_CHAT: {
      ProcurementChatEventCatcher.process({
        chat: data,
        dispatch,
        loggedInUser,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.EXTERNAL.GET_CHAT_HISTORY: {
      ProcurementChatHistoryEventCatcher.process({
        historyInfo: data,
        dispatch,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.EXTERNAL.REMOVE_MESSAGE: {
      ProcurementChatRemoveMessageEventCatcher.process({
        messageInfo: data,
        dispatch,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.EXTERNAL.SEND_MESSAGE: {
      ProcurementChatSendMessageEventCatcher.process({
        messageInfo: data,
        dispatch,
        loggedInUser,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.EXTERNAL.ADD_PARTICIPANT: {
      ProcurementChatAddParticipantEventCatcher.process({
        participantInfo: data,
        dispatch,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.EXTERNAL.REMOVE_PARTICIPANT: {
      ProcurementChatRemoveParticipantEventCatcher.process({
        participantInfo: data,
        dispatch,
        loggedInUser,
      });
      break;
    }
    case PROCUREMENT_EVENTS.CHAT.EXTERNAL.UNREAD_MESSAGES_UPDATED: {
      ProcurementChatUnreadMessagesUpdated.process({
        unreadMessageInfo: data,
        dispatch,
      });
      break;
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn('There are no cases for this event', { event, data });
    }
  }
};

export default processProcurementEvents;
