import AbstractEventCatcher from '../../AbstractEventCatcher';
import ChatParticipantModel from '../../../models/ChatParticipantModel';
import ChatRoleModel from '../../../models/ChatRoleModel';
import { numericRegex, required } from '../../../../utils/helpers';
import PATH from '../../../../routes/paths';
import {
  conversationIdPattern,
  projectIdPattern,
} from '../../../../routes/replacePatterns';

export default class AbstractDirectMessagesChannelEventCatcher extends AbstractEventCatcher {
  static findChatOwner(chatParticipants) {
    return chatParticipants
      .map((participant) => new ChatParticipantModel(participant))
      .find((participant) => participant.role === ChatRoleModel.ROLES.OWNER);
  }

  static getIsChatOwner = (creator, loggedInUser) => {
    return creator.user.id === loggedInUser.id;
  };

  static getProjectIdFromUrl = (url) => {
    const projects = 'projects';
    const oneProjectRegExp = new RegExp(`/${projects}\\/${numericRegex}`);

    const match = url.match(oneProjectRegExp);
    const projectOnePath = match?.[0];

    if (projectOnePath) {
      const projectIdString = projectOnePath.slice(
        projectOnePath.lastIndexOf('/') + 1,
        projectOnePath.length
      );

      return Number(projectIdString);
    }

    return undefined;
  };

  static getMessagesOneViewPath = (projectId, conversationId) => {
    required({ conversationId });

    return projectId
      ? PATH.PROJECT.CHAT.CONVERSATION.ONE.replace(
          projectIdPattern,
          projectId.toString()
        ).replace(conversationIdPattern, conversationId.toString())
      : PATH.CHAT.CONVERSATION.ONE.replace(
          conversationIdPattern,
          conversationId.toString()
        );
  };

  static redirectToConversationCheckingChatOwner = (
    chat,
    loggedInUser,
    history
  ) => {
    const creator = this.findChatOwner(chat.chatParticipants);

    if (this.getIsChatOwner(creator, loggedInUser)) {
      this.redirectToConversation(chat, history);
    }
  };

  static redirectToConversation = (chat, history) => {
    const currentUrl = history.location.pathname;

    const projectId = this.getProjectIdFromUrl(currentUrl);

    const chatUrl = this.getMessagesOneViewPath(projectId, chat.id);

    chatUrl && history.push(chatUrl);
  };

  static getDirectMessagesChannelListUrl = (projectId) => {
    return projectId
      ? PATH.PROJECT.CHAT.CONVERSATION.LIST.replace(projectIdPattern, projectId)
      : PATH.CHAT.CONVERSATION.LIST;
  };
}
