import ApiResourceRequest from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import ListingResponse from '../ListingResponse';
import Pager from '../../helpers/Pager';

export class EquipmentModelRequest extends ApiResourceRequest {
  get apiResource() {
    return 'equipments/models';
  }

  get routes() {
    return {
      ...super.routes,
      SEARCH_GET: `${this.apiRoute}/search/{part}`,
    };
  }

  async searchGet(part, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ part });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.SEARCH_GET.replace('{part}', part) + queryString
    );

    return new ListingResponse(response?.data);
  }
}

export const equipmentModelRequest = new EquipmentModelRequest();
