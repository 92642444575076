import ChatModel from '../../../realTimeApi/models/ChatModel';
import TicketChatHistoryMessageModel from '../../../realTimeApi/models/TicketChatHistoryMessageModel';
import ProcurementChatHistoryMessageModel from '../../../realTimeApi/models/ProcurementChatHistoryMessageModel';

export const createTicketChatMessageModel = (
  historyItem,
  conversationInfo = null
) => {
  return new TicketChatHistoryMessageModel({
    ...historyItem,
    ...(conversationInfo instanceof ChatModel ? { conversationInfo } : {}),
  });
};

export const createProcurementChatMessageModel = (
  historyItem,
  conversationInfo = null
) => {
  return new ProcurementChatHistoryMessageModel({
    ...historyItem,
    ...(conversationInfo instanceof ChatModel ? { conversationInfo } : {}),
  });
};
