export default {
  announcement: {
    plural: {
      itself: 'Announcements',
    },
    createAnnouncement: 'Create a new announcement',
    editAnnouncement: 'Edit announcement',
    saveNewAnnouncement: 'Save new announcement',
    form: {
      title: {
        itself: 'Title',
        '*': 'Title *',
        required: 'Title is required',
      },
      summary: {
        itself: 'Summary',
        '*': 'Summary *',
        required: 'Summary is required',
      },
      body: {
        itself: 'Body',
        '*': 'Body *',
        required: 'Body is required',
      },
      coverImage: {
        itself: 'Cover image',
        '*': 'Cover image *',
        required: 'Cover image is required',
        uploadImage: 'Upload an image',
      },
      documentation: {
        itself: 'Documentation',
        '*': 'Documentation *',
        required: 'Documentation is required',
        uploadDocuments: 'Upload documents',
      },
      media: {
        itself: 'Media',
        '*': 'Media *',
        required: 'Media is required',
        uploadImagesVideos: 'Upload images & videos',
      },
      startDate: {
        itself: 'Start date',
        '*': 'Start date *',
        required: 'Start date is required',
      },
      endDate: {
        itself: 'End date',
        '*': 'End date *',
        required: 'End date is required',
      },
    },
    noAnnouncements: 'No announcements yet',
  },
};
