import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function NavigationDropdownIcon({ size, color, primary, ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 4H16M0 1H16M0 7H16"
        stroke={primary ? '#376BFB' : color}
        strokeWidth={size > 24 ? 0.5 : 1.2}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(NavigationDropdownIcon);
