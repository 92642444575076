export default {
  procurement: {
    itself: 'Achiziții',
    plural: {
      itself: 'Achizitii',
    },
    attributes: {
      subject: {
        itself: 'Subiect',
        '*': 'Subiect *',
        required: 'Subiectul e obligatoriu',
      },
      description: {
        itself: 'Descriere',
        '*': 'Descriere *',
        required: 'Descrierea e obligatorie',
        placeholder: 'Descrie problema',
      },
      organization: {
        itself: 'Organizație',
        '*': 'Organizație *',
        required: 'Organizația e obligatorie',
      },
      user: {
        itself: 'Utilizator',
        '*': 'Utilizator *',
        required: 'Utilizatorul e obligatoriu',
      },
      recipient: {
        itself: 'Destinatar',
        '*': 'Destinatar *',
        required: 'Destinatarul e obligatoriu',
      },
      equipment: {
        itself: 'Echipament',
        '*': 'Echipament *',
        required: 'Echipamentul e obligatoriu',
        placeholder: 'Selectează un echipament',
      },
      ticket: {
        itself: 'Tichet',
        '*': 'Tichet *',
        required: 'Tichetul e obligatoriu',
        placeholder: 'Selectează un tichet',
      },
      status: {
        new: 'Nou',
        open: 'Deschis',
        proposalSubmitted: 'Propunere depusă',
        accepted: 'Admis',
        rejected: 'Respins',
        workInProgress: 'Lucrări în curs',
        workFinished: 'Lucrare terminată',
        closed: 'Închis',
        all: 'Toate',
      },
    },
    submitProposal: {
      attributes: {
        proposalFile: {
          itself: 'Fișier ofertă',
          '*': 'Proposal file *',
          required: 'Fișierul ofertă e obligatoriu',
        },
        currency: {
          itself: 'Valută',
          '*': 'Valută *',
          required: 'Valuta e obligatorie',
        },
        amount: {
          itself: 'Sumă',
          '*': 'Sumă *',
          required: 'Introdu un număr',
          greaterThanZero: 'Suma trebuie sa fie mai mare ca 0',
        },
      },
    },
    actions: {
      acknowledge: 'Confirmă',
      markInProgress: 'Marchează în curs',
      submitProposal: 'Trimite oferta',
      cancelProposal: 'Anulează oferta',
      startWork: 'Începe lucrul',
      finish: 'Încheie lucrul',
      rate: 'Evaluează',
    },
    createProcurement: 'Creați o achiziție',
    closeProcurementOrder: 'Închide ordinul de achiziție',
    confirmModals: {
      cancel: 'Nu, anulează',
      confirmClose: 'Da, închide!',
      confirmCancel: 'Da, anulează!',
      confirmCloseMessage:
        'Ești sigur că vrei să închizi acest ordin de achiziție?',
      confirmCancelMessage:
        'Ești sigur că vrei să anulezi acest ordin de achiziție?',
      submitReasonClose: 'Scrie un motiv pentru închidere ..',
      submitReasonCancel: 'Scrie un motiv pentru anulare ..',
      reasonRequired: 'Motivul e obligatoriu',
      confirmProcurementOrder:
        'Ești sigur că vrei să accepți ordinul de achiziție?',
      acceptProcurementOrder: 'Acceptă ordin de achiziție',
      rejectProcurementOrder: 'Respinge ordin de achiziție',
      acceptWork: 'Acceptă munca',
      rejectWork: 'Respinge munca',
    },
    submittedProposal: 'Propunerea depusă',
  },
};
