import { getFileExtension } from '../../../utils/helpers';
import {
  FileRequest,
  fileRequest,
} from '../../../api/requestWrappers/FileRequest';
import {
  ATTACHMENT_EXTENSIONS,
  ATTACHMENT_TYPES,
} from '../../../utils/chat/chat.constants';

const areAttachmentsFromApi = (attachments) => Boolean(attachments[0].uuid);

const getAttachmentExtension = (attachment, fromApi) =>
  getFileExtension(
    fromApi
      ? {
          name: attachment.originalName,
        }
      : attachment.file
  );

const getAttachmentType = (attachmentFileExtension) =>
  // eslint-disable-next-line no-nested-ternary
  ATTACHMENT_EXTENSIONS.IMAGE.includes(attachmentFileExtension)
    ? ATTACHMENT_TYPES.IMAGE
    : ATTACHMENT_EXTENSIONS.ARCHIVE.includes(attachmentFileExtension)
    ? ATTACHMENT_TYPES.ARCHIVE
    : ATTACHMENT_TYPES.DOCUMENT;

const getAttachmentName = (attachment, fromApi) =>
  fromApi ? attachment.originalName : attachment.name;

const getAttachmentFileUrl = (attachment, fromApi) =>
  fromApi
    ? fileRequest.getFilePath(attachment.uuid)
    : URL.createObjectURL(attachment.file);

const getAttachmentFilePreview = (
  attachment,
  attachmentType,
  attachmentFileUrl,
  fromApi
) => {
  const previewUrl = fromApi
    ? fileRequest.getFilePath(
        attachment.uuid,
        FileRequest.THUMBNAIL_SIZES.SQUARE_176_PX
      )
    : attachmentFileUrl;
  return attachmentType === ATTACHMENT_TYPES.IMAGE ? previewUrl : null;
};

const getMessageAttachments = (attachments) => {
  if (!attachments?.length) {
    return [];
  }
  const fromApi = areAttachmentsFromApi(attachments);

  return attachments.map((attachment) => {
    const type = getAttachmentType(getAttachmentExtension(attachment, fromApi));
    const name = getAttachmentName(attachment, fromApi);
    const url = getAttachmentFileUrl(attachment, fromApi);
    const preview = getAttachmentFilePreview(attachment, type, url, fromApi);

    return { type, name, url, preview };
  });
};

export default getMessageAttachments;
