class AbstractClientStore {
  static storage;

  static setStorage(storage) {
    this.storage = storage;
  }
}

AbstractClientStore.setStorage(localStorage);

export default AbstractClientStore;
