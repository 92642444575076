import {
  ADD_NOTIFICATION_TYPES,
  REMOVE_NOTIFICATION_TYPES,
  RESET_NOTIFICATIONS_TYPES,
  SET_NOTIFICATIONS_TYPES,
} from './constants';
import { ORDERS, STATUS } from '../../utils/constants';
import TypesHelper from '../../utils/types/TypesHelper';
import processNotifications from './helpers/processNotifications';
import {
  getUniqueArrayOfObjectsWithCheck,
  sortArrayByObjectDateProp,
} from '../../utils/helpers';

const initialState = {
  list: {
    items: null,
    total: null,
    status: null,
    error: null,
  },
  lastRemovedNotification: {
    status: null,
    error: null,
  },
  resetList: {
    status: null,
    error: null,
  },
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    /** SET_NOTIFICATIONS */
    case SET_NOTIFICATIONS_TYPES.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          status: STATUS.LOADING,
          error: null,
        },
      };
    }
    case SET_NOTIFICATIONS_TYPES.SUCCESS: {
      const currentNotifications = state.list.items || [];

      return {
        ...state,
        list: {
          ...state.list,
          status: STATUS.SUCCESS,
          error: null,
          items: sortArrayByObjectDateProp(
            getUniqueArrayOfObjectsWithCheck(
              [
                ...currentNotifications,
                ...processNotifications(
                  action.payload.resources,
                  action.payload.resourceModel
                ),
              ],
              'id',
              (fistItem, secondItem) => secondItem
            ),
            'createdAt',
            ORDERS.DESC
          ),
          total: action.payload.total,
        },
      };
    }
    case SET_NOTIFICATIONS_TYPES.ERROR: {
      return {
        ...state,
        list: {
          ...state.list,
          status: STATUS.ERROR,
          error: action.payload.error,
        },
      };
    }

    /** ADD_NOTIFICATION */
    case ADD_NOTIFICATION_TYPES.SUCCESS: {
      const total = TypesHelper.isNumber(state.list.total)
        ? state.list.total
        : 0;

      return {
        ...state,
        list: {
          ...state.list,
          items: [
            ...processNotifications(
              [action.payload.newNotification],
              action.payload.resourceModel
            ),
            ...(state.list.items || []),
          ],
          total: total + 1,
        },
      };
    }

    /** REMOVE_NOTIFICATION */
    case REMOVE_NOTIFICATION_TYPES.REQUEST: {
      return {
        ...state,
        lastRemovedNotification: {
          ...state.lastRemovedNotification,
          status: STATUS.LOADING,
          error: null,
        },
      };
    }
    case REMOVE_NOTIFICATION_TYPES.SUCCESS: {
      const total = TypesHelper.isNumber(state.list.total)
        ? state.list.total
        : 0;

      return {
        ...state,
        lastRemovedNotification: {
          ...state.lastRemovedNotification,
          status: STATUS.SUCCESS,
          error: null,
        },
        list: {
          ...state.list,
          total: total > 0 ? total - 1 : 0,
          items: state.list.items?.filter(
            (item) => +item.id !== +action.payload.id
          ),
        },
      };
    }
    case REMOVE_NOTIFICATION_TYPES.ERROR: {
      return {
        ...state,
        lastRemovedNotification: {
          ...state.lastRemovedNotification,
          status: STATUS.ERROR,
          error: action.payload.error,
        },
      };
    }

    /** RESET_NOTIFICATIONS */
    case RESET_NOTIFICATIONS_TYPES.REQUEST: {
      return {
        ...state,

        resetList: {
          ...state.resetList,
          status: STATUS.LOADING,
          error: null,
        },
      };
    }
    case RESET_NOTIFICATIONS_TYPES.SUCCESS: {
      return {
        ...state,
        resetList: {
          ...state.resetList,
          status: STATUS.SUCCESS,
          error: null,
        },
        list: {
          items: [],
          total: 0,
        },
      };
    }
    case RESET_NOTIFICATIONS_TYPES.ERROR: {
      return {
        ...state,
        resetList: {
          ...state.resetList,
          status: STATUS.ERROR,
          error: action.payload.error,
        },
      };
    }

    default:
      return state;
  }
}
