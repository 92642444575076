import AbstractDataAdapter from './AbstractDataAdapter';

class ProcurementDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get createAllowedFields() {
    return [
      'subject',
      'description',
      'projectId',
      'ticketIds',
      'equipmentIds',
      'participantIds',
      'recipientId',
    ];
  }

  /**
   *
   * @returns {string[]}
   */
  static get createRequiredFields() {
    return ['subject', 'description', 'projectId'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get updateAllowedFields() {
    return ['ticketIds', 'equipmentIds', 'workOrderIds'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get updateRequiredFields() {
    return [];
  }
}

export default ProcurementDataAdapter;
