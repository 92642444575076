import { lazy } from 'react';
import PATH from '../paths';

const MyProfileModal = lazy(() =>
  import('../../containers/myProfile/MyProfileModal')
);
const EditProfileModal = lazy(() =>
  import('../../containers/myProfile/EditProfileModal')
);

export const MY_PROFILE_SHOW = {
  component: MyProfileModal,
  path: PATH.MY_PROFILE.SHOW,
  exact: true,
  isProtected: true,
};

export const MY_PROFILE_EDIT = {
  component: EditProfileModal,
  path: PATH.MY_PROFILE.EDIT,
  exact: false,
  isProtected: true,
};

export default [MY_PROFILE_SHOW, MY_PROFILE_EDIT];
