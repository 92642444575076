export const CHAT_PAGER_DEFAULT_LIMIT = 30;

class ChatPager {
  #offset;

  #limit;

  #page;

  static calculateOffset = (page, limit) => {
    return (page - 1) * limit;
  };

  static calculatePage = (offset, limit) => {
    return Math.ceil(offset / limit) + 1;
  };

  constructor(page = 1, limit = CHAT_PAGER_DEFAULT_LIMIT) {
    this.#page = page;
    this.#limit = limit;
    this.#offset = ChatPager.calculateOffset(page, this.limit);
  }

  get limit() {
    return this.#limit;
  }

  get offset() {
    return this.#offset;
  }

  get page() {
    return this.#page;
  }

  getJSON(stringify = true) {
    const pager = {
      offset: this.offset,
      limit: this.limit,
    };

    return stringify ? JSON.stringify(pager) : pager;
  }

  static checkInstance(obj) {
    if (!(obj instanceof ChatPager)) {
      throw new Error('obj is not instance of ChatPager');
    }
  }
}

export default ChatPager;
