export default {
  procedure: {
    itself: 'Procedure',
    plural: {
      itself: 'Procedures',
    },
    selectFrequency: 'Select frequency',
    startDate: 'Start date',
    endDate: 'End date',
    deleteProcedure: 'Delete procedure',
    enableProcedure: 'Enable procedure',
    disableProcedure: 'Disable procedure',
    procedureWillBeDisabledOn: 'This procedure will be disabled on {{date}}',
    procedureIsDisabled: 'This procedure is disabled',
    procedureWillBeEnabledOn: 'This procedure will be enabled on {{date}}',
    enable: 'Enable',
    cancelEnable: 'Cancel enable',
    disable: 'Disable',
    cancelDisable: 'Cancel disable',
    frequency: 'Frequency',
    type: 'Type',
    subcontractor: 'Subcontractor',
    operations: 'Operations',
    createNewProcedure: 'Create a new procedure',
    updateProcedure: 'Edit the procedure',
    saveNewProcedure: 'Save a new procedure',
    saveUpdatedProcedure: 'Save edited procedure',
    form: {
      errors: {
        description: 'Description is required',
        operationType: 'Type is required',
        parameters: {
          name: 'Label is required',
          parameter: 'Parameter is required',
        },
        labels: {
          name: 'Label is required',
        },
      },
    },
    categories: {
      heating: 'Heating',
      cooling: 'Cooling',
      ventilation: 'Ventilation',
      emergencySystem: 'Emergency system',
      sanitary: 'Sanitary',
      plumbingSystem: 'Plumbing system',
      lowVoltage: 'Low voltage',
      extraLowVoltage: 'Extra low voltage',
      others: 'Others',
      fireFightingSystem: 'Fire fighting system',
    },
    errors: {
      startDateShouldBeGreaterThanOrEqualToProjectStartDate: `Start date should be greater than or equal to project's start date ({{date}})`,
    },
    modal: {
      confirmDeleteMessage: `Are you sure? <br /> <br /> This action is irreversible and will result in deleting the maintenance procedure events. Did you mean to Disable instead?`,
    },
  },
};
