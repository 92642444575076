import { lazy } from 'react';
import PATH from '../../paths/index';

const ProcurementList = lazy(() =>
  import('components/layout/mainPages/pmPages/procurement/ProcurementList')
);

const Procurement = lazy(() =>
  import('components/layout/mainPages/beneficiaryPages/procurement/Procurement')
);

const PROCUREMENT_LIST = {
  component: ProcurementList,
  path: PATH.PROJECT.PROCUREMENT.LIST,
  exact: false,
  isProtected: true,
};

const PROCUREMENT = {
  component: Procurement,
  path: PATH.PROJECT.PROCUREMENT.ONE,
  exact: true,
  isProtected: true,
};

/**
 * It's very important to put PROCUREMENT before PROCUREMENT_LIST because we need Switch statement to see PROCUREMENT first
 * as PROCUREMENT_LIST exact is false and we don't want ProcurementModalController from inside ProcurementList
 * to catch PROCUREMENT which is a separate page in the app
 */
export default [PROCUREMENT, PROCUREMENT_LIST];
