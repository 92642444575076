export const GET = Object.freeze({
  REQUEST: 'country/GET_REQUEST',
  SUCCESS: 'country/GET_SUCCESS',
  ERROR: 'country/GET_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'country/FIND_REQUEST',
  SUCCESS: 'country/FIND_SUCCESS',
  ERROR: 'country/FIND_ERROR',
});

export const GET_COUNTRY_STATES = Object.freeze({
  REQUEST: 'country/GET_COUNTRY_STATES_REQUEST',
  SUCCESS: 'country/GET_COUNTRY_STATES_ SUCCESS',
  ERROR: 'country/GET_COUNTRY_STATES_ERROR',
});

export const GET_COUNTRIES_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_COUNTRY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const GET_COUNTRY_STATES_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_COUNTRY_STATES.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_COUNTRY_STATES.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_COUNTRY_STATES.ERROR,
    payload,
    error: true,
  }),
});
