import TypesHelper from '../../../../utils/types/TypesHelper';

export default (state, action, config) => {
  const currentResource =
    state[config.propName][action.payload.resourceId] || {};
  const currentResourceChats = currentResource.chats || {};
  const currentChat = currentResourceChats[action.payload.chatId] || {};
  const currentHistory = currentChat.history || [];
  const currentTotal = currentChat.total;

  return {
    ...state,
    [config.propName]: {
      ...state[config.propName],
      [action.payload.resourceId]: {
        ...currentResource,
        chats: {
          ...currentResourceChats,
          [action.payload.chatId]: {
            ...currentChat,
            history: currentHistory.filter(
              (message) => message.id !== +action.payload.messageId
            ),
            total: TypesHelper.isNumber(currentTotal) ? currentTotal - 1 : 0,
          },
        },
      },
    },
  };
};
