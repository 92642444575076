import toCamelCase from 'lodash.camelcase';
import AbstractModel from '../models/AbstractModel';

class AbstractDataAdapter {
  static ACTION_TYPES = Object.freeze({
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
  });

  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return [];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return [];
  }

  /**
   *
   * @returns {string[]}
   */
  static get createAllowedFields() {
    return [];
  }

  /**
   *
   * @returns {string[]}
   */
  static get createRequiredFields() {
    return [];
  }

  /**
   *
   * @returns {string[]}
   */
  static get updateAllowedFields() {
    return [];
  }

  /**
   *
   * @returns {string[]}
   */
  static get updateRequiredFields() {
    return [];
  }

  static validateRequiredFields() {
    if (
      this.requiredFields.some(
        (requiredField) => !this.allowedFields.includes(requiredField)
      )
    ) {
      throw new Error('requiredFields must be a subset of allowedFields!');
    }
  }

  static isFieldRequired(field) {
    return this.requiredFields.includes(field);
  }

  static sendableAttributeRule(attribute) {
    return toCamelCase(attribute);
  }

  static convertFieldsToBackendFormat(model) {
    const sendableAttributes = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const allowedField of this.allowedFields) {
      if (
        this.isFieldRequired(allowedField) &&
        [null, undefined].includes(model[allowedField])
      ) {
        throw new Error(
          `${allowedField} attribute is required on ${
            Object.getPrototypeOf(model).constructor.name
          } but its value is undefined or null! Also, make sure the backend together with allowedFields and requiredFields arrays are synchronized.`
        );
      }

      sendableAttributes[this.sendableAttributeRule(allowedField)] =
        model[allowedField];
    }

    return sendableAttributes;
  }

  static getAdaptedAttributes(model, actionType) {
    if (!(model instanceof AbstractModel)) {
      throw new Error('model must be an instance of AbstractModel!');
    }

    if (actionType && !Object.values(this.ACTION_TYPES).includes(actionType)) {
      throw new Error(
        `Action type must be one of ${Object.values(
          this.ACTION_TYPES
        ).toString()} ${actionType} received!`
      );
    }

    if (actionType) {
      this.overwriteAllowedFields(
        actionType === AbstractDataAdapter.ACTION_TYPES.CREATE
          ? this.createAllowedFields
          : this.updateAllowedFields
      );
      this.overwriteRequiredFields(
        actionType === AbstractDataAdapter.ACTION_TYPES.CREATE
          ? this.createRequiredFields
          : this.updateRequiredFields
      );
    }

    this.validateRequiredFields();
    this.customValidations(model);
    return this.convertFieldsToBackendFormat(model);
  }

  static customValidations() {}

  static overwriteAllowedFields(fieldsArray) {
    return this.overwriteFieldsGetter('allowedFields', fieldsArray);
  }

  static overwriteRequiredFields(fieldsArray) {
    return this.overwriteFieldsGetter('requiredFields', fieldsArray);
  }

  static overwriteFieldsGetter(property, fieldsArray) {
    if (!(`${property}` in this)) {
      throw new Error(
        `_${property} is not defined on ${
          Object.getPrototypeOf(this).constructor.name
        }`
      );
    }

    Object.defineProperty(this, property, {
      get() {
        return fieldsArray;
      },
      enumerable: true,
      configurable: true,
    });
  }
}

export default AbstractDataAdapter;
