import ApiResourceRequest from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import ListingResponse from '../ListingResponse';
import Pager from '../../helpers/Pager';

export class StandardEquipmentGroupRequest extends ApiResourceRequest {
  get apiResource() {
    return 'standard/category-groups';
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_STANDARD_EQUIPMENT_CATEGORY_ID: `${this.apiRoute}/by-category-id/{standardEquipmentCategoryId}`,
    };
  }

  async getByStandardEquipmentCategoryId(
    standardEquipmentCategoryId,
    pager = new Pager(),
    query = {}
  ) {
    pager && Pager.check(pager);
    required({ standardEquipmentCategoryId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_STANDARD_EQUIPMENT_CATEGORY_ID.replace(
        '{standardEquipmentCategoryId}',
        standardEquipmentCategoryId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }
}

export const standardEquipmentGroupRequest = new StandardEquipmentGroupRequest();
