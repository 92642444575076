import AbstractModel from './AbstractModel';
import { STATUS } from '../../utils/constants';
// this cycle is fine as long as UserModel is not used in top level code
// eslint-disable-next-line import/no-cycle
import UserModel from './UserModel';

class RealmModel extends AbstractModel {
  static TYPES = Object.freeze({
    PROVIDER: 'provider',
    BENEFICIARY: 'beneficiary',
    TENANT: 'tenant',
  });

  constructor(...params) {
    super(...params);
    if (this.status !== STATUS.LOADING && Array.isArray(this.users)) {
      this.users = this.users.map((user) => new UserModel({ ...user }));
    }
    if (this.status !== STATUS.LOADING && this.manager) {
      this.manager = new UserModel(this.manager);
    }
  }

  convertAttribute(attribute) {
    switch (attribute) {
      case 'type':
        return 'organizationType';

      case 'status':
        return 'organizationStatus';

      default:
        return super.convertAttribute(attribute);
    }
  }

  get managerFullName() {
    return `${
      this.manager?.firstName || this.manager?.lastName
        ? `${this.manager?.firstName} ${this.manager?.lastName} `
        : '--'
    }`;
  }

  get fullAddress() {
    switch (true) {
      case Boolean(this.addressOne && this.addressTwo):
        return `${this.addressOne} ${this.addressTwo}`;

      case Boolean(this.addressOne && !this.addressTwo):
        return this.addressOne;

      case Boolean(!this.addressOne && this.addressTwo):
        return this.addressTwo;

      default:
        return '--';
    }
  }

  get isSuspended() {
    return this.organizationStatus === RealmModel.STATUSES.SUSPENDED;
  }

  static STATUSES = Object.freeze({
    ACTIVE: 'Active',
    SUSPENDED: 'Suspended',
  });
}

export default RealmModel;
