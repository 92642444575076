import AbstractModel from '../AbstractModel';
import { STATUS } from '../../../utils/constants';
import MaintenanceParameterModel from './MaintenanceParameterModel';
import MaintenanceLabelModel from './MaintenanceLabelModel';

class MaintenanceOperationModel extends AbstractModel {
  constructor(...params) {
    super(...params);
    if (
      this.status !== STATUS.LOADING &&
      Array.isArray(this.parameters) &&
      Array.isArray(this.labels)
    ) {
      this.maintenanceProcedureOperationParameters = this.parameters.map(
        (parameter) => new MaintenanceParameterModel({ ...parameter })
      );
      this.maintenanceProcedureOperationLabels = this.labels.map(
        (parameter) => new MaintenanceLabelModel({ ...parameter })
      );
    }
  }

  convertAttribute(attribute) {
    if (attribute === 'type') {
      return 'operationType';
    }
    return super.convertAttribute(attribute);
  }
}

export default MaintenanceOperationModel;
