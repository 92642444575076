import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';
import createChatChannelItemModel from '../../../../store/chat/helpers/createChatChannelItemModel';

export default class DirectMessagesUpdateChannelEventCatcher extends AbstractEventCatcher {
  static process(channel, dispatch, loggedInUser) {
    dispatch(
      generalChatMessagesConversationActionCreators.updateConversationInfo(
        channel.id,
        createChatChannelItemModel(channel, loggedInUser.id)
      )
    );
  }
}
