import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  ${({ width, height }) => css`
    width: ${width};
    height: ${height};
  `}
  align-items: center;
  justify-content: center;

  .text-wrap {
    font-weight: 500;
    padding: 15px 0;
  }
`;

// todo @dima delete it if a new design contains only empty message (reuse CenteredText component)
const EmptyNotifications = ({ customText, width, height }) => {
  const { t } = useTranslation();
  return (
    <Wrapper width={width} height={height}>
      <span className="text-wrap">{t(customText)}</span>
    </Wrapper>
  );
};

EmptyNotifications.propTypes = {
  customText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.string,
  height: PropTypes.string,
};
EmptyNotifications.defaultProps = {
  customText: 'general.noNotifications',
  width: '100%',
  height: '100%',
};

export default EmptyNotifications;
