export default {
  pmp: {
    title: 'Jobs overview',
    fetchError: 'Eroare',
    weekNumber: 'Săptămâna #',
    eventStates: {
      planned: 'Planificat',
      opened: 'Deschis',
      inProgress: 'În curs',
      onHold: 'În așteptare',
      resolved: 'Rezolvat',
      closed: 'Închis',
      overdue: 'Peste termen',
      outsourced: 'Externalizat',
      archive: 'Arhivat',
      unarchive: 'Unarchive',
      archived: 'Arhivat',
      draft: 'Proiect',
    },
    worklog: {
      itself: 'Jurnal de lucru',
      entries: 'Jurnal de lucru • {{count}} intrări',
      noEntries: 'Nu există intrări de afișat.',
      changeStatus: 'Status modificat',
      changeDate: 'Dată modificată',
      creatMeasurement: 'Măsurare creată',
      updateMeasurement: 'Măsurare actualizată',
      createOperationData: 'Datele operațiunii create',
      updateOperationData: 'Datele operațiunii actualizate',
      dueDateWasChangeFromTo:
        'Data scadentă a fost modificată de la {{initial}} la {{final}}',
      statusChange: {
        planned: 'Planificat',
        opened: 'Deschis',
        inProgress: 'Marcat în curs',
        onHold: 'Pus în așteptare',
        resolved: 'Rezolvat',
        rejected: 'Respins',
        closed: 'Închis',
        outsourced: 'Externalizat',
      },
    },
    rejectWithNote: 'Respinge cu notă',
    delay: 'Amână',
    start: 'Start',
    resolveProcedure: 'Rezolvă procedura',
    putOnHold: 'Pune în așteptare',
    continue: 'Continuă',
    reject: 'Respinge',
    close: 'Închide',
    additionalNote: {
      itself: 'Notă suplimentară ',
      '*': 'Notă suplimentară *',
      required: 'Nota suplimentară e obligatorie',
    },
    conclusions: {
      itself: 'Concluzii',
      '*': 'Concluzii *',
    },
    addAttachment: 'Adaugă atașament',
    type: 'Tip',
    subcontractor: 'Subcontractor',
    contactPersons: 'Persoane contact',
    date: 'Dată',
    procedureStart: 'Start procedură',
    procedureEnd: 'Sfârșit procedură',
    location: 'Locație',
    equipment: 'Echipament',
    procedureNumber: 'Procedură #',
    overview: 'Rezumat',
    operationSubtitle: '{{total}} Operațiuni • ',
    operationStates: {
      completed: 'Completată',
      notStarted: 'Neîncepută',
      failed: 'Eșuată',
      na: 'N/A',
    },
    visualOperation: 'Valoare selectată',
    visualError: 'Selectează una',
    discard: 'Renunță',
    saveChanges: 'Salvează modificări',
    edit: 'Modifică',
    parameterOperation: 'Parametri',
    parameterError: 'Introduceti o valoare',
    measurementError: {
      invalidSelection: 'Selecția a generat o alertă',
      lessThanMin:
        'Valoarea introdusă este mai mică decât valoarea minimă admisă',
      greaterThanMin:
        'Valoarea introdusă este mai mare decât valoarea maximă admisă',
    },
    feedback: 'Feedback',
    feedbackPlaceholder: 'Lasă feedback ...',
    media: 'Media',
    selectNewDate: {
      itself: 'Selectează dată start',
      '*': 'Selectează dată start *',
    },
    moveEventModal: {
      cancel: 'No, cancel!',
      confirm: 'Yes, move!',
      message:
        'Are you sure you want to move event "{{eventName}}" to week #{{weekNumber}}?',
    },
    tour: {
      status: {
        planned: 'Planificat',
        opened: 'Deschis',
        inProgress: 'În curs',
        resolved: 'Rezolvat',
        closed: 'Închis',
      },
    },
    exportMaintenancePlan: 'Export Maintenance Plan',
    includeProcedureStatusInExport: 'Include statusul procedurilor în export.',
    currentJobs: 'Job-uri curente',
    fullView: 'Vizualizare completă',
    weekly: 'Săptămânal',
    noPmpEventsYet: 'Niciun eveniment PMP încă',
    viewOperations: 'Vedeți operațiuni',
    loadPreviousWeek: {
      itself: 'Afișează săptămâna precedentă',
      plural: 'Afișează săptămânile precedente',
    },
  },
};
