import { setChatHistoryLastReadMessage } from '../../helpers/updateChatHistoryLastReadMessage.helpers';

export default (state, action, config) => {
  const currentResource =
    state[config.propName][action.payload.resourceId] || {};
  const currentResourceChats = currentResource.chats || {};
  const currentChat = currentResourceChats[action.payload.chatId] || {};
  const currentHistory = currentChat.history || [];

  return {
    ...state,
    [config.propName]: {
      ...state[config.propName],
      [action.payload.resourceId]: {
        ...currentResource,
        chats: {
          ...currentResourceChats,
          [action.payload.chatId]: {
            ...currentChat,
            history: setChatHistoryLastReadMessage(
              currentHistory,
              currentChat?.lastReadMessageId
            ),
          },
        },
      },
    },
  };
};
