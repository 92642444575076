import { lazy } from 'react';
import PATH from 'routes/paths';

const SubcontractorList = lazy(() =>
  import('containers/subcontractor/SubcontractorList')
);

export const SUBCONTRACTOR_LIST = {
  component: SubcontractorList,
  path: PATH.SUBCONTRACTOR.LIST,
  exact: false,
  isProtected: true,
};

export default [SUBCONTRACTOR_LIST];
