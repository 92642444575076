import { required } from '../../../utils/helpers';
import {
  RESET_RESOURCE_CHAT_STATE,
  RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES,
  RESOURCE_TICKET_CHAT_ACTIONS_TYPES,
  UPDATE_PROCUREMENT_CHAT_LAST_READ_MESSAGE_ID,
  UPDATE_PROCUREMENT_CHAT_UNREAD_COUNTS,
  UPDATE_TICKET_CHAT_LAST_READ_MESSAGE_ID,
  UPDATE_TICKET_CHAT_UNREAD_COUNTS,
} from '../constants';
import createResourceChatActions from './helpers/createResourceChatActions';

export function updateTicketChatUnreadCounts(chatType, unreadCounts) {
  required(chatType, unreadCounts);
  return {
    type: UPDATE_TICKET_CHAT_UNREAD_COUNTS,
    payload: {
      chatType,
      unreadCounts,
    },
  };
}

export function updateTicketChatLastReadMessageId(chatType, lastReadMessageId) {
  required(chatType, lastReadMessageId);
  return {
    type: UPDATE_TICKET_CHAT_LAST_READ_MESSAGE_ID,
    payload: {
      chatType,
      lastReadMessageId,
    },
  };
}

export function updateProcurementChatUnreadCounts(chatType, unreadCounts) {
  required(chatType, unreadCounts);
  return {
    type: UPDATE_PROCUREMENT_CHAT_UNREAD_COUNTS,
    payload: {
      chatType,
      unreadCounts,
    },
  };
}

export function updateProcurementChatLastReadMessageId(
  chatType,
  lastReadMessageId
) {
  required(chatType, lastReadMessageId);
  return {
    type: UPDATE_PROCUREMENT_CHAT_LAST_READ_MESSAGE_ID,
    payload: {
      chatType,
      lastReadMessageId,
    },
  };
}

export function resetResourceChatState() {
  return {
    type: RESET_RESOURCE_CHAT_STATE,
  };
}

export const ticketChatActionCreators = createResourceChatActions(
  RESOURCE_TICKET_CHAT_ACTIONS_TYPES
);

export const procurementChatActionCreators = createResourceChatActions(
  RESOURCE_PROCUREMENT_CHAT_ACTIONS_TYPES
);
