import subDate from 'date-fns/sub';
import AbstractModel from './AbstractModel';
import TypesHelper from '../../utils/types/TypesHelper';
import { required } from '../../utils/helpers';

class NotificationModel extends AbstractModel {
  static NOTIFICATION_TYPES = {
    UPDATE: 'update',
    CREATE: 'create',
    INCOMING_MESSAGE: 'newMessage',
    CUSTOM: 'custom',
  };

  constructor(...params) {
    super(...params);

    this._authorFirstName = this.creatorFirstName;
    this._authorLastName = this.creatorLastName;

    this.date = this.formatDate(this._createdAt);
  }

  get name() {
    return this._name;
  }

  get authorFullName() {
    return (
      this._authorFirstName &&
      this._authorLastName &&
      `${this._authorFirstName} ${this._authorLastName}`
    );
  }

  get authorNameAbbreviation() {
    const firstLetter = this._authorFirstName?.charAt(0) || '';
    const lastLetter = this._authorLastName?.charAt(0) || '';

    if (firstLetter || lastLetter) {
      return `${firstLetter}${lastLetter}`;
    }
    return '--';
  }

  get description() {
    return this._description;
  }

  get resourceMetaData() {
    return this._resourceMetaData;
  }

  get notificationUiType() {
    return this._notificationUiType;
  }

  get customTypeTitle() {
    return this._customTypeTitle;
  }

  set notificationUiType(type) {
    if (!Object.values(NotificationModel.NOTIFICATION_TYPES).includes(type)) {
      throw new Error('param should be one of NOTIFICATION_TYPES');
    }

    this._notificationUiType = type;
  }

  set description(description) {
    NotificationModel.checkText(description);

    this._description = description;
  }

  set name(name) {
    NotificationModel.checkText(name);

    this._name = name;
  }

  set customTypeTitle(typeTitle) {
    NotificationModel.checkText(typeTitle);

    this._customTypeTitle = typeTitle;
  }

  /**
   *
   * @param {Object} resourceMetaData
   * @param {number | undefined} resourceMetaData.resourceId
   * @param {string} resourceMetaData.resourceType
   * @param {string} resourceMetaData.path
   * @param {number | undefined} resourceMetaData.projectId
   */
  set resourceMetaData(resourceMetaData) {
    required(
      resourceMetaData.resourceType,
      resourceMetaData.resourceId,
      resourceMetaData.path
    );

    this._resourceMetaData = {
      ...resourceMetaData,
    };
  }

  formatDate(date) {
    return date ? subDate(new Date(date), { minutes: 2 }) : undefined;
  }

  convertAttribute(attribute) {
    switch (attribute) {
      case 'type': {
        return 'notificationType';
      }

      default:
        return super.convertAttribute(attribute);
    }
  }

  static checkText(text) {
    if (TypesHelper.isObject(text)) {
      if (!TypesHelper.isString(text.text)) {
        throw new Error('text is required');
      }
    } else if (!TypesHelper.isString(text)) {
      throw new Error('text should be string');
    }
  }
}

export default NotificationModel;
