import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function CategoryIcon({ size, color, ...rest }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.36842 20C9.22875 20 10.7368 18.4919 10.7368 16.6316C10.7368 14.7713 9.22875 13.2632 7.36842 13.2632C5.50809 13.2632 4 14.7713 4 16.6316C4 18.4919 5.50809 20 7.36842 20Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M20 20H13.2632V13.2632H20V20Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.5L12 4L16 10.5H8Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(CategoryIcon);
