import createTheme from './helpers/createTheme';
import GRAY from './helpers/graySpectrum';

// eslint-disable-next-line import/prefer-default-export
export const defaultThemeOptions = Object.freeze({
  // eslint-disable-next-line global-require
  logoSrc: require('./assets/img/cafm-logo.png'),
  palette: {
    themeCommon: {
      separator: GRAY[400],
      gray: GRAY[500],
      black: {
        main: '#2F384C',
        light: '#E9EAEC',
      },
      red: {
        main: '#F0565F',
        light: '#FDECED',
      },
      purple: {
        main: '#952CF2',
        light: '#F5ECFE',
      },
      yellow: {
        main: '#F2B02C',
        light: '#FEF8EC',
      },
      white: {
        main: '#FFFFFF',
      },
      blue: {
        main: '#376BFB',
        normal: '#87A6FD',
        light: '#EBF0FF',
      },
    },
    primary: {
      main: '#376BFB',
      normal: '#87A6FD',
      light: '#EBF0FF',
    },
    secondary: {
      main: '#2CC941',
      light: '#EEFBF0',
    },
    gray: {
      ...GRAY,
    },
    userPicBgColors: ['#5896F3', '#36D098', '#CB58F3'],
  },
  typography: {
    fontFamily: [
      'Archivo',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.04em',
    lineHeight: '1rem',
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: 14,
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: GRAY[100],
        '& fieldset': { borderColor: 'transparent' },
        '&$focused': { background: '#FFF' },
        borderRadius: '6px',
      },
      input: {
        boxSizing: 'border-box',
        height: '2.5rem',
        paddingLeft: '16px',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        minHeight: '2.5rem',
        boxSizing: 'border-box',
        padding: '0  2rem 0 2px !important',
      },
    },
    MuiLink: {
      root: {
        color: 'inherit',
      },
    },
    MuiCssBaseline: {
      '@global': {
        'input::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        'input::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        'input[type=number]': {
          '-moz-appearance': 'textfield',
        },

        '*::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: GRAY[500],
        },
        h3: {
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '1.5rem',
          color: GRAY[1900],
          margin: 0,
          letterSpacing: '0.04em',
        },
        body: {
          fontWeight: 400,
          color: GRAY[1900],
          letterSpacing: '0.04em',
          lineHeight: '1rem',
        },
      },
    },
  },
});

export const defaultTheme = createTheme(defaultThemeOptions);
