export const QUERY_KEYS = Object.freeze({
  LISTING: Object.freeze({
    PAGE: 'page',
    PAGE_SIZE: 'pageSize',
  }),
  ORDER: 'order',
  STATUS: 'status',
  ORG_TYPE: 'orgType',
  SEARCH: 'search',
  FILTER: 'filter',
  VIEW: 'view',
  TICKETS_TYPE: 'ticketsType',
});

export const ALL_KEY = 'ALL';
