export const GET = Object.freeze({
  REQUEST: 'level/GET_REQUEST',
  SUCCESS: 'level/GET_SUCCESS',
  ERROR: 'level/GET_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'level/FIND_REQUEST',
  SUCCESS: 'level/FIND_SUCCESS',
  ERROR: 'level/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'level/CREATE_REQUEST',
  SUCCESS: 'level/CREATE_SUCCESS',
  ERROR: 'level/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'level/UPDATE_REQUEST',
  SUCCESS: 'level/UPDATE_SUCCESS',
  ERROR: 'level/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'level/DELETE_REQUEST',
  SUCCESS: 'level/DELETE_SUCCESS',
  ERROR: 'level/DELETE_ERROR',
});

export const LINK = Object.freeze({
  REQUEST: 'level/LINK_REQUEST',
  SUCCESS: 'level/LINK_SUCCESS',
  ERROR: 'level/LINK_ERROR',
});

export const UNLINK = Object.freeze({
  REQUEST: 'level/UNLINK_REQUEST',
  SUCCESS: 'level/UNLINK_SUCCESS',
  ERROR: 'level/UNLINK_ERROR',
});

export const GET_LEVELS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_LEVEL_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_LEVEL_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_LEVEL_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_LEVEL_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});

export const LINK_LEVEL_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: LINK.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: LINK.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: LINK.ERROR,
    payload,
    error: true,
  }),
});

export const UNLINK_LEVEL_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UNLINK.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UNLINK.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UNLINK.ERROR,
    payload,
    error: true,
  }),
});
