import general from './general/en';
import realm from './realm/en';
import user from './user/en';
import building from './buildingBox/building/en';
import level from './buildingBox/building/level/en';
import surface from './buildingBox/building/level/surface/en';
import room from './buildingBox/building/level/room/en';
import project from './project/en';
import subcontractor from './subcontractor/en';
import role from './role/en';

import dailyCheck from './dailyCheckBox/en';
import dailyCheckProcedure from './dailyCheckBox/procedure/en';
import dailyCheckOperation from './dailyCheckBox/procedure/operation/en';

import group from './equipmentBox/category/group/en';
import equipment from './equipmentBox/category/group/equipment/en';
import procedure from './equipmentBox/category/group/equipment/procedure/en';
import operation from './equipmentBox/category/group/equipment/procedure/operation/en';
import parameter from './equipmentBox/category/group/equipment/procedure/operation/parameter/en';

import ticket from './ticket/en';
import procurement from './procurement/en';

import metaTitles from './metaTitles/en';

import pmp from './pmp/en';
import inventory from './inventory/en';

import chat from './chat/en';
import error from './apiErrors/en';

import standardProcedure from './standard/procedure/en';

import warrantyTracker from './warrantyTracker/en';

import dailyProcedure from './dailyProcedure/en';
import dailyCheckTour from './dailyCheck/tour/en';
import dailyCheckTourEvent from './dailyCheck/tour/event/en';

import layout from './layout/en';

import announcement from './announcement/en';
import activityReport from './activityReport/en';
import units from './units/en';

import report from './report/en';

const en = {
  translations: {
    ...general,
    ...realm,
    ...user,
    ...building,
    ...level,
    ...surface,
    ...room,
    ...project,
    ...subcontractor,
    ...role,
    ...units,

    ...dailyCheck,
    ...dailyCheckProcedure,
    ...dailyCheckOperation,

    ...group,
    ...equipment,
    ...procedure,
    ...operation,
    ...parameter,

    ...ticket,

    ...procurement,

    ...metaTitles,

    ...pmp,

    ...inventory,

    ...chat,

    ...error,

    ...standardProcedure,

    ...warrantyTracker,

    ...dailyProcedure,
    ...dailyCheckTour,
    ...dailyCheckTourEvent,

    ...layout,

    ...announcement,
    ...activityReport,
    ...report,

    cancel: 'Cancel',
    submit: 'Submit',
    welcome: 'Welcome {{name}} this is a protected page!',
    login: 'Log in',
    logout: 'Log out',
    back: 'Back',
    next: 'Next',
    generate: 'Generate',
    save: 'Save',
    saveAndContinue: 'Save & continue',
    skip: 'Skip',
    signIn: 'Sign in',
    enterCredentials: 'Enter your credentials',
    createStrongPassword: 'Create a strong password',
    profilePic: 'Profile pic',
    yesterday: 'Yesterday',
    today: 'Today',
    tomorrow: 'Tomorrow',
    outdated: 'Outdated',
    nextWeeks: 'Next weeks',
    week: 'Week',
    reminder: 'Reminder',

    newPassword: 'New password',
    repeatNewPassword: 'Repeat new password',

    invalidEmail: 'Invalid email',
    enterEmail: 'Enter your email',
    enterUsername: 'Enter your username',
    enterPassword: 'Enter your password',
    enterPasswordOrPin: 'Enter your password or pin',

    browseOrDragDrop: 'Browse or drag & drop document',
    dropFile: 'Drop file',

    username: 'Username',
    usernameRequired: 'Username is required',

    emailAddress: 'Email address',
    emailRequired: 'Email is required',

    password: 'Password',
    passwordRequired: 'Password is required',

    pin: 'Pin',
    pinRequired: 'Pin is required',

    name: 'Name',
    nameRequired: 'Name is required',
    firstNameRequired: 'First name is required',
    lastNameRequired: 'Last name is required',

    resourceType: 'Resource type',
    resourceTypeRequired: 'Resource type is required',

    selectPermission: 'Select a permission',
    permissionRequired: 'Permission is required',

    selectUser: 'Select an user',
    userRequired: 'User is required',

    selectPermissionContext: 'Select a permission context',
    permissionContextRequired: 'Permission context is required',

    assign: 'Assign',
    unassign: 'Unassign',

    assignmentType: 'Assignment type',
    assignmentTypeRequired: 'Assignment type is required',

    accessType: 'Access type',
    accessTypeRequired: 'Access type is required',

    grantAccess: 'Grant access',
    denyAccess: 'Deny access',

    selectResource: 'Select a resource',
    resourceRequired: 'Resource is required',

    assignPermissionPermissionContext:
      'Assign permission to permission context',
    assignPermissionRole: 'Assign permission to role',
    assignUserRole: 'Assign user to role',
    assignAccessResource: 'Assign access resource',
    assignAccessResourceRole: 'Assign access resource to role',

    allowedActions: 'Allowed actions',
    action: 'Action',

    users: 'Users',
    goToUserList: 'Go to user list',
    backToUserList: 'Back to user list',
    addNewUser: 'Add new user',
    createUpdateUser: 'Create or update user',

    userGroups: 'User groups',
    goToUserGroupList: 'Go to user group list',
    backToUserGroupList: 'Back to user group list',
    addNewUserGroup: 'Add new user group',
    createUpdateUserGroup: 'Create or update user group',

    roles: 'Roles',
    goToRoleList: 'Go to role list',
    backToRoleList: 'Back to role list',
    addNewRole: 'Add new role',
    createUpdateRole: 'Create or update role',

    permissions: 'Permissions',
    goToPermissionList: 'Go to permission list',
    backToPermissionList: 'Back to permission list',
    addNewPermission: 'Add new permission',
    createUpdatePermission: 'Create or update permission',

    permissionContexts: 'Permission contexts',
    goToPermissionContextList: 'Go to permission context list',
    backToPermissionContextList: 'Back to permission context list',
    addNewPermissionContext: 'Add new permission context',
    createUpdatePermissionContext: 'Create or update permission context',

    projects: 'Projects',
    goToProjectList: 'Go to project list',
    backToProjectList: 'Back to project list',
    addNewProject: 'Add new project',
    createUpdateProject: 'Create or update project',

    hiddenItems: 'Hidden {{count}} items',
    hide: 'Hide',
    more: '{{count}} More',

    changeDueDate: 'Change due date',
    addNote: 'Add note',

    view: 'View',

    language: 'Language',
    ro: 'Romanian',
    en: 'English',
  },
};

export default en;
