import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  useAdminMenuItems,
  useBeneficiaryProjectMenuItems,
  useProjectMenuItems,
} from 'hooks/menu/useMenuItems';
import Z_INDEX_VALUES from 'css/constants';
import { IconButton, Slide } from '@material-ui/core';
import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import {
  Link as RouterLink,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import Fab from 'stories/ui/mobile/Fab/Fab';
import HomeIcon from 'stories/ui/Icons/HomeIcon';
import ArrowPrevIcon from 'stories/ui/Icons/ArrowPrevIcon';
import PATH from 'routes/paths';
import useProjectPublicData from 'containers/project/hooks/useProjectPublicData';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { authUserSelector } from 'store/auth/selectors';
import RoleModel from 'api/models/RoleModel';
import { ReactComponent as CafmMainLogo } from 'assets/coralLogo40x40.svg';
import UserProfileHeader from '../../../../components/layout/Header/UserProfileHeader';

const Overlay = styled.div`
  position: fixed;
  touch-action: pan-x pinch-zoom;
  z-index: ${Z_INDEX_VALUES.MOBILE_MENU};
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: ${Z_INDEX_VALUES.MOBILE_MENU};
  overflow: auto;
  top: 0;
  right: 72px;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.themeCommon.white.main};
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div`
  height: ${({ header }) => (header ? 'auto' : '56px')}
  margin: 0 12px;
  padding: ${({ header }) => (header ? '8px 0 !important' : '10px 0')};
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.themeCommon.black.light};
  display: flex;
  align-items: center;
  
  a {
    ${({ header }) =>
      header &&
      css`
        display: flex;
        align-items: center;
      `}
  }

  :first-child {
    margin: 0;
    padding: 20px 12px;
  }

  :last-child {
    margin: auto 0 0 0;
    padding: 20px 12px;
    border-top: 1px solid
      ${({ theme }) => theme.palette.themeCommon.black.light};
    border-bottom: 0;
  }

  ${({ menuItemPath, currentPath, theme }) => {
    if (typeof currentPath === 'string' && currentPath === menuItemPath) {
      return `
        color: ${theme.palette.primary.main};
        .MuiIconButton-label svg path {
          stroke: ${theme.palette.primary.main};
      `;
    }
    return null;
  }}
`;

const UserProfileHeaderWrap = styled.div`
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.palette.themeCommon.white.main};
  width: 100%;

  .logged-in-user-header {
    padding: 0;
    padding-right: 10px;
  }
`;

const StyledCafmMainLogo = styled(CafmMainLogo)`
  margin-left: 24px;
`;

const useGetProjectName = () => {
  const match = useRouteMatch(PATH.PROJECT.ONE);
  const projectPublicData = useProjectPublicData(match?.params?.projectId);

  return { projectName: projectPublicData?.name };
};

export default function MobileMenu({ mobileMenuIsActive }) {
  const projectPageMatch = useRouteMatch(PATH.PROJECT.ONE);
  const isInProject = Boolean(projectPageMatch);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { menuItems: projectMenuItems } = useProjectMenuItems();
  const { menuItems: adminMenuItems } = useAdminMenuItems();
  const {
    menuItems: projectBeneficiaryMenuItems,
  } = useBeneficiaryProjectMenuItems();
  const { projectName } = useGetProjectName();
  const currentPath = useLocation().pathname;
  const { t } = useTranslation();
  const loggedInUser = useSelector(authUserSelector);
  const isLoggedInUserAtLeastCoordinator = RoleModel.authUserIsAtLeastCoordinator(
    loggedInUser
  );
  const isLoggedInUserPmOrApm = RoleModel.authUserIsPmOrApm(loggedInUser);
  const isLoggedInUserBeneficiaryOrTenant = RoleModel.authUserIsBeneficiaryOrTenant(
    loggedInUser
  );

  const getCurrentMenuItems = () => {
    if (isInProject) {
      switch (true) {
        case isLoggedInUserAtLeastCoordinator:
        case isLoggedInUserPmOrApm:
          return projectMenuItems;
        case isLoggedInUserBeneficiaryOrTenant:
          return projectBeneficiaryMenuItems;
        default:
          return [];
      }
    }

    switch (true) {
      case isLoggedInUserAtLeastCoordinator:
        return adminMenuItems;
      default:
        return [];
    }
  };

  const currentMenuItems = getCurrentMenuItems();

  const hideMobileMenu = () => {
    setShowMobileMenu(false);
  };

  return (
    <>
      {showMobileMenu && <Overlay className="mobile-menu-overlay" />}
      <Slide in={showMobileMenu} direction="right">
        <Wrapper className="mobile-menu">
          <MenuItem header>
            <Link
              component={RouterLink}
              to={PATH.PROJECT.LIST}
              onClick={hideMobileMenu}
            >
              <IconButton>
                <ArrowPrevIcon />
              </IconButton>
              <h3 style={{ display: 'inline' }}>
                {isInProject ? projectName || t('metaTitles.project') : 'Menu'}
              </h3>

              <StyledCafmMainLogo />
            </Link>
          </MenuItem>
          {currentMenuItems.map((menuItem) => {
            return (
              <MenuItem
                key={menuItem.text}
                currentPath={currentPath}
                menuItemPath={menuItem.path}
              >
                <Link
                  component={RouterLink}
                  to={menuItem.path}
                  onClick={hideMobileMenu}
                >
                  <IconButton>{menuItem.icon}</IconButton>
                  <span className="menu-item-text">{menuItem.text}</span>
                </Link>
              </MenuItem>
            );
          })}
          {isInProject && (
            <MenuItem>
              <Link
                component={RouterLink}
                to={PATH.DASHBOARD}
                onClick={hideMobileMenu}
              >
                <IconButton>
                  <HomeIcon />
                </IconButton>
                <span className="menu-item-text">Home</span>
              </Link>
            </MenuItem>
          )}

          <UserProfileHeaderWrap>
            <UserProfileHeader
              className="logged-in-user-header"
              zIndex={Z_INDEX_VALUES.MOBILE_MENU + 1}
              onMenuItemSelected={() => setShowMobileMenu(false)}
            />
          </UserProfileHeaderWrap>
        </Wrapper>
      </Slide>
      <Fab
        fabIsActive={mobileMenuIsActive}
        showMenu={showMobileMenu}
        onShowMenuChange={(show) => setShowMobileMenu(show)}
      />
    </>
  );
}

MobileMenu.propTypes = {
  mobileMenuIsActive: PropTypes.bool,
};

MobileMenu.defaultProps = {
  mobileMenuIsActive: true,
};
