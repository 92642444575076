import { QUERY_KEYS } from 'routes/paths/query.constants';
import PMPEventModel from 'api/models/PMPEventModel';
import AbstractFilterClientStore from './AbstractFilterClientStore';
import TypesHelper from '../utils/types/TypesHelper';

class MaintenancePlanFilterClientStore extends AbstractFilterClientStore {
  static get resourceName() {
    return 'maintenance-plan';
  }

  static getSieveQueryAsObj = (str) => {
    const query = new URLSearchParams(str);
    const filterQueryArr =
      query
        .get(QUERY_KEYS.FILTER)
        ?.split(PMPEventModel.FILTER.KEY_DELIMITER)
        ?.filter(Boolean) || [];

    const buildingsQueryParam = filterQueryArr.find((param) =>
      param.includes(PMPEventModel.FILTER.BUILDING)
    );
    const levelsQueryParam = filterQueryArr.find((param) =>
      param.includes(PMPEventModel.FILTER.LEVEL)
    );
    const roomsQueryParam = filterQueryArr.find((param) =>
      param.includes(PMPEventModel.FILTER.ROOM)
    );
    const includeDisabledQueryParam = filterQueryArr.find((param) =>
      param.includes(PMPEventModel.FILTER.INCLUDE_DISABLED)
    );
    const includeDraftQueryParam = filterQueryArr.find((param) =>
      param.includes(PMPEventModel.FILTER.INCLUDE_DRAFT)
    );
    const frequencyQueryParam = filterQueryArr.find((param) =>
      param.includes(PMPEventModel.FILTER.FREQUENCY)
    );
    const categoryQueryParam = filterQueryArr.find((param) =>
      param.includes(PMPEventModel.FILTER.CATEGORY)
    );
    const groupQueryParam = filterQueryArr.find((param) =>
      param.includes(PMPEventModel.FILTER.GROUP)
    );
    const equipmentQueryParam = filterQueryArr.find((param) =>
      param.includes(PMPEventModel.FILTER.EQUIPMENT)
    );
    const typeQueryParam = filterQueryArr.find((param) =>
      param.includes(PMPEventModel.FILTER.TYPE)
    );

    const getValuesArr = (queryParam, { toNumber, toBoolean } = {}) => {
      if (queryParam) {
        const results = queryParam
          .split(PMPEventModel.FILTER.KEY_VALUE_DELIMITER)[1]
          .split(PMPEventModel.FILTER.VALUE_DELIMITER)
          .filter(Boolean);

        if (toNumber) {
          return results.map(Number);
        }
        if (toBoolean) {
          return results.map((item) => item === 'true');
        }
        return results;
      }
      return [];
    };

    return {
      [PMPEventModel.FILTER.BUILDING]: getValuesArr(buildingsQueryParam, {
        toNumber: true,
      }),
      [PMPEventModel.FILTER.LEVEL]: getValuesArr(levelsQueryParam, {
        toNumber: true,
      }),
      [PMPEventModel.FILTER.ROOM]: getValuesArr(roomsQueryParam, {
        toNumber: true,
      }),
      [PMPEventModel.FILTER.FREQUENCY]: getValuesArr(frequencyQueryParam),
      [PMPEventModel.FILTER.INCLUDE_DISABLED]: getValuesArr(
        includeDisabledQueryParam,
        {
          toBoolean: true,
        }
      ),
      [PMPEventModel.FILTER.INCLUDE_DRAFT]: getValuesArr(
        includeDraftQueryParam,
        {
          toBoolean: true,
        }
      ),
      [PMPEventModel.FILTER.CATEGORY]: getValuesArr(categoryQueryParam, {
        toNumber: true,
      }),
      [PMPEventModel.FILTER.GROUP]: getValuesArr(groupQueryParam, {
        toNumber: true,
      }),
      [PMPEventModel.FILTER.EQUIPMENT]: getValuesArr(equipmentQueryParam, {
        toNumber: true,
      }),
      [PMPEventModel.FILTER.TYPE]: getValuesArr(typeQueryParam),
    };
  };

  static getSieveQueryAsStrFromObj = (obj) => {
    const subQueryArr = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [param, value] of Object.entries(obj)) {
      if (TypesHelper.isArray(value) && value.length) {
        subQueryArr.push(
          `${param}${PMPEventModel.FILTER.KEY_VALUE_DELIMITER}${value.join(
            PMPEventModel.FILTER.VALUE_DELIMITER
          )}`
        );
      }
    }
    return subQueryArr.join(PMPEventModel.FILTER.KEY_DELIMITER);
  };
}

export default MaintenancePlanFilterClientStore;
