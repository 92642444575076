export default {
  inventory: {
    itself: 'Inventar',
    createNewInventoryItem: 'Creează un nou articol de inventar',
    editInventoryItem: 'Modifică articol de inventar',
    createItem: 'Creează articol',
    editItem: 'Modifică articol',
    emptyInventoryList:
      'Apăsați butonul albastru plus și începeți să adăugați articole în inventar',
    attributes: {
      name: {
        itself: 'Nume',
        '*': 'Nume *',
        required: 'Numele e obligatoriu',
      },
      description: {
        itself: 'Descriere',
        '*': 'Descriere *',
        required: 'Descrierea e obligatorie',
        placeholder: 'Descrie articolul',
      },
      quantity: {
        itself: 'Cantitate',
        '*': 'Cantitate *',
        required: 'Introdu un număr',
        placeholder: 'Introdu cantitatea',
      },
      owner: {
        itself: 'Posesor',
        '*': 'Posesor *',
        required: 'Posesorul e obligatoriu',
        types: {
          project: 'Proiect',
          provider: 'Furnizor',
        },
      },
      category: {
        itself: 'Categorie',
        '*': 'Categorie *',
        required: 'Categoria este obligatorie',
        types: {
          consumables: 'Consumabile',
          tools: 'Instrumente',
        },
      },
    },
    log: {
      title: 'Jurnal • {{count}} intrări',
      add: 'Added {{count}} items',
      extract: 'Extracted {{count}} items',
      create: 'Inventar creat',
      delete: 'Inventarul a fost șters',
      fieldsUpdate: 'Actualizare câmpuri',
    },
    addTitle: 'Adăuga',
    extractTitle: 'Extract',
    addInputTitle: 'Număr de articole suplimentare *',
    extractInputTitle: 'Număr de articole extrase *',
    extractInputTitle2: 'din {{total}}',
    addButton: 'Adăuga',
    extractButton: 'Extrage',
    description: 'Descriere',
    quantity: 'Cantitate',
    owner: 'Proprietar',
    availability: 'Disponibilitate',
    inStock: 'În stoc',
    outOfStock: 'Stoc epuizat',
    reasonInputTitle: {
      itself: 'Reason',
      '*': 'Reason *',
    },
    addNotesInputTitle: {
      itself: 'Additional notes',
      '*': 'Additional notes *',
    },
  },
};
