import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';

export default class DirectMessagesRemoveMessageEventCatcher extends AbstractEventCatcher {
  static process(removedMessage, dispatch) {
    dispatch(
      generalChatMessagesConversationActionCreators.removeMessage(
        removedMessage.chat.id,
        removedMessage.id
      )
    );
  }
}
