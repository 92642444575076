import React, { useState } from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function DashboardIcon({ size, color, hoverDisabled, hoverBgColor, ...rest }) {
  const [fillColor, setFillColor] = useState(undefined);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      onMouseEnter={() => {
        setFillColor(hoverBgColor);
      }}
      onMouseLeave={() => {
        setFillColor(undefined);
      }}
    >
      {hoverDisabled ? undefined : (
        <path
          d="M10.7368 7.36842C10.7368 9.22875 9.22875 10.7368 7.36842 10.7368C5.50809 10.7368 4 9.22875 4 7.36842C4 5.50809 5.50809 4 7.36842 4C9.22875 4 10.7368 5.50809 10.7368 7.36842Z"
          fill={fillColor}
        />
      )}
      {hoverDisabled ? undefined : (
        <path
          d="M20 7.36842C20 9.22875 18.4919 10.7368 16.6316 10.7368C14.7713 10.7368 13.2632 9.22875 13.2632 7.36842C13.2632 5.50809 14.7713 4 16.6316 4C18.4919 4 20 5.50809 20 7.36842Z"
          fill={fillColor}
        />
      )}
      {hoverDisabled ? undefined : (
        <path
          d="M10.7368 16.6316C10.7368 18.4919 9.22875 20 7.36842 20C5.50809 20 4 18.4919 4 16.6316C4 14.7713 5.50809 13.2632 7.36842 13.2632C9.22875 13.2632 10.7368 14.7713 10.7368 16.6316Z"
          fill={fillColor}
        />
      )}
      {hoverDisabled ? undefined : (
        <path
          d="M20 16.6316C20 18.4919 18.4919 20 16.6316 20C14.7713 20 13.2632 18.4919 13.2632 16.6316C13.2632 14.7713 14.7713 13.2632 16.6316 13.2632C18.4919 13.2632 20 14.7713 20 16.6316Z"
          fill={fillColor}
        />
      )}
      <path
        d="M10.7368 7.36842C10.7368 9.22875 9.22875 10.7368 7.36842 10.7368C5.50809 10.7368 4 9.22875 4 7.36842C4 5.50809 5.50809 4 7.36842 4C9.22875 4 10.7368 5.50809 10.7368 7.36842Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M20 7.36842C20 9.22875 18.4919 10.7368 16.6316 10.7368C14.7713 10.7368 13.2632 9.22875 13.2632 7.36842C13.2632 5.50809 14.7713 4 16.6316 4C18.4919 4 20 5.50809 20 7.36842Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M10.7368 16.6316C10.7368 18.4919 9.22875 20 7.36842 20C5.50809 20 4 18.4919 4 16.6316C4 14.7713 5.50809 13.2632 7.36842 13.2632C9.22875 13.2632 10.7368 14.7713 10.7368 16.6316Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M20 16.6316C20 18.4919 18.4919 20 16.6316 20C14.7713 20 13.2632 18.4919 13.2632 16.6316C13.2632 14.7713 14.7713 13.2632 16.6316 13.2632C18.4919 13.2632 20 14.7713 20 16.6316Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(DashboardIcon);
