import AbstractEventCatcher from '../../AbstractEventCatcher';
import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';

export default class MessageChatChannelUnreadMessagesUpdatedEventCatcher extends AbstractEventCatcher {
  static process(
    { unreadMessagesIds, lastReadMessageId, lastReadMessage, chatId },
    dispatch
  ) {
    dispatch(
      generalChatMessagesConversationActionCreators.updateUnreadMessagesIds(
        chatId,
        unreadMessagesIds
      )
    );

    const firstUnreadMessageId = lastReadMessageId || unreadMessagesIds[0];

    if (firstUnreadMessageId) {
      dispatch(
        generalChatMessagesConversationActionCreators.updateLastReadMessageId(
          chatId,
          firstUnreadMessageId
        )
      );

      if (lastReadMessage) {
        dispatch(
          generalChatMessagesConversationActionCreators.addNewMessage(
            chatId,
            lastReadMessage
          )
        );
      }

      dispatch(
        generalChatMessagesConversationActionCreators.setHistoryLastReadMessage(
          chatId
        )
      );
    }
  }
}
