class Pager {
  constructor(pageNumber, pageSize) {
    this.pageNumber = Number(pageNumber) || 1;
    this.pageSize = Number(pageSize) || 10;
  }

  static check(obj) {
    if (!(obj instanceof Pager)) {
      throw new Error('object should extend Pager class');
    }
  }

  static hasPageSizeChanged(currentPageSize, nextPageSize) {
    return currentPageSize && currentPageSize !== nextPageSize;
  }
}

export default Pager;
