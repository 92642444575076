import {
  COLON_SYMBOL,
  SEMI_SYMBOL,
  SEMICOLON_SYMBOL,
} from '../utils/constants';
import { QUERY_KEYS } from '../routes/paths/query.constants';
import TypesHelper from '../utils/types/TypesHelper';
import AbstractSieveClientStore from './AbstractSieveClientStore';

class AbstractFilterClientStore extends AbstractSieveClientStore {
  static get queryKey() {
    return QUERY_KEYS.FILTER;
  }

  static get sieveType() {
    return 'filtering';
  }

  static getSieveQueryAsObj = (str, newQueryFilterFormat, nestedOptions) => {
    const withNestedOptions =
      nestedOptions && Object?.keys(nestedOptions).length;
    const filterQueryStr =
      new URLSearchParams(str).get(QUERY_KEYS.FILTER) || str || '';

    return filterQueryStr
      .split(newQueryFilterFormat ? SEMICOLON_SYMBOL : SEMI_SYMBOL)
      .reduce(
        (acc, subFilterString) => {
          const [key, value] = subFilterString.split(COLON_SYMBOL);
          if (value) {
            let prevValue = [];
            if (acc[key]) {
              prevValue = TypesHelper.isString(acc[key])
                ? [acc[key]]
                : [...acc[key]];
            }
            if (withNestedOptions) {
              return {
                ...acc,
                [key]: acc[key]
                  ? [...prevValue, value]
                      .join(SEMI_SYMBOL)
                      .split(SEMI_SYMBOL)
                      .map(Number)
                  : value.split(SEMI_SYMBOL).map(Number) || [],
              };
            }
            return {
              ...acc,
              [key]: acc[key] ? [...prevValue, value] : value,
            };
          }
          return acc;
        },
        withNestedOptions ? nestedOptions : {}
      );
  };

  static getSieveQueryAsStrFromObj = (obj, newQueryFilterFormat) => {
    let subQueryResult = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const [param, value] of Object.entries(obj)) {
      if (TypesHelper.isArray(value)) {
        // eslint-disable-next-line no-loop-func,no-return-assign
        value.forEach((item, index, array) => {
          if (newQueryFilterFormat) {
            if (array.length > 1) {
              if (index === 0) {
                subQueryResult += `${param}${COLON_SYMBOL}${item}`;
              } else {
                subQueryResult += `${SEMI_SYMBOL}${item}${
                  index === array.length - 1 ? SEMICOLON_SYMBOL : ''
                }`;
              }
            } else {
              subQueryResult += `${param}${COLON_SYMBOL}${item}${SEMICOLON_SYMBOL}`;
            }
          } else {
            subQueryResult += `${param}${COLON_SYMBOL}${item}${SEMI_SYMBOL}`;
          }
        });
      } else {
        subQueryResult += `${param}${COLON_SYMBOL}${value}${
          newQueryFilterFormat ? SEMICOLON_SYMBOL : SEMI_SYMBOL
        }`;
      }
    }
    subQueryResult = subQueryResult.slice(0, -1);
    return subQueryResult;
  };
}

export default AbstractFilterClientStore;
