import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function SettingsIcon({ size, color, ...rest }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3L10 3V3.5C10 5.8094 7.63883 7.17265 5.63883 6.01795L5.20581 5.76794L3.20581 9.23205L3.63883 9.48205C5.63883 10.6367 5.63882 13.3632 3.63882 14.5179L3.20581 14.7679L5.20581 18.232L5.63883 17.982C7.63883 16.8273 10 18.1906 10 20.5V21H14V20.5C14 18.1906 16.3613 16.8273 18.3613 17.982L18.7943 18.232L20.7943 14.7679L20.3613 14.5179C18.3613 13.3632 18.3613 10.6367 20.3613 9.48205L20.7943 9.23205L18.7943 5.76794L18.3613 6.01795C16.3613 7.17265 14 5.8094 14 3.5V3Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M15 12C15 13.6569 13.6569 15 12 15C10.3432 15 9.00005 13.6569 9.00005 12C9.00005 10.3431 10.3432 9 12 9C13.6569 9 15 10.3431 15 12Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(SettingsIcon);
