import AbstractDataAdapter from './AbstractDataAdapter';

class PMPEventDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return ['date', 'eventStatus'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return [];
  }

  static sendableAttributeRule(attribute) {
    if (attribute === 'eventStatus') {
      return 'status';
    }
    return super.sendableAttributeRule(attribute);
  }
}

export default PMPEventDataAdapter;
