import AbstractModel from 'api/models/AbstractModel';
import { STATUS } from '../../../utils/constants';
import DailyCheckProceduresModel from '../DailyCheck/DailyCheckProceduresModel';

class DailyProcedureGroupModel extends AbstractModel {
  constructor(...params) {
    super(...params);

    if (this.status !== STATUS.LOADING) {
      if (Array.isArray(this.dailycheckProcedures)) {
        this.dailycheckProcedures = this.dailycheckProcedures.map(
          (procedure) => new DailyCheckProceduresModel({ ...procedure })
        );
      }
    }
  }

  static FILTER = Object.freeze({
    BUILDING: 'buildingId',
    LEVEL: 'levelId',
    ROOM: 'roomId',
  });
}

export default DailyProcedureGroupModel;
