const TICKET_EVENTS = Object.freeze({
  NOTIFICATION: 'ticketNotification',
  CHAT: {
    INTERNAL: {
      GET_CHAT: 'getInternalTicketChat',
      GET_CHAT_HISTORY: 'getInternalTicketChatHistory',
      SEND_MESSAGE: 'sendInternalTicketChatMessage',
      REMOVE_MESSAGE: 'removeInternalTicketChatMessage',
      ADD_PARTICIPANT: 'addInternalTicketChatParticipant',
      REMOVE_PARTICIPANT: 'removeInternalTicketChatParticipant',
      UNREAD_MESSAGES_UPDATED: 'internalTicketChatUnreadMessagesIdsUpdated',
    },
    EXTERNAL: {
      GET_CHAT: 'getExternalTicketChat',
      GET_CHAT_HISTORY: 'getExternalTicketChatHistory',
      SEND_MESSAGE: 'sendExternalTicketChatMessage',
      REMOVE_MESSAGE: 'removeExternalTicketChatMessage',
      ADD_PARTICIPANT: 'addExternalTicketChatParticipant',
      REMOVE_PARTICIPANT: 'removeExternalTicketChatParticipant',
      UNREAD_MESSAGES_UPDATED: 'externalTicketChatUnreadMessagesIdsUpdated',
    },
  },
});

export default TICKET_EVENTS;
