import AbstractDataAdapter from '../AbstractDataAdapter';

class EquipmentInputDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return ['name', 'value', 'unitId'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return ['name', 'value', 'unitId'];
  }
}

export default EquipmentInputDataAdapter;
