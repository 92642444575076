import TicketModel from 'api/models/TicketModel';
import i18n from 'translations/config';
import TicketNotificationsEventCatcher from '../../../../realTimeApi/eventCatchers/ticket/TicketNotificationsEventCatcher';

class TicketNotificationDescriptionUtils {
  static TICKET_MESSAGE_GETTERS_BY_UPDATE_TYPE = Object.freeze({
    [TicketNotificationsEventCatcher.TICKET_UPDATE_TYPES.TICKET_CREATED]: (
      subject
    ) => this.getTicketCreatedMessage(subject),
    [TicketNotificationsEventCatcher.TICKET_UPDATE_TYPES.PRIORITY_UPDATED]: (
      subject,
      prevPriority,
      currentPriority
    ) =>
      this.getTicketPriorityChangedMessage(
        subject,
        prevPriority,
        currentPriority
      ),
    [TicketNotificationsEventCatcher.TICKET_UPDATE_TYPES.STATUS_UPDATED]: (
      subject,
      prevStatus,
      currentStatus
    ) => this.getTicketStatusChangedMessage(subject, prevStatus, currentStatus),
  });

  static getTicketCreatedMessage(subject) {
    return `Ticket ${subject} was created`;
  }

  static getUpdateMessage = (subject) => {
    return `Ticket ${subject} was updated`;
  };

  static getUpdateFromToMessage = (subject, from, to) => {
    return `${this.getUpdateMessage(subject)} from ${from} to ${to}`;
  };

  static getTicketPriorityChangedMessage(
    subject,
    prevPriority,
    currentPriority
  ) {
    return `${this.getUpdateFromToMessage(
      subject,
      prevPriority,
      currentPriority
    )} priority`;
  }

  static getTicketStatusChangedMessage(subject, prevStatus, currentStatus) {
    return `${this.getUpdateFromToMessage(
      subject,
      i18n.t(TicketModel.READABLE_I18N_STATUSES[prevStatus]),
      i18n.t(TicketModel.READABLE_I18N_STATUSES[currentStatus])
    )} status`;
  }
}

export default TicketNotificationDescriptionUtils;
