import AbstractEventCatcher from '../AbstractEventCatcher';
import { procurementChatActionCreators } from '../../../store/chat/resource/actions';

export default class ProcurementChatRemoveMessageEventCatcher extends AbstractEventCatcher {
  static process({ messageInfo, dispatch }) {
    dispatch(
      procurementChatActionCreators.removeMessage(
        messageInfo.procurementId,
        messageInfo.chat.id,
        messageInfo.id
      )
    );
  }
}
