import AbstractModel from './AbstractModel';
import { required } from '../../utils/helpers';

class RoleModel extends AbstractModel {
  static ROLES = Object.freeze({
    SUPER_ADMIN: 'superAdmin',
    ADMIN: 'admin',
    COORDINATOR: 'coordinator',
    PROJECT_MANAGER: 'projectManager',
    ASSISTANT_PROJECT_MANAGER: 'assistantProjectManager',
    TECHNICIAN: 'technician',
    PROCUREMENT: 'procurement',
    BUILDING_MANAGER: 'buildingManager',
    OFFICE_MANAGER: 'officeManager',
  });

  static READABLE_ROLES = Object.freeze({
    [this.ROLES.SUPER_ADMIN]: 'Super Admin',
    [this.ROLES.ADMIN]: 'Headquarter Team',
    [this.ROLES.COORDINATOR]: 'Senior Facility Manager',
    [this.ROLES.PROJECT_MANAGER]: 'Facility Manager',
    [this.ROLES.ASSISTANT_PROJECT_MANAGER]: 'Junior Facility Manager',
    [this.ROLES.TECHNICIAN]: 'Technician',
    [this.ROLES.PROCUREMENT]: 'Procurement Specialist',
    [this.ROLES.BUILDING_MANAGER]: 'Beneficiary',
    [this.ROLES.OFFICE_MANAGER]: 'Tenant',
  });

  static READABLE_I18N_ROLES = Object.freeze({
    [this.READABLE_ROLES[this.ROLES.SUPER_ADMIN]]: 'user.role.superAdmin',
    [this.READABLE_ROLES[this.ROLES.ADMIN]]: 'user.role.admin',
    [this.READABLE_ROLES[this.ROLES.COORDINATOR]]: 'user.role.coordinator',
    [this.READABLE_ROLES[this.ROLES.PROJECT_MANAGER]]:
      'user.role.projectManager',
    [this.READABLE_ROLES[this.ROLES.ASSISTANT_PROJECT_MANAGER]]:
      'user.role.assistantProjectManager',
    [this.READABLE_ROLES[this.ROLES.TECHNICIAN]]: 'user.role.technician',
    [this.READABLE_ROLES[this.ROLES.PROCUREMENT]]: 'user.role.procurement',
    [this.READABLE_ROLES[this.ROLES.BUILDING_MANAGER]]: 'user.role.beneficiary',
    [this.READABLE_ROLES[this.ROLES.OFFICE_MANAGER]]: 'user.role.tenant',
  });

  /**
   *
   * @type {[string, string]}
   */
  static ADMIN_ROLES = [this.ROLES.SUPER_ADMIN, this.ROLES.ADMIN];

  /**
   *
   * @type {[string, string, string]}
   */
  static COORDINATOR_AND_ABOVE_ROLES = [
    this.ROLES.SUPER_ADMIN,
    this.ROLES.ADMIN,
    this.ROLES.COORDINATOR,
  ];

  /**
   *
   * @type {[string, string, string, string, string]}
   */
  static ASSISTANT_PM_AND_ABOVE_ROLES = [
    this.ROLES.SUPER_ADMIN,
    this.ROLES.ADMIN,
    this.ROLES.COORDINATOR,
    this.ROLES.PROJECT_MANAGER,
    this.ROLES.ASSISTANT_PROJECT_MANAGER,
  ];

  /**
   *
   * @type {[string, string]}
   */
  static PM_AND_ASSISTANT = [
    this.ROLES.PROJECT_MANAGER,
    this.ROLES.ASSISTANT_PROJECT_MANAGER,
  ];

  /**
   *
   * @param authUser
   * @returns {*}
   */
  static getRoleOfAuthUser = (authUser) => {
    return authUser?.roles?.[0]?.name;
  };

  /**
   *
   * @param authUser
   * @param role
   * @returns {boolean}
   */
  static authUserIs = (authUser, role) => {
    required({ authUser });
    if (!Object.values(this.ROLES).includes(role)) {
      throw new Error(
        `role must be one of ${Object.values(this.ROLES).toString()}`
      );
    }

    return this.getRoleOfAuthUser(authUser) === role;
  };

  /**
   *
   * @param user
   * @param role
   * @returns {boolean}
   */
  static userIs = (user, role) => this.authUserIs(user, role);

  /**
   *
   * @param authUser
   * @returns {boolean}
   */
  static authUserIsBeneficiaryOrTenant = (authUser) => {
    return [this.ROLES.BUILDING_MANAGER, this.ROLES.OFFICE_MANAGER].includes(
      this.getRoleOfAuthUser(authUser)
    );
  };

  /**
   *
   * @param authUser
   * @returns {boolean}
   */
  static authUserIsPmOrApm = (authUser) => {
    return this.PM_AND_ASSISTANT.includes(this.getRoleOfAuthUser(authUser));
  };

  /**
   *
   * @param authUser
   * @returns {boolean}
   */
  static authUserIsBeneficiary = (authUser) => {
    return this.ROLES.BUILDING_MANAGER.includes(
      this.getRoleOfAuthUser(authUser)
    );
  };

  /**
   *
   * @param role
   * @returns {boolean}
   */
  static roleIsBeneficiaryOrTenant = (role) => {
    return [this.ROLES.BUILDING_MANAGER, this.ROLES.OFFICE_MANAGER].includes(
      role
    );
  };

  /**
   *
   * @param authUser
   * @returns {boolean}
   */
  static authUserIsAtLeastAssistantPM = (authUser) => {
    return this.ASSISTANT_PM_AND_ABOVE_ROLES.includes(
      this.getRoleOfAuthUser(authUser)
    );
  };

  /**
   *
   * @param role
   * @returns {boolean}
   */
  static roleIsAtLeastAssistantPM = (role) => {
    return this.ASSISTANT_PM_AND_ABOVE_ROLES.includes(role);
  };

  /**
   *
   * @param authUser
   * @returns {boolean}
   */
  static authUserIsAtLeastCoordinator = (authUser) => {
    return this.COORDINATOR_AND_ABOVE_ROLES.includes(
      this.getRoleOfAuthUser(authUser)
    );
  };

  /**
   *
   * @param role
   * @returns {boolean}
   */
  static roleIsAtLeastCoordinator = (role) => {
    return this.COORDINATOR_AND_ABOVE_ROLES.includes(role);
  };

  /**
   *
   * @param authUser
   * @returns {boolean}
   */
  static authUserCanAccessApp = (authUser) => {
    return this.getRoleOfAuthUser(authUser) !== this.ROLES.TECHNICIAN;
  };

  /**
   *
   * @param role
   * @returns {boolean}
   */
  static roleCanAccessApp = (role) => {
    return role !== this.ROLES.TECHNICIAN;
  };
}

export default RoleModel;
