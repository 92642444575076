import ApiResourceRequest, { API_METHODS } from './ApiResourceRequest';
import { required } from '../../utils/helpers';
import RealmModel from '../models/RealmModel';
import Pager from '../helpers/Pager';
import ListingResponse from './ListingResponse';

export class RealmRequest extends ApiResourceRequest {
  get apiResource() {
    return 'organizations';
  }

  get dynamicIds() {
    return {
      ORGANIZATION_ID: '{organizationId}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_TYPE: `${this.apiRoute}/by-type/{type}`,
      GET_ASSOCIATED_ORGANIZATIONS: `${this.apiUrl}/projects/{projectId}/associated-organizations`,
      DEACTIVATE_ORGANIZATION: `${this.apiRoute}/suspend/${this.dynamicIds.ORGANIZATION_ID}`,
      ACTIVATE_ORGANIZATION: `${this.apiRoute}/activate/${this.dynamicIds.ORGANIZATION_ID}`,
    };
  }

  async getByType(type, pager = new Pager(), query = {}) {
    Pager.check(pager);
    if (!Object.values(RealmModel.TYPES).includes(type)) {
      throw new Error(`type must be one of ${Object.values(RealmModel.TYPES)}`);
    }
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_TYPE.replace('{type}', type) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async getAssociatedOrganizations(projectId, pager = new Pager(), query = {}) {
    required({ projectId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_ASSOCIATED_ORGANIZATIONS.replace(
        '{projectId}',
        projectId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async toggleActivation(isActivated, organizationId) {
    required({ organizationId });

    const response = await this.request(
      API_METHODS.POST,
      (isActivated
        ? this.routes.ACTIVATE_ORGANIZATION
        : this.routes.DEACTIVATE_ORGANIZATION
      ).replace(`${this.dynamicIds.ORGANIZATION_ID}`, organizationId)
    );

    return response?.data;
  }

  async activateOrganization(organizationId) {
    return this.toggleActivation(true, organizationId);
  }

  async deactivateOrganization(organizationId) {
    return this.toggleActivation(false, organizationId);
  }
}

export const realmRequest = new RealmRequest();
