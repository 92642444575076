import React from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function AddIcon({ size, color, ...rest }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6 12H12M12 12H18M12 12V6M12 12V18"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(AddIcon);
