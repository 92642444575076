import AbstractDataAdapter from '../AbstractDataAdapter';

class StandardEquipmentCategoryDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return ['name', 'categoryId'];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return ['name', 'categoryId'];
  }
}

export default StandardEquipmentCategoryDataAdapter;
