import { useEffect, useMemo, useState } from 'react';
import createRealTimeProvider from '../../realTimeApi/provider';
import AuthClientStore from '../../clientStore/AuthClientStore';
import RealTimeMessageModel from '../../realTimeApi/models/RealTimeMessageModel';

/**
 * @param {Object} actions
 * @param {Function} actions.onMessage
 * @param {Function} actions.onError
 * @param {Function} actions.onClose
 * @param {Function} actions.onOpen
 * @param {UserModel} loggedInUser
 */
export default (actions, loggedInUser) => {
  const [realTimeProvider, setRealTimeProvider] = useState(null);
  const [isOpenedStatus, setIsOpenedStatus] = useState(false);
  const isUserLoggedIn = !!loggedInUser.id;

  useEffect(() => {
    isUserLoggedIn &&
      setRealTimeProvider(createRealTimeProvider({ ...actions }));
    // eslint-disable-next-line
  }, [isUserLoggedIn]);

  useEffect(() => {
    isUserLoggedIn && realTimeProvider?.connect();

    return () => {
      isUserLoggedIn && realTimeProvider?.close();
    };
  }, [realTimeProvider, isUserLoggedIn]);

  useEffect(() => {
    !isUserLoggedIn && realTimeProvider?.close();
  }, [isUserLoggedIn, realTimeProvider]);

  useEffect(() => {
    if (isUserLoggedIn && realTimeProvider) {
      realTimeProvider
        .waitForOpenedStatus()
        .then(() => {
          setIsOpenedStatus(true);
        })
        .catch(() => {
          setIsOpenedStatus(false);
        });
    }
  }, [isUserLoggedIn, realTimeProvider]);

  return useMemo(() => {
    return {
      sendMessage: (messageObj) => {
        const token = isUserLoggedIn ? AuthClientStore.getToken() : undefined;

        if (!token) {
          // eslint-disable-next-line no-console
          console.warn('Provide token first');

          return;
        }

        if (!isOpenedStatus) {
          return;
        }

        RealTimeMessageModel.checkInstance(messageObj);

        realTimeProvider?.send(messageObj);
      },
    };
  }, [realTimeProvider, isUserLoggedIn, isOpenedStatus]);
};
