export default (state, action, config) => {
  const currentResource =
    state[config.propName][action.payload.resourceId] || {};
  const currentResourceInfo = currentResource.resourceInfo || {};

  return {
    ...state,
    [config.propName]: {
      ...state[config.propName],
      [action.payload.resourceId]: {
        ...currentResource,
        resourceInfo: {
          ...currentResourceInfo,
          ...action.payload.resourceInfo,
        },
      },
    },
  };
};
