const compareEquipmentPmpEventsQueryObject = (
  prevQueryObject,
  currentQueryObject
) => {
  const isTypeEqual = prevQueryObject?.type === currentQueryObject?.type;
  const isFrequencyEqual =
    prevQueryObject?.frequency === currentQueryObject?.frequency;

  return isTypeEqual && isFrequencyEqual;
};

export default compareEquipmentPmpEventsQueryObject;
