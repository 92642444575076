import LocalClientStore from './LocalClientStore';
import { required } from '../utils/helpers';

class AbstractSieveClientStore extends LocalClientStore {
  static get resourceName() {
    return '';
  }

  static get sieveType() {
    return '';
  }

  static getIdStr = (idKey, idValue) => {
    return idValue ? `${idKey}:${idValue};` : '';
  };

  /**
   * @param {number} userId
   * @param {string} name
   * @param {string} sieveType
   * @param {Array.<{idValue: number, idKey: string}> | undefined} extraIds
   */
  static convertResourceName = (userId, name, sieveType, extraIds) => {
    required({ userId, name, sieveType });

    const userIdStr = this.getIdStr('userId', userId);
    const extraIdsStr = (extraIds || [])
      .map(({ idKey, idValue }) => this.getIdStr(idKey, idValue))
      .join('');

    return `${name}-${sieveType};${userIdStr}${extraIdsStr}`;
  };

  /**
   * @param {number} userId
   * @param {Object} config
   * @param {Array.<{idValue: number, idKey: string}> | undefined} extraIds
   */
  static setSieveConfig(userId, config, extraIds) {
    this.storage.setItem(
      this.convertResourceName(
        userId,
        this.resourceName,
        this.sieveType,
        extraIds
      ),
      JSON.stringify(config)
    );
  }

  /**
   * @param {number} userId
   * @param {Array.<{idValue: number, idKey: string}> | undefined} extraIds
   */
  static removeSieveConfig(userId, extraIds) {
    this.storage.removeItem(
      this.convertResourceName(
        userId,
        this.resourceName,
        this.sieveType,
        extraIds
      )
    );
  }

  /**
   * @param {number} userId
   * @param {Array.<{idValue: number, idKey: string}> | undefined} extraIds
   */
  static getSieveConfig(userId, extraIds) {
    const config = this.storage.getItem(
      this.convertResourceName(
        userId,
        this.resourceName,
        this.sieveType,
        extraIds
      )
    );

    return config ? JSON.parse(config) : undefined;
  }

  static replaceWithNewSieveQuery = (history, queryStr, historyState) => {
    history.replace({
      search: queryStr,
      ...(historyState ? { state: historyState } : {}),
    });
  };

  /* Dummy methods. Need to override */
  static getSieveQueryAsObj = (str) => ({ str });

  static getSieveQueryAsStrFromObj = (obj) => JSON.stringify(obj);
}

export default AbstractSieveClientStore;
