module.exports = {
  CAFM_API_PROTOCOL: process.env.REACT_APP_CAFM_API_PROTOCOL || 'http',
  CAFM_API_HOST: process.env.REACT_APP_CAFM_API_HOST,
  CAFM_API_PORT: process.env.REACT_APP_CAFM_API_PORT || '',
  CAFM_API_ENTRY_ROUTE: process.env.REACT_APP_CAFM_API_ENTRY_ROUTE || '/api',
  CAFM_WS_HOST: process.env.REACT_APP_CAFM_WS_HOST,
  CAFM_WS_PORT: process.env.REACT_APP_CAFM_WS_PORT || '',
  CAFM_WS_PROTOCOL: process.env.REACT_APP_CAFM_WS_PROTOCOL || 'wss',
  CAFM_WS_ENTRY_ROUTE: process.env.REACT_APP_CAFM_WS_ENTRY_ROUTE || '/ws',
  APP_DEFAULT_TITLE: process.env.REACT_APP_APP_DEFAULT_TITLE || 'CAFM',
  I18N_DEBUG_MODE: process.env.REACT_APP_I18N_DEBUG_MODE === 'true',
};
