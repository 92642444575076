import UserModel from './models/UserModel';
import RoleModel from './models/RoleModel';
import ProjectModel from './models/Project/ProjectModel';
import RealmModel from './models/RealmModel';
import CountryModel from './models/CountryModel';
import CityModel from './models/CityModel';
import { userRequest } from './requestWrappers/UserRequest';
import { roleRequest } from './requestWrappers/RoleRequest';
import { projectRequest } from './requestWrappers/ProjectRequest';
import { realmRequest } from './requestWrappers/RealmRequest';
import { countryRequest } from './requestWrappers/CountryRequest';
import { cityRequest } from './requestWrappers/CityRequest';
import UserDataAdapter from './dataAdapters/UserDataAdapter';
import RealmDataAdapter from './dataAdapters/RealmDataAdapter';
import { manufacturerRequest } from './requestWrappers/ManufacturerRequest';
import ManufacturerModel from './models/ManufacturerModel';
import ManufacturerDataAdapter from './dataAdapters/ManufacturerDataAdapter';
import { standardEquipmentCategoryRequest } from './requestWrappers/Equipment/StandardEquipmentCategoryRequest';
import StandardEquipmentCategoryModel from './models/Equipment/StandardEquipmentCategoryModel';
import StandardEquipmentCategoryDataAdapter from './dataAdapters/Equipment/StandardEquipmentCategoryDataAdapter';
import { equipmentGroupRequest } from './requestWrappers/Equipment/EquipmentGroupRequest';
import EquipmentGroupModel from './models/Equipment/EquipmentGroupModel';
import EquipmentGroupDataAdapter from './dataAdapters/Equipment/EquipmentGroupDataAdapter';
import { equipmentProjectCategoryRequest } from './requestWrappers/Equipment/EquipmentProjectCategoryRequest';
import EquipmentProjectCategoryModel from './models/Equipment/EquipmentProjectCategoryModel';
import EquipmentProjectCategoryDataAdapter from './dataAdapters/Equipment/EquipmentProjectCategoryDataAdapter';
import { equipmentModelRequest } from './requestWrappers/Equipment/EquipmentModelRequest';
import EquipmentModelModel from './models/Equipment/EquipmentModelModel';
import EquipmentModelDataAdapter from './dataAdapters/Equipment/EquipmentModelDataAdapter';
import { equipmentRequest } from './requestWrappers/Equipment/EquipmentRequest';
import EquipmentModel from './models/Equipment/EquipmentModel';
import EquipmentDataAdapter from './dataAdapters/Equipment/EquipmentDataAdapter';
import { unitRequest } from './requestWrappers/UnitRequest';
import UnitModel from './models/UnitModel';
import UnitDataAdapter from './dataAdapters/UnitDataAdapter';
import { equipmentInputRequest } from './requestWrappers/Equipment/EquipmentInputRequest';
import EquipmentInputModel from './models/Equipment/EquipmentInputModel';
import EquipmentInputDataAdapter from './dataAdapters/Equipment/EquipmentInputDataAdapter';
import CountryDataAdapter from './dataAdapters/CountryDataAdapter';
import CityDataAdapter from './dataAdapters/CityDataAdapter';
import { maintenanceProcedureRequest } from './requestWrappers/MaintenanceProcedure/MaintenanceProcedureRequest';
import MaintenanceProcedureModel from './models/MaintenanceProcedure/MaintenanceProcedureModel';
import MaintenanceProcedureDataAdapter from './dataAdapters/MaintenanceProcedure/MaintenanceProcedureDataAdapter';
import { maintenanceOperationRequest } from './requestWrappers/MaintenanceProcedure/MaintenanceOperationRequest';
import MaintenanceOperationModel from './models/MaintenanceProcedure/MaintenanceOperationModel';
import MaintenanceOperationDataAdapter from './dataAdapters/MaintenanceProcedure/MaintenanceOperationDataAdapter';
import { maintenanceParameterRequest } from './requestWrappers/MaintenanceProcedure/MaintenanceParameterRequest';
import MaintenanceParameterModel from './models/MaintenanceProcedure/MaintenanceParameterModel';
import MaintenanceParameterDataAdapter from './dataAdapters/MaintenanceProcedure/MaintenanceParameterDataAdapter';
import { buildingRequest } from './requestWrappers/Building/BuildingRequest';
import BuildingModel from './models/Building/BuildingModel';
import BuildingDataAdapter from './dataAdapters/Building/BuildingDataAdapter';
import { levelRequest } from './requestWrappers/Building/LevelRequest';
import LevelModel from './models/Building/LevelModel';
import LevelDataAdapter from './dataAdapters/Building/LevelDataAdapter';
import { roomRequest } from './requestWrappers/Building/RoomRequest';
import RoomModel from './models/Building/RoomModel';
import RoomDataAdapter from './dataAdapters/Building/RoomDataAdapter';
import { subcontractorRequest } from './requestWrappers/SubcontractorRequest';
import SubcontractorModel from './models/SubcontractorModel';
import SubcontractorDataAdapter from './dataAdapters/SubcontractorDataAdapter';
import { subcontractorCategoryRequest } from './requestWrappers/SubcontractorCategoryRequest';
import SubcontractorCategoryModel from './models/SubcontractorCategoryModel';
import SubcontractorCategoryDataAdapter from './dataAdapters/SubcontractorCategoryDataAdapter';
import { standardEquipmentGroupRequest } from './requestWrappers/Equipment/StandardEquipmentGroupRequest';
import StandardEquipmentGroupModel from './models/Equipment/StandardEquipmentGroupModel';
import StandardEquipmentGroupDataAdapter from './dataAdapters/Equipment/StandardEquipmentGroupDataAdapter';
import { pmpEventRequest } from './requestWrappers/PMPEventRequest';
import PMPEventModel from './models/PMPEventModel';
import PMPEventDataAdapter from './dataAdapters/PMPEventDataAdapter';
import { ticketRequest } from './requestWrappers/TicketRequest';
import TicketModel from './models/TicketModel';
import TicketDataAdapter from './dataAdapters/TicketDataAdapter';
import { procurementRequest } from './requestWrappers/ProcurementRequest';
import ProcurementModel from './models/ProcurementModel';
import ProcurementDataAdapter from './dataAdapters/ProcurementDataAdapter';
import { dailyProcedureGroupRequest } from './requestWrappers/DailyProcedure/DailyProcedureGroupRequest';
import DailyProcedureGroupModel from './models/DailyProcedure/DailyProcedureGroupModel';
import DailyProcedureGroupDataAdapter from './dataAdapters/DailyProcedure/DailyProcedureGroupDataAdapter';

export const TICKET_TYPE = 'ticket';
export const PROCUREMENT_TYPE = 'procurement';
export const REALM_TYPE = 'realm';
export const USER_TYPE = 'user';
export const EQUIPMENT_TYPE = 'equipment';
export const COUNTRY_TYPE = 'country';
export const CITY_TYPE = 'city';
export const ROLE_TYPE = 'role';
export const PROJECT_TYPE = 'project';
export const MANUFACTURER_TYPE = 'manufacturer';
export const UNIT_TYPE = 'unit';
export const STANDARD_EQUIPMENT_CATEGORY_TYPE = 'standardEquipmentCategory';
export const STANDARD_EQUIPMENT_GROUP_TYPE = 'standardEquipmentGroup';
export const EQUIPMENT_PROJECT_CATEGORY_TYPE = 'equipmentProjectCategory';
export const EQUIPMENT_GROUP_TYPE = 'equipmentGroup';
export const EQUIPMENT_MODEL_TYPE = 'equipmentModel';
export const EQUIPMENT_INPUT_TYPE = 'equipmentInput';
export const MAINTENANCE_PROCEDURE_TYPE = 'maintenanceProcedure';
export const MAINTENANCE_OPERATION_TYPE = 'maintenanceOperation';
export const MAINTENANCE_PARAMETER_TYPE = 'maintenanceParameter';
export const BUILDING_TYPE = 'building';
export const LEVEL_TYPE = 'level';
export const ROOM_TYPE = 'room';
export const SUBCONTRACTOR_TYPE = 'subcontractor';
export const SUBCONTRACTOR_CATEGORY_TYPE = 'subcontractorCategory';
export const PMP_EVENT_TYPE = 'pmpEvent';
export const DAILY_PROCEDURE_GROUPS_TYPE = 'dailycheck-procedure-groups';

// eslint-disable-next-line import/prefer-default-export
export const RESOURCE_TYPE_MAPPING = Object.freeze({
  [USER_TYPE]: {
    request: userRequest,
    model: UserModel,
    adapter: UserDataAdapter,
  },
  [COUNTRY_TYPE]: {
    request: countryRequest,
    model: CountryModel,
    adapter: CountryDataAdapter,
  },
  [CITY_TYPE]: {
    request: cityRequest,
    model: CityModel,
    adapter: CityDataAdapter,
  },
  [ROLE_TYPE]: {
    request: roleRequest,
    model: RoleModel,
  },
  [REALM_TYPE]: {
    request: realmRequest,
    model: RealmModel,
    adapter: RealmDataAdapter,
  },
  [PROJECT_TYPE]: {
    request: projectRequest,
    model: ProjectModel,
  },
  [MANUFACTURER_TYPE]: {
    request: manufacturerRequest,
    model: ManufacturerModel,
    adapter: ManufacturerDataAdapter,
  },
  [UNIT_TYPE]: {
    request: unitRequest,
    model: UnitModel,
    adapter: UnitDataAdapter,
  },
  [EQUIPMENT_TYPE]: {
    request: equipmentRequest,
    model: EquipmentModel,
    adapter: EquipmentDataAdapter,
  },
  [STANDARD_EQUIPMENT_CATEGORY_TYPE]: {
    request: standardEquipmentCategoryRequest,
    model: StandardEquipmentCategoryModel,
    adapter: StandardEquipmentCategoryDataAdapter,
  },
  [STANDARD_EQUIPMENT_GROUP_TYPE]: {
    request: standardEquipmentGroupRequest,
    model: StandardEquipmentGroupModel,
    adapter: StandardEquipmentGroupDataAdapter,
  },
  [EQUIPMENT_PROJECT_CATEGORY_TYPE]: {
    request: equipmentProjectCategoryRequest,
    model: EquipmentProjectCategoryModel,
    adapter: EquipmentProjectCategoryDataAdapter,
  },
  [EQUIPMENT_GROUP_TYPE]: {
    request: equipmentGroupRequest,
    model: EquipmentGroupModel,
    adapter: EquipmentGroupDataAdapter,
  },
  [EQUIPMENT_MODEL_TYPE]: {
    request: equipmentModelRequest,
    model: EquipmentModelModel,
    adapter: EquipmentModelDataAdapter,
  },
  [EQUIPMENT_INPUT_TYPE]: {
    request: equipmentInputRequest,
    model: EquipmentInputModel,
    adapter: EquipmentInputDataAdapter,
  },
  [MAINTENANCE_PROCEDURE_TYPE]: {
    request: maintenanceProcedureRequest,
    model: MaintenanceProcedureModel,
    adapter: MaintenanceProcedureDataAdapter,
  },
  [MAINTENANCE_OPERATION_TYPE]: {
    request: maintenanceOperationRequest,
    model: MaintenanceOperationModel,
    adapter: MaintenanceOperationDataAdapter,
  },
  [MAINTENANCE_PARAMETER_TYPE]: {
    request: maintenanceParameterRequest,
    model: MaintenanceParameterModel,
    adapter: MaintenanceParameterDataAdapter,
  },
  [BUILDING_TYPE]: {
    request: buildingRequest,
    model: BuildingModel,
    adapter: BuildingDataAdapter,
  },
  [LEVEL_TYPE]: {
    request: levelRequest,
    model: LevelModel,
    adapter: LevelDataAdapter,
  },
  [ROOM_TYPE]: {
    request: roomRequest,
    model: RoomModel,
    adapter: RoomDataAdapter,
  },
  [SUBCONTRACTOR_TYPE]: {
    request: subcontractorRequest,
    model: SubcontractorModel,
    adapter: SubcontractorDataAdapter,
  },
  [SUBCONTRACTOR_CATEGORY_TYPE]: {
    request: subcontractorCategoryRequest,
    model: SubcontractorCategoryModel,
    adapter: SubcontractorCategoryDataAdapter,
  },
  [PMP_EVENT_TYPE]: {
    request: pmpEventRequest,
    model: PMPEventModel,
    adapter: PMPEventDataAdapter,
  },
  [TICKET_TYPE]: {
    request: ticketRequest,
    model: TicketModel,
    adapter: TicketDataAdapter,
  },
  [PROCUREMENT_TYPE]: {
    request: procurementRequest,
    model: ProcurementModel,
    adapter: ProcurementDataAdapter,
  },
  [DAILY_PROCEDURE_GROUPS_TYPE]: {
    request: dailyProcedureGroupRequest,
    model: DailyProcedureGroupModel,
    adapter: DailyProcedureGroupDataAdapter,
  },
});
