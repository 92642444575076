import React from 'react';
import { Helmet } from 'react-helmet';
import { APP_DEFAULT_TITLE } from '../config';

/**
 *
 * @param rawTitle
 * @param excludePrefix
 * @returns {string}
 */
const getTitle = (rawTitle, excludePrefix) => {
  if (!rawTitle || !(typeof rawTitle === 'string')) {
    console.error('rawTitle must be a non-empty string!');
    return APP_DEFAULT_TITLE;
  }

  return excludePrefix ? rawTitle : `${APP_DEFAULT_TITLE} - ${rawTitle}`;
};
/**
 *
 * @param rawTitle
 * @param excludePrefix
 * @returns {function(*): function(*)}
 */
export default function withHelmet(rawTitle, excludePrefix = false) {
  return (WrappedComponent) => {
    function withHelmetWrapper(wrappedComponentProps) {
      return (
        <>
          <Helmet title={getTitle(rawTitle, excludePrefix)} />
          <WrappedComponent {...wrappedComponentProps} />
        </>
      );
    }

    return withHelmetWrapper;
  };
}
