import { createSelector } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/constants';
import RealmModel from '../../api/models/RealmModel';

export const dictionarySelector = (state) => state.realm.dictionary;
const listDataSelector = (state) => state.realm.list;
const currentKeySelector = (state) => state.realm.list.meta.currentKey;
const pageNumberSelector = (state) => state.realm.list.pagination.pageNumber;
const pageSizeSelector = (state) => state.realm.list.pagination.pageSize;

const listSelector = createSelector(
  [listDataSelector, currentKeySelector],
  (listData, currentKey) => {
    return listData[currentKey];
  }
);

export const realmsSelector = createSelector(
  [listSelector, pageNumberSelector, dictionarySelector],
  (list, pageNumber, dictionary) => {
    if (!list?.[pageNumber]?.items) {
      return [];
    }

    const realms = list[pageNumber].items.map((realmId) => dictionary[realmId]);

    return realms;
  }
);

export const totalRealmsSelector = createSelector(
  [listDataSelector],
  (listData) => listData.pagination.total
);

export const lastPageSelector = createSelector(
  [totalRealmsSelector, pageSizeSelector],
  (total, pageSize) => Math.ceil(total / pageSize)
);

export const realmsLoadingSelector = createSelector(
  [listSelector, pageNumberSelector],
  (list, pageNumber) => list?.[pageNumber]?.status === STATUS.LOADING
);

export const findRealmSelector = (state, { match }) => {
  const realmId = match.params.id || match.params.organizationId;
  if (!realmId) {
    return null;
  }
  const { dictionary } = state.realm;

  if (!dictionary[realmId]) {
    return null;
  }

  return dictionary[realmId];
};

export const beneficiaryOrganizationsSelector = createSelector(
  [dictionarySelector],
  (dictionary) => {
    return Object.values(dictionary).filter(
      (org) => org.organizationType === RealmModel.TYPES.BENEFICIARY
    );
  }
);

export const tenantOrganizationsSelector = createSelector(
  [dictionarySelector],
  (dictionary) => {
    return Object.values(dictionary).filter(
      (org) => org.organizationType === RealmModel.TYPES.TENANT
    );
  }
);
