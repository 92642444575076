import { createActions, createActionTypesConfig } from '../helpers';

export const WARRANTY_TRACKER_LABEL = 'warranty-tracker';

/** Action Types */

export const CREATE_WARRANTY_TRACKER_TYPES = createActionTypesConfig(
  WARRANTY_TRACKER_LABEL,
  'CREATE_WARRANTY_TRACKER'
);
export const UPDATE_WARRANTY_TRACKER_TYPES = createActionTypesConfig(
  WARRANTY_TRACKER_LABEL,
  'UPDATE_WARRANTY_TRACKER'
);

export const GET_PROJECT_WARRANTY_TRACKERS_TYPES = createActionTypesConfig(
  WARRANTY_TRACKER_LABEL,
  'GET_PROJECT_WARRANTY_TRACKERS'
);

export const FIND_WARRANTY_TRACKER_TYPES = createActionTypesConfig(
  WARRANTY_TRACKER_LABEL,
  'FIND_WARRANTY_TRACKER'
);

export const DELETE_WARRANTY_TRACKER_TYPES = createActionTypesConfig(
  WARRANTY_TRACKER_LABEL,
  'DELETE_WARRANTY_TRACKER'
);
export const GET_WARRANTY_STATISTICS_TYPES = createActionTypesConfig(
  WARRANTY_TRACKER_LABEL,
  'GET_WARRANTY_STATISTICS_TRACKER'
);

/** Actions */

export const CREATE_WARRANTY_TRACKER_ACTION = createActions(
  CREATE_WARRANTY_TRACKER_TYPES
);
export const UPDATE_WARRANTY_TRACKER_ACTION = createActions(
  UPDATE_WARRANTY_TRACKER_TYPES
);
export const GET_PROJECT_WARRANTY_TRACKERS_ACTION = createActions(
  GET_PROJECT_WARRANTY_TRACKERS_TYPES
);
export const FIND_WARRANTY_TRACKER_ACTION = createActions(
  FIND_WARRANTY_TRACKER_TYPES
);
export const GET_WARRANTY_STATISTICS_ACTION = createActions(
  GET_WARRANTY_STATISTICS_TYPES
);
export const DELETE_WARRANTY_TRACKER_ACTION = createActions(
  DELETE_WARRANTY_TRACKER_TYPES
);
