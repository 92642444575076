export default {
  warrantyTracker: {
    itself: 'Tracker garanție',
    plural: {
      itself: 'Gestiune garantii',
    },
    statuses: {
      inProgress: 'În curs',
      onHold: 'În așteptare',
      underObservation: 'Sub observare',
      closed: 'Închis',
    },
    createNewWarrantyTrackerItem: 'Create a new warranty tracker item',
    editWarrantyTrackerItem: 'Edit warranty tracker item',
    createNewWarrantyTrackerNote: 'Creați o nouă notă de garanție',
    editWarrantyNote: 'Editați nota de garanție',
    createItem: 'Create item',
    editItem: 'Edit item',
    actions: {
      addAdditionalNote: 'Adăugați notă suplimentară',
      updateDueDate: 'Actualizați data scadentă',
    },
    lastNote: 'Ultima notă',
    attributes: {
      subject: {
        itself: 'Subiect',
        '*': 'Subiect *',
        required: 'Subiectul este obligatoriu',
      },
      description: {
        itself: 'Descriere',
        '*': 'Descriere *',
        required: 'Este necesară descrierea',
        placeholder: 'Descrieți articolul',
      },
      location: {
        itself: 'Locație',
        '*': 'Locație *',
        required: 'Este necesară locația',
      },
      building: {
        itself: 'Clădire',
        '*': 'Clădire *',
        required: 'Este necesară construcția',
        select: 'Selectați clădirea',
      },
      level: {
        itself: 'Nivel',
        '*': 'Nivel *',
        required: 'Nivelul este necesar',
        select: 'Selectează nivelul',
      },
      room: {
        itself: 'Cameră',
        '*': 'Cameră *',
        required: 'Este necesară cameră',
        select: 'Selectați camera',
      },
      category: {
        itself: 'Categorie',
        '*': 'Categorie *',
        required: 'Categoria este obligatorie',
        options: {
          mep: 'MEP',
          civil: 'Civil',
        },
        types: {
          consumables: 'Consumables',
          tools: 'Tools',
        },
      },
      equipment: {
        itself: 'Echipamente',
        '*': 'Echipamente *',
        required: 'Este necesar un echipament',
        placeholder: 'Selectați echipamentul',
      },
      subcontractor: {
        itself: 'Subcontractant',
        '*': 'Subcontractant *',
        required: 'Este necesar un subcontractant',
      },
      assignedTo: {
        itself: 'Responsabil',
        '*': 'Responsabil*',
        required: 'Este necesar să responsabil',
      },
    },
    status: {
      opened: 'Deschis',
      submitted: 'Trimis',
      inProgress: 'În curs',
      underObservation: 'Sub observare',
      onHold: 'In asteptare',
      closed: 'Închis',
    },
    note: {
      status: {
        created: 'Creată',
        updated: 'La curent',
        subject: 'Subiect',
        closed: 'Închis',
      },
    },
    exportWarrantyTracker: 'Exportați datele de urmărire a garanției',
    showOverdue: 'Afișează întârziat',

    conclusion: {
      itself: 'Concluzie',
      '*': 'Concluzie *',
      required: 'Concluzia e obligatorie',
    },
    addConclusion: 'Adaugă concluzie',
  },
};
