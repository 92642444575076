import { LOGIN, LOGOUT, ME, SET_PASSWORD } from './constants';
import { STATUS } from '../../utils/constants';
import RealmModel from '../../api/models/RealmModel';
import UserModel from '../../api/models/UserModel';
import { authRequest } from '../../api/requestWrappers/AuthRequest';

/**
 *
 * @type {{error: null, status: null, object: {}}}
 */
const initialState = {
  status: null,
  error: null,
  object: {
    user: {},
    meta: {
      isAuthenticated: false,
      hasOtp: false,
      otpPassword: '',
      parentOrg: null,
    },
  },
  lastAction: null,
};

export default function authReducer(state = initialState, action) {
  const actionType = action.type;

  switch (true) {
    case [LOGIN.REQUEST, ME.REQUEST, LOGOUT.REQUEST].includes(actionType):
      return {
        ...state,
        status: STATUS.LOADING,
        error: null,
        lastAction: actionType,
      };
    case LOGIN.SUCCESS === actionType:
      return {
        ...state,
        status: STATUS.SUCCESS,
        error: null,
        object: {
          ...state.object,
          meta: {
            ...state.object.meta,
            otpPassword: action.payload.userPassword,
          },
        },
        lastAction: actionType,
      };
    case ME.SUCCESS === actionType:
      const hasOtp = Boolean(action.payload.isOTP);
      if (hasOtp) {
        authRequest.removeTokenFromLocalStorage();
      }

      return {
        ...state,
        status: STATUS.SUCCESS,
        error: null,
        object: {
          ...state.object,
          user: new UserModel(action.payload),
          meta: {
            ...state.object.meta,
            isAuthenticated: true,
            hasOtp,
            otpPassword: hasOtp ? state.object.meta.otpPassword : null,
            parentOrg: new RealmModel({ ...action.payload?.organization }),
            projectCount: action.payload.projectCount,
          },
        },
        lastAction: actionType,
      };

    case [LOGIN.ERROR, ME.ERROR, LOGOUT.ERROR].includes(actionType):
      return {
        ...state,
        status: STATUS.ERROR,
        error: action.payload,
        lastAction: actionType,
      };
    case LOGOUT.SUCCESS === actionType:
      return {
        ...state,
        status: STATUS.SUCCESS,
        error: null,
        object: {
          ...state.object,
          user: {},
          meta: {
            ...state.object.meta,
            isAuthenticated: false,
          },
        },
        lastAction: actionType,
      };

    case SET_PASSWORD.REQUEST === actionType:
      return state;
    case SET_PASSWORD.SUCCESS === actionType:
      authRequest.refreshToken();
      return {
        ...state,
        object: {
          ...state.object,
          meta: {
            ...state.object.meta,
            otpPassword: '',
            hasOtp: false,
          },
        },
      };
    case SET_PASSWORD.ERROR === actionType:
      return state;

    default:
      return state;
  }
}
