export default {
  dailyCheckOperation: {
    itself: 'Operation',
    description: 'Description',
    label: 'Enter new option label',
    plural: {
      itself: 'Operations',
    },
    selectEquipment: 'Select equipment',
    selectParameter: 'Select parameter',
    selectType: 'Select type',
    visualCheck: 'Visual check',
    parameterCheck: 'Parameter check',
    pressure: {
      itself: 'Pressure',
      withUnit: 'Pressure (bar)',
    },
    temperature: {
      itself: 'Temperature',
      withUnit: 'Temperature (Degree Celsius)',
    },
    parameter: {
      min: 'min',
      max: 'max',
    },
  },
};
