import { createSelector } from '@reduxjs/toolkit';
import { STATUS } from 'utils/constants';
import ProjectPublicDataModel from 'api/models/Project/ProjectPublicDataModel';
import RealmModel from 'api/models/RealmModel';
import { dictionarySelector as orgDictionarySelector } from '../realm/selectors';
import { dictionarySelector as userDictionarySelector } from '../user/selectors';
import { handleSortLocations } from '../helpers';

const dictionarySelector = (state) => state.project.dictionary;
const listDataSelector = (state) => state.project.list;
const currentKeySelector = (state) => state.project.list.meta.currentKey;
const pageNumberSelector = (state) => state.project.list.pagination.pageNumber;
const pageSizeSelector = (state) => state.project.list.pagination.pageSize;
const publicDataDictionarySelector = (state) =>
  state.project.publicDataDictionary;

const teamMemberUsersSelector = (state) => state.project.teamMemberUsers;
const tenantUsersSelector = (state) => state.project.tenantUsers;
const beneficiaryUsersSelector = (state) => state.project.beneficiaryUsers;
const layoutFilesSelector = (state) => state.project.layoutFiles;

const listSelector = createSelector(
  [listDataSelector, currentKeySelector],
  (listData, currentKey) => {
    return listData[currentKey];
  }
);

export const projectsPublicDataDictionaryItemSelectorCreator = (projectId) => {
  return createSelector(
    [publicDataDictionarySelector],
    (projectsPublicDataDictionary) =>
      projectsPublicDataDictionary[projectId] || null
  );
};

export const projectsSelector = createSelector(
  [listSelector, pageNumberSelector, dictionarySelector],
  (list, pageNumber, dictionary) => {
    if (!list?.[pageNumber]?.items) {
      return [];
    }

    const projects = list[pageNumber].items.map(
      (projectId) => dictionary[projectId]
    );

    return projects;
  }
);

export const totalProjectsSelector = createSelector(
  [listDataSelector],
  (listData) => listData.pagination.total
);

export const lastPageSelector = createSelector(
  [totalProjectsSelector, pageSizeSelector],
  (total, pageSize) => Math.ceil(total / pageSize)
);

export const projectsLoadingSelector = createSelector(
  [listSelector, pageNumberSelector],
  (list, pageNumber) => list?.[pageNumber]?.status === STATUS.LOADING
);

export const projectsPublicDataDictionaryItemStatusSelectorCreator = (
  projectId,
  status
) => {
  return createSelector(
    [projectsPublicDataDictionaryItemSelectorCreator(projectId)],
    (projectPublicInfo) => {
      return projectPublicInfo instanceof ProjectPublicDataModel
        ? projectPublicInfo.status === status
        : null;
    }
  );
};

export const projectsPublicDataDictionaryItemInPendingSelectorCreator = (
  projectId
) => {
  return projectsPublicDataDictionaryItemStatusSelectorCreator(
    projectId,
    STATUS.LOADING
  );
};

export const projectsPublicDataDictionaryItemErrorSelectorCreator = (
  projectId
) => {
  return projectsPublicDataDictionaryItemStatusSelectorCreator(
    projectId,
    STATUS.ERROR
  );
};

export const findProjectSelector = (state, { match }) => {
  const projectId = match.params.id || match.params.projectId;
  if (!projectId) {
    return null;
  }
  const { dictionary } = state.project;

  if (!dictionary[projectId]) {
    return null;
  }

  return dictionary[projectId];
};

export const projectListTabsStateCountsSelector = (state) =>
  state.project.projectListTabsStateCounts;

export const findProjectSelectorClosure = (projectId) => {
  return (state) => {
    if (!projectId) {
      return null;
    }
    const { dictionary } = state.project;

    if (!dictionary[projectId]) {
      return null;
    }

    return dictionary[projectId];
  };
};

export const projectUsersStatisticsClosure = (projectId) => {
  return (state) => {
    const noProjectResponse = {
      teamMemberCount: -1,
      tenantMemberCount: -1,
      beneficiaryMemberCount: -1,
    };

    if (!projectId) {
      return noProjectResponse;
    }
    const { usersStatistics } = state.project;

    if (!usersStatistics[projectId]) {
      return noProjectResponse;
    }

    return usersStatistics[projectId];
  };
};

export const projectTeamMemberUsersSelector = (projectId) => {
  return createSelector(
    [teamMemberUsersSelector, userDictionarySelector],
    (teamMemberUsers, userDictionary) => {
      if (!teamMemberUsers[projectId]) {
        return [];
      }

      return teamMemberUsers[projectId].map((userId) => userDictionary[userId]);
    }
  );
};

export const projectTenantUsersSelector = (projectId) => {
  return createSelector(
    [tenantUsersSelector, userDictionarySelector, orgDictionarySelector],
    (tenantUsers, userDictionary, orgDictionary) => {
      if (!tenantUsers[projectId]) {
        return [];
      }

      const tenants = [];
      Object.entries(tenantUsers[projectId])
        .filter(([, { userIds }]) => userIds?.length)
        .forEach(([organizationId, { userIds, locations }]) => {
          const tenantOrg = new RealmModel({
            ...orgDictionary[organizationId],
            users: userIds.map((userId) => userDictionary[userId]),
            locations,
          });
          tenants.push(tenantOrg);
        });

      const sortedLocationsTenants = tenants.map((tenant) => {
        tenant.locations.sort(handleSortLocations);
        return tenant;
      });

      return sortedLocationsTenants.sort((a, b) =>
        handleSortLocations(a?.locations[0], b?.locations[0])
      );
    }
  );
};

export const projectBeneficiaryUsersSelector = (projectId) => {
  return createSelector(
    [beneficiaryUsersSelector, userDictionarySelector],
    (beneficiaryUsers, userDictionary) => {
      if (!beneficiaryUsers[projectId]) {
        return [];
      }

      let beneficiaries = [];
      Object.entries(beneficiaryUsers[projectId]).forEach(([, userIds]) => {
        beneficiaries = [
          ...beneficiaries,
          ...userIds.map((userId) => userDictionary[userId]),
        ];
      });

      return beneficiaries;
    }
  );
};

export const projectBeneficiaryOrgSelector = (projectId) => {
  return createSelector(
    [beneficiaryUsersSelector, orgDictionarySelector],
    (beneficiaryUsers, orgDictionary) => {
      if (!beneficiaryUsers[projectId]) {
        return null;
      }

      const orgId = Object.keys(beneficiaryUsers[projectId])[0];
      if (!orgDictionary[orgId]) {
        return null;
      }

      return orgDictionary[orgId];
    }
  );
};
export const projectLayoutFilesSelector = (projectId) => (state) =>
  layoutFilesSelector(state)[projectId] || [];
