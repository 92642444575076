import React from 'react';
import { connect } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageOutlined } from '@material-ui/icons';
import JointBottomIcon from 'stories/ui/Icons/JointBottomIcon';
import Dropdown from 'stories/ui/Dropdown/Dropdown';
import UserDefaultIcon from 'stories/ui/Icons/UserDefaultIcon';
import CloseAltIcon from 'stories/ui/Icons/CloseAltIcon';
import { authUserSelector } from 'store/auth/selectors';
import { logout as logoutAction } from 'store/auth/actions';
import { getHumanReadableRoles } from 'utils/helpers';
import { FileRequest, fileRequest } from 'api/requestWrappers/FileRequest';
import useUserProfilePhotoUpdate from 'hooks/user/useUserProfilePhotoUpdate';
import PATH from 'routes/paths';
import ContainerNotificationSection from 'containers/project/notifications/ContainerNotificationSection';
import { changeLanguage } from 'translations/helpers';
import { LANGUAGES } from 'translations/config';
import useMobile from 'stories/hooks/devices/useMobile';
import NotificationIcon from 'stories/ui/Icons/NotificationIcon';

const StyledUserProfile = styled.div`
  max-height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 1.5rem;
  border-left: 1px solid ${({ theme }) => theme.palette.themeCommon.separator};
  justify-content: space-between;
`;

const StyledJointIcon = styled(JointBottomIcon)`
  position: absolute;
  top: 69px;
  left: -3px;
`;

const MainInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

const UserInfo = styled.div`
  margin: 0 1rem;
  font-size: ${({ theme }) =>
    theme.typography.pxToRem(theme.typography.fontSize)};
`;

const UserName = styled.div`
  color: ${({ theme }) => theme.palette.themeCommon.black.main};
`;

const RoleName = styled.div`
  color: ${({ theme }) => theme.palette.themeCommon.gray};
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > *:first-child {
    margin-right: 8px;
  }
`;

const UserProfileHeader = ({
  className,
  user,
  logout,
  zIndex,
  onMenuItemSelected,
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const getAuthUserAvatarSrc = () =>
    user?.avatarImage?.uuid
      ? fileRequest.getFilePath(
          user?.avatarImage?.uuid,
          FileRequest.THUMBNAIL_SIZES.SQUARE_176_PX
        )
      : undefined;

  const { updatedProfilePhoto } = useUserProfilePhotoUpdate(user?.id);
  const avatarSrc = updatedProfilePhoto.updated
    ? updatedProfilePhoto.filePath
    : getAuthUserAvatarSrc();

  const { isLessThanTablet } = useMobile();

  const dropdownOptions = [
    {
      option: t('general.myProfile'),
      icon: <UserDefaultIcon color="gray" $hoverDisabled />,
      onClick: () => history.push(PATH.MY_PROFILE.SHOW),
    },
    {
      option: t('language'),
      icon: <LanguageOutlined style={{ fill: theme.palette.gray[500] }} />,
      nestedLevelOptions: [
        {
          option: t('en'),
          icon: <>🇬🇧</>,
          onClick: () => changeLanguage(LANGUAGES.EN),
          isActive: language === LANGUAGES.EN,
        },
        {
          option: t('ro'),
          icon: <>🇷🇴</>,
          onClick: () => changeLanguage(LANGUAGES.RO),
          isActive: language === LANGUAGES.RO,
        },
      ],
      dontTriggerItemSelected: true,
    },
    ...(isLessThanTablet
      ? [
          {
            option: (
              <ContainerNotificationSection
                onNotificationSelected={onMenuItemSelected}
                withIcon={false}
              />
            ),
            icon: <NotificationIcon color="gray" />,
            preventDropdownClose: true,
            dontTriggerItemSelected: true,
          },
        ]
      : []),
    {
      option: t('general.logout'),
      icon: <CloseAltIcon color="gray" />,
      onClick: () => logout(),
    },
  ];

  return (
    <StyledUserProfile className={className}>
      <StyledJointIcon />
      <MainInfo>
        <Avatar alt="avatar" src={avatarSrc} />
        <UserInfo>
          <UserName>{user.email}</UserName>
          <RoleName>{getHumanReadableRoles(user.roles)[0].title}</RoleName>
        </UserInfo>
      </MainInfo>
      <StyledButtonWrapper>
        <Dropdown
          options={dropdownOptions}
          onMenuItemSelected={onMenuItemSelected}
          zIndex={zIndex}
          nestedLevelsContainerHoverDirection="left"
        />

        {isLessThanTablet || (
          <ContainerNotificationSection
            onNotificationSelected={onMenuItemSelected}
          />
        )}
      </StyledButtonWrapper>
    </StyledUserProfile>
  );
};

UserProfileHeader.propTypes = {
  className: PropTypes.string.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  logout: PropTypes.func.isRequired,
  zIndex: PropTypes.number,
  onMenuItemSelected: PropTypes.func,
};

UserProfileHeader.defaultProps = {
  zIndex: undefined,
  onMenuItemSelected: () => {},
};

const mapStateToProps = (state) => ({
  user: authUserSelector(state),
});

const mapDispatchToProps = {
  logout: logoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileHeader);
