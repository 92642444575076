import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { getRandomItemFromArr } from 'stories/mocks/random-values';
import TypesHelper from '../utils/types/TypesHelper';
import { HTTP_STATUS_CODE } from '../utils/constants';

export const useForceUpdate = () => {
  const [forceUpdateIndex, forceUpdate] = useReducer((x) => x + 1, 0);

  return { forceUpdateIndex, forceUpdate };
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useHasChanged = (value) => {
  const prevValue = usePrevious(value);
  return prevValue !== value;
};

export const useRequestDecorator = (throwError = false) => {
  const [requestInProgress, setRequestInProgress] = useState(false);

  const requestDecorator = async (requestPromise) => {
    setRequestInProgress(true);
    let response = null;
    try {
      response = await requestPromise;
    } catch (e) {
      if (throwError) {
        throw e;
      } else {
        console.error(e);
      }
    } finally {
      setRequestInProgress(false);
    }

    return response;
  };

  return { requestInProgress, requestDecorator };
};

export const useRandomKey = (range) => {
  const getRandomValue = () =>
    TypesHelper.isArray(range) && range.length
      ? getRandomItemFromArr(range)
      : Math.random();

  const [randomKey, setRandomKey] = useState(getRandomValue());

  const updateRandomKey = () => setRandomKey(getRandomValue());

  return { randomKey, updateRandomKey };
};

/**
 *
 * @param {Object | undefined} mockResponseConfig
 * @param {any | undefined} mockResponseConfig.response
 * @param {Function | undefined} mockResponseConfig.responseMapper
 * @param {Object | undefined} errorConfig
 * @param {number | undefined} errorConfig.status
 */
export const useRequestMock = (
  mockResponseConfig = {
    response: 'ok',
  },
  errorConfig
) => {
  const { requestInProgress, requestDecorator } = useRequestDecorator();
  const { randomKey: requestPendingTime } = useRandomKey([
    1000,
    2000,
    3000,
    4000,
    5000,
  ]);

  return {
    makeRequest: () =>
      requestDecorator(
        new Promise((res, rej) => {
          setTimeout(() => {
            let response = {
              data: mockResponseConfig.response,
              status: errorConfig
                ? errorConfig.status || HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR
                : HTTP_STATUS_CODE.OK,
            };

            if (mockResponseConfig.responseMapper) {
              response = mockResponseConfig.responseMapper(
                mockResponseConfig.response
              );
            }

            if (errorConfig) {
              rej(response);
            } else {
              res(response);
            }
          }, requestPendingTime);
        })
      ),
    isInPending: requestInProgress,
  };
};

export const MOCK_REAL_TIME_STATUSES = Object.freeze({
  CONNECTED: 'connected',
  CLOSED: 'closed',
  INIT: 'init',
});

export const useMockRealTime = (onMessage, responseData, config = {}) => {
  const [connection, setConnection] = useState({
    status: MOCK_REAL_TIME_STATUSES.INIT,
  });

  const [firstRun, setFirstRun] = useState(true);

  const enableAutomaticConnect = config.enableAutomaticConnect ?? true;
  const onMessageInterval = config.onMessageInterval || 5000;
  const eventType = config.eventType || 'mock';
  const randomizeResponse = !!config.randomizeResponse;
  const responseWithFirstElement = !!config.responseWithFirstElement;

  const connect = () => {
    const connectionTime = getRandomItemFromArr([100, 200]);

    return setTimeout(
      () => setConnection({ status: MOCK_REAL_TIME_STATUSES.CONNECTED }),
      connectionTime
    );
  };

  const connectionClosedLogger = () => {
    console.warn('Mock connection is closed');
  };

  const close = () => {
    setConnection({ status: MOCK_REAL_TIME_STATUSES.CLOSED });
    connectionClosedLogger();
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    let possibleTimeoutId;

    if (enableAutomaticConnect) {
      possibleTimeoutId = connect();
    }

    return () => {
      possibleTimeoutId && clearTimeout(possibleTimeoutId);

      close();
    };
    // eslint-disable-next-line
  }, [enableAutomaticConnect]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    let possibleIntervalId;

    // eslint-disable-next-line default-case
    switch (connection.status) {
      case MOCK_REAL_TIME_STATUSES.CONNECTED: {
        const sendMessage = () => {
          const getResponseWithFirstElementCondition = (res) =>
            responseWithFirstElement && !!res?.[0];

          if (randomizeResponse && TypesHelper.isArray(responseData)) {
            let response = getRandomItemFromArr(responseData);

            if (getResponseWithFirstElementCondition(response)) {
              response = [response?.[0]];
            }

            onMessage?.({
              event: eventType,
              response,
            });

            return;
          }

          let response = responseData;

          if (getResponseWithFirstElementCondition(response)) {
            response = [responseData?.[0]];
          }

          onMessage?.({
            event: eventType,
            response,
          });
        };

        if (firstRun) {
          sendMessage();

          setFirstRun(false);
        } else {
          possibleIntervalId = setInterval(() => {
            sendMessage();
          }, onMessageInterval);
        }

        break;
      }
      case MOCK_REAL_TIME_STATUSES.CLOSED: {
        connectionClosedLogger();
        break;
      }
    }

    return () => {
      possibleIntervalId && clearInterval(possibleIntervalId);
    };
    // eslint-disable-next-line
  }, [
    connection,
    onMessageInterval,
    randomizeResponse,
    firstRun,
    responseWithFirstElement,
    onMessage,
  ]);

  return useMemo(
    () => ({
      connect,
      close,
      connection,
    }),
    // eslint-disable-next-line
    [connection]
  );
};

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export function useShowMobileMenu() {
  const { width } = useWindowSize();
  const SHOW_MOBILE_MENU_WIDTH = 1023;

  return width <= SHOW_MOBILE_MENU_WIDTH;
}
