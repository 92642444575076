import { createActionTypesConfig } from '../../helpers';

export const GET_BUILDINGS = createActionTypesConfig(
  'project',
  'GET_BUILDINGS'
);
export const CREATE_BUILDING = createActionTypesConfig(
  'project',
  'CREATE_BUILDING'
);
export const DELETE_BUILDING = createActionTypesConfig(
  'project',
  'DELETE_BUILDING'
);
export const UPDATE_BUILDING = createActionTypesConfig(
  'project',
  'UPDATE_BUILDING'
);

export const CREATE_BUILDING_LEVEL = createActionTypesConfig(
  'project',
  'CREATE_BUILDING_LEVEL'
);
export const DELETE_BUILDING_LEVEL = createActionTypesConfig(
  'project',
  'DELETE_BUILDING_LEVEL'
);
/**
 * all room related actions will be fulfilled within UPDATE_BUILDING_LEVEL
 * @type {Readonly<{SUCCESS: string, REQUEST: string, ERROR: string}>}
 */
export const UPDATE_BUILDING_LEVEL = createActionTypesConfig(
  'project',
  'UPDATE_BUILDING_LEVEL'
);
export const DUPLICATE_BUILDING_LEVEL = createActionTypesConfig(
  'project',
  'DUPLICATE_BUILDING_LEVEL'
);
export const MOVE_BUILDING_LEVEL = createActionTypesConfig(
  'project',
  'MOVE_BUILDING_LEVEL'
);
export const LINK_BUILDING_LEVEL = createActionTypesConfig(
  'project',
  'LINK_BUILDING_LEVEL'
);
export const UNLINK_BUILDING_LEVEL = createActionTypesConfig(
  'project',
  'UNLINK_BUILDING_LEVEL'
);

export const CHANGE_ACTIVE_BUILDING_ID = 'project/CHANGE_ACTIVE_BUILDING_ID';
