import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCallback, useRef } from 'react';
import NotificationsDropdown from 'stories/ui/NotificationsDropdown/NotificationsDropdown';
import {
  notificationItemsSelector,
  notificationTotalSelector,
} from 'store/notifications/selectors';
import {
  getNotifications,
  markNotificationAsAcknowledged,
  markAllNotificationsAsAcknowledged,
} from 'store/notifications/actions';
import NotificationItemMapper from 'store/notifications/helpers/NotificationItemMapper';
import Pager from 'api/helpers/Pager';
import TypesHelper from 'utils/types/TypesHelper';
import { useDidMount } from '../../../hooks/lifecycleHooks';

const PAGE_SIZE = 15;

const ContainerNotificationSection = ({
  Child,
  onNotificationSelected,
  withIcon,
}) => {
  const dispatch = useDispatch();
  const totalNotifications = useSelector(notificationTotalSelector) || 0;
  const notificationItems = useSelector(notificationItemsSelector) || [];
  const history = useHistory();
  const pagerRef = useRef(new Pager(1, PAGE_SIZE));

  const markNotificationAsAcknowledgedCallback = (notificationId) => {
    return dispatch(markNotificationAsAcknowledged(notificationId));
  };

  const markAllNotificationsAsAcknowledgedCallback = () => {
    return dispatch(markAllNotificationsAsAcknowledged());
  };

  const fetchNotifications = useCallback(() => {
    dispatch(getNotifications(pagerRef.current)).then(() => {
      pagerRef.current = new Pager(pagerRef.current.pageNumber + 1, PAGE_SIZE);
    });
  }, [dispatch]);

  useDidMount(() => {
    fetchNotifications();
  });

  const mappedNotifications = notificationItems?.map((notificationModel) => {
    const notificationItemMapper = new NotificationItemMapper(
      notificationModel
    );

    return {
      ...notificationItemMapper,
      onClick: () => {
        notificationItemMapper.navigateToResource(
          () =>
            markNotificationAsAcknowledgedCallback(notificationItemMapper.id),
          history
        );
      },
    };
  });

  const infiniteScrollLoadItems = useCallback(() => {
    const getIsAllowedToFetch = () => {
      if (
        TypesHelper.isNumber(notificationItems?.length) &&
        TypesHelper.isNumber(totalNotifications)
      ) {
        return notificationItems?.length < totalNotifications;
      }

      return true;
    };

    const isAllowed = getIsAllowedToFetch();

    if (isAllowed) {
      fetchNotifications();
    }
  }, [fetchNotifications, notificationItems?.length, totalNotifications]);

  return (
    <div className="notification-section-wrapper">
      <Child
        withIcon={withIcon}
        count={totalNotifications}
        notifications={mappedNotifications}
        onDismissAllClick={markAllNotificationsAsAcknowledgedCallback}
        isDismissButtonDisabled={!totalNotifications}
        infiniteScrollLoadItems={infiniteScrollLoadItems}
        onNotificationSelected={onNotificationSelected}
      />
    </div>
  );
};

ContainerNotificationSection.propTypes = {
  Child: PropTypes.func,
  onNotificationSelected: PropTypes.func,
  withIcon: PropTypes.bool,
};

ContainerNotificationSection.defaultProps = {
  Child: NotificationsDropdown,
  onNotificationSelected: () => {},
  withIcon: true,
};

export default ContainerNotificationSection;
