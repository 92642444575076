import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Z_INDEX_VALUES from 'css/constants';
import DropdownNew from '../DropdownNew/DropdownNew';
import NotificationsCount from '../NotificationsCount/NotificationsCount';
import NotificationsItem from '../NotificationsSection/NotificationsItem/NotificationsItem';
import EmptyNotifications from './EmptyNotifications';
import NotificationModel from '../../../api/models/NotificationModel';
import NotificationsHeader from '../NotificationsSection/NotificationsHeader/NotificationsHeader';

const { NOTIFICATION_TYPES } = NotificationModel;

const Wrapper = styled.div`
  cursor: pointer;
`;

const NotificationsDropdown = ({
  count,
  notifications,
  onDismissAllClick,
  isDismissButtonDisabled,
  infiniteScrollLoadItems,
  onNotificationSelected,
  withIcon,
}) => {
  const [isDismissAllInProgress, setIsDismissAllInProgress] = useState(false);

  const mappedNotifications = [...notifications]
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .map((item) => ({
      option: <NotificationsItem withHover item={item} />,
      id: item.id,
      onClick: (...data) => {
        item.onClick(...data);

        onNotificationSelected?.(...data);
      },
    }));

  const finalNotifications = notifications?.length
    ? [
        {
          option: (
            <NotificationsHeader
              isDismissButtonDisabled={
                isDismissButtonDisabled || isDismissAllInProgress
              }
              onDismissAllClick={() => {
                setIsDismissAllInProgress(true);

                const promise = onDismissAllClick();

                if (promise.then) {
                  promise.finally(() => {
                    setIsDismissAllInProgress(false);
                  });
                } else {
                  setIsDismissAllInProgress(false);
                }
              }}
            />
          ),
          clickable: false,
          styles: css`
            position: sticky;
            top: 0;
            background: ${({ theme }) => theme.palette.themeCommon.white.main};
            z-index: 5;
          `,
        },
        ...mappedNotifications,
      ]
    : [
        {
          option: <EmptyNotifications />,
          clickable: false,
        },
      ];

  return (
    <Wrapper>
      <DropdownNew
        width="370px"
        withOptionIcon={false}
        optionsWithHover={false}
        optionContentPadding="0"
        options={finalNotifications}
        openedWrapperCss={css`
          border: 1px solid ${({ theme }) => theme.palette.primary.main};
        `}
        withGaps={false}
        withInfiniteScroll={Boolean(notifications?.length)}
        infiniteScrollLoadItems={infiniteScrollLoadItems}
        zIndex={Z_INDEX_VALUES.MOBILE_MENU + 2}
      >
        {({ onClick, ref, open }) => {
          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div onClick={onClick} ref={ref}>
              <NotificationsCount
                wrapperClassName="count-wrapper"
                count={count}
                active={!!open}
                withIcon={withIcon}
              />
            </div>
          );
        }}
      </DropdownNew>
    </Wrapper>
  );
};

NotificationsDropdown.propTypes = {
  count: PropTypes.number,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      number: PropTypes.number,
      type: PropTypes.oneOfType([
        PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)),
        PropTypes.string,
      ]),
      date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      author: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  onDismissAllClick: PropTypes.func,
  isDismissButtonDisabled: PropTypes.bool,
  infiniteScrollLoadItems: PropTypes.func,
  onNotificationSelected: PropTypes.func,
  withIcon: PropTypes.bool,
};
NotificationsDropdown.defaultProps = {
  count: 0,
  notifications: [],
  onDismissAllClick: () => {},
  isDismissButtonDisabled: false,
  infiniteScrollLoadItems: () => {},
  onNotificationSelected: () => {},
  withIcon: true,
};

export default NotificationsDropdown;
