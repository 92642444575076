export const GET = Object.freeze({
  REQUEST: 'equipmentGroup/GET_REQUEST',
  SUCCESS: 'equipmentGroup/GET_SUCCESS',
  ERROR: 'equipmentGroup/GET_ERROR',
});

export const GET_BY_EQUIPMENT_PROJECT_CATEGORY_ID = Object.freeze({
  REQUEST: 'equipmentGroup/GET_BY_EQUIPMENT_PROJECT_CATEGORY_ID_REQUEST',
  SUCCESS: 'equipmentGroup/GET_BY_EQUIPMENT_PROJECT_CATEGORY_ID_SUCCESS',
  ERROR: 'equipmentGroup/GET_BY_EQUIPMENT_PROJECT_CATEGORY_ID_ERROR',
});

export const GET_BY_PROJECT_ID = Object.freeze({
  REQUEST: 'equipmentGroup/GET_BY_PROJECT_ID_REQUEST',
  SUCCESS: 'equipmentGroup/GET_BY_PROJECT_ID_SUCCESS',
  ERROR: 'equipmentGroup/GET_BY_PROJECT_ID_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'equipmentGroup/FIND_REQUEST',
  SUCCESS: 'equipmentGroup/FIND_SUCCESS',
  ERROR: 'equipmentGroup/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'equipmentGroup/CREATE_REQUEST',
  SUCCESS: 'equipmentGroup/CREATE_SUCCESS',
  ERROR: 'equipmentGroup/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'equipmentGroup/UPDATE_REQUEST',
  SUCCESS: 'equipmentGroup/UPDATE_SUCCESS',
  ERROR: 'equipmentGroup/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'equipmentGroup/DELETE_REQUEST',
  SUCCESS: 'equipmentGroup/DELETE_SUCCESS',
  ERROR: 'equipmentGroup/DELETE_ERROR',
});

export const GET_EQUIPMENT_GROUPS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const GET_EQUIPMENT_GROUPS_BY_EQUIPMENT_PROJECT_CATEGORY_ID_ACTION = Object.freeze(
  {
    REQUEST: (payload) => ({
      type: GET_BY_EQUIPMENT_PROJECT_CATEGORY_ID.REQUEST,
      payload,
    }),
    SUCCESS: (payload) => ({
      type: GET_BY_EQUIPMENT_PROJECT_CATEGORY_ID.SUCCESS,
      payload,
      error: false,
    }),
    ERROR: (payload) => ({
      type: GET_BY_EQUIPMENT_PROJECT_CATEGORY_ID.ERROR,
      payload,
      error: true,
    }),
  }
);

export const GET_EQUIPMENT_GROUPS_BY_PROJECT_ID_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_PROJECT_ID.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_PROJECT_ID.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_PROJECT_ID.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_EQUIPMENT_GROUP_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_EQUIPMENT_GROUP_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_EQUIPMENT_GROUP_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_EQUIPMENT_GROUP_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});
