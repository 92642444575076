export const GET = Object.freeze({
  REQUEST: 'subcontractorCategory/GET_REQUEST',
  SUCCESS: 'subcontractorCategory/GET_SUCCESS',
  ERROR: 'subcontractorCategory/GET_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'subcontractorCategory/FIND_REQUEST',
  SUCCESS: 'subcontractorCategory/FIND_SUCCESS',
  ERROR: 'subcontractorCategory/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'subcontractorCategory/CREATE_REQUEST',
  SUCCESS: 'subcontractorCategory/CREATE_SUCCESS',
  ERROR: 'subcontractorCategory/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'subcontractorCategory/UPDATE_REQUEST',
  SUCCESS: 'subcontractorCategory/UPDATE_SUCCESS',
  ERROR: 'subcontractorCategory/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'subcontractorCategory/DELETE_REQUEST',
  SUCCESS: 'subcontractorCategory/DELETE_SUCCESS',
  ERROR: 'subcontractorCategory/DELETE_ERROR',
});

export const GET_SUBCONTRACTOR_CATEGORIES_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_SUBCONTRACTOR_CATEGORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_SUBCONTRACTOR_CATEGORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_SUBCONTRACTOR_CATEGORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_SUBCONTRACTOR_CATEGORY_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});
