import { lazy } from 'react';
import authRoutes from 'routes/globalRoutes/auth';
import myProfileRoutes from 'routes/globalRoutes/myProfile';
import PATH from 'routes/paths';

const Projects = lazy(() => import('components/layout/mainPages/Projects'));

const ProjectInfo = lazy(() =>
  import('components/layout/mainPages/beneficiaryPages/projectInfo/ProjectInfo')
);

const BeneficiaryDashboard = lazy(() =>
  import(
    'components/layout/mainPages/beneficiaryPages/dashboard/BeneficiaryDashboard'
  )
);
const MaintenancePlan = lazy(() =>
  import(
    'components/layout/mainPages/beneficiaryPages/maintenancePlan/BeneficiaryMaintenancePlan'
  )
);
const EquipmentList = lazy(() =>
  import('containers/project/equipment/list/EquipmentList')
);
const Equipment = lazy(() =>
  import('containers/project/equipment/one/Equipment')
);

export const ASSIGNED_PROJECTS = {
  component: Projects,
  path: PATH.PROJECT.LIST,
  exact: true,
  isProtected: true,
};
export const MAINTENANCE_PLAN = {
  component: MaintenancePlan,
  path: PATH.PROJECT.MAINTENANCE_PLAN.LIST,
  exact: false,
  isProtected: true,
};
export const PROJECT_INFO = {
  component: ProjectInfo,
  path: PATH.PROJECT.INFO.ONE,
  exact: false,
  isProtected: true,
};
export const PROJECT_EQUIPMENT_LIST = {
  component: EquipmentList,
  path: PATH.PROJECT.EQUIPMENT.LIST,
  exact: false,
  isProtected: true,
};
export const PROJECT_EQUIPMENT = {
  component: Equipment,
  path: PATH.PROJECT.EQUIPMENT.ONE,
  exact: false,
  isProtected: true,
};

const DASHBOARD = {
  component: BeneficiaryDashboard,
  path: PATH.PROJECT.ONE,
  exact: false,
  isProtected: true,
};

let routes = [];
const routeFiles = require.context('./', true, /^(?!.\/index|.\/paths).*\.js$/);

routeFiles.keys().forEach((routeFile) => {
  routes = [...routes, ...routeFiles(routeFile).default];
});

const beneficiaryRoutes = [
  ...authRoutes,
  ...myProfileRoutes,
  ...routes,
  MAINTENANCE_PLAN,
  PROJECT_INFO,
  ASSIGNED_PROJECTS,
  PROJECT_EQUIPMENT,
  PROJECT_EQUIPMENT_LIST,
  DASHBOARD,
];

export default beneficiaryRoutes;
