import addLastReadMessageToEachResourceChatHistory from '../../helpers/addLastReadMessageToEachResourceChatHistory';

export default (state, action, config) => {
  return {
    ...state,
    [config.propName]: addLastReadMessageToEachResourceChatHistory(
      state[config.propName],
      action.payload.chats,
      config.messageModelCreator
    ),
  };
};
