import React, { useState, cloneElement } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';
import clsx from 'clsx';
import ClickAwayListener from 'react-click-away-listener';
import PropTypes from 'prop-types';
import { placements } from '@popperjs/core/lib/enums';
import IconButton from '@material-ui/core/IconButton';
import Z_INDEX_VALUES from 'css/constants';
import TypesHelper from 'utils/types/TypesHelper';
import { POPPER_ROOT } from 'utils/constants';
import DirectionRightIcon from 'stories/ui/Icons/DirectionRightIcon';
import EllipsisIcon from 'stories/ui/Icons/EllipsisIcon';
import ContainerTextField from '../TextField/ContainerTextField';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';

const optionPropTypes = PropTypes.shape({
  option: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  onClick: PropTypes.func,
  preventDropdownClose: PropTypes.bool,
  activeItemIcon: PropTypes.element,
  nestedLevelOptions: PropTypes.arrayOf(PropTypes.any),
  nestedLevelChildren: PropTypes.node,
  withSearchFilter: PropTypes.bool,
  styles: PropTypes.instanceOf(Object),
});

const Wrapper = styled.div`
  background: #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  z-index: ${({ zIndex }) => zIndex};
  max-height: 80vh;
  overflow-y: auto;
  ${({ width }) =>
    width
      ? css`
          width: ${width};
        `
      : css``}

  ${({ openedWrapperCss }) => openedWrapperCss}
`;

const OptionContent = styled.button`
  font: inherit;
  display: flex;
  padding: ${({ optionContentPadding }) => {
    return `${optionContentPadding || '11px 12px'};`;
  }}
  background: transparent;
  border: 0;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  align-items: center;
`;

const OptionWrapper = styled.div`
  position: relative;
  display: flex;

  &:first-of-type {
    margin-top: ${({ withGaps }) => (withGaps ? '8px' : 'none')};
  }
  &:last-of-type {
    margin-bottom: ${({ withGaps }) => (withGaps ? '8px' : 'none')};
  }

  ${({ isClickable }) => {
    return isClickable
      ? css`
          &:hover,
          &.open {
            background: ${({ theme, optionWithHover }) =>
              optionWithHover && theme.palette.gray[100]};
          }
        `
      : css`
          ${OptionContent} {
            cursor: default !important;
          }
        `;
  }}

  ${({ styles }) => styles}
`;

const IconPlaceholder = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
`;

const StyledIconButton = styled(IconButton)`
  background-color: transparent;
  :hover {
    background-color: transparent;
  }
  padding: 0;

  &[disabled] {
    cursor: default;
  }
`;

const NestedRightIcon = styled(DirectionRightIcon).attrs((attrs) => ({
  ...attrs,
  customSize: 18,
}))`
  margin-left: auto;
  border: 0;
`;

const OptionName = styled.div`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.palette.gray[1900]};
  margin: ${({ withGaps }) => (withGaps ? '0 10px' : 'none')};
  width: 100%;
  text-align: left;
`;

const OptionSearchFilterWrapper = styled.div`
  padding: 5px;
`;

const Option = ({
  option,
  placement,
  withOptionIcon,
  optionContentPadding,
  clickable,
  optionWithHover,
  withGaps,
  zIndex,
}) => {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-8, -8],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 16,
        },
      },
    ],
  });

  const hasNestedLevelOptions =
    option.nestedLevelOptions?.length || option.nestedLevelChildren;

  const getNestedChildren = () => {
    if (option.nestedLevelChildren) {
      return (
        <Wrapper
          zIndex={zIndex}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes}
        >
          {option.nestedLevelChildren}
        </Wrapper>
      );
    }

    if (option.nestedLevelOptions?.length) {
      return (
        <Wrapper
          zIndex={zIndex}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes}
        >
          {option.withSearchFilter && !!option.nestedLevelOptions?.length && (
            <OptionSearchFilterWrapper>
              <ContainerTextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </OptionSearchFilterWrapper>
          )}
          {option.nestedLevelOptions
            ?.filter((nestedOption) => {
              if (
                TypesHelper.isString(nestedOption.option) &&
                option.withSearchFilter &&
                search
              ) {
                return nestedOption.option
                  .toLowerCase()
                  .includes(search.trim().toLowerCase());
              }

              return true;
            })
            .map((nestedOption, idx) => (
              <Option
                withOptionIcon={nestedOption.withOptionIcon}
                key={String(idx)}
                option={nestedOption}
                placement={placement}
                clickable={nestedOption.clickable}
                optionContentPadding={nestedOption.optionContentPadding}
                optionWithHover={nestedOption.optionWithHover}
                withGaps={nestedOption.withGaps}
              />
            ))}
        </Wrapper>
      );
    }
    return null;
  };

  const isClickable = !TypesHelper.isBoolean(clickable) || clickable;

  return (
    <OptionWrapper
      styles={option.styles}
      isClickable={isClickable}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className={clsx({ open })}
      optionWithHover={optionWithHover}
      withGaps={withGaps}
    >
      <OptionContent
        ref={setReferenceElement}
        type="button"
        onClick={(e) => isClickable && option.onClick?.(e, option)}
        optionContentPadding={optionContentPadding}
      >
        {withOptionIcon && (
          <IconPlaceholder>
            {(open && option.activeItemIcon) || option.icon}
          </IconPlaceholder>
        )}
        <OptionName withGaps={withGaps}>{option.option}</OptionName>
        {hasNestedLevelOptions ? (
          <IconPlaceholder>
            <NestedRightIcon />
          </IconPlaceholder>
        ) : null}
      </OptionContent>
      {open && hasNestedLevelOptions
        ? createPortal(getNestedChildren(), POPPER_ROOT)
        : null}
    </OptionWrapper>
  );
};

Option.propTypes = {
  option: optionPropTypes.isRequired,
  placement: PropTypes.oneOf(placements),
  withOptionIcon: PropTypes.bool,
  optionContentPadding: PropTypes.string,
  clickable: PropTypes.bool,
  optionWithHover: PropTypes.bool,
  withGaps: PropTypes.bool,
  zIndex: PropTypes.number,
};

Option.defaultProps = {
  placement: 'left-start',
  withOptionIcon: true,
  optionContentPadding: undefined,
  clickable: true,
  optionWithHover: true,
  withGaps: true,
  zIndex: Z_INDEX_VALUES.POPPER,
};

export default function DropdownNew({
  children,
  options,
  placement,
  nestedPlacement,
  withOptionIcon,
  optionContentPadding,
  width,
  optionsWithHover,
  openedWrapperCss,
  withGaps,
  withInfiniteScroll,
  infiniteScrollLoadItems,
  portalId,
  zIndex,
}) {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const [open, setOpen] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const handlerToggleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  const normalizeTarget = () => {
    if (!children) {
      return (
        <StyledIconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handlerToggleOpen}
        >
          <EllipsisIcon />
        </StyledIconButton>
      );
    }

    if (TypesHelper.isFunction(children)) {
      return children({
        onClick: handlerToggleOpen,
        open,
        ref: setReferenceElement,
      });
    }
    return cloneElement(children, {
      onClick: handlerToggleOpen,
      className: clsx(children.className, { open }),
      ref: setReferenceElement,
    });
  };

  return (
    <>
      {normalizeTarget()}

      {open
        ? createPortal(
            <ClickAwayListener
              onClickAway={() => {
                setOpen(false);
              }}
            >
              <Wrapper
                zIndex={zIndex}
                width={width}
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                openedWrapperCss={open ? openedWrapperCss : undefined}
              >
                {withInfiniteScroll ? (
                  <InfiniteScroll
                    scrollDirection="down"
                    scrollableContainerRef={{ current: popperElement }}
                    intersectionHandler={infiniteScrollLoadItems}
                    listItems={options.map((option, idx) => {
                      return (
                        <Option
                          key={String(idx)}
                          option={option}
                          placement={nestedPlacement}
                          withOptionIcon={withOptionIcon}
                          optionContentPadding={optionContentPadding}
                          clickable={option.clickable}
                          optionWithHover={optionsWithHover}
                          withGaps={withGaps}
                          zIndex={zIndex}
                        />
                      );
                    })}
                  />
                ) : (
                  options.map((option, idx) => {
                    return (
                      <Option
                        key={String(idx)}
                        option={option}
                        placement={nestedPlacement}
                        withOptionIcon={withOptionIcon}
                        optionContentPadding={optionContentPadding}
                        clickable={option.clickable}
                        optionWithHover={optionsWithHover}
                        withGaps={withGaps}
                        zIndex={zIndex}
                      />
                    );
                  })
                )}
              </Wrapper>
            </ClickAwayListener>,
            POPPER_ROOT,
            portalId || 'dropdownPortal'
          )
        : null}
    </>
  );
}

DropdownNew.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  options: PropTypes.arrayOf(optionPropTypes).isRequired,
  placement: PropTypes.oneOf(placements),
  nestedPlacement: PropTypes.oneOf(placements),
  withOptionIcon: PropTypes.bool,
  optionContentPadding: PropTypes.string,
  width: PropTypes.string,
  optionsWithHover: PropTypes.bool,
  openedWrapperCss: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  ),
  withGaps: PropTypes.bool,
  withInfiniteScroll: PropTypes.bool,
  infiniteScrollLoadItems: PropTypes.func,
  portalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zIndex: PropTypes.number,
};

DropdownNew.defaultProps = {
  placement: 'bottom-end',
  nestedPlacement: 'left-start',
  withOptionIcon: true,
  optionContentPadding: undefined,
  width: '',
  optionsWithHover: true,
  openedWrapperCss: [],
  withGaps: true,
  withInfiniteScroll: false,
  infiniteScrollLoadItems: () => {},
  portalId: null,
  zIndex: Z_INDEX_VALUES.POPPER,
};
