import AbstractDataAdapter from '../AbstractDataAdapter';

class EquipmentDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get createAllowedFields() {
    return [
      'name',
      'quantity',
      'projectId',
      'projectCategoryId',
      'equipmentCategoryGroupId',
      'standardCategoryGroupId',
      'manufacturerId',
      'equipmentModelId',
      'locationDtos',
      'inputs',
      'documentationFileIds',
      'mediaFileIds',
    ];
  }

  /**
   *
   * @returns {string[]}
   */
  static get createRequiredFields() {
    return [
      'name',
      'quantity',
      'projectId',
      'equipmentCategoryGroupId',
      'standardCategoryGroupId',
      'manufacturerId',
      'equipmentModelId',
      'locationDtos',
    ];
  }

  /**
   *
   * @returns {string[]}
   */
  static get updateAllowedFields() {
    return [
      'name',
      'quantity',
      'equipmentCategoryGroupId',
      'manufacturerId',
      'equipmentModelId',
      'locationDtos',
      'inputs',
      'documentationFileIds',
      'mediaFileIds',
      'projectId',
      'state',
      'standardCategoryGroupId',
    ];
  }

  /**
   *
   * @returns {string[]}
   */
  static get updateRequiredFields() {
    return ['projectId', 'standardCategoryGroupId'];
  }
}

export default EquipmentDataAdapter;
