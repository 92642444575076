export default {
  standardProcedure: {
    plural: {
      itself: 'Standard procedures',
    },
    createNewProcedure: 'Create a new procedure',
    editProcedure: 'Edit procedure',
    saveProcedure: 'Save procedure',
    saveNewProcedure: 'Save new procedure',
    emptyStandardProcedureList:
      'Hit the blue plus button and start adding procedures',
    modal: {
      confirmDeleteMessage: `Are you sure? <br /> <br /> This action is irreversible and will result in deleting the standard procedure and the associated operations.`,
    },
    attributes: {
      category: {
        itself: 'Category',
        '*': 'Category *',
        required: 'Category is required',
        select: 'Select category',
      },
      group: {
        itself: 'Group',
        '*': 'Group *',
        required: 'Group is required',
        select: 'Select group',
      },
      frequency: {
        itself: 'Frequency',
        '*': 'Frequency *',
        required: 'Frequency is required',
      },
      type: {
        itself: 'Type',
        '*': 'Type *',
        required: 'Type is required',
      },
      subtype: {
        itself: 'Subtype',
        '*': 'Subtype *',
        required: 'Subtype is required',
      },
    },
  },
};
