import { generalChatMessagesConversationActionCreators } from '../../../../store/chat/general/actions';
import AbstractDirectMessagesChannelEventCatcher from './AbstractDirectMessagesChannelEventCatcher';
import createChatChannelItemModel from '../../../../store/chat/helpers/createChatChannelItemModel';

export default class DirectMessagesNewChannelEventCatcher extends AbstractDirectMessagesChannelEventCatcher {
  static process({ chat }, loggedInUser, dispatch, history) {
    dispatch(
      generalChatMessagesConversationActionCreators.updateConversationInfo(
        chat.id,
        createChatChannelItemModel(chat, loggedInUser.id)
      )
    );

    this.redirectToConversationCheckingChatOwner(chat, loggedInUser, history);
  }
}
