import { createActions, createActionTypesConfig } from '../helpers';

const BASE_NOTIFICATIONS = 'notifications';

const NOTIFICATIONS_ACTION_TYPES = Object.freeze({
  SET_NOTIFICATIONS: `SET_NOTIFICATIONS`,
  ADD_NOTIFICATION: `ADD_NOTIFICATION`,
  REMOVE_NOTIFICATION: `REMOVE_NOTIFICATION`,
  RESET_NOTIFICATIONS: `RESET_NOTIFICATIONS`,
});

export const SET_NOTIFICATIONS_TYPES = createActionTypesConfig(
  BASE_NOTIFICATIONS,
  NOTIFICATIONS_ACTION_TYPES.SET_NOTIFICATIONS
);

export const ADD_NOTIFICATION_TYPES = createActionTypesConfig(
  BASE_NOTIFICATIONS,
  NOTIFICATIONS_ACTION_TYPES.ADD_NOTIFICATION
);

export const REMOVE_NOTIFICATION_TYPES = createActionTypesConfig(
  BASE_NOTIFICATIONS,
  NOTIFICATIONS_ACTION_TYPES.REMOVE_NOTIFICATION
);

export const RESET_NOTIFICATIONS_TYPES = createActionTypesConfig(
  BASE_NOTIFICATIONS,
  NOTIFICATIONS_ACTION_TYPES.RESET_NOTIFICATIONS
);

export const SET_NOTIFICATIONS_ACTION = createActions(SET_NOTIFICATIONS_TYPES);
export const ADD_NOTIFICATION_ACTION = createActions(ADD_NOTIFICATION_TYPES);
export const REMOVE_NOTIFICATION_ACTION = createActions(
  REMOVE_NOTIFICATION_TYPES
);
export const RESET_NOTIFICATIONS_ACTION = createActions(
  RESET_NOTIFICATIONS_TYPES
);

export default NOTIFICATIONS_ACTION_TYPES;
