import ApiResourceRequest from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import ListingResponse from '../ListingResponse';
import Pager from '../../helpers/Pager';
import { PROMISE_STATUSES } from '../../../utils/file/getUploadedFilesUuids';

export class EquipmentGroupRequest extends ApiResourceRequest {
  get apiResource() {
    return 'equipments/category-groups';
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_EQUIPMENT_PROJECT_CATEGORY_ID: `${this.apiRoute}/by-project-category/{equipmentProjectCategoryId}`,
      GET_BY_PROJECT_ID: `${this.apiRoute}/by-project-id/{projectId}`,
    };
  }

  async getByEquipmentProjectCategoryId(
    equipmentProjectCategoryId,
    pager = new Pager(),
    query = {}
  ) {
    pager && Pager.check(pager);
    required({ equipmentProjectCategoryId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_EQUIPMENT_PROJECT_CATEGORY_ID.replace(
        '{equipmentProjectCategoryId}',
        equipmentProjectCategoryId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async getByEquipmentProjectCategoryIds(equipmentProjectCategoryIds) {
    const promises = await Promise.allSettled(
      equipmentProjectCategoryIds.map((id) =>
        this.getByEquipmentProjectCategoryId(id, new Pager(1, -1))
      )
    );

    const fulfilledPromises = promises.filter(
      (result) => result.status === PROMISE_STATUSES.FULFILLED
    );

    const combinedResponses = fulfilledPromises.reduce(
      (acc, { value: listingResponse }) => {
        return {
          total: acc.total + listingResponse.total,
          data: [...acc.data, ...listingResponse.resources],
        };
      },
      { total: 0, data: [] }
    );

    return new ListingResponse(combinedResponses);
  }

  async getByProjectId(projectId, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ projectId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_PROJECT_ID.replace('{projectId}', projectId) +
        queryString
    );

    return new ListingResponse(response?.data);
  }
}

export const equipmentGroupRequest = new EquipmentGroupRequest();
