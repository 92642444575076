import { createActions, createActionTypesConfig } from 'store/helpers';

const PMP_DC_TOUR_LABEL = 'pmpDcTour';

export const GET = createActionTypesConfig(PMP_DC_TOUR_LABEL, 'GET');
export const FIND = createActionTypesConfig(PMP_DC_TOUR_LABEL, 'FIND');
export const CREATE = createActionTypesConfig(PMP_DC_TOUR_LABEL, 'CREATE');
export const UPDATE = createActionTypesConfig(PMP_DC_TOUR_LABEL, 'UPDATE');
export const DELETE = createActionTypesConfig(PMP_DC_TOUR_LABEL, 'DELETE');

export const GET_PMP_DC_TOURS_ACTION = createActions(GET);
export const FIND_PMP_DC_TOUR_ACTION = createActions(FIND);
export const CREATE_PMP_DC_TOUR_ACTION = createActions(CREATE);
export const UPDATE_PMP_DC_TOUR_ACTION = createActions(UPDATE);
export const DELETE_PMP_DC_TOUR_ACTION = createActions(DELETE);
