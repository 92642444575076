import { createMockedId } from '../../stories/mocks/random-values';

export class RequestMiddleware {
  static REQUEST_TYPES = Object.freeze({
    ALL_MATTER: 'allMatter',
    LAST_MATTERS: 'lastMatters',
  });

  #requests = {};

  makeRequestProtected(makeRequest, createAbortSource, requestConfig) {
    const request = this.addRequest(
      requestConfig,
      makeRequest,
      createAbortSource
    );

    return request.makeRequest().finally(() => {
      this.clearRequest(requestConfig.url, requestConfig.method, request.id);
    });
  }

  lastMatters(makeRequest, createAbortSource, requestConfig) {
    this.cancelLastRequest(requestConfig.url, requestConfig.method);

    return this.makeRequestProtected(
      makeRequest,
      createAbortSource,
      requestConfig
    );
  }

  allMatter(makeRequest, createAbortSource, requestConfig) {
    return this.makeRequestProtected(
      makeRequest,
      createAbortSource,
      requestConfig
    );
  }

  handleRequest(
    makeRequest,
    createAbortSource,
    requestConfig,
    requestType = RequestMiddleware.REQUEST_TYPES.ALL_MATTER
  ) {
    const config = {
      [RequestMiddleware.REQUEST_TYPES.LAST_MATTERS]: this.lastMatters.bind(
        this
      ),
      [RequestMiddleware.REQUEST_TYPES.ALL_MATTER]: this.allMatter.bind(this),
    };

    const action = config[requestType] || this.allMatter.bind(this);

    return action(makeRequest, createAbortSource, requestConfig);
  }

  apply(
    makeRequest,
    createAbortSource,
    requestConfig,
    requestType = RequestMiddleware.REQUEST_TYPES.ALL_MATTER
  ) {
    return this.handleRequest(
      makeRequest,
      createAbortSource,
      requestConfig,
      requestType
    );
  }

  getRequestId(requestUrl, requestMethod) {
    return `${requestMethod}:${requestUrl}`;
  }

  addRequest(requestConfig, makeRequest, createAbortSource) {
    const requestId = this.getRequestId(
      requestConfig.url,
      requestConfig.method
    );

    const createRequest = () => {
      const abortSource = createAbortSource();

      return {
        makeRequest: () => {
          const requestParameters = {
            ...requestConfig,
            signal: abortSource.signal,
          };

          return makeRequest(requestParameters);
        },
        id: createMockedId(),
        abortSource,
      };
    };

    const request = createRequest();

    // eslint-disable-next-line no-prototype-builtins
    if (this.#requests.hasOwnProperty(requestId)) {
      this.#requests[requestId].requests.push(request);
    } else {
      this.#requests[requestId] = {
        requests: [request],
      };
    }

    return request;
  }

  getRequests(requestUrl, requestMethod) {
    const requestId = this.getRequestId(requestUrl, requestMethod);

    return this.#requests[requestId];
  }

  getRequestsItems(requestUrl, requestMethod) {
    const requestId = this.getRequestId(requestUrl, requestMethod);

    return this.#requests[requestId]?.requests || [];
  }

  cancelLastRequest(requestUrl, requestMethod) {
    const requestItems = this.getRequestsItems(requestUrl, requestMethod);

    const lastRequest = requestItems.at(-1);

    if (lastRequest) {
      lastRequest?.abortSource?.abort();

      this.clearRequest(requestUrl, requestMethod, lastRequest.id);
    }
  }

  clearRequests(requestUrl, requestMethod) {
    const requestId = this.getRequestId(requestUrl, requestMethod);

    // eslint-disable-next-line no-prototype-builtins
    if (this.#requests.hasOwnProperty(requestId)) {
      this.#requests[requestId] = {
        requests: [],
      };
    }
  }

  clearRequest(requestUrl, requestMethod, id) {
    const requestId = this.getRequestId(requestUrl, requestMethod);

    // eslint-disable-next-line no-prototype-builtins
    if (this.#requests.hasOwnProperty(requestId)) {
      this.#requests[requestId].requests = (
        this.#requests[requestId].requests || []
      ).filter((request) => request.id !== id);
    }
  }
}

const requestMiddleware = new RequestMiddleware();

export default requestMiddleware;
