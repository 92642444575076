import AbstractModel from '../AbstractModel';
import { STATUS } from '../../../utils/constants';
import DailyCheckProceduresOperationsParametersModel from './DailyCheckProceduresOperationsParametersModel';
import DailyCheckProceduresOperationsLabelsModel from './DailyCheckProceduresOperationsLabelsModel';
import EquipmentModel from '../Equipment/EquipmentModel';

class DailyCheckProceduresOperationsModel extends AbstractModel {
  constructor(...params) {
    super(...params);
    if (this.status !== STATUS.LOADING && Array.isArray(this.parameters)) {
      this.dailyCheckProcedureOperationParameters = this.parameters.map(
        (parameter) =>
          new DailyCheckProceduresOperationsParametersModel({ ...parameter })
      );
    }

    if (this.status !== STATUS.LOADING && Array.isArray(this.labels)) {
      this.dailyCheckProcedureOperationLabels = this.labels.map(
        (label) => new DailyCheckProceduresOperationsLabelsModel({ ...label })
      );
    }

    if (this.status !== STATUS.LOADING && this.equipment) {
      this.equipment = EquipmentModel.convertToModel(this.equipment);
    }
  }

  convertAttribute(attribute) {
    if (attribute === 'type') {
      return 'operationType';
    }
    return super.convertAttribute(attribute);
  }
}

export default DailyCheckProceduresOperationsModel;
