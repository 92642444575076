import AbstractEventCatcher from '../AbstractEventCatcher';
import { ticketChatActionCreators } from '../../../store/chat/resource/actions';

export default class TicketChatAddParticipantEventCatcher extends AbstractEventCatcher {
  static process({ participantInfo, dispatch }) {
    dispatch(
      ticketChatActionCreators.addParticipant(
        participantInfo.ticketId,
        participantInfo.chatId,
        participantInfo.newParticipant
      )
    );
  }
}
