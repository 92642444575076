import AbstractModel from '../AbstractModel';
import { STATUS } from '../../../utils/constants';
import RealmModel from '../RealmModel';

class ProjectModel extends AbstractModel {
  static STATES = Object.freeze({
    DRAFT: 'draft',
    ARCHIVED: 'archived',
    ACTIVE: 'active',
  });

  constructor(...params) {
    super(...params);
    if (this.status !== STATUS.LOADING && Array.isArray(this.organizations)) {
      this.organizations = this.organizations.map(
        (organization) => new RealmModel({ ...organization })
      );
    }
  }

  convertAttribute(attribute) {
    if (attribute === 'status') {
      return 'state';
    }
    return super.convertAttribute(attribute);
  }

  get fullAddress() {
    const addressArr = [];

    if (this.addressOne) {
      addressArr.push(this.addressOne);
    }
    if (this.addressTwo) {
      addressArr.push(this.addressTwo);
    }
    if (this.postCode) {
      addressArr.push(this.postCode);
    }
    if (this.city?.name) {
      addressArr.push(this.city.name);
    }

    return addressArr.length ? addressArr.join(', ') : '---';
  }
}

export default ProjectModel;
