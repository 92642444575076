export default {
  dailyCheckTour: {
    dailyCheck: 'Verificare zilnică',
    startDailyCheck: 'Începe tur',
    closeDailyCheck: 'Închide tur',
    emptyDailyCheckTourList:
      'Nu a fost definită nicio verificare zilnică pentru proiect',
    dailyCheckInProgressWarning:
      'Există o verificare zilnică în curs, vă rugăm să o închideți pentru a începe una nouă',
    dailyCheckNoGroupsWarning:
      'Trebuie să definiți grupuri și proceduri zilnice de verificare',
    options: {
      startDailyCheck: 'Începeți verificarea zilnică',
    },
    emptyDailyCheckTourGroupList:
      'Nu a fost găsit niciun grup pentru acest tur',
    dailyCheckHasNoEvents: 'Verificarea zilnică începută nu are evenimente',
    tableColumns: {
      date: 'Data',
      status: 'Stare',
      startTime: 'Oră start',
      endTime: 'Oră sfârșit',
      procedures: 'Proceduri',
      completed: 'Completat',
      alerts: 'Alerte',
    },
    close: 'Închide',
    attributes: {
      comment: {
        '*': 'Comentariu *',
        itself: 'Comentariu',
        required: 'Comentariul e obligatoriu',
        placeholder: 'Scrie un comentariu',
      },
      file: {
        plural: {
          itself: 'Fișiere',
          '*': 'Fișiere *',
          required: 'Fișierele sunt obligatorii',
        },
      },
    },
  },
};
