export const GET_BY_STD_CATEGORY_ID = Object.freeze({
  REQUEST: 'standardProcedure/GET_BY_STD_CATEGORY_ID_REQUEST',
  SUCCESS: 'standardProcedure/GET_BY_STD_CATEGORY_ID_SUCCESS',
  ERROR: 'standardProcedure/GET_BY_STD_CATEGORY_ID_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'standardProcedure/FIND_REQUEST',
  SUCCESS: 'standardProcedure/FIND_SUCCESS',
  ERROR: 'standardProcedure/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'standardProcedure/CREATE_REQUEST',
  SUCCESS: 'standardProcedure/CREATE_SUCCESS',
  ERROR: 'standardProcedure/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'standardProcedure/UPDATE_REQUEST',
  SUCCESS: 'standardProcedure/UPDATE_SUCCESS',
  ERROR: 'standardProcedure/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'standardProcedure/DELETE_REQUEST',
  SUCCESS: 'standardProcedure/DELETE_SUCCESS',
  ERROR: 'standardProcedure/DELETE_ERROR',
});

export const GET_STANDARD_PROCEDURES_BY_STD_CATEGORY_ID_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_STD_CATEGORY_ID.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_STD_CATEGORY_ID.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_STD_CATEGORY_ID.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_STANDARD_PROCEDURE_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_STANDARD_PROCEDURE_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_STANDARD_PROCEDURE_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_STANDARD_PROCEDURE_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});
