import React, { useState } from 'react';
import iconWrapper from '../../../hocs/iconWrapper';

// eslint-disable-next-line react/prop-types
function UserTeamIcon({ size, color, hoverDisabled, hoverBgColor, ...rest }) {
  const [fillColor, setFillColor] = useState(undefined);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      onMouseEnter={() => {
        setFillColor(hoverBgColor);
      }}
      onMouseLeave={() => {
        setFillColor(undefined);
      }}
    >
      {hoverDisabled ? undefined : (
        <path
          d="M1 21C1 18.4537 2.35958 16.2249 4.39241 15H11.6076C13.6404 16.2249 15 18.4537 15 21H8H1Z"
          fill={fillColor}
        />
      )}
      {hoverDisabled ? undefined : (
        <path
          d="M12 7C12 9.20914 10.2091 11 8 11C5.79086 11 4 9.20914 4 7C4 4.79086 5.79086 3 8 3C10.2091 3 12 4.79086 12 7Z"
          fill={fillColor}
        />
      )}
      <path
        d="M1 21C1 18.4537 2.35958 16.2249 4.39241 15H11.6076C13.6404 16.2249 15 18.4537 15 21M19 21C19 18.4537 17.6404 16.2249 15.6076 15H14.5M13 3.12601C14.7252 3.57005 16 5.13615 16 6.99999C16 8.86383 14.7252 10.4299 13 10.874M23 21C23 18.4537 21.6404 16.2249 19.6076 15H18.5M17 3.12601C18.7252 3.57005 20 5.13615 20 6.99999C20 8.86383 18.7252 10.4299 17 10.874M12 7C12 9.20914 10.2091 11 8 11C5.79086 11 4 9.20914 4 7C4 4.79086 5.79086 3 8 3C10.2091 3 12 4.79086 12 7Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default iconWrapper(UserTeamIcon);
