import ApiResourceRequest from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import Pager from '../../helpers/Pager';

export class MaintenanceParameterRequest extends ApiResourceRequest {
  get apiResource() {
    return 'maintenance_procedures/operations/{maintenance_operation_id}/parameters';
  }

  get apiUrl() {
    return this.projectApiUrl;
  }

  async get(
    maintenanceOperationId,
    pager = new Pager(),
    query = {},
    resourcesDataKey = 'maintenance_procedure_operation_parameters'
  ) {
    pager && Pager.check(pager);
    required({ maintenanceOperationId });
    const paginationParams = this.getPaginationParams(pager);
    const orderParams = this.getOrderParams(query.order);

    const response = await this.request(
      'GET',
      this.routes.GET.replace(
        '{maintenance_operation_id}',
        maintenanceOperationId
      ) +
        paginationParams +
        orderParams
    );

    return {
      resources: response?.data?.[resourcesDataKey],
      total: response?.data?.total_item_count,
    };
  }

  async find(maintenanceOperationId, maintenanceParameterId) {
    required({
      maintenanceOperationId,
      maintenanceParameterId,
    });
    const response = await this.request(
      'GET',
      `${
        this.routes.FIND.replace(
          '{maintenance_operation_id}',
          maintenanceOperationId
        ) + maintenanceParameterId
      }`
    );

    return response?.data;
  }

  async create(maintenanceOperationId, attributes) {
    const response = await this.queuedRequest(
      'POST',
      this.routes.CREATE.replace(
        '{maintenance_operation_id}',
        maintenanceOperationId
      ),
      attributes
    );

    return response?.data?.result.maintenance_procedure_operation_parameter_id;
  }

  async update(
    maintenanceOperationId,
    maintenanceParameterId,
    attributes,
    method = 'PUT'
  ) {
    required({
      maintenanceOperationId,
      maintenanceParameterId,
    });
    const response = await this.queuedRequest(
      method,
      this.routes.UPDATE.replace(
        '{maintenance_operation_id}',
        maintenanceOperationId
      ) + maintenanceParameterId,
      attributes
    );

    return response?.data?.result;
  }

  async partialUpdate(
    maintenanceOperationId,
    maintenanceParameterId,
    attributes
  ) {
    return this.update(
      maintenanceOperationId,
      maintenanceParameterId,
      attributes,
      'PATCH'
    );
  }

  async delete(maintenanceOperationId, maintenanceParameterId) {
    required({
      maintenanceOperationId,
      maintenanceParameterId,
    });
    const response = await this.queuedRequest(
      'DELETE',
      this.routes.DELETE.replace(
        '{maintenance_operation_id}',
        maintenanceOperationId
      ) + maintenanceParameterId
    );

    return response?.data?.result;
  }
}

export const maintenanceParameterRequest = new MaintenanceParameterRequest();
