import AbstractModel from './AbstractModel';
import RoleModel from './RoleModel';
import { STATUS } from '../../utils/constants';
import UserModel from './UserModel';
import ProjectModel from './Project/ProjectModel';
// eslint-disable-next-line import/no-cycle
import TicketModel from './TicketModel';

class ProcurementModel extends AbstractModel {
  static STATUSES = Object.freeze({
    NEW: 'new',
    OPENED: 'open',
    PROPOSAL_SUBMITTED: 'proposalSubmitted',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    WORK_IN_PROGRESS: 'workInProgress',
    WORK_FINISHED: 'workFinished',
    CLOSED: 'closed',
    ALL: 'all',
  });

  static authUserCanEditProcurement = (authUser) => {
    return RoleModel.authUserIsAtLeastAssistantPM(authUser);
  };

  static READABLE_STATUS = Object.freeze({
    [ProcurementModel.STATUSES.NEW]: 'procurement.attributes.status.new',
    [ProcurementModel.STATUSES.OPENED]: 'procurement.attributes.status.open',
    [ProcurementModel.STATUSES.PROPOSAL_SUBMITTED]:
      'procurement.attributes.status.proposalSubmitted',
    [ProcurementModel.STATUSES.ACCEPTED]:
      'procurement.attributes.status.accepted',
    [ProcurementModel.STATUSES.REJECTED]:
      'procurement.attributes.status.rejected',
    [ProcurementModel.STATUSES.WORK_IN_PROGRESS]:
      'procurement.attributes.status.workInProgress',
    [ProcurementModel.STATUSES.WORK_FINISHED]:
      'procurement.attributes.status.workFinished',
    [ProcurementModel.STATUSES.CLOSED]: 'procurement.attributes.status.closed',
    [ProcurementModel.STATUSES.ALL]: 'procurement.attributes.status.all',
  });

  static PROPOSAL_CURRENCY = Object.freeze({
    USD: 'USD',
    EUR: 'EUR',
    RON: 'RON ',
  });

  constructor(...params) {
    super(...params);
    if (this.status !== STATUS.LOADING) {
      this.createdAt &&
        this.overwriteProperty('createdAt', {
          get() {
            return this._createdAt ? new Date(this._createdAt) : null;
          },
        });
      this.updatedAt &&
        this.overwriteProperty('updatedAt', {
          get() {
            return this._updatedAt ? new Date(this._updatedAt) : null;
          },
        });
      this.creator = new UserModel(this.creator);
      this.recipient = new UserModel(this.recipient);
      this.project = new ProjectModel(this.project);

      if (Array.isArray(this.externalChat?.chatParticipants)) {
        this.externalChat = {
          ...this.externalChat,
          chatParticipants: this.externalChat.chatParticipants.map(
            (participant) => ({
              ...participant,
              user: new UserModel(participant.user),
            })
          ),
        };
      }
      if (Array.isArray(this.internalChat?.chatParticipants)) {
        this.internalChat = {
          ...this.externalChat,
          chatParticipants: this.internalChat.chatParticipants.map(
            (participant) => ({
              ...participant,
              user: new UserModel(participant.user),
            })
          ),
        };
      }
      if (Array.isArray(this.tickets)) {
        this.tickets = this.tickets.map((ticket) => new TicketModel(ticket));
      }
    }
  }

  convertAttribute(attribute) {
    if (attribute === 'status') {
      return 'procurementStatus';
    }
    return super.convertAttribute(attribute);
  }

  get organization() {
    return this.recipient?.organization?.name || '-';
  }

  get readableProcurementStatus() {
    return ProcurementModel.READABLE_STATUS[this.procurementStatus] || '-';
  }
}

export default ProcurementModel;
