import axios from 'axios';
import { simulateRequest } from '../../utils/helpers';
import {
  CAFM_API_HOST,
  CAFM_API_PORT,
  CAFM_API_PROTOCOL,
  CAFM_API_ENTRY_ROUTE,
} from '../../config';
import ApiErrorModel from '../models/ApiErrorModel';
import { HTTP_STATUS_CODE } from '../../utils/constants';
import requestMiddleware, { RequestMiddleware } from './RequestMiddleware';

/**
 * Abstract request class
 */
class AbstractRequest {
  constructor({ useMock = false, mockResponse, mockRequestDuration = 0 } = {}) {
    this._useMock = useMock;
    if (useMock) {
      this._useMock = useMock;
      this._mockResponse = mockResponse;
      this._mockRequestDuration = mockRequestDuration;
    }
  }

  /**
   *
   * @returns {string}
   */
  get apiProtocol() {
    return CAFM_API_PROTOCOL;
  }

  /**
   *
   * @returns {string}
   */
  get apiHost() {
    return CAFM_API_HOST;
  }

  /**
   *
   * @returns {string}
   */
  get apiPort() {
    return CAFM_API_PORT;
  }

  get apiEntryRoute() {
    return CAFM_API_ENTRY_ROUTE;
  }

  /**
   *
   * @returns {string}
   */
  get apiUrl() {
    return `${this.apiProtocol}://${this.apiHost}${
      this.apiPort ? `:${this.apiPort}` : ''
    }${this.apiEntryRoute}`;
  }

  /**
   *
   * @returns {string}
   */
  get apiResource() {
    return '';
  }

  /**
   *
   * @returns {string}
   */
  get apiRoute() {
    return `${this.apiUrl}/${this.apiResource}`;
  }

  /**
   *
   * @returns {{}}
   */
  get routes() {
    return {};
  }

  /**
   * request wrapper
   *
   * @param method
   * @param url
   * @param data
   * @param extraConfig
   * @param requestType
   * @returns {Promise<AxiosResponse<any>|*>}
   */
  async request(
    method,
    url,
    data = {},
    extraConfig,
    requestType = RequestMiddleware.REQUEST_TYPES.ALL_MATTER
  ) {
    if (this._useMock) {
      await simulateRequest(this._mockRequestDuration);
      return this._mockResponse;
    }

    const requestConfig = {
      method,
      url,
      data,
      ...extraConfig,
    };

    const makeRequest = (requestConfigParameter) =>
      axios(requestConfigParameter);

    return requestMiddleware.apply(
      makeRequest,
      this.createAbortSource.bind(this),
      requestConfig,
      requestType
    );
  }

  /**
   *
   * @returns {AxiosInterceptorManager<AxiosRequestConfig>}
   */
  getRequestInterceptors() {
    return axios.interceptors.request;
  }

  /**
   *
   * @param handler
   * @returns {number}
   */
  addRequestInterceptor(handler) {
    return axios.interceptors.request.use(handler);
  }

  /**
   *
   * @param responseHandler
   * @param errorHandler
   */
  addResponseInterceptor(responseHandler, errorHandler) {
    return axios.interceptors.response.use(responseHandler, errorHandler);
  }

  /**
   *
   * @param {number} id
   */
  removeRequestInterceptor(id) {
    axios.interceptors.request.eject(id);
  }

  addGlobalErrorHandlingInterceptor() {
    this.addResponseInterceptor(
      (response) => response,
      (error) => {
        const errorCodeFormApi = error?.response?.data?.message?.toString();

        if (error?.response?.status !== HTTP_STATUS_CODE.UNAUTHORIZED) {
          ApiErrorModel.showError(errorCodeFormApi);
        }

        return error;
      }
    );
  }

  createAbortSource() {
    return new AbortController();
  }
}

export default AbstractRequest;
