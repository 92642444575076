import AbstractDataAdapter from '../AbstractDataAdapter';

/** @deprecated */
class MaintenanceProcedureDataAdapter extends AbstractDataAdapter {
  /**
   *
   * @returns {string[]}
   */
  static get allowedFields() {
    return [
      'procedureType',
      'subType',
      'frequency',
      'startDate',
      'subcontractorId',
      'equipmentId',
      'positionIndex',
    ];
  }

  /**
   *
   * @returns {string[]}
   */
  static get requiredFields() {
    return ['procedureType', 'frequency', 'equipmentId'];
  }

  static sendableAttributeRule(attribute) {
    if (attribute === 'procedureType') {
      return 'type';
    }
    return super.sendableAttributeRule(attribute);
  }
}

export default MaintenanceProcedureDataAdapter;
