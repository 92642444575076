import { required } from '../../../utils/helpers';
import ChatModel from '../../../realTimeApi/models/ChatModel';

const createChatChannelItemModel = (channelInfo, loggedInUserId) => {
  required({ channelInfo, loggedInUserId });

  return new ChatModel({
    ...channelInfo,
    loggedInUserId,
  });
};

export default createChatChannelItemModel;
