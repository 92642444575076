import React from 'react';
import { useHistory } from 'react-router-dom';
import PATH from 'routes/paths';
import { useTranslation } from 'react-i18next';
import Dropdown from 'stories/ui/Dropdown/Dropdown';
import PropTypes from 'prop-types';
import NavigationDropdownIcon from '../../stories/ui/Icons/NavigationDropdownIcon';

const NavigationDropdown = ({ options, onChange }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const adminOptions = [
    {
      option: t('project.dashboard.itself'),
      onClick: () => history.push(PATH.DASHBOARD),
    },
    {
      option: t('user.plural.itself'),
      onClick: () => history.push(PATH.USER.LIST),
    },
    {
      option: t('realm.plural.itself'),
      onClick: () => history.push(PATH.ORGANIZATION.LIST),
    },
    {
      option: t('subcontractor.plural.itself'),
      onClick: () => history.push(PATH.SUBCONTRACTOR.LIST),
    },
    {
      option: t('announcement.plural.itself'),
      onClick: () => history.push(PATH.ANNOUNCEMENT.LIST),
    },
    {
      option: t('project.standardProcedures'),
      onClick: () => history.push(PATH.STANDARD.PROCEDURE.LIST),
    },
    {
      option: t('project.projects'),
      onClick: () => history.push(PATH.PROJECT.LIST),
    },
    {
      option: t('chat.itself'),
      onClick: () => history.push(PATH.CHAT.PROJECT_CHANNEL.LIST),
    },
    {
      option: t('units.itself'),
      onClick: () => history.push(PATH.UNITS.LIST),
    },
  ];

  return (
    <Dropdown
      onMenuItemSelected={onChange}
      options={options.length ? options : adminOptions}
      nestedLevelsContainerHoverDirection="right"
      icon={NavigationDropdownIcon}
    />
  );
};

NavigationDropdown.propTypes = {
  options: PropTypes.arrayOf([PropTypes.instanceOf(Object)]),
  onChange: PropTypes.func,
};

NavigationDropdown.defaultProps = {
  options: [],
  onChange: () => {},
};

export default NavigationDropdown;
