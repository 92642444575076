export default {
  equipment: {
    itself: 'Equipment',
    plural: {
      itself: 'Equipment',
    },
    addEquipment: 'Add equipment',
    editEquipment: 'Edit equipment',
    enableEquipment: 'Enable equipment',
    disableEquipment: 'Disable equipment',
    linkToOther: 'Link to other',
    deleteEquipment: 'Delete equipment',
    saveNewEquipment: 'Save new equipment',
    saveEquipment: 'Save equipment',
    addEquipmentIn: 'Add equipment in',
    editEquipmentIn: 'Edit equipment in',
    equipmentDetails: 'Equipment details',
    technicalDetails: 'Technical details',
    standardProcedures: 'Standard procedures',
    statuses: {
      draft: 'Draft',
      active: 'Active',
      disabled: 'Disabled',
      archived: 'Archived',
    },
    actions: {
      activate: 'Activate',
      archive: 'Archive',
      disable: 'Disable',
      enable: 'Enable',
    },
    form: {
      projectCategory: {
        itself: 'Project Category',
        '*': 'Project Category *',
        required: 'Project Category is required',
      },
      category: {
        itself: 'Category',
        '*': 'Category *',
        required: 'Category is required',
        select: 'Select category',
      },
      group: {
        itself: 'Group',
        '*': 'Group *',
        required: 'Group is required',
        select: 'Select group',
      },
      equipmentGroup: {
        itself: 'Equipment group',
        '*': 'Equipment group *',
        required: 'Equipment group is required',
        select: 'Select equipment group',
        selectOrAdd: 'Select or add equipment group',
        addNew: 'Add new equipment group',
      },
      name: {
        itself: 'Name',
        '*': 'Name *',
        required: 'Name is required',
      },
      manufacturer: {
        itself: 'Manufacturer',
        '*': 'Manufacturer *',
        required: 'Manufacturer is required',
        selectOrAdd: 'Select or add manufacturer',
        addNew: 'Add new manufacturer',
      },
      model: {
        itself: 'Model',
        '*': 'Model *',
        required: 'Model is required',
        selectOrAdd: 'Select or add model',
        addNew: 'Add new model',
      },
      quantity: {
        itself: 'Quantity',
        '*': 'Quantity *',
        required: 'Please enter a number',
        enterQuantity: 'Enter quantity',
      },
      documentation: {
        itself: 'Documentation',
        '*': 'Documentation *',
        required: 'Documentation is required',
        uploadDocuments: 'Upload documents',
      },
      media: {
        itself: 'Media',
        '*': 'Media *',
        required: 'Media is required',
        uploadImagesVideos: 'Upload images & videos',
      },
      location: {
        itself: 'Location',
        '*': 'Location *',
        required: 'Location is required',
      },
      building: {
        itself: 'Building',
        '*': 'Building *',
        required: 'Building is required',
        select: 'Select building',
      },
      locations: {
        required: 'Locations are required',
      },
      level: {
        itself: 'Level',
        '*': 'Level *',
        required: 'Level is required',
        select: 'Select level',
      },
      room: {
        itself: 'Room',
        '*': 'Room *',
        required: 'Room is required',
        select: 'Select room',
      },
      input: {
        plural: {
          itself: 'Parameters',
        },
        name: {
          itself: 'Name',
          '*': 'Name *',
          required: 'Name is required',
        },
        value: {
          itself: 'Value',
          '*': 'Value *',
          required: 'Value is required',
        },
        unit: {
          itself: 'Unit',
          '*': 'Unit *',
          required: 'Unit is required',
        },
      },
      status: {
        itself: 'Status',
        '*': 'Status *',
      },
    },
    maintenanceProcedures: 'Maintenance Procedures',
    additionalProcedures: 'Additional Procedures',
    maintenancePlan: 'Maintenance Plan',
    enablingPlannedOn: 'Enabling planned on {{date}}',
    disablingPlannedOn: 'Disabling planned on {{date}}',
    logs: {
      statusWillBeChangedOn: `Status will be changed to {{status}} on {{date}}`,
      statusWasChangedOn: `Status was changed to {{status}} on {{date}}`,
      equipmentCreated: 'Equipment is created',
      equipmentLinkedToProcedure:
        '{{equipmentName}} equipment is linked to {{procedureName}} procedure',
      equipmentUnlinkedFromProcedure:
        '{{equipmentName}} equipment is unlinked from {{procedureName}} procedure',
      procedureCreated: '{{procedureName}} procedure is created',
      procedureStatusChangedTo:
        '{{procedureName}} procedure status is changed to {{status}}',
      equipmentIsLinkedToProcurement:
        '{{equipmentName}} equipment is linked to {{procurementName}} procurement',
      equipmentIsUnlinkedFromProcurement:
        '{{equipmentName}} equipment is unlinked from {{procurementName}} procurement',
      procurementStatusChangedTo:
        '{{procurementName}} procurement status is changed to {{status}}',
      equipmentIsLinkedToWarrantyTracker:
        '{{equipmentName}} equipment is linked to {{warrantyTrackerName}} warranty tracker',
      equipmentIsUnlinkedFromWarrantyTracker:
        '{{equipmentName}} equipment is unlinked from {{warrantyTrackerName}} warranty tracker',
      warrantyTrackerStatusChangedTo:
        '{{warrantyTrackerName}} warranty tracker status is changed to {{status}}',
      pmpEventStatusChangedTo:
        '{{eventName}} event status is changed to {{status}}',
      pmpDcEventStatusChangedTo:
        '{{eventName}} daily check event status is changed to {{status}}',
      pmpDcEventsResolved: '{{dailyCheckGroupName}} events are resolved',
    },
    categories: {
      heating: 'Heating',
      cooling: 'Cooling',
      ventilation: 'Ventilation',
      emergencySystem: 'Emergency system',
      sanitary: 'Sanitary',
      plumbingSystem: 'Plumbing system',
      lowVoltage: 'Low voltage',
      extraLowVoltage: 'Extra low voltage',
      other: 'Other',
    },
    table: {
      columns: {
        equipmentName: 'Equipment Name',
      },
    },
    exportEquipmentData: 'Export Equipment Data',
    exportEquipmentIncludeProcedures:
      'Include maintenance procedures in export.',
    modalDeleteMessage:
      'Are you sure you want to delete this equipment and all its information?',
    modalArchiveMessage: 'Are you sure you want archive this equipment?',
    modalActivateMessage: 'Are you sure you want activate this equipment?',
  },
};
