import ApiResourceRequest, {
  API_METHODS,
} from 'api/requestWrappers/ApiResourceRequest';
import { required } from 'utils/helpers';
import ListingResponse from '../ListingResponse';
import Pager from '../../helpers/Pager';

export class LevelRequest extends ApiResourceRequest {
  get apiResource() {
    return 'levels';
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_BUILDING_ID: `${this.apiUrl}/buildings/levels/by-building-id/{buildingId}`,
      LINK: `${this.apiUrl}/buildings/link-level-to-building`,
      UNLINK: `${this.apiUrl}/buildings/unlink-level-from-building`,
      UPDATE_POSITIONS: `${this.apiUrl}/buildings/update-level-positions`,
    };
  }

  async get(buildingId, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ buildingId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      'GET',
      this.routes.GET_BY_BUILDING_ID.replace('{buildingId}', buildingId) +
        queryString
    );

    return new ListingResponse(response?.data);
  }

  async link(buildingId, levelId) {
    required({ buildingId, levelId });

    const response = await this.request(API_METHODS.POST, this.routes.LINK, {
      buildingId,
      buildingLevelId: levelId,
    });

    return response?.data;
  }

  async unlink(buildingId, levelId) {
    required({ buildingId, levelId });

    const response = await this.request(API_METHODS.POST, this.routes.UNLINK, {
      buildingId,
      buildingLevelId: levelId,
    });

    return response?.data;
  }

  async updatePositions(items) {
    required({ items });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.UPDATE_POSITIONS,
      items
    );

    return response?.data;
  }
}

export const levelRequest = new LevelRequest();
