export default {
  level: {
    itself: 'Level',
    plural: {
      itself: 'Levels',
    },
    levelName: {
      itself: 'Level name',
      required: 'You need to set a level name first!',
    },
    sublevel: {
      itself: 'Sublevel',
    },
    sharedLevel: {
      itself: 'Shared level',
    },
    duplicateLevel: {
      itself: 'Duplicate level',
    },
    modalDeleteMessage:
      'Are you sure you want to delete this level and all its information?',
    addLevel: 'Add level',
    editLevel: 'Edit level',
  },
};
