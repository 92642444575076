import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '../alerts/Alert';

const useNetworkChecker = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleOnline = () => {
      Alert.showSuccess(t('general.online'));
    };
    const handleOffline = () => {
      Alert.showError(t('general.offline'));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('offline', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [t]);
};

export default useNetworkChecker;
