export default {
  pmp: {
    title: 'Jobs Overview',
    fetchError: 'Error',
    weekNumber: 'Week #',
    eventStates: {
      planned: 'Planned',
      opened: 'Opened',
      inProgress: 'In progress',
      onHold: 'On hold',
      resolved: 'Resolved',
      closed: 'Closed',
      overdue: 'Overdue',
      outsourced: 'Outsourced',
      archive: 'Archive',
      unarchive: 'Unarchive',
      archived: 'Archived',
      draft: 'Draft',
    },
    worklog: {
      itself: 'Worklog',
      entries: 'Worklog • {{count}} entries',
      noEntries: 'No entries to display.',
      changeStatus: 'Changed status',
      changeDate: 'Changed date',
      creatMeasurement: 'Created measurement',
      updateMeasurement: 'Updated measurement',
      createOperationData: 'Create operation data',
      updateOperationData: 'Update operation data',
      dueDateWasChangeFromTo:
        'Due date was changed from {{initial}} to {{final}}',
      statusChange: {
        planned: 'Planned',
        opened: 'Opened',
        inProgress: 'Marked in progress',
        onHold: 'Put on hold',
        resolved: 'Resolved',
        rejected: 'Rejected',
        closed: 'Closed',
        outsourced: 'Outsourced',
      },
    },
    rejectWithNote: 'Reject with note',
    delay: 'Delay',
    start: 'Start',
    resolveProcedure: 'Resolve procedure',
    putOnHold: 'Put on hold',
    continue: 'Continue',
    reject: 'Reject',
    close: 'Close',
    additionalNote: {
      itself: 'Additional note',
      '*': 'Additional note *',
      required: 'Additional note is required',
    },
    conclusions: {
      itself: 'Conclusions',
      '*': 'Conclusions *',
    },
    addAttachment: 'Add attachment',
    type: 'Type',
    subcontractor: 'Subcontractor',
    contactPersons: 'Contact person(s)',
    date: 'Date',
    procedureStart: 'Procedure start',
    procedureEnd: 'Procedure end',
    location: 'Location',
    equipment: 'Equipment',
    procedureNumber: 'Procedure #',
    overview: 'Overview',
    operationSubtitle: '{{total}} Operations • ',
    operationStates: {
      completed: 'Completed',
      notStarted: 'Not started',
      failed: 'Failed',
      na: 'N/A',
    },
    visualOperation: 'Selected value',
    visualError: 'Select one',
    discard: 'Discard',
    saveChanges: 'Save changes',
    edit: 'Edit',
    parameterOperation: 'Parameters',
    parameterError: 'A value is required',
    measurementError: {
      invalidSelection: 'The selection has generated an alert',
      lessThanMin: 'The entered value is below the minimum allowed',
      greaterThanMin: 'The entered value exceeds the maximum allowed',
    },
    feedback: 'Feedback',
    feedbackPlaceholder: 'Leave feedback ...',
    media: 'Media',
    selectNewDate: {
      itself: 'Select new date',
      '*': 'Select new date *',
    },
    moveEventModal: {
      cancel: 'No, cancel!',
      confirm: 'Yes, move!',
      message:
        'Are you sure you want to move event "{{eventName}}" to week #{{weekNumber}}?',
    },
    tour: {
      status: {
        planned: 'Planned',
        opened: 'Open',
        inProgress: 'In progress',
        resolved: 'Resolved',
        closed: 'Closed',
      },
    },
    exportMaintenancePlan: 'Export Maintenance Plan',
    includeProcedureStatusInExport: 'Include procedures status in export.',
    currentJobs: 'Current Jobs',
    fullView: 'Full View',
    weekly: 'Weekly',
    noPmpEventsYet: 'No PMP events yet',
    viewOperations: 'View Operations',
    loadPreviousWeek: {
      itself: 'Load previous week',
      plural: 'Load previous weeks',
    },
  },
};
