import AbstractModel from '../AbstractModel';
import { STATUS } from '../../../utils/constants';
import DailyCheckProceduresOperationsModel from './DailyCheckProceduresOperationsModel';

class DailyCheckProceduresModel extends AbstractModel {
  constructor(...params) {
    super(...params);
    if (this.status !== STATUS.LOADING && Array.isArray(this.operations)) {
      this.dailyCheckProcedureOperations = this.operations.map(
        (operation) => new DailyCheckProceduresOperationsModel({ ...operation })
      );
    }
  }
}

export default DailyCheckProceduresModel;
