import ApiErrorModel from 'api/models/ApiErrorModel';

export default {
  codes: {
    default: 'Something went wrong...',
    [ApiErrorModel.ERROR_CODES.BUILDING_NOT_FOUND]: 'The building is not found',
    [ApiErrorModel.ERROR_CODES.BUILDING_LEVEL_NOT_FOUND]:
      'The building level is not found',
    [ApiErrorModel.ERROR_CODES.BUILDING_ROOM_NOT_FOUND]:
      'The building room is not found',
    [ApiErrorModel.ERROR_CODES.PROJECT_NOT_FOUND]: 'The project is not found',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_NOT_FOUND]:
      'The equipment is not found',
    [ApiErrorModel.ERROR_CODES.ORGANIZATION_NOT_FOUND]:
      'The organization is not found',
    [ApiErrorModel.ERROR_CODES.USER_NOT_FOUND]: 'The user is not found',
    [ApiErrorModel.ERROR_CODES.DAILYCHECK_OPERATION_NOT_FOUND]:
      'The dailycheck operation is not found',
    [ApiErrorModel.ERROR_CODES.DAILYCHECK_PROCEDURE_NOT_FOUND]:
      'The dailycheck procedure is not found',
    [ApiErrorModel.ERROR_CODES.UNIT_NOT_FOUND]: 'The unit is not found',
    [ApiErrorModel.ERROR_CODES.MAINTENANCE_OPERATION_NOT_FOUND]:
      'The maintenance operation is not found',
    [ApiErrorModel.ERROR_CODES.MAINTENANCE_PROCEDURE_NOT_FOUND]:
      'The maintenance procedure is not found',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_CATEGORY_GROUP_NOT_FOUND]:
      'The equipment category group is not found',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_MODEL_NOT_FOUND]:
      'The equipment model is not found',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_MANUFACTURER_NOT_FOUND]:
      'The equipment manufacturer is not found',
    [ApiErrorModel.ERROR_CODES.SUBCONTRACTOR_NOT_FOUND]:
      'The subcontractor is not found',
    [ApiErrorModel.ERROR_CODES.COUNTRY_NOT_FOUND]: 'The country is not found',
    [ApiErrorModel.ERROR_CODES.SOURCE_EQUIPMENT_NOT_FOUND]:
      'The source equipment is not found',
    [ApiErrorModel.ERROR_CODES.CHAT_NOT_FOUND]: 'The chat is not found',
    [ApiErrorModel.ERROR_CODES.PROJECT_CATEGORY_NOT_FOUND]:
      'The project category is not found',
    [ApiErrorModel.ERROR_CODES.STANDARD_CATEGORY_GROUP_NOT_FOUND]:
      'The standard category group is not found',
    [ApiErrorModel.ERROR_CODES.BUILDING_IS_NOT_PART_OF_PROJECT]:
      'The building is not part of the project',
    [ApiErrorModel.ERROR_CODES.BUILDING_LEVEL_IS_NOT_PART_OF_BUILDING]:
      'The building level is not part of the building',
    [ApiErrorModel.ERROR_CODES.BUILDING_ROOM_IS_NOT_PART_OF_BUILDING]:
      'The building room is not part of the building',
    [ApiErrorModel.ERROR_CODES.PROJECT_ID_NOT_SET]: 'The project id is not set',
    [ApiErrorModel.ERROR_CODES.INVALID_FILE_SIZE]: 'Invalid file size',
    [ApiErrorModel.ERROR_CODES.THUMBNAIL_NOT_SUPPORTED_FOR_FILE_TYPE]:
      'The thumbnail is not supported for the file type',
    [ApiErrorModel.ERROR_CODES.PROJECT_NEEDS_START_DATE_SET]:
      'The project should have start date set',
    [ApiErrorModel.ERROR_CODES.INVALID_MAINTENANCE_PROCEDURE_ID]:
      'Invalid maintenance procedure id',
    [ApiErrorModel.ERROR_CODES.INVALID_EVENT_ID]: 'Invalid event id',
    [ApiErrorModel.ERROR_CODES.INVALID_OPERATION_ID]: 'Invalid operation id',
    [ApiErrorModel.ERROR_CODES.INVALID_LABEL_ID]: 'Invalid label id',
    [ApiErrorModel.ERROR_CODES.INVALID_PARAMETER_ID]: 'Invalid parameter id',
    [ApiErrorModel.ERROR_CODES.INVALID_DATES]: 'Invalid dates',
    [ApiErrorModel.ERROR_CODES.INVALID_PROJECT_ID]: 'Invalid project id',
    [ApiErrorModel.ERROR_CODES.USER_MUST_BE_PART_OF_ORGANIZATION]:
      'User should be a part of the organization',
    [ApiErrorModel.ERROR_CODES.INVALID_ORGANIZATION_TYPE]:
      'Invalid organization type',
    [ApiErrorModel.ERROR_CODES.USER_DOES_NOT_HAVE_A_VALID_ROLE]:
      'The user does not have a valid role',
    [ApiErrorModel.ERROR_CODES.USER_TO_ASSIGN_NOT_FOUND]:
      'Assigning failed. The user is not found',
    [ApiErrorModel.ERROR_CODES
      .CHAT_DOES_NOT_EXIST_OR_USER_IS_NOT_A_PARTICIPANT]:
      'The chat does not exist or the user is not a participant',
    [ApiErrorModel.ERROR_CODES.YOU_CAN_ADD_LABELS_ONLY_TO_VISUAL_OPERATION]:
      'You can add labels only to visual operation',
    [ApiErrorModel.ERROR_CODES
      .YOU_CAN_ADD_PARAMETERS_ONLY_TO_PARAMETER_OPERATION]:
      'You can add parameters only to parameter operation',
    [ApiErrorModel.ERROR_CODES
      .YOU_NEED_TO_SET_EITHER_EQUIPMENT_OR_EQUIPMENT_CATEGORY_GROUP_FOR_DAILYCHECK_PROCEDURE]:
      'You need to set either equipment or equipment category group for dailycheck procedure',
    [ApiErrorModel.ERROR_CODES.PARENT_ORGANIZATION_TYPE_MUST_BE_PROVIDER]:
      'A organization type parent should be provided',
    [ApiErrorModel.ERROR_CODES.YOU_NEDD_TO_SET_EITHER_A_LABEL_OR_A_PARAMETER]:
      'You need to set either a label or a parameter',
    [ApiErrorModel.ERROR_CODES.USER_TO_ASIGN_IS_NOT_PART_OF_AN_ORGANIZATION]:
      'Assigning is failed. The user is not a part of an organization',
    [ApiErrorModel.ERROR_CODES.YOU_CAN_ACTIVATE_ONLY_PROJECTS_IN_DRAFT_STATE]:
      'Only draft state projects are able to be activated',
    [ApiErrorModel.ERROR_CODES.INITIATOR_USER_NOT_FOUND]:
      'The initiator user is not found',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING]:
      'It is not able to delete the building',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING_FK_CONSTRAINT]:
      'Unable to delete the building FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING_LEVEL]:
      'Unable to delete the building level',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING_LEVEL_FK_CONSTRAINT]:
      'Unable to delete the building level FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING_ROOM]:
      'Unable to delete the building room',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_BUILDING_ROOM_FK_CONSTRAINT]:
      'Unable to delete the building room FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_ORGANIZATION]:
      'Unable to delete the organization',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_ORGANIZATION_FK_CONSTRAINT]:
      'Unable to delete the organization FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_SUBCONTRACTOR]:
      'Unable to delete the subcontractor',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_SUBCONTRACTOR_FK_CONSTRAINT]:
      'Unable to delete the subcontractor FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_USER]:
      'Unable to delete the user',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_USER_FK_CONSTRAINT]:
      'Unable to delete the user FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_EQUIPMENT]:
      'Unable to delete the equipment',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_EQUIPMENT_FK_CONSTRAINT]:
      'Unable to delete the equipment FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_EQUIPMENT_NOT_DELETABLE]:
      'Unable to delete. The equipment is not deletable',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_EQUIPMENT_READ_ONLY]:
      'Unable to delete. The equipment is readonly',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_IS_READ_ONLY]:
      'The equipment is readonly',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_PROJECT]:
      'Unable to delete the project',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_PROJECT_FK_CONSTRAINT]:
      'Unable to delete the project FK CONSTRAINT',
    [ApiErrorModel.ERROR_CODES.USER_NOT_FOUND_IN_PROJECT]:
      'The user is not found in the project',
    [ApiErrorModel.ERROR_CODES
      .TICKET_UPDATE_NEED_TO_SPECIFY_DUE_DATE_IF_CHANGING_PRIORITY]:
      'The ticket update is failed. It is necessary to provide a due date to change the priority',
    [ApiErrorModel.ERROR_CODES.TICKET_NOT_FOUND]: 'The ticket is not found',
    [ApiErrorModel.ERROR_CODES.TICKET_RECIPIENT_MUST_BE_SET]:
      'The ticket recipient is required',
    [ApiErrorModel.ERROR_CODES.INTERNAL_CHAT_IS_ONY_FOR_PROVIDER_USERS]:
      'The internal chat is only for providers',
    [ApiErrorModel.ERROR_CODES.USER_ALREADY_ACTIVE_IN_CHAT]:
      'The user is already active in the chat',
    [ApiErrorModel.ERROR_CODES.USER_NOT_ACTIVE_IN_CHAT]:
      'The user is not active in the chat',
    [ApiErrorModel.ERROR_CODES.EQUIPMENT_CATEGORY_NOT_FOUND]:
      'The equipment category is not found',
    [ApiErrorModel.ERROR_CODES.PROCUREMENT_NOT_FOUND]:
      'The procurement is not found',
    [ApiErrorModel.ERROR_CODES.FILE_NOT_FOUND]: 'The file is not found',
    [ApiErrorModel.ERROR_CODES.EMAIL_IN_USE]: 'The email is already in use',
    [ApiErrorModel.ERROR_CODES.DAILYCHECK_GROUP_NOT_FOUND]:
      'The dailycheck group is not found',
    [ApiErrorModel.ERROR_CODES.MUST_DEFINE_DAILYCHECK_GROUP]:
      'You must define daily check groups and procedures',
    [ApiErrorModel.ERROR_CODES.DAILYCHECK_GROUP_EXISTS]:
      'The dailycheck group exists',
    [ApiErrorModel.ERROR_CODES.WORK_ORDER_NOT_FOUND]:
      'The work order is not found',
    [ApiErrorModel.ERROR_CODES.TICKET_INVALID_ORGANIZATION_TYPE]:
      'The ticket has an invalid organization type',
    [ApiErrorModel.ERROR_CODES.RESTRICTED_FROM_EXTERNAL_CHAT]:
      'It was restricted from external chat',
    [ApiErrorModel.ERROR_CODES
      .YOU_NEED_TO_HAVE_AT_LEAST_ONE_USER_FROM_THE_RECIPIENT_ORG_IN_CHAT]:
      'You need to have at least one user from the recipient organization in chat',
    [ApiErrorModel.ERROR_CODES.TICKET_STATUS_CHANGE_INVALID]:
      'The new ticket status is not valid',
    [ApiErrorModel.ERROR_CODES.USER_NOT_IN_ORGANIZATION]:
      'The user is not in the organization',
    [ApiErrorModel.ERROR_CODES.DC_EVENT_NOT_FOUND]: 'The DC event is not found',
    [ApiErrorModel.ERROR_CODES.PERSONAL_AGENDA_DOES_NOT_EXIST]:
      'The personal agenda does not exist',
    [ApiErrorModel.ERROR_CODES
      .PERSONAL_AGENDA_REMINDER_DATE_MUST_BE_BEFORE_START_DATE]:
      'The personal agenda reminder date should be before the start date',
    [ApiErrorModel.ERROR_CODES
      .PERSONAL_AGENDA_MUST_RECEIVE_BOTH_START_AND_END_DATES]:
      'The personal agenda should receive both start and end dates',
    [ApiErrorModel.ERROR_CODES
      .PERSONAL_AGENDA_START_DATE_MUST_BE_BEFORE_END_DATE]:
      'The personal agenda start date should be before the end date',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_PERSONAL_AGENDA]:
      'Unable to delete the personal agenda',
    [ApiErrorModel.ERROR_CODES.YOU_CAN_ARCHIVE_ONLY_PROJECTS_IN_ACTIVE_STATE]:
      'You can archive only projects in active state',
    [ApiErrorModel.ERROR_CODES.STANDARD_PROJECT_CATEGORY_EXISTS]:
      'Standard project category already exist',
    [ApiErrorModel.ERROR_CODES.USER_CANNOT_BE_ACTIVATED]:
      'User cannot be activated',
    [ApiErrorModel.ERROR_CODES.USER_TO_UPDATE_HAS_HIGHER_CLEARANCE]:
      'User has higher clearance',
    [ApiErrorModel.ERROR_CODES.USER_TO_DEACTIVATE_HAS_OPEN_TICKETS]:
      'User has opened tickets',
    [ApiErrorModel.ERROR_CODES.USER_TO_DEACTIVATE_HAS_OPEN_PROCUREMENTS]:
      'User has opened procurements',
    [ApiErrorModel.ERROR_CODES.USER_TO_DEACTIVATE_HAS_UNIQUE_ROLE_IN_PROJECT]:
      'User has unique role in project',
    [ApiErrorModel.ERROR_CODES.BOTH_EQUIPMENTS_MUST_BE_IN_SAME_PROJECT]:
      'Both equipments should be in the same project',
    [ApiErrorModel.ERROR_CODES.DAILYCHECK_NOT_FOUND]: 'Dailycheck is not found',
    [ApiErrorModel.ERROR_CODES.PMP_EVENT_NOT_FOUND]: 'Pmp event is not found',
    [ApiErrorModel.ERROR_CODES
      .YOU_CAN_DISABLE_MAINTENANCE_PROCEDURES_ONLY_IN_ACTIVE_PRJ]:
      'You can disable maintenance procedures only in active project',
    [ApiErrorModel.ERROR_CODES
      .YOU_CAN_ENABLE_MAINTENANCE_PROCEDURES_ONLY_IN_ACTIVE_PRJ]:
      'You can enable maintenance procedures only in active project',
    [ApiErrorModel.ERROR_CODES.CHAT_MESSAGE_NOT_FOUND]: 'Message is not found',
    [ApiErrorModel.ERROR_CODES.CHAT_MESSAGE_AUTH_USER_NOT_OWNER_OF_MESSAGE]:
      'User is not owner of the message',
    [ApiErrorModel.ERROR_CODES.CHAT_IS_READ_ONLY]: 'Chat is read only',
    [ApiErrorModel.ERROR_CODES.COULD_NOT_SEND_EMAIL]: 'Could not send email',
    [ApiErrorModel.ERROR_CODES.PROCUREMENT_INVALID_ORGANIZATION_TYPE]:
      'The procurement has an invalid organization type',
    [ApiErrorModel.ERROR_CODES.INVENTORY_ITEM_NOT_FOUND]:
      'Inventory item is not found',
    [ApiErrorModel.ERROR_CODES.INVENTORY_ITEM_DELETE_ERROR]:
      'Unable to delete an inventory item',
    [ApiErrorModel.ERROR_CODES.INVENTORY_INVALID_QUANTITY]:
      'The inventory has an invalid quantity',
    [ApiErrorModel.ERROR_CODES.INVENTORY_USER_UNAUTHORIZED_TO_MANAGE_TOOLS]:
      'User unauthorized to manage tools',
    [ApiErrorModel.ERROR_CODES.UNABLE_TO_DELETE_UNIT]: 'This unit is in use',
  },
};
