import {
  GENERAL_CHAT_MESSAGES_ACTION_TYPES,
  GENERAL_CHAT_PROJECTS_ACTION_TYPES,
} from '../constants';
import { required } from '../../../utils/helpers';
import ChatModel from '../../../realTimeApi/models/ChatModel';
import ChatPager from '../../../realTimeApi/helpers/ChatPager';
import TypesHelper from '../../../utils/types/TypesHelper';

/** Message chats */

export const generalChatMessagesConversationActionCreators = Object.freeze({
  setConversations(conversations, loggedInUserId) {
    required(conversations, loggedInUserId);

    return {
      type: GENERAL_CHAT_MESSAGES_ACTION_TYPES.SET_CONVERSATIONS,
      payload: {
        conversations,
        loggedInUserId,
      },
    };
  },

  update(conversationId, updateObj = {}) {
    required(conversationId, updateObj);

    return {
      type: GENERAL_CHAT_MESSAGES_ACTION_TYPES.UPDATE_SPECIFIED_CONVERSATION,
      payload: {
        conversationId,
        updateObj,
      },
    };
  },

  updateHistory(conversationId, history, total, pagerFromApi) {
    required(conversationId, history, total, pagerFromApi);

    ChatPager.checkInstance(pagerFromApi);

    return {
      type: GENERAL_CHAT_MESSAGES_ACTION_TYPES.UPDATE_CONVERSATION_HISTORY,
      payload: {
        conversationId,
        history,
        total,
        pagerFromApi,
      },
    };
  },

  updateConversationInfo(conversationId, channelItemModel) {
    required(conversationId, channelItemModel);

    if (!(channelItemModel instanceof ChatModel)) {
      throw new Error(
        'channelItemModel should be instance of ChatChannelItemModel'
      );
    }

    return this.update(conversationId, { conversationInfo: channelItemModel });
  },

  updateNextPager(conversationId, chatPager) {
    // no longer in use
    if (chatPager instanceof ChatPager || chatPager === undefined) {
      return this.update(conversationId, { nextPager: chatPager });
    }

    throw new Error('pager should be an instance of ChatPager or undefined');
  },

  addNewMessage(conversationId, newMessageObj) {
    required(conversationId, newMessageObj);

    return {
      type: GENERAL_CHAT_MESSAGES_ACTION_TYPES.ADD_NEW_HISTORY_MESSAGE,
      payload: {
        newMessageObj,
        conversationId,
      },
    };
  },

  removeMessage(conversationId, messageId) {
    required(conversationId, messageId);

    return {
      type: GENERAL_CHAT_MESSAGES_ACTION_TYPES.REMOVE_HISTORY_MESSAGE,
      payload: {
        conversationId,
        messageId,
      },
    };
  },

  updateLastReadMessageIdUsingHistory(conversationId, reset = false) {
    required(conversationId);

    return {
      type:
        GENERAL_CHAT_MESSAGES_ACTION_TYPES.UPDATE_LAST_READ_MESSAGE_USING_HISTORY,
      payload: {
        conversationId,
        reset,
      },
    };
  },

  updateLastReadMessageId(conversationId, lastReadMessageId) {
    required(conversationId);

    return {
      type: GENERAL_CHAT_MESSAGES_ACTION_TYPES.UPDATE_LAST_READ_MESSAGE,
      payload: {
        conversationId,
        lastReadMessageId,
      },
    };
  },

  updateUnreadMessagesIds(conversationId, unreadMessagesIds) {
    required(conversationId, unreadMessagesIds);

    return {
      type:
        GENERAL_CHAT_MESSAGES_ACTION_TYPES.UPDATE_CONVERSATION_UNREAD_MESSAGES_IDS,
      payload: {
        conversationId,
        unreadMessagesIds,
      },
    };
  },

  setHistoryLastReadMessage(conversationId) {
    required(conversationId);

    return {
      type: GENERAL_CHAT_MESSAGES_ACTION_TYPES.SET_HISTORY_LAST_READ_MESSAGE,
      payload: {
        conversationId,
      },
    };
  },

  removeConversation(conversationId) {
    required(conversationId);

    return {
      type: GENERAL_CHAT_MESSAGES_ACTION_TYPES.REMOVE_CONVERSATION,
      payload: {
        conversationId,
      },
    };
  },

  removeParticipantFromConversation(conversationId, participantId) {
    required(conversationId, participantId);

    return {
      type:
        GENERAL_CHAT_MESSAGES_ACTION_TYPES.REMOVE_PARTICIPANT_FROM_CONVERSATION,
      payload: {
        conversationId,
        participantId,
      },
    };
  },

  addParticipantToConversation(conversationId, participant) {
    required(conversationId, participant);

    return {
      type: GENERAL_CHAT_MESSAGES_ACTION_TYPES.ADD_PARTICIPANT_TO_CONVERSATION,
      payload: {
        conversationId,
        participant,
      },
    };
  },

  addLastReadMessageToEachConversationHistory(chats) {
    required(chats);

    if (!TypesHelper.isArray(chats)) {
      throw new Error('chats should be array');
    }

    if (chats?.length) {
      const firstConversation = chats[0];

      if (
        !firstConversation.id ||
        // eslint-disable-next-line no-prototype-builtins
        !firstConversation.hasOwnProperty('lastReadMessage')
      ) {
        throw new Error('chat should have at least id and lastReadMessage');
      }
    }

    return {
      type:
        GENERAL_CHAT_MESSAGES_ACTION_TYPES.ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY,
      payload: {
        chats,
      },
    };
  },
});

/** Project chats */

export const generalChatProjectsConversationActionsCreators = Object.freeze({
  setConversations(conversations, loggedInUserId) {
    required(conversations, loggedInUserId);

    return {
      type: GENERAL_CHAT_PROJECTS_ACTION_TYPES.SET_CONVERSATIONS,
      payload: {
        conversations,
        loggedInUserId,
      },
    };
  },

  updateConversationInfo: (projectId, conversationId, newConversationInfo) => {
    required(projectId, conversationId, newConversationInfo);

    return {
      type: GENERAL_CHAT_PROJECTS_ACTION_TYPES.UPDATE_CONVERSATION_INFO,
      payload: {
        projectId,
        conversationId,
        newConversationInfo,
      },
    };
  },

  removeMessage: (projectId, conversationId, messageId) => {
    required(projectId, conversationId, messageId);

    return {
      type: GENERAL_CHAT_PROJECTS_ACTION_TYPES.REMOVE_HISTORY_MESSAGE,
      payload: {
        projectId,
        conversationId,
        messageId,
      },
    };
  },

  updateHistory(projectId, conversationId, history, total, pagerFromApi) {
    required(projectId, conversationId, history);

    return {
      type: GENERAL_CHAT_PROJECTS_ACTION_TYPES.UPDATE_CONVERSATION_HISTORY,
      payload: {
        projectId,
        conversationId,
        history,
        total,
        pagerFromApi,
      },
    };
  },

  addNewMessage(projectId, conversationId, newMessageObj) {
    required(projectId, conversationId, newMessageObj);

    return {
      type: GENERAL_CHAT_PROJECTS_ACTION_TYPES.ADD_NEW_HISTORY_MESSAGE,
      payload: {
        newMessageObj,
        projectId,
        conversationId,
      },
    };
  },

  updateLastReadMessageIdUsingHistory(
    projectId,
    conversationId,
    reset = false
  ) {
    required(projectId, conversationId);

    return {
      type:
        GENERAL_CHAT_PROJECTS_ACTION_TYPES.UPDATE_LAST_READ_MESSAGE_USING_HISTORY,
      payload: {
        projectId,
        conversationId,
        reset,
      },
    };
  },

  updateLastReadMessageId(projectId, conversationId, lastReadMessageId) {
    required(projectId, conversationId);

    return {
      type: GENERAL_CHAT_PROJECTS_ACTION_TYPES.UPDATE_LAST_READ_MESSAGE,
      payload: {
        projectId,
        conversationId,
        lastReadMessageId,
      },
    };
  },

  updateUnreadMessagesIds(projectId, conversationId, unreadMessagesIds) {
    required(projectId, conversationId, unreadMessagesIds);

    return {
      type:
        GENERAL_CHAT_PROJECTS_ACTION_TYPES.UPDATE_CONVERSATION_UNREAD_MESSAGES_IDS,
      payload: {
        projectId,
        conversationId,
        unreadMessagesIds,
      },
    };
  },

  setHistoryLastReadMessage(projectId, conversationId) {
    required(projectId, conversationId);

    return {
      type: GENERAL_CHAT_PROJECTS_ACTION_TYPES.SET_HISTORY_LAST_READ_MESSAGE,
      payload: {
        conversationId,
        projectId,
      },
    };
  },

  addLastReadMessageToEachConversationHistory(projects) {
    required(projects);

    return {
      type:
        GENERAL_CHAT_PROJECTS_ACTION_TYPES.ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY,
      payload: {
        projects,
      },
    };
  },
});
