import { required } from '../../../../utils/helpers';

export default (resourceChatActionTypes) => {
  return {
    setResource(resourceId, resourceInfo = {}) {
      required({ resourceId, resourceInfo });

      return {
        type: resourceChatActionTypes.SET_RESOURCE,
        payload: {
          resourceId,
          resourceInfo,
        },
      };
    },

    setChat(resourceId, chatId, chatInfo, loggedInUser) {
      required({ resourceId, chatId, chatInfo, loggedInUser });

      return {
        type: resourceChatActionTypes.SET_CHAT,
        payload: {
          resourceId,
          chatId,
          chatInfo,
          loggedInUser,
        },
      };
    },

    setChatHistory(resourceId, chatId, history, pagerFromApi, total) {
      required({ resourceId, chatId, history, pagerFromApi, total });

      return {
        type: resourceChatActionTypes.SET_CHAT_HISTORY,
        payload: {
          resourceId,
          chatId,
          history,
          pagerFromApi,
          total,
        },
      };
    },

    addMessage(resourceId, chatId, message) {
      required({ resourceId, chatId, message });

      return {
        type: resourceChatActionTypes.ADD_MESSAGE,
        payload: {
          resourceId,
          chatId,
          message,
        },
      };
    },

    removeMessage(resourceId, chatId, messageId) {
      required({ resourceId, chatId, messageId });

      return {
        type: resourceChatActionTypes.REMOVE_MESSAGE,
        payload: {
          resourceId,
          chatId,
          messageId,
        },
      };
    },

    updateUnreadMessages(resourceId, chatId, unreadMessagesIds) {
      required({ resourceId, chatId, unreadMessagesIds });

      return {
        type: resourceChatActionTypes.UPDATE_UNREAD_MESSAGES,
        payload: {
          resourceId,
          chatId,
          unreadMessagesIds,
        },
      };
    },

    addParticipant(resourceId, chatId, participant) {
      required({ resourceId, chatId, participant });

      return {
        type: resourceChatActionTypes.ADD_PARTICIPANT,
        payload: {
          resourceId,
          chatId,
          participant,
        },
      };
    },

    removeParticipant(resourceId, chatId, participantId) {
      required({ resourceId, chatId, participantId });

      return {
        type: resourceChatActionTypes.REMOVE_PARTICIPANT,
        payload: {
          resourceId,
          chatId,
          participantId,
        },
      };
    },

    setHistoryLastReadMessage(resourceId, chatId) {
      required(resourceId, chatId);

      return {
        type: resourceChatActionTypes.SET_HISTORY_LAST_READ_MESSAGE,
        payload: {
          chatId,
          resourceId,
        },
      };
    },

    updateLastReadMessageIdUsingHistory(resourceId, chatId, reset = false) {
      required(resourceId, chatId);

      return {
        type: resourceChatActionTypes.UPDATE_LAST_READ_MESSAGE_USING_HISTORY,
        payload: {
          resourceId,
          chatId,
          reset,
        },
      };
    },

    updateLastReadMessageId(resourceId, chatId, lastReadMessageId) {
      required(resourceId, chatId, lastReadMessageId);

      return {
        type: resourceChatActionTypes.UPDATE_LAST_READ_MESSAGE,
        payload: {
          resourceId,
          chatId,
          lastReadMessageId,
        },
      };
    },

    addLastReadMessageToEachHistory(chats) {
      required(chats);

      return {
        type:
          resourceChatActionTypes.ADD_LAST_READ_MESSAGE_TO_EACH_CONVERSATION_HISTORY,
        payload: {
          chats,
        },
      };
    },
  };
};
