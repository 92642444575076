import React from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import useChangeWithDebounce from '../../helpers/useChangeWithDebounce';

const ContainerTextField = ({
  debounceMsTime,
  value: valueFromParent,
  onChange: onChangeFromParent,
  ...textFieldProps
}) => {
  const [textValue, onChange] = useChangeWithDebounce(
    valueFromParent,
    onChangeFromParent,
    debounceMsTime
  );

  return (
    <TextField {...textFieldProps} value={textValue} onChange={onChange} />
  );
};

ContainerTextField.propTypes = {
  debounceMsTime: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
ContainerTextField.defaultProps = {
  debounceMsTime: 250,
  onChange() {},
  value: undefined,
};

export default ContainerTextField;
