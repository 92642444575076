import AbstractEventCatcher from '../AbstractEventCatcher';
import { ticketChatActionCreators } from '../../../store/chat/resource/actions';
import TicketChatHistoryMessageModel from '../../models/TicketChatHistoryMessageModel';
import eventBus, { EVENT_LIST } from '../../../utils/eventBus/eventBus';

export default class TicketChatSendMessageEventCatcher extends AbstractEventCatcher {
  static process({ messageInfo, dispatch, loggedInUser }) {
    /** @dima todo ask be to align models */

    const ticketId = messageInfo.ticketId || messageInfo.metadata?.ticketId;
    const chatId = messageInfo.chatId || messageInfo.chat?.id;

    // eslint-disable-next-line no-prototype-builtins
    const message = messageInfo.message?.hasOwnProperty('type')
      ? messageInfo.message
      : messageInfo;

    dispatch(ticketChatActionCreators.addMessage(ticketId, chatId, message));

    if (TicketChatHistoryMessageModel.getIsStatusChangeMessage(message)) {
      eventBus.$emit(EVENT_LIST.COMPONENTS.REAL_TIME.TICKET.STATUS_CHANGED, {
        status:
          TicketChatHistoryMessageModel.TYPES_JOINED_WITH_STATUSES[
            message.type
          ],
        metadata: message.metadata,
      });
    }

    if (loggedInUser?.id !== message.metadata?.userId) {
      if (
        TicketChatHistoryMessageModel.getIsProcurementLinkingMessage(message)
      ) {
        // eslint-disable-next-line default-case
        switch (message.type) {
          case TicketChatHistoryMessageModel.TYPES
            .SYSTEM_TICKET_LINKED_TO_PROCUREMENT_ID:
          case TicketChatHistoryMessageModel.TYPES
            .SYSTEM_TICKET_LINKED_PROCUREMENT_CREATED:
            eventBus.$emit(
              EVENT_LIST.COMPONENTS.REAL_TIME.TICKET.PROCUREMENT_LINKED,
              message.metadata?.procurementId
            );
            break;

          case TicketChatHistoryMessageModel.TYPES
            .SYSTEM_TICKET_UNLINKED_FROM_PROCUREMENT_ID:
          case TicketChatHistoryMessageModel.TYPES
            .SYSTEM_TICKET_LINKED_PROCUREMENT_CLOSED:
            eventBus.$emit(
              EVENT_LIST.COMPONENTS.REAL_TIME.TICKET.PROCUREMENT_UNLINKED,
              message.metadata?.procurementId
            );
            break;
        }
      }
    }
  }
}
