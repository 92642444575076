import AbstractEventCatcher from '../AbstractEventCatcher';
import { ticketChatActionCreators } from '../../../store/chat/resource/actions';
import ChatPager from '../../helpers/ChatPager';

export default class TicketChatHistoryEventCatcher extends AbstractEventCatcher {
  static process({ historyInfo, dispatch }) {
    const pagerFromApi = new ChatPager(
      ChatPager.calculatePage(historyInfo.offset, historyInfo.limit),
      historyInfo.limit
    );

    dispatch(
      ticketChatActionCreators.setChatHistory(
        historyInfo.ticketId,
        historyInfo.chatId,
        historyInfo.messages.messages,
        pagerFromApi,
        historyInfo.messages.total
      )
    );
  }
}
