import { createActions, createActionTypesConfig } from '../../helpers';

const EQUIPMENT_LABEL = 'equipment';

export const GET_ADDITIONAL_PROCEDURES = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'GET_ADDITIONAL_PROCEDURES'
);

export const GET_STANDARD_PROCEDURES = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'GET_STANDARD_PROCEDURES'
);

export const GET_EQUIPMENT_PMP_EVENTS_ACTION_TYPES_CONFIG = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'GET_EQUIPMENT_PMP_EVENTS'
);

export const FIND_EQUIPMENT_PROCEDURE_ACTION_TYPES_CONFIG = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'FIND_EQUIPMENT_PROCEDURE'
);

export const CREATE_EQUIPMENT_PROCEDURE_ACTION_TYPES_CONFIG = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'CREATE_EQUIPMENT_PROCEDURE'
);

export const UPDATE_EQUIPMENT_PROCEDURE_ACTION_TYPES_CONFIG = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'UPDATE_EQUIPMENT_PROCEDURE'
);

export const DELETE_EQUIPMENT_PROCEDURE_ACTION_TYPES_CONFIG = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'DELETE_EQUIPMENT_PROCEDURE'
);

export const GET_EQUIPMENT_PROCEDURE_OPERATIONS_ACTION_TYPES_CONFIG = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'GET_EQUIPMENT_PROCEDURE_OPERATIONS'
);

export const ENABLE_EQUIPMENT_ACTION_TYPES_CONFIG = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'ENABLE_EQUIPMENT'
);

export const DISABLE_EQUIPMENT_ACTION_TYPES_CONFIG = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'DISABLE_EQUIPMENT'
);

export const ARCHIVE_EQUIPMENT_ACTION_TYPES_CONFIG = createActionTypesConfig(
  EQUIPMENT_LABEL,
  'ARCHIVE_EQUIPMENT'
);

export const GET = Object.freeze({
  REQUEST: 'equipment/GET_REQUEST',
  SUCCESS: 'equipment/GET_SUCCESS',
  ERROR: 'equipment/GET_ERROR',
});

export const GET_BY_PROJECT_ID = Object.freeze({
  REQUEST: 'equipment/GET_BY_PROJECT_ID_REQUEST',
  SUCCESS: 'equipment/GET_BY_PROJECT_ID_SUCCESS',
  ERROR: 'equipment/GET_BY_PROJECT_ID_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'equipment/FIND_REQUEST',
  SUCCESS: 'equipment/FIND_SUCCESS',
  ERROR: 'equipment/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'equipment/CREATE_REQUEST',
  SUCCESS: 'equipment/CREATE_SUCCESS',
  ERROR: 'equipment/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'equipment/UPDATE_REQUEST',
  SUCCESS: 'equipment/UPDATE_SUCCESS',
  ERROR: 'equipment/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'equipment/DELETE_REQUEST',
  SUCCESS: 'equipment/DELETE_SUCCESS',
  ERROR: 'equipment/DELETE_ERROR',
});

export const RESET_STATE = 'equipment/RESET_STATE';

export const GET_STANDARD_PROCEDURES_ACTION = createActions(
  GET_STANDARD_PROCEDURES
);

export const GET_ADDITIONAL_PROCEDURES_ACTION = createActions(
  GET_ADDITIONAL_PROCEDURES
);

export const GET_EQUIPMENTS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const GET_EQUIPMENTS_BY_PROJECT_ID_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_PROJECT_ID.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_PROJECT_ID.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_PROJECT_ID.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_EQUIPMENT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_EQUIPMENT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_EQUIPMENT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_EQUIPMENT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});

export const GET_EQUIPMENT_PMP_EVENTS_ACTION = createActions(
  GET_EQUIPMENT_PMP_EVENTS_ACTION_TYPES_CONFIG
);

export const FIND_EQUIPMENT_PROCEDURE_ACTION = createActions(
  FIND_EQUIPMENT_PROCEDURE_ACTION_TYPES_CONFIG
);

export const UPDATE_EQUIPMENT_PROCEDURE_ACTION = createActions(
  UPDATE_EQUIPMENT_PROCEDURE_ACTION_TYPES_CONFIG
);

export const CREATE_EQUIPMENT_PROCEDURE_ACTION = createActions(
  CREATE_EQUIPMENT_PROCEDURE_ACTION_TYPES_CONFIG
);

export const DELETE_EQUIPMENT_PROCEDURE_ACTION = createActions(
  DELETE_EQUIPMENT_PROCEDURE_ACTION_TYPES_CONFIG
);

export const GET_EQUIPMENT_PROCEDURE_OPERATIONS_ACTION = createActions(
  GET_EQUIPMENT_PROCEDURE_OPERATIONS_ACTION_TYPES_CONFIG
);

export const ENABLE_EQUIPMENT_ACTION = createActions(
  ENABLE_EQUIPMENT_ACTION_TYPES_CONFIG
);

export const DISABLE_EQUIPMENT_ACTION = createActions(
  DISABLE_EQUIPMENT_ACTION_TYPES_CONFIG
);

export const ARCHIVE_EQUIPMENT_ACTION = createActions(
  ARCHIVE_EQUIPMENT_ACTION_TYPES_CONFIG
);
