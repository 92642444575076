import { createGlobalStyle } from 'styled-components';
import {
  APP_CONTAINER_ID,
  PRINTABLE_CONTAINER_ID,
} from './components/print/constants';

const GlobalStyles = createGlobalStyle`
  #${PRINTABLE_CONTAINER_ID} {
    display: none;
  }

  @media print {
    #${APP_CONTAINER_ID} {
      display: none;
    }

    .MuiPopover-root,
    .MuiDialog-root {
      display: none !important;
      position: static !important;
      z-index: 0 !important;
    }

    #${PRINTABLE_CONTAINER_ID} {
      display: block;
    }
  }
`;

export default GlobalStyles;
