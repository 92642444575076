import authRoutes from 'routes/globalRoutes/auth';
import myProfileRoutes from 'routes/globalRoutes/myProfile';

let routes = [];
const routeFiles = require.context('./', true, /^(?!.\/index|.\/paths).*\.js$/);

routeFiles.keys().forEach((routeFile) => {
  routes = [...routes, ...routeFiles(routeFile).default];
});

const pmRoutes = [...authRoutes, ...myProfileRoutes, ...routes];

export default pmRoutes;
