export const GET = Object.freeze({
  REQUEST: 'equipmentInput/GET_REQUEST',
  SUCCESS: 'equipmentInput/GET_SUCCESS',
  ERROR: 'equipmentInput/GET_ERROR',
});

export const GET_BY_EQUIPMENT_ID = Object.freeze({
  REQUEST: 'equipmentInput/GET_BY_EQUIPMENT_ID_REQUEST',
  SUCCESS: 'equipmentInput/GET_BY_EQUIPMENT_ID_SUCCESS',
  ERROR: 'equipmentInput/GET_BY_EQUIPMENT_ID_ERROR',
});

export const FIND = Object.freeze({
  REQUEST: 'equipmentInput/FIND_REQUEST',
  SUCCESS: 'equipmentInput/FIND_SUCCESS',
  ERROR: 'equipmentInput/FIND_ERROR',
});

export const CREATE = Object.freeze({
  REQUEST: 'equipmentInput/CREATE_REQUEST',
  SUCCESS: 'equipmentInput/CREATE_SUCCESS',
  ERROR: 'equipmentInput/CREATE_ERROR',
});

export const UPDATE = Object.freeze({
  REQUEST: 'equipmentInput/UPDATE_REQUEST',
  SUCCESS: 'equipmentInput/UPDATE_SUCCESS',
  ERROR: 'equipmentInput/UPDATE_ERROR',
});

export const DELETE = Object.freeze({
  REQUEST: 'equipmentInput/DELETE_REQUEST',
  SUCCESS: 'equipmentInput/DELETE_SUCCESS',
  ERROR: 'equipmentInput/DELETE_ERROR',
});

export const RESET_STATE = 'equipmentInput/RESET_STATE';

export const GET_EQUIPMENT_INPUTS_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET.ERROR,
    payload,
    error: true,
  }),
});

export const GET_EQUIPMENT_INPUTS_BY_EQUIPMENT_ID_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: GET_BY_EQUIPMENT_ID.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: GET_BY_EQUIPMENT_ID.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: GET_BY_EQUIPMENT_ID.ERROR,
    payload,
    error: true,
  }),
});

export const FIND_EQUIPMENT_INPUT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: FIND.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: FIND.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: FIND.ERROR,
    payload,
    error: true,
  }),
});

export const CREATE_EQUIPMENT_INPUT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: CREATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: CREATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: CREATE.ERROR,
    payload,
    error: true,
  }),
});

export const UPDATE_EQUIPMENT_INPUT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: UPDATE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: UPDATE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: UPDATE.ERROR,
    payload,
    error: true,
  }),
});

export const DELETE_EQUIPMENT_INPUT_ACTION = Object.freeze({
  REQUEST: (payload) => ({
    type: DELETE.REQUEST,
    payload,
  }),
  SUCCESS: (payload) => ({
    type: DELETE.SUCCESS,
    payload,
    error: false,
  }),
  ERROR: (payload) => ({
    type: DELETE.ERROR,
    payload,
    error: true,
  }),
});
