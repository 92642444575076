import { required } from '../../utils/helpers';
import {
  ADD_NOTIFICATION_ACTION,
  REMOVE_NOTIFICATION_ACTION,
  RESET_NOTIFICATIONS_ACTION,
  SET_NOTIFICATIONS_ACTION,
} from './constants';
import { notificationRequest } from '../../api/requestWrappers/NotificationRequest';
import NotificationModel from '../../api/models/NotificationModel';

export const markNotificationAsAcknowledged = (notificationId) => {
  required(notificationId);

  return (dispatch) => {
    dispatch(
      REMOVE_NOTIFICATION_ACTION.REQUEST({
        resourceModel: NotificationModel,
        id: notificationId,
      })
    );
    return notificationRequest
      .markOneAsAcknowledged(notificationId)
      .then((data) => {
        dispatch(
          REMOVE_NOTIFICATION_ACTION.SUCCESS({
            resourceModel: NotificationModel,
            data,
            id: notificationId,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          REMOVE_NOTIFICATION_ACTION.ERROR({
            resourceModel: NotificationModel,
            id: notificationId,
            error: error.message,
          })
        );
      });
  };
};

export function getNotifications(pager, query = {}) {
  return (dispatch) => {
    dispatch(
      SET_NOTIFICATIONS_ACTION.REQUEST({
        query,
      })
    );
    return notificationRequest
      .get(pager, query)
      .then(({ total, resources }) => {
        dispatch(
          SET_NOTIFICATIONS_ACTION.SUCCESS({
            resourceModel: NotificationModel,
            resources,
            total,
            query,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);

        throw dispatch(
          SET_NOTIFICATIONS_ACTION.ERROR({
            error: error.message,
            query,
          })
        );
      });
  };
}

export const addNotificationAction = (newNotification) =>
  ADD_NOTIFICATION_ACTION.SUCCESS({
    newNotification,
    resourceModel: NotificationModel,
  });

export const markAllNotificationsAsAcknowledged = () => {
  return (dispatch) => {
    dispatch(
      RESET_NOTIFICATIONS_ACTION.REQUEST({
        resourceModel: NotificationModel,
      })
    );
    return notificationRequest
      .markAllAsAcknowledged()
      .then((data) => {
        dispatch(
          RESET_NOTIFICATIONS_ACTION.SUCCESS({
            resourceModel: NotificationModel,
            data,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          RESET_NOTIFICATIONS_ACTION.ERROR({
            resourceModel: NotificationModel,
            error: error.message,
          })
        );
      });
  };
};
