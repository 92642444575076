import PROJECT_BOARD_EVENTS from '../events/projectBoard.events';
import ProjectBoardNotificationsEventCatcher from '../eventCatchers/projectBoard/ProjectBoardNotificationsEventCatcher';

const processProjectBoardEvents = ({ event, data, dispatch }) => {
  switch (event) {
    case PROJECT_BOARD_EVENTS.NOTIFICATION: {
      ProjectBoardNotificationsEventCatcher.process({
        notification: data,
        dispatch,
      });
      break;
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn('There are no cases for this event', { event, data });
    }
  }
};

export default processProjectBoardEvents;
