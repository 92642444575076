import { lazy } from 'react';
import PATH from 'routes/paths';

const UserList = lazy(() => import('containers/user/UserList'));

export const USER_LIST = {
  component: UserList,
  path: PATH.USER.LIST,
  exact: false,
  isProtected: true,
};

export default [USER_LIST];
