import styled from 'styled-components';
import { Fab as MuiFab, Zoom } from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuRegularIcon from 'stories/ui/Icons/MenuRegularIcon';
import CloseIcon from 'stories/ui/Icons/CloseIcon';
import Z_INDEX_VALUES from 'css/constants';

const StyledFab = styled(MuiFab)`
  z-index: ${Z_INDEX_VALUES.FAB};
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: ${({ theme }) => theme.palette.primary.main};

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export default function Fab({ fabIsActive, showMenu, onShowMenuChange }) {
  return (
    fabIsActive && (
      <>
        <Zoom
          in={showMenu}
          unmountOnExit
          style={{
            transitionDelay: `${showMenu ? 50 : 0}ms`,
          }}
          onClick={() => onShowMenuChange(false)}
        >
          <StyledFab size="small" aria-label="close-mobile-menu">
            <CloseIcon color="white" />
          </StyledFab>
        </Zoom>
        <Zoom
          in={!showMenu}
          unmountOnExit
          style={{
            transitionDelay: `${!showMenu ? 50 : 0}ms`,
          }}
          onClick={() => onShowMenuChange(true)}
        >
          <StyledFab size="small" aria-label="open-mobile-menu">
            <MenuRegularIcon color="white" />
          </StyledFab>
        </Zoom>
      </>
    )
  );
}

Fab.propTypes = {
  fabIsActive: PropTypes.bool,
  showMenu: PropTypes.bool,
  onShowMenuChange: PropTypes.func,
};

Fab.defaultProps = {
  fabIsActive: true,
  showMenu: false,
  onShowMenuChange: () => null,
};
