import AbstractModel from '../AbstractModel';
import { STATUS } from '../../../utils/constants';

class MaintenanceParameterModel extends AbstractModel {
  constructor(...params) {
    super(...params);

    if (this.status !== STATUS.LOADING) {
      this.minValue &&
        this.overwriteProperty('minValue', {
          get() {
            return Number(this._minValue);
          },
        });
      this.maxValue &&
        this.overwriteProperty('maxValue', {
          get() {
            return Number(this._maxValue);
          },
        });
    }
  }
}

export default MaintenanceParameterModel;
