import ApiResourceRequest, { API_METHODS } from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import Pager from '../../helpers/Pager';

export class MaintenanceProcedureRequest extends ApiResourceRequest {
  get apiResource() {
    return 'maintenance-procedures';
  }

  get dynamicIds() {
    return {
      PROCEDURE_ID: '{procedureId}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_EQUIPMENT_ID: `${this.apiRoute}/by_equipment_id/{equipment_id}`,
      ENABLE: `${this.apiRoute}/enable/`,
      CANCEL_ENABLE: `${this.apiRoute}/cancel-enable/`,
      DISABLE: `${this.apiRoute}/disable/`,
      CANCEL_DISABLE: `${this.apiRoute}/cancel-disable/`,
      UPDATE_PROCEDURES_POSITIONS: `${this.apiRoute}/update-procedures-positions`,
      UPDATE_OPERATIONS_POSITIONS: `${this.apiRoute}/update-operations-positions`,
      GET_OPERATIONS: `${this.apiRoute}/${this.dynamicIds.PROCEDURE_ID}/operations`,
    };
  }

  async updateProceduresPositions(items) {
    required({ items });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.UPDATE_PROCEDURES_POSITIONS,
      items
    );

    return response.data;
  }

  async updateOperationsPositions(items) {
    required({ items });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.UPDATE_OPERATIONS_POSITIONS,
      items
    );

    return response?.data;
  }

  async getByEquipmentId(
    equipmentId,
    pager = new Pager(),
    query = {},
    resourcesDataKey = this.apiResource
  ) {
    pager && Pager.check(pager);
    required({ equipmentId });
    const paginationParams = this.getPaginationParams(pager);
    const orderParams = this.getOrderParams(query.order);

    const response = await this.request(
      'GET',
      this.routes.GET_BY_EQUIPMENT_ID.replace('{equipment_id}', equipmentId) +
        paginationParams +
        orderParams
    );

    return {
      resources: response?.data?.[resourcesDataKey],
      total: response?.data?.total_item_count,
    };
  }

  async enable(procedureId, toggleDate) {
    required({ procedureId, toggleDate });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.ENABLE + procedureId,
      { toggleDate }
    );

    return response?.data;
  }

  async cancelEnable(procedureId) {
    required({ procedureId });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.CANCEL_ENABLE + procedureId
    );

    return response?.data;
  }

  async disable(procedureId, toggleDate) {
    required({ procedureId, toggleDate });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.DISABLE + procedureId,
      { toggleDate }
    );

    return response?.data;
  }

  async cancelDisable(procedureId) {
    required({ procedureId });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.CANCEL_DISABLE + procedureId
    );

    return response?.data;
  }

  async getOperations(procedureId) {
    required({ procedureId });

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_OPERATIONS.replace(
        this.dynamicIds.PROCEDURE_ID,
        procedureId
      )
    );

    return response?.data;
  }
}

export const maintenanceProcedureRequest = new MaintenanceProcedureRequest();
