export default {
  activityReport: {
    itself: 'Activity registry',
    plural: {
      itself: 'Activity registries',
    },
    hoursSpent: 'Hours spent',
    saveItem: 'Save the activity report',
    createNew: 'Create a new activity report',
    updateNew: 'Update the new activity report',
    form: {
      hoursSpent: {
        itself: 'Hours spent',
        '*': 'Hours spent *',
        required: 'Spent hours are required',
      },
    },
    types: {
      technicalMeeting: 'Technical meeting',
      technicalAssistance: 'Technical assistance',
      additionalReporting: 'Additional reporting',
      tickets: 'Tickets',
      others: 'Others',
    },
  },
};
