import AbstractModel from './AbstractModel';
import { STATUS } from '../../utils/constants';
import RoleModel from './RoleModel';
import { getHumanReadableRoles } from '../../utils/helpers';
// this cycle is fine as long as RealmModel is not used in top level code
// eslint-disable-next-line import/no-cycle
import RealmModel from './RealmModel';
import { FileRequest, fileRequest } from '../requestWrappers/FileRequest';

class UserModel extends AbstractModel {
  constructor(...params) {
    super(...params);

    if (this.status !== STATUS.LOADING) {
      if (Array.isArray(this.roles)) {
        this.roles = this.roles.map((role) => new RoleModel({ ...role }));
      }
      if (this.organization) {
        this.organization = new RealmModel(this.organization);
      }

      if (new Date(this.lastSeen).toString() !== 'Invalid Date') {
        this.overwriteProperty('lastSeen', {
          get() {
            return this._lastSeen ? new Date(this._lastSeen) : null;
          },
        });
      }
      if (this.phoneNumber !== undefined) {
        this.overwriteProperty('phoneNumber', {
          get() {
            return this._phoneNumber ? this._phoneNumber : '--';
          },
        });
      }
    }
  }

  convertAttribute(attribute) {
    if (attribute === 'status') {
      return 'userStatus';
    }
    return super.convertAttribute(attribute);
  }

  getAvatarImgUrl = (uuid) => {
    return uuid
      ? fileRequest.getFilePath(uuid, FileRequest.THUMBNAIL_SIZES.SQUARE_176_PX)
      : '';
  };

  get fullName() {
    return `${this.firstName ?? '-'} ${this.lastName ?? '-'}`;
  }

  get nameAbbreviation() {
    const firstLetter = this.firstName?.charAt(0) || '';
    const lastLetter = this.lastName?.charAt(0) || '';

    if (firstLetter || lastLetter) {
      return `${firstLetter}${lastLetter}`;
    }
    return '--';
  }

  get organizationName() {
    return this.organization?.name ?? '--';
  }

  get role() {
    return getHumanReadableRoles(this.roles)[0].title;
  }

  get roleName() {
    return getHumanReadableRoles(this.roles)[0].name;
  }

  get avatarImgUrl() {
    const avatarImageUuid = this._avatarImage?.uuid;

    return this.getAvatarImgUrl(avatarImageUuid);
  }

  get isGeneralChatMagician() {
    if (RoleModel.ADMIN_ROLES.includes(this.roleName)) {
      return true;
    }

    const magicianRoles = RoleModel.ASSISTANT_PM_AND_ABOVE_ROLES;

    const isAppropriateRole = magicianRoles.includes(this.roleName);
    const isAppropriateProjectsCount = this.projectCount >= 2;

    return isAppropriateRole && isAppropriateProjectsCount;
  }

  get isSuspended() {
    return this.userStatus === UserModel.STATUSES.SUSPENDED;
  }

  static STATUSES = Object.freeze({
    ACTIVE: 'Active',
    SUSPENDED: 'Suspended',
  });

  static FILTER = Object.freeze({
    BUILDING: 'buildingId',
    LEVEL: 'levelId',
    ROOM: 'roomId',
  });
}

export default UserModel;
