export default {
  dailyProcedure: {
    dailyProcedureGroup: 'Daily Procedure Groups',
    itself: 'Daily procedure',
    plural: {
      itself: 'Daily procedures',
    },
    procedures: 'Procedures',
    createNewGroup: 'Create a new group',
    editGroup: 'Edit group',
    createGroup: 'Create group',
    saveGroup: 'Save group',
    emptyDailyCheckGroupList:
      'Hit the blue plus button and start adding daily procedure groups',
    group: {
      attributes: {
        name: {
          itself: 'Name',
          '*': 'Name *',
          required: 'Name is required',
        },
      },
    },
    confirmDeleteModal: {
      cancel: 'No, cancel',
      confirm: 'Yes, delete',
      deleteMessage:
        'Are you sure you want to delete this group and all its information?',
    },
    completed: 'Completed',
    resolvedProcedures: 'Resolved procedures',
    alerts: 'Alerts',
  },
};
