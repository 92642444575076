import AbstractEventCatcher from '../AbstractEventCatcher';
import { procurementChatActionCreators } from '../../../store/chat/resource/actions';
import eventBus, { EVENT_LIST } from '../../../utils/eventBus/eventBus';
import ProcurementChatHistoryMessageModel from '../../models/ProcurementChatHistoryMessageModel';

export default class ProcurementChatSendMessageEventCatcher extends AbstractEventCatcher {
  static process({ messageInfo, dispatch, loggedInUser }) {
    /** @dima todo ask be to align models */

    const procurementId =
      messageInfo.procurementId || messageInfo.metadata?.procurementId;
    const chatId = messageInfo.chatId || messageInfo.chat?.id;

    // eslint-disable-next-line no-prototype-builtins
    const message = messageInfo.message?.hasOwnProperty('type')
      ? messageInfo.message
      : messageInfo;

    dispatch(
      procurementChatActionCreators.addMessage(procurementId, chatId, message)
    );

    if (ProcurementChatHistoryMessageModel.getIsStatusChangeMessage(message)) {
      eventBus.$emit(
        EVENT_LIST.COMPONENTS.REAL_TIME.PROCUREMENT.STATUS_CHANGED,
        {
          status:
            ProcurementChatHistoryMessageModel.TYPES_JOINED_WITH_STATUSES[
              message.type
            ],
          metadata: message.metadata,
        }
      );
    }

    if (loggedInUser?.id !== message.metadata?.userId) {
      if (
        ProcurementChatHistoryMessageModel.getIsTicketLinkingMessage(message)
      ) {
        if (
          message.type ===
          ProcurementChatHistoryMessageModel.TYPES
            .SYSTEM_PROCUREMENT_LINKED_TO_TICKED_ID
        ) {
          eventBus.$emit(
            EVENT_LIST.COMPONENTS.REAL_TIME.PROCUREMENT.TICKET_LINKED,
            message.metadata?.ticketId
          );
        } else {
          eventBus.$emit(
            EVENT_LIST.COMPONENTS.REAL_TIME.PROCUREMENT.TICKET_UNLINKED,
            message.metadata?.ticketId
          );
        }
      }
    }
  }
}
